import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { LicenseChecklistComponent } from './license-checklist.component';
import { LicenseChecklistApplicantComponent } from './license-checklist-applicant/license-checklist-applicant.component';
import { LicenseChecklistCurriculumComponent } from './license-checklist-curriculum/license-checklist-curriculum.component';

const COMPONENTS = [
  LicenseChecklistComponent,
  LicenseChecklistApplicantComponent,
  LicenseChecklistCurriculumComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [LicenseChecklistComponent]
})
export class LicenseChecklistModule { }
