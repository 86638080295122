import { Component } from '@angular/core';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-manage-compliance',
  templateUrl: './manage-compliance.component.html',
  styleUrls: ['./manage-compliance.component.scss']
})
export class ManageComplianceComponent {
  constructor(public sharedService: SharedService) { }
}
