import { Component, OnInit, Input, Output, ViewChild, EventEmitter, Inject, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/auth/auth.service";
import { ICompliancePlan } from "app/models/compliance-plan/compliance-plan";
import { ICompliancePlanRules } from "app/models/compliance-plan/compliance-plan-rule";
import { ICompliance } from "app/models/compliance/compliance";
import { IComplianceRule } from "app/models/compliance/compliance-rule";
import { IStaff } from "app/models/user-info/staff";
import { CompliancePlanService } from "app/services/compliance-plan/compliance-plan.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";
import { PrintCompliancePlanComponent } from "./print-compliance-plan/print-compliance-plan.component";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@Component({
  selector: 'app-compliancePlan',
  templateUrl: './compliance-plan.component.html',
  styleUrls: ['./compliance-plan.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class CompliancePlanComponent implements OnInit {
  @Input() caseId: number = 0;
  @Input() compliance: ICompliance;
  @Input() editing: boolean = true;
  @Input() staff: IStaff[] = [];
  @Input() staffReference: {[id: string]: string} = {};
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public today: Date = new Date(Date.now());
  public viewingComplianceCase: boolean = false;
  public compliancePlans: ICompliancePlan[] = [];
  public compliancePlan: ICompliancePlan = {
    id: 0,
    compliancePlanId: "",
    status: "",
    startedDate: "",
    sentTrainingPlan: false,
    assignedTo: "",
    compliancePlanRules: []
  };
  public compliancePlanColumns: string[] = ['compliancePlanId', 'status', 'startedDate', 'assignedTo', 'actions'];
  public compliancePlanSource = new MatTableDataSource<ICompliancePlan>(this.compliancePlans);
  @ViewChild('compliancePlanPaginator', {static: false}) compliancePlanPaginator: MatPaginator;
  @ViewChild('compliancePlanSort', {static: false}) compliancePlanSort: MatSort;

  public compliancePlanForm = new UntypedFormGroup({
    status: new UntypedFormControl(""),
    assignedTo: new UntypedFormControl(""),
    startedDate: new UntypedFormControl(""),
    sentTrainingPlan: new UntypedFormControl(false)
  });

  constructor(public sharedService: SharedService,
    public toastr: ToastrService,
    public authService: AuthService,
    private dialog: MatDialog,
    public compliancePlanService: CompliancePlanService) { }

  ngOnInit(): void {
    this.compliancePlanService.getCompliancePlans(this.compliance.id).subscribe(
      response => this.compliancePlans = response,
      error => console.log('error', error),
          () => {
            this.updateCompliancePlanTable();
          }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['caseId'];
    if (change != null && change.currentValue !== change.previousValue && !change.firstChange) {
      this.compliancePlanService.getCompliancePlans(this.compliance.id).subscribe(
        response => this.compliancePlans = response,
        error => console.log('error', error),
            () => {
              this.updateCompliancePlanTable();
            }
      );
    }
  }

  exportPlan() {
    this.dialog.open(PrintCompliancePlanComponent, {
      data: {
        currentCompliancePlan: this.compliancePlan,
        staff: this.staffReference
      },
      role: 'dialog',
      ariaLabel: 'Compliance Plan',
      minWidth: !this.sharedService.mobile ? '900px' : '300px',
      maxWidth: !this.sharedService.mobile ? '980px' : '300px'
    });
  }
  addCompliancePlanRules(): void{
    let currentRules: number[] = this.compliancePlan.compliancePlanRules.map((rule: ICompliancePlanRules) => { return rule.rule.id });
    let newRules: IComplianceRule[] = this.compliance.complianceRules.filter(rule => !currentRules.includes(rule.rule.id));
    const dialogRef = this.dialog.open(AddComplianceRuleDialogComponent, {
      data: newRules,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '' : '300px',
      maxHeight: !this.sharedService.mobile ? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        response.forEach((rule: IComplianceRule) => {
          let complianceRule: ICompliancePlanRules = {
            id: 0,
            status: "New",
            resolvedDate: "",
            notes: "",
            rulesSummary: "",
            notMeetingRequirementReason: "",
            rule: rule.rule
          }
          this.compliancePlan.compliancePlanRules.push(complianceRule)
        });

        this.compliancePlanService.addRules(this.compliancePlan.compliancePlanRules,this.compliancePlan.id).subscribe(
          response =>  this.compliancePlan.compliancePlanRules = response,
          error => console.log('error', error),
        );
      }
    });
  }
  public updateCompliancePlanTable(): void {
    this.compliancePlanSource.data = this.compliancePlans;
    this.compliancePlanSource.paginator = this.compliancePlanPaginator;
    this.compliancePlanSource.sort = this.compliancePlanSort;
  }

  public filterTable(event: Event, type: string): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    if(type == 'compliancePlans')
      this.compliancePlanSource.filter = filter;
  }

  addCompliancePlan(): void {
    this.sharedService.openConfirm("Add Compliance Plan?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.compliancePlanService.createCompliancePlan(this.compliance).subscribe(
            response => this.compliancePlan = response,
            error => console.log('error', error),
            () => {
              this.compliancePlans.push(this.compliancePlan);
              this.updateCompliancePlanForm();
              this.updateCompliancePlanTable();
              this.notifyParent.emit(true);
              this.viewingComplianceCase = true;
              this.editing = true;
            }
          );
        }
      }
    );
  }

  public editCompliancePlan(item: ICompliancePlan): void{
    this.compliancePlan = item;
    setTimeout(() => {
      this.updateCompliancePlanForm();
      this.viewingComplianceCase = true;
      this.editing = false;
      this.notifyParent.emit(true);
      }, 50);

  }

  edit(): void {
    this.editing = !this.editing;
  }

  updateCompliancePlan(close: boolean): void {
    let form = this.compliancePlanForm.value;
    this.compliancePlan.status = form.status;
    this.compliancePlan.assignedTo = form.assignedTo;
    this.compliancePlan.startedDate = form.startedDate;
    this.compliancePlan.sentTrainingPlan = form.sentTrainingPlan;
    this.compliancePlanService.updateCompliancePlan(this.compliancePlan).subscribe(
      response => this.compliancePlan = response,
      error => console.log('error', error),
      () => {
        this.compliance.complianceRules.forEach(rule =>
          {
            let currentSiteRule = this.compliancePlan.compliancePlanRules.find(e => e.rule.id == rule.rule.id).notes;
            if(currentSiteRule != '')
            {
            if(rule.notes == null || rule.notes == '')
              rule.notes = ' Compliance Plan ' + this.compliancePlan.compliancePlanId + ' notes: ' + currentSiteRule;
            if(!rule.notes.includes(' notes: ' + currentSiteRule))
              rule.notes += ' Compliance Plan ' + this.compliancePlan.compliancePlanId + ' notes: ' + currentSiteRule;
            }
          });
        if(close){
          this.viewComplianceCase();
        }
      }
    );
  }

  viewComplianceCase(): void {
    this.viewingComplianceCase = false;
    this.compliancePlan = this.getEmptyCompliancePlan();
    this.updateCompliancePlanForm();
    this.updateCompliancePlanTable();
    this.editing = !this.viewingComplianceCase;
    setTimeout(() => {
      this.notifyParent.emit(false);
    }, 150);
  }

  getEmptyCompliancePlan(): ICompliancePlan {
    let compliancePlan: ICompliancePlan = {
      id: 0,
      compliancePlanId: "",
      status: "",
      startedDate: "",
      sentTrainingPlan: false,
      assignedTo: "",
      compliancePlanRules: []
    }
    return compliancePlan;
  }

  updateCompliancePlanForm(): void {
    this.compliancePlanForm.patchValue({
      status: this.compliancePlan.status,
      assignedTo: this.compliancePlan.assignedTo,
      sentTrainingPlan: this.compliancePlan.sentTrainingPlan
    });

    if (this.compliancePlan.startedDate != null && this.compliancePlan.startedDate.length > 0)
      this.compliancePlanForm.get('startedDate').patchValue(new Date(this.compliancePlan.startedDate));

  }

  deleteCompliancePlan(id: number): void{
    this.sharedService.openConfirm("Delete Compliance Plan?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.compliancePlanService.deleteCompliancePlan(id).subscribe(
            () => {
              this.compliancePlans = this.compliancePlans.filter(item => item.id !== id);
              this.updateCompliancePlanTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }
}

@Component({
  selector: 'add-compliance-rule-dialog',
  styleUrls: ['./compliance-plan.component.scss'],
  templateUrl: 'dialog-add-compliance-rule.html'
})

export class AddComplianceRuleDialogComponent implements OnInit {
  public selectedRules: IComplianceRule[] = [];
  public ruleRefColumns: string[] = ['statute', 'summary', 'actions'];
  public ruleRefSource = new MatTableDataSource<IComplianceRule>(this.rules);
  @ViewChild('ruleRefPaginator', {static: false}) ruleRefPaginator: MatPaginator;
  @ViewChild('ruleRefSort', {static: false}) ruleRefSort: MatSort;

  constructor(public dialogRef: MatDialogRef<AddComplianceRuleDialogComponent>,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public rules: IComplianceRule[]) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.ruleRefSource.data = this.rules;
      this.ruleRefSource.sort = this.ruleRefSort;
      this.ruleRefSource.paginator = this.ruleRefPaginator;
    }, 30);

  }

  filterTable(event: Event): void{
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.ruleRefSource.filter = filter;
  }

  selectRule(rule: IComplianceRule) : void {
    if(!this.selectedRules.some(sr => sr.id === rule.id)){
      this.selectedRules.push(rule);
    }
  }

  removeRule(rule: IComplianceRule): void {
    this.selectedRules = this.selectedRules.filter(sr => sr !== rule);
  }

  addRules(): void {
    this.sharedService.openConfirm('Add selected rules?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.dialogRef.close(this.selectedRules);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
