<mat-card *ngIf="!loading && !viewingEntity">
  <mat-card-title>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <button type="button" color="primary" mat-flat-button (click)="openApplicantHistoryChangeLog()">
        <span>
          Contact Information History
        </span>
      </button>
      <span fxFlex></span>
      <button  type="button" mat-icon-button [color]="editingApplicant? 'primary':'warn'"
        [matTooltip]="editingApplicant? 'Stop Editing' : 'Edit Applicant'" matTooltipShowDelay=400
        aria-label="Edit Facilitator" (click)="edit()">
        <mat-icon>{{editingApplicant? 'edit' : 'edit_off'}}</mat-icon>
      </button>
       <button mat-flat-button type="button" color="warn" (click)="cancel()">Close</button>
     <button mat-flat-button type="button" color="primary"
        (click)="saveApplicant(false)">Save</button>
      <button mat-flat-button type="button" color="primary"
        (click)="saveApplicant(true)">Save and Close</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <!--Applicant Form-->
    <form [formGroup]="applicantForm">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Personal Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <div fxLayout="row" fxLayout.lt-lg="column">
                <span fxFlex>Personal Information</span>
              </div>
            </mat-card-title>
            <mat-card-content *ngIf="!editingApplicant">
              <div fxLayout="column" fxLayoutGap="8px">

                <div fxLayout="row" fxLayoutGap="8px">
                  <span><strong>Legal Name</strong></span>
                  <span>{{applicant.legalFirstName}}</span>
                  <span>{{applicant.legalMiddleName}}</span>
                  <span>{{applicant.legalLastName}}</span>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span><strong>Preferred Name</strong></span>
                  <span>{{applicant.preferredFirstName}}</span>
                  <span>{{applicant.preferredMiddleName}}</span>
                  <span>{{applicant.preferredLastName}}</span>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span><strong>Other Names or Aliases</strong></span>
                  <span>{{applicant.alternateNames}}</span>
                </div>

                <div fxLayout="row" fxLayoutGap="16px">
                  <div fxLayout="column">
                    <span><strong>DOB</strong></span>
                    <span>{{applicant.dob | date}}</span>
                  </div>
                  <div fxLayout="column">
                    <span><strong>Age</strong></span>
                    <span>{{sharedService.ageCalculator(applicant.dob)}}</span>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="16px" fxFlex="100">
                  <div fxLayout="column">
                    <span><strong>SSN</strong></span>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                      fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <span>{{applicant.ssn | mask:"000-00-0000"}}</span>
                      <mat-checkbox [checked]="applicant.noSSN" disabled>
                        Applicant has no SSN
                      </mat-checkbox>
                    </div>
                    <mat-checkbox [checked]="applicant.ssnAttestation" disabled>
                      <span class="text-wrap">
                        Applicant consents to use of SSN
                      </span>
                    </mat-checkbox>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <mat-checkbox fxFlex="40" [checked]="applicant.backgroundCheckPassed" disabled>
                    Background Check Passed
                  </mat-checkbox>


                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Initiated Date</strong></span>
                    <span>{{applicant.backgroundCheckInitiatedDate | date}}</span>
                  </div>

                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Completed Date</strong></span>
                    <span>{{applicant.backgroundCheckDate | date}}</span>
                  </div>
                </div>

                <textarea matInput cols="2" [value]="applicant.backgroundCheckNotes" placeholder="Background Check Notes" disabled></textarea>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingApplicant">
              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Legal Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="legalFirstName">
                    <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                      Please enter your first name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="legalLastName">
                    <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                      Please enter your last name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="legalMiddleName">
                  </mat-form-field>
                </div>

                <span><strong>Preferred Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="preferredFirstName">
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="preferredLastName">
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                  </mat-form-field>
                </div>

                <div fxLayout="column">
                  <span><strong>Please provide any other names you have used</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                    <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                      <input matInput placeholder="Name, other name, any other names" formControlName="alternateNames">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Date of Birth</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                        [max]="sharedService.today" />
                      <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                      <mat-datepicker #dob>
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                        Date of birth is required
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>

                <div fxLayout="column">

                  <span>Social Security Number Disclosure</span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                    fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                      <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                      <mat-error *ngIf="validateSsn()">
                        SSN is Required
                      </mat-error>
                    </mat-form-field>
                    <mat-checkbox formControlName="noSsn">
                      Applicant has no SSN
                    </mat-checkbox>
                  </div>
                  <mat-checkbox formControlName="ssnAttestation">
                    <span class="text-wrap">
                      Applicant consents to use of SSN
                    </span>
                  </mat-checkbox>

                  <div fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-checkbox fxFlex="35" formControlName="backgroundCheckPassed">
                        Background Check Passed
                      </mat-checkbox>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Initiated Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckInitiatedDate" matInput [matDatepicker]="backgroundCheckInitiatedDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckInitiatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckInitiatedDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').hasError('required')">
                          Background Check Initiated Date is required
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Completed Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').hasError('required')">
                          Background Check Completed Date is required
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <textarea matInput cols="2" formControlName="backgroundCheckNotes" placeholder="Background Check Notes"></textarea>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Contact Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Contact Information
            </mat-card-title>
            <mat-card-content *ngIf="!editingApplicant">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <span>{{applicant.phone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <span>{{applicant.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <span>{{applicant.email}}</span>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <span>{{applicant.language}}</span>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                  <mat-checkbox [checked]="applicant.previousLicense" disabled>
                    <span class="text-wrap">Applicant has other licenses</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="applicant.previousLicense">
                    <span><strong>Other Oregon Psilocybin licenses</strong></span>
                    <span>{{applicant.affiliatedLicenses}}</span>
                  </div>
                </div>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingApplicant">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000"
                        [validation]="false">
                      <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                        A primary phone number is required
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Alt Phone" formControlName="alternatePhone"
                        mask="(000) 000-0000 000000" [validation]="false">
                      <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Email" formControlName="email">
                      <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                        Please enter an email adress
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                      <mat-error *ngIf="validateEmail()">
                        Email does not match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Language" formControlName="language">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                  <mat-checkbox formControlName="previousLicense">
                    <span class="text-wrap">
                      Check if this individual has applied for or received any other licenses from Oregon Psilocybin
                      Services</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Please provide the license ids for each Oregon Psilocybin license</span>
                    <mat-form-field>
                      <input matInput placeholder="Affiliated Licenses" formControlName="affiliatedLicenses"
                        (blur)="validatePreviousLicense()">
                      <mat-error *ngIf="validatePreviousLicense()">
                        Please provide all license numbers
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!--Addresses-->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <mat-card fxFlex="100" fxFlex.lt-md="100">
            <mat-card-title>Address</mat-card-title>
            <mat-card-content *ngIf="!editingApplicant">
              <div fxLayout="row" fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                  <div fxLayout="column">
                    <span><strong>Physical Address</strong></span>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <span>{{applicant.physicalStreet }}</span>
                      <span>{{applicant.physicalCity }}</span>
                      <span>{{applicant.physicalState }}</span>
                      <span>{{applicant.physicalZip }}</span>
                      <span>{{applicant.physicalCounty }}</span>
                    </div>
                  </div>

                  <div fxLayout="column" class="m-t-8">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                      <span><strong>Mailing Address</strong></span>
                      <mat-checkbox [checked]="applicant.mailingIsPhysical" disabled>
                        Same as physical
                      </mat-checkbox>
                    </div>
                    <div fxLayout="column" [@slideInOut] *ngIf="!applicant.mailingIsPhysical">
                      <span>{{applicant.mailingStreet }}</span>
                      <span>{{applicant.mailingCity }}</span>
                      <span>{{applicant.mailingState }}</span>
                      <span>{{applicant.mailingZip }}</span>
                      <span>{{applicant.mailingCounty }}</span>
                    </div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="8px">
                    <span><strong>Oregon Residency</strong></span>
                    <mat-checkbox [checked]="applicant.oregonResidency" disabled>
                      <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                    </mat-checkbox>
                  </div>
                </div>

                <app-residential-history [applicantId]="applicant.id"
                                         [residentialHistory]="applicant.residentialHistory"
                                         [editingLicense]="editingApplicant"
                                         fxFlex="50">
                </app-residential-history>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingApplicant">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="column">
                  <span><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                      <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="physicalCity">
                      <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                        Please select a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="physicalZip">
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="physicalCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                      Same as physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet"
                          (blur)="validateMailingStreet()">
                        <mat-error *ngIf="validateMailingStreet()">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()">
                        <mat-error *ngIf="validateMailingCity()">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="validateMailingState()">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()">
                        <mat-error *ngIf="validateMailingZip()">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                        *ngIf="applicantForm.get('mailingState').value === 'OR'">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="mailingCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-18"><strong>Oregon Residency</strong></span>
                  <mat-checkbox formControlName="oregonResidency">
                    <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                  </mat-checkbox>
                </div>

                <app-residential-history [applicantId]="applicant.id"
                                         [editingLicense]="editingApplicant"
                                         [residentialHistory]="applicant.residentialHistory">
                </app-residential-history>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!--Documents-->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <div fxLayout="row" fxFlex="100">
            <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
            <!--Upload-->
            <mat-card *ngIf="editingApplicant">
              <mat-card-title>
                <span *ngIf="documentsValid">
                  Supporting Documents
                </span>
                <span *ngIf="!documentsValid" class="text-red-400">
                  Supporting Documents Incomplete
                </span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap.lt-lg="8px">
                  <span>You must provide proof of your identity and that you are at least 21</span>
                  <span>You can upload a separate document for each requirement or combine multiple documents into a
                    single file for upload</span>

                  <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                    <div fxLayout="column">
                      <span class="f-2-14">For each document select the requirements it verifies</span>
                      <mat-checkbox formControlName="identification">Identification</mat-checkbox>
                      <mat-checkbox formControlName="applicantInfo">Applicant Information Form</mat-checkbox>
                      <mat-checkbox formControlName="other">Other</mat-checkbox>
                      <mat-checkbox formControlName="physicalApplication">Physical Application</mat-checkbox>
                    </div>

                    <input type="file" class="file-input" (change)="uploadDocument($event)" #applicantFileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!applicantForm.get('identification').value &&
                                            !applicantForm.get('applicantInfo').value &&
                                            !applicantForm.get('other').value &&
                                            !applicantForm.get('physicalApplication').value"
                          type="button" mat-raised-button color="primary" class="upload-btn"
                          (click)="applicantFileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Current-->
            <mat-card fxFlex="100">
              <mat-card-title>
                <span *ngIf="documentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>

                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                    <span class="f-s-16" fxFlex="10"><strong>Archive</strong></span>
                    <span class="f-s-16" fxFlex="10"><strong></strong></span>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments">
                    <ng-container *ngIf="!document.deprecated">
                      <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                      (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                      <div fxFlex="20" fxFlex.lt-md="" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <span fxFlex="20">{{document.dateCreated | date}}</span>

                      <button fxFlex="10" [hidden]="!editingApplicant || !authService.isAdmin" mat-icon-button color="warn" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                        <mat-icon>archive</mat-icon>
                      </button>
                    </ng-container>
                  </div>

                  <div fxLayout="row">
                    <button type="button" color="primary" mat-flat-button (click)="viewArchivedDocuments()">
                      <span *ngIf="!viewArchived">
                        View Archived Documents
                      </span>
                      <span *ngIf="viewArchived">
                        Hide Archived Documents
                      </span>
                    </button>
                  </div>

                  <br />
                  <mat-card-title *ngIf="viewArchived">
                    <span>Archived Documents</span>
                  </mat-card-title>
                  <mat-card *ngIf="viewArchived" [style.background]="'#c7cdd1'">
                    <mat-card-content>
                      <div fxLayout="column">
                        <div fxLayout="row" *ngIf="!sharedService.mobile">
                          <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                          <span class="f-s-16" fxFlex="10"><strong>Unarchive</strong></span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                        *ngFor="let document of applicant.applicantDocuments">
                        <ng-container *ngIf="document.deprecated">
                          <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                          (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                          <div fxFlex="20" fxFlex.lt-md="" fxLayout="column">
                            <span *ngFor="let type of document.types">
                              {{documentTypeReference[type]}}
                            </span>
                          </div>
                          <span fxFlex="20">{{document.dateCreated | date}}</span>

                          <button fxFlex="10" [hidden]="!editingApplicant || !authService.isAdmin" mat-icon-button color="warn" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                            <mat-icon>unarchive</mat-icon>
                          </button>

                          <button fxFlex="10" *ngIf="editingApplicant && authService.isAdmin" type="button" mat-icon-button class="m-8"
                            (click)="deleteFile(document.id, document.name)" color="warn" aria-label="Delete">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </ng-container>
                      </div>
                      </div>
                    </mat-card-content>
                  </mat-card>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

        <!--Related Applicants-->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <div fxLayout="row" fxFlex="100">
            <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
              <mat-card fxFlex="100">
                <mat-card-title>
                  <span>Related Applicants</span>
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div fxLayout="row" *ngFor="let applicant of relatedApplicants">
                      <div fxLayout="column">
                        <span>{{applicant.applicantId}} {{applicant.legalFirstName}} {{applicant.legalLastName}}</span>
                      </div>
                      <div fxLayout="column">
                        <mat-icon *ngIf="editingApplicant" color="warn" aria-label="Delete" style="cursor: pointer" (click)='removeDuplicate(applicant.id)'>delete</mat-icon>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
              <mat-card fxFlex="100">
                <mat-card-title>
                  <span>Related Licenses</span>
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" fxLayoutGap="8px" *ngFor="let applicant of relatedApplicants">

                      <div *ngIf="applicant.facilitators.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                          *ngFor="let relationship of applicant.facilitators" [routerLink]="'facilitator/search/' + relationship.id">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>

                      <div *ngIf="applicant.manufacturers.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                          *ngFor="let relationship of applicant.manufacturers" [routerLink]="'/manufacturer/search/' + relationship.id">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>

                      <div *ngIf="applicant.serviceCenters.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                           *ngFor="let relationship of applicant.serviceCenters" [routerLink]="'service-center/search/' + relationship.id">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>

                      <div *ngIf="applicant.laboratories.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                          *ngFor="let relationship of applicant.laboratories" [routerLink]="'testing-lab/search/' + relationship.id">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>

                      <div *ngIf="applicant.workerPermits.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                          *ngFor="let relationship of applicant.workerPermits" [routerLink]="'worker-permit/search/' + relationship.id">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>

                      <div *ngIf="applicant.entityApplicants.length > 0">
                        <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                          *ngFor="let relationship of applicant.entityApplicants" (click)="getEntityDetails(relationship.id)">
                          <span>{{relationship.name}}</span>
                          <span>{{relationship.entityId}}</span>
                        </div>
                      </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="!loading && viewingEntity">
  <mat-card-title>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>Viewing</span>
      <span>{{entityDetails.entityName}}</span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="closeEntity()">Back</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
        <!--Contact Information-->
        <mat-card fxFlex="50" fxFlex.lt-md="100">
          <mat-card-title>
            Contact Information
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <span class="f-s-16"><strong>Legal Entity Name</strong></span>
              <span>{{entityDetails.entityName}}</span>
              <span class="f-s-16"><strong>Registered To Do Business In</strong></span>
              <span>{{entityDetails.businessRegisteredIn}}</span>
              <div fxLayout="column">
                <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                <mat-checkbox [checked]="entityDetails.previousLicense" disabled>
                  <span class="text-wrap">
                    Entity has applied for or received other licenses from Oregon Psilocybin Services
                  </span>
                </mat-checkbox>
                <div fxLayout="column" class="m-t-8" *ngIf="entityDetails.previousLicense">
                  <span>Oregon Psilocybin license ids</span>
                  <span>{{entityDetails.affiliatedLicenses}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="50" fxFlex.lt-md="100">
          <mat-card-title>
            <span>Primary Point of Contact</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Contact Name</strong></span>
                  <span>{{entityDetails.primaryContactName}}</span>
                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Contact Phone</strong></span>
                  <span>{{entityDetails.primaryContactNumber | mask:'(000)-000-0000 000000'}}</span>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Email Address</strong></span>
                  <span>{{entityDetails.primaryContactEmail}}</span>

                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Preferred Language</strong></span>
                  <span>{{entityDetails.language}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
        <!--Addresses-->
        <mat-card fxFlex="50" fxFlex.lt-md="100">
          <mat-card-title>Addresses</mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="16px" style="font-size: 18px;">
              <div fxLayout="column">
                <span><strong>Physical Address</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <span>{{entityDetails.physicalStreet }}</span>
                  <span>{{entityDetails.physicalCity }}</span>
                  <span>{{entityDetails.physicalState }}</span>
                  <span>{{entityDetails.physicalZip }}</span>
                  <span>{{entityDetails.physicalCounty }}</span>
                </div>
              </div>

              <div fxLayout="column" class="m-t-8">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                  <span><strong>Mailing Address</strong></span>
                  <mat-checkbox [checked]="entityDetails.mailingIsPhysical" disabled>
                    Same as physical
                  </mat-checkbox>
                </div>
                <div fxLayout="row" fxLayoutGap="16px" [@slideInOut] *ngIf="!entityDetails.mailingIsPhysical">
                  <span>{{entityDetails.mailingStreet }}</span>
                  <span>{{entityDetails.mailingCity }}</span>
                  <span>{{entityDetails.mailingState }}</span>
                  <span>{{entityDetails.mailingZip }}</span>
                  <span>{{entityDetails.mailingCounty }}</span>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Oregon Residency</strong></span>
                <mat-checkbox [checked]="entityDetails.oregonResidency" disabled>
                  <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                </mat-checkbox>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Current Documents-->
        <mat-card fxFlex="50" fxFlex.lt-lg="100">
          <mat-card-title>
            <span>Current Documents</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">
              <div fxLayout="row" *ngIf="!sharedService.mobile" style="font-size: 18px;">
                <span class="f-s-16" fxFlex="60"><strong>File</strong></span>
                <span class="f-s-16"><strong>Requirement</strong></span>
              </div>
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="" style="font-size: 18px;"
                *ngFor="let document of entityDetails.applicantDocuments">
                <span fxFlex="60" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                  (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>
                  <div fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex.lt-md="" fxLayout="column">
                      <span *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </span>
                    </div>
                  </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
