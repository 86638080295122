import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ITrainingProgram } from 'app/models/training/training-program';
import { ITrainingProgramContact } from 'app/models/training/training-program-contact';
import { IUser } from 'app/auth/auth.models';
import { IStaff } from 'app/models/user-info/staff';
import { ITrainingProgramSimple } from 'app/models/training/training-program-simple';
import { IDocumentType } from 'app/models/documents/documentType';
import { ITrainingProgramRevocation } from 'app/models/training/training-program-revocation';
import { ITrainingProgramSearch } from 'app/models/training/training-program-search';
import { ICurriculumCoreElement } from 'app/models/training/curriculum/curriculum-core-element';
import { ICurriculumSubSection } from 'app/models/training/curriculum/curriculum-sub-section';
import { ICurriculumSecondarySubSection } from 'app/models/training/curriculum/curriculum-secondary-sub-section';
import { ITrainingProgramDocument } from 'app/models/training/training-program-document';
import { ITaskSendNotice } from 'app/models/tasks/task';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';

@Injectable({providedIn: 'root'})
export class TrainingService {
  private api: string = environment.privateApi + 'TrainingProgram/';

  private taskSendNotice: ITaskSendNotice=  {
    id: 0,
    reason: ''
  }

  constructor(private http: HttpClient) { }

  getTrainingProgramTasks(): Observable<ITrainingProgramSimple[]>{
    return this.http.get<ITrainingProgramSimple[]>(this.api + 'GetTrainingProgramTasks');
  }

  getPDF(search: AuditLoggingSearch): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + "OriginalCopy", search);
  }

  getTrainingProgram(id: number): Observable<ITrainingProgram>{
    return this.http.get<ITrainingProgram>(this.api + 'GetTrainingProgram/' + id);
  }

  withdrawLicense(approved: boolean, id: number): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + "withdrawLicense/" + approved + "/" + id, null);
  }

  surrenderLicense(approved: boolean, id: number): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + "surrenderLicense/" + approved + "/" + id, null);
  }

  searchTrainingPrograms(search: ITrainingProgramSearch): Observable<ITrainingProgramSimple[]>{
    return this.http.post<ITrainingProgramSimple[]>(this.api + "SearchTrainingPrograms", search);
  }

  getAssignedPrograms(): Observable<ITrainingProgramSimple[]>{
    return this.http.get<ITrainingProgramSimple[]>(this.api + "GetAssignedPrograms");
  }

  getTrainingPrograms(): Observable<ITrainingProgramSimple[]>{
    return this.http.get<ITrainingProgramSimple[]>(this.api + 'GetTrainingPrograms');
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  updateTrainingProgram(program: ITrainingProgram): Observable<ITrainingProgramSimple>{
    return this.http.post<any>(this.api + 'UpdateTrainingProgram', program);
  }

  updateTrainingProgramAccount(program: ITrainingProgram): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + 'UpdateTrainingProgramAccount', program);
  }

  claimTrainingProgram(id: number): Observable<ITrainingProgram[]>{
    return this.http.post<ITrainingProgram[]>(this.api + 'ClaimTrainingProgram' + '/' + id, null);
  }

  createTrainingProgram(): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + 'CreateTrainingProgram', null);
  }

  sendTaskNotice(id: number, reason: string): Observable<boolean> {
    this.taskSendNotice.id = id;
    this.taskSendNotice.reason = reason;
    return this.http.post<boolean>(this.api + 'SendTaskNotice/', this.taskSendNotice);
  }

  acknowledgeTask(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AcknowledgeTask/' + id, null);
  }

  deleteTask(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteTask/' + id);
  }

  renewTrainingProgram(id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'RenewTrainingProgram/' + id, null);
  }

  revokeTrainingProgram(revocation: ITrainingProgramRevocation): Observable<boolean>{
    return this.http.post<boolean>(this.api + "RevokeTrainingProgram", revocation);
  }

  assignTrainingProgram(id: number, staff: IStaff): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignTrainingProgram/' + id, staff);
  }

  getTrainingUsers(): Observable<IUser[]>{
    return this.http.get<IUser[]>(this.api + 'GetTrainingUsers');
  }

  releaseTrainingProgram(id: number): Observable<ITrainingProgram[]>{
    return this.http.post<ITrainingProgram[]>(this.api + 'ReleaseTrainingProgram' + '/' + id, null);
  }

  addTrainingProgramContact(contact: ITrainingProgramContact): Observable<ITrainingProgramContact>{
    return this.http.post<ITrainingProgramContact>(this.api + 'AddTrainingProgramContact', contact);
  }

  editTrainingProgramContact(contact: ITrainingProgramContact): Observable<any>{
    return this.http.post<any>(this.api + 'EditTrainingProgramContact', contact);
  }

  deleteTrainingProgramContact(contactId: number): Observable<any>{
    return this.http.delete<any>(this.api + 'DeleteTrainingProgramContact/' + contactId);
  }

  archiveContact(id: number, archive: boolean): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'ArchiveContact/' + id + '/' + archive, null);
  }

  deleteTrainingProgram(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteTrainingProgram/" + id);
  }

  uploadTrainingProgramDocument(file: FormData): Observable<ITrainingProgramDocument>{
    return this.http.post<ITrainingProgramDocument>(this.api + 'UploadTrainingProgramDocument', file)
  }

  uploadTrainingProgramDocumentComments(document: ITrainingProgramDocument){
    this.http.post<any>(this.api + 'UpdateTrainingProgramDocumentComment', document).subscribe(
      () => {},
      error => console.log('error', error)
    );
  }

  markCurrentTool(document: ITrainingProgramDocument): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'MarkCurrentTool', document);
  }

  downloadTrainingProgramFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadTrainingProgramDocument/" + fileId, { responseType: 'blob' as 'json' })
  }

  deleteTrainingProgramDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteTrainingProgramDocument/" + id)
  }

  deprecateDocument(id: number): Observable<any>{
    return this.http.post<any>(this.api + "DeprecateDocument/" + id, null);
  }

  approveCurriculumChange(id: number, approved: boolean): Observable<boolean>{
    return this.http.post<boolean>(this.api + "ApproveUpdatedCurriculum/" + id + "/" + approved, null);
  }

  getTrainingStaff(): Observable<IStaff[]>{
    return this.http.get<IStaff[]>(this.api + "GetTrainingStaff");
  }

  getCurriculumMap(): Observable<ICurriculumCoreElement[]>{
    return this.http.get<ICurriculumCoreElement[]>(this.api + 'GetCurriculumMap');
  }

  updateCurriculumMap(curriculumMap: ICurriculumCoreElement[]): Observable<ICurriculumCoreElement[]>{
    return this.http.post<ICurriculumCoreElement[]>(this.api + 'UpdateCurriculumMap', curriculumMap);
  }

  updateCurriculumCoreElement(element: ICurriculumCoreElement): Observable<ICurriculumCoreElement>{
    return this.http.post<ICurriculumCoreElement>(this.api + 'UpdateCurriculumCoreElement', element);
  }

  updateCurriculumSubSection(section: ICurriculumSubSection): Observable<ICurriculumSubSection>{
    return this.http.post<ICurriculumSubSection>(this.api + 'UpdateCurriculumSubSection', section);
  }

  updateCurriculumSecondarySubSection(section: ICurriculumSecondarySubSection): Observable<ICurriculumSecondarySubSection>{
    return this.http.post<ICurriculumSecondarySubSection>(this.api + 'UpdateCurriculumSecondarySubSection', section);
  }

  deleteCoreElement(id: number): Observable<ICurriculumCoreElement[]>{
    return this.http.delete<ICurriculumCoreElement[]>(this.api + 'DeleteCoreElement/' + id);
  }

  deleteSubSection(id: number): Observable<ICurriculumCoreElement>{
    return this.http.delete<ICurriculumCoreElement>(this.api + 'DeleteSubSection/' + id);
  }

  deleteSecondarySubSection(id: number): Observable<ICurriculumSubSection>{
    return this.http.delete<ICurriculumSubSection>(this.api + 'DeleteSecondarySubSection/' + id);
  }

}
