import {
  Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation,
} from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  host: {
    class: 'matero-header',
  },
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

  public environment = environment;
  constructor() {}

  ngOnInit() { }

}
