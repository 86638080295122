<section>
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>Add Note</span>
    <button mat-raised-button type="button" color="primary" (click)="addNotes()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="16px" class="m-t-16">

    <mat-table [dataSource]="notesSource" aria-describedby="notes" class="mat-elevation-z8">
      <ng-container matColumnDef="text">
        <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
        <mat-cell *matCellDef="let note">
          <div fxLayout="row" fxFlex="100" fxLayoutGap="8px">
            <textarea rows="2" matInput [value]="note.text"></textarea>
            <button mat-button color="primary" aria-label="Select Note" (click)="selectNote(note)">
              Add
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="notesColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: notesColumns"></mat-row>
    </mat-table>

    <div *ngIf="selectedNotes.length > 0" fxLayout="column">
      <span class="f-s-16">Selected Notes</span>
      <mat-list>
        <mat-list-item *ngFor="let note of selectedNotes">
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" fxFlex="100">
            <textarea matInput rows="1" [value]="note.text" fxFlex="75"></textarea>
            <button type="button" mat-button color="warn" (click)="removeNote(note.id)">Remove</button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  </div>
</mat-dialog-content>
