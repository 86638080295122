<section>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
    <span class="f-s-20" fxFlex>Rule and/or Statute Reference</span>
    <div fxLayout="row" fxLayoutGap="8px">
      <button mat-raised-button type="submit" color="primary" (click)="updateReference()">Save</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
    </div>
  </div>
</section>
<mat-dialog-content>
  <form [formGroup]="dateForm" (ngSubmit)="updateReference()">
    <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
      <mat-form-field>
        <textarea formControlName="notes" matInput rows="3" placeholder="Notes" type="text"></textarea>
      </mat-form-field>
      <span>Resolved On</span>
      <mat-form-field>
        <input placeholder="mm/dd/yyyy"
               matInput
               formControlName="resolvedDate"
               [matDatepicker]="datepickerResolvedDate"
               [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="datepickerResolvedDate"></mat-datepicker-toggle>
        <mat-datepicker #datepickerResolvedDate>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="this.dateForm.get('resolvedDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<section *ngIf="complianceRule.rule.userCreated">
  <mat-dialog-content>
    <form [formGroup]="ruleForm" class="m-t-24">
      <div fxLayout="column">

        <mat-form-field class="m-t-8">
          <mat-label>Sub-Section</mat-label>
          <input matInput placeholder="Sub-Section" formControlName="subSection">
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <mat-label>Segment Number</mat-label>
          <input matInput type="number" placeholder="Segment Number" formControlName="segmentNumber">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Segment</mat-label>
          <input matInput placeholder="Segment" formControlName="segment">

        </mat-form-field>

        <mat-form-field class="m-b-8">
          <mat-label>Summary</mat-label>
          <textarea formControlName="summary" matInput rows="3" placeholder="Summary" type="text"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Application</mat-label>
          <input matInput placeholder="Application" formControlName="application">

        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="questionServiceCenter" matInput rows="3" placeholder="Question Service Center" type="text"></textarea>
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="questionManufacturer" matInput rows="3" placeholder="Question Manufacturer" type="text"></textarea>
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="questionLaboratory" matInput rows="3" placeholder="Question Laboratory" type="text"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Answer</mat-label>
          <input matInput placeholder="Answer" formControlName="answer">
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="guidanceServiceCenter" matInput rows="3" placeholder="Guidance Service Center" type="text"></textarea>
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="guidanceManufacturer" matInput rows="3" placeholder="Guidance Manufacturer" type="text"></textarea>
        </mat-form-field>

        <mat-form-field class="m-t-16">
          <textarea formControlName="guidanceLaboratory" matInput rows="3" placeholder="Guidance Laboratory" type="text"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>If Non-Compliant</mat-label>
          <input matInput placeholder="If Non-Compliant" formControlName="nonCompliant">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Statute</mat-label>
          <input matInput placeholder="Statute" formControlName="statute">
        </mat-form-field>

        <mat-checkbox formControlName="monitorMetric">Metric</mat-checkbox>

      </div>
    </form>
  </mat-dialog-content>
</section>
