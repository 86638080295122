import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IInvestigationItem } from 'app/models/compliance/look-ups/investigation-item';
import { ISubSection } from 'app/models/compliance/look-ups/sub-section';
import { IRule } from 'app/models/compliance/rule';
import { ComplianceService } from 'app/services/compliance/compliance.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-manage-rules',
  templateUrl: './manage-rules.component.html',
  styleUrls: ['./manage-rules.component.scss']
})
export class ManageRulesComponent implements OnInit {
  public hideArchived: boolean = true;
  public displayCsv: boolean = true;
  public rules: IRule[] = [];

  public ruleColumns: string[] = ['section', 'subSection' ,'statute', 'summary', 'actions'];
  public ruleSource = new MatTableDataSource<IRule>(this.rules);
  @ViewChild('rulePaginator', {static: false}) rulePaginator: MatPaginator;
  @ViewChild('ruleSort', {static: false}) ruleSort: MatSort;

  constructor(public sharedService: SharedService,
              private dialog: MatDialog,
              public complianceService: ComplianceService) { }

  ngOnInit(): void {
    this.complianceService.getRules("all").subscribe(
      response => this.rules = response,
      error => console.log('error', error),
      () => {
        this.updateRulesTable();
      }
    );
  }

  updateRulesTable(): void {
    if(this.hideArchived){
      this.ruleSource.data = this.rules.filter(r => !r.archived);
    }
    else{
      this.ruleSource.data = this.rules;
    }
    this.ruleSource.paginator = this.rulePaginator;
    this.ruleSource.sort = this.ruleSort;
  }

  archive(rule: IRule, archived: boolean): void {
    rule.archived = archived;
    this.complianceService.updateRule(rule).subscribe(
      response => rule = response,
      error => console.log('error', error),
      () => this.updateRulesTable()
    );
  }

  displayArchived(): void {
    this.hideArchived = !this.hideArchived;
    this.updateRulesTable();
  }

  filterTable(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.ruleSource.filter = filter;
  }

  uploadRules(event: Event): void {
    this.sharedService.openConfirm("Upload rules via CSV?");
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        let dirtyFile = (event.target as HTMLInputElement).files[0];
        let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
        const formData = new FormData();
        formData.append("file", file, file.name);
        this.complianceService.uploadRules(formData).subscribe
        (
          response => this.rules = response,
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error)
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.updateRulesTable()
          }
        );
      }
    });
  }

  addRule(): void {
    let rule: IRule = {
      id: 0,
      general: false,
      trainingPrograms: false,
      facilitators: false,
      manufacturers: false,
      serviceCenters: false,
      testingLabs: false,
      workerPermits: false,
      subSection: '',
      segmentNumber: 0,
      segment: '',
      summary: '',
      application: '',
      questionServiceCenter: '',
      questionManufacturer: '',
      questionLaboratory: '',
      answer: '',
      guidanceServiceCenter: '',
      guidanceManufacturer: '',
      guidanceLaboratory: '',
      nonCompliant: '',
      statute: '',
      monitorMetric: false,
      notes: '',
      archived: false,
      historicNonCompliances: [],
      investigationItems: [],
      userCreated: false
    }

    const dialogRef = this.dialog.open(AddRuleDialogComponent, {
      data: rule,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.rules.push(response);
        this.updateRulesTable();
      }
    });
  }

  editRule(rule: IRule): void {
    const dialogRef = this.dialog.open(AddRuleDialogComponent, {
      data: rule,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        rule = response;
        this.updateRulesTable();
      }
    });
  }

  viewRuleDetails(rule: IRule): void {
    this.dialog.open(ViewRuleDialogComponent, {
      data: rule,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

}

@Component({
  selector: 'add-rule-dialog',
  templateUrl: 'dialog-add-rule.html'
})

export class AddRuleDialogComponent implements OnInit {
  public subSections: ISubSection[] = [];
  public investigationItems: IInvestigationItem[] = [];
  public currentItems: number[] = [];

  public ruleForm = new UntypedFormGroup({
    general: new UntypedFormControl(false),
    trainingPrograms: new UntypedFormControl(false),
    facilitators: new UntypedFormControl(false),
    manufacturers: new UntypedFormControl(false),
    serviceCenters: new UntypedFormControl(false),
    testingLabs: new UntypedFormControl(false),
    workerPermits: new UntypedFormControl(false),
    subSection: new UntypedFormControl('', Validators.required),
    segmentNumber: new UntypedFormControl(0, Validators.required),
    segment: new UntypedFormControl('', Validators.required),
    summary: new UntypedFormControl('', Validators.required),
    application: new UntypedFormControl('', Validators.required),
    questionServiceCenter: new UntypedFormControl(''),
    questionManufacturer: new UntypedFormControl(''),
    questionLaboratory: new UntypedFormControl(''),
    answer: new UntypedFormControl(''),
    guidanceServiceCenter: new UntypedFormControl(''),
    guidanceManufacturer: new UntypedFormControl(''),
    guidanceLaboratory: new UntypedFormControl(''),
    nonCompliant: new UntypedFormControl(''),
    statute: new UntypedFormControl('', Validators.required),
    monitorMetric: new UntypedFormControl(false),
    notes: new UntypedFormControl('')
  });

  constructor(public dialogRef: MatDialogRef<AddRuleDialogComponent>,
              public sharedService: SharedService,
              public complianceService: ComplianceService,
              @Inject(MAT_DIALOG_DATA) public rule: IRule) { }

  ngOnInit(): void {
    this.currentItems = this.rule.investigationItems.map(item => {return item.id});
    this.complianceService.getLookUps(false, this.sharedService.subSection).subscribe(
      response => this.subSections = response,
      error => console.log('error', error)
    );
    this.complianceService.getLookUps(false, this.sharedService.investigationItem).subscribe(
      response => this.investigationItems = response,
      error => console.log('error', error)
    );
    if(this.rule.id !== 0){
      this.ruleForm.patchValue({
        general: this.rule.general,
        trainingPrograms: this.rule.trainingPrograms,
        facilitators: this.rule.facilitators,
        manufacturers: this.rule.manufacturers,
        serviceCenters: this.rule.serviceCenters,
        testingLabs: this.rule.testingLabs,
        workerPermits: this.rule.workerPermits,
        subSection: this.rule.subSection,
        segmentNumber: this.rule.segmentNumber,
        segment: this.rule.segment,
        summary: this.rule.summary,
        application: this.rule.application,
        questionServiceCenter: this.rule.questionServiceCenter,
        questionManufacturer: this.rule.questionManufacturer,
        questionLaboratory: this.rule.questionLaboratory,
        answer: this.rule.answer,
        guidanceServiceCenter: this.rule.guidanceServiceCenter,
        guidanceManufacturer: this.rule.guidanceManufacturer,
        guidanceLaboratory: this.rule.guidanceLaboratory,
        nonCompliant: this.rule.nonCompliant,
        statute: this.rule.statute,
        monitorMetric: this.rule.monitorMetric,
        notes: this.rule.notes
      });
    }
  }

  updateRule(): void {
    let form = this.ruleForm.value;
    this.rule.general = form.general,
    this.rule.trainingPrograms = form.trainingPrograms,
    this.rule.facilitators = form.facilitators,
    this.rule.manufacturers = form.manufacturers,
    this.rule.serviceCenters = form.serviceCenters,
    this.rule.testingLabs = form.testingLabs,
    this.rule.workerPermits = form.workerPermits,
    this.rule.subSection = form.subSection,
    this.rule.segmentNumber = form.segmentNumber,
    this.rule.segment = form.segment,
    this.rule.summary = form.summary,
    this.rule.application = form.application,
    this.rule.questionServiceCenter = form.questionServiceCenter,
    this.rule.questionManufacturer = form.questionManufacturer,
    this.rule.questionLaboratory = form.questionLaboratory,
    this.rule.answer = form.answer,
    this.rule.guidanceServiceCenter = form.guidanceServiceCenter,
    this.rule.guidanceManufacturer = form.guidanceManufacturer,
    this.rule.guidanceLaboratory = form.guidanceLaboratory,
    this.rule.nonCompliant = form.nonCompliant,
    this.rule.statute = form.statute,
    this.rule.monitorMetric = form.monitorMetric,
    this.rule.notes = form.notes
    this.rule.investigationItems = this.investigationItems.filter(item => this.currentItems.includes(item.id));
    this.complianceService.updateRule(this.rule).subscribe(
      response => this.rule = response,
      error => console.log('error', error),
      () => this.dialogRef.close(this.rule)
    );
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}

@Component({
  selector: 'rule-details',
  templateUrl: 'dialog-view-rule.html'
})

export class ViewRuleDialogComponent {
  constructor(public dialogRef: MatDialogRef<ViewRuleDialogComponent>,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public rule: IRule) { }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
