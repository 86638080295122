import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ICompliancePlan } from "app/models/compliance-plan/compliance-plan";
import { ICompliancePlanRules } from "app/models/compliance-plan/compliance-plan-rule";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})

export class CompliancePlanService {
  private readonly localStorageKey = 'myCachedRequests';
  private api: string = environment.privateApi + 'CompliancePlan/';

  constructor(private http: HttpClient) {
    window.addEventListener('online', () => this.sendCachedRequests());
   }

  createCompliancePlan(compliance: any): Observable<ICompliancePlan>{
    let extension: string = 'CreateCompliancePlan';
    if (navigator.onLine) {
      return this.http.post<ICompliancePlan>(this.api + "CreateCompliancePlan", compliance);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliance, 'type': 'compliancePlan'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliance);
        observer.complete();
      });
    }
  }

  getCompliancePlans(caseId: number): Observable<ICompliancePlan[]>{
    return this.http.get<ICompliancePlan[]>(this.api + "GetCompliancePlans/" + caseId);
  }

  updateCompliancePlan(compliancePlan: any): Observable<ICompliancePlan> {
    let extension: string = 'UpdateCompliancePlan';
    if (navigator.onLine) {
      return this.http.post<ICompliancePlan>(this.api + 'UpdateCompliancePlan', compliancePlan);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliancePlan, 'type': 'compliancePlan'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliancePlan);
        observer.complete();
      });
    }
  }

  addRules(compliancePlanRules: any, id:number): Observable<ICompliancePlanRules[]> {
    let extension: string = 'addRules/' + id;
    if (navigator.onLine) {
      return this.http.post<ICompliancePlanRules[]>(this.api + 'addRules/' + id, compliancePlanRules);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliancePlanRules, 'type': 'compliancePlan'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliancePlanRules);
        observer.complete();
      });
    }
  }

  deleteCompliancePlan(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteCompliancePlan/" + id);
  }

  sendCachedRequests(): void {
    if (navigator.onLine) {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
        Object.keys(cachedRequests).forEach(extension => {
          const request = cachedRequests[extension];
          if(request['type'] == 'compliancePlan')
          {
                this.http.post(this.api + extension, request['data']).subscribe(() => {
                  delete cachedRequests[extension];
                  localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
                });
          }
        });
        Object.keys(cachedRequests).forEach(extension => {
          delete cachedRequests[extension];
        });
      }
    }
}
