import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IResidentialHistory } from 'app/models/licenses/residential-history';
import { Observable } from 'rxjs';
import { IDocument } from 'app/models/documents/document';
import { IApplicant } from 'app/models/licenses/applicant';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';
import { IApplicantSearch } from 'app/models/licenses/applicant-search/applicant-search';
import { IApplicantSearchResult } from 'app/models/licenses/applicant-search/applicant-search-result';
import { IApplicantDetails } from 'app/models/licenses/applicant-search/applicant-details';
import { IDocumentType } from 'app/models/documents/documentType';
import { IEntitySearchResult } from 'app/models/licenses/applicant-search/entity-search-result';
import { IEntityDetails } from 'app/models/licenses/applicant-search/entity-details';

@Injectable({providedIn: 'root'})
export class ApplicantService {
  private api: string = environment.privateApi + 'Applicant/';

  constructor(private http: HttpClient) { }
  //DeDuplicating

  createParentApplicant(parentApplicant: IApplicant): Observable<IApplicant>{
    return this.http.post<IApplicant>(this.api + 'CreateParentApplicant', parentApplicant);
  }

  markAsDuplicate(duplicateApplicants: IApplicantSearchResult[], parentId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'MarkAsDuplicate/' + parentId, duplicateApplicants);
  }

  linkToParent(currentId: number, parentId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'LinkToParent/' + currentId + '/' + parentId, null);
  }

  getChildrenApplicants(applicantId: number): Observable<IApplicantDetails[]>{
    return this.http.get<IApplicantDetails[]>(this.api + 'GetChildrenApplicants/' + applicantId)
  }

  removeDuplicate(applicantId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'RemoveDuplicate/' + applicantId, null);
  }

  updateParentApplicant(applicant: IApplicant): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'UpdateParentApplicant', applicant);
  }

  //Individual Applicants
  getApplicant(applicantId: number): Observable<IApplicant>{
    return this.http.get<IApplicant>(this.api + 'GetApplicant/' + applicantId);
  }

  checkDuplicates(applicantId: number): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckForDuplicate/' + applicantId);
  }

  getApplicants(licenseType: string, licenseId: number): Observable<IApplicant[]>{
    return this.http.get<IApplicant[]>(this.api + 'GetApplicants/' + licenseType + '/' + licenseId);
  }

  createApplicant(licenseType: string, licenseId: number): Observable<IApplicant>{
    return this.http.post<IApplicant>(this.api + 'CreateApplicant/' + licenseType + '/' + licenseId, null);
  }

  editApplicant(applicant: IApplicant, licenseType: string, licenseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'EditApplicant/' + licenseType + '/' + licenseId, applicant);
  }

  deleteApplicant(id: number, licenseId: number, licenseType: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteApplicant/' + id + '/' + licenseId + '/' + licenseType);
  }

  archiveApplicant(id: number, archive: boolean): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'ArchiveApplicant/' + id + '/' + archive, null);
  }

  //EntityApplicants
  getEntityApplicants(licenseType: string, licenseId: number): Observable<IEntityApplicant[]>{
    return this.http.get<IEntityApplicant[]>(this.api + 'GetEntityApplicants/' + licenseType + '/' + licenseId);
  }

  getAssociatedApplicants(applicantId: number): Observable<IApplicant[]>{
    return this.http.get<IApplicant[]>(this.api + 'GetAssociatedApplicants/' + applicantId);
  }

  createEntityApplicant(licenseType: string, licenseId: number): Observable<IEntityApplicant>{
    return this.http.post<IEntityApplicant>(this.api + 'CreateEntityApplicant/' + licenseType + '/' + licenseId, null);
  }

  editEntityApplicant(applicant: IEntityApplicant,licenseType: string, licenseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'EditEntityApplicant/' + licenseType + '/' + licenseId, applicant);
  }

  addIndividualApplicant(entityId: number, applicantId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AddIndividualApplicant/' + entityId + '/' + applicantId, null);
  }

  deleteIndividualApplicant(entityId: number, applicantId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteIndividualApplicant/' + entityId + '/' + applicantId);
  }

  deleteEntityApplicant(id: number, licenseType: string, licenseId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteEntityApplicant/' + id + '/' + licenseType + '/' + licenseId);
  }

  archiveEntityApplicant(id: number, archive: boolean): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'ArchiveEntityApplicant/' + id + '/' + archive, null);
  }
  //Documents
  uploadDocument(files: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument', files);
  }

  deprecateDocument(id: number): Observable<any>{
    return this.http.post<any>(this.api + "DeprecateDocument/" + id, null);
  }

  uploadEntityDocument(files: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadEntityDocument', files);
  }

  downloadFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  deleteDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id);
  }

  //Residential History
  updateResidentialHistory(residence: IResidentialHistory): Observable<IResidentialHistory>{
    return this.http.post<IResidentialHistory>(this.api + 'UpdateResidentialHistory', residence);
  }

  deleteResidentialHistory(id: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteResidentialHistory/' + id);
  }

  //Applicant Search
  searchApplicants(search: IApplicantSearch): Observable<IApplicantSearchResult[]> {
    return this.http.post<IApplicantSearchResult[]>(this.api + 'SearchApplicants', search);
  }

  searchEntityApplicants(search: IApplicantSearch): Observable<IEntitySearchResult[]> {
    return this.http.post<IEntitySearchResult[]>(this.api + 'SearchEntityApplicants', search);
  }

  getApplicantDetails(id: number): Observable<IApplicantDetails>{
    return this.http.get<IApplicantDetails>(this.api + 'GetApplicantDetails/' + id);
  }

  getEntityApplicantDetails(id: number): Observable<IEntityDetails>{
    return this.http.get<IEntityDetails>(this.api + 'GetEntityDetails/' + id);
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }
}
