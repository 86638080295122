import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/services/core/shared.service';
import { ExamService } from 'app/services/licenses/facilitator/exam/exam.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { IExamAnswer } from 'app/models/licenses/facilitator/exam/exam-answer';
import { IExamQuestion } from 'app/models/licenses/facilitator/exam/exam-question';

@Component({
  selector: 'app-manage-curriculum',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {
  public examMap: IExamQuestion[] = [];
  public unModifiedMap: IExamQuestion[] = [];

  public editingExamQuestions: boolean = false;
  public coreText: string = '';
  public subText: string = '';
  public correct: boolean = false;
  public examValid: boolean = true;

  public addingCoreElement: boolean = false;
  public showArchived: boolean = false;
  private letterArray: string[] = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'zz',
    'aa', 'bb', 'cc', 'dd', 'ee', 'ff', 'gg', 'hh', 'ii', 'jj', 'kk', 'll', 'mm', 'nn', 'oo', 'pp', 'qq', 'rr', 'ss', 'tt', 'uu', 'vv', 'ww', 'xx', 'yy', 'zz'
  ]

  constructor(public sharedService: SharedService,
              private examService: ExamService) { }

  ngOnInit(): void {
    this.examService.getExamMap().subscribe(
      response => {
        this.examMap = response;
      },
      error => console.log('error', error)
    );
  }

  getIndexNumber(index: number) {
    if(this.examMap[index].archived){
      return this.sharedService.archivedIndicator;
    }
    //determine if there are any archived elements prior to this element
    let archivedElements = this.examMap.slice(0, index).filter(f => f.archived).length;
    return ((index + 1) - archivedElements) + "";
  }

  getAnswerIndexLetter(elementIndex: number, answerIndex: number,  archived: boolean): String {
    if(archived) {
      return this.sharedService.archivedIndicator;
    }

    //determine if there are any archived elements prior to this element
    let archivedElements = this.examMap[elementIndex].examAnswers.slice(0, answerIndex).filter(f => f.archived).length;

    return this.letterArray[answerIndex - archivedElements];
  }

  toggleArchived() {
   this.showArchived = !this.showArchived;
  }

  setCorrect(question: IExamQuestion, answer: IExamAnswer){
    question.examAnswers.forEach(a => a.correct = false);
    answer.correct = true;
  }

  saveQuiz(): void {
    this.sharedService.openConfirm("Update the Quiz?");
    this.sharedService.confirmed().subscribe( confirmed => {
      if(confirmed){
        if(this.validateExam())
        {
          this.examValid = true;
          this.updateExamMap();
        }
        else{
          this.examValid = false;
        }
      }
    });
  }

  validateExam(): boolean{
    let validated: boolean = true;
    this.examMap.forEach(question => {
      let numQuestions = question.examAnswers.length;
      let hasCorrect = question.examAnswers.some(answer => answer.correct);
      if(!hasCorrect || numQuestions < 2)
      {
        validated = false;
      }
    })
    return validated;
  }

  updateExamMap(): void {
    if(this.validateExam())
    {
      this.examValid = true;
      this.examService.updateExamMap(this.examMap).subscribe(
        response => this.examMap = response,
        error => console.log('error', error),
        () => this.editingExamQuestions = false);
    }
    else
      this.examValid = false;
  }

  toggleQuizEdit(): void{
    if(!this.editingExamQuestions){
      this.unModifiedMap = JSON.parse(JSON.stringify(this.examMap));
      this.editingExamQuestions = !this.editingExamQuestions;
    }
    else{
      this.sharedService.openConfirm("Any unsaved changes will be lost, continue?");
      this.sharedService.confirmed().subscribe( confirmed => {
        if(confirmed){
          this.examMap = JSON.parse(JSON.stringify(this.unModifiedMap));
          this.editingExamQuestions = !this.editingExamQuestions;
          this.examValid = true;
        }
      }
      );
    }
  }

  //Core Element Management
  toggleQuestionAddForm(): void{
    this.addingCoreElement = !this.addingCoreElement;
  }

  addCoreElement(): void{
    let element: IExamQuestion = {
      text: this.coreText,
      addingAnswer: false,
      examAnswers: [],
      archived: false
    }
    this.examMap.push(element);
    this.coreText = '';
    this.toggleQuestionAddForm();
  }

  deleteQuiz(element: IExamQuestion): void {
    this.sharedService.openConfirm(`Do you want to delete this question? It will delete the question as well as all relevant answers.
    All user data associated with this section will be lost!
    We encourage you to use the archive feature instead.`);
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.examMap = this.examMap.filter(cm => cm !== element);
        }
      }
    );
  }

  toggleArchiveQuestion(element: IExamQuestion): void {
    let action = element.archived ? "unarchive" : "archive";
    this.sharedService.openConfirm("Do you want to " + action + " this question?  ");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          element.archived = !element.archived;
        }
      }
    );
  }

  dropQuestion(event: CdkDragDrop<IExamQuestion[]>) {
    this.sharedService.openConfirm("This action will automatically save the quiz. Continue?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          moveItemInArray(this.examMap, event.previousIndex, event.currentIndex);
          this.updateExamMap();
        }
      }
    );
  }

  //Answer Management
  toggleAnswerAddForm(element: IExamQuestion, toggle: boolean): void {
    element.addingAnswer = toggle;
  }

  addAnswer(element: IExamQuestion): void{
    let answer: IExamAnswer = {
      referenceId: '',
      text: this.subText,
      correct: false,
      archived: false
    }
    element.examAnswers.push(answer);
    element.addingAnswer = false;
    this.subText = '';
  }

  deleteAnswer(element: IExamQuestion, answer: IExamAnswer): void {
    this.sharedService.openConfirm(`Do you want to delete this Answer?
    All user data associated with this Answer will be lost!
    We encourage you to use the archive feature instead.`);
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          element.examAnswers = element.examAnswers.filter(ss => ss !== answer);
        }
      }
    );
  }

  toggleArchiveAnswer(answer: IExamAnswer): void {
    let action = answer.archived ? "unarchive" : "archive";
    this.sharedService.openConfirm("Do you want to " + action + " this Answer? ");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          answer.archived = !answer.archived;
        }
      }
    );
  }

  dropAnswer(event: CdkDragDrop<IExamAnswer[]>, answers: IExamAnswer[]) {
    this.sharedService.openConfirm("This action will automatically save the quiz. Continue?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          moveItemInArray(answers, event.previousIndex, event.currentIndex);
          this.updateExamMap();
        }
      }
    );
  }
}
