import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManufacturerRoutingModule } from './manufacturer-routing.module';
import { SharedModule } from '@shared';
import { AssignedManufacturersComponent } from './assigned-manufacturers/assigned-manufacturers.component';
import { ManufacturerDetailsDialog, SearchManufacturersComponent } from './search-manufacturers/search-manufacturers.component';
import { ManufacturerComplaintsComponent } from './manufacturer-complaints/manufacturer-complaints.component';
import { ManufacturerTasksComponent } from './manufacturer-tasks/manufacturer-tasks.component';
import { ApplicantModule } from '../applicant/applicant.module';
import { NotesModule } from '../notes/notes.module';
import { PaymentsModule } from '../payments/paymentsmodule';
import { ComplaintModule } from '../complaint/complaint.module';
import { ComplianceModule } from '../compliance/compliance.module';
import { SiteInspectionModule } from '../site-inspection/site-inspection.module';
import { LicenseChecklistModule } from '../license-checklist/license-checklist.module';
import { PrintManufacturerComponent } from './print-manufacturers/print-manufacturers.component';
import { ManagerNotesModule } from '../manager-notes/manager-notes.module';
import { ReportedEventModule } from '../reported-event/reported-event.module';
import { AssociatedWorkerPermitsModule } from '../associated-worker-permits/associated-worker-permits.module';

@NgModule({
  declarations: [
    AssignedManufacturersComponent,
    SearchManufacturersComponent,
    ManufacturerComplaintsComponent,
    ManufacturerTasksComponent,
    PrintManufacturerComponent,
    ManufacturerDetailsDialog
  ],
  imports: [
    CommonModule,
    SharedModule,
    ManufacturerRoutingModule,
    PaymentsModule,
    NotesModule,
    ApplicantModule,
    ComplianceModule,
    ComplaintModule,
    SiteInspectionModule,
    LicenseChecklistModule,
    ManagerNotesModule,
    AssociatedWorkerPermitsModule,
    ReportedEventModule
  ]
})
export class ManufacturerModule { }
