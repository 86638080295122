import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { IPropertyNamesWithInfo } from 'app/models/audit-logging/audit-logging';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { ToastrService } from 'ngx-toastr';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-entity-applicant',
  templateUrl: './entity-applicant.component.html',
  styleUrls: ['./entity-applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})
export class EntityApplicantComponent implements OnInit {
  @Input() licenseId: number = 0;
  @Input() licenseType: string = '';
  @Input() cardColor: string = '';
  @Input() tableColor: string = '';
  @Input() editingLicense: boolean = false;
  @Input() documentTypes: IDocumentType[] = [];
  @Input() individualApplicants: IApplicant[] = [];
  @Input() renewal: boolean = false;
  @Input() renewalChanges: IPropertyNamesWithInfo[] = [];
  @Input() admin: boolean = false;
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public applicants: IEntityApplicant[] = [];
  public applicant: IEntityApplicant = {
    id: 0,
    entityName: '',
    registeredBusiness: false,
    registryNumber: '',
    registeredIn: '',
    businessInOregon: false,
    secretaryofStateNumber: '',
    registrationAttestation: false,
    oregonResidency: false,
    headquartersState: '',
    headquartersCity: '',
    headquartersStreet: '',
    headquartersZip: '',
    headquartersCounty: '',
    physicalIsHeadquarters: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingIsHeadquarters: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    applicantDocuments: [],
    applicants: [],
    applicantPageCorrect: null,
    applicantPageCorrectReason: '',
    archived: false
  }
  public addingApplicant: boolean = false;
  public documentsValid: boolean = true;
  public applicantInfoType: number = 0;
  public viewArchived: boolean = false;
  public applicantInfo: string = 'Applicant Information';
  public applicantForm = new UntypedFormGroup({
    entityName: new UntypedFormControl("", [Validators.required]),
    businessInOregon: new UntypedFormControl(),
    registrationAttestation: new UntypedFormControl(false, Validators.required),
    registryNumber: new UntypedFormControl(''),
    registeredBusiness: new UntypedFormControl(),
    secretaryofStateNumber: new UntypedFormControl(''),
    registeredIn: new UntypedFormControl(''),
    oregonResidency: new UntypedFormControl(false),
    headquartersStreet: new UntypedFormControl("", [Validators.required]),
    headquartersCity: new UntypedFormControl("", [Validators.required]),
    headquartersState: new UntypedFormControl("", [Validators.required]),
    headquartersZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    headquartersCounty: new UntypedFormControl(""),
    physicalIsHeadquarters: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsHeadquarters: new UntypedFormControl(false),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    primaryContactNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    primaryContactEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl(''),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    language: new UntypedFormControl(''),
    applicantPageCorrect: new UntypedFormControl(),
    applicantPageCorrectReason: new UntypedFormControl(''),
    }, {
      validators: [
        this.physicalStreetValidator(),
        this.physicalCityValidator(),
        this.physicalStateValidator(),
        this.physicalZipValidator(),
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.previousLicenseValidator(),
        this.emailValidator()
      ]
    }
  );

  public dataColumns: string[] = ['entityName', 'primaryContact', 'actions'];
  public dataSource = new MatTableDataSource<IEntityApplicant>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  public applicantColumns: string[] = ['firstName', 'lastName', 'actions'];
  public applicantSource = new MatTableDataSource<IApplicant>(this.individualApplicants);
  @ViewChild('applicantPaginator', {static: false}) applicantPaginator: MatPaginator;
  @ViewChild('applicantSort', {static: false}) applicantSort: MatSort;

  public associatedApplicantSource = new MatTableDataSource<IApplicant>(this.applicant.applicants);
  @ViewChild('associatedApplicantPaginator', {static: false}) associatedApplicantPaginator: MatPaginator;
  @ViewChild('associatedApplicantSort', {static: false}) associatedApplicantSort: MatSort;

  constructor(public sharedService: SharedService,
              public applicantService: ApplicantService,
              public toastr: ToastrService,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.applicantColumns = ['firstName', 'lastName', 'actions'];
    this.getApplicants();
    this.setupDocumentTypeReference();
  }

  updateIndividualApplicantTable(): void {
    this.applicantSource.data = this.individualApplicants;
    this.applicantSource.sort = this.applicantSort;
    this.applicantSource.paginator = this.applicantPaginator;
  }

  updateAssociatedApplicantTable(): void {
    this.associatedApplicantSource.data = this.applicant.applicants;
    this.associatedApplicantSource.sort = this.associatedApplicantSort;
    this.associatedApplicantSource.paginator = this.associatedApplicantPaginator;
  }

  setupDocumentTypeReference(): void {
    this.applicantInfoType = this.documentTypes.find(dt => dt.type === this.applicantInfo).id;
  }

  getApplicants(): void {
    this.applicantService.getEntityApplicants(this.licenseType, this.licenseId).subscribe(
      response => this.applicants = response,
      error => console.log('error', error),
      () => {
        this.updateApplicantTable();
      }
    );
  }

  updateApplicantTable(): void{
    this.dataSource.data = this.applicants;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  filterTable(event: Event){
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.dataSource.filter = filter;
  }

  addApplicant(): void {
    this.addingApplicant = true;
    this.applicantService.createEntityApplicant(this.licenseType, this.licenseId).subscribe(
      response => this.applicant = response,
      error => console.log('error', error),
      () => {
        this.applicants.push(this.applicant);
        this.applicantForm.markAsUntouched();
        this.applicantForm.markAsPristine();
        this.notifyParent.emit(true);
        setTimeout(() => this.updateIndividualApplicantTable(), 100);
      }
    );
  }

  editApplicant(applicant: IEntityApplicant): void {
    this.applicant = applicant;
    this.applicantService.getAssociatedApplicants(this.applicant.id).subscribe(
      response => this.applicant.applicants = response,
      error => console.log('error', error),
      () => {
        this.updateApplicantForm();
        if(this.applicant.archived)
          this.applicantForm.disable();
        this.notifyParent.emit(true);
        setTimeout(() => {
          this.updateIndividualApplicantTable();
          this.updateAssociatedApplicantTable();
        }, 100);
      }
    );
  }

  getEmptyApplicant(): IEntityApplicant {
    let emptyApplicant: IEntityApplicant = {
      id: 0,
      entityName: '',
      registeredBusiness: false,
      businessInOregon: false,
      secretaryofStateNumber: '',
      registrationAttestation: false,
      registryNumber: '',
      registeredIn: '',
      oregonResidency: false,
      headquartersState: '',
      headquartersCity: '',
      headquartersStreet: '',
      headquartersZip: '',
      headquartersCounty: '',
      physicalIsHeadquarters: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingIsHeadquarters: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      primaryContactName: '',
      primaryContactNumber: '',
      primaryContactEmail: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      applicantDocuments: [],
      applicants: [],
      applicantPageCorrect: null,
      applicantPageCorrectReason: '',
      archived: false
    }
    return emptyApplicant;
  }

  saveApplicant(close: boolean): void {
    this.updateApplicant();
    this.applicantService.editEntityApplicant(this.applicant, this.licenseType, this.licenseId).subscribe(
      () => {
        this.addingApplicant = false;
        let index = this.applicants.findIndex(a => a.id === this.applicant.id);
        this.applicants[index] = this.applicant;
        if(close){
          this.cancel();
        }
      },
      error => console.log('error', error)
    );
  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    this.applicant.entityName = applicantInfo.entityName;
    this.applicant.businessInOregon = applicantInfo.businessInOregon;
    this.applicant.registrationAttestation = applicantInfo.registrationAttestation;
    this.applicant.registryNumber = applicantInfo.registryNumber;
    this.applicant.registeredBusiness = applicantInfo.registeredBusiness
    this.applicant.secretaryofStateNumber = applicantInfo.secretaryofStateNumber;
    this.applicant.registeredIn = applicantInfo.registeredIn;
    this.applicant.oregonResidency = applicantInfo.oregonResidency;
    this.applicant.headquartersState = applicantInfo.headquartersState;
    this.applicant.headquartersCity = applicantInfo.headquartersCity;
    this.applicant.headquartersStreet = applicantInfo.headquartersStreet;
    this.applicant.headquartersZip = applicantInfo.headquartersZip;
    this.applicant.headquartersCounty = applicantInfo.headquartersCounty;
    this.applicant.physicalIsHeadquarters = applicantInfo.physicalIsHeadquarters;
    if (applicantInfo.physicalIsHeadquarters) {
      this.applicant.physicalState = applicantInfo.headquartersState;
      this.applicant.physicalCity = applicantInfo.headquartersCity;
      this.applicant.physicalStreet = applicantInfo.headquartersStreet;
      this.applicant.physicalZip = applicantInfo.headquartersZip;
      this.applicant.physicalCounty = applicantInfo.headquartersCounty;
      this.applicantForm.patchValue({
        physicalState: applicantInfo.headquartersState,
        physicalCity: applicantInfo.headquartersCity,
        physicalStreet: applicantInfo.headquartersStreet,
        physicalZip: applicantInfo.headquartersZip,
        physicalCounty: applicantInfo.headquartersCounty
      });
    }
    else {
      this.applicant.physicalState = applicantInfo.physicalState;
      this.applicant.physicalCity = applicantInfo.physicalCity;
      this.applicant.physicalStreet = applicantInfo.physicalStreet;
      this.applicant.physicalZip = applicantInfo.physicalZip;
      this.applicant.physicalCounty = applicantInfo.physicalCounty;
    }
    this.applicant.mailingIsHeadquarters = applicantInfo.mailingIsHeadquarters;
    this.applicant.mailingIsPhysical = applicantInfo.mailingIsPhysical;
    if (applicantInfo.mailingIsHeadquarters) {
      this.applicant.mailingState = applicantInfo.headquartersState;
      this.applicant.mailingCity = applicantInfo.headquartersCity;
      this.applicant.mailingStreet = applicantInfo.headquartersStreet;
      this.applicant.mailingZip = applicantInfo.headquartersZip;
      this.applicant.mailingCounty = applicantInfo.headquartersCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.headquartersState,
        mailingCity: applicantInfo.headquartersCity,
        mailingStreet: applicantInfo.headquartersStreet,
        mailingZip: applicantInfo.headquartersZip,
        mailingCounty: applicantInfo.headquartersCounty
      });
    }
    else if (applicantInfo.mailingIsPhysical) {
      this.applicant.mailingState = applicantInfo.physicalState;
      this.applicant.mailingCity = applicantInfo.physicalCity;
      this.applicant.mailingStreet = applicantInfo.physicalStreet;
      this.applicant.mailingZip = applicantInfo.physicalZip;
      this.applicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
    else {
      this.applicant.mailingState = applicantInfo.mailingState;
      this.applicant.mailingCity = applicantInfo.mailingCity;
      this.applicant.mailingStreet = applicantInfo.mailingStreet;
      this.applicant.mailingZip = applicantInfo.mailingZip;
      this.applicant.mailingCounty = applicantInfo.mailingCounty;
    }
    this.applicant.primaryContactEmail = applicantInfo.primaryContactEmail;
    this.applicant.primaryContactNumber = applicantInfo.primaryContactNumber;
    this.applicant.previousLicense = applicantInfo.previousLicense;
    this.applicant.affiliatedLicenses = applicantInfo.affiliatedLicenses;
    this.applicant.language = applicantInfo.language;
    this.applicant.applicantPageCorrect = applicantInfo.applicantPageCorrect;
    this.applicant.applicantPageCorrectReason = applicantInfo.applicantPageCorrectReason;
  }

  cancel(): void {
    let applicantId: number = this.applicant.id;
    if(this.addingApplicant){
      this.sharedService.openConfirm("Your work will not be saved. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.applicantService.deleteEntityApplicant(applicantId, this.licenseType, this.licenseId).subscribe(
              () => {
                this.notifyParent.emit(false);
                this.applicants = this.applicants.filter(a => a.id !== applicantId);
                this.applicant = this.getEmptyApplicant();
                this.updateApplicantForm();
                setTimeout(() => {
                  this.updateApplicantTable();
                }, 500);
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }
    else{
      this.notifyParent.emit(false);
      this.applicant = this.getEmptyApplicant();
      this.updateApplicantForm();
      setTimeout(() => {
        this.updateApplicantTable();
      }, 500);
    }
  }

  updateApplicantForm(): void {
    this.applicantForm.patchValue({
      entityName: this.applicant.entityName,
      secretaryofStateNumber: this.applicant.secretaryofStateNumber,
      registeredBusiness: this.applicant.registeredBusiness,
      registeredIn: this.applicant.registeredIn,
      registryNumber: this.applicant.registryNumber,
      businessInOregon: this.applicant.businessInOregon,
      registrationAttestation: this.applicant.registrationAttestation,
      oregonResidency: this.applicant.oregonResidency,
      headquartersState: this.applicant.headquartersState,
      headquartersCity: this.applicant.headquartersCity,
      headquartersStreet: this.applicant.headquartersStreet,
      headquartersZip: this.applicant.headquartersZip,
      headquartersCounty: this.applicant.headquartersCounty,
      physicalIsHeadquarters: this.applicant.physicalIsHeadquarters,
      physicalStreet: this.applicant.physicalStreet,
      physicalCity: this.applicant.physicalCity,
      physicalState: this.applicant.physicalState,
      physicalZip: this.applicant.physicalZip,
      physicalCounty: this.applicant.physicalCounty,
      mailingIsHeadquarters: this.applicant.mailingIsHeadquarters,
      mailingIsPhysical: this.applicant.mailingIsPhysical,
      mailingStreet: this.applicant.mailingStreet,
      mailingCity: this.applicant.mailingCity,
      mailingState: this.applicant.mailingState,
      mailingZip: this.applicant.mailingZip,
      mailingCounty: this.applicant.mailingCounty,
      primaryContactEmail: this.applicant.primaryContactEmail,
      confirmEmail: this.applicant.primaryContactEmail,
      primaryContactNumber: this.applicant.primaryContactNumber,
      previousLicense: this.applicant.previousLicense,
      affiliatedLicenses: this.applicant.affiliatedLicenses,
      language: this.applicant.language,
      applicantPageCorrect: this.applicant.applicantPageCorrect,
      applicantPageCorrectReason: this.applicant.applicantPageCorrectReason,
    });
  }

  deleteApplicant(applicant: IEntityApplicant): void {
    this.sharedService.openConfirm("Delete " + applicant.entityName + "? " + "You will lose all records of this applicant.");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteEntityApplicant(applicant.id, this.licenseType, this.licenseId).subscribe(
            () => {
              this.applicants = this.applicants.filter(a => a.id !== applicant.id);
              this.updateApplicantTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  archiveApplicant(applicant: IEntityApplicant, archive: boolean): void {
    if(archive)
      this.sharedService.openConfirm("Archive " + applicant.entityName + "?");
    else
      this.sharedService.openConfirm("Unarchive " + applicant.entityName + "?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.archiveEntityApplicant(applicant.id, archive).subscribe(
            () => {
              this.applicants.find(a => a.id == applicant.id).archived = archive;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addIndividualApplicant(applicantId: number): void {
    this.applicantService.addIndividualApplicant(this.applicant.id, applicantId).subscribe(
      response => {
        if(response){
          this.applicant.applicants.push(this.individualApplicants.find(ia => ia.id === applicantId));
          this.filterIndividualApplicants();
          this.updateAssociatedApplicantTable();
        }
      },
      error => console.log('error', error)
    );
  }

  filterIndividualApplicants(): void {
    this.individualApplicants = this.individualApplicants.filter((applicant) => {
      return !this.applicant.applicants.some(a => a.id === applicant.id);
    });
    this.updateIndividualApplicantTable();
  }

  deleteIndividualApplicant(applicantId: number): void {
    this.sharedService.openConfirm('Remove applicant association?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteIndividualApplicant(this.applicant.id, applicantId).subscribe(
            response => {
              if(response){
                this.applicant.applicants = this.applicant.applicants.filter(a => a.id !== applicantId);
                this.updateAssociatedApplicantTable();
              }
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateDocuments(): void {
    this.documentsValid = this.applicant.applicantDocuments.length > 0;
  }

  deprecateDocument(id: number) {
    let archived = false;
    let currentDocumentIndex = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
    archived = this.applicant.applicantDocuments[currentDocumentIndex].deprecated;

    this.sharedService.openConfirm(archived ? "Unarchive Document?" : "Archive Document?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deprecateDocument(id).subscribe(
            () => {
                let index = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
                this.applicant.applicantDocuments[index].deprecated = !this.applicant.applicantDocuments[index].deprecated;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  viewArchivedDocuments(): void {
    this.viewArchived = !this.viewArchived;
  }

  uploadDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.applicant.id,
      types: [this.applicantInfoType],
      deprecated: false,
      pendingUpdate: false,
      adminOnly: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.applicantService.uploadEntityDocument(formData).subscribe(
      response => this.applicant.applicantDocuments.unshift(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.validateDocuments();
      }
    );

  }

  downloadDocument(fileId: number, fileName: string): void {
    this.applicantService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteDocument(id).subscribe(
            () => {
              this.applicant.applicantDocuments = this.applicant.applicantDocuments.filter(item => item.id !== id);
              this.validateDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  isFieldUpdated(propertyName: string, tableName: string, keyValue: string): boolean {
    return this.renewalChanges.some(field =>
      field.propertyName === propertyName &&
      field.tableName === tableName &&
      field.keyValue === keyValue
    );
  }

  //Applicant Custom Validators
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.primaryContactEmail;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  physicalIsSame(): void {
    this.applicantForm.get('physicalStreet').updateValueAndValidity();
    this.applicantForm.get('physicalCity').updateValueAndValidity();
    this.applicantForm.get('physicalState').updateValueAndValidity();
    this.applicantForm.get('physicalZip').updateValueAndValidity();
  }

  physicalStreetValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.physicalStreet;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (street !== null && street !== '' && street !== undefined) ? null : { physicalStreetRequired: true };
      }
      return null;
    };
  }

  validatePhysicalStreet(): boolean{
    if (this.applicantForm.hasError('physicalStreetRequired') && this.applicantForm.get('physicalStreet').touched) {
      this.applicantForm.get('physicalStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalStreet').clearValidators();
    this.applicantForm.get('physicalStreet').updateValueAndValidity();
    return false;
  }

  physicalCityValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.physicalCity;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (city !== null && city !== '' && city !== undefined) ? null : { physicalCityRequired: true };
      }
      return null;
    };
  }

  validatePhysicalCity(): boolean{
    if (this.applicantForm.hasError('physicalCityRequired') && this.applicantForm.get('physicalCity').touched) {
      this.applicantForm.get('physicalCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalCity').clearValidators();
    this.applicantForm.get('physicalCity').updateValueAndValidity();
    return false;
  }

  physicalStateValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.physicalState;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (state !== null && state !== '' && state !== undefined) ? null : { physicalStateRequired: true };
      }
      return null;
    };
  }

  validatePhysicalState(): boolean{
    if (this.applicantForm.hasError('physicalStateRequired') && this.applicantForm.get('physicalState').touched) {
      this.applicantForm.get('physicalState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalState').clearValidators();
    this.applicantForm.get('physicalState').updateValueAndValidity();
    return false;
  }

  physicalZipValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.physicalZip;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { physicalZipRequired: true };
      }
      return null;
    };
  }

  validatePhysicalZip(): boolean{
    if (this.applicantForm.hasError('physicalZipRequired') && this.applicantForm.get('physicalZip').touched) {
      this.applicantForm.get('physicalZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalZip').clearValidators();
    this.applicantForm.get('physicalZip').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  mailingStreetValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean{
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean{
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean{
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean{
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators

}
