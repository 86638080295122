<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row">
    <span style="font-size: larger;"><strong>Download Reporting Window Data</strong></span>
    <span fxFlex></span>
    <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
  </div>
  <br/>
  <span>Enter Reporting Window start and end dates (4/1-4/25; 7/1-7/25; 10/1-10/25; 1/1-1/25) to download all data submitted by all service centers for that reporting period.</span>
  <span>Note: Data that was reported or corrected outside the reporting window is not included in this data. Such data can be viewed by querying for the service center and the reporting window in question, on the main 303 Data page, and then downloading data for the individual service center(s).</span>
  <form [formGroup]="dateForm">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-form-field fxFlex.lt-md="100">
        <mat-label>Begin</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="from" matInput [matDatepicker]="from" />
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="dateForm.get('from').hasError('required')">
          Begin date is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.lt-md="100">
        <mat-label>End</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="to" matInput [matDatepicker]="to" />
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="dateForm.get('to').hasError('required')">
          End date is required
        </mat-error>
      </mat-form-field>

      <button *ngIf="dateForm.valid" type="button" mat-flat-button color="primary" (click)="download()">Download</button>
    </div>
  </form>
</div>

