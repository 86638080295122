import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { ToastrService } from 'ngx-toastr';
import { ViewParentComponent } from '../duplicate-applicant/dialog/view-parent/view-parent-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IPropertyNamesWithInfo } from 'app/models/audit-logging/audit-logging';
import { IBackgroundCheck } from 'app/models/licenses/background-check';
import { BackgroundCheckService } from 'app/services/background-check/background-check.service';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class ApplicantComponent implements OnInit {
  @Input() licenseId: number = 0;
  @Input() licenseType: string = '';
  @Input() primaryContactId: string = '';
  @Input() cardColor: string = '';
  @Input() tableColor: string = '';
  @Input() editingLicense: boolean = false;
  @Input() documentTypes: IDocumentType[] = [];
  @Input() renewal: boolean = false;
  @Input() renewalChanges: IPropertyNamesWithInfo[] = [];
  @Input() admin: boolean = false;
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyUpdate: EventEmitter<IApplicant> = new EventEmitter<IApplicant>();
  @Output() notifyDeduplicate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyDelete:EventEmitter<number> = new EventEmitter<number>();
  @Output() setPointOfContact: EventEmitter<IApplicant> = new EventEmitter<IApplicant>();

  public applicants: IApplicant[] = [];
  public applicant: IApplicant = {
    id: 0,
    applicantId: '',
    ssn: '',
    noSSN: false,
    ssnAttestation: false,
    legalFirstName: '',
    legalMiddleName: '',
    legalLastName: '',
    preferredFirstName: '',
    preferredMiddleName: '',
    preferredLastName: '',
    alternateNames: '',
    oregonResidency: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    phone: '',
    alternatePhone: '',
    email: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    dob: '',
    backgroundCheckDate: '',
    backgroundCheckPassed: false,
    backgroundCheckNotes: '',
    applicantDocuments: [],
    residentialHistory: [],
    backgroundCheckInitiatedDate: '',
    parentApplicantId: 0,
    duplicate: false,
    possibleDuplicate: false,
    isParent: false,
    heldLicense: null,
    licensesHeld: '',
    deniedLicense: null,
    subjectToDiscipline: null,
    suedForDamages: null,
    settledAllegations: null,
    allegedAbuse: null,
    substantiatedAllegation: null,
    applicantPageCorrect: null,
    applicantPageCorrectReason: '',
    changeInConvictionHistory: null,
    archived: false,
    backgroundChecks: [],
    fingerPrintsDate: '',
    fingerPrintsExpirationDate: '',
    stateOnly: false,
    fullBackGroundCheck: false
  }
  public dedeuplicating: boolean = false;
  public loadingDuplicates: boolean = false;
  public addingApplicant: boolean = false;
  public today: Date = new Date(Date.now());
  public documentsValid: boolean = true;
  public viewArchived: boolean = false;
  public documentTypeReference: { [id: number]: string } = {};
  public personalIdentificationType: number = 0;
  public applicantInfoType: number = 0;
  public physicalType: number = 0;
  public otherType: number = 0;
  public personalIdentification: string = 'Personal Identification';
  public applicantInfo: string = 'Applicant Information';
  public physicalApplication: string = 'Physical Application';
  public otherApplication: string = 'Other';
  public deniedLicense: string = 'Previous Denied License Information';
  public subjectToDiscipline: string = 'Previous License Discipline or Revocation Information';
  public suedForDamages: string = 'Previously Sued Information';
  public settledAllegations: string = 'Previous License Allegations or Claims';
  public allegedAbuse: string = 'Previous Alleged Abuse of Child or Adult';
  public deniedLicenseType: number = 0;
  public subjectToDisciplineType: number = 0;
  public suedForDamagesType: number = 0;
  public settledAllegationsType: number = 0;
  public allegedAbuseType: number = 0;

  public applicantForm = new UntypedFormGroup({
    ssn: new UntypedFormControl(""),
    noSsn: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    oregonResidency: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    backgroundCheckDate: new UntypedFormControl(''),
    backgroundCheckInitiatedDate: new UntypedFormControl(''),
    backgroundCheckNotes: new UntypedFormControl(''),
    backgroundCheckPassed: new UntypedFormControl(false),
    fingerPrintsDate: new UntypedFormControl(''),
    fingerPrintsExpirationDate: new UntypedFormControl(''),
    stateOnly: new UntypedFormControl(false),
    fullBackGroundCheck: new UntypedFormControl(false),
    dob: new UntypedFormControl("", [Validators.required]),
    identification: new UntypedFormControl(false),
    applicantInfo: new UntypedFormControl(false),
    other: new UntypedFormControl(false),
    physicalApplication: new UntypedFormControl(false),
    deniedLicenseDocument: new UntypedFormControl(false),
    subjectToDisciplineDocument: new UntypedFormControl(false),
    suedForDamagesDocument: new UntypedFormControl(false),
    settledAllegationsDocument: new UntypedFormControl(false),
    allegedAbuseDocument: new UntypedFormControl(false),
    heldLicense: new UntypedFormControl(false),
    deniedLicense: new UntypedFormControl(false),
    subjectToDiscipline: new UntypedFormControl(false),
    suedForDamages: new UntypedFormControl(false),
    settledAllegations: new UntypedFormControl(false),
    allegedAbuse: new UntypedFormControl(false),
    substantiatedAllegation: new UntypedFormControl(),
    applicantPageCorrect: new UntypedFormControl(),
    applicantPageCorrectReason: new UntypedFormControl(''),
    changeInConvictionHistory: new UntypedFormControl(false),
    }, {
      validators: [
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.emailValidator(),
        this.ssnValidator(),
        this.previousLicenseValidator()
      ]
    }
  );

  public dataColumns: string[] = ['firstName', 'lastName', 'actions', 'delete', 'duplicate', 'checkDuplicate'];
  public dataSource = new MatTableDataSource<IApplicant>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  public backgroundCheckColumns: string[] = ['backgroundCheckInitiatedDate', 'backgroundCheckDate', 'fingerPrintsDate', 'fingerPrintsExpirationDate',  'backgroundCheckPassed', 'backgroundType'];
  public backgroundCheckSource = new MatTableDataSource<IBackgroundCheck>(this.applicant.backgroundChecks);
  @ViewChild('backgroundCheckPaginator', {static: false}) backgroundCheckPaginator: MatPaginator;
  @ViewChild('backgroundCheckSort', {static: false}) backgroundCheckSort: MatSort;

  constructor(public sharedService: SharedService,
              public applicantService: ApplicantService,
              public toastr: ToastrService,
              private dialog: MatDialog,
              public authService: AuthService,
              public backgroundCheckService: BackgroundCheckService) { }

  ngOnInit(): void {
    this.getApplicants();
    this.setupDocumentTypeReference();
  }

  setupDocumentTypeReference(): void {
    this.personalIdentificationType = this.documentTypes.find(dt => dt.type === this.personalIdentification).id;
    this.applicantInfoType = this.documentTypes.find(dt => dt.type === this.applicantInfo).id;
    this.physicalType = this.documentTypes.find(dt => dt.type === this.physicalApplication).id;
    this.otherType = this.documentTypes.find(dt => dt.type === this.otherApplication).id;
    this.deniedLicenseType = this.documentTypes.find(dt => dt.type === this.deniedLicense).id;
    this.subjectToDisciplineType = this.documentTypes.find(dt => dt.type === this.subjectToDiscipline).id;
    this.suedForDamagesType = this.documentTypes.find(dt => dt.type === this.suedForDamages).id;
    this.settledAllegationsType = this.documentTypes.find(dt => dt.type === this.settledAllegations).id;
    this.allegedAbuseType = this.documentTypes.find(dt => dt.type === this.allegedAbuse).id;

    this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
    this.documentTypeReference[this.applicantInfoType] = this.applicantInfo;
    this.documentTypeReference[this.physicalType] = this.physicalApplication;
    this.documentTypeReference[this.otherType] = this.otherApplication;
    this.documentTypeReference[this.deniedLicenseType] = this.deniedLicense;
    this.documentTypeReference[this.subjectToDisciplineType] = this.subjectToDiscipline;
    this.documentTypeReference[this.suedForDamagesType] = this.suedForDamages;
    this.documentTypeReference[this.settledAllegationsType] = this.settledAllegations;
    this.documentTypeReference[this.allegedAbuseType] = this.allegedAbuse;
  }

  getApplicants(): void {
    this.applicantService.getApplicants(this.licenseType, this.licenseId).subscribe(
      response => this.applicants = response,
      error => console.log('error', error),
      () => {
        this.updateApplicantTable();
      }
    );
  }

  checkDuplicates(applicantId: number): void {
    this.loadingDuplicates = true;
    this.applicantService.checkDuplicates(applicantId).subscribe(
      response => {
        this.loadingDuplicates = false;
        this.applicants.find(a => a.id == applicantId).possibleDuplicate = response;
        if(!response)
          this.toastr.success('No Duplicates Found');
      },
      error => console.log('error', error)
    );
  }

  updateApplicantTable(): void{
    this.dataSource.data = this.applicants;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  filterTable(event: Event){
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.dataSource.filter = filter;
  }

  addApplicant(): void {
    this.addingApplicant = true;
    this.applicantService.createApplicant(this.licenseType, this.licenseId).subscribe(
      response => this.applicant = response,
      error => console.log('error', error),
      () => {
        this.applicants.push(this.applicant);
        this.applicantForm.markAsUntouched();
        this.applicantForm.markAsPristine();
      }
    );
    this.notifyParent.emit(true);
  }

  editApplicant(applicant: IApplicant): void {
    this.applicant = applicant;
    this.updateApplicantForm();
    this.updateBackgroundCheckTable();
    if(this.applicant.archived)
      this.applicantForm.disable();
    this.notifyParent.emit(true);
  }

  getEmptyApplicant(): IApplicant {
    let emptyApplicant: IApplicant = {
      id: 0,
      applicantId: '',
      ssn: '',
      noSSN: false,
      ssnAttestation: false,
      legalFirstName: '',
      legalMiddleName: '',
      legalLastName: '',
      preferredFirstName: '',
      preferredMiddleName: '',
      preferredLastName: '',
      alternateNames: '',
      oregonResidency: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      phone: '',
      alternatePhone: '',
      email: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      dob: '',
      backgroundCheckDate: '',
      applicantDocuments: [],
      residentialHistory: [],
      backgroundCheckPassed: false,
      backgroundCheckNotes: '',
      backgroundCheckInitiatedDate: '',
      parentApplicantId: 0,
      duplicate: false,
      possibleDuplicate: false,
      isParent: false,
      heldLicense: null,
      licensesHeld: '',
      deniedLicense: null,
      subjectToDiscipline: null,
      suedForDamages: null,
      settledAllegations: null,
      allegedAbuse: null,
      substantiatedAllegation: null,
      applicantPageCorrect: null,
      applicantPageCorrectReason: '',
      changeInConvictionHistory: null,
      archived: false,
      backgroundChecks: [],
      fingerPrintsDate: '',
      fingerPrintsExpirationDate: '',
      stateOnly: false,
      fullBackGroundCheck: false
    }
    return emptyApplicant;
  }

  saveApplicant(close: boolean): void {
    this.updateApplicant();
    this.applicantService.editApplicant(this.applicant, this.licenseType, this.licenseId).subscribe(
      () => {
        if(this.primaryContactId === this.applicant.applicantId){
          this.setPointOfContact.emit(this.applicant);
        }
        this.addingApplicant = false;
        let index = this.applicants.findIndex(a => a.id === this.applicant.id);
        this.applicants[index] = this.applicant;
        this.notifyUpdate.emit(this.applicant);
        if(close){
          this.cancel();
        }
      },
      error => console.log('error', error)
    );
  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    this.applicant.ssn = applicantInfo.ssn,
    this.applicant.noSSN = applicantInfo.noSsn,
    this.applicant.ssnAttestation = applicantInfo.ssnAttestation,
    this.applicant.legalFirstName = applicantInfo.legalFirstName,
    this.applicant.legalMiddleName = applicantInfo.legalMiddleName,
    this.applicant.legalLastName = applicantInfo.legalLastName,
    this.applicant.preferredFirstName = applicantInfo.preferredFirstName,
    this.applicant.preferredMiddleName = applicantInfo.preferredMiddleName,
    this.applicant.preferredLastName = applicantInfo.preferredLastName,
    this.applicant.alternateNames = applicantInfo.alternateNames,
    this.applicant.oregonResidency = applicantInfo.oregonResidency,
    this.applicant.physicalState = applicantInfo.physicalState,
    this.applicant.physicalCity = applicantInfo.physicalCity,
    this.applicant.physicalStreet = applicantInfo.physicalStreet,
    this.applicant.physicalZip = applicantInfo.physicalZip,
    this.applicant.physicalCounty = applicantInfo.physicalCounty,
    this.applicant.mailingIsPhysical = applicantInfo.mailingIsPhysical,
    this.applicant.mailingState = applicantInfo.mailingState,
    this.applicant.mailingCity = applicantInfo.mailingCity,
    this.applicant.mailingStreet = applicantInfo.mailingStreet,
    this.applicant.mailingZip = applicantInfo.mailingZip,
    this.applicant.mailingCounty = applicantInfo.mailingCounty,
    this.applicant.phone = applicantInfo.phone,
    this.applicant.alternatePhone = applicantInfo.alternatePhone,
    this.applicant.email = applicantInfo.email,
    this.applicant.language = applicantInfo.language,
    this.applicant.backgroundCheckNotes = applicantInfo.backgroundCheckNotes,
    this.applicant.backgroundCheckPassed = applicantInfo.backgroundCheckPassed,
    this.applicant.previousLicense = applicantInfo.previousLicense,
    this.applicant.changeInConvictionHistory = applicantInfo.changeInConvictionHistory,
    this.applicant.affiliatedLicenses = applicantInfo.affiliatedLicenses
    this.applicant.heldLicense = applicantInfo.heldLicense,
    this.applicant.deniedLicense = applicantInfo.deniedLicense,
    this.applicant.subjectToDiscipline = applicantInfo.subjectToDiscipline,
    this.applicant.suedForDamages = applicantInfo.suedForDamages,
    this.applicant.settledAllegations = applicantInfo.settledAllegations,
    this.applicant.allegedAbuse = applicantInfo.allegedAbuse,
    this.applicant.substantiatedAllegation = applicantInfo.substantiatedAllegation,
    this.applicant.applicantPageCorrect = applicantInfo.applicantPageCorrect,
    this.applicant.applicantPageCorrectReason = applicantInfo.applicantPageCorrectReason
    this.applicant.stateOnly = applicantInfo.stateOnly,
    this.applicant.fullBackGroundCheck = applicantInfo.fullBackGroundCheck

    if(applicantInfo.backgroundCheckDate != '')
      this.applicant.backgroundCheckDate = new Date(applicantInfo.backgroundCheckDate).toLocaleDateString()

    if(applicantInfo.backgroundCheckInitiatedDate != '')
      this.applicant.backgroundCheckInitiatedDate = new Date(applicantInfo.backgroundCheckInitiatedDate).toLocaleDateString()

    if(applicantInfo.fingerPrintsDate != '')
      this.applicant.fingerPrintsDate = new Date(applicantInfo.fingerPrintsDate).toLocaleDateString()

    if(applicantInfo.fingerPrintsExpirationDate != '')
      this.applicant.fingerPrintsExpirationDate = new Date(applicantInfo.fingerPrintsExpirationDate).toLocaleDateString()

    if(applicantInfo.dob != '')
      this.applicant.dob = new Date(applicantInfo.dob).toLocaleDateString()

    if(applicantInfo.mailingIsPhysical){
      this.applicant.mailingState = applicantInfo.physicalState;
      this.applicant.mailingCity = applicantInfo.physicalCity;
      this.applicant.mailingStreet = applicantInfo.physicalStreet;
      this.applicant.mailingZip = applicantInfo.physicalZip;
      this.applicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
  }

  selectPointOfContact(applicant: IApplicant): void {
    this.sharedService.openConfirm('Set ' + applicant.legalFirstName + ' ' + applicant.legalLastName + ' as the primary point of contact?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.setPointOfContact.emit(applicant);
        }
      }
    );
  }

  cancel(): void {
    let applicantId: number = this.applicant.id;
    if(this.addingApplicant){
      this.sharedService.openConfirm("Changes to this applicant will not be saved. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.applicantService.deleteApplicant(applicantId, this.licenseId, this.licenseType).subscribe(
              () => {
                this.notifyParent.emit(false);
                this.applicants = this.applicants.filter(a => a.id !== applicantId);
                this.applicant = this.getEmptyApplicant();
                this.updateApplicantForm();
                this.addingApplicant = false;
                setTimeout(() => {
                  this.updateApplicantTable();
                }, 500);
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }
    else{
      this.notifyParent.emit(false);
      this.applicant = this.getEmptyApplicant();
      this.updateApplicantForm();
      setTimeout(() => {
        this.updateApplicantTable();
      }, 500);
    }
  }

  updateApplicantForm(): void {
    this.applicantForm.patchValue({
      ssn: this.applicant.ssn,
      noSsn: this.applicant.noSSN,
      ssnAttestation: this.applicant.ssnAttestation,
      legalFirstName: this.applicant.legalFirstName,
      legalMiddleName: this.applicant.legalMiddleName,
      legalLastName: this.applicant.legalLastName,
      preferredFirstName: this.applicant.preferredFirstName,
      preferredMiddleName: this.applicant.preferredMiddleName,
      preferredLastName: this.applicant.preferredLastName,
      alternateNames: this.applicant.alternateNames,
      oregonResidency: this.applicant.oregonResidency,
      physicalStreet: this.applicant.physicalStreet,
      physicalCity: this.applicant.physicalCity,
      physicalState: this.applicant.physicalState,
      physicalZip: this.applicant.physicalZip,
      physicalCounty: this.applicant.physicalCounty,
      mailingIsPhysical: this.applicant.mailingIsPhysical,
      mailingStreet: this.applicant.mailingStreet,
      mailingCity: this.applicant.mailingCity,
      mailingState: this.applicant.mailingState,
      mailingZip: this.applicant.mailingZip,
      mailingCounty: this.applicant.mailingCounty,
      phone: this.applicant.phone,
      alternatePhone: this.applicant.alternatePhone,
      email: this.applicant.email,
      confirmEmail: this.applicant.email,
      language: this.applicant.language,
      backgroundCheckPassed: this.applicant.backgroundCheckPassed,
      backgroundCheckNotes: this.applicant.backgroundCheckNotes,
      previousLicense: this.applicant.previousLicense,
      changeInConvictionHistory: this.applicant.changeInConvictionHistory,
      affiliatedLicenses: this.applicant.affiliatedLicenses,
      identification: false,
      residency: false,
      applicantPageCorrect: this.applicant.applicantPageCorrect,
      applicantPageCorrectReason: this.applicant.applicantPageCorrectReason,
      dob: '',
      backgroundCheckDate: '',
      backgroundCheckInitiatedDate: '',
      fingerPrintsDate: '',
      fingerPrintsExpirationDate: '',
      stateOnly: this.applicant.stateOnly,
      fullBackGroundCheck: this.applicant.fullBackGroundCheck,
      heldLicense: this.applicant.heldLicense,
      deniedLicense: this.applicant.deniedLicense,
      subjectToDiscipline: this.applicant.subjectToDiscipline,
      suedForDamages: this.applicant.suedForDamages,
      settledAllegations: this.applicant.settledAllegations,
      allegedAbuse: this.applicant.allegedAbuse,
      substantiatedAllegation: this.applicant.substantiatedAllegation
    });
    if(this.applicant.dob != null && this.applicant.dob.length > 0)
    {
      this.applicantForm.patchValue({
        dob: new Date(this.applicant.dob)
      });
    }
    if(this.applicant.backgroundCheckDate != null && this.applicant.backgroundCheckDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckDate: new Date(this.applicant.backgroundCheckDate)
      });
    }
    if(this.applicant.backgroundCheckInitiatedDate != null && this.applicant.backgroundCheckInitiatedDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckInitiatedDate: new Date(this.applicant.backgroundCheckInitiatedDate)
      });
    }
    if(this.applicant.fingerPrintsDate != null && this.applicant.fingerPrintsDate.length > 0)
    {
      this.applicantForm.patchValue({
        fingerPrintsDate: new Date(this.applicant.fingerPrintsDate)
      });
    }
    if(this.applicant.fingerPrintsExpirationDate != null && this.applicant.fingerPrintsExpirationDate.length > 0)
    {
      this.applicantForm.patchValue({
        fingerPrintsExpirationDate: new Date(this.applicant.fingerPrintsExpirationDate)
      });
    }
  }

  deleteApplicant(applicant: IApplicant): void {
    this.sharedService.openConfirm("Delete " + applicant.legalFirstName + " " + applicant.legalLastName + "? " + "You will lose all records of this applicant.");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.notifyDelete.emit(applicant.id);
          this.applicantService.deleteApplicant(applicant.id, this.licenseId, this.licenseType).subscribe(
            () => {
              if(this.primaryContactId == applicant.applicantId){
                let emptyApplicant = this.getEmptyApplicant();
                this.setPointOfContact.emit(emptyApplicant);
              }
              this.applicants = this.applicants.filter(a => a.id !== applicant.id);
              this.updateApplicantTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  archiveApplicant(applicant: IApplicant, archive: boolean): void {
    if(archive)
      this.sharedService.openConfirm("Archive " + applicant.legalFirstName + " " + applicant.legalLastName + "?");
    else
      this.sharedService.openConfirm("Unarchive " + applicant.legalFirstName + " " + applicant.legalLastName + "?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.archiveApplicant(applicant.id, archive).subscribe(
            () => {
              if(this.primaryContactId == applicant.applicantId){
                let emptyApplicant = this.getEmptyApplicant();
                this.setPointOfContact.emit(emptyApplicant);
              }
              this.applicants.find(a => a.id == applicant.id).archived = archive;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateDocuments(): void {
    this.documentsValid = true;
    let requiredTypes: number[];

    if(this.renewal){
      requiredTypes = [
        this.applicantInfoType,
        this.personalIdentificationType
      ];
    }
    else {
      requiredTypes = [
        this.applicantInfoType,
        this.personalIdentificationType
      ];
    }
    let types: number[] = [];
    this.applicant.applicantDocuments.forEach(ld => ld.types.forEach(t => types.push(t)));

    requiredTypes.forEach(type => {
      if(!types.includes(type)){
        this.documentsValid = false;
      }
    });
  }

  uploadDocument(event: Event): void {
    let form = this.applicantForm.value;
    let types: number[] = [];
    let upload: IDocument = {
      id: 0,
      name: "",
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.applicant.id,
      types: types,
      pendingUpdate: false,
      adminOnly: false,
      deprecated: false
    }

    if(form.deniedLicenseDocument) {
      types.push(this.deniedLicenseType);
      upload.adminOnly = true;
    }
    if(form.subjectToDisciplineDocument) {
      types.push(this.subjectToDisciplineType);
      upload.adminOnly = true;
    }
    if(form.suedForDamagesDocument) {
      types.push(this.suedForDamagesType);
      upload.adminOnly = true;
    }
    if(form.settledAllegationsDocument) {
      types.push(this.settledAllegationsType);
      upload.adminOnly = true;
    }
    if(form.allegedAbuseDocument) {
      types.push(this.allegedAbuseType);
      upload.adminOnly = true;
    }
    if(form.identification) {
      types.push(this.personalIdentificationType);
    }
    if(form.applicantInfo){
      types.push(this.applicantInfoType);
    }
    if(form.other){
      types.push(this.otherType);
    }
    if(form.physicalApplication){
      types.push(this.physicalType);
    }

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      upload.name = file.name;

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.applicantService.uploadDocument(formData).subscribe(
        response => this.applicant.applicantDocuments.unshift(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.validateDocuments();
          this.applicantForm.patchValue({
            identification: false,
            applicantInfo: false,
            other: false,
            physicalApplication: false,
            deniedLicenseDocument: false,
            subjectToDisciplineDocument: false,
            suedForDamagesDocument: false,
            settledAllegationsDocument: false,
            allegedAbuseDocument: false
          });
        });
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  downloadDocument(fileId: number, fileName: string): void {
    if(this.applicant.applicantDocuments.find(d => d.id == fileId).adminOnly)
    {
      if(this.authService.isAdmin)
        this.applicantService.downloadFile(fileId).subscribe(
          (response) => this.saveFile(fileName, response),
          (error) => console.log("error", error)
        );
      else
        this.toastr.error("You do not have permission to download this file");
    }
    else
      this.applicantService.downloadFile(fileId).subscribe(
        (response) => this.saveFile(fileName, response),
        (error) => console.log("error", error)
      );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteDocument(id).subscribe(
            () => {
              this.applicant.applicantDocuments = this.applicant.applicantDocuments.filter(item => item.id !== id);
              this.validateDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  deprecateDocument(id: number) {
    let archived = false;
    let currentDocumentIndex = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
    archived = this.applicant.applicantDocuments[currentDocumentIndex].deprecated;

    this.sharedService.openConfirm(archived ? "Unarchive Document?" : "Archive Document?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deprecateDocument(id).subscribe(
            () => {
                let index = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
                this.applicant.applicantDocuments[index].deprecated = !this.applicant.applicantDocuments[index].deprecated;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  viewArchivedDocuments(): void {
    this.viewArchived = !this.viewArchived;
  }

  deduplicateApplicant(dedeuplicating: boolean): void {
    this.dedeuplicating = dedeuplicating;
    this.notifyDeduplicate.emit(this.dedeuplicating);
  }

  ViewParent(){
    const dialogRef = this.dialog.open(ViewParentComponent, {
      disableClose: true,
      minWidth: !this.sharedService.mobile? '500px' : '',
      minHeight: !this.sharedService.mobile? '' : '',
      maxWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxHeight: !this.sharedService.mobile? '900px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
      data: { parentId: this.applicant.parentApplicantId, documentTypes: this.documentTypes, currentApplicantId: this.applicant.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.applicant.possibleDuplicate = true;
        this.applicant.duplicate = false;
        this.applicant.parentApplicantId = 0;
      }
    });
  }

  isFieldUpdated(propertyName: string, tableName: string, keyValue: string): boolean {
    return this.renewalChanges.some(field =>
      field.propertyName === propertyName &&
      field.tableName === tableName &&
      field.keyValue === keyValue
    );
  }
  //Applicant Custom Validators

  ssnValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssn;
      const noSsn = control.value.noSsn;
      if(noSsn){
        return null;
      }
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
    }
  }

  validateSsn(): boolean {
    if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
      this.applicantForm.get('ssn').setErrors(['ssnRequired']);
      return true;
    }
    this.applicantForm.get('ssn').clearValidators();
    this.applicantForm.get('ssn').updateValueAndValidity();
    return false;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.email;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators
  deprecateBackgroundCheck(): void {
    let backgroundCheck: IBackgroundCheck = {
      id: 0,
      applicantId: this.applicant.id,
      backgroundCheckDate: this.applicant.backgroundCheckDate,
      backgroundCheckInitiatedDate: this.applicant.backgroundCheckInitiatedDate,
      backgroundCheckPassed: this.applicant.backgroundCheckPassed,
      fingerPrintsDate: this.applicant.fingerPrintsDate,
      fingerPrintsExpirationDate: this.applicant.fingerPrintsExpirationDate,
      stateOnly: this.applicant.stateOnly,
      fullBackGroundCheck: this.applicant.fullBackGroundCheck,
    };

    this.backgroundCheckService.createBackgroundCheck(backgroundCheck).subscribe({
      next: response => {
        if(response != null)
        {
          this.applicant.backgroundChecks.push(response);
          this.applicant.backgroundCheckDate = '';
          this.applicant.backgroundCheckInitiatedDate = '';
          this.applicant.backgroundCheckPassed = false;
          this.applicant.fingerPrintsDate = '';
          this.applicant.fingerPrintsExpirationDate = '';
          this.applicant.stateOnly = false;
          this.applicant.fullBackGroundCheck = false;
          this.updateBackgroundCheckTable();

          this.applicantForm.patchValue({
            backgroundCheckPassed: false,
            stateOnly: false,
            fullBackGroundCheck: false,
            backgroundCheckDate: '',
            backgroundCheckInitiatedDate: '',
            fingerPrintsDate: '',
            fingerPrintsExpirationDate: ''
          });

          this.toastr.success('Background Check Archived');
        }
        if(response == null)
          this.toastr.error('Error Archiving Background Check');
      },
      error: e => {
        this.toastr.error('Error Archiving Background Check');
      }
    });
  }

  updateBackgroundCheckTable(): void {
    this.applicant.backgroundChecks.sort((a, b) => b.id - a.id);
    this.backgroundCheckSource.data = this.applicant.backgroundChecks;
    this.backgroundCheckSource.sort = this.backgroundCheckSort;
    this.backgroundCheckSource.paginator = this.backgroundCheckPaginator;
  }
}
