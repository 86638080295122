import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { IPayment } from '../../models/payment';
import { PaymentService } from '../../services/payments.service';
import { DialogEditPaymentComponent } from '../edit/edit-payment-dialog';
import { DialogMarkPaidComponent } from '../edit/mark-paid';

@Component({
  selector: 'app-manage-payments',
  templateUrl: './manage-payments.component.html',
  styleUrls: ['./manage-payments.component.scss']
})

export class ManagePaymentComponent implements OnInit {
  @Input() entityId = "";
  @Input() entityType = "";
  @Input() allowMultiplePayments = false;
  @Input() allowDelete = true;
  @Input() editingPayment = false;
  @Input() cardColor: string = '';
  @Input() renewal: boolean = false;
  @Output() notifyParent: EventEmitter<IPayment[]> = new EventEmitter<IPayment[]>();

  public updateParent: boolean = false;
  public processing: boolean = false;
  public paymentsSource = new MatTableDataSource<IPayment>();
  public payments: IPayment [] = [];
  public paymentColumns: string[] = ['type','status','paymentCleared','amount','paymentMethod', 'sku', 'comment', 'localReferenceId', 'actions'];
  public isAdmin: boolean = false;

  constructor(public sharedService: SharedService,
              public authService: AuthService,
              public paymentService: PaymentService,
              private dialog: MatDialog,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.loadPayments();
    this.checkAdmin();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityId && !changes.entityId.firstChange) {
      this.reload();
    }
  }

  reload() {
    this.loadPayments();
  }

  checkAdmin(): void {
  if(this.authService.isAdmin)
    this.isAdmin = true;
  if(this.entityType == this.paymentService.trainingProgramPaymentOption)
    if(this.authService.isTrainingAdmin)
      this.isAdmin = true;
  if(this.entityType == this.paymentService.facilitatorPaymentOption)
    if(this.authService.isFacilitatorAdmin)
      this.isAdmin = true;
  if(this.entityType == this.paymentService.ServiceCenterPaymentOption)
    if(this.authService.isServiceCenterAdmin)
      this.isAdmin = true;
  if(this.entityType == this.paymentService.WorkerPermitPaymentOption)
    if(this.authService.isWorkerPermitAdmin)
      this.isAdmin = true;
  if(this.entityType == this.paymentService.ManufacturerPaymentOption)
    if(this.authService.isManufacturerAdmin)
      this.isAdmin = true;
  if(this.entityType == this.paymentService.TestingLabPaymentOption)
    if(this.authService.isTestingLabAdmin)
      this.isAdmin = true;
  }

  loadPayments(): void {
    this.processing = true;
    this.paymentService.getPaymentsByEntity(this.entityId, this.entityType).subscribe(
      response => {
        this.payments = response;
        this.paymentsSource = new MatTableDataSource<IPayment>(this.payments);
        if(this.updateParent){
          this.notifyParent.emit(this.payments);
          this.updateParent = false;
        }
      },
      error => console.log('error', error),
      () => { this.processing = false; }
    );
  }

  markAsCleared(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogMarkPaidComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }

  deletePayment(paymentId: number, type: string ) {
    this.sharedService.openConfirm(
      "Are you sure you want to delete this payment? Continue?"
      );
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.processing = true;
          this.paymentService.deletePayment(paymentId).subscribe(
            () => {
              this.updateParent = true;
              this.loadPayments();
              this.processing = false;
              this.toastr.success("Payment Removed");
            } ,
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addNewPayment() {
    let payment: IPayment = {
      id: 0,
      localReferenceId: '',
      paymentGuid: '',
      paid: false,
      status: '',
      type: this.entityType,
      orderNumber: '',
      orderRecordId: '',
      billingName: '',
      billingAddress: '',
      orderTimePlaced: '',
      quantity: 1,
      amount: "0.00",
      comment: '',
      createdBy: '',
      dastUpdatedBy: '',
      dateCreated: '',
      dateLastUpdated: '',
      entityId: this.entityId,
      entityType: this.entityType,
      paymentCleared: '',
      paymentMethod: ''
    }

    const dialogRef = this.dialog.open(DialogEditPaymentComponent, {
      data: { payment: payment, renewal: this.renewal},
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }

  editPayment(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogEditPaymentComponent, {
      data: { payment: payment, renewal: this.renewal},
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }
}
