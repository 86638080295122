import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuditLogService } from "app/services/audit-log/audit-log.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";
import { IAuditLog, IPropertyNamesWithInfo } from "app/models/audit-logging/audit-logging";
import { AuditLoggingSearch } from "app/models/audit-logging/audit-logging-search";

@Component({
  selector: 'renewal-changes-audit-log',
  templateUrl: './renewal-change-table.component.html',
  providers: [AuditLogService],
})

export class RenewalChangesDialogComponent implements OnInit  {
  @Output() highlightChanges: EventEmitter<IPropertyNamesWithInfo[]> = new EventEmitter<IPropertyNamesWithInfo[]>();
  public renewalChanges: IPropertyNamesWithInfo[] = [];
  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingSearch = null;
  entityId: string = "";
  entityType: string = "";
  startDate: string = "";
  endDate: string = "";

  constructor(public sharedService: SharedService,
    private auditLogService: AuditLogService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<RenewalChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.entityId = data.entityId;
      this.entityType = data.entityType;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    }

  ngOnInit(): void {
    this.loading = true;
    this.auditLogService.initiateSearch(this.entityId, this.entityType).subscribe(
      response => {
        let start = new Date(this.startDate);
        start.setSeconds(start.getSeconds() + 10);
        this.search = response;
        this.search.startDate = start.toLocaleString();
        this.search.endDate = this.endDate
        this.search.displayLicense = true;
        this.search.displayApplicants = true;
        this.getLogs();
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  getLogs() {
    this.auditLogService.getAuditLogging(this.search).subscribe(
      response => {
        this.auditlogs = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  emitChanges() {
    this.auditlogs.forEach((auditLog) => {
      const { keyValue, tableName, changes } = auditLog;
      changes.forEach((change) => {
        const { propertyName } = change;
        this.renewalChanges.push({ propertyName, keyValue, tableName });
      });
    });

    this.highlightChanges.emit(this.renewalChanges);
  }
}
