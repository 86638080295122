
 <div *ngIf="!loading">
  <div [@slideLeft] *ngIf="currentEvent.id <= 0" fxLayout="column" class="m-t-16">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
       <span>Reported Events</span>
        <span fxFlex></span>
        <button type="button" mat-icon-button color="primary" matTooltip="Add New Event"
                matTooltipShowDelay=800 aria-label="Add New Event" (click)="addReportedEvent()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="reportedEventSource" matSort #reportedEventSort="matSort" aria-describedby="Reported Events" class="mat-elevation-z8">
        <ng-container matColumnDef="eventId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Event Id</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Event Id</span>
            {{ event.eventId }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Type</span>
            {{ event.eventType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Date</span>
            {{ event.eventDate | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Time</span>
            {{ event.eventTime}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reoccurringEvent">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Reoccurring Event?</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Reoccurring Event?</span>
            {{ event.reoccurringEvent | slice:0:90 }}{{ event.reoccurringEvent.length > 50 ? '...' : '' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="emergencyService">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Reported to Emergency Services</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Reported to Emergency Services</span>
            <span *ngIf="event.emergencyService">Yes</span>
            <span *ngIf="!event.emergencyService">No</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label">Description</span>
            {{ event.description | slice:0:90 }}{{ event.description.length > 50 ? '...' : '' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let event">
            <span class="mobile-label"></span>
            <button *ngIf="authService.isAdmin" mat-icon-button color="warn" matTooltip="Delete Event" matTooltipShowDelay=800 aria-label="Delete Event" (click)="deleteReportedEvent(event.id); $event.stopPropagation();">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="reportedEventColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: reportedEventColumns" (click)="viewReportedEvent(row, false)"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #reportedEventPaginator="matPaginator">
      </mat-paginator>
    </div>
  </div>
  <div [@slideRight] *ngIf="currentEvent.id > 0" fxLayout="column" class="m-t-16" fxLayoutGap="12px">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxFlex="100">
        <span fxFlex></span>
        <button mat-button type="button" color="warn" (click)="viewReportedEvents()">Close</button>
        <button *ngIf="editing" mat-button type="button" color="primary" (click)="updateReportedEvent(false)">Save</button>
        <button *ngIf="editing" mat-button type="button" color="primary" (click)="updateReportedEvent(true)">Save & Close</button>
        <button type="button" mat-icon-button [color]="editing? 'primary':'warn'"
              [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800
              aria-label="Edit Case" (click)="editReportedEvent()">
          <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
        </button>
      </div>
       <div fxLayout="row"  fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">
        <div fxLayout="column" fxFlex="25">
          <span style="font-size:xx-large">Reported Event</span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span class="f-s-16">Reported Event ID:</span>
            <span>{{currentEvent.eventId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(currentEvent.eventId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25" fxLayoutGap="16px" *ngIf="!editing">
            <div fxLayout="column">
              <span><strong>Event Type</strong></span>
              <span>{{currentEvent.eventType}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Date</strong></span>
              <span>{{currentEvent.eventDate | date}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Time</strong></span>
              <span>{{currentEvent.eventTime}}</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="40" fxLayoutGap="16px" *ngIf="!editing">
          <div fxLayout="column">
            <span><strong>Description</strong></span>
            <span>{{currentEvent.description}}</span>
          </div>
          <div fxLayout="column">
            <span><strong>Other Licensees Involved</strong></span>
            <span>{{currentEvent.licenseesInvolved}}</span>
          </div>
          <div fxLayout="column">
            <span><strong>Reoccurring Event?</strong></span>
            <span>{{currentEvent.reoccurringEvent}}</span>
          </div>
          <div fxLayout="column">
            <span><strong>Reported to Emergency Services?</strong></span>
            <span *ngIf="currentEvent.emergencyService">Yes</span>
            <span *ngIf="!currentEvent.emergencyService">No</span>
          </div>
        </div>
        <div *ngIf="editing" fxFlex="100">
          <form [formGroup]="reportedEventForm" (ngSubmit)="updateReportedEvent(false)">
            <div fxLayout="column" fxFlex="90">
              <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="50">
                  <mat-form-field *ngIf="authService.isAdmin">
                    <mat-label>Event Type</mat-label>
                    <input #input
                      type="text"
                      placeholder="Event Type"
                      matInput
                      formControlName="eventType"
                      [matAutocomplete]="auto">
                    <mat-autocomplete requireSelection #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-form-field *ngIf="!authService.isAdmin">
                    <mat-label>Event Type</mat-label>
                      <mat-select formControlName="eventType">
                        <mat-option *ngFor="let option of options" [value]="option">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <mat-form-field>
                    <mat-label>Event Date</mat-label>
                    <input placeholder="mm/dd/yyyy"
                           formControlName="eventDate"
                           matInput
                           [matDatepicker]="datepickerEventDate"/>
                    <mat-datepicker-toggle matSuffix [for]="datepickerEventDate"></mat-datepicker-toggle>
                    <mat-datepicker #datepickerEventDate>
                      <mat-datepicker-actions>
                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        <button mat-button matDatepickerCancel>Cancel</button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <mat-form-field>
                    <mat-label>Event Time</mat-label>
                    <input #input
                      type="text"
                      placeholder="Event Time"
                      matInput
                      formControlName="eventTime">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="50">
                  <mat-form-field>
                    <textarea matInput placeholder="Other Licensees Involved" formControlName="licenseesInvolved"></textarea>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="50">
                  <mat-form-field>
                    <textarea matInput placeholder="Description" formControlName="description"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="50">
                  <mat-form-field>
                    <textarea matInput placeholder="Reoccurring Event?" formControlName="reoccurringEvent"></textarea>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="50">
                  <mat-checkbox formControlName="emergencyService">Reported to Emergency Services</mat-checkbox>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
