import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IExamQuestion } from 'app/models/licenses/facilitator/exam/exam-question';

@Injectable({providedIn: 'root'})
export class ExamService {
  private api: string = environment.privateApi + 'Exam/';


  constructor(private http: HttpClient) { }

  getExamMap(): Observable<IExamQuestion[]>{
    return this.http.get<IExamQuestion[]>(this.api + 'GetExamMap');
  }

  updateExamMap(examMap: IExamQuestion[]): Observable<IExamQuestion[]>{
    return this.http.post<IExamQuestion[]>(this.api + 'UpdateExamMap', examMap);
  }

}
