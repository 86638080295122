import { trigger, transition, style, animate } from "@angular/animations";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/auth/auth.service";
import { IDocument } from "app/models/documents/document";
import { IReportedEvent } from "app/models/reported-events/reported-event";
import { SharedService } from "app/services/core/shared.service";
import { ReportedEventService } from "app/services/reported-event/reported-event.service";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'app-reported-event',
  templateUrl: './reported-event.component.html',
  styleUrls: ['./reported-event.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class ReportedEventComponent implements OnInit {
  @Input() reportedEvent: IReportedEvent = {
    id: 0,
    eventId: "",
    eventType: "",
    eventDate: "",
    description: "",
    entityId: 0,
    entityType: "",
    licenseesInvolved: "",
    eventTime: "",
    reoccurringEvent: "",
    emergencyService: false,
    reportedEventDocuments: []
  }
  @Input() entityId: number = 0;
  @Input() entityType: string = '';

  public reportedEventForm: FormGroup = new FormGroup({
    eventId: new FormControl(''),
    eventType: new FormControl(''),
    eventDate: new FormControl(''),
    eventTime: new FormControl(''),
    description: new FormControl(''),
    licenseesInvolved: new FormControl(''),
    reoccurringEvent: new FormControl(''),
    emergencyService: new FormControl(false)
  });
  public today: Date = new Date(Date.now());
  public editing: boolean = false;
  public loading: boolean = true;
  public reportedEvents: IReportedEvent[] = [];
  public currentEvent:IReportedEvent = {
    id: 0,
    eventId: "",
    eventType: "",
    eventDate: "",
    eventTime: "",
    description: "",
    entityId: 0,
    entityType: "",
    licenseesInvolved: "",
    reoccurringEvent: "",
    emergencyService: false,
    reportedEventDocuments: []
  };
  options: string[] = [
    'Temporary closure lasting more than 30 days',
    'Emergency services contacted',
    'Temporary use events',
    'Criminal history update',
    'Arrest for conduct that occurred on licensed premises',
    'Theft of psilocybin products',
    'Theft of cash from licensed premises',
    'Administration session continuing after midnight',
    'Out-of-state practicum student'
  ];
  filteredOptions: Observable<string[]>;
  public reportedEventColumns: string[] = ['eventId', 'eventType', 'eventDate', 'eventTime', 'reoccurringEvent', 'emergencyService' ,'description', 'delete'];
  public reportedEventSource = new MatTableDataSource<IReportedEvent>(this.reportedEvents);
  @ViewChild('reportedEventPaginator', {static: false}) reportedEventPaginator: MatPaginator;
  @ViewChild('reportedEventSort', {static: false}) reportedEventSort: MatSort;

  public reportedEventDocumentColumns: string[] = ['name', 'dateCreated', 'actions'];
  public reportedEventDocumentSource = new MatTableDataSource<IDocument>(this.currentEvent.reportedEventDocuments);
  @ViewChild('reportedEventDocumentPaginator', {static: false}) reportedEventDocumentPaginator: MatPaginator;
  @ViewChild('reportedEventDocumentSort', {static: false}) reportedEventDocumentSort: MatSort;

  constructor(public sharedService: SharedService,
              public reportedEventService: ReportedEventService,
              public authService: AuthService,
              public toastr: ToastrService) {
                this.filteredOptions = this.reportedEventForm.get('eventType').valueChanges.pipe(
                  startWith(''),
                  map(value => this._filter(value))
                );
              }

  ngOnInit(): void {
    this.updateReportedEventsTable();
    if(this.reportedEvent.id > 0){
      this.currentEvent = this.reportedEvent;
    }
    else{
      this.currentEvent = this.getEmptyReportedEvent();
    }
    this.getReportedEvents();
  }

  private getReportedEvents(): void {
    this.reportedEventService.getReportedEvents(this.entityId, this.entityType).subscribe({
      next: response => this.reportedEvents = response,
      error: error => console.log('error', error),
      complete: () => {
        this.loading = false;
        this.updateReportedEventsTable();
      }
    });
  }

  public viewReportedEvents(): void {
    this.editing = false;
    this.currentEvent = this.getEmptyReportedEvent();
    this.updateReportedEventsTable();
    this.updateReportedEventForm();
    this.updateReportedEventDocumentTable();
  }

  public addReportedEvent(): void {
    this.sharedService.openConfirm('Add a Reported Event?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.reportedEventService.createReportedEvent(this.currentEvent).subscribe({
           next: response =>  this.currentEvent = response,
           error: error => console.log('error', error),
            complete: () => {
            this.editing = true;
            this.reportedEvents.push(this.currentEvent);
            this.updateReportedEventForm();
            this.updateReportedEventDocumentTable();
            }
        });
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  private updateReportedEventsTable(): void {
    this.reportedEventSource.data = this.reportedEvents;
    this.reportedEventSource.sort = this.reportedEventSort;
    this.reportedEventSource.paginator = this.reportedEventPaginator;
  }

  private getEmptyReportedEvent(): IReportedEvent {
    let reportedEvent: IReportedEvent = {
      id: 0,
      eventId: "",
      eventType: "",
      eventDate: "",
      description: "",
      entityId: this.entityId,
      entityType: this.entityType,
      licenseesInvolved: "",
      eventTime: "",
      reoccurringEvent: "",
      emergencyService: false,
      reportedEventDocuments: []
    }
    return reportedEvent;
  }

  public editReportedEvent(): void {
    this.editing = !this.editing;
    this.updateReportedEventsTable();
  }

  public viewReportedEvent(reportedEvent: IReportedEvent, expired: boolean): void {
    this.currentEvent = reportedEvent;
      setTimeout(() => {
        this.updateReportedEventForm();
        this.updateReportedEventDocumentTable();
      }, 50);
  }

  public updateReportedEvent(close: boolean): void {
    let form = this.reportedEventForm.value;
    this.currentEvent.eventType = form.eventType;
    this.currentEvent.eventDate = form.eventDate,
    this.currentEvent.eventTime = form.eventTime,
    this.currentEvent.description = form.description,
    this.currentEvent.licenseesInvolved = form.licenseesInvolved,
    this.currentEvent.reoccurringEvent = form.reoccurringEvent,
    this.currentEvent.emergencyService = form.emergencyService
    this.reportedEventService.updateReportedEvent(this.currentEvent).subscribe({
      next: response => this.currentEvent = response,
      error: error => console.log('error', error),
      complete: () => {
        if(close){
          this.viewReportedEvents();
        }
    }
  });
  }

  private updateReportedEventForm(): void {
    this.reportedEventForm.patchValue({
      eventType: this.currentEvent.eventType,
      description: this.currentEvent.description,
      licenseesInvolved: this.currentEvent.licenseesInvolved,
      reoccurringEvent: this.currentEvent.reoccurringEvent,
      emergencyService: this.currentEvent.emergencyService,
      eventTime: this.currentEvent.eventTime
    });
    if (this.currentEvent.eventDate != null && this.currentEvent.eventDate.length > 0)
    {
      this.reportedEventForm.get('eventDate').patchValue(new Date(this.currentEvent.eventDate));
    }
  }

  public deleteReportedEvent(eventId: number): void {
    this.sharedService.openConfirm('Delete Reported Event?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.reportedEventService.deleteReportedEvent(eventId).subscribe({
          next: response => {
            if(response)
              {
                this.reportedEvents = this.reportedEvents.filter(event => event.id != eventId);
                this.updateReportedEventsTable();
                this.toastr.success('Reported Event Deleted');
              }
              else
                this.toastr.error('Could not delete reported event');
          },
          error: error => console.log('error', error)
        });
      }
    });
  }

  private updateReportedEventDocumentTable(): void {
    this.reportedEventDocumentSource.data = this.currentEvent.reportedEventDocuments;
    this.reportedEventDocumentSource.sort = this.reportedEventDocumentSort;
    this.reportedEventDocumentSource.paginator = this.reportedEventDocumentPaginator;
  }

  deleteReportedEventDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.reportedEventService.deleteReportedEventDocument(id).subscribe(
            () => {
              this.currentEvent.reportedEventDocuments = this.currentEvent.reportedEventDocuments.filter(item => item.id !== id);
              this.updateReportedEventDocumentTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  downloadReportedEventDocument(fileId: number, fileName: string): void {
    this.reportedEventService.downloadReportedEventDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  uploadReportedEventDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.currentEvent.id,
      pendingUpdate: false,
      deprecated: false,
      types: [],
      adminOnly: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.reportedEventService.uploadReportedEventDocument(formData).subscribe(
      response => this.currentEvent.reportedEventDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.updateReportedEventDocumentTable();
      });
  }
}
