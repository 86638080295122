import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { SharedModule } from './shared/shared.module';
import { FormlyConfigModule } from './configuration/angular/formly-config.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DefaultLayoutComponent } from '../app/layout/default-layout/default-layout.component';
import { SidebarComponent } from '../app/layout/sidebar/sidebar.component';
import { AccordionDirective } from '../app/layout/sidebar/accordion.directive';
import { AccordionItemDirective } from '../app/layout/sidebar/accordionItem.directive';
import { AccordionAnchorDirective } from '../app/layout/sidebar/accordionanchor.directive';
import { NavbarComponent } from '../app/layout/navbar/navbar.component';
import { FooterComponent } from '../app/layout/footer/footer.component';
import { TranslateComponent } from '../app/layout/widgets/translate.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppTimeoutComponent, TimeoutDialog } from './layout/widgets/timeout.component';

import { environment } from '@env/environment';
import { BASE_URL } from '@core/interceptors/base-url-interceptor';
import { httpInterceptorProviders } from '@core/interceptors';
import { appInitializerProviders } from '@core/initializers';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule, MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { msalConfig, loginRequest, protectedResources } from '../app/auth/auth-config';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ManufacturerModule } from './components/manufacturer/manufacturer.module';
import { ServiceCenterModule } from './components/service-center/service-center.module';
import { TestingLabModule } from './components/testing-lab/testing-lab.module';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.sampleApi.endpoint, protectedResources.sampleApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// date picker
export const DateFormats = {
  parse: {
    dateInput: ['YYYY/MM/DD']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    TimeoutDialog,
    AppTimeoutComponent,
    DefaultLayoutComponent,
    SidebarComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    NavbarComponent,
    FooterComponent,
    TranslateComponent,
    ConfirmDialogComponent
  ],
  imports: [
    BrowserModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    RoutesModule,
    SharedModule,
    MsalModule,
    FormlyConfigModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ManufacturerModule,
    ServiceCenterModule,
    TestingLabModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: BASE_URL, useValue: environment.baseUrl },
    httpInterceptorProviders,
    appInitializerProviders,
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    SwPush,
    SwUpdate
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
