import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestingLabRoutingModule } from './testing-lab-routing.module';
import { SharedModule } from '@shared';
import { AssignedTestingLabsComponent } from './assigned-testing-labs/assigned-testing-labs.component';
import { SearchTestingLabsComponent, TestingLabDetailsDialog } from './search-testing-labs/search-testing-labs.component';
import { TestingLabComplaintsComponent } from './testing-lab-complaints/testing-lab-complaints.component';
import { TestingLabTasksComponent } from './testing-lab-tasks/testing-lab-tasks.component';
import { ApplicantModule } from '../applicant/applicant.module';
import { ComplaintModule } from '../complaint/complaint.module';
import { ComplianceModule } from '../compliance/compliance.module';
import { NotesModule } from '../notes/notes.module';
import { PaymentsModule } from '../payments/paymentsmodule';
import { SiteInspectionModule } from '../site-inspection/site-inspection.module';
import { LicenseChecklistModule } from '../license-checklist/license-checklist.module';
import { PrintTestingLabComponent } from './print-testing-lab/print-testing-lab.component';
import { ManagerNotesModule } from '../manager-notes/manager-notes.module';
import { ReportedEventModule } from '../reported-event/reported-event.module';
import { AssociatedWorkerPermitsModule } from '../associated-worker-permits/associated-worker-permits.module';

@NgModule({
  declarations: [
    AssignedTestingLabsComponent,
    SearchTestingLabsComponent,
    TestingLabComplaintsComponent,
    TestingLabTasksComponent,
    PrintTestingLabComponent,
    TestingLabDetailsDialog
  ],
  imports: [
    CommonModule,
    SharedModule,
    TestingLabRoutingModule,
    PaymentsModule,
    NotesModule,
    ApplicantModule,
    ComplianceModule,
    ComplaintModule,
    SiteInspectionModule,
    LicenseChecklistModule,
    ManagerNotesModule,
    ReportedEventModule,
    AssociatedWorkerPermitsModule
  ]
})
export class TestingLabModule { }
