<div fxLayout="column" [hidden]="viewingInvestigation">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
    <span><strong>Investigations</strong></span>
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event, 'investigations')" placeholder="Filter" />
    </mat-form-field>
    <span fxFlex></span>
    <span *ngIf="editing">Click to Edit</span>
    <button type="button" mat-icon-button color="primary" *ngIf="editing" (click)="addInvestigation()"
      matTooltip="Add Investigation" matTooltipShowDelay=800 aria-label="Add Investigation">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-table [dataSource]="investigationSource" aria-describedby="investigations" class="mat-elevation-z8">
    <ng-container matColumnDef="investigationId">
      <mat-header-cell *matHeaderCellDef>Investigation ID</mat-header-cell>
      <mat-cell *matCellDef="let investigation">
        <span class="mobile-label">Investigation ID</span>
        <span>{{ investigation.investigationId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let investigation">
        <span class="mobile-label">Status</span>
        {{ investigation.status }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startedDate">
      <mat-header-cell *matHeaderCellDef>Date Started</mat-header-cell>
      <mat-cell *matCellDef="let investigation">
        <span class="mobile-label">Date Started</span>
        {{ investigation.startedDate | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="completedDate">
      <mat-header-cell *matHeaderCellDef>Date Completed</mat-header-cell>
      <mat-cell *matCellDef="let investigation">
        <span class="mobile-label">Date Completed</span>
        {{ investigation.completedDate | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignedTo">
      <mat-header-cell *matHeaderCellDef>Assigned To</mat-header-cell>
      <mat-cell *matCellDef="let investigation">
        <span class="mobile-label">Assigned To</span>
        {{staffReference[investigation.assignedTo]}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let investigation" (click)="$event.stopPropagation()">
        <div fxLayout="row" *ngIf="editing">
          <button type="button" mat-icon-button class="m-8" (click)="deleteInvestigation(investigation.id)"
            color="warn" aria-label="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="investigationColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: investigationColumns" (click)="editInvestigation(row)"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" #investigationPaginator="matPaginator"></mat-paginator>
</div>

<!--Investigation Screen-->
<div [@slideRight] *ngIf="viewingInvestigation && !addingSiteInspection" class="m-t-16" >
  <div [@slideLeft] fxLayout="column" fxFlex="100">
    <div fxLayout="column" fxLayoutGap="16px" fxFlex="100">
<!--Investigation Info-->

<div fxLayout="row" fxFlex="100">
  <span fxFlex></span>
  <button mat-button type="button" color="warn" (click)="viewComplianceCase()">Close</button>
  <button *ngIf="editing" mat-button type="button" color="primary" (click)="updateInvestigation(false)">Save</button>
  <button *ngIf="editing" mat-button type="button" color="primary" (click)="updateInvestigation(true)">Save & Close</button>
  <button type="button" mat-icon-button [color]="editing? 'primary':'warn'"
    [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800
    aria-label="Edit Case" (click)="edit()">
    <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
  </button>
</div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">
        <div fxLayout="column" fxFlex="25">
          <span style="font-size:xx-large">Investigation</span>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span class="f-s-16">Investigation ID:</span>
            <span>{{investigation.investigationId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(investigation.investigationId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="!editing">
          <div fxLayout="row" fxLayoutGap="12px">
          <div fxLayout="column"  fxLayout="" fxLayoutGap="16px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
              <span><strong>Status</strong></span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <span>{{investigation.status}}</span>
          </div>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutGap="8px">
              <span><strong>Date Started</strong></span>
            </div>
            <div fxLayout="row" fxLayoutGap="8px">
              <span><strong>Date Completed</strong></span>
            </div>
            <div fxLayout="row" fxLayoutGap="8px">
              <span><strong>Incident Date</strong></span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <span>{{investigation.startedDate | date}}</span>
            <span>{{investigation.completedDate | date}}</span>
            <span>{{investigation.incidentDate | date}}</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="12px">
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutGap="8px">
              <span><strong>Assigned To</strong></span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <span>{{staffReference[investigation.assignedTo]}}</span>
          </div>
        </div>
      </div>
        <div *ngIf="editing">
          <form [formGroup]="investigationForm">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option value="New">New</mat-option>
                    <mat-option value="Working">Working</mat-option>
                    <mat-option value="Closed">Closed</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Date Started</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="startedDate"
                         matInput
                         [matDatepicker]="startedDate"
                         [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="startedDate"></mat-datepicker-toggle>
                  <mat-datepicker #startedDate>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="investigationForm.get('startedDate').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Date Completed</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="completedDate"
                         matInput
                         [matDatepicker]="completedDate"
                         [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="completedDate"></mat-datepicker-toggle>
                  <mat-datepicker #completedDate>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="investigationForm.get('completedDate').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Incident Date</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="incidentDate"
                         matInput
                         [matDatepicker]="incidentDate"
                         [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="incidentDate"></mat-datepicker-toggle>
                  <mat-datepicker #incidentDate>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="investigationForm.get('incidentDate').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Assigned To</mat-label>
                  <mat-select formControlName="assignedTo">
                    <mat-option [value]="">-- None --</mat-option>
                    <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>


<!--Site Inspections-->
<mat-card>
  <div fxLayout="column">
    <mat-card-title>
      Site Inspections
    </mat-card-title>
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'SiteInspections')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add Site Inspection" matTooltipShowDelay=800 aria-label="Add Site Inspection" (click)="createSiteInspection()">
          Add Site Inspection
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="100">

        <mat-table [dataSource]="siteInspectionSource" aria-describedby="siteInspections" class="mat-elevation-z8">

          <ng-container matColumnDef="caseId">
            <mat-header-cell *matHeaderCellDef>Site Inspection ID</mat-header-cell>
            <mat-cell *matCellDef="let siteInspection">
              <span class="mobile-label">Site Inspection ID</span>
              <span>{{ siteInspection.caseId }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let siteInspection">
              <span class="mobile-label">Status</span>
              <span>{{ siteInspection.status }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let siteInspection">
              <span class="mobile-label">Type</span>
              <span>{{ siteInspection.type}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let siteInspection" (click)="$event.stopPropagation()">
              <div fxLayout="row" *ngIf="editing">
                <button type="button" mat-icon-button class="m-8" (click)="deleteSiteInspection(siteInspection.id, siteInspection.caseId)"
                  color="warn" aria-label="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="siteInspectionColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: siteInspectionColumns" (click)="editing? editSiteInspection(row): ''"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #siteInspectionPaginator="matPaginator"></mat-paginator>
      </div>
    </div>
  </div>
</mat-card>


<!--Interviews-->
      <mat-card >
        <div fxLayout="column">
          <mat-card-title>
            Interviews
          </mat-card-title>
          <div fxLayout="row" fxLayoutGap="16px" fxFlex="100" fxLayoutAlign="start start">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
              <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                <input matInput (keyup)="filterTable($event, 'interviews')" placeholder="Filter" />
              </mat-form-field>
              <span fxFlex></span>
              <span *ngIf="editing">Click to Edit</span>
              <button *ngIf="editing" type="button" mat-button color="primary"
                matTooltip="Add Interview" matTooltipShowDelay=800 aria-label="Add Interview" (click)="createInterview()">
                Add Interview
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="100">
              <mat-table [dataSource]="interviewSource" aria-describedby="investigations" class="mat-elevation-z8">
                <ng-container matColumnDef="notes">
                  <mat-header-cell *matHeaderCellDef>Interview Notes</mat-header-cell>
                  <mat-cell *matCellDef="let interview">
                    <span class="mobile-label">Interview Notes</span>
                    <span>{{ interview.notes }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="interviewDocuments">
                  <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
                  <mat-cell *matCellDef="let interview">
                    <span class="mobile-label">Documents</span>
                    <div fxLayout="column">
                      <span *ngFor="let document of interview.interviewDocuments">{{document.name}}</span>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="auditInfo">
                  <mat-header-cell *matHeaderCellDef>Audit Info</mat-header-cell>
                  <mat-cell *matCellDef="let interview">
                    <span class="mobile-label">Audit Info</span>
                    <span>{{ interview.auditInfo | date:'medium'}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let interview" (click)="$event.stopPropagation()">
                    <div fxLayout="row" *ngIf="editing">
                      <button type="button" mat-icon-button class="m-8" (click)="deleteInterview(interview.id)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="interviewColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: interviewColumns" (click)="editInterview(row)"></mat-row>
              </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" #interviewPaginator="matPaginator"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card>
<!--Document Requests-->
<mat-card >
  <div fxLayout="column">
    <mat-card-title>
      Document Requests
    </mat-card-title>
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'DocumentRequests')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add Document Request" matTooltipShowDelay=800 aria-label="Add Document Request" (click)="createDocumentRequest()">
          Add Document Request
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="100">
        <mat-table [dataSource]="documentRequestSource" aria-describedby="investigations" class="mat-elevation-z8">
          <ng-container matColumnDef="documentName">
            <mat-header-cell *matHeaderCellDef>Document Requested</mat-header-cell>
            <mat-cell *matCellDef="let documentRequest">
              <span class="mobile-label">Document Requested</span>
              <span>{{ documentRequest.documentName }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="auditInfo">
            <mat-header-cell *matHeaderCellDef>Requested Date</mat-header-cell>
            <mat-cell *matCellDef="let documentRequest">
              <span class="mobile-label">Requested Date</span>
              <span>{{ documentRequest.auditInfo | date:'medium'}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>Document Request Notes</mat-header-cell>
            <mat-cell *matCellDef="let documentRequest">
              <span class="mobile-label">Document Request Notes</span>
              <span>{{ documentRequest.notes }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="documentRequestDocuments">
            <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
            <mat-cell *matCellDef="let documentRequest">
              <span class="mobile-label">Documents</span>
              <div fxLayout="column">
                <span *ngFor="let document of documentRequest.documentRequestDocuments">{{document.name}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let documentRequest" (click)="$event.stopPropagation()">
              <div fxLayout="row" *ngIf="editing">
                <button type="button" mat-icon-button class="m-8" (click)="deleteDocumentRequest(documentRequest.id)"
                  color="warn" aria-label="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="documentRequestColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: documentRequestColumns" (click)="editing? editDocumentRequest(row): ''"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #documentRequestPaginator="matPaginator"></mat-paginator>
      </div>
    </div>
  </div>
</mat-card>

<!--Photos-->
<mat-card >
  <div fxLayout="column">
    <mat-card-title>
      Photos
    </mat-card-title>
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'Photos')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add Photo" matTooltipShowDelay=800 aria-label="Add Photo" (click)="createInvestigationPhoto()">
          Add Photo
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="100">
        <mat-table [dataSource]="photoSource" aria-describedby="investigations" class="mat-elevation-z8">

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>Photo Description</mat-header-cell>
            <mat-cell *matCellDef="let photo">
              <span class="mobile-label">Photo Description</span>
              <span style="max-width: 90%;">{{photo.description}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="photographer">
            <mat-header-cell *matHeaderCellDef>Taken By</mat-header-cell>
            <mat-cell *matCellDef="let photo">
              <span class="mobile-label">Taken By</span>
              <span>{{ photo.photographer }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="photoTakenDate">
            <mat-header-cell *matHeaderCellDef>Date Taken</mat-header-cell>
            <mat-cell *matCellDef="let photo">
              <span class="mobile-label">Date Taken</span>
              <span>{{ photo.photoTakenDate | date}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="photoLocation">
            <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
            <mat-cell *matCellDef="let photo">
              <span class="mobile-label">Location</span>
              <span>{{ photo.photoLocation }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let photo" (click)="$event.stopPropagation()">
              <div fxLayout="row" *ngIf="editing">
                <button type="button" mat-icon-button class="m-8" (click)="deleteInvestigationPhoto(photo.id)"
                  color="warn" aria-label="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="photoColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: photoColumns" (click)="editing? editInvestigationPhoto(row): ''"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #photoPaginator="matPaginator"></mat-paginator>
      </div>
    </div>
  </div>
</mat-card>

<!--Witnesses-->
<mat-card >
  <div fxLayout="column">
    <mat-card-title>
      Witnesses
    </mat-card-title>
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'Witnesses')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add Witness" matTooltipShowDelay=800 aria-label="Add Witness" (click)="createInvestigationWitness()">
          Add Witness
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="24px" fxFlex="100" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="100">
        <mat-table [dataSource]="witnessSource" aria-describedby="investigations" class="mat-elevation-z8">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let witness">
              <span class="mobile-label">Name</span>
              <span>{{ witness.name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="interviewDate">
            <mat-header-cell *matHeaderCellDef>Interview Date</mat-header-cell>
            <mat-cell *matCellDef="let witness">
              <span class="mobile-label">Interview Date</span>
              <span>{{ witness.interviewDate | date }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let witness" (click)="$event.stopPropagation()">
              <div fxLayout="row" *ngIf="editing">
                <button type="button" mat-icon-button class="m-8" (click)="deleteInvestigationWitness(witness.id)"
                  color="warn" aria-label="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="witnessColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: witnessColumns" (click)="editing? editInvestigationWitness(row): ''"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #witnessPaginator="matPaginator"></mat-paginator>
      </div>
    </div>
  </div>
</mat-card>


<!--Summary-->
      <mat-card >
        <mat-card-title>
          Summary
        </mat-card-title>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutGap="16px" *ngIf="!editing">
            <div fxLayout="column" fxLayoutGap="16px">
              <span>{{investigation.summary}}</span>
            </div>
          </div>

          <div fxLayout="row" fxFlex="100" *ngIf="editing">
            <form [formGroup]="investigationForm" fxFlex="100">
              <mat-form-field formControlName="summary" fxFlex="100">
                <mat-label>Summary of investigation</mat-label>
                <textarea matInput formControlName="summary"></textarea>
              </mat-form-field>
            </form>
          </div>
          <span fxFlex></span>
        </div>
      </mat-card>
    </div>
  </div>
</div>




<!--Edit/Add Site inspection-->
<div [@slideLeft] *ngIf="addingSiteInspection" fxLayout="column">
  <form [formGroup]="siteInspectionForm">
    <div fxLayout="column">
      <span style="font-size: x-large" fxFlex><strong>Site Inspection for {{investigation.investigationId}}</strong></span>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <span>Site Inspection ID:</span>
           <span>{{siteInspection.caseId}}</span>
          <button mat-icon-button (click)="sharedService.copyToClipboard(siteInspection.caseId)" color="primary" matTooltip="Copy to Clipboard"
            matTooltipShowDelay=800 aria-label="Copy to Clipboard">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>

        <span fxFlex></span>
        <div fxLayout="row">
          <button type="button" mat-button color="primary" (click)="updateSiteInspection(false)">Save</button>
          <button type="button" mat-button color="primary" (click)="updateSiteInspection(true)">Save and Close</button>
          <button type="button" mat-button color="warn" (click)="closeInspection()">Close</button>
        </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-t-8">
        <mat-form-field fxFlex="15" fxFlex.lt-md="100">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option value="New">New</mat-option>
            <mat-option value="Working">Working</mat-option>
            <mat-option *ngIf="authService.isAdmin" value="Complete">Complete</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="15" fxFlex.lt-md="100">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option value="Compliance">Compliance</mat-option>
            <mat-option value="Initial">Change Request</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="15" fxFlex.lt-md="100">
          <mat-label>Visit Date</mat-label>
          <input placeholder="mm/dd/yyyy"
                 formControlName="visitDate"
                 matInput
                 [matDatepicker]="datepickerVisit"
                 [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="datepickerVisit"></mat-datepicker-toggle>
          <mat-datepicker #datepickerVisit>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="siteInspectionForm.get('visitDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div fxLayout="column" fxLayoutGap="8px" class="m-t-16" style="overflow-y: auto; height: 400px">
    <div *ngFor="let rule of siteInspection.siteInspectionRules">
      <ng-container [ngTemplateOutlet]="inspectionRuleTpl"
                    [ngTemplateOutletContext]="{inspectionRule: rule, locked: siteInspection.locked}">
      </ng-container>
    </div>
  </div>

  <form [formGroup]="siteInspectionForm" class="m-t-8">
    <span><strong>Site Visit Summary</strong></span>
    <div fxLayout="row" class="m-t-8">
      <mat-form-field fxFlex="100">
        <textarea formControlName="summary" matInput rows="5" placeholder="Summary" type="text"></textarea>
      </mat-form-field>
    </div>
  </form>

  <div fxLayout="row">
    <button type="button" mat-button color="primary" (click)="updateSiteInspection(false)">Save</button>
    <button type="button" mat-button color="primary" (click)="updateSiteInspection(true)">Save and Close</button>
    <button type="button" mat-button color="warn" (click)="closeInspection()">Close</button>
  </div>
</div>
<ng-template #inspectionRuleTpl let-inspectionRule="inspectionRule" let-locked="locked">
  <mat-card>
    <mat-card-content>
      <mat-card>
        <mat-card-title>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <span>Segment {{inspectionRule.rule.segmentNumber}}</span>
            <span fxFlex>{{inspectionRule.rule.segment}}</span>
            <span>OAR Rule {{inspectionRule.rule.statute}}</span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="column">
              <span><strong>Question</strong></span>
              <span class="text-wrap" *ngIf="entityType === sharedService.manufacturer">{{inspectionRule.rule.questionManufacturer}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.serviceCenter">{{inspectionRule.rule.questionServiceCenter}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.testingLab">{{inspectionRule.rule.questionLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Answer</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.answer}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Guidance</strong></span>
              <span class="text-wrap" *ngIf="entityType === sharedService.manufacturer">{{inspectionRule.rule.guidanceManufacturer}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.serviceCenter">{{inspectionRule.rule.guidanceServiceCenter}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.testingLab">{{inspectionRule.rule.guidanceLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span class="m-t-8"><strong>If Non-Compliant</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.nonCompliant}}</span>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="" class="m-b-16">
              <mat-checkbox [(ngModel)]="inspectionRule.compliant" [disabled]="locked">
                Compliant
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="inspectionRule.educationProvided" [disabled]="locked">
                Education Provided
              </mat-checkbox>
            </div>
            <div fxLayout="column">
              <span><strong>Notes</strong></span>
              <textarea matInput cols="2" placeholder="Notes" [(ngModel)]="inspectionRule.notes" [disabled]="locked"></textarea>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</ng-template>
