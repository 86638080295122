import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { INote } from 'app/models/notes/note';
import { SharedService } from 'app/services/core/shared.service';
import { NoteService } from 'app/services/notes/notes.service';
import { ToastrService } from 'ngx-toastr';
import { DialogEditNoteComponent } from '../edit/edit-note-dialog';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-manage-notes',
  templateUrl: './manage-notes.component.html',
  styleUrls: ['./manage-notes.component.scss']
})

export class ManageNotesComponent implements OnInit {
  @Input() entityId = "";
  @Input() entityType = "";
  @Input() editingNotes = false;
  @Input() cardColor: string = '';

  public processing: boolean = false;
  public notes: INote[] = [];

  public today: Date = new Date(Date.now());
  public yesterday: Date = new Date(Date.now() - (24 * 60 * 60 * 1000));
  public notesColumns: string[] = ['text', 'dateLastUpdated', 'actions'];
  public notesSource = new MatTableDataSource<INote>(this.notes);
  @ViewChild('notesPaginator', {static: false}) notesPaginator: MatPaginator;
  @ViewChild('notesSort', {static: false}) notesSort: MatSort;

  constructor(public sharedService: SharedService,
              public noteService: NoteService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              public authService: AuthService) { }

  ngOnInit() {
    this.loadNotes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityId && !changes.entityId.firstChange) {
      this.reload();
    }
  }

  reload() {
    this.loadNotes();
  }

  loadNotes(): void {
    this.processing = true;
    this.noteService.getNotesByEntity(this.entityId, this.entityType).subscribe(
      response => this.notes = response,
      error => console.log('error', error),
      () => {

        this.notesSource.data = this.notes;
        this.notesSource.paginator = this.notesPaginator;
        this.notesSource.sort = this.notesSort;

        this.processing = false;
      }
    );
  }

  deleteNote(noteId: number) {
    this.sharedService.openConfirm(
      "Are you sure you want to delete this note? Continue?"
    );
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          this.processing = true;
          this.noteService.deleteNote(noteId).subscribe(
            () => {
              this.loadNotes();
              this.processing = false;
              this.toastr.success("Note Removed");
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addNewNote() {
    let newNote: INote = {
      id: 0,
      archived: false,
      createdBy: '',
      dateCreated: '',
      dateLastUpdated: '',
      entityId: this.entityId,
      entityType: this.entityType,
      generic: true,
      lastUpdatedBy: '',
      text: ''
    }

    const dialogRef = this.dialog.open(DialogEditNoteComponent, {
      data: newNote,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.isCancelled(response)) {
        this.loadNotes();
      }
    });
  }

  editNote(note: INote) {
      const dialogRef = this.dialog.open(DialogEditNoteComponent, {
        data: note,
        minWidth: !this.sharedService.mobile ? '700px' : '300px',
        maxWidth: !this.sharedService.mobile ? '800px' : '300px',
        maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (!this.isCancelled(response)) {
          this.loadNotes();
        }
      });
  }

  canEdit(note:INote) : boolean {
    if(this.authService.isAdmin)
      return true;
    const noteDate = new Date(note.dateCreated);
    return (noteDate > this.yesterday) && (this.authService.unformattedUsername == note.createdBy);
  }

  canDelete(note:INote): boolean {
    return(this.authService.isAdmin);
  }

  isCancelled(response: any): boolean {
    return response === 'cancel' || response === null || response === '' || response === undefined;
  }
}
