export const environment = {
  production: false,
  baseUrl: '',
  useHash: false,
  hmr: false,
  showThemeToggle: true,
  translateText: false,
  collapsibleSidebar: true,
  useAppInsights: false,
  appInsightsInstrumentationKey: 'e6f30bb6-a160-4d69-a8d2-8642430020c1',
  showChatbot: false,
  useTimeout: true,
  idleInterval: 3600,
  build: 'Test',
  version: '3.2.13',
  displaySidebar: true,
  authEntireSite: false,
  useAdIdentity: false,
  theme: 'light',
  publicApi: 'https://test-team-psilocybin.oregon.gov/api/public/',
  privateApi: 'https://test-team-psilocybin.oregon.gov/api/private/',
  /*
    Azure AD values
    privateApi: endpoint msal should append Bearer tokens to
    clientId: Azure AD application client id
    scopes: Exposed API scope from Azure AD
    groups: Azure AD group guids that are authorized - if empty no groups required
    authority: https://login.microsoftonline.com/<Azure AD application tenant id>/
    redirectUrl: Url to redirect to after login or logout
  */
  clientId: '817cff66-e6a4-44a7-a218-37e196c91493',
  scopes: ['api://817cff66-e6a4-44a7-a218-37e196c91493/user.access'],
  groups: ['8474cfc8-154b-4507-b834-3eb85a9bd5d0'],
  authority: 'https://login.microsoftonline.com/658e63e8-8d39-499c-8f48-13adc9452f4c/',
  redirectUri: 'https://test-team-psilocybin.oregon.gov/',
  enableHttpsRedirect: true,
  defaultRoute: '/training/assigned',
  internalMessaging: {
    enabled: true,
    canRespond: true,
    canCreate: false,
    canAttach: true,
    canSetPriority: true,
    canViewArchived: true,
    canAnnounce: false,
    canViewStaffMessages: true,
    defaultStaffRecipients: [
      { recipient: "Psilocybin Licensing Team",
        name: "Psilocybin Licensing Team",
        email: "Licensing.Psilocybin@odhsoha.oregon.gov"
      },
      { recipient: "Psilocybin Compliance Team",
        name: "Psilocybin Compliance Team",
        email: "Compliance.Psilocybin@odhsoha.oregon.gov"
      },
      { recipient: "Product Tracking Team",
      name: "Product Tracking Team",
      email: "Compliance.Psilocybin@odhsoha.oregon.gov"
      }
    ]
  },
  reports: {
    powerBi: "https://app.powerbigov.us/groups/e96bb9a6-353d-4ab3-aaf6-4be807642579"
  }
};
