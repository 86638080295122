import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IApplicationTrackingLicense } from "app/models/licenses/application-tracking-license";
import { IStaffType } from "app/models/user-info/staff-type";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})

export class ActiveApplicationTrackingService {
  private api: string = environment.privateApi + 'ActiveApplicationTracking/';

  constructor(private http: HttpClient) { }

  getLicenses(entityType: string): Observable<IApplicationTrackingLicense[]>{
    return this.http.get<IApplicationTrackingLicense[]>(this.api + "GetLicenses/" + entityType);
  }
  getStaff(): Observable<IStaffType[]>{
    return this.http.get<IStaffType[]>(this.api + 'GetStaff');
  }

  getExpiredLicenses(): Observable<IApplicationTrackingLicense[]>{
    return this.http.get<IApplicationTrackingLicense[]>(this.api + "GetExpiredLicenses");
  }

  getUpForRenewalLicenses(): Observable<IApplicationTrackingLicense[]>{
    return this.http.get<IApplicationTrackingLicense[]>(this.api + "GetUpForRenewalLicenses");
  }

  hideLicense(license: IApplicationTrackingLicense): Observable<boolean>{
    return this.http.post<boolean>(this.api + "HideLicense", license);
  }
}
