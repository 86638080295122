<!--
  Add Complaint
-->
<mat-dialog-content>
  <form [formGroup]="complaintForm">
    <div fxLayout="column" class="m-t-24" fxLayoutGap="8px">

      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Ready For Compliance Manager Review" [disabled]="data.caseId != ''">Ready For Compliance Manager Review</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Complaint Submitted" [disabled]="data.caseId != ''">Complaint Submitted</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Complaint Open" [disabled]="!authService.isAdmin || data.caseId != ''">Complaint Open</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Complaint Closed- No Investigation Opened" [disabled]="!authService.isAdmin || data.caseId != ''">Complaint Closed- No Investigation Opened</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Investigation Open" [disabled]="!authService.isAdmin || data.caseId != ''">Investigation Open</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Investigation Closed- Case Still in Process" [disabled]="!authService.isAdmin || data.caseId != ''">Investigation Closed- Case Still in Process</mat-option>
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" value="Investigation Closed- No Administrative Action" [disabled]="!authService.isAdmin || data.caseId != ''">Investigation Closed- No Administrative Action</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Notice Status</mat-label>
        <mat-select formControlName="noticeStatus">
          <mat-option value="" [disabled]="!authService.isAdmin || data.caseId != ''">--None--</mat-option>
          <mat-option value="Notice Issued" [disabled]="!authService.isAdmin || data.caseId != ''">Notice Issued</mat-option>
          <mat-option value="Hearing Requested" [disabled]="!authService.isAdmin || data.caseId != ''">Hearing Requested</mat-option>
          <mat-option value="Proposed Order Issued" [disabled]="!authService.isAdmin || data.caseId != ''">Proposed Order Issued</mat-option>
          <mat-option value="Exceptions Submitted" [disabled]="!authService.isAdmin || data.caseId != ''">Exceptions Submitted</mat-option>
          <mat-option value="Default Final Order Issued" [disabled]="!authService.isAdmin || data.caseId != ''">Default Final Order Issued</mat-option>
          <mat-option value="Final Order Issued" [disabled]="!authService.isAdmin || data.caseId != ''">Final Order Issued</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Contact Name</mat-label>
        <input matInput placeholder="First Last" formControlName="name">
        <mat-error *ngIf="complaintForm.get('name').invalid">
          Please provide your name
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Contact Email</mat-label>
        <input matInput placeholder="Email" formControlName="contactEmail">
        <mat-error *ngIf="complaintForm.get('contactEmail').hasError('email')">
          This doesn't look like a valid email address
        </mat-error>
        <mat-error *ngIf="complaintForm.get('contactEmail').hasError('required')">
          Please provide your email
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Contact Phone</mat-label>
        <input matInput placeholder="Phone" formControlName="contactNumber" mask="(000) 000-0000 000000" [validation]="false">
        <mat-error *ngIf="complaintForm.get('contactNumber').hasError('required')">
          Please provide a phone number
        </mat-error>
        <mat-error *ngIf="complaintForm.get('contactNumber').hasError('pattern')">
          Phone should match this format: (999) 999-9999 999999
        </mat-error>
      </mat-form-field>

      <span>Preferred method of contact</span>
      <mat-radio-group fxLayout="row" aria-label="preferred contact method" formControlName="preferredContact" class="radio-group">
        <mat-radio-button class="radio-button" value="email">Email</mat-radio-button>
        <mat-radio-button class="radio-button" value="phone">Phone</mat-radio-button>
      </mat-radio-group>

      <mat-form-field fxFlex>
        <mat-label>Identity Concerns</mat-label>
        <textarea matInput rows="2" fxFlex="100" type="text" placeholder="Concerns" formControlName="identityConcern"></textarea>
        <mat-error *ngIf="complaintForm.get('identityConcern').invalid">
          Please provide a response
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> User Entered {{data.type}} Name</mat-label>
        <textarea matInput rows="2" fxFlex="100" type="text" placeholder="Concerns" formControlName="parentLicenseName"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{data.type}} ID</mat-label>
        <input matInput placeholder="License Id" formControlName="parentId">
        <mat-error *ngIf="complaintForm.get('parentId').invalid">
          The id provided could not be found
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Complaint</mat-label>
        <textarea matInput rows="3" fxFlex="100" type="text" placeholder="Complaint" formControlName="complaintText"></textarea>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Previous Reporting</mat-label>
        <textarea matInput rows="3" fxFlex="100" type="text" placeholder="Previous Reporting" formControlName="previousReporting"></textarea>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Signature</mat-label>
        <input matInput placeholder="First Last" formControlName="signature">
        <mat-error *ngIf="complaintForm.get('signature').invalid">
          A signature is required
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="8px" class="m-b-24">
        <span fxFlex></span>
        <button *ngIf="isParentIdEmptyOrSpaces()" mat-stroked-button type="submit" color="primary" [disabled]="!complaintForm.valid" (click)="submitComplaint()">Unassociate Complaint</button>
        <button *ngIf="!isParentIdEmptyOrSpaces()" mat-stroked-button type="submit" color="primary" [disabled]="!complaintForm.valid" (click)="submitComplaint()">Submit</button>
        <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</mat-dialog-content>
