import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEditAddUserProfile } from 'app/models/user-info/edit-add-user-profile';
import { SharedService } from 'app/services/core/shared.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  public type: string = 'Add';
  public userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", Validators.required),
    lastName: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", [Validators.required, Validators.email])
  });
  licenseCount: boolean = false;

  constructor(public sharedService: SharedService,
              public userService: UserService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<AddUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public user: IEditAddUserProfile) { }

  ngOnInit() {
    if(this.user.userId !== null && this.user.userId !== ''){
      this.type = 'Edit';
      this.userForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email
      });
    }
  }

  addUser() : void {
    let form = this.userForm.value;
    this.user.firstName = form.firstName;
    this.user.lastName = form.lastName;
    this.user.email = form.email;

    this.userService.checkUserLicenses(this.user).subscribe(
      response => {
      this.licenseCount = response;
      },
      error => console.log('error', error),
      () =>
      {
        if(!this.licenseCount)
          this.saveUser();
      });
}


  saveUser(): void {
    let form = this.userForm.value;
    this.user.firstName = form.firstName;
    this.user.lastName = form.lastName;
    this.user.email = form.email;
    this.userService.updateUser(this.user).subscribe(
      response => this.dialogRef.close(response),
      error => console.log('error', error)
    );
  }
  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
