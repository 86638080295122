<section class="p-b-16">
  <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
    <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs('Original_Manufacturer_License.pdf')">
      Export to PDF
    </button>

    <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
  </div>
</section>
<mat-dialog-content>
  <kendo-pdf-export #pdf
                    paperSize="letter"
                    [scale]="0.75"
                    margin="1cm"
                    keepTogether="span">
    <div fxLayout="column" fxLayoutGap="8px" id="certificate" #certificate class="f-s-16">
      <div fxLayout="row" fxLayoutGap="8px">
        <div fxLayout="column" fxFlex>
          <span class="f-s-18"><strong>PUBLIC HEALTH DIVISION</strong></span>
          <span class="f-s-18"><strong>CENTER FOR HEALTH PROTECTION</strong></span>
          <span><strong>Oregon Psilocybin Services</strong></span>
          <span><a href="http://oregon.gov/psilocybin">http://oregon.gov/psilocybin</a></span>
        </div>
        <img class="p-4" src="../../../../../assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
      </div>

      <mat-card>
        <mat-card-title>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <span>Manufacturer</span>
            <span class="f-s-18">{{license.licenseId}}</span>
            <span fxFlex></span>
          </div>
        </mat-card-title>

        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="16px">
            <mat-card fxFlex="50">
              <mat-card-title>
                <span>Manufacturer Information</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column"  fxLayoutGap="8px">
                  <span class="f-s-16"><strong>Manufacturer Operational Name</strong></span>
                  <span>{{license.entityName}}</span>

                  <span class="f-s-16"><strong>Manufacturer Website</strong></span>
                  <span>{{license.entityWebsite}}</span>

                  <span class="f-s-16"><strong>Registered Business</strong></span>
                  <div fxLayout="row">
                    <mat-checkbox [checked]="license.businessInOregon" disabled>
                      <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" *ngIf="license.businessInOregon" [@slideInOut]>
                    <div fxLayout="column">
                      <span>Oregon Secretary of State Registry Number</span>
                      <span>{{license.secretaryofStateNumber}}</span>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <mat-checkbox [checked]="license.registeredBusiness" disabled>
                      <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" *ngIf="license.registeredBusiness" [@slideInOut]>
                    <div fxLayout="column">
                      <span>Location Registered</span>
                      <span>{{license.registeredIn}}</span>
                    </div>
                    <div fxLayout="column">
                      <span>Registration Number</span>
                      <span>{{license.registryNumber}}</span>
                    </div>
                  </div>

                  <span class="f-s-16"><strong>Previous License or Permit</strong></span>
                  <mat-checkbox [checked]="license.previousLicense" disabled>
                    <span class="text-wrap">Manufacturer has applied for or received any other Oregon Psilocybin licenses</span>
                  </mat-checkbox>
                  <div fxLayout="column" *ngIf="license.previousLicense" [@slideInOut]>
                    <span>{{license.affiliatedLicenses}}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxFlex="100">
                <mat-card>
                  <mat-card-title>
                    <span>Primary Point of Contact</span>
                  </mat-card-title>
                  <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="16px">
                      <div fxLayout="column">
                        <span class="f-s-16"><strong>Contact Name</strong></span>
                        <span>{{license.primaryContactName}}</span>
                      </div>
                      <div fxLayout="column">
                        <span class="f-s-16"><strong>Contact Phone</strong></span>
                        <span>{{license.primaryContactNumber | mask: '(000) 000-0000 000000'}}</span>
                      </div>
                      <div fxLayout="column">
                        <span class="f-s-16"><strong>Email Address</strong></span>
                        <span>{{license.primaryContactEmail}}</span>
                      </div>
                      <div fxLayout="column">
                        <span class="f-s-16"><strong>Preferred Language</strong></span>
                        <span>{{license.language}}</span>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>

<!--Account Information-->
          <div fxLayout="row" fxLayoutGap="16px">
            <mat-card fxFlex="50">
                <div fxLayout="column"  fxLayoutGap="8px">
                  <div fxLayout="row" fxFlex="100">
                      <mat-card-title>
                        <span>Account Information</span>
                      </mat-card-title>
                  </div>
                  <div fxLayout="row" fxFlex="100">
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <span><strong>Account Name</strong></span>
                          <span>{{license.accountName}} </span>
                          <span><strong>Account Email</strong></span>
                          <span>{{license.accountEmail}} </span>
                      </div>
                    </mat-card-content>
                  </div>
                </div>
            </mat-card>
          </div>

<!-- Endorsements & Address-->
          <div fxLayout="row" fxLayoutGap="16px">
            <mat-card fxFlex="50">
              <mat-card-content>
                <div fxLayout="column"  fxLayoutGap="8px">
                  <div fxLayout="row" fxFlex="100">
                    <mat-card fxFlex="100">
                      <mat-card-title>
                        <span>Endorsements and Attestations</span>
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <span class="f-s-16">
                            <strong>Endorsements requested</strong>
                          </span>

                          <div fxLayout="column" fxLayoutGap="8px">
                            <mat-checkbox [checked]="license.fungiCultivation" disabled>
                              <span class="text-wrap">Fungi Cultivation</span>
                            </mat-checkbox>
                            <mat-checkbox [checked]="license.psilocybinExtraction" disabled>
                              <span class="text-wrap">Psilocybin Extraction</span>
                            </mat-checkbox>
                            <mat-checkbox [checked]="license.ediblePsilocybinProduction" disabled>
                              <span class="text-wrap">Edible Psilocybin Production</span>
                            </mat-checkbox>

                            <mat-checkbox [checked]="license.denyEndorsementAttestation" disabled>
                              <span class="text-wrap">Applicant Attests to all endorsement statements</span>
                            </mat-checkbox>

                            <mat-checkbox *ngIf="license.ediblePsilocybinProduction" [checked]="license.edibleAttestation" disabled>
                              <span class="text-wrap">Applicant Attests to all edible endorsement statements</span>
                            </mat-checkbox>
                          </div>

                          <span class="f-s-16">
                            <strong>Financial Interest</strong>
                          </span>
                          <mat-checkbox [checked]="license.financialAttestation" disabled>
                            <span class="text-wrap">Applicant attests to all financial stuff</span>
                          </mat-checkbox>

                          <span class="f-s-16">
                            <strong>License Attestation</strong>
                          </span>
                          <mat-checkbox [checked]="license.attestation" disabled>
                            <span class="text-wrap">Applicant attests to everything</span>
                          </mat-checkbox>

                          <span class="f-s-16">
                            <strong>Registration Attestation</strong>
                          </span>
                          <mat-checkbox [checked]="license.registrationAttestation" disabled>
                            <span class="text-wrap">Applicant attests to registration requirements</span>
                          </mat-checkbox>

                          <span class="f-s-16">
                            <strong>Directory Consent</strong>
                          </span>
                          <mat-checkbox [checked]="license.directoryConsent" disabled>
                            <span class="text-wrap">
                              Applicant consents to having license in the OPS License Directory
                            </span>
                          </mat-checkbox>

                          <span class="f-s-16">
                            <strong>Electronic Signature</strong>
                          </span>
                          <span>{{license.electronicSignature}}</span>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="50">
              <mat-card-content>
                <div fxLayout="column"  fxLayoutGap="8px">
                  <div fxLayout="row" fxFlex="100">
                    <mat-card fxFlex="100">
                      <mat-card-title>
                        <span>Manufacturer Address</span>
                      </mat-card-title>
                          <mat-card-content>
                            <div fxLayout="column" fxLayoutGap="16px">
                              <span class="f-s-18"><strong>Physical Address</strong></span>
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                <span>{{license.physicalStreet}}</span>
                                <span>{{license.physicalCity}}</span>
                                <span>{{license.physicalState}}</span>
                                <span>{{license.physicalZip}}</span>
                                <span>{{license.physicalCounty}}</span>
                              </div>

                              <div fxLayout="column">
                                <span><strong>Description of the premises</strong></span>
                                <div>
                                  <textarea matInput placeholder="Describe the Premises" cols="2" [value]="license.premisesDescription"></textarea>
                                </div>
                              </div>
                            </div>

                            <div fxLayout="column" class="m-t-8">
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                                <span class="f-s-18"><strong>Mailing Address</strong></span>
                                <mat-checkbox [checked]="license.mailingIsPhysical" disabled>
                                  Same as Physical
                                </mat-checkbox>
                              </div>
                              <div *ngIf="!license.mailingIsPhysical" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                <span>{{license.mailingStreet}}</span>
                                <span>{{license.mailingCity}}</span>
                                <span>{{license.mailingState}}</span>
                                <span>{{license.mailingZip}}</span>
                                <span>{{license.mailingCounty}}</span>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

<!-- Applicants-->
              <div fxLayout="row" fxLayoutGap="16px">
                <mat-card fxFlex="100">
                  <mat-card-title>
                    <span>Applicants</span>
                  </mat-card-title>
                  <mat-card-content  fxLayoutGap="10px" >
                    <div fxLayoutGap="18px" fxLayout="column">
                      <div *ngFor="let applicant of license.applicants" fxLayout="row" fxFlex="100">
                        <mat-card fxFlex="100">
                         <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
                           <div fxLayout="row" fxLayoutGap="8px">
                             <div fxLayout="column" fxFlex="35">
                               <span><strong>Legal Name</strong></span>
                               <div fxLayout="row" fxLayoutGap="8px">
                                 <span>{{applicant.legalFirstName}}</span>
                                 <span>{{applicant.legalMiddleName}}</span>
                                 <span>{{applicant.legalLastName}}</span>
                               </div>
                             </div>

                             <div fxLayout="column" fxFlex="35">
                               <span><strong>Preferred Name</strong></span>
                               <div fxLayout="row" fxLayoutGap="8px">
                                 <span>{{applicant.preferredFirstName}}</span>
                                 <span>{{applicant.preferredMiddleName}}</span>
                                 <span>{{applicant.preferredLastName}}</span>
                               </div>
                             </div>
                             <div fxLayout="column" fxFlex="30">
                               <span><strong>Other Names or Aliases</strong></span>
                               <span>{{applicant.alternateNames}}</span>
                             </div>
                           </div>

                           <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                            <div fxLayout="column" fxFlex="25">
                              <span><strong>DOB</strong></span>
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span>{{applicant.dob | date}}</span>
                              </div>
                            </div>
                            <div fxLayout="column"  fxLayoutGap="8px" fxFlex="75">
                              <span><strong>SSN</strong></span>
                              <div fxLayout="row" fxLayoutGap="8px"  fxLayoutGap="8px">
                                <span>{{applicant.ssn | mask:"000-00-0000"}}</span>

                                <mat-checkbox [checked]="applicant.noSSN" disabled>
                                  Applicant has no SSN
                                </mat-checkbox>
                                <mat-checkbox [checked]="applicant.ssnAttestation" disabled>
                                  <span class="text-wrap">
                                    Applicant consents to use of SSN
                                  </span>
                                </mat-checkbox>
                              </div>
                            </div>
                           </div>

                           <div fxLayout="row" fxLayoutGap="8px" flFlex="100">
                             <div fxLayout="column">
                               <span *ngIf="!applicant.heldLicense && !applicant.deniedLicense && !applicant.subjectToDiscipline && !applicant.suedForDamages && !applicant.settledAllegations && !applicant.allegedAbuse"> Applicant answered no to all additional questions. </span>
                               <mat-checkbox [checked]="applicant.heldLicense" disabled *ngIf="applicant.heldLicense">
                                 <span class="text-wrap">
                                   In the last 10 years, Applicant has held a license in Oregon or in another state or country.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.deniedLicense" disabled *ngIf="applicant.deniedLicense">
                                 <span class="text-wrap">
                                   In the last 10 years, Applicant has been denied a license.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.subjectToDiscipline" disabled *ngIf="applicant.subjectToDiscipline">
                                 <span class="text-wrap">
                                   In the last 10 years, Applicant has been subject to discipline by the entity that issued their license or had their license suspended or revoked.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.suedForDamages" disabled *ngIf="applicant.suedForDamages">
                                 <span class="text-wrap">
                                   In the last 10 years, Applicant has been sued for money damages related to work they provided under a license.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.settledAllegations" disabled *ngIf="applicant.settledAllegations">
                                 <span class="text-wrap">
                                   In the last 10 years, Applicant has settled allegations or claims against them related to a license.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.allegedAbuse" disabled *ngIf="applicant.allegedAbuse">
                                 <span class="text-wrap">
                                   Applicant has had someone allege that they abused a child or an adult.
                                 </span>
                               </mat-checkbox>
                               <mat-checkbox [checked]="applicant.substantiatedAllegation" disabled *ngIf="applicant.substantiatedAllegation">
                                 <span class="text-wrap">
                                   Allegations were substantiated
                                 </span>
                               </mat-checkbox>
                             </div>
                           </div>

                           <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span class="f-2-20"><strong>Primary Phone</strong></span>
                              <span>{{applicant.phone | mask:'(000)-000-0000 000000'}}</span>
                            </div>
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span class="f-2-20"><strong>Alternate Phone</strong></span>
                              <span>{{applicant.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
                            </div>
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span class="f-2-20"><strong>Primary Email Address</strong></span>
                              <span>{{applicant.email}}</span>
                            </div>
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span class="f-2-18"><strong>Preferred Language</strong></span>
                              <span>{{applicant.language}}</span>
                            </div>
                           </div>

                           <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                              <mat-checkbox [checked]="applicant.previousLicense" disabled>
                                <span class="text-wrap">Applicant has other licenses</span>
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                              <span><strong>Other Oregon Psilocybin licenses</strong></span>
                              <span>{{applicant.affiliatedLicenses}}</span>
                            </div>
                           </div>

                           <div fxLayout="row" fxLayoutGap="8px">
                             <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                               <div fxLayout="column">
                                 <span><strong>Physical Address</strong></span>
                                 <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                   <span>{{applicant.physicalStreet }}</span>
                                   <span>{{applicant.physicalCity }}</span>
                                   <span>{{applicant.physicalState }}</span>
                                   <span>{{applicant.physicalZip }}</span>
                                   <span>{{applicant.physicalCounty }}</span>
                                 </div>
                               </div>

                               <div fxLayout="column" class="m-t-8">
                                 <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                                   <span><strong>Mailing Address</strong></span>
                                   <mat-checkbox [checked]="applicant.mailingIsPhysical" disabled>
                                     Same as physical
                                   </mat-checkbox>
                                 </div>
                                 <div fxLayout="column" [@slideInOut] *ngIf="!applicant.mailingIsPhysical">
                                   <span>{{applicant.mailingStreet }}</span>
                                   <span>{{applicant.mailingCity }}</span>
                                   <span>{{applicant.mailingState }}</span>
                                   <span>{{applicant.mailingZip }}</span>
                                   <span>{{applicant.mailingCounty }}</span>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </mat-card>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

<!-- Entity Applicants-->
              <div fxLayout="row" fxLayoutGap="16px">
                <mat-card fxFlex="100">
                  <mat-card-title>
                    <span>Entity Applicants</span>
                  </mat-card-title>
                  <mat-card-content  fxLayoutGap="10px" >
                    <div fxLayoutGap="18px" fxLayout="column">
                      <div *ngFor="let applicant of license.entityApplicants" fxLayout="row" fxFlex="100">
                        <mat-card fxFlex="100">
                          <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">

                            <div fxLayout="row" fxLayoutGap="8px">
                              <div fxLayout="column" fxFlex="35">
                                <span><strong>Legal Entity Name</strong></span>
                                <div fxLayout="row" fxLayoutGap="8px">
                                  <span>{{applicant.entityName}}</span>
                                </div>
                              </div>

                              <div fxLayout="column" fxFlex="35">
                                <span><strong>Contact Phone</strong></span>
                                <div fxLayout="row" fxLayoutGap="8px">
                                  <span>{{applicant.primaryContactNumber | mask:'(000)-000-0000 000000'}}</span>
                                </div>
                              </div>
                              <div fxLayout="column" fxFlex="30">
                                <span><strong>Email Address</strong></span>
                                <span>{{applicant.primaryContactEmail}}</span>
                              </div>
                            </div>

                            <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                              <div fxLayout="column" fxFlex="25">
                                <span><strong>Preferred Language</strong></span>
                                <div fxLayout="row" fxLayoutGap="8px">
                                  <span>{{applicant.language}}</span>
                                </div>
                              </div>
                              <div fxLayout="column" fxFlex="25">
                                <span><strong>Previous License or Permit</strong></span>
                                  <mat-checkbox [checked]="applicant.previousLicense" disabled>
                                    <span class="text-wrap">
                                      Entity has applied for or received other licenses from Oregon Psilocybin Services
                                    </span>
                                  </mat-checkbox>
                              </div>
                              <div fxLayout="column" fxFlex="25">
                                <span><strong>Oregon Psilocybin license Ids</strong></span>
                                <span>{{applicant.affiliatedLicenses}}</span>
                              </div>
                            </div>

                            <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                              <div fxLayout="column" fxFlex="50">
                                <span><strong>Registered Business</strong></span>
                                <div fxLayout="row" fxLayoutGap="8px">
                                  <mat-checkbox [checked]="applicant.businessInOregon" disabled>
                                    <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                                  </mat-checkbox>
                                </div>
                                <div fxLayout="row" fxLayoutGap="8px">
                                  <mat-checkbox [checked]="applicant.registeredBusiness" disabled>
                                    <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                                  </mat-checkbox>
                                </div>
                              </div>
                            </div>

                              <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                                <div fxLayout="column" fxFlex="25">
                                  <span><strong>Oregon Secretary of State Registry Number</strong></span>
                                  <div fxLayout="row" fxLayoutGap="8px">
                                    <span>{{applicant.secretaryofStateNumber}}</span>
                                  </div>
                                </div>

                                <div fxLayout="column" fxFlex="25">
                                  <span><strong>Location Registered</strong></span>
                                  <div fxLayout="row" fxLayoutGap="8px">
                                    <span>{{applicant.registeredIn}}</span>
                                  </div>
                                </div>

                                <div fxLayout="column" fxFlex="25">
                                  <span><strong>Registration Number</strong></span>
                                  <div fxLayout="row" fxLayoutGap="8px">
                                    <span>{{applicant.registryNumber}}</span>
                                  </div>
                                </div>
                              </div>


                            <div fxLayout="row" fxLayoutGap="8px">
                              <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                              <div fxLayout="column">
                                <span><strong>Physical Address</strong></span>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                                  <mat-checkbox [checked]="applicant.physicalIsHeadquarters" disabled>
                                    Same as Headquarters
                                  </mat-checkbox>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="!applicant.physicalIsHeadquarters">
                                  <span>{{applicant.physicalStreet }}</span>
                                  <span>{{applicant.physicalCity }}</span>
                                  <span>{{applicant.physicalState }}</span>
                                  <span>{{applicant.physicalZip }}</span>
                                  <span>{{applicant.physicalCounty }}</span>
                                </div>
                              </div>

                              <div fxLayout="column">
                                <span><strong>Mailing Address</strong></span>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                                  <mat-checkbox [checked]="applicant.mailingIsPhysical" disabled>
                                    Same as Physical
                                  </mat-checkbox>
                                  <mat-checkbox [checked]="applicant.mailingIsHeadquarters" disabled>
                                    Same as Headquarters
                                  </mat-checkbox>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="!applicant.mailingIsPhysical && !applicant.mailingIsHeadquarters">
                                  <span>{{applicant.mailingStreet }}</span>
                                  <span>{{applicant.mailingCity }}</span>
                                  <span>{{applicant.mailingState }}</span>
                                  <span>{{applicant.mailingZip }}</span>
                                  <span>{{applicant.mailingCounty }}</span>
                                </div>
                              </div>

                              <div fxLayout="column">
                                <span><strong>Headquarters Address</strong></span>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                  <span>{{applicant.headquartersStreet }}</span>
                                  <span>{{applicant.headquartersCity }}</span>
                                  <span>{{applicant.headquartersState }}</span>
                                  <span>{{applicant.headquartersZip }}</span>
                                  <span>{{applicant.headquartersCounty }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      <div fxLayout="row">
        <span fxFlex></span>
        <img class="p-4" src="../../../../../assets/images/ORSeal1.png" alt="OHA Logo">
      </div>
    </div>
  </kendo-pdf-export>
</mat-dialog-content>








