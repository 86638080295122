import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { AssignedTestingLabsComponent } from './assigned-testing-labs/assigned-testing-labs.component';
import { SearchTestingLabsComponent } from './search-testing-labs/search-testing-labs.component';
import { TestingLabComplaintsComponent } from './testing-lab-complaints/testing-lab-complaints.component';
import { TestingLabTasksComponent } from './testing-lab-tasks/testing-lab-tasks.component';

const routes: Routes = [
  { path: "", redirectTo: "search", pathMatch: 'full' },
  { path: 'search', component: SearchTestingLabsComponent, canActivate: [ AuthGuard ]},
  { path: 'search/:id', component: SearchTestingLabsComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned', component: AssignedTestingLabsComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned/:id', component: AssignedTestingLabsComponent, canActivate: [ AuthGuard ]},
  { path: 'tasks', component: TestingLabTasksComponent, canActivate: [ AuthGuard ]},
  { path: 'complaints', component: TestingLabComplaintsComponent, canActivate: [ AuthGuard ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestingLabRoutingModule { }
