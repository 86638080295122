import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantComponent } from './applicant/applicant.component';
import { ResidentialHistoryComponent } from './residential-history/residential-history.component';
import { SharedModule } from '@shared';
import { EntityApplicantComponent } from './entity-applicant/entity-applicant.component';
import { ApplicantSearchComponent } from './applicant-search/applicant-search.component';
import { ApplicantRoutingModule } from './applicant-routing.module';
import { DuplicateApplicantComponent } from './duplicate-applicant/duplicate-applicant.component';
import { AutofillDuplicateApplicantComponent } from './duplicate-applicant/dialog/autofill-duplicate-applicant.component';
import { ViewParentComponent } from './duplicate-applicant/dialog/view-parent/view-parent-dialog.component';


@NgModule({
  declarations: [
    ApplicantComponent,
    ResidentialHistoryComponent,
    EntityApplicantComponent,
    ApplicantSearchComponent,
    DuplicateApplicantComponent,
    AutofillDuplicateApplicantComponent,
    ViewParentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ApplicantRoutingModule,
  ],
  exports: [
    ApplicantComponent,
    ResidentialHistoryComponent,
    EntityApplicantComponent,
    DuplicateApplicantComponent,
    AutofillDuplicateApplicantComponent,
    ViewParentComponent
  ]
})
export class ApplicantModule { }
