import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { ManageNotesComponent } from './manage/manage-notes.component';
import { DialogEditNoteComponent } from './edit/edit-note-dialog';

const COMPONENTS = [
  ManageNotesComponent,DialogEditNoteComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [ManageNotesComponent]
})
export class NotesModule { }
