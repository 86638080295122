import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ILookUp } from 'app/models/compliance/look-ups/look-up';
import { ComplianceService } from 'app/services/compliance/compliance.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-manage-lookups',
  templateUrl: './manage-lookups.component.html',
  styleUrls: ['./manage-lookups.component.scss']
})
export class ManageLookUpsComponent implements OnInit {
  @Input() type: string = '';

  public hideArchived: boolean = true;
  public lookUps: ILookUp[] = [];
  public headerType: string = '';
  public lookUpColumns: string[] = ['value', 'actions'];
  public lookUpSource = new MatTableDataSource<ILookUp>(this.lookUps);
  @ViewChild('lookUpPaginator', {static: false}) lookUpPaginator: MatPaginator;
  @ViewChild('lookUpSort', {static: false}) lookUpSort: MatSort;

  constructor(public sharedService: SharedService,
              private dialog: MatDialog,
              public complianceService: ComplianceService) { }

  ngOnInit(): void {
    this.getHeaderTitle();
    this.complianceService.getLookUps(true, this.type).subscribe(
      response => this.lookUps = response,
      error => console.log('error', error),
      () => {
        this.updateLookUpTable();
      }
    );
  }

  getHeaderTitle(): void {
    switch(this.type){
      case this.sharedService.category:
        this.headerType = "Categories";
        break;
      case this.sharedService.subSection:
        this.headerType = "Sub-Sections";
        break;
      case this.sharedService.investigationItem:
        this.headerType = "Investigation Items";
        break;
      case this.sharedService.actionItem:
        this.headerType = "Action Items";
        break;
    }
  }

  updateLookUpTable(): void {
    if(this.hideArchived){
      this.lookUpSource.data = this.lookUps.filter(r => !r.archived);
    }
    else{
      this.lookUpSource.data = this.lookUps;
    }
    this.lookUpSource.paginator = this.lookUpPaginator;
    this.lookUpSource.sort = this.lookUpSort;
  }

  archive(lookUp: ILookUp, archived: boolean): void {
    lookUp.archived = archived;
    this.complianceService.updateLookUp(lookUp).subscribe(
      response => lookUp = response,
      error => console.log('error', error),
      () => this.updateLookUpTable()
    );
  }

  displayArchived(): void {
    this.hideArchived = !this.hideArchived;
    this.updateLookUpTable();
  }

  filterTable(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.lookUpSource.filter = filter;
  }

  addLookUp(): void {
    let lookUp: ILookUp = {
      id: 0,
      value: '',
      type: this.type,
      archived: false
    }

    const dialogRef = this.dialog.open(AddLookUpDialogComponent, {
      data: lookUp,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.lookUps.push(response);
        this.updateLookUpTable();
      }
    });
  }

  editLookUp(lookUp: ILookUp): void {
    const dialogRef = this.dialog.open(AddLookUpDialogComponent, {
      data: lookUp,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        lookUp = response;
        this.updateLookUpTable();
      }
    });
  }

}

@Component({
  selector: 'add-lookUp',
  templateUrl: 'dialog-add-lookup.html'
})

export class AddLookUpDialogComponent implements OnInit {
  public value: string = '';

  constructor(public dialogRef: MatDialogRef<AddLookUpDialogComponent>,
              public sharedService: SharedService,
              public complianceService: ComplianceService,
              @Inject(MAT_DIALOG_DATA) public lookUp: ILookUp) { }

  ngOnInit(): void {
    this.value = this.lookUp.value;
  }

  updateLookUp(): void {
    this.lookUp.value = this.value;
    this.complianceService.updateLookUp(this.lookUp).subscribe(
      response => this.lookUp = response,
      error => console.log('error', error),
      () => this.dialogRef.close(this.lookUp)
    )
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
