import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { INote } from 'app/models/notes/note';
import { IFollowupRule } from 'app/models/site-inspection/followup-rule';

@Component({
  selector: 'app-dialog-export-followup',
  templateUrl: './dialog-export-followup.component.html',
  styleUrls: ['./dialog-export-followup.component.scss']
})
export class DialogExportFollowupComponent {
   @ViewChild('pdf') pdf: PDFExportComponent;

  public followupRules: IFollowupRule[]= [];
  public notes: INote[] = [];

  public notesColumns: string[] = ['text'];
  public notesSource = new MatTableDataSource<INote>(this.notes);

  constructor(public dialogRef: MatDialogRef<DialogExportFollowupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngAfterViewInit(): void {
    this.updateTables();
    setTimeout(() => {
      this.pdf.saveAs('Followup ' + this.data.followup.caseId + '.pdf');
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }, 2000);
  }

  public updateTables(): void {
    this.notesSource.data = this.data.followup.notes;
  }

  public formatName(): string {
    const nameParts = this.data.staffReference[this.data.followup.assignedTo].split(' ');
    return nameParts[1] + ' ' + nameParts[0];
  }
}
