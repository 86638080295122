<div [hidden]="loading">

<div fxLayout="row" >
  <mat-card class="sticky-header p-y-0" fxFlex>
    <mat-card-content style="padding-bottom: 1em" fxFlex>

      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" *ngIf="assignedLicenses.length < 1 && assignedComplianceLicenses.length < 1">
        <span class="f-s-18"><strong>You don't currently have any assigned licenses</strong></span>
      </div>
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="24px" [hidden]="assignedLicenses.length < 1 && assignedComplianceLicenses.length < 1">
          <div fxLayout="column" fxLayoutGap="16px" [hidden]="assignedLicenses.length < 1">
            <mat-card-title>Assigned Licenses</mat-card-title>
            <mat-table
            [dataSource]="assignedSource"
            matSort
            #licenseSort="matSort"
            aria-describedby="licenses"
            class="mat-elevation-z8 m-b-0">

                <ng-container matColumnDef="identifier">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Number</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.identifier}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.name}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.type}}
                    </span>
                  </mat-cell>
                </ng-container>

              <mat-header-row *matHeaderRowDef="assignedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: assignedColumns"></mat-row>
            </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" #licensePaginator="matPaginator">
              </mat-paginator>
          </div>

          <div fxLayout="column" fxLayoutGap="16px" [hidden]="assignedLicenses.length < 1">
            <mat-card-title>Assigned Renewal Licenses</mat-card-title>
            <mat-table
            [dataSource]="assignedRenewalSource"
            matSort
            #licenseRenewalSort="matSort"
            aria-describedby="licenses"
            class="mat-elevation-z8 m-b-0">

                <ng-container matColumnDef="identifier">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Number</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.identifier}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.name}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.type}}
                    </span>
                  </mat-cell>
                </ng-container>

              <mat-header-row *matHeaderRowDef="assignedRenewalColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: assignedRenewalColumns"></mat-row>
            </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" #licenseRenewalPaginator="matPaginator">
              </mat-paginator>
          </div>

          <div fxLayout="column" fxLayoutGap="16px" [hidden]="assignedComplianceLicenses.length < 1">
            <mat-card-title>Assigned Compliance Licenses</mat-card-title>
            <mat-table
            [dataSource]="assignedComplianceSource"
            matSort
            #assignedComplianceSort="matSort"
            aria-describedby="licenses"
            class="mat-elevation-z8 m-b-0">

                <ng-container matColumnDef="identifier">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Number</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.identifier}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.name}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
                  <mat-cell *matCellDef="let license" [routerLink]="['/' + license.url + '/assigned', license.id]">
                     <span>
                      {{license.type}}
                    </span>
                  </mat-cell>
                </ng-container>

              <mat-header-row *matHeaderRowDef="assignedComplianceColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: assignedComplianceColumns"></mat-row>
            </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" #assignedCompliancePaginator="matPaginator">
              </mat-paginator>
          </div>

          <div fxLayout="column" fxLayoutGap="16px" [hidden]="assignedCases.length < 1">
            <mat-card-title>Assigned Cases</mat-card-title>
            <mat-table
            [dataSource]="assignedCasesSource"
            matSort
            #assignedCasesSort="matSort"
            aria-describedby="cases"
            class="mat-elevation-z8 m-b-0 spaced-table"
            style="width: 110%">

                <ng-container matColumnDef="licenseId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Number</mat-header-cell>
                  <mat-cell *matCellDef="let case" [routerLink]="['/' + case.url + '/assigned', case.id]">
                     <span>
                      {{case.licenseId}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let case" [routerLink]="['/' + case.url + '/assigned', case.id]">
                     <span class="text-wrap">
                      {{case.name}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
                  <mat-cell *matCellDef="let case" [routerLink]="['/' + case.url + '/assigned', case.id]">
                     <span>
                      {{case.type}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="caseNumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</mat-header-cell>
                  <mat-cell *matCellDef="let case" [routerLink]="['/' + case.url + '/assigned', case.id]">
                     <span>
                      {{case.caseNumber}}
                    </span>
                  </mat-cell>
                </ng-container>

              <mat-header-row *matHeaderRowDef="assignedCasesColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: assignedCasesColumns"></mat-row>
            </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" #assignedCasesPaginator="matPaginator" style="width: 110%">
              </mat-paginator>
          </div>

    </div>
    </mat-card-content>
  </mat-card>
</div>

<h4>License Type</h4>
<mat-form-field appearance="fill">
  <mat-label>License</mat-label>
  <mat-select (selectionChange)="licenseChange($event.value)">
    <mat-option *ngFor="let type of licenseTypes" [value]="type" >
      {{type}}
    </mat-option>
  </mat-select>
</mat-form-field>

        <div fxLayout="row">
            <div fxLayout="row" fxLayout.lt-md="column">
              <div fxLayout="column">
                <canvas id="pie_chart_wip"></canvas>
                <h3 class="text-center mt-3">Work in Progress</h3>
              </div>
              <div fxLayout="column">
                <canvas id="pie_chart_completed"></canvas>
                <h3 class="text-center mt-3">Completed Licenses</h3>
              </div>
              <div fxLayout="column">
                <canvas id="pie_chart_total"></canvas>
                <h3 class="text-center mt-3">Total Licenses</h3>
              </div>
          </div>
        </div>
      </div>

      <div *ngIf="loading">
        <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
          <h1>Loading</h1>
        </div>
      </div>
