import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { ManagerNotesComponent } from './manager-notes.component';
import { ManagerNotesDialogComponent } from './dialog/manager-notes-dialog.component';

const COMPONENTS = [
  ManagerNotesComponent,
  ManagerNotesDialogComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [ManagerNotesComponent]
})
export class ManagerNotesModule { }
