import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { InternalApplicationLicense, InternalApplicationMessage, InternalApplicationNewMessage, InternalApplicationRecipient } from './internal-app-message';
import { IStaffType } from 'app/models/user-info/staff-type';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

const httpOptionsFormData = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class InternalApplicationMessagingService {

  public unreadCount: number = 0;
  unreadView = 'Unread';
  sentView = 'Sent';
  inboxView = 'Inbox';
  staffView = 'Staff';

  assignedInbox = 'Assigned';
  licensingInbox = 'Licensing';
  complianceInbox = 'Compliance';
  ptsInbox = 'PTS';


  constructor(private http: HttpClient) {}

  loadUnreadCount() {
    this.getUnread().subscribe(result => {
     this.unreadCount = result.length;
    }, error => {
      console.error(error);
    });
  }

  getStaff(): Observable<IStaffType[]>{
    return this.http.get<IStaffType[]>(environment.privateApi + 'InternalApplicationMessaging/GetStaff');
  }

  changeRecipient(recipient: string, recipientEmail: string, messageId: number): Observable<InternalApplicationMessage> {
    return this.http.post<InternalApplicationMessage>(environment.privateApi + 'InternalApplicationMessaging/changeRecipient/' + recipient + '/' + recipientEmail + '/' + messageId, { withCredentials: true });
  }

  assignMessage(userId: string, messageId: number): Observable<boolean> {
    return this.http.post<boolean>(environment.privateApi + 'InternalApplicationMessaging/assignMessage/' + userId + '/' + messageId, { withCredentials: true });
  }

  assignedMessages(): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/assignedMessages', { withCredentials: true });
  }

  getInbox(email: string, includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/inbox/' + email + '/' + includeArchived ,  { withCredentials: true });
  }

  getRecipientInbox(recipient: string, includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/getInboxByRecipient/' + recipient + '/' + includeArchived ,  { withCredentials: true });
  }

  getStaffInbox(includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/staffInbox/' + includeArchived ,  { withCredentials: true });
  }

  getMessage(messageId: number): Observable<InternalApplicationMessage> {
    return this.http.get<InternalApplicationMessage>(environment.privateApi + 'InternalApplicationMessaging/message/' + messageId ,  { withCredentials: true });
  }

  getUnread(): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/unread'  ,  { withCredentials: true });
  }

  getSent(includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/sent/' + includeArchived,  { withCredentials: true });
  }

  getRecipients(): Observable<InternalApplicationRecipient[]> {
    return this.http.get<InternalApplicationRecipient[]>(environment.privateApi + 'InternalApplicationMessaging/recipients' ,  { withCredentials: true });
  }

  getSenderInfo(messageId: number): Observable<InternalApplicationRecipient> {
    return this.http.get<InternalApplicationRecipient>(environment.privateApi + 'InternalApplicationMessaging/senderInfo/' + messageId ,  { withCredentials: true });
  }

  getUserLicenses(email: string): Observable<InternalApplicationLicense[]> {
    return this.http.get<InternalApplicationLicense[]>(environment.privateApi + 'InternalApplicationMessaging/licenses/' + email,  { withCredentials: true });
  }

  getMessageLicense(licenseType: string, licenseId: string): Observable<InternalApplicationLicense> {
    return this.http.get<InternalApplicationLicense>(environment.privateApi + 'InternalApplicationMessaging/getLicense/' + licenseType + '/' + licenseId,  { withCredentials: true });
  }

  public sendMessage(message: FormData) : Observable<any> {
    return this.http.post<any>(environment.privateApi + 'InternalApplicationMessaging/send', message, httpOptionsFormData);
  }

  public sendBroadcast(message: InternalApplicationNewMessage) : Observable<any> {
    return this.http.post<any>(environment.privateApi + 'InternalApplicationMessaging/send/broadcast', message, httpOptions);
  }

  public updateBroadcast(message: InternalApplicationNewMessage) : Observable<any> {
    return this.http.post<any>(environment.privateApi + 'InternalApplicationMessaging/update/broadcast', message, httpOptions);
  }

  public opened(messageId: number) {
    this.http.get(environment.privateApi + 'InternalApplicationMessaging/opened/' + messageId,  { withCredentials: true }).subscribe(
      () => {
        this.loadUnreadCount();
      }, error => {
        console.error(error);
      });
  }

  public openedBroadcast(messageId: number){
    this.http.get(environment.privateApi + 'InternalApplicationMessaging/opened/broadcast/' + messageId,  { withCredentials: true }).subscribe(
      () => {
        this.loadUnreadCount();
      }, error => {
        console.error(error);
      });
  }

  public openedStaffMessage(messageId: number){
    this.http.get(environment.privateApi + 'InternalApplicationMessaging/opened/staffMessage/' + messageId,  { withCredentials: true }).subscribe(
      () => {
        this.loadUnreadCount();
      }, error => {
        console.error(error);
      });
  }

  public archive(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/archive/' + messageId ,  { withCredentials: true });
  }

  public flagMessage(messageId: number, flagged: boolean) : Observable<boolean>{
    return this.http.get<boolean>(environment.privateApi + 'InternalApplicationMessaging/flagMessage/' + messageId + '/' + flagged ,  { withCredentials: true });
  }

  public archiveBroadcast(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/archive/broadcast/' + messageId ,  { withCredentials: true });
  }

  public archiveStaffMessage(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/archive/staffMessage/' + messageId ,  { withCredentials: true });
  }

  public unarchiveStaffMessage(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/unarchive/staffMessage/' + messageId ,  { withCredentials: true });
  }

  public unarchive(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/unarchive/' + messageId ,  { withCredentials: true });
  }

  public unarchiveBroadcast(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/unarchive/broadcast/' + messageId ,  { withCredentials: true });
  }

  public download(id: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/download/' + id ,  { responseType: 'blob' as 'json', withCredentials: true });
  }

  public attachDocument(id: number) : Observable<boolean>{
    return this.http.post<boolean>(environment.privateApi + 'InternalApplicationMessaging/attachDocument/' + id ,  { withCredentials: true });
  }

  public saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

}
