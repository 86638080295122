import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { RoutesRoutingModule } from "./routes-routing.module";

import { HomeComponent } from "./components/home/home.component";
import {
  UserAdministrationComponent,
  DialogRemoveUserComponent,
  DialogAddUserComponent,
  DialogEditUserComponent,
} from "./components/admin/user-management/user-administration.component";
import { LicenseDashboardComponent } from "./components/license-dashboard/license-dashboard.component";
import { ActiveApplicationTrackingComponent } from "./components/active-application-tracking/active-application-tracking.component";
import { ComplianceTrackingComponent } from "./components/compliance-tracking/compliance-tracking.component";
import { LicensingHomeComponent } from "./components/licensing-home/licensing-home.component";

const COMPONENTS = [
  HomeComponent,
  LicensingHomeComponent,
  UserAdministrationComponent,
  DialogRemoveUserComponent,
  DialogAddUserComponent,
  DialogEditUserComponent,
  LicenseDashboardComponent,
  ActiveApplicationTrackingComponent,
  ComplianceTrackingComponent
];
const COMPONENTS_DYNAMIC = [];

@NgModule({
    imports: [SharedModule, RoutesRoutingModule],
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC]
})
export class RoutesModule {}
