<h2 mat-dialog-title>
  Manager Notes
</h2>
<form [formGroup]="managerNotesForm">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
      <mat-card fxFlex="40">
        <mat-card-title *ngIf="isAdmin">
          Select Flag
        </mat-card-title>
        <mat-card-title *ngIf="!isAdmin">
          Flag
        </mat-card-title>
        <mat-card-content fxLayoutGap="8px">
          <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'red'" style="color:red" (click)="selectFlag('red')">flag</mat-icon>
          <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'blue'" style="color:blue" (click)="selectFlag('blue')">flag</mat-icon>
          <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'yellow'" style="color:rgb(229, 238, 65)" (click)="selectFlag('yellow')">flag</mat-icon>
          <mat-icon [class.selected]="managerNotesForm.get('flag').value === '' || managerNotesForm.get('flag').value === null" (click)="selectFlag('')">not_interested</mat-icon>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="60">
        <mat-card-title>
          Review Type
        </mat-card-title>
        <mat-card-content fxLayoutGap="8px">
          <mat-form-field fxFlex="100">
            <mat-label>
              Review Type
            </mat-label>
            <mat-select formControlName="reviewType" fxFlex="100" *ngIf="isAdmin">
              <mat-option></mat-option>
              <mat-option *ngFor="let type of reviewTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
            <span *ngIf="!isAdmin">{{managerNotes.reviewType}}</span>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
    <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
      <mat-card fxFlex="100">
        <mat-card-title>
          Notes
        </mat-card-title>
        <mat-card-content fxLayoutGap="8px">
          <mat-form-field fxFlex="100" *ngIf="isAdmin">
            <textarea formControlName="notes" matInput rows="5" placeholder="Notes" type="text">
            </textarea>
          </mat-form-field>
          <span *ngIf="!isAdmin" class="text-wrap">{{managerNotes.notes}}</span>
        </mat-card-content>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-raised-button color="primary" (click)="save()" *ngIf="isAdmin">Save</button>
      <button type="button" mat-raised-button color="warn" (click)="closeDialog()">Close</button>
    </div>
  </div>
</form>
