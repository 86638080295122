import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WorkerPermitValidator } from "@shared/validators/worker-permit-validator";
import { IWorkerLicense } from "app/models/licenses/worker/worker-license";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-dialog-add-permit',
  templateUrl: './dialog-add-permit.component.html'
})

export class DialogAddPermitComponent {
  public selectedPermits: string[] = []
  public workerPermitForm = new FormGroup({
    permitId: new FormControl('', {
      asyncValidators: [this.permitValidator.validate.bind(this.permitValidator)]
    })
  });

  constructor(public dialogRef: MatDialogRef<DialogAddPermitComponent>,
              public sharedService: SharedService,
              public permitValidator: WorkerPermitValidator,
              public toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: IWorkerLicense[]) { }

  addPermit(): void {
    let form = this.workerPermitForm.value;
    if (this.data.some(license => license.licenseId === form.permitId)) {
      this.toastr.error("This Worker Permit has already been added");
    } else {
      this.selectedPermits.push(form.permitId);
      this.workerPermitForm.reset({
        permitId: ''
      });
    }
  }

  removePermit(licenseId: string): void {
    this.selectedPermits = this.selectedPermits.filter(p => p != licenseId);
  }

  save(): void {
    this.dialogRef.close(this.selectedPermits);
  }

  cancel(): void {
    if(this.selectedPermits.length > 0){
      this.sharedService.openConfirm("Cancel Associating the Selected Permits?");
      this.sharedService.confirmed().subscribe(confirmed => {
        if(confirmed){
          this.dialogRef.close('cancel');
        }
      });
    }
    else{
      this.dialogRef.close('cancel');
    }
  }

}
