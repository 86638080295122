import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { DateFormats } from "../active-application-tracking/active-application-tracking.component";
import { FormGroup, FormControl } from "@angular/forms";
import { IManagerNotes } from "app/models/manager-notes/manager-notes";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-manager-notes',
  templateUrl: './manager-notes.component.html',
  styleUrls: ['./manager-notes.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
})
export class ManagerNotesComponent implements OnInit {

  @Input() editingNotes = false;
  @Input() cardColor: string = '';
  @Input() isAdmin: boolean = false;
  @Input() managerFlag: string = '';
  @Input() reviewType: string = '';
  @Input() notes: string = '';
  @Output() notifyParent = new EventEmitter<IManagerNotes>();

  public reviewTypes:string[] = ['Ready For Licensing Manager Review', 'Ready For Compliance Manager review', 'Under Review', 'Ready For Final Review'];
  public managerNotes: IManagerNotes = {
    flag: "",
    reviewType: "",
    notes: "",
    entityId: 0,
    entityType: ""
  }

  public managerNotesForm = new FormGroup({
    flag: new FormControl(''),
    reviewType: new FormControl(''),
    notes: new FormControl(''),
  });

  constructor() { }

  ngOnInit(): void {
    this.managerNotes.flag = this.managerFlag;
    this.managerNotes.reviewType = this.reviewType;
    this.managerNotes.notes = this.notes;
    this.updateManagerNotesForm();
  }

  updateManagerNotesForm(): void {
    this.managerNotesForm.patchValue({
      flag: this.managerNotes.flag,
      reviewType: this.managerNotes.reviewType,
      notes: this.managerNotes.notes
    });
  }


  onBlur(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.managerNotes.notes = inputValue;
    this.notifyParent.emit(this.managerNotes);
  }

  onSelectionChange(event: MatSelectChange) {
    const selectedValue = event.value;
    this.managerNotes.reviewType = selectedValue;
    this.notifyParent.emit(this.managerNotes);
  }

  selectFlag(select: string): void {
    if(this.isAdmin) {
      this.managerNotesForm.patchValue({
        flag: select
      });
      this.managerNotes.flag = select;
      this.notifyParent.emit(this.managerNotes);
    }
  }

}
