import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { IResidentialHistory } from 'app/models/licenses/residential-history';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import * as _moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-residential-history',
  templateUrl: './residential-history.component.html',
  styleUrls: ['./residential-history.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class ResidentialHistoryComponent {
  @Input() editingLicense: boolean = false;
  @Input() applicantId: number = 0;
  @Input() residentialHistory: IResidentialHistory[] = [];
  public temporaryResidence: IResidentialHistory = {
    id: 0,
    applicantId: 0,
    city: '',
    state: '',
    country: '',
    from: '',
    to: ''
  };
  public today: Date = new Date(Date.now());
  public editingResidenceId: number = 0;
  public residenceHistoryIncomplete: boolean = false;
  public residenceForm = new UntypedFormGroup({
    city: new UntypedFormControl(""),
    state: new UntypedFormControl(""),
    country: new UntypedFormControl(""),
    from: new UntypedFormControl(""),
    to: new UntypedFormControl(""),
  });

  constructor(public sharedService: SharedService, public applicantService: ApplicantService) { }

  addResidentialHistory(): void {
    let form = this.residenceForm.value;
    let residence: IResidentialHistory = {
      id: 0,
      applicantId: this.applicantId,
      city: form.city,
      state: form.state,
      country: form.country,
      from: form.from,
      to: form.to
    }
    this.validateAndSubmitResidence(residence);
  }

  editResidentialHistory(residence: IResidentialHistory): void {
    this.copyResidence(residence, this.temporaryResidence);
    this.editingResidenceId = residence.id;
    this.residenceForm.patchValue({
      city: residence.city,
      state: residence.state,
      country: residence.country
    });
    if(residence.from !== null && residence.from.length > 0)
    {
      this.residenceForm.patchValue({
        from: new Date(residence.from)
      });
    }
    if(residence.to !== null && residence.to.length > 0)
    {
      this.residenceForm.patchValue({
        to: new Date(residence.to)
      });
    }
  }

  clearHistoricalResidenceValues(): void {
    this.residenceForm.patchValue({
      city: '',
      state: '',
      country: '',
      from: '',
      to: ''
    });
    this.residenceHistoryIncomplete = false;
    this.editingResidenceId = 0;
    this.temporaryResidence.id = 0;
    this.temporaryResidence.applicantId = 0;
    this.temporaryResidence.city = '';
    this.temporaryResidence.state = '';
    this.temporaryResidence.country = '';
    this.temporaryResidence.from = '';
    this.temporaryResidence.to = '';
  }

  cancelResidentialHistoryEdit(): void {
    let index = this.residentialHistory.findIndex(rh => rh.id == this.editingResidenceId);
    this.copyResidence(this.temporaryResidence, this.residentialHistory[index]);
    this.clearHistoricalResidenceValues();
  }

  copyResidence(input: IResidentialHistory, output: IResidentialHistory): void {
    output.id = input.id;
    output.applicantId = input.applicantId;
    output.city = input.city;
    output.state = input.state;
    output.country = input.country;
    output.from = input.from;
    output.to = input.to;
  }

  updateResidentialHistory(): void {
    let form = this.residenceForm.value;
    let residence = this.residentialHistory.find(rh => rh.id == this.editingResidenceId);
    residence.city = form.city;
    residence.state = form.state;
    residence.country = form.country;
    residence.from = form.from;
    residence.to = form.to;
    this.validateAndSubmitResidence(residence);
  }

  validateAndSubmitResidence(residence: IResidentialHistory): void {
    if(residence.city !== "" &&
       residence.state !== "" &&
       residence.country !== "" &&
       residence.from !== "" &&
       residence.to !== "")
    {
      this.applicantService.updateResidentialHistory(residence).subscribe(
        response => {
          let index = this.residentialHistory.findIndex(rh => rh.id === response.id);
          if(index === -1){
            this.residentialHistory.push(response);
          }
          else{
            this.residentialHistory[index] = response;
          }
        },
        error => console.log('error', error),
        () => this.clearHistoricalResidenceValues()
      );
    }
    else{
      this.residenceHistoryIncomplete = true;
    }
  }

  deleteResidentialHistory(id: number): void {
    this.sharedService.openConfirm("Delete this residence?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteResidentialHistory(id).subscribe(
            () => {
              this.residentialHistory = this.residentialHistory.filter(rh => rh.id !== id);
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  setMonthAndYear(normalizedMonthAndYear: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>, value: string) {
    datepicker.close();
    if(value === 'from'){
      this.residenceForm.patchValue({
        from: normalizedMonthAndYear
      });
    }
    else{
      this.residenceForm.patchValue({
        to: normalizedMonthAndYear
      });
    }
  }

}
