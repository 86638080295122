import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ICompliance } from 'app/models/compliance/compliance';
import { IRule } from 'app/models/compliance/rule';
import { ILookUp } from 'app/models/compliance/look-ups/look-up';
import { IStaff } from 'app/models/user-info/staff';
import { INotice } from 'app/models/compliance/notice';
import { IReferencedLicense } from 'app/models/compliance/referenced-license';
import { IDocument } from 'app/models/documents/document';

@Injectable({providedIn: 'root'})

export class ComplianceService {
  private readonly localStorageKey = 'myCachedRequests';
  private api: string = environment.privateApi + 'Compliance/';

  constructor(private http: HttpClient) {
    window.addEventListener('online', () => this.sendCachedRequests());
   }

   addRule(complianceId: number, customRule: IRule): Observable<IRule> {
    return this.http.post<IRule>(this.api + 'AddComplianceRule/' + complianceId, customRule);
  }

  getComplianceCases(entityId: number, entityType: string, includeLocked: boolean): Observable<ICompliance[]>{
    return this.http.get<ICompliance[]>(this.api + 'GetComplianceCases/' + entityId + '/' + entityType +  '/' + includeLocked);
  }

  getComplianceCase(complianceId: number): Observable<ICompliance> {
    return this.http.get<ICompliance>(this.api + 'GetComplianceCase/' + complianceId);
  }

  getAllComplianceCases(): Observable<ICompliance[]>{
    return this.http.get<ICompliance[]>(this.api + 'GetAllComplianceCases');
  }

  relatedCase(currentCaseId: number, relatedCaseId: number): Observable<ICompliance>{
    return this.http.get<ICompliance>(this.api + 'RelatedCase/' + currentCaseId + '/' + relatedCaseId);
  }

  removeRelatedCase(currentCaseId: number, relatedCaseId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'RemoveRelatedCase/' + currentCaseId + '/' + relatedCaseId);
  }

  getExpiredComplianceCases(entityId: number, licenseId: string, entityType: string, includeLocked: boolean): Observable<ICompliance[]>{
    return this.http.get<ICompliance[]>(this.api + 'GetExpiredComplianceCases/' + entityId + '/' + licenseId + '/' + entityType +  '/' + includeLocked);
  }

  createComplianceCase(compliance: any): Observable<ICompliance>{
    let extension: string = 'CreateComplianceCase';
    if (navigator.onLine) {
      return this.http.post<ICompliance>(this.api + 'CreateComplianceCase', compliance);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliance, 'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliance);
        observer.complete();
      });
    }
  }

  createNotice(notice: INotice, complianceId: number): Observable<INotice>{
    return this.http.post<INotice>(this.api + 'CreateNotice/' + complianceId, notice);
  }

  deleteNotice(noticeId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteNotice/' + noticeId);
  }

  updateCompliance(compliance: any): Observable<ICompliance> {
    let extension: string = 'UpdateComplianceCase';
    if (navigator.onLine) {
      return this.http.post<ICompliance>(this.api + 'UpdateComplianceCase', compliance);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliance, 'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliance);
        observer.complete();
      });
    }
  }

  removeComplianceRule(complianceRuleId: number): Observable<boolean> {
    let extension: string = 'DeleteComplianceRule/' + complianceRuleId;
    if (navigator.onLine) {
      return this.http.post<boolean>(this.api + 'DeleteComplianceRule/' + complianceRuleId, null)
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  lockCompliance(complianceId: number, locked: boolean): Observable<boolean> {
    let extension: string = 'LockComplianceCase/' + complianceId + '/' + locked;
    if (navigator.onLine) {
      return this.http.post<boolean>(this.api + 'LockComplianceCase/' + complianceId + '/' + locked, null);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  getAdmins(): Observable<IStaff[]> {
    return this.http.get<IStaff[]>(this.api + "GetAdmins");
  }

  getRules(entityType: string): Observable<IRule[]> {
    return this.http.get<IRule[]>(this.api + 'GetRules/' + entityType);
  }

  updateRule(rule: any): Observable<IRule> {
    let extension: string = 'UpdateRule';
    if (navigator.onLine) {
      return this.http.post<IRule>(this.api + 'UpdateRule', rule);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': rule, 'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(rule);
        observer.complete();
      });
    }
  }

  uploadRules(file: any): Observable<IRule[]>{
    let extension: string = 'UploadRules';
    if (navigator.onLine) {
      return this.http.post<IRule[]>(this.api + 'UploadRules', file)
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': file, 'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(file);
        observer.complete();
      });
    }
  }

  getLookUps(includeArchived: boolean, type: string): Observable<ILookUp[]> {
    return this.http.get<ILookUp[]>(this.api + "GetLookUps/" + includeArchived + "/" + type);
  }

  updateLookUp(lookUp: any): Observable<ILookUp>{
    let extension: string = 'UpdateLookUp';
    if (navigator.onLine) {
      return this.http.post<ILookUp>(this.api + "UpdateLookUp", lookUp);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': lookUp, 'type': 'compliance'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(lookUp);
        observer.complete();
      });
    }
  }

  sendCachedRequests(): void {
    if (navigator.onLine) {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
        Object.keys(cachedRequests).forEach(extension => {
          const request = cachedRequests[extension];
          if(request['type'] == 'compliance')
          {
                this.http.post(this.api + extension, request['data']).subscribe(() => {
                  delete cachedRequests[extension];
                  localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
                });
          }
        });
        Object.keys(cachedRequests).forEach(extension => {
          delete cachedRequests[extension];
        });
      }
    }

  associateLicense(currentCaseId: number, licenseId: string): Observable<IReferencedLicense>{
    return this.http.get<IReferencedLicense>(this.api + 'AssociateLicense/' + currentCaseId + '/' + licenseId);
  }

  unassociateLicense(referencedLicenseId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'UnassociateLicense/' + referencedLicenseId);
  }

  uploadComplianceDocument(file: any): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadComplianceDocument', file);
  }

  deleteComplianceDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteComplianceDocument/" + id);
  }

  downloadComplianceDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadComplianceDocument/" + fileId, { responseType: 'blob' as 'json' });
  }
}
