<mat-card>
  <div fxLayout="row" fxLayoutGap="8px">
    <mat-card-title>
      Curriculum Review
    </mat-card-title>
    <span fxFlex></span>
    <button mat-button type="button" color="primary" *ngIf="!addingCurriculum" (click)="showCurriculum()">Add Curriculum Review</button>
    <button mat-button type="button" color="primary" *ngIf="addingCurriculum" (click)="save()">Save</button>
    <button mat-flat-button color="warn" (click)="close()">
      Close
    </button>
  </div>
  <mat-card-content *ngIf="addingCurriculum">

      <form [formGroup]="curriculumForm" fxLayout="column" fxLayoutGap="8px">
      <mat-checkbox formControlName="curriculumOne">
        <span class="text-wrap">(1) Historical, Traditional, and Contemporary Practices and Applications:  12 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumOneA" style="padding-left:1em">
        <span class="text-wrap">(a) Current and historical use of plant and fungal medicines in indigenous and western cultures; </span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumOneB" style="padding-left:1em">
        <span class="text-wrap"> (b) The Controlled Substances Act and its effect on psilocybin research and drug policy; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumOneC" style="padding-left:1em">
        <span class="text-wrap">(c) Overview of historical and recent academic research</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwo">
        <span class="text-wrap">(2) Cultural Equity in relation to Psilocybin Services: 12 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoA" style="padding-left:1em">
        <span class="text-wrap">(a) Cultural equity, its relationship to health equity and social determinants of health;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoB" style="padding-left:1em">
        <span class="text-wrap">(b) Racial justice, including the impact of race and privilege on health outcomes and the impact of systemic racism on individuals and communities;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoC" style="padding-left:1em">
        <span class="text-wrap">(c) The impact of drug policy on individuals and communities;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoD" style="padding-left:1em">
        <span class="text-wrap">(d) History of systemic inequity, including systemic inequity in delivery of healthcare, mental health and behavioral health services;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoE" style="padding-left:1em">
        <span class="text-wrap">(e) Intergenerational trauma; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumTwoF" style="padding-left:1em">
        <span class="text-wrap">(f) Responsible Referral and Support</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThree">
        <span class="text-wrap">(3) Safety, Ethics and Responsibilities: 12 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeA" style="padding-left:1em">
        <span class="text-wrap">(a) Awareness of facilitator's personal bias, including examination of facilitator's motives;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeB" style="padding-left:1em">
        <span class="text-wrap">(b) Training on the Oregon Psilocybin Services Act and related laws, regulations, and professional standards for facilitators; including facilitator scope of practice and expectation of referral when scope of practice is exceeded.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeC" style="padding-left:1em">
        <span class="text-wrap">(c) Training in ethical issues related to psilocybin facilitation, including:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCA" style="padding-left:2em">
        <span class="text-wrap">(A) Oregon's Facilitator Code of Ethics;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCB" style="padding-left:2em">
        <span class="text-wrap">(B) Ethical considerations relating to equity, privilege, bias and power;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCC" style="padding-left:2em">
        <span class="text-wrap">(C) Awareness of increased vulnerability associated with altered states of consciousness.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCD" style="padding-left:2em">
        <span class="text-wrap">(D) Appropriate use of touch and client consent to physical contact;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCE" style="padding-left:2em">
        <span class="text-wrap">(E) Appropriate emotional and sexual boundaries between facilitators and clients both during provision of psilocybin services and at other times, potential harm to clients and consequences for facilitators of breaching those boundaries;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCF" style="padding-left:2em">
        <span class="text-wrap">(F) Historical and contemporary abuse of power associated with psychedelics, including sexual, emotional and physical abuse, and implications for facilitators;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCG" style="padding-left:2em">
        <span class="text-wrap">(G) Financial conflicts of interest and duties to clients.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeCH" style="padding-left:2em">
        <span class="text-wrap">(H) Reasonable expectations regarding client outcomes</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeD" style="padding-left:1em">
        <span class="text-wrap">(d) Accurate record keeping and client confidentiality.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeE" style="padding-left:1em">
        <span class="text-wrap">(e) Awareness of new research related to safety and ethics of providing psilocybin services and resources for professional development following program completion.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumThreeF" style="padding-left:1em">
        <span class="text-wrap">(f) Appropriate measures to mitigate risks associated with psilocybin services, including harm reduction, de-escalation, and conflict resolution.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFour">
        <span class="text-wrap">(4) Psilocybin Pharmacology, Neuroscience, and Clinical Research: 4 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourA" style="padding-left:1em">
        <span class="text-wrap">(a) Pharmacodynamics and pharmacokinetics of psilocybin;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourB" style="padding-left:1em">
        <span class="text-wrap">(b) Drug and supplement interaction;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourC" style="padding-left:1em">
        <span class="text-wrap">(c) The metabolism of psilocybin and psilocybin products including concentration of psilocybin and psilocin in available psilocybin products.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourD" style="padding-left:1em">
        <span class="text-wrap">(d) The primary effects and mechanisms of action of psilocybin on the brain, including connectivity in the brain and activation of serotonin receptors; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourE" style="padding-left:1em">
        <span class="text-wrap">(e) Key areas of psilocybin research.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFourF" style="padding-left:1em">
        <span class="text-wrap">(f) Models of substance use, addiction, and recovery.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFive">
        <span class="text-wrap">(5) Core Facilitation Skills module shall include the following subjects and their application during preparation, administration, and integration sessions: 16 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveA" style="padding-left:1em">
        <span class="text-wrap">(a) Client communication, empathy and rapport, including a nondirective facilitation approach, cultural attunement and a nonjudgmental disposition;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveB" style="padding-left:1em">
        <span class="text-wrap">(b) Response to psychological distress and creating a safe space for difficult emotional experiences;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveC" style="padding-left:1em">
        <span class="text-wrap">(c) Physical reactions and side effects of psilocybin;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveD" style="padding-left:1em">
        <span class="text-wrap">(d) Trauma informed care, including physiology of trauma, vicarious trauma, empathic stress and compassion fatigue;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveE" style="padding-left:1em">
        <span class="text-wrap">(e) Active monitoring of client-facilitator boundaries specifically boundaries related to consent and touch;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveF" style="padding-left:1em">
        <span class="text-wrap">(f) Identification and facilitation of a variety of subjective psilocybin experiences, including experiences relating to physiological sensations, cognitive, emotional and mystical states, and traumatic memories;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveG" style="padding-left:1em">
        <span class="text-wrap">(g) Appropriate modes of intervention, understanding when intervention is necessary, and when a client may need a higher level of care;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveH" style="padding-left:1em">
        <span class="text-wrap">(h) Recognizing and addressing adverse behavioral reactions and adverse medical reactions; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumFiveI" style="padding-left:1em">
        <span class="text-wrap">(i) Identification of the unique health, psychological and socio-cultural presented by persons with terminal illness; and awareness of the appropriate knowledge, skills and approach needed to provide safe facilitation to such persons in a manner consistent with client goals, values, heritage, and spiritual practices.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSix">
        <span class="text-wrap">(6) Preparation and Orientation: 16 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixA" style="padding-left:1em">
        <span class="text-wrap">(a) Informed consent;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixB" style="padding-left:1em">
        <span class="text-wrap">(b) Client information form and intake interview, including discussion of client's reasons for seeking psilocybin services;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixC" style="padding-left:1em">
        <span class="text-wrap">(c) Using the client information form to assist clients in identifying benefits of referral to specialized treatment services;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixD" style="padding-left:1em">
        <span class="text-wrap">(d) Facilitator role and the limits of facilitator's scope of practice; </span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixE" style="padding-left:1em">
        <span class="text-wrap">(e) Trauma informed communication skills;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixF" style="padding-left:1em">
        <span class="text-wrap">(f) Identification of client safety concerns, including medical history, contra-indicated medication and psychological instability;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixG" style="padding-left:1em">
        <span class="text-wrap">(g) Appropriate strategies to discuss client safety concerns, including but not limited to identification of client's support system;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixH" style="padding-left:1em">
        <span class="text-wrap">(h) Determination of whether a client should participate in an administration session;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixI" style="padding-left:1em">
        <span class="text-wrap">(i) Client directed safety planning to address identified safety concerns;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixJ" style="padding-left:1em">
        <span class="text-wrap">(j) Boundaries between the facilitator and the client including use of touch;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixK" style="padding-left:1em">
        <span class="text-wrap">(k) Understanding of how racial and cultural dynamics affect interactions between client and facilitator; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSixL" style="padding-left:1em">
        <span class="text-wrap">(l) Historical and indigenous modalities of preparation.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSeven">
        <span class="text-wrap">(7) Administration: 20 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenA" style="padding-left:1em">
        <span class="text-wrap">(a) Dosing strategies and considerations, including:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenAA" style="padding-left:2em">
        <span class="text-wrap">(A) Experiential differences relating to differing dosages;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenAB" style="padding-left:2em">
        <span class="text-wrap">(B) Physiological considerations in relation to dosage;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenAC" style="padding-left:2em">
        <span class="text-wrap">(C) Delivery mechanisms of psilocybin; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenAD" style="padding-left:2em">
        <span class="text-wrap">(D) Use of secondary doses.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenB" style="padding-left:1em">
        <span class="text-wrap">(b) Effectively working with challenging behaviors during an administration session, including:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenBA" style="padding-left:2em">
        <span class="text-wrap">(A) Unexpected client disclosures;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenBB" style="padding-left:2em">
        <span class="text-wrap">(B) Substance-induced psychosis; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenBC" style="padding-left:2em">
        <span class="text-wrap">(C) Suicidality</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenC" style="padding-left:1em">
        <span class="text-wrap">(c) Traumatic stress and its manifestation during a psilocybin experience and appropriate facilitator response, including:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenCA" style="padding-left:2em">
        <span class="text-wrap">(A) Trauma's relationship to the body;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenCB" style="padding-left:2em">
        <span class="text-wrap">(B) Repressed trauma emerging during a psilocybin experience;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenCC" style="padding-left:2em">
        <span class="text-wrap">(C) Trauma and traumatic stress resulting from systemic oppression;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenCD" style="padding-left:2em">
        <span class="text-wrap">(D) Safety for trauma resolution and risks associated with re-traumatization; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenCE" style="padding-left:2em">
        <span class="text-wrap">(E) Protocols for ensuring facilitator safety and responding to emergencies.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenD" style="padding-left:1em">
        <span class="text-wrap">(d) “Set and Setting” including environmental considerations for administration session such as lighting, sound and temperature; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumSevenE" style="padding-left:1em">
        <span class="text-wrap">(e) Completion of administration session.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEight">
        <span class="text-wrap">(8) Integration; 12 hours.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightA" style="padding-left:1em">
        <span class="text-wrap">(a) Identification of appropriate resources that may assist client with integration, including resources for:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightAA" style="padding-left:2em">
        <span class="text-wrap">(A) Interpreting feelings and emotions experienced during administration session;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightAB" style="padding-left:2em">
        <span class="text-wrap">(B) Facilitation of positive internal and external changes;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightAC" style="padding-left:2em">
        <span class="text-wrap">(C) Enhancement of existing supportive relationship</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightB" style="padding-left:1em">
        <span class="text-wrap">(b) Identification of client safety concerns;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightC" style="padding-left:1em">
        <span class="text-wrap">(c) Facilitator scope of practice; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumEightD" style="padding-left:1em">
        <span class="text-wrap">(d) Discussion of appropriate intervals between administration sessions and related safety concerns.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNine">
        <span class="text-wrap">(9) Group Facilitation: 16 hours.  </span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineA" style="padding-left:1em">
        <span class="text-wrap">(a) Skills required to facilitate psilocybin group sessions, including, but not limited to:</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineAA" style="padding-left:2em">
        <span class="text-wrap">(A) Assessing client's compatibility with group format;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineAB" style="padding-left:2em">
        <span class="text-wrap">(B) Set and setting for group facilitation;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineAC" style="padding-left:2em">
        <span class="text-wrap">(C) Facilitating group communications and dynamics, including strategies for working with multiple facilitators;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineAD" style="padding-left:2em">
        <span class="text-wrap">(D) Group agreements, including confidentiality and safety; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineAE" style="padding-left:2em">
        <span class="text-wrap">(E) Identifying when a client within a group requires individual support, removal from a group, or additional intervention.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineB" style="padding-left:1em">
        <span class="text-wrap">(b) Group Preparation Sessions;</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineC" style="padding-left:1em">
        <span class="text-wrap">(c) Group Integration Sessions; and</span>
      </mat-checkbox>
      <mat-checkbox formControlName="curriculumNineD" style="padding-left:1em">
        <span class="text-wrap">(d) Regulatory requirements for group facilitation.</span>
      </mat-checkbox>
      <mat-checkbox formControlName="practicumPlanIncluded">
        <span class="text-wrap">Practicum plan included</span>
      </mat-checkbox>
    </form>

  </mat-card-content>
  <mat-card-content *ngIf="!addingCurriculum">
    <mat-table [dataSource]="curriculumSource" matSort aria-describedby="Curriculum" #curriculumSort="matSort"
    class="mat-elevation-z8 m-b-0">

    <ng-container matColumnDef="completed">
      <mat-header-cell *matHeaderCellDef>Curriculum Complete</mat-header-cell>
      <mat-cell *matCellDef="let curriculum">
        <span class="mobile-label">Curriculum Complete</span>
        <span *ngIf="!curriculum.completed">Not Complete</span>
        <span *ngIf="curriculum.completed">Completed</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
      <mat-header-cell *matHeaderCellDef>Date Submitted</mat-header-cell>
      <mat-cell *matCellDef="let curriculum">
        <span class="mobile-label">Date Submitted</span>
        {{curriculum.submittedDate}}
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let curriculum">
        <button type="button" mat-icon-button
        [matTooltip]="'Edit'" matTooltipShowDelay=800
        aria-label="Edit" (click)="editLicense(curriculum.id)">
        <mat-icon>edit</mat-icon>
      </button>
        <button type="button" mat-icon-button class="m-8"
          (click)="deleteCurriculum(curriculum.id)" color="warn"
          aria-label="Remove Curriculum Review">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="curriculumColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: curriculumColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[25, 50, 100]" #curriculumPaginator="matPaginator">
  </mat-paginator>
</mat-card-content>
</mat-card>
