<section [hidden]="addingRule">
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>Add Rule or Statute</span>
    <button mat-raised-button type="button" color="primary" (click)="addRules()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>

<mat-dialog-content [hidden]="addingRule">
  <div fxLayout="column">
    <div fxLayout="row">
      <mat-form-field fxFlex="50" fxFlex.lt-md="100">
        <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button type="button" mat-icon-button color="primary"
        matTooltip="Add Rule" matTooltipShowDelay=800 aria-label="Add Rule" (click)="addRule()">
        <mat-icon>add_box</mat-icon>
      </button>
      <span>Add Manually Entered Rule</span>
      </div>

    </div>

    <mat-table [dataSource]="ruleRefSource" aria-describedby="Rules" class="mat-elevation-z8">
      <ng-container matColumnDef="statute">
        <mat-header-cell *matHeaderCellDef>Rule</mat-header-cell>
        <mat-cell *matCellDef="let rule">
          <span class="mobile-label">Rule</span>
          {{ rule.statute }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="summary">
        <mat-header-cell *matHeaderCellDef>Summary</mat-header-cell>
        <mat-cell *matCellDef="let rule">
          <span class="mobile-label">Summary</span>
          {{ rule.summary }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let rule">
          <button type="button" mat-button color="primary" (click)="selectRule(rule)">Select</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="ruleRefColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ruleRefColumns">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" #ruleRefPaginator="matPaginator">
    </mat-paginator>

    <div *ngIf="selectedRules.length > 0" fxLayout="column">
      <span class="f-s-16">Selected Rules</span>
      <mat-list>
        <mat-list-item *ngFor="let rule of selectedRules">
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <span>{{rule.statute}}</span>
            <button type="button" mat-button color="warn" (click)="removeRule(rule)">Remove</button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  </div>
</mat-dialog-content>

<section [hidden]="!addingRule">
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>Add Custom Rule or Statute</span>
    <button mat-raised-button type="button" color="primary" (click)="saveNewRule()">Add</button>
    <button mat-raised-button type="button" color="warn" (click)="cancelNewRule()">Close</button>
  </div>
</section>
<mat-dialog-content [hidden]="!addingRule">
  <mat-card fxFlex="100">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div fxLayout="column" fxFlex="50">
          <span style="font-size: large"><strong>Rule out of compliance:</strong></span>
          <mat-form-field>
            <input matInput [(ngModel)]="customRule.statute" placeholder="Rule" />
          </mat-form-field>
        </div>
      </div>
      <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
        <mat-form-field fxFlex="100">
          <textarea [(ngModel)]="customRule.summary" matInput rows="5" placeholder="Rule Summary" type="text"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
</mat-dialog-content>
