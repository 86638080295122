<section>
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>{{(lookUp.id > 0? 'Edit ' : 'Add ') + lookUp.type}}</span>
    <button mat-raised-button type="button" color="primary" [disabled]="ref.errors?.['required']"
      (click)="updateLookUp()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <div fxLayout="row">
    <mat-form-field fxFlex="100">
      <input matInput [placeholder]="lookUp.type" required [(ngModel)]="value" #ref="ngModel">
      <mat-error *ngIf="ref.errors?.['required']">
        Please provide the {{lookUp.type}}
      </mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>
