import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IReportedEvent } from "app/models/reported-events/reported-event";
import { Observable } from "rxjs";
import { IDocument } from 'app/models/documents/document';

@Injectable({providedIn: 'root'})

export class ReportedEventService {
  private api: string = environment.privateApi + 'ReportedEvents/';

  constructor(private http: HttpClient) { }

  createReportedEvent(reportedEvent: IReportedEvent): Observable<IReportedEvent>{
    return this.http.post<IReportedEvent>(this.api + 'CreateReportedEvent', reportedEvent);
  }

  getReportedEvents(entityId: number, entityType: string): Observable<IReportedEvent[]>{
    return this.http.get<IReportedEvent[]>(this.api + 'GetReportedEvents/' + entityId + '/' + entityType);
  }

  updateReportedEvent(reportedEvent: IReportedEvent): Observable<IReportedEvent> {
    return this.http.post<IReportedEvent>(this.api + 'UpdateReportedEvent', reportedEvent);
  }

  deleteReportedEvent(eventId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteReportedEvent/' + eventId);
  }

  uploadReportedEventDocument(file: any): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadReportedEventDocument', file);
  }

  deleteReportedEventDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteReportedEventDocument/" + id);
  }

  downloadReportedEventDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadReportedEventDocument/" + fileId, { responseType: 'blob' as 'json' });
  }
}
