<mat-card class="sticky-header p-y-0"
          [style.background]="'linear-gradient(to right,' + sharedService.purple + ',' +
                                                            sharedService.blue + ',' +
                                                            sharedService.green + ',' +
                                                            sharedService.gold + ',' +
                                                            sharedService.orange + ',' +
                                                            sharedService.pink + ',' +
                                                            sharedService.red +')'">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
      <mat-icon class="medium-icon m-24">search</mat-icon>
      <span class="f-s-20">Search Applicants</span>
      <span fxFlex></span>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" *ngIf="applicantDetails.id === 0 && entityDetails.id === 0" [@slideInOut]>

  <span class="f-s-20">Search Individual Applicants</span>
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-t-8">
    <mat-form-field>
      <input matInput type="text" placeholder="First Name" [(ngModel)]="firstName">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Last Name" [(ngModel)]="lastName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Date of Birth</mat-label>
      <input placeholder="mm/dd/yyyy" [(ngModel)]="dob" matInput [matDatepicker]="searchDob"
        [max]="sharedService.today" />
      <mat-datepicker-toggle matSuffix [for]="searchDob"></mat-datepicker-toggle>
      <mat-datepicker #searchDob>
        <mat-datepicker-actions>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          <button mat-button matDatepickerCancel>Cancel</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="SSN" [(ngModel)]="ssn" mask="000-00-0000">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Applicant Id" [(ngModel)]="applicantId">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Similarity Threshold" [(ngModel)]="similarity">
    </mat-form-field>
    <div fxLayout="column">
      <mat-checkbox [(ngModel)]="duplicate">
        Include Duplicates
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="archived">
        Include Archived
      </mat-checkbox>
    </div>
    <button mat-flat-button type="button" color="primary" (click)="searchApplicants()">Search Individual Applicants</button>
  </div>

  <div>
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable('individual', $event)" placeholder="Filter Individuals" />
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
    class="mat-elevation-z8 p-t-0">
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">First</span>
        {{ applicant.firstName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Last</span>
        {{ applicant.lastName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="middleName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Middle</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Middle</span>
        {{ applicant.middleName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dob">
      <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">DOB</span>
        {{ applicant.dob | date }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="ssn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">SSN</span>
        <span>***-**-</span>{{ applicant.ssn | slice: 5:9}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="applicantId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant ID</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">ID</span>
        {{ applicant.applicantId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="similarity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Similarity</span>
        {{ applicant.similarity }} %
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row (click)="getApplicantDetails(row.id)" *matRowDef="let row; columns: dataColumns" [ngClass]="{'archived': row.archived }"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
  </mat-paginator>

  <span class="f-s-20 m-t-16">Search Entity Applicants</span>
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-t-8">
    <mat-form-field>
      <input matInput type="text" placeholder="Entity Name" [(ngModel)]="entityName">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="City" [(ngModel)]="city">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Entity Id" [(ngModel)]="entityId">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Similarity Threshold" [(ngModel)]="entitySimilarity">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="archivedEntity">
      Include Archived
    </mat-checkbox>
    <button mat-flat-button type="button" color="primary" (click)="searchEntityApplicants()">Search Entity Applicants</button>
  </div>

  <div>
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable('entity', $event)" placeholder="Filter Entities" />
    </mat-form-field>
  </div>

  <mat-table [dataSource]="entitySource" matSort #entitySort="matSort" aria-describedby="Entity Applicants" class="mat-elevation-z8 p-t-0">
  <ng-container matColumnDef="entityName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">Entity Name</span>
      {{ applicant.entityName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="pointOfContact">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Point of Contact</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">Point of Contact</span>
      {{ applicant.pointOfContact }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="city">
    <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">City</span>
      {{ applicant.city }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">State</span>
      {{ applicant.state }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="entityId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Id</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">Entity Id</span>
      {{ applicant.entityId}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="similarity">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
    <mat-cell tabindex="0" *matCellDef="let applicant">
      <span class="mobile-label">Similarity</span>
      {{ applicant.similarity }} %
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="entityColumns"></mat-header-row>
  <mat-row (click)="getEntityDetails(row.id)" *matRowDef="let row; columns: entityColumns" [ngClass]="{'archived': row.archived }"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 20, 50]" #entityPaginator="matPaginator">
</mat-paginator>
</div>

<mat-card *ngIf="applicantDetails.id > 0" [@slideInOut]>
  <mat-card-title>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>Viewing</span>
      <span>{{applicantDetails.legalFirstName}}</span>
      <span>{{applicantDetails.legalLastName}}</span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="closeApplicantDetails()">Close</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
        <!--Personal Information-->
        <mat-card fxFlex="35" fxFlex.lt-lg="100">
          <mat-card-title>
            <div fxLayout="row" fxLayout.lt-lg="column">
              <span fxFlex>Personal Information</span>
            </div>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div fxLayout="column">
                <span><strong>Legal Name</strong></span>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span>{{applicantDetails.legalFirstName}}</span>
                  <span>{{applicantDetails.legalMiddleName}}</span>
                  <span>{{applicantDetails.legalLastName}}</span>
                </div>
              </div>
              <div fxLayout="column">
                <span><strong>Preferred Name</strong></span>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span>{{applicantDetails.preferredFirstName}}</span>
                  <span>{{applicantDetails.preferredMiddleName}}</span>
                  <span>{{applicantDetails.preferredLastName}}</span>
                </div>
              </div>
              <div fxLayout="column">
                <span><strong>Other Names or Aliases</strong></span>
                <span>{{applicantDetails.alternateNames}}</span>
              </div>

              <div fxLayout="column">
                <span><strong>DOB</strong></span>
                <span>{{applicantDetails.dob | date}}</span>
              </div>

              <div fxLayout="column" fxLayoutGap="18px" fxFlex="100">
                <div fxLayout="column">
                  <span><strong>SSN</strong></span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="18px" fxLayoutGap.lt-lg=""
                    fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">

                    <span>{{showSsn? (applicantDetails.ssn | mask: '000-00-0000') :
                      '***-**-' + applicantDetails.ssn.substring(applicantDetails.ssn.length - 4)}}
                    </span>
                    <button type="button" mat-icon-button [color]="showSsn? 'primary':'warn'"
                      [matTooltip]="showSsn? 'Hide SSN' : 'Show SSN'" matTooltipShowDelay=800 aria-label="Toggle SSN"
                      (click)="toggleSsn()">
                      <mat-icon>{{showSsn? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>

                    <mat-checkbox [checked]="applicantDetails.noSSN">
                      Applicant has no SSN
                    </mat-checkbox>
                  </div>
                  <mat-checkbox [checked]="applicantDetails.ssnAttestation">
                    <span class="text-wrap">
                      Applicant consents to use of SSN
                    </span>
                  </mat-checkbox>
                </div>
              </div>

              <mat-checkbox fxFlex="40" [checked]="applicantDetails.backgroundCheckPassed" disabled>
                Background Check Passed
              </mat-checkbox>

              <div fxLayout="column" fxFlex="30">
                <span><strong>Background Check Date</strong></span>
                <span>{{applicantDetails.backgroundCheckDate | date}}</span>
              </div>

              <textarea matInput cols="2" [value]="applicantDetails.backgroundCheckNotes" placeholder="Background Check Notes" disabled></textarea>
              <mat-card  fxLayoutGap="12px">
                <mat-card-title>
                  Additional Applicant Questions
                </mat-card-title>
                <div fxLayout="column" fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="!applicantDetails.heldLicense && !applicantDetails.deniedLicense && !applicantDetails.subjectToDiscipline && !applicantDetails.suedForDamages && !applicantDetails.settledAllegations && !applicantDetails.allegedAbuse">
                    <span><strong>Applicant answered no to all additional questions.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.heldLicense">
                    <span><strong>In the last 10 years, Applicant has held a license in Oregon or in another state or country.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.deniedLicense">
                    <span><strong>In the last 10 years, Applicant has been denied a license.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.subjectToDiscipline">
                    <span><strong>In the last 10 years, Applicant has been subject to discipline by the entity that issued their license or had their license suspended or revoked.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.suedForDamages">
                    <span><strong>In the last 10 years, Applicant has been sued for money damages related to work they provided under a license.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.settledAllegations">
                    <span><strong>In the last 10 years, Applicant has settled allegations or claims against them related to a license.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.allegedAbuse">
                    <span><strong>Applicant has had someone allege that they abused a child or an adult.</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicantDetails.substantiatedAllegation">
                    <span><strong>Allegations were substantiated</strong></span>
                  </div>
                </div>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Contact Information-->
        <mat-card fxFlex="35" fxFlex.lt-lg="100">
          <mat-card-title>
            Contact Information
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div fxLayout="column">
                <span class="f-2-20"><strong>Primary Phone</strong></span>
                <span>{{applicantDetails.phone | mask:'(000)-000-0000 000000'}}</span>
              </div>
              <div fxLayout="column">
                <span class="f-2-20"><strong>Alternate Phone</strong></span>
                <span>{{applicantDetails.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
              </div>

              <div fxLayout="column">
                <span class="f-2-20"><strong>Primary Email Address</strong></span>
                <span>{{applicantDetails.email}}</span>
              </div>
              <div fxLayout="column">
                <span class="f-2-18"><strong>Preferred Language</strong></span>
                <span>{{applicantDetails.language}}</span>
              </div>

              <div fxLayout="column">
                <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                <mat-checkbox [checked]="applicantDetails.previousLicense">
                  <span class="text-wrap">Applicant has other licenses</span>
                </mat-checkbox>
                <div fxLayout="column" class="m-t-8" *ngIf="applicantDetails.previousLicense">
                  <span><strong>Other Oregon Psilocybin licenses</strong></span>
                  <span>{{applicantDetails.affiliatedLicenses}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Relationships-->
        <mat-card fxFlex="30">
          <mat-card-title>
            <span>Relationships</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div *ngIf="applicantDetails.facilitators.length > 0">
                <span class="f-s-16"><strong>Facilitators</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of applicantDetails.facilitators" [routerLink]="'facilitator/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="applicantDetails.manufacturers.length > 0">
                <span class="f-s-16"><strong>Manufacturers</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of applicantDetails.manufacturers" [routerLink]="'/manufacturer/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="applicantDetails.serviceCenters.length > 0">
                <span class="f-s-16"><strong>Service Centers</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                   *ngFor="let relationship of applicantDetails.serviceCenters" [routerLink]="'service-center/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="applicantDetails.laboratories.length > 0">
                <span class="f-s-16"><strong>Laboratories</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of applicantDetails.laboratories" [routerLink]="'testing-lab/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="applicantDetails.workerPermits.length > 0">
                <span class="f-s-16"><strong>Worker Permits</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of applicantDetails.workerPermits" [routerLink]="'worker-permit/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="applicantDetails.entityApplicants.length > 0">
                <span class="f-s-16"><strong>Entity Applicants</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of applicantDetails.entityApplicants" (click)="viewRelatedEntity(relationship.id)">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
        <!--Addresses-->
        <mat-card fxFlex="70" fxFlex.lt-md="100">
          <mat-card-title>Address</mat-card-title>
          <mat-card-content>
            <div fxLayout="row" fxLayoutGap="8px" style="font-size: 18px;">
              <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                <div fxLayout="column">
                  <span><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="18px">
                    <span>{{applicantDetails.physicalStreet }}</span>
                    <span>{{applicantDetails.physicalCity }}</span>
                    <span>{{applicantDetails.physicalState }}</span>
                    <span>{{applicantDetails.physicalZip }}</span>
                    <span>{{applicantDetails.physicalCounty }}</span>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox [checked]="applicantDetails.mailingIsPhysical" disabled>
                      Same as physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!applicantDetails.mailingIsPhysical">
                    <span>{{applicantDetails.mailingStreet }}</span>
                    <span>{{applicantDetails.mailingCity }}</span>
                    <span>{{applicantDetails.mailingState }}</span>
                    <span>{{applicantDetails.mailingZip }}</span>
                    <span>{{applicantDetails.mailingCounty }}</span>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px">
                  <span><strong>Oregon Residency</strong></span>
                  <mat-checkbox [checked]="applicantDetails.oregonResidency" disabled>
                    <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                  </mat-checkbox>
                </div>
              </div>

              <app-residential-history [applicantId]="applicantDetails.id"
                                       [residentialHistory]="applicantDetails.residentialHistory"
                                       [editingLicense]="false"
                                       fxFlex="50">
              </app-residential-history>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Documents-->
        <div fxLayout="column" fxFlex="30" fxFlex.lt-md="100">
          <!--Current-->
          <mat-card>
            <mat-card-title>
              <span>Current Documents</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" style="font-size: 18px;">
                <div fxLayout="row" *ngIf="!sharedService.mobile">
                  <span class="f-s-16" fxFlex="60"><strong>File</strong></span>
                  <span class="f-s-16"><strong>Requirement</strong></span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                  *ngFor="let document of applicantDetails.applicantDocuments">
                  <span fxFlex="60" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                    (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>
                  <div fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex.lt-md="" fxLayout="column">
                      <span *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="entityDetails.id > 0" [@slideInOut]>
  <mat-card-title>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>Viewing</span>
      <span>{{entityDetails.entityName}}</span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="closeEntityDetails()">Close</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
        <!--Contact Information-->
        <mat-card fxFlex="35" fxFlex.lt-md="100">
          <mat-card-title>
            Contact Information
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <span class="f-s-16"><strong>Legal Entity Name</strong></span>
              <span>{{entityDetails.entityName}}</span>
              <span class="f-s-16"><strong>Registered To Do Business In</strong></span>
              <span>{{entityDetails.businessRegisteredIn}}</span>
              <div fxLayout="column">
                <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                <mat-checkbox [checked]="entityDetails.previousLicense" disabled>
                  <span class="text-wrap">
                    Entity has applied for or received other licenses from Oregon Psilocybin Services
                  </span>
                </mat-checkbox>
                <div fxLayout="column" class="m-t-8" *ngIf="entityDetails.previousLicense">
                  <span>Oregon Psilocybin license ids</span>
                  <span>{{entityDetails.affiliatedLicenses}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="35" fxFlex.lt-md="100">
          <mat-card-title>
            <span>Primary Point of Contact</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Contact Name</strong></span>
                  <span>{{entityDetails.primaryContactName}}</span>
                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Contact Phone</strong></span>
                  <span>{{entityDetails.primaryContactNumber | mask:'(000)-000-0000 000000'}}</span>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Email Address</strong></span>
                  <span>{{entityDetails.primaryContactEmail}}</span>

                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                  <span class="f-s-16"><strong>Preferred Language</strong></span>
                  <span>{{entityDetails.language}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Relationships-->
        <mat-card fxFlex="30" fxFlex.lt-md="100">
          <mat-card-title>
            <span>Relationships</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
              <div *ngIf="entityDetails.manufacturers.length > 0">
                <span class="f-s-16"><strong>Manufacturers</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of entityDetails.manufacturers" [routerLink]="'/manufacturer/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="entityDetails.serviceCenters.length > 0">
                <span class="f-s-16"><strong>Service Centers</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                    *ngFor="let relationship of entityDetails.serviceCenters" [routerLink]="'service-center/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="entityDetails.laboratories.length > 0">
                <span class="f-s-16"><strong>Laboratories</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of entityDetails.laboratories" [routerLink]="'testing-lab/search/' + relationship.id">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>

              <div *ngIf="entityDetails.applicants.length > 0">
                <span class="f-s-16"><strong>Individual Applicants</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                  *ngFor="let relationship of entityDetails.applicants" (click)="viewRelatedIndividual(relationship.id)">
                  <span>{{relationship.name}}</span>
                  <span>{{relationship.entityId}}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
        <!--Addresses-->
        <mat-card fxFlex="50" fxFlex.lt-md="100">
          <mat-card-title>Addresses</mat-card-title>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="16px" style="font-size: 18px;">
              <div fxLayout="column">
                <span><strong>Physical Address</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <span>{{entityDetails.physicalStreet }}</span>
                  <span>{{entityDetails.physicalCity }}</span>
                  <span>{{entityDetails.physicalState }}</span>
                  <span>{{entityDetails.physicalZip }}</span>
                  <span>{{entityDetails.physicalCounty }}</span>
                </div>
              </div>

              <div fxLayout="column" class="m-t-8">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                  <span><strong>Mailing Address</strong></span>
                  <mat-checkbox [checked]="entityDetails.mailingIsPhysical" disabled>
                    Same as physical
                  </mat-checkbox>
                </div>
                <div fxLayout="row" fxLayoutGap="16px" [@slideInOut] *ngIf="!entityDetails.mailingIsPhysical">
                  <span>{{entityDetails.mailingStreet }}</span>
                  <span>{{entityDetails.mailingCity }}</span>
                  <span>{{entityDetails.mailingState }}</span>
                  <span>{{entityDetails.mailingZip }}</span>
                  <span>{{entityDetails.mailingCounty }}</span>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Oregon Residency</strong></span>
                <mat-checkbox [checked]="entityDetails.oregonResidency" disabled>
                  <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                </mat-checkbox>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Current Documents-->
        <mat-card fxFlex="50" fxFlex.lt-lg="100">
          <mat-card-title>
            <span>Current Documents</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">
              <div fxLayout="row" *ngIf="!sharedService.mobile" style="font-size: 18px;">
                <span class="f-s-16" fxFlex="60"><strong>File</strong></span>
                <span class="f-s-16"><strong>Requirement</strong></span>
              </div>
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="" style="font-size: 18px;"
                *ngFor="let document of entityDetails.applicantDocuments">
                <span fxFlex="60" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                  (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>
                  <div fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex.lt-md="" fxLayout="column">
                      <span *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </span>
                    </div>
                  </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>
