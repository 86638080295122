<h2 mat-dialog-title>
  <mat-icon class="icon-20" >history_edu</mat-icon> Change Log 
  <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
  <section style="font-family: Oswald; font-size: 12pt;">
    <mat-checkbox  [(ngModel)]="search.displayLicense">License</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayPayments">Payments</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayApplicants">Applicants/People</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayNotes">Notes</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayDocuments">Documents</mat-checkbox>&nbsp;
    
    <!-- <mat-form-field appearance="fill">
      <mat-label>Users</mat-label>
      <mat-select>
        <mat-option *ngFor="let user of search.users" [value]="user">
          {{user}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <button mat-button (click)="showLogs()" color="primary" aria-label="Show Logs">Show Logs</button>
  </section>
</h2>
<div>
  <mat-card >
      <div fxLayout="column" fxLayoutGap="8px grid">
          <br/>
          <div *ngFor="let log of auditlogs">
            <audit-log-table [auditLog]=log></audit-log-table>
          </div>
        <br />
        <div *ngIf="loading">
          <small>Loading...</small>
          <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
          </mat-progress-bar>
        </div>
        <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
      </div>
  </mat-card>
</div>


