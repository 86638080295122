import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { ManagePaymentComponent } from './components/manage/manage-payments.component';
import { DialogEditPaymentComponent } from './components/edit/edit-payment-dialog';
import { DialogMarkPaidComponent } from './components/edit/mark-paid';

const COMPONENTS = [
    ManagePaymentComponent,DialogEditPaymentComponent,DialogMarkPaidComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [ManagePaymentComponent]
})
export class PaymentsModule { }
