<mat-card >
  <div fxLayout="column" fxLayoutGap="8px grid">
      <br/>
      <div *ngFor="let log of auditlogs">
        <audit-log-table [auditLog]=log></audit-log-table>
      </div>
    <br />
    <div *ngIf="loading">
      <small>Loading...</small>
      <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
      </mat-progress-bar>
    </div>
    <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
  </div>
</mat-card>
