<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.blue">
  <mat-card-content>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="" style="color: #303030">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" fxFlex="100">
        <mat-icon class="medium-icon m-24">toc</mat-icon>
        <span class="f-s-20">303 Data</span>
        <span fxFlex></span>
        <div fxLayout="row" fxLayoutGap="8px" *ngIf="authService.isAdmin || authService.isAnalyst">
          <div class="file-upload">
            <button mat-raised-button color="primary" class="upload-btn" (click)="uploadDeIdentifiedData()">
              Upload De-Identified Data
            </button>
          </div>
        </div>
        <button *ngIf="authService.isAdmin || authService.isAnalyst" type="button" mat-flat-button color="primary" (click)="downloadIndividualServiceCenterData()">Individual Service Center Data</button>
        <button *ngIf="authService.isAdmin || authService.isAnalyst" type="button" mat-flat-button color="primary" (click)="downloadAllData()">Reporting Window Data</button>
        <button *ngIf="authService.isAdmin || authService.isAnalyst" type="button" mat-flat-button color="primary" (click)="downloadAllApplicantData()">Download Applicant Data</button>
        <button *ngIf="authService.isAdmin || authService.isAnalyst" type="button" mat-flat-button color="primary" (click)="createReportingPeriod()">Manually "Start Reporting" for a Service Center</button>
        <button *ngIf="authService.isAdmin || authService.isAnalyst" type="button" mat-flat-button color="warn" (click)="closeReportingPeriod()">Close Reporting Period</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Search by Reporting Window</mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <span>Enter Reporting Window Dates (4/1-4/25; 7/1-7/25; 10/1-10/25; 1/1-1/25).</span>
      <span>To see multiple reporting windows at the same time, enter a broader range that includes the reporting window start date of all Reporting Windows being queried.</span>
    </div>
    <form [formGroup]="dateForm">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-form-field fxFlex.lt-md="100">
          <mat-label>Begin</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="from" matInput [matDatepicker]="from" />
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="dateForm.get('from').hasError('required')">
            Begin date is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex.lt-md="100">
          <mat-label>End</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="to" matInput [matDatepicker]="to" />
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="dateForm.get('to').hasError('required')">
            End date is required
          </mat-error>
        </mat-form-field>
        <button *ngIf="dateForm.valid" type="button" mat-flat-button color="primary" (click)="getRecordsByDate()">Get Records</button>
      </div>
    </form>
    <mat-card-title>Or search by Service Center ID</mat-card-title>
    <form [formGroup]="serviceCenterForm">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-form-field fxFlex.lt-md="100">
          <mat-label>Service Center ID</mat-label>
          <input matInput placeholder="License ID" formControlName="id">
          <mat-error *ngIf="serviceCenterForm.get('id').hasError('required')">
            Service Center ID is required
          </mat-error>
        </mat-form-field>

        <button *ngIf="serviceCenterForm.valid" type="button" mat-flat-button color="primary" (click)="getRecordsById()">Get Records</button>
      </div>
    </form>
  </mat-card-content>
  <mat-card-title>303 Client Data</mat-card-title>
  <div fxLayout="row">
    <span>These are service centers who started reporting quarterly 303 Client Data.</span>
  </div>
  <mat-form-field>
    <input matInput (keyup)="filterClientDataTable($event)" placeholder="Filter" />
  </mat-form-field>
  <mat-card-content>
    <mat-table [dataSource]="clientDataSource" matSort #clientDataSort="matSort" aria-describedby="Client Data Records" class="mat-elevation-z8">
      <ng-container matColumnDef="licenseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License ID</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">License ID</span>
          {{clientData.licenseId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">Entity Name</span>
          {{clientData.entityName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submitted">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Submission Status</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">Submission Status</span>
          <span *ngIf="clientData.submitted" style="color:green">Submitted</span>
          <span *ngIf="!clientData.submitted" style="color:red">Not Submitted</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Submitted</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">Date Submitted</span>
          {{clientData.submittedDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedOnTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On Time</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">Submitted On Time</span>
          <span style="color:green" *ngIf="submittedOnTime(clientData.submittedDate, clientData.reportingEndDate)">Yes</span>
          <span style="color:red" *ngIf="clientData.submittedDate && !submittedOnTime(clientData.submittedDate, clientData.reportingEndDate)">No</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="open">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Open</mat-header-cell>
        <mat-cell *matCellDef="let clientData">
          <span class="mobile-label">Open</span>
          <span *ngIf="clientData.open" style="color:green">Open</span>
          <span *ngIf="!clientData.open" style="color:red">Closed</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingStartDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Open Date</mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let clientData">
          <span class="mobile-label">Reporting Window Open Date</span>
          {{ clientData.reportingStartDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingEndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Close Date</mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let clientData">
          <span class="mobile-label">Reporting Window Close Date</span>
          {{ clientData.reportingEndDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let clientData" fxLayoutGap="8px">
          <mat-icon matTooltip="View Client Data" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="viewClientData(clientData.id)">visibility</mat-icon>
          <mat-icon matTooltip="Close" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="closeClientData(clientData.id)">lock</mat-icon>
          <mat-icon matTooltip="Open" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="openClientData(clientData.id)">lock_open</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="clientDataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: clientDataColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25]" #clientDataPaginator="matPaginator">
    </mat-paginator>
  </mat-card-content>

  <mat-card-title>303 Service Center Data</mat-card-title>
  <div fxLayout="row">
    <span>These are service centers who started reporting quarterly 303 Service Center Data.</span>
  </div>
  <mat-form-field>
    <input matInput (keyup)="filterServiceCenterDataTable($event)" placeholder="Filter" />
  </mat-form-field>
  <mat-card-content>
    <mat-table [dataSource]="serviceCenterDataSource" matSort #serviceCenterDataSort="matSort" aria-describedby="Client Data Records" class="mat-elevation-z8">
      <ng-container matColumnDef="licenseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License ID</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">License ID</span>
          {{serviceCenterData.licenseId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">Entity Name</span>
          {{serviceCenterData.entityName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submitted">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Submission Status</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">Submission Status</span>
          <span *ngIf="serviceCenterData.submitted" style="color:green">Submitted</span>
          <span *ngIf="!serviceCenterData.submitted" style="color:red">Not Submitted</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Submitted</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">Date Submitted</span>
          {{serviceCenterData.submittedDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedOnTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On Time</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">Submitted On Time</span>
          <span style="color:green" *ngIf="submittedOnTime(serviceCenterData.submittedDate, serviceCenterData.reportingEndDate)">Yes</span>
          <span style="color:red" *ngIf="serviceCenterData.submittedDate && !submittedOnTime(serviceCenterData.submittedDate, serviceCenterData.reportingEndDate)">No</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="open">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Open</mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData">
          <span class="mobile-label">Open</span>
          <span *ngIf="serviceCenterData.open" style="color:green">Open</span>
          <span *ngIf="!serviceCenterData.open" style="color:red">Closed</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingStartDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Open Date</mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let serviceCenterData">
          <span class="mobile-label">Reporting Window Open Date</span>
          {{ serviceCenterData.reportingStartDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingEndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Close Date</mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let serviceCenterData">
          <span class="mobile-label">Reporting Window Close Date</span>
          {{ serviceCenterData.reportingEndDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let serviceCenterData" fxLayoutGap="8px">
          <mat-icon matTooltip="View Service Center Data" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="viewServiceCenterData(serviceCenterData.id)">visibility</mat-icon>
          <mat-icon matTooltip="Close" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="closeServiceCenterData(serviceCenterData.id)">lock</mat-icon>
          <mat-icon matTooltip="Open" *ngIf="authService.isAnalyst || authService.isAdmin" (click)="openServiceCenterData(serviceCenterData.id)">lock_open</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceCenterDataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceCenterDataColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25]" #serviceCenterDataPaginator="matPaginator">
    </mat-paginator>
  </mat-card-content>

  <mat-card-title>303 Non-Reporting Licenses</mat-card-title>
  <div fxLayout="row">
    <span>These are service centers who did not start reporting any quarterly 303 data. They have not clicked the "Start Reporting" button on the public side 303 Data Reporting Portal.</span>
  </div>
  <mat-form-field>
    <input matInput (keyup)="filterLicensesTable($event)" placeholder="Filter" />
  </mat-form-field>
  <mat-card-content>
    <mat-table [dataSource]="licenseSource" matSort #licenseSort="matSort" aria-describedby="Non-Reporting Licenses" class="mat-elevation-z8">

      <ng-container matColumnDef="licenseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
        <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
          <span class="mobile-label">License Id</span>
          {{license.licenseId}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</mat-header-cell>
        <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
          <span class="mobile-label">Entity Name</span>
          {{license.entityName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="primaryContactName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Primary Contact Name</mat-header-cell>
        <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
          <span class="mobile-label">Primary Contact Name</span>
          {{license.primaryContactName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingStartDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Open Date</mat-header-cell>
        <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
          <span class="mobile-label">Reporting Window Open Date</span>
          {{ license.reportingStartDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reportingEndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Window Close Date</mat-header-cell>
        <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
          <span class="mobile-label">Reporting Window Close Date</span>
          {{ license.reportingEndDate | date }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="licenseColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: licenseColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25]" #licensePaginator="matPaginator">
    </mat-paginator>
  </mat-card-content>

  <mat-card-title *ngIf="authService.isAdmin || authService.isAnalyst">303 De-Identified Data</mat-card-title>
  <mat-form-field *ngIf="authService.isAdmin || authService.isAnalyst">
    <input matInput (keyup)="filterDeIdentifiedTable($event)" placeholder="Filter" />
  </mat-form-field>
  <mat-card-content *ngIf="authService.isAdmin || authService.isAnalyst">
    <mat-table [dataSource]="deIdentifiedDataSource" matSort #deIdentifiedDataSort="matSort" aria-describedby="De-Identified Data" class="mat-elevation-z8">

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Start Date</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">Reporting Start Date</span>
          {{data.startDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting End Date</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">Reporting End Date</span>
          {{data.endDate | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let data" fxLayoutGap="8px">
          <mat-icon matTooltip="Download"  (click)="downloadDeIdentifiedData(data.id)">save_alt</mat-icon>
          <mat-icon matTooltip="Delete" color="warn" (click)="deleteDeIdentifiedData(data.id)">delete</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="deIdentifiedDataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: deIdentifiedDataColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25]" #deIdentifiedDataPaginator="matPaginator">
    </mat-paginator>
  </mat-card-content>

  <mat-card-title>Required 303 Applicant and Licensee REAL & SOGI Data</mat-card-title>
  <form [formGroup]="applicantDataSearchForm">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-form-field fxFlex.lt-md="100">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
      <mat-form-field fxFlex.lt-md="100">
        <mat-label>License Id</mat-label>
        <input matInput placeholder="License ID" formControlName="licenseId">
      </mat-form-field>
      <mat-form-field fxFlex.lt-md="100">
        <mat-label>Submitted Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="submittedDate" matInput [matDatepicker]="applicantDataSubmitted" />
        <mat-datepicker-toggle matSuffix [for]="applicantDataSubmitted"></mat-datepicker-toggle>
        <mat-datepicker #applicantDataSubmitted>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>
      <button type="button" mat-flat-button color="primary" (click)="loadApplicantData()">Search</button>
    </div>
  </form>
  <mat-card-content>
    <mat-table [dataSource]="applicantDataSource" matSort #applicantDataSort="matSort" aria-describedby="Required 303 Applicant and Licensee REAL & SOGI Data" class="mat-elevation-z8" (matSortChange)="onSortChange($event)">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">Name</span>
          {{data.legalFirstName}} {{data.legalMiddleName}} {{data.legalLastName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="licenseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">License Id</span>
          {{data.licenseId}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="applicantStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant Status</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">Applicant Status</span>
          <span *ngIf="data.applicantStatus == 'licensee'">Licensee</span>
          <span *ngIf="data.applicantStatus == 'applicant'">Applicant</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="mobile-label">Submitted Date</span>
          {{data.submittedDate | date }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="applicantDataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: applicantDataColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #applicantDataPaginator
      [pageSize]="10"
      [length]="totalApplicantRecords"
      (page)="onPageChange($event)">
    </mat-paginator>
  </mat-card-content>
</mat-card>

