import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IManagerNotes } from "app/models/manager-notes/manager-notes";
import { ManagerNotesService } from "app/services/manager-notes/manager-notes.service";


@Component({
  selector: 'app-manager-notes-dialog',
  templateUrl: './manager-notes-dialog.component.html',
  styleUrls: ['./manager-notes-dialog.component.scss'],
})
export class ManagerNotesDialogComponent implements OnInit {
  public reviewTypes:string[] = ['Ready For Licensing Manager Review', 'Ready For Compliance Manager review', 'Under Review', 'Ready For Final Review'];
  public entityType: string = '';
  public entityId: string = '';
  public isAdmin: boolean = false;
  public managerNotes: IManagerNotes = {
    flag: "",
    reviewType: "",
    notes: "",
    entityId: 0,
    entityType: ""
  }

  public managerNotesForm = new FormGroup({
    flag: new FormControl(''),
    reviewType: new FormControl(''),
    notes: new FormControl(''),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                                       public dialogRef: MatDialogRef<ManagerNotesDialogComponent>,
                                       private toastr: ToastrService,
                                       public managerNotesService: ManagerNotesService) { }

  ngOnInit(): void {
    this.managerNotes.flag = this.data.managerFlag;
    this.managerNotes.reviewType = this.data.reviewType;
    this.managerNotes.notes = this.data.managerNotes;
    this.updateManagerNotesForm();
    this.managerNotes
    this.isAdmin = this.data.isAdmin;;
  }

  updateManagerNotesForm(): void {
    this.managerNotesForm.patchValue({
      flag: this.data.managerFlag,
      reviewType: this.data.reviewType,
      notes: this.data.managerNotes
    });
  }


  selectFlag(select: string): void {
    if(this.isAdmin)
      this.managerNotesForm.patchValue({
        flag: select
      });
  }

  closeDialog(): void {
    this.dialogRef.close(this.managerNotes);
  }

  save(): void {
    this.managerNotes.flag = this.managerNotesForm.get('flag')?.value;
    this.managerNotes.reviewType = this.managerNotesForm.get('reviewType')?.value;
    this.managerNotes.notes = this.managerNotesForm.get('notes')?.value;
    this.managerNotes.entityId = this.data.entityId;
    this.managerNotes.entityType = this.data.entityType;

    this.managerNotesService.saveNotes(this.managerNotes).subscribe(
      {
        next: response => {
          if(response)
            this.toastr.success('Manager Notes Saved');
          if(!response)
            this.toastr.error('Error Saving Manager Notes');
        },
        error: e => {
          this.toastr.error('Error Saving Manager Notes');
        }
      }

    );
  }
}
