<mat-card fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row">
    <mat-card-title>Manually "Start Reporting" for a Service Center</mat-card-title>
    <span fxFlex></span>
    <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
  </div>
  <div fxLayout="row">
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="10px">
        <span style="font-size: 16px;">Use this option to allow a service center to report when they have not clicked "Start Reporting" during a designated reporting period.</span>
        <span style="font-size: 16px;">If the license number shows up in the 303 Client Data and 303 Service Center data sections for the reporting period in question, do not use this button- the service center has already begun reporting.</span>
        <span style="font-size: 16px;">Enter a date range that matches the reporting window (4/1-4/25; 7/1-7/25; 10/1-10/25; 1/1-1/25).</span>
      </div>
    </mat-card-content>
  </div>
  <div fxLayout="row" fxFlex="100">
    <form [formGroup]="createReportForm" fxFlex="100" fxLayoutGap="10px">

      <mat-form-field fxFlex="30">
        <mat-label>License Id</mat-label>
        <input matInput type="text" formControlName="licenseId" placeholder="License Id">
        <mat-error *ngIf="createReportForm.get('licenseId').hasError('required')">
          License Id is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.lt-md="100">
        <mat-label>Begin</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="from" matInput [matDatepicker]="from" />
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="createReportForm.get('from').hasError('required')">
          Begin date is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.lt-md="100">
        <mat-label>End</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="to" matInput [matDatepicker]="to" />
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="createReportForm.get('to').hasError('required')">
          End date is required
        </mat-error>
      </mat-form-field>

      <div fxLayout="column" fxLayoutAlign="center center">
        <button type="button" mat-flat-button color="primary" (click)="createReport()" *ngIf="createReportForm.valid">Open Data Reporting Portal</button>
      </div>
    </form>
  </div>
</mat-card>
