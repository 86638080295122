<div fxLayout="column">
  <span class="f-s-20">
    Psilocybin Complaints
  </span>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutAlign.lt-md="">
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
    </mat-form-field>
    <span fxFlex></span>
    <span class="f-s-16">Click to Edit</span>

    <button type="button" mat-flat-button color="primary" matTooltip="Enter Complaint" matTooltipShowDelay=800
      aria-label="Enter Complaint" (click)="addComplaint()">
      Enter Complaint
      <mat-icon>spatial_audio_off</mat-icon>
    </button>
  </div>
  <mat-table [dataSource]="complaintSource" matSort #complaintSort="matSort" aria-describedby="complaints"
    class="mat-elevation-z8">
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Entered</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">Date Entered</span>
        {{ complaint.dateCreated | date }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">Name</span>
        {{ complaint.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="licenseName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{entityTypeRef[entityType]}} Name</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">{{entityTypeRef[entityType]}} Name</span>
        {{ complaint.parentLicenseName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="parentId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{entityTypeRef[entityType]}}</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">{{entityTypeRef[entityType]}}</span>
        <span>{{ complaint.parentName }}<br /><em>{{ complaint.parentId }}</em></span>

      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="complaintText">
      <mat-header-cell *matHeaderCellDef>Complaint</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">Complaint</span>
        <span style="white-space: pre-wrap">{{complaint.complaintText}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="contactEmail">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">Email</span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '150px': '150px'}"
          style="word-wrap: break-word; white-space: normal">
          <div fxLayout="column">
            <span>{{ complaint.contactEmail }}</span>
            <span>{{ complaint.preferredContact === 'Email'? 'Preferred' : '' }}</span>
          </div>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="contactNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
      <mat-cell *matCellDef="let complaint">
        <span class="mobile-label">Phone</span>
        <div fxLayout="column">
          <span>{{ complaint.contactNumber | mask: '(000) 000-0000' }}</span>
          <span>{{ complaint.preferredContact === 'Phone'? 'Preferred' : '' }}</span>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="complaintColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: complaintColumns" (click)="editComplaint(row)">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" #complaintPaginator="matPaginator">
  </mat-paginator>
</div>
