<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.green">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
      <mat-icon class="medium-icon m-24">domain</mat-icon>
      <span class="f-s-20">Service Center Tasks</span>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column">
  <div>
    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
    </mat-form-field>
  </div>
  <mat-table [dataSource]="taskSource" matSort #taskSort="matSort" aria-describedby="service center tasks"
  class="mat-elevation-z8">
  <ng-container matColumnDef="entityName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <span class="mobile-label">Entity Name</span>
      {{ task.entityName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tasks">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Tasks</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <span class="mobile-label">Tasks</span>
      <div fxLayout="column">
        <div *ngFor="let task of task.tasks; let i = index">
          <span *ngIf="!task.acknowledge && !task.complete && !task.archived">{{task.taskText}}</span>
          <span *ngIf="task.acknowledge && !task.complete && !task.archived">{{(i + 1) + ': ' + task.taskText}}</span>
        </div>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <span class="mobile-label">Status</span>
      {{ task.status }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assignedTo">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
        <mat-select [(value)]="task.assignedTo" (selectionChange)="assignTask($event, task.id)">
          <mat-option>-- None --</mat-option>
          <mat-option *ngFor="let staff of licenseStaff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf="!authService.isAdmin">{{task.assignedTo}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="claim">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Claim/Release</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <button mat-button class="m-l-16" type="button" color="primary"
      *ngIf="sharedService.userNameClaim != task.assignedTo"
      (click)="claimProgram(task.id)">Claim &amp; Edit</button>
      <button mat-button class="m-l-16" type="button" color="error"
        *ngIf="sharedService.userNameClaim == task.assignedTo"
        (click)="releaseProgram(task.id, $event)">Release </button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assignedToCompliance">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
        <mat-select [(value)]="task.assignedToCompliance" (selectionChange)="assignComplianceMember($event, task.id)">
         <mat-option>-- None --</mat-option>
         <mat-option *ngFor="let staff of licenseStaff" value={{staff.name}}>{{staff.name}}
         </mat-option>
       </mat-select>
     </mat-form-field>
     <span *ngIf="!authService.isAdmin">{{task.assignedToCompliance}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
    <mat-cell *matCellDef="let task">
      <span class="mobile-label">Actions</span>
      <div fxLayout="column" fxLayoutGap="8px">
        <div *ngFor="let task of task.tasks; let i = index">
          <button *ngIf="task.sendNotice && !task.complete && !task.archived" mat-flat-button color="primary" style="line-height: normal" (click)="task.requiresReason? sendTaskWithReason(task) :
                                                                                                                                    sendTaskNotice(task)">
            <span class="text-wrap">{{task.taskText}}</span>
          </button>
          <button *ngIf="task.acknowledge && !task.complete && !task.archived" mat-flat-button color="primary" (click)="acknowledgeTask(task.id)">
            Acknowledge {{i + 1}}
          </button>
        </div>
      </div>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="taskColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: taskColumns">
  </mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" #taskPaginator="matPaginator">
</mat-paginator>
</div>
<div *ngIf="sharedService.loading || loading || loadingStaff">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
