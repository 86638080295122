import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SB303Service } from "app/services/303/303.service";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-download-individual-service-center-data',
  templateUrl: './dialog-download-individual-service-center-data.component.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class DialogDownloadIndividualServiceCenterDataComponent {

  public loading: boolean = false;

  public dateForm = new FormGroup({
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
    licenseId: new FormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<DialogDownloadIndividualServiceCenterDataComponent>,
    public sb303Service: SB303Service) { }

    download(): void {
      let form = this.dateForm.value;
      let from = new Date(form.from).toDateString();
      let to = new Date(form.to).toDateString();

      let fileName = `${from}-${to} ${form.licenseId} Individual Service Center Data.csv`

      this.sb303Service.downloadIndividualServiceCenterData(from, to, form.licenseId).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const download = document.createElement('a');
        download.href = url;
        download.download = fileName;
        download.click();
        window.URL.revokeObjectURL(url);
      });
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
