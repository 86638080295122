import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IStaff } from 'app/models/user-info/staff';
import { IDocumentType } from 'app/models/documents/documentType';
import { ITaskSendNotice } from 'app/models/tasks/task';
import { IWorkerLicenseSimple } from 'app/models/licenses/worker/worker-license-simple';
import { IWorkerPermitSearch } from 'app/models/licenses/worker/worker-search';
import { IWorkerLicense } from 'app/models/licenses/worker/worker-license';
import { IDocument } from 'app/models/documents/document';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';

@Injectable({providedIn: 'root'})
export class WorkerPermitService {
  private api: string = environment.privateApi + 'WorkerPermit/';
  private taskSendNotice: ITaskSendNotice=  {
    id: 0,
    reason: ''
  }

  constructor(private http: HttpClient) { }

  getPDF(search: AuditLoggingSearch): Observable<IWorkerLicense>{
    return this.http.post<IWorkerLicense>(this.api + "OriginalCopy", search);
  }

  getWorkerPermitasks(): Observable<IWorkerLicenseSimple[]>{
    return this.http.get<IWorkerLicenseSimple[]>(this.api + 'GetWorkerPermitTasks');
  }

  withdrawLicense(approved: boolean, id: number): Observable<IWorkerLicense>{
    return this.http.post<IWorkerLicense>(this.api + "withdrawLicense/" + approved + "/" + id, null);
  }

  surrenderLicense(approved: boolean, id: number): Observable<IWorkerLicense>{
    return this.http.post<IWorkerLicense>(this.api + "surrenderLicense/" + approved + "/" + id, null);
  }

  getWorkerPermit(id: number): Observable<IWorkerLicense>{
    return this.http.get<IWorkerLicense>(this.api + 'GetWorkerPermit/' + id);
  }

  checkDuplicates(applicantId: number): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckForDuplicate/' + applicantId);
  }

  searchWorkerPermits(search: IWorkerPermitSearch): Observable<IWorkerLicenseSimple[]>{
    return this.http.post<IWorkerLicenseSimple[]>(this.api + "SearchWorkerPermits", search);
  }

  claimWorkerPermit(id: number): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'ClaimWorkerPermit' + '/' + id, null);
  }

  getAssignedPermits(): Observable<IWorkerLicenseSimple[]>{
    return this.http.get<IWorkerLicenseSimple[]>(this.api + "GetAssignedPermits");
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  updateWorkerPermit(program: IWorkerLicense): Observable<IWorkerLicenseSimple>{
    return this.http.post<any>(this.api + 'UpdateWorkerPermit', program);
  }

  uploadDocument(file: FormData, id: number): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadWorkerDocument/' + id, file)
  }

  createWorkerPermit(): Observable<IWorkerLicense>{
    return this.http.post<IWorkerLicense>(this.api + 'createWorkerPermit', null);
  }

  sendTaskNotice(id: number, reason: string): Observable<boolean> {
    let taskSendNotice: ITaskSendNotice = {
      id: id,
      reason: reason
    };

    return this.http.post<boolean>(this.api + 'SendTaskNotice/', taskSendNotice);
  }

  acknowledgeTask(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AcknowledgeTask/' + id, null);
  }

  deleteTask(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteTask/' + id);
  }

  renewWorkerPermit(id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'RenewWorkerPermit/' + id, null);
  }

  assignWorkerPermit(id: number, staff: IStaff): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignWorkerPermit/' + id, staff);
  }

  releaseWorkerPermit(id: number): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'ReleaseWorkerPermit' + '/' + id, null);
  }

  deleteWorkerPermit(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteWorkerPermit/" + id);
  }

  downloadWorkerFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadWorkerDocument/" + fileId, { responseType: 'blob' as 'json' })
  }

  deleteWorkerDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteWorkerDocument/" + id)
  }
  deprecateDocument(id: number): Observable<any>{
    return this.http.post<any>(this.api + "DeprecateDocument/" + id,null);
  }

  getWorkerStaff(): Observable<IStaff[]>{
    return this.http.get<IStaff[]>(this.api + "GetWorkerStaff");
  }

  updateWorkerPermitAccount(licenseId: number, userId: string): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'UpdateWorkerPermitAccount/' + licenseId + '/' + userId, null);
  }

  updateWorkerPermitPaymentId(program: IWorkerLicense): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'UpdateWorkerPermitPaymentId', program);
  }

  DeleteAddress(addressId: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'DeleteAddress/' + addressId, null);
  }

  checkWorkerPermitExists(licenseId: string): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckWorkerPermitExists/' + licenseId);
  }
}
