<mat-card [style.background]="cardColor">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">Manager Notes</span>
      </div>
      <form [formGroup]="managerNotesForm" *ngIf="editingNotes && isAdmin">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
            <mat-card fxFlex="40">
              <mat-card-title *ngIf="isAdmin">
                Select Flag
              </mat-card-title>
              <mat-card-title *ngIf="!isAdmin">
                Flag
              </mat-card-title>
              <mat-card-content fxLayoutGap="8px">
                <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'red'" style="color:red" (click)="selectFlag('red')">flag</mat-icon>
                <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'blue'" style="color:blue" (click)="selectFlag('blue')">flag</mat-icon>
                <mat-icon [class.selected]="managerNotesForm.get('flag').value === 'yellow'" style="color:rgb(229, 238, 65)" (click)="selectFlag('yellow')">flag</mat-icon>
                <mat-icon [class.selected]="managerNotesForm.get('flag').value === '' || managerNotesForm.get('flag').value === null" (click)="selectFlag('')">not_interested</mat-icon>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="60">
              <mat-card-title>
                Review Type
              </mat-card-title>
              <mat-card-content fxLayoutGap="8px">
                <mat-form-field fxFlex="100">
                  <mat-label>
                    Review Type
                  </mat-label>
                  <mat-select formControlName="reviewType" fxFlex="100" (selectionChange)="onSelectionChange($event)">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let type of reviewTypes" [value]="type">
                      {{type}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
            <mat-card fxFlex="100">
              <mat-card-title>
                Notes
              </mat-card-title>
              <mat-card-content fxLayoutGap="8px">
                <mat-form-field fxFlex="100">
                  <textarea formControlName="notes" matInput rows="5" placeholder="Notes" type="text" (blur)="onBlur($event)">
                  </textarea>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>
      <div fxLayout="column" fxFlex="100" *ngIf="(!editingNotes || (!isAdmin && (managerFlag == 'yellow' || managerFlag == 'blue')))">
        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-card fxFlex="40">
            <mat-card-title>
              Flag
            </mat-card-title>
            <mat-card-content fxLayoutGap="8px">
              <mat-icon *ngIf="managerFlag == 'red'" style="color:red">flag</mat-icon>
              <mat-icon *ngIf="managerFlag == 'blue'" style="color:blue">flag</mat-icon>
              <mat-icon *ngIf="managerFlag == 'yellow'" style="color:rgb(229, 238, 65)">flag</mat-icon>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex="60">
            <mat-card-title>
              Review Type
            </mat-card-title>
            <mat-card-content fxLayoutGap="8px">
              <span>{{reviewType}}</span>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-card fxFlex="100">
            <mat-card-title>
              Notes
            </mat-card-title>
            <mat-card-content fxLayoutGap="8px">
                <span fxFlex="100" class="text-wrap">{{notes}}</span>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
