import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { INote } from "app/models/notes/note";
import { NoteService } from "app/services/notes/notes.service";
import { ToastrService } from "ngx-toastr";

export const DateFormats = {
  parse: {
    dateInput: ['YYYY/MM/DD']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
    selector: 'dialog-edit-note',
    templateUrl: 'edit-note.html',
    providers: [NoteService, { provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  })
  export class DialogEditNoteComponent implements OnInit  {
    public processing: boolean = false;
    public noteForm = new UntypedFormGroup({
        text: new UntypedFormControl("", [Validators.required])
    });

    constructor(public noteService: NoteService,
                private toastr: ToastrService,
                private dialogRef: MatDialogRef<DialogEditNoteComponent>,
                @Inject(MAT_DIALOG_DATA) public note: INote) {}

    ngOnInit(): void {
      this.noteForm.patchValue({
        text:  this.note.text
      });
    }

    saveNote() {
      if(this.noteForm.valid) {
        this.processing = true;
        let form = this.noteForm.value;
        this.note.text = form.text;

        this.noteService.saveNote(this.note).subscribe(
          response => {
          this.processing = false;
          this.toastr.success("Note Saved" );
          this.dialogRef.close(response);
        },
        error => {
          console.error('error: ' + error.statusText);
          this.processing = false;
          this.toastr.error("Unable to save note");
        });
      }
    }

    cancel() {
      this.dialogRef.close('cancel');
    }
  }
