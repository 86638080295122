<div fxLayout="column" [hidden]="viewingComplianceCase">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
    <span><strong>Compliance Plans</strong></span>
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event, 'compliancePlans')" placeholder="Filter" />
    </mat-form-field>
    <span fxFlex></span>
    <span *ngIf="editing">Click to Edit</span>
    <button type="button" mat-icon-button color="primary" *ngIf="editing" (click)="addCompliancePlan()"
      matTooltip="Add Compliance Plan" matTooltipShowDelay=800 aria-label="Add Compliance Plan">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-table [dataSource]="compliancePlanSource" aria-describedby="compliancePlans" class="mat-elevation-z8">
    <ng-container matColumnDef="compliancePlanId">
      <mat-header-cell *matHeaderCellDef>Compliance Plan ID</mat-header-cell>
      <mat-cell *matCellDef="let compliancePlan">
        <span class="mobile-label">Compliance Plan ID</span>
        <span>{{ compliancePlan.compliancePlanId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let compliancePlan">
        <span class="mobile-label">Status</span>
        {{ compliancePlan.status }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startedDate">
      <mat-header-cell *matHeaderCellDef>Date Started</mat-header-cell>
      <mat-cell *matCellDef="let compliancePlan">
        <span class="mobile-label">Date Started</span>
        {{ compliancePlan.startedDate | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignedTo">
      <mat-header-cell *matHeaderCellDef>Assigned To</mat-header-cell>
      <mat-cell *matCellDef="let compliancePlan">
        <span class="mobile-label">Assigned To</span>
        {{staffReference[compliancePlan.assignedTo]}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let compliancePlan" (click)="$event.stopPropagation()">
        <div fxLayout="row" *ngIf="editing">
          <button type="button" mat-icon-button class="m-8" (click)="deleteCompliancePlan(compliancePlan.id)"
            color="warn" aria-label="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="compliancePlanColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: compliancePlanColumns" (click)="editing? editCompliancePlan(row) : ''"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" #compliancePlanPaginator="matPaginator"></mat-paginator>
</div>
<!-- Compliance Plan Details -->
<div class="m-t-16" *ngIf="viewingComplianceCase">
  <div fxLayout="column" fxLayoutGap="16px">
    <form [formGroup]="compliancePlanForm">
      <div fxLayout="column">
        <span style="font-size: x-large" fxFlex><strong>Compliance Plan</strong></span>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>Compliance Plan ID:</span>
            <span>{{compliancePlan.compliancePlanId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(compliancePlan.compliancePlanId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span fxFlex></span>
          <div fxLayout="row">
            <button mat-button type="button" color="primary" (click)="exportPlan()">Export to PDF</button>
            <button *ngIf="editing" type="button" mat-button color="primary" (click)="updateCompliancePlan(false)">Save</button>
            <button *ngIf="editing" type="button" mat-button color="primary" (click)="updateCompliancePlan(true)">Save and Close</button>
            <button type="button" mat-button color="warn" (click)="viewComplianceCase()">Close</button>
            <button type="button" mat-icon-button [color]="editing? 'primary':'warn'"
              [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800
              aria-label="Edit Case" (click)="edit()">
              <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-t-8" *ngIf="editing">
          <mat-form-field fxFlex="15" fxFlex.lt-md="100">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option value="New">New</mat-option>
              <mat-option value="Working">Working</mat-option>
              <mat-option *ngIf="authService.isAdmin" value="Complete">Complete</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
            <mat-label>Assigned To</mat-label>
            <mat-select formControlName="assignedTo">
              <mat-option [value]="">-- None --</mat-option>
              <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
            <mat-label>Date Started</mat-label>
            <input placeholder="mm/dd/yyyy"
                  formControlName="startedDate"
                  matInput
                  [matDatepicker]="startedDate"
                  [max]="today" />
            <mat-datepicker-toggle matSuffix [for]="startedDate"></mat-datepicker-toggle>
            <mat-datepicker #startedDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <mat-error *ngIf="compliancePlanForm.get('startedDate').value > today">
              Please choose an earlier date.
            </mat-error>
          </mat-form-field>


          <mat-checkbox fxFlex="25" fxFlex.lt-md="100" formControlName="sentTrainingPlan" fxLayoutAlign="start center">
            Plan Sent
          </mat-checkbox>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-t-8" *ngIf="!editing">
          <div fxLayout="column" fxFlex="15" fxFlex.lt-md="100" fxLayoutAlign="center center">
            <span><strong><u>Status</u></strong></span>
            <span>{{compliancePlan.status}}</span>
          </div>

          <div fxLayout="column" fxFlex="15" fxFlex.lt-md="100" fxLayoutAlign="center center">
            <span><strong><u>Assigned To</u></strong></span>
            <span>{{staffReference[compliancePlan.assignedTo]}}</span>
          </div>

          <div fxLayout="column" fxFlex="15" fxFlex.lt-md="100" fxLayoutAlign="center center">
            <span><strong><u>Date Started</u></strong></span>
            <span>{{compliancePlan.startedDate | date}}</span>
          </div>

          <div fxLayout="column" fxFlex="15" fxFlex.lt-md="100" fxLayoutAlign="center center">
            <span><strong><u>Plan Status</u></strong></span>
            <span *ngIf="compliancePlan.sentTrainingPlan">Sent</span>
            <span *ngIf="!compliancePlan.sentTrainingPlan">Not Sent</span>
          </div>
        </div>
      </div>
    </form>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="90"></div>
      <div fxLayout="column" fxFlex="10">
        <button *ngIf="editing" type="button" mat-icon-button color="primary" (click)="addCompliancePlanRules()"
          matTooltip="Add Rule Reference" matTooltipShowDelay=800 aria-label="Add Rule Reference">
          Add Rule
        </button>
      </div>
    </div>

    <div *ngFor="let rule of compliancePlan.compliancePlanRules">
      <ng-container [ngTemplateOutlet]="compliancePlanRuleTemplate"
                    [ngTemplateOutletContext]="{compliancePlanRule: rule}">
      </ng-container>
    </div>

    <div fxLayout="row">
      <button *ngIf="editing" type="button" mat-button color="primary" (click)="updateCompliancePlan(false)">Save</button>
      <button *ngIf="editing" type="button" mat-button color="primary" (click)="updateCompliancePlan(true)">Save and Close</button>
      <button type="button" mat-button color="warn" (click)="viewComplianceCase()">Close</button>
      <button type="button" mat-icon-button [color]="editing? 'primary':'warn'"
        [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800
        aria-label="Edit Case" (click)="edit()">
        <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #compliancePlanRuleTemplate let-compliancePlanRule="compliancePlanRule">
  <mat-card>
    <mat-card-content>
      <mat-card  fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
          <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row">
              <span style="font-size: large"><strong>Rule out of compliance: {{compliancePlanRule.rule.statute}}</strong></span>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="editing" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row">
              <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="compliancePlanRule.status">
                  <mat-option value="">Select</mat-option>
                  <mat-option value="New">New</mat-option>
                  <mat-option value="Working">Working</mat-option>
                  <mat-option *ngIf="authService.isAdmin" value="Complete">Complete</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="!editing" style="font-size: large" fxLayoutAlign.lt-md="center center">
            <div fxLayout="column">
              <span><strong>Status</strong></span>
              <span>{{compliancePlanRule.status}}</span>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="editing" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row">
              <mat-form-field appearance="fill">
                <mat-label>Resolved Date</mat-label>
                <input
                  type="text"
                  [(ngModel)]="compliancePlanRule.resolvedDate"
                  matInput
                  [matDatepicker]="resolvedDate"/>
                <mat-datepicker-toggle matSuffix [for]="resolvedDate"></mat-datepicker-toggle>
                <mat-datepicker #resolvedDate>
                  <mat-datepicker-actions>
                    <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    <button mat-button matDatepickerCancel>Cancel</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="!editing" style="font-size: large" fxLayoutAlign.lt-md="center center">
            <div fxLayout="column">
              <span><strong>Resolved Date</strong></span>
              <span>{{compliancePlanRule.resolvedDate | date}}</span>
            </div>
          </div>
        </div>

        <mat-card-content fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
            <mat-card fxFlex="50">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field fxFlex="100" *ngIf="editing">
                  <textarea [(ngModel)]="compliancePlanRule.rulesSummary" matInput rows="5" placeholder="Rules Summary" type="text"></textarea>
                </mat-form-field>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing">
                  <span><strong>Rules Summary</strong></span>
                  <span >{{compliancePlanRule.rulesSummary}}</span>
                </div>
              </div>
            </mat-card>

            <mat-card fxFlex="50">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field fxFlex="100" *ngIf="editing">
                  <textarea [(ngModel)]="compliancePlanRule.notMeetingRequirementReason" matInput rows="5" placeholder="Reason for not meeting Requirement" type="text"></textarea>
                </mat-form-field>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing">
                  <span><strong>Reason for not meeting Requirement</strong></span>
                  <span >{{compliancePlanRule.notMeetingRequirementReason}}</span>
                </div>
              </div>
            </mat-card>
          </div>

          <div fxLayout="row">
            <mat-card fxFlex="100">
              <div fxLayout="row" fxFlex="100">
                <mat-form-field fxFlex="100" *ngIf="editing">
                  <textarea [(ngModel)]="compliancePlanRule.notes" matInput rows="5" placeholder="Plan and Guidance" type="text"></textarea>
                </mat-form-field>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing">
                  <span><strong>Plan and Guidance</strong></span>
                  <span >{{compliancePlanRule.notes}}</span>
                </div>
              </div>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</ng-template>
