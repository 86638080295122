<mat-card [style.background]="cardColor">
  <mat-card-title>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <span>Complaints</span>
      <span fxFlex></span>
      <span *ngIf="editing" class="f-s-14">Click Entry to Edit</span>
      <button type="button" mat-flat-button color="primary" matTooltip="Enter Complaint" matTooltipShowDelay=800
        aria-label="Enter Complaint" (click)="addComplaint()" [disabled]="status !== sharedService.approved || !editing">
        Enter Complaint
        <mat-icon>spatial_audio_off</mat-icon>
      </button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-table [dataSource]="complaintSource" matSort #complaintSort="matSort" aria-describedby="complaints"
      class="mat-elevation-z8">
      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Entered</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Date Entered</span>
          {{ complaint.dateCreated | date }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Name</span>
          {{ complaint.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="complaintText">
        <mat-header-cell *matHeaderCellDef>Complaint</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Complaint</span>
          <span style="white-space: pre-wrap">{{complaint.complaintText}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contactEmail">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Email</span>
          <span [ngStyle]="{'max-width': sharedService.isMobile? '150px': '150px'}"
            style="word-wrap: break-word; white-space: normal">
            <div fxLayout="column">
              <span>{{ complaint.contactEmail }}</span>
              <span>{{ complaint.preferredContact === 'Email'? 'Preferred' : '' }}</span>
            </div>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contactNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Phone</span>
          <div fxLayout="column">
            <span>{{ complaint.contactNumber | mask: '(000) 000-0000' }}</span>
            <span>{{ complaint.preferredContact === 'Phone'? 'Preferred' : '' }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Status</span>
          <div fxLayout="column">
            <span>{{ complaint.status }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <button mat-flat-button
                  type="button"
                  color="primary"
                  (click)="openNonCompliance(complaint)">Create Case</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="complaintColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: complaintColumns"  (click)="editing? editComplaint(row) : ''" ></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" #complaintPaginator="matPaginator">
    </mat-paginator>
  </mat-card-content>
</mat-card>
