import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComplaintDialogComponent } from 'app/components/complaint/complaint.component';
import { IComplaint } from 'app/models/complaints/complaint';
import { IComplaintDialogData } from 'app/models/complaints/complaint-dialog-data';
import { ComplaintService } from 'app/services/complaints/complaint.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-entity-complaints',
  templateUrl: './entity-complaints.component.html',
  styleUrls: ['./entity-complaints.component.scss']
})
export class EntityComplaintsComponent implements OnInit {
  @Input() entityType: string = '';

  public entityTypeRef: {[type: string]: string } =
  {
    ['Facilitator']: 'Facilitator',
    ['WorkerPermit']: 'Worker Permit',
    ['Manufacturer']: 'Manufacturer',
    ['TestingLab']: 'Testing Lab',
    ['TrainingProgram']: 'Training Program',
    ['ServiceCenter']: 'Service Center'
  };
  public complaints: IComplaint[] = [];
  public complaintColumns: string[] = ['dateCreated', 'name', 'licenseName', 'parentId', 'complaintText', 'contactEmail', 'contactNumber'];
  public complaintSource = new MatTableDataSource<IComplaint>(this.complaints);
  @ViewChild('complaintPaginator', {static: false}) complaintPaginator: MatPaginator;
  @ViewChild('complaintSort', {static: false}) complaintSort: MatSort;

  constructor(public sharedService: SharedService,
              public complaintService: ComplaintService,
              public dialog: MatDialog,
              public toastr: ToastrService) { }

  ngOnInit(): void {
      this.complaintService.getComplaintsByType(this.entityType).subscribe(
        response => this.complaints = response,
        error => console.log('error', error),
        () => {
          this.complaintSource.data = this.complaints;
          this.complaintSource.sort = this.complaintSort;
          this.complaintSource.paginator = this.complaintPaginator;
        }
      );
  }

  filterTable(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.complaintSource.filter = filter;
  }

  addComplaint(): void{
    let complaint: IComplaint = {
      id: 0,
      entityType: '',
      status: '',
      name: '',
      contactEmail: '',
      contactNumber: '',
      preferredContact: '',
      identityConcern: '',
      parentId: '',
      parentName: '',
      complaintText: '',
      previousReporting: '',
      signature: '',
      dateCreated: '',
      parentLicenseName: '',
      assignedTo: '',
      noticeStatus: '',
      caseId: ''
    };
    let dialogData: IComplaintDialogData = {
      type: this.entityType,
      complaint: complaint,
      disableParentSelection: false,
      caseId: complaint.caseId
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.complaints.push(response);
        this.complaintSource.data = this.complaints;
        this.complaintSource.sort = this.complaintSort;
        this.complaintSource.paginator = this.complaintPaginator;
        this.toastr.success("Added Complaint!");
      }
    });
  }

  editComplaint(complaint: IComplaint): void {
    let dialogData: IComplaintDialogData = {
      type: this.entityType,
      complaint: complaint,
      disableParentSelection: false,
      caseId: complaint.caseId
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        let index = this.complaints.findIndex(c => c.id === response.id);
        this.complaints[index] = response;
        this.complaintSource.data = this.complaints;
        this.complaintSource.sort = this.complaintSort;
        this.complaintSource.paginator = this.complaintPaginator;
        this.toastr.success("Complaint Updated!");
      }
    });
  }
}
