<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row">
    <span style="font-size: larger;"><strong>Download Individual Service Center Data</strong></span>
    <span fxFlex></span>
    <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
  </div>
  <br/>
  <span>Enter Reporting Window start and end dates (4/1-4/25; 7/1-7/25; 10/1-10/25; 1/1-1/25) and a service center license number to download all data submitted for a particular service center for a particular reporting period.</span>
  <span>Note: To download all data submitted by all service centers for a particular reporting period, use the "Reporting Window Data" button.</span>
  <form [formGroup]="dateForm">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-form-field fxFlex.lt-md="100">
        <mat-label>Begin</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="from" matInput [matDatepicker]="from" />
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="dateForm.get('from').hasError('required')">
          Begin date is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.lt-md="100">
        <mat-label>End</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="to" matInput [matDatepicker]="to" />
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="dateForm.get('to').hasError('required')">
          End date is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.lt-md="100">
        <mat-label>License Id</mat-label>
        <input placeholder="License Id" formControlName="licenseId" matInput/>
        <mat-error *ngIf="dateForm.get('licenseId').hasError('required')">
          License Id is required
        </mat-error>
      </mat-form-field>

      <button *ngIf="dateForm.valid" type="button" mat-flat-button color="primary" (click)="download()">Download</button>
    </div>
  </form>
</div>

