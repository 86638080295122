import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { ApplicantSearchComponent } from './applicant-search/applicant-search.component';

const routes: Routes = [
  { path: "", redirectTo: "search", pathMatch: 'full'  },
  { path: 'search', component: ApplicantSearchComponent, canActivate: [ AuthGuard ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicantRoutingModule { }
