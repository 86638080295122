import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ILicenseStatuses } from 'app/models/license-dashboard/licenseStatuses';
import { ILicenseIdandIdentifier } from 'app/models/license-dashboard/licenseIdandIdentifier';
import { ILicenseAdminSettings } from 'app/models/product-tracking/admin-settings';
import { ILicenseDashboardCase } from 'app/models/license-dashboard/license-dashboard-case';

@Injectable({providedIn: 'root'})
export class LicenseDashboardService {
  private api: string = environment.privateApi + 'LicenseDashboard/';

  constructor(private http: HttpClient) { }

  GetAllStatuses(): Observable<ILicenseStatuses>{
    return this.http.get<ILicenseStatuses>(this.api + "GetAllStatuses");
  };

  GetAssignedLicenses(): Observable<ILicenseIdandIdentifier[]>{
    return this.http.get<ILicenseIdandIdentifier[]>(this.api + "GetAssignedLicenses");
  };

  GetAssignedComplianceLicenses(): Observable<ILicenseIdandIdentifier[]>{
    return this.http.get<ILicenseIdandIdentifier[]>(this.api + "GetAssignedComplianceLicenses");
  };

  GetAssignedCases(): Observable<ILicenseDashboardCase[]>{
    return this.http.get<ILicenseDashboardCase[]>(this.api + "GetAssignedCases");
  };

  updateAdminSettings(settings: ILicenseAdminSettings): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'UpdateAdminSettings', settings);
  }

  getAdminSettings(): Observable<ILicenseAdminSettings>{
    return this.http.get<ILicenseAdminSettings>(this.api + 'GetAdminSettings');
  }
}
