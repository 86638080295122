import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { IComplianceTrackingItem } from 'app/models/compliance/compliance-tracking-item';
import { ISiteInspectionTrackingItem } from 'app/models/site-inspection/site-inspection-tracking-item';
import { IStaffType } from 'app/models/user-info/staff-type';
import { ComplianceTrackingService } from 'app/services/compliance-tracking/compliance-tracking.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ManagerNotesDialogComponent } from '../manager-notes/dialog/manager-notes-dialog.component';
import { IComplaint } from 'app/models/complaints/complaint';
import { IComplaintDialogData } from 'app/models/complaints/complaint-dialog-data';
import { ComplaintDialogComponent } from '../complaint/complaint.component';
import { IComplaintTrackingItem } from 'app/models/complaints/complaint-tracking-item';


export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-compliance-tracking',
  templateUrl: './compliance-tracking.component.html',
  styleUrls: ['./compliance-tracking.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class ComplianceTrackingComponent implements OnInit {
  public cases: IComplianceTrackingItem[] = [];
  public siteInspections: ISiteInspectionTrackingItem[] = [];
  public complaints: IComplaintTrackingItem[] = [];

  public managers: IStaffType[] = [];
  public staff: IStaffType[] = [];

  public managerReference: {[id: string]: string} = {};
  public staffReference: {[id: string]: string} = {};

  public loadingCases: boolean = true;
  public loadingSiteInspections: boolean = true;
  public loadingComplaints: boolean = true;
  public showClosedCases: boolean = false;
  public activeComplaintFilters: { [key: string]: string } = {};
  public activeComplianceFilters: { [key: string]: string } = {};
  public activeSiteInspectionFilters: { [key: string]: string } = {};
  public caseColumns: string[] = ['licenseName', 'entityId', 'caseId', 'status', 'noticeStatus', 'dateOfNonCompliance', 'category', 'tier', 'type', 'dueDate', 'outcome', 'dateOfCompliance', 'assignedTo', 'manager', 'agencyGenerated', 'locked', 'managerFlag' ];
  public caseSource = new MatTableDataSource<IComplianceTrackingItem>(this.cases);
  @ViewChild('casePaginator', {static: false}) casePaginator: MatPaginator;
  @ViewChild('caseSort', {static: false}) caseSort: MatSort;

  public siteInspectionColumns: string[] = ['licenseName', 'entityId', 'caseId', 'status', 'type','visitDate', 'followupAge', 'assignedTo', 'manager', 'locked', 'managerFlag'];
  public siteInspectionSource = new MatTableDataSource<ISiteInspectionTrackingItem>(this.siteInspections);
  @ViewChild('siteInspectionPaginator', {static: false}) siteInspectionPaginator: MatPaginator;
  @ViewChild('siteInspectionSort', {static: false}) siteInspectionSort: MatSort;

  public complaintColumns: string[] = ['complaintNumber', 'dateCreated', 'name', 'parentLicenseName', 'parentId', 'noticeStatus', 'status', 'complaintText', 'contactEmail', 'contactNumber', 'caseId', 'dueDate'];
  public complaintSource = new MatTableDataSource<IComplaintTrackingItem>(this.complaints);
  @ViewChild('complaintPaginator', {static: false}) complaintPaginator: MatPaginator;
  @ViewChild('complaintSort', {static: false}) complaintSort: MatSort;

  constructor(public complianceTrackingService: ComplianceTrackingService,
               public sharedService: SharedService,
               public authService: AuthService,
               public toastr: ToastrService,
               private dialog: MatDialog,
              ) { }

  ngOnInit(): void {
    this.getStaff();
    this.getSiteInspections();
    this.getManagers();
    this.getCases();
    this.getComplaints();
  }

  getStaff(): void {
    this.complianceTrackingService.getStaff().subscribe(
      response =>
      {
        this.staff = response;
        this.setupStaffReference();
      },
      error => console.log('error', error)
    );
  }

  getManagers(): void {
    this.complianceTrackingService.getAdmins().subscribe(
      response =>
      {
        this.managers = response;
        this.setupManagerReference();
      },
      error => console.log('error', error)
    );
  }

  setupStaffReference(): void {
    this.staff.forEach(staff => {
      this.staffReference[staff.id] = staff.name;
    });
  }

  setupManagerReference(): void {
    this.managers.forEach(manager => {
      this.managerReference[manager.id] = manager.name;
    });
  }

//Cases
toggleClosedCases(): void {
  this.showClosedCases = !this.showClosedCases;
  this.updateCaseTable();
}
  getCases(): void {
    this.complianceTrackingService.getCases().subscribe(
      response =>
      {
        this.cases = response;
        this.updateCaseTable();
        this.loadingCases = false;
      },
      error => console.log('error', error)
    );
  }

  updateCaseTable(): void {
    if (this.showClosedCases) {
      this.caseSource.data = this.cases;
    } else {
      this.caseSource.data = this.cases.filter(caseItem => caseItem.status !== 'Closed');
    }
    this.caseSource.sort = this.caseSort;
    this.caseSource.paginator = this.casePaginator;
  }

  applyCaseFilters(): void {
    this.caseSource.filterPredicate = (data: IComplianceTrackingItem) => {
      return Object.keys(this.activeComplianceFilters).every(key => {
        const filterValue = this.activeComplianceFilters[key].toLowerCase();
        switch (key) {
          case 'licenseName':
            return data.licenseName ? data.licenseName.toLowerCase().includes(filterValue) : false;
          case 'entityId':
            return data.entityId.toLowerCase().includes(filterValue);
          case 'status':
            return data.status.toLowerCase().includes(filterValue);
          default:
            return true;
        }
      });
    };
    this.caseSource.filter = JSON.stringify(this.activeComplianceFilters);
    }

    filterCasesStatus(event: MatSelectChange, filterKey: string): void {
      const filterValue = event.value;
      if (filterValue) {
        this.activeComplianceFilters[filterKey] = filterValue;
      } else {
        delete this.activeComplianceFilters[filterKey];
      }
      this.applyCaseFilters();
    }

    filterCases(event: Event, filterKey: string): void {
      const filterValue = (event.target as HTMLInputElement).value;
      if (filterValue) {
        this.activeComplianceFilters[filterKey] = filterValue;
      } else {
        delete this.activeComplianceFilters[filterKey];
      }
      this.applyCaseFilters();
    }

  assignCase(event: MatSelectChange, caseId: number): void {
    this.complianceTrackingService.assignCase(event.value, caseId).subscribe(
      response => {
        if(response) {
          if(event.value == null)
            this.toastr.success('Case unassigned');
          else
            this.toastr.success('Case assigned to ' + this.staffReference[event.value]);
        }
        else
          this.toastr.error('Could not assign Case');
      },
      error => console.log('error', error)
    );
  }

  assignCaseManager(event: MatSelectChange, caseId: number): void {
    this.complianceTrackingService.assignCaseManager(event.value, caseId).subscribe(
      response => {
        if(response) {
          if(event.value == null)
            this.toastr.success('Case manager unassigned');
          else
            this.toastr.success('Case manager assigned to ' + this.managerReference[event.value]);
        }
        else
          this.toastr.error('Could not assign Case');
      },
      error => console.log('error', error)
    );
  }

  //Site Inspections

  getSiteInspections(): void {
    this.complianceTrackingService.getSiteInspections().subscribe(
      response =>
      {
        this.siteInspections = response;
        this.updateSiteInspectionTable();
        this.loadingSiteInspections = false;
      },
      error => console.log('error', error)
    );
  }

  updateSiteInspectionTable(): void {
    this.siteInspectionSource.data = this.siteInspections;
    this.siteInspectionSource.sort = this.siteInspectionSort;
    this.siteInspectionSource.paginator = this.siteInspectionPaginator;
  }

  applySiteInspectionFilters(): void {
    this.siteInspectionSource.filterPredicate = (data: ISiteInspectionTrackingItem) => {
      return Object.keys(this.activeSiteInspectionFilters).every(key => {
        const filterValue = this.activeSiteInspectionFilters[key].toLowerCase();
        switch (key) {
          case 'licenseName':
            return data.licenseName ? data.licenseName.toLowerCase().includes(filterValue) : false;
          case 'entityId':
            return data.entityId.toLowerCase().includes(filterValue);
          case 'status':
            return data.status.toLowerCase().includes(filterValue);
          default:
            return true;
        }
      });
    };
    this.siteInspectionSource.filter = JSON.stringify(this.activeSiteInspectionFilters);
    }

    filterSiteInspectionsStatus(event: MatSelectChange, filterKey: string): void {
      const filterValue = event.value;
      if (filterValue) {
        this.activeSiteInspectionFilters[filterKey] = filterValue;
      } else {
        delete this.activeSiteInspectionFilters[filterKey];
      }
      this.applySiteInspectionFilters();
    }

    filterSiteInspections(event: Event, filterKey: string): void {
      const filterValue = (event.target as HTMLInputElement).value;
      if (filterValue) {
        this.activeSiteInspectionFilters[filterKey] = filterValue;
      } else {
        delete this.activeSiteInspectionFilters[filterKey];
      }
      this.applySiteInspectionFilters();
    }

  assignSiteInspection(event: MatSelectChange, caseId: number): void {
    this.complianceTrackingService.assignSiteInspection(event.value, caseId).subscribe(
      response => {
        if(response) {
          if(event.value == null)
            this.toastr.success('Site Inspection unassigned');
          else
            this.toastr.success('Site Inspection assigned to ' + this.staffReference[event.value]);
        }
        else
          this.toastr.error('Could not assign Site Inspection');
      },
      error => console.log('error', error)
    );
  }

  assignSiteInspectionManager(event: MatSelectChange, caseId: number): void {
    this.complianceTrackingService.assignSiteInspectionManager(event.value, caseId).subscribe(
      response => {
        if(response) {
          if(event.value == null)
            this.toastr.success('Site Inspection manager unassigned');
          else
            this.toastr.success('Site Inspection manager assigned to ' + this.managerReference[event.value]);
        }
        else
          this.toastr.error('Could not assign Site Inspection');
      },
      error => console.log('error', error)
    );
  }

  openManagerNotesInspection(license: ISiteInspectionTrackingItem): void {
    let entityType = "";
    if(license.entityType == "testing-lab")
      entityType = this.sharedService.testingLabEntityType;
    if(license.entityType == "service-center")
      entityType = this.sharedService.serviceCenterEntityType;
    if(license.entityType == "manufacturer")
      entityType = this.sharedService.manufacturerEntityType;

    const dialogRef = this.dialog.open(ManagerNotesDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        entityId: license.licenseId,
        entityType: entityType,
        managerFlag: license.managerFlag,
        reviewType: license.reviewType,
        managerNotes: license.managerNotes,
        isAdmin: this.authService.isAdmin
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        license.managerFlag = result.flag;
        license.reviewType = result.reviewType;
        license.managerNotes = result.notes;
      }
     });
  }

  openManagerNotesCases(license: IComplianceTrackingItem): void {
    let entityType = "";
    if(license.entityType == "testing-lab")
      entityType = this.sharedService.testingLabEntityType;
    if(license.entityType == "service-center")
      entityType = this.sharedService.serviceCenterEntityType;
    if(license.entityType == "manufacturer")
      entityType = this.sharedService.manufacturerEntityType;
      if(license.entityType == "facilitator")
      entityType = this.sharedService.facilitatorEntityType;
    if(license.entityType == "training")
      entityType = this.sharedService.trainingProgramEntityType;
    if(license.entityType == "worker-permit")
      entityType = this.sharedService.workerPermitEntityType;

    const dialogRef = this.dialog.open(ManagerNotesDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        entityId: license.licenseId,
        entityType: entityType,
        managerFlag: license.managerFlag,
        reviewType: license.reviewType,
        managerNotes: license.managerNotes,
        isAdmin: this.authService.isAdmin
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        license.managerFlag = result.flag;
        license.reviewType = result.reviewType;
        license.managerNotes = result.notes;
      }
     });
  }

  //Complaints
  editComplaint(complaint: IComplaint): void {
    let dialogData: IComplaintDialogData = {
      type: complaint.entityType,
      complaint: complaint,
      disableParentSelection: false,
      caseId: complaint.caseId
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        let index = this.complaints.findIndex(c => c.id === response.id);
        this.complaints[index] = response;
        this.complaintSource.data = this.complaints;
        this.complaintSource.sort = this.complaintSort;
        this.complaintSource.paginator = this.complaintPaginator;
        this.toastr.success("Complaint Updated!");
      }
    });
  }

  getComplaints(): void {
    this.complianceTrackingService.getComplaints().subscribe(
      response =>
      {
        this.complaints = response;
        setTimeout(() => {
          this.updateComplaintsTable();
        }, 500);

        this.loadingComplaints = false;
      },
      error => console.log('error', error)
    );
  }

  updateComplaintsTable(): void {
    this.complaintSource.data = this.complaints;
    this.complaintSource.sort = this.complaintSort;
    this.complaintSource.paginator = this.complaintPaginator;

    this.complaintSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'contactNumber': return item.contactNumber.replace(/\D/g, '');
        case 'name': return item.name.trim().toLowerCase();
        case 'dateCreated':
          const dateValue = item.dateCreated ? new Date(item.dateCreated).getTime() : null;
          return dateValue ? dateValue : Number.NEGATIVE_INFINITY;
        default: return item[property];
      }
    };

  }

  applyComplaintFilters(): void {
  this.complaintSource.filterPredicate = (data: IComplaintTrackingItem) => {
    return Object.keys(this.activeComplaintFilters).every(key => {
      const filterValue = this.activeComplaintFilters[key].toLowerCase();
      switch (key) {
        case 'parentLicenseName':
          return data.parentLicenseName ? data.parentLicenseName.toLowerCase().includes(filterValue) : false;
        case 'parentId':
          return data.parentId.toLowerCase().includes(filterValue);
        case 'status':
          return data.status.toLowerCase().includes(filterValue);
        default:
          return true;
      }
    });
  };
  this.complaintSource.filter = JSON.stringify(this.activeComplaintFilters);
  }

  filterComplaintsStatus(event: MatSelectChange, filterKey: string): void {
    const filterValue = event.value;
    if (filterValue) {
      this.activeComplaintFilters[filterKey] = filterValue;
    } else {
      delete this.activeComplaintFilters[filterKey];
    }
    this.applyComplaintFilters();
  }

  filterComplaints(event: Event, filterKey: string): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.activeComplaintFilters[filterKey] = filterValue;
    } else {
      delete this.activeComplaintFilters[filterKey];
    }
    this.applyComplaintFilters();
  }
}
