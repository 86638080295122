<div [hidden]="loading">
  <section>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <span class="f-s-20" fxFlex>Edit Document Request</span>
      <button mat-raised-button type="button" color="primary" (click)="saveDocumentRequest(false)">Save</button>
      <button mat-raised-button type="button" color="primary" (click)="saveDocumentRequest(true)">Save & Close</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Close</button>
    </div>
  </section>

  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
      <form [formGroup]="documentRequestForm" fxLayout="column">

        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Document Requested" formControlName="documentName" style="height: 40px;">
          </mat-form-field>

          <mat-form-field formControlName="auditInfo" fxFlex="50">
            <mat-label>Document Request Date</mat-label>
            <input matInput type="datetime-local" formControlName="auditInfo" style="height: 40px;"/>
            <mat-error *ngIf="this.documentRequestForm.get('auditInfo').value > today">
              Please choose an earlier date.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-form-field fxFlex="50">
            <mat-label>Document Requested To Be Received By Date</mat-label>
            <input placeholder="mm/dd/yyyy" formControlName="deadlineDate" matInput [matDatepicker]="deadlineDate"/>
            <mat-datepicker-toggle matSuffix [for]="deadlineDate"></mat-datepicker-toggle>
            <mat-datepicker #deadlineDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <mat-label>Document Received Date</mat-label>
            <input placeholder="mm/dd/yyyy" formControlName="receivedDate" matInput [matDatepicker]="receivedDate"/>
            <mat-datepicker-toggle matSuffix [for]="receivedDate"></mat-datepicker-toggle>
            <mat-datepicker #receivedDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Document Sent By" formControlName="sentBy">
          </mat-form-field>

          <div fxLayout="column" fxFlex="50">
            <mat-label>Is sender willing to be a Records witness for the record?</mat-label>
            <mat-radio-group formControlName="recordsWitness" fxLayout="column" fxLayoutGap="8px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div fxLayout="row">
          <mat-form-field formControlName="notes" fxFlex="100">
            <mat-label>Document Request Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutGap="12px">
          <div fxlayout="row" fxFlex="100">
            <span class="f-s-20">Files</span>
          </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <mat-table [dataSource]="documentRequestDocumentsSource" aria-describedby="Document Request Documents" class="mat-elevation-z8" fxFlex="100">

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
                    <mat-cell *matCellDef="let document">
                      <span class="mobile-label">Documents</span>
                      <span>{{ document.name }}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let document">
                      <div fxLayout="row">
                        <button type="button" mat-icon-button class="m-8" (click)="deleteDocumentRequestDocument(document.id, document.name)"
                          color="warn" aria-label="Delete">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <button type="primary" mat-icon-button class="m-8" (click)="downloadDocumentRequestDocument(document.id, document.name)"
                          color="primary" aria-label="Download">
                          <mat-icon>download</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="documentRequestDocumentsColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: documentRequestDocumentsColumns"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" #documentRequestDocumentsPaginator="matPaginator"></mat-paginator>
              </div>
            </div>

            <div fxlayout="row">
              <input type="file" class="file-input"  #fileUpload (change)="uploadDocumentRequestDocument($event)"/>
              <div fxLayout="row" fxLayoutGap="8px">
                <div class="file-upload">
                  <button mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                    Upload
                    <mat-icon>attach_file</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>

  </div>
  <div [hidden]="!loading">
    <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
  </div>
