  <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
    <span>Previous Cases</span>
    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
    </mat-form-field>
    <span fxFlex></span>
    <button mat-raised-button color="warn" (click)="cancel()">Close</button>
  </div>

  <mat-table [dataSource]="complianceSource" matSort #complianceSort="matSort" aria-describedby="Cases" class="mat-elevation-z8">
    <ng-container matColumnDef="caseId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        {{ compliance.caseId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="licenseId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        {{ compliance.licenseId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        {{ compliance.status }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        {{ compliance.category }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tier">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        {{ compliance.tier }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let compliance">
        <button mat-icon-button (click)="relateCase(compliance)" color="primary">
          <mat-icon>add_box</mat-icon>
        </button>
        <span>Relate</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="complianceColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: complianceColumns" ></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" #compliancePaginator="matPaginator">
  </mat-paginator>
