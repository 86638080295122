<div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
  <div fxLayout="row">
    <mat-card-title>
      Select Applicant To Autofill from
    </mat-card-title>
    <span fxFlex></span>
    <button mat-stroked-button color="warn" type="button" (click)="cancel()">Close</button>
  </div>

  <div fxLayout="row" fxFlex="100">
    <div fxLayout="column" fxFlex="100">
      <mat-table [dataSource]="dataSource" matSort aria-describedby="tasks" #sort="matSort" class="mat-elevation-z8 m-b-0" fxFlex="100">
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">First</span>
            {{ applicant.firstName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Last</span>
            {{ applicant.lastName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="middleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Middle</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Middle</span>
            {{ applicant.middleName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dob">
          <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">DOB</span>
            {{ applicant.dob | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ssn">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">SSN</span>
            {{ applicant.ssn }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="applicantId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant ID</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">ID</span>
            {{ applicant.applicantId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="similarity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Similarity</span>
            {{ applicant.similarity }} %
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
        <mat-row (click)="selectApplicant(row.id)" *matRowDef="let row; columns: dataColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25]" #paginator="matPaginator">
      </mat-paginator>
    </div>
  </div>
  </div>
