import { Component, OnInit, ViewChild } from "@angular/core";
import { ActiveDataPoint, ActiveElement, BasePlatform, BubbleDataPoint, Chart, ChartMeta, ChartTypeRegistry, Element, InteractionItem, InteractionOptions, Point, registerables, ScatterDataPoint } from 'chart.js';
import { LicenseDashboardService } from "app/services/licenses/license-dashboard/license-dashboard.service";
import { ILicenseStatuses } from "app/models/license-dashboard/licenseStatuses";
import { SharedService } from "app/services/core/shared.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { ILicenseIdandIdentifier } from "app/models/license-dashboard/licenseIdandIdentifier";
import { AnyObject } from "chart.js/types/basic";
import { ILicenseDashboardCase } from "app/models/license-dashboard/license-dashboard-case";

@Component({
  selector: 'license-dashboard',
  templateUrl: './license-dashboard.component.html',
  styleUrls: ['./license-dashboard.component.scss'],
})


export class LicenseDashboardComponent implements OnInit {
  title = 'angular-chart';
  assignedLicenses: ILicenseIdandIdentifier[] = [];
  assignedComplianceLicenses: ILicenseIdandIdentifier[] = [];
  assignedCases: ILicenseDashboardCase[] = [];
  statuses: ILicenseStatuses = {
    submitted: [],
    resubmitted: [],
    incomplete: [],
    insufficient: [],
    underReview: [],
    withdrawn: [],
    pending: [],
    pendingDenial: [],
    denied: [],
    submittedForApproval: [],
    approved: [],
    closed: [],
    expired: [],
    revoked: [],
    awaitingPayment : [],
    facilitator: 0,
    manufacturer: 0,
    serviceCenter: 0,
    testingLab: 0,
    workerPermit: 0,
    trainingProgram: 0
  };
  loading: boolean = false;
  wipData: number[];
  completedData: number[];
  licenseTypes: string[] = ['All', 'Facilitator', 'Service Center', 'Worker Permit', 'Manufacturer', 'Testing Lab', 'Training Program'];

  public assignedColumns: string[] = ['identifier', 'name','type'];
  public assignedSource = new MatTableDataSource<ILicenseIdandIdentifier>(this.assignedLicenses);
  @ViewChild('licensePaginator', {static: false}) licensePaginator: MatPaginator;
  @ViewChild('licenseSort', {static: false}) sort: MatSort;

  public assignedRenewalColumns: string[] = ['identifier', 'name','type'];
  public assignedRenewalSource = new MatTableDataSource<ILicenseIdandIdentifier>(this.assignedLicenses);
  @ViewChild('licenseRenewalPaginator', {static: false}) licenseRenewalPaginator: MatPaginator;
  @ViewChild('licenseRenewalSort', {static: false}) sortRenewal: MatSort;

  public assignedComplianceColumns: string[] = ['identifier', 'name','type'];
  public assignedComplianceSource = new MatTableDataSource<ILicenseIdandIdentifier>(this.assignedComplianceLicenses);
  @ViewChild('assignedCompliancePaginator', {static: false}) assignedCompliancePaginator: MatPaginator;
  @ViewChild('assignedComplianceSort', {static: false}) assignedComplianceSort: MatSort;

  public assignedCasesColumns: string[] = ['licenseId', 'name', 'type', 'caseNumber'];
  public assignedCasesSource = new MatTableDataSource<ILicenseDashboardCase>(this.assignedCases);
  @ViewChild('assignedCasesPaginator', {static: false}) assignedCasesPaginator: MatPaginator;
  @ViewChild('assignedCasesSort', {static: false}) assignedCasesSort: MatSort;

  pieChartWip: any = {
    platform: new BasePlatform,
    id: "",
    canvas: undefined,
    ctx: undefined,
    config: undefined,
    width: 0,
    height: 0,
    aspectRatio: 0,
    boxes: [],
    currentDevicePixelRatio: 0,
    chartArea: undefined,
    scales: {},
    attached: false,
    data: undefined,
    options: undefined,
    clear: function (): Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint)[], unknown> {
      throw new Error("Function not implemented.");
    },
    stop: function (): Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint)[], unknown> {
      throw new Error("Function not implemented.");
    },
    resize: function (width?: number, height?: number): void {
      throw new Error("Function not implemented.");
    },
    ensureScalesHaveIDs: function (): void {
      throw new Error("Function not implemented.");
    },
    buildOrUpdateScales: function (): void {
      throw new Error("Function not implemented.");
    },
    buildOrUpdateControllers: function (): void {
      throw new Error("Function not implemented.");
    },
    reset: function (): void {
      throw new Error("Function not implemented.");
    },
    update: function (mode?: "reset" | "resize" | "none" | "hide" | "show" | "normal" | "active"): void {
      throw new Error("Function not implemented.");
    },
    render: function (): void {
      throw new Error("Function not implemented.");
    },
    draw: function (): void {
      throw new Error("Function not implemented.");
    },
    isPointInArea: function (point: Point): boolean {
      throw new Error("Function not implemented.");
    },
    getElementsAtEventForMode: function (e: Event, mode: string, options: InteractionOptions, useFinalPosition: boolean): InteractionItem[] {
      throw new Error("Function not implemented.");
    },
    getSortedVisibleDatasetMetas: function (): ChartMeta<Element<AnyObject, AnyObject>, Element<AnyObject, AnyObject>, keyof ChartTypeRegistry>[] {
      throw new Error("Function not implemented.");
    },
    getDatasetMeta: function (datasetIndex: number): ChartMeta<Element<AnyObject, AnyObject>, Element<AnyObject, AnyObject>, keyof ChartTypeRegistry> {
      throw new Error("Function not implemented.");
    },
    getVisibleDatasetCount: function (): number {
      throw new Error("Function not implemented.");
    },
    isDatasetVisible: function (datasetIndex: number): boolean {
      throw new Error("Function not implemented.");
    },
    setDatasetVisibility: function (datasetIndex: number, visible: boolean): void {
      throw new Error("Function not implemented.");
    },
    toggleDataVisibility: function (index: number): void {
      throw new Error("Function not implemented.");
    },
    getDataVisibility: function (index: number): boolean {
      throw new Error("Function not implemented.");
    },
    hide: function (datasetIndex: number, dataIndex?: number): void {
      throw new Error("Function not implemented.");
    },
    show: function (datasetIndex: number, dataIndex?: number): void {
      throw new Error("Function not implemented.");
    },
    getActiveElements: function (): ActiveElement[] {
      throw new Error("Function not implemented.");
    },
    setActiveElements: function (active: ActiveDataPoint[]): void {
      throw new Error("Function not implemented.");
    },
    destroy: function (): void {
      throw new Error("Function not implemented.");
    },
    toBase64Image: function (type?: string, quality?: unknown): string {
      throw new Error("Function not implemented.");
    },
    bindEvents: function (): void {
      throw new Error("Function not implemented.");
    },
    unbindEvents: function (): void {
      throw new Error("Function not implemented.");
    },
    updateHoverStyle: function (items: InteractionItem[], mode: "dataset", enabled: boolean): void {
      throw new Error("Function not implemented.");
    },
    notifyPlugins: function (hook: string, args?: AnyObject): boolean | void {
      throw new Error("Function not implemented.");
    }
  }

  pieChartCompleted : any = {
    platform: new BasePlatform,
    id: "",
    canvas: undefined,
    ctx: undefined,
    config: undefined,
    width: 0,
    height: 0,
    aspectRatio: 0,
    boxes: [],
    currentDevicePixelRatio: 0,
    chartArea: undefined,
    scales: {},
    attached: false,
    data: undefined,
    options: undefined,
    clear: function (): Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint)[], unknown> {
      throw new Error("Function not implemented.");
    },
    stop: function (): Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint)[], unknown> {
      throw new Error("Function not implemented.");
    },
    resize: function (width?: number, height?: number): void {
      throw new Error("Function not implemented.");
    },
    ensureScalesHaveIDs: function (): void {
      throw new Error("Function not implemented.");
    },
    buildOrUpdateScales: function (): void {
      throw new Error("Function not implemented.");
    },
    buildOrUpdateControllers: function (): void {
      throw new Error("Function not implemented.");
    },
    reset: function (): void {
      throw new Error("Function not implemented.");
    },
    update: function (mode?: "reset" | "resize" | "none" | "hide" | "show" | "normal" | "active"): void {
      throw new Error("Function not implemented.");
    },
    render: function (): void {
      throw new Error("Function not implemented.");
    },
    draw: function (): void {
      throw new Error("Function not implemented.");
    },
    isPointInArea: function (point: Point): boolean {
      throw new Error("Function not implemented.");
    },
    getElementsAtEventForMode: function (e: Event, mode: string, options: InteractionOptions, useFinalPosition: boolean): InteractionItem[] {
      throw new Error("Function not implemented.");
    },
    getSortedVisibleDatasetMetas: function (): ChartMeta<Element<AnyObject, AnyObject>, Element<AnyObject, AnyObject>, keyof ChartTypeRegistry>[] {
      throw new Error("Function not implemented.");
    },
    getDatasetMeta: function (datasetIndex: number): ChartMeta<Element<AnyObject, AnyObject>, Element<AnyObject, AnyObject>, keyof ChartTypeRegistry> {
      throw new Error("Function not implemented.");
    },
    getVisibleDatasetCount: function (): number {
      throw new Error("Function not implemented.");
    },
    isDatasetVisible: function (datasetIndex: number): boolean {
      throw new Error("Function not implemented.");
    },
    setDatasetVisibility: function (datasetIndex: number, visible: boolean): void {
      throw new Error("Function not implemented.");
    },
    toggleDataVisibility: function (index: number): void {
      throw new Error("Function not implemented.");
    },
    getDataVisibility: function (index: number): boolean {
      throw new Error("Function not implemented.");
    },
    hide: function (datasetIndex: number, dataIndex?: number): void {
      throw new Error("Function not implemented.");
    },
    show: function (datasetIndex: number, dataIndex?: number): void {
      throw new Error("Function not implemented.");
    },
    getActiveElements: function (): ActiveElement[] {
      throw new Error("Function not implemented.");
    },
    setActiveElements: function (active: ActiveDataPoint[]): void {
      throw new Error("Function not implemented.");
    },
    destroy: function (): void {
      throw new Error("Function not implemented.");
    },
    toBase64Image: function (type?: string, quality?: unknown): string {
      throw new Error("Function not implemented.");
    },
    bindEvents: function (): void {
      throw new Error("Function not implemented.");
    },
    unbindEvents: function (): void {
      throw new Error("Function not implemented.");
    },
    updateHoverStyle: function (items: InteractionItem[], mode: "dataset", enabled: boolean): void {
      throw new Error("Function not implemented.");
    },
    notifyPlugins: function (hook: string, args?: AnyObject): boolean | void {
      throw new Error("Function not implemented.");
    }
  }
  constructor(
    private licenseDashboardService: LicenseDashboardService,
    public sharedService : SharedService) {
      Chart.register(...registerables);
      Chart.defaults.font.size = 16;
     }

  ngOnInit(): void {
    this.loading = true;

    this.licenseDashboardService.GetAssignedLicenses().subscribe(
      response => this.assignedLicenses = response,
      error => console.log('error', error),
      () => {
        this.assignedSource.data = this.assignedLicenses.filter(l => !l.isRenewal);
        this.assignedSource.paginator = this.licensePaginator;
        this.assignedSource.sort = this.sort;

        this.assignedRenewalSource.data = this.assignedLicenses.filter(l => l.isRenewal);
        this.assignedRenewalSource.paginator = this.licenseRenewalPaginator;
        this.assignedRenewalSource.sort = this.sortRenewal;

        this.licenseDashboardService.GetAssignedComplianceLicenses().subscribe(
          response => this.assignedComplianceLicenses = response,
          error => console.log('error', error),
          () => {
            this.assignedComplianceSource.data = this.assignedComplianceLicenses;
            this.assignedComplianceSource.paginator = this.assignedCompliancePaginator;
            this.assignedComplianceSource.sort = this.assignedComplianceSort
          });

        this.licenseDashboardService.GetAssignedCases().subscribe(
          response => this.assignedCases = response,
          error => console.log('error', error),
          () => {
            this.assignedCasesSource.data = this.assignedCases;
            this.assignedCasesSource.paginator = this.assignedCasesPaginator;
            this.assignedCasesSource.sort = this.assignedCasesSort
          });

        this.licenseDashboardService.GetAllStatuses().subscribe(
          response =>
            {
              this.statuses = response;
              this.wipData = [this.statuses.submitted.length,  this.statuses.resubmitted.length, this.statuses.underReview.length,
              this.statuses.pending.length, this.statuses.pendingDenial.length, this.statuses.submittedForApproval.length, this.statuses.awaitingPayment.length];

              this.completedData = [this.statuses.incomplete.length,  this.statuses.denied.length, this.statuses.approved.length, this.statuses.closed.length,
              this.statuses.expired.length, this.statuses.revoked.length, this.statuses.insufficient.length];

              const pieChartWipEle: any = document.getElementById('pie_chart_wip')
              this.pieChartWip = new Chart(pieChartWipEle.getContext('2d'), {
             type: 'pie',
               data: {
                 labels: ['Submitted', 'Resubmitted', 'Under Review', 'Pending', 'Pending Denial', 'Submitted For Approval', 'Approved, Pending Payment and Clearance'],
                 datasets: [{
                   data: this.wipData,
                   backgroundColor: [
                     'rgba(255, 99, 132, 0.9)',
                     'rgba(255, 159, 64, 0.9)',
                     'rgba(255, 205, 86, 0.9)',
                     'rgba(75, 192, 192, 0.9)',
                     'rgba(54, 162, 235, 0.9)',
                     'rgba(153, 102, 255, 0.9)',
                     'rgba(201, 203, 207, 0.9)'
                   ],
                   borderColor: [
                     'rgb(255, 99, 132)',
                     'rgb(255, 159, 64)',
                     'rgb(255, 205, 86)',
                     'rgb(75, 192, 192)',
                     'rgb(54, 162, 235)',
                     'rgb(153, 102, 255)',
                     'rgb(201, 203, 207)'
                   ],
                   borderWidth: 1
                 }]
               },
               options: {
                 responsive: true,
                 plugins: {
                   legend: {
                     position: 'top',
                   }
                 }
               }
                });

              const pieChartCompletedEle: any = document.getElementById('pie_chart_completed')
              this.pieChartCompleted = new Chart(pieChartCompletedEle.getContext('2d'), {
               type: 'pie',
                 data: {
                   labels: ['Incomplete', 'Denied', 'Approved', 'Closed', 'Expired', 'Revoked', 'Insufficient'],
                   datasets: [{
                     label: 'Sales',
                     data: this.completedData,
                     backgroundColor: [
                       'rgba(255, 99, 132, 0.9)',
                       'rgba(255, 159, 64, 0.9)',
                       'rgba(255, 205, 86, 0.9)',
                       'rgba(75, 192, 192, 0.9)',
                       'rgba(54, 162, 235, 0.9)',
                       'rgba(153, 102, 255, 0.9)',
                       'rgba(201, 203, 207, 0.9)'
                     ],
                     borderColor: [
                       'rgb(255, 99, 132)',
                       'rgb(255, 159, 64)',
                       'rgb(255, 205, 86)',
                       'rgb(75, 192, 192)',
                       'rgb(54, 162, 235)',
                       'rgb(153, 102, 255)',
                       'rgb(201, 203, 207)'
                     ],
                     borderWidth: 1
                   }]
                 },
                 options: {
                   responsive: true,
                   plugins: {
                     legend: {
                       position: 'top',
                     }
                   }
                 }
                });

              const pieChartTotalEle: any = document.getElementById('pie_chart_total')
              const pieChartTotal = new Chart(pieChartTotalEle.getContext('2d'), {
                 type: 'pie',
                   data: {
                     labels: ['Facilitator', 'Manufacturer', 'Service Center', 'Testing Lab', 'Worker Permit', 'Training Program'],
                     datasets: [{
                       label: 'Sales',
                       data: [this.statuses.facilitator,  this.statuses.manufacturer, this.statuses.serviceCenter, this.statuses.testingLab, this.statuses.workerPermit, this.statuses.trainingProgram],
                       backgroundColor: [
                         'rgba(147, 85, 220, 0.9)',
                         'rgba(255, 190, 46, 0.9)',
                         'rgba(112, 255, 123, 0.9)',
                         'rgba(236, 137, 29, 0.9)',
                         'rgba(252, 65, 50, 0.9)',
                         'rgba(98, 142, 244, 0.9)'

                       ],
                       borderColor: [
                         'rgb(147, 85, 220)',
                         'rgb(255, 190, 46)',
                         'rgb(112, 225, 123)',
                         'rgb(236, 137, 29)',
                         'rgb(252, 65, 50)',
                         'rgb(98, 142, 244)',

                       ],
                       borderWidth: 1
                     }]
                   },
                   options: {
                     responsive: true,
                     plugins: {
                       legend: {
                         position: 'top',
                       }
                     }
                   }
                });
            },
              error => console.log('error', error));
              this.loading = false;
            });
          }

  licenseChange(license): void{
    if(license != 'All')
      {
        this.wipData = [this.statuses.submitted.filter(t => t.type === license).length,  this.statuses.resubmitted.filter(t => t.type === license).length,
          this.statuses.underReview.filter(t => t.type === license).length, this.statuses.pending.filter(t => t.type === license).length,
          this.statuses.pendingDenial.filter(t => t.type === license).length, this.statuses.submittedForApproval.filter(t => t.type === license).length,
          this.statuses.awaitingPayment.filter(t => t.type === license).length];

          this.completedData = [this.statuses.incomplete.filter(t => t.type === license).length,this.statuses.denied.filter(t => t.type === license).length,
            this.statuses.approved.filter(t => t.type === license).length, this.statuses.closed.filter(t => t.type === license).length,
            this.statuses.expired.filter(t => t.type === license).length, this.statuses.revoked.filter(t => t.type === license).length, this.statuses.insufficient.filter(t => t.type === license).length];
      }
    else
      {
        this.wipData = [this.statuses.submitted.length,  this.statuses.resubmitted.length, this.statuses.underReview.length,
          this.statuses.pending.length, this.statuses.pendingDenial.length, this.statuses.submittedForApproval.length, this.statuses.awaitingPayment.length];
        this.completedData = [this.statuses.incomplete.length,  this.statuses.denied.length, this.statuses.approved.length, this.statuses.closed.length,
            this.statuses.expired.length, this.statuses.revoked.length, this.statuses.insufficient.length];
      }
    this.pieChartCompleted.data.datasets[0].data = this.completedData;
    this.pieChartWip.data.datasets[0].data = this.wipData;
    this.pieChartWip.update();
    this.pieChartCompleted.update();
    }

}
