import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IInvestigation } from 'app/models/investigations/investigation';
import { IInterview } from 'app/models/investigations/interview';
import { IDocument } from 'app/models/documents/document';
import { IDocumentRequest } from 'app/models/investigations/documentRequest';
import { ISiteInspection } from 'app/models/site-inspection/site-inspection';
import { IInterviewDocument } from 'app/models/documents/interviewDocument';
import { IInvestigationPhoto } from 'app/models/investigations/investigation-photo';
import { IInvestigationWitness } from 'app/models/investigations/investigation-witness';

@Injectable({providedIn: 'root'})

export class InvestigationService {
  private api: string = environment.privateApi + 'Investigation/';
  private readonly localStorageKey = 'myCachedRequests';

  constructor(private http: HttpClient) {window.addEventListener('online', () => this.sendCachedRequests());
 }

  createInvestigation(compliance: any): Observable<IInvestigation>{
    let extension: string = 'CreateInvestigation';
    if (navigator.onLine) {
      return this.http.post<IInvestigation>(this.api + "CreateInvestigation", compliance);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': compliance, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(compliance);
        observer.complete();
      });
    }
  }

  deleteInvestigation(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInvestigation/" + id);
  }

  getInvestigations(caseId: number): Observable<IInvestigation[]>{
    return this.http.get<IInvestigation[]>(this.api + "GetInvestigations/" + caseId);
  }

  updateInvestigation(investigation: IInvestigation): Observable<IInvestigation> {
    let extension: string = 'UpdateInvestigation';
    if (navigator.onLine) {
      return this.http.post<IInvestigation>(this.api + 'UpdateInvestigation', investigation);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': investigation, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(investigation);
        observer.complete();
      });
    }
  }
//Site Inspections
createSiteInspection(siteInspection: ISiteInspection, investigationId ,caseId: number): Observable<ISiteInspection>{
  let extension: string = 'CreateSiteInspection/' + investigationId + '/'+ caseId;
  if (navigator.onLine) {
    return this.http.post<ISiteInspection>(this.api + "CreateSiteInspection/" + investigationId + '/'+ caseId, siteInspection);
  } else {
    const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
    cachedRequests[extension] = {'data': siteInspection, 'type': 'investigation'};
    localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
    return new Observable(observer => {
      observer.next(siteInspection);
      observer.complete();
    });
  }
}

updateSiteInspection(siteInspection: ISiteInspection, investigationId: number): Observable<ISiteInspection> {
  let extension: string = 'UpdateSiteInspection/' + investigationId + '/' + investigationId;
  if (navigator.onLine) {
    return this.http.post<ISiteInspection>(this.api + 'UpdateSiteInspection/' + investigationId, siteInspection);
  } else {
    const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
    cachedRequests[extension] = {'data': siteInspection, 'type': 'investigation'};
    localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
    return new Observable(observer => {
      observer.next(siteInspection);
      observer.complete();
    });
  }
}

deleteSiteInspection(id:number): Observable<any>{
  return this.http.delete<any>(this.api + "DeleteSiteInspection/" + id);
}
//Interviews
  createInterview(interview: IInterview): Observable<IInterview>{
    let extension: string = 'CreateInterview';
    if (navigator.onLine) {
      return this.http.post<IInterview>(this.api + "CreateInterview", interview);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': interview, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(interview);
        observer.complete();
      });
    }
  }

  updateInterview(interview: IInterview): Observable<IInterview> {
    let extension: string = 'UpdateInterview';
    if (navigator.onLine) {
      return this.http.post<IInterview>(this.api + 'UpdateInterview', interview);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': interview, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(interview);
        observer.complete();
      });
    }
  }

  deleteInterview(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInterview/" + id);
  }

  uploadInterviewDocument(file: any): Observable<IInterviewDocument>{
    let extension: string = 'UploadInterviewDocument';
    if (navigator.onLine) {
      return this.http.post<IInterviewDocument>(this.api + 'UploadInterviewDocument', file);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': file, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(file);
        observer.complete();
      });
    }
  }

  uploadInterviewRecording(document: IInterviewDocument): Observable<IInterviewDocument>{
    return this.http.post<IInterviewDocument>(this.api + "UploadInterviewRecording", document);
  }

  getSASToken(): Observable<string>{
    return this.http.get(this.api + "GetSASToken", { responseType: 'text' });
  }

  deleteInterviewDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInterviewDocument/" + id);
  }
  downloadInterviewDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadInterviewDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  //DocumentRequests
  createDocumentRequest(documentRequest: any): Observable<IDocumentRequest>{
    let extension: string = 'CreateDocumentRequest';
    if (navigator.onLine) {
      return this.http.post<IDocumentRequest>(this.api + "CreateDocumentRequest", documentRequest);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': documentRequest, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(documentRequest);
        observer.complete();
      });
    }
  }

  updateDocumentRequest(documentRequest: IDocumentRequest): Observable<IDocumentRequest> {
    let extension: string = 'UpdateDocumentRequest';
    if (navigator.onLine) {
      return this.http.post<IDocumentRequest>(this.api + 'UpdateDocumentRequest', documentRequest);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': documentRequest, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(documentRequest);
        observer.complete();
      });
    }
  }

  deleteDocumentRequest(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocumentRequest/" + id);
  }

  uploadDocumentRequestDocument(file: any): Observable<IDocument>{
    let extension: string = 'UploadDocumentRequestDocument';
    if (navigator.onLine) {
      return this.http.post<IDocument>(this.api + 'UploadDocumentRequestDocument', file);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': file, 'type': 'investigation'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(file);
        observer.complete();
      });
    }
  }

  deleteDocumentRequestDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocumentRequestDocument/" + id);
  }
  downloadDocumentRequestDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocumentRequestDocument/" + fileId, { responseType: 'blob' as 'json' });
  }
  //Investigation Photos
  createInvestigatonPhoto(photo: any): Observable<IInvestigationPhoto>{
      return this.http.post<IInvestigationPhoto>(this.api + "CreateInvestigationPhoto", photo);
  }

  deleteInvestigationPhoto(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInvestigationPhoto/" + id);
  }

  updateInvestigationPhoto(investigationPhoto: IInvestigationPhoto): Observable<IInvestigationPhoto> {
    return this.http.post<IInvestigationPhoto>(this.api + 'UpdateInvestigationPhoto', investigationPhoto);
  }

  deleteInvestigationPhotoDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInvestigationPhotoDocument/" + id);
  }

  downloadInvestigationPhotoDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadInvestigationPhotoDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  uploadInvestigationPhotoDocument(file: any): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadInvestigationPhotoDocument', file);
  }

  //Investigation Witnesses
  createInvestigatonWitness(photo: any): Observable<IInvestigationWitness>{
      return this.http.post<IInvestigationWitness>(this.api + "CreateInvestigationWitness", photo);
  }

  deleteInvestigationWitness(id:number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteInvestigationWitness/" + id);
  }

  updateInvestigationWitness(investigationWitness: IInvestigationWitness): Observable<IInvestigationWitness> {
    return this.http.post<IInvestigationWitness>(this.api + 'UpdateInvestigationWitness', investigationWitness);
  }

  sendCachedRequests(): void {
    if (navigator.onLine) {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
        Object.keys(cachedRequests).forEach(extension => {
          const request = cachedRequests[extension];
          if(request['type'] == 'investigation')
          {
                this.http.post(this.api + extension, request['data']).subscribe(() => {
                  delete cachedRequests[extension];
                  localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
                });
          }
        });
        Object.keys(cachedRequests).forEach(extension => {
          delete cachedRequests[extension];
        });
      }
    }
}
