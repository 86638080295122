<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">
    <span class="f-s-20" fxFlex>Generating PDF</span>
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</mat-dialog-content>

<div class="print-outer">
  <div class="print-inner">
    <kendo-pdf-export #pdf
    paperSize="letter"
    [scale]="0.70"
    margin="1cm"
    keepTogether="mat-row">
      <ng-template kendoPDFTemplate
                    let-pageNum="pageNum"
                    let-totalPages="totalPages">
        <div class="pageNum">Page {{ pageNum }} of {{ totalPages }}</div>
      </ng-template>
      <div fxLayout="column" id="manifestPdf" #manifestPdf>
        <div fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="column" fxFlex>
            <span><strong>PUBLIC HEALTH DIVISION</strong></span>
            <span><strong>CENTER FOR HEALTH PROTECTION</strong></span>
            <span><strong>Oregon Psilocybin Services</strong></span>
            <span><a href="http://oregon.gov/psilocybin">http://oregon.gov/psilocybin</a></span>
          </div>
          <img class="p-4" src="../../../../../assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
        </div>
        <br>
        <div>
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutGap="12px" fxFlex="100">
              <div fxLayout="column" fxFlex fxLayoutGap="12px">
                <h3 fxLayoutAlign="center center"><strong>Site Inspection Follow Up</strong></h3>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div fxLayout="column" fxLayoutGap="5px">
                    <span>This document is used by the site inspector to record the rule requirements that were not met during the site inspection.
                      Each individual rule is separated into sections with a summary of the rule, the reason for not meeting the requirement, and the plan and guidance to meet the requirement.
                      Please work with your site inspector through TLC's messaging function to communicate and meet the requirements of each of these rules.</span>
                    <br>
                    <span *ngIf="data.siteInspectionType == 'Renewal' || data.siteInspectionType == 'ChangeRequest'">From the date notified of required site inspection follow up, you have 30 days to satisfy requirements that were not met at the time of inspection. Work with your site inspector if you have questions or concerns.</span>
                    <span *ngIf="data.siteInspectionType == 'Application'">From the date notified of required site inspection follow up, you have 90 days to satisfy requirements that were not met at the time of inspection. Work with your site inspector if you have questions or concerns.</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxLayoutGap="5px">
                    <div fxLayout="row">
                      <span fxFlex="50"><strong>Premises Name:</strong></span>
                      <span>{{data.entityName}}</span>
                    </div>
                    <div fxLayout="row">
                      <span fxFlex="50"><strong>Site Inspector:</strong></span>
                      <span>{{formatName()}}</span>
                    </div>
                    <div fxLayout="row">
                      <span fxFlex="50"><strong>Date of Site Inspection:</strong></span>
                      <span>{{data.followup.dateOfInspection | date}}</span>
                    </div>
                    <div fxLayout="row">
                      <span fxFlex="50"><strong>Date Requirements Met:</strong></span>
                      <span>{{data.followup.dateRequirementsMet | date}}</span>
                    </div>
                    <div fxLayout="row">
                      <span fxFlex="50"><strong>Type of Site Inspection:</strong></span>
                      <span>{{data.siteInspectionType}}</span>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div fxLayout="column" class="bordered-card" style="padding: 15px">
                    <span style="white-space: nowrap;" *ngIf="data.siteInspectionType == 'Renewal' || data.siteInspectionType == 'ChangeRequest'"><strong>Message To Licensee:</strong></span>
                    <span style="white-space: nowrap;" *ngIf="data.siteInspectionType == 'Application'"><strong>Message To Applicant:</strong></span>
                    <span>{{data.followup.message}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="24px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
              <span class="f-s-18">Insufficient Rules and Statutes</span>
            </div>

            <div *ngFor="let rule of data.followup.followupRules">
            <mat-card>
              <mat-card-content>
                <mat-card fxLayout="column" fxLayoutGap="16px" class="bordered-card">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="40px">
                    <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center">
                      <div fxLayout="row">
                        <span style="font-size: large"><strong>Rule out of compliance: {{rule.rule.statute}}</strong></span>
                      </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start">
                      <div fxLayout="row" fxLayoutGap="12px">
                        <span><strong>Resolved Date:</strong></span>
                        <span>{{rule.resolvedDate | date}}</span>
                      </div>
                    </div>
                  </div>

                  <mat-card-content fxLayout="column" fxLayoutGap="16px">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                      <mat-card fxFlex="100" class="bordered-card">
                        <div fxLayout="column" fxFlex="100">
                          <div style="overflow-x: hidden; overflow-y: auto; text-align: left;" fxLayout="column" fxFlex="100">
                            <span><strong>Rules Summary:</strong></span>
                            <span >{{rule.rule.summary}}</span>
                          </div>
                        </div>
                      </mat-card>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                      <mat-card fxFlex="100" class="bordered-card">
                        <div fxLayout="column" fxFlex="100">
                          <div style="overflow-x: hidden; overflow-y: auto; text-align: left;" fxLayout="column" fxFlex="100">
                            <span><strong>Reason for not meeting Requirement:</strong></span>
                            <span >{{rule.notMeetingRequirementReason}}</span>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                      <mat-card fxFlex="100" class="bordered-card">
                        <div fxLayout="column" fxFlex="100">
                          <div style="overflow-x: hidden; overflow-y: auto; text-align: left;" fxLayout="column" fxFlex="100">
                            <span><strong>Plan and Guidance:</strong></span>
                            <span >{{rule.notes}}</span>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mat-card-content>
            </mat-card>
          </div>
          </div>
        </div>
      </div>
    </kendo-pdf-export>
  </div>
</div>
