<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.blue">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-icon class="medium-icon m-24">description</mat-icon>
      <span class="f-s-20">{{editingExamQuestions? "Edit" : "View"}} Exam Questions</span>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
    <span class="f-s-20">Exam Questions</span>
    <button *ngIf="editingExamQuestions" type="button" mat-icon-button color="warn"
      matTooltip="Stop Editing Exam Questions" matTooltipShowDelay=800
      aria-label="Stop Editing Exam Questions" (click)="toggleQuizEdit()">
      <mat-icon>edit_off</mat-icon>
    </button>
    <button *ngIf="!editingExamQuestions" type="button" mat-icon-button color="primary"
      matTooltip="Edit Exam Questions" matTooltipShowDelay=800 aria-label="Edit Exam Questions"
      (click)="toggleQuizEdit()">
      <mat-icon>edit</mat-icon>
    </button>
    <button  *ngIf="editingExamQuestions" type="button"
      mat-button color="primary"
      matTooltip="toggleArchived" matTooltipShowDelay=800
      aria-label="Toggle Archived" (click)="toggleArchived()">
      {{showArchived? "Hide Archived" : "Show Archived"}}
    </button>
    <button *ngIf="editingExamQuestions" type="button" mat-stroked-button color="primary"
      matTooltip="Update Quiz Map" matTooltipShowDelay=800
      aria-label="Update Quiz Map" (click)="saveQuiz()">
      Save Quiz
    </button>
  </div>
  <span *ngIf="!editingExamQuestions" class="f-s-14">Drag and Drop to Change question Ordering</span>

  <!--
    Viewing
  -->
  <div style="cursor: pointer" *ngIf="!editingExamQuestions" fxLayout="column" cdkDropList (cdkDropListDropped)="dropQuestion($event)">
    <div fxLayout="column" fxLayoutGap="12px" *ngFor="let element of examMap; let elementIndex = index" cdkDrag>
      <!--Core Elements-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="" *ngIf="!element.archived">
        <span><strong>({{getIndexNumber(elementIndex)}})</strong></span>
        <span>{{element.text}}</span>
      </div>
      <!--Sub Sections-->
      <div fxLayout="row"  *ngIf="!element.archived">
        <span fxFlex="5"></span>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex="70" cdkDropList (cdkDropListDropped)="dropAnswer($event, element.examAnswers)">
          <div fxLayout="column" fxLayoutGap="8px" *ngFor="let answer of element.examAnswers; let answerIndex = index" cdkDrag>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign.lt-md="" *ngIf="!answer.archived">
              <span><strong>({{getAnswerIndexLetter(elementIndex, answerIndex, answer.archived)}}) </strong></span>
              <span [ngStyle]="answer.correct === true ? {'color':'green','font-weight':'bolder'} : {}" >{{answer.text}}</span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
    Editing
  -->
  <div *ngIf="editingExamQuestions" fxLayout="column">
    <div fxLayout="column" fxLayoutGap="8px" *ngFor="let element of examMap; let elementIndex = index">
      <!--Core Elements-->
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"
        *ngIf="!element.archived || showArchived"
        [ngClass]="{'archive-element': element.archived}"
        fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
        <span><strong>({{getIndexNumber(elementIndex)}})</strong></span>
        <mat-form-field fxFlex="70">
          <textarea required rows="1" matInput type="text" [(ngModel)]="element.text" name="elementText" placeholder="Text"></textarea>
        </mat-form-field>
        <div fxLayout="row" fxFlex="20" fxLayoutGap="8px">
          <button *ngIf="editingExamQuestions" type="button" mat-icon-button color="warn" matTooltip="Delete Question"
            matTooltipShowDelay=800 aria-label="Delete Question" (click)="deleteQuiz(element)">
            <mat-icon>delete</mat-icon>
          </button>
          <button *ngIf="editingExamQuestions && !element.archived" type="button" mat-icon-button color="warn" matTooltip="Archive Question"
            matTooltipShowDelay=800 aria-label="Archive Question" (click)="toggleArchiveQuestion(element)">
            <mat-icon>archive</mat-icon>
          </button>
          <button *ngIf="editingExamQuestions && element.archived" type="button" mat-icon-button color="warn" matTooltip="Unarchive Question"
            matTooltipShowDelay=800 aria-label="Unrchive Question" (click)="toggleArchiveQuestion(element)">
            <mat-icon>unarchive</mat-icon>
          </button>
          <button *ngIf="!element.addingAnswer && !element.archived" type="button" mat-icon-button color="primary"
            matTooltip="Add Answer" matTooltipShowDelay=800 aria-label="Add Answer"
            (click)="toggleAnswerAddForm(element, true)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <!--Answers-->
      <div fxLayout="row" fxFlex="100"
          [ngClass]="{'archive-element': element.archived}"
           *ngIf="!element.archived  || showArchived">
        <span fxFlex="5"></span>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex="70">

          <div *ngIf="element.addingAnswer" fxLayout="row" fxLayout.lt-md="column"
               fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
            <span><strong>({{getAnswerIndexLetter(elementIndex, element.examAnswers.length, element.archived)}})</strong></span>
            <mat-form-field fxFlex="100">
              <textarea rows="1" matInput type="text" [(ngModel)]="subText" name="subText" placeholder="Text"></textarea>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="8px">
              <button *ngIf="subText != ''" type="button" mat-stroked-button color="primary" matTooltip="Submit Answer" matTooltipShowDelay=800
                aria-label="Submit Answer" (click)="addAnswer(element)">
                save
              </button>
              <button type="button" mat-stroked-button color="warn" matTooltip="Cancel Answer Submission"
                matTooltipShowDelay=800 aria-label="Cancel Answer Submission"
                (click)="toggleAnswerAddForm(element, false)">
                cancel
              </button>
            </div>
          </div>
          <mat-radio-group>

          <div fxLayout="column" *ngFor="let answer of element.examAnswers; let answerIndex = index" fxLayoutGap="8px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"
              [ngClass]="{'archive-element': element.archived || answer.archived}"
              *ngIf="!answer.archived || showArchived"
              fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
              <span><strong>({{getAnswerIndexLetter(elementIndex, answerIndex, answer.archived || element.archived)}}) </strong></span>
              <mat-form-field fxFlex="100">
                <textarea rows="1" matInput type="text" [(ngModel)]="answer.text" name="answerText" placeholder="Text"></textarea>
              </mat-form-field>
              <mat-radio-button [(ngModel)]="answer.correct" [checked]="answer.correct" (click)="setCorrect(element, answer)" [value]="answer.correct" [name]="answer.text" >Correct Answer</mat-radio-button>
              <div fxLayout="row" fxLayoutGap="8px">
                <button *ngIf="editingExamQuestions" type="button" mat-icon-button color="warn" matTooltip="Delete Answer"
                  matTooltipShowDelay=800 aria-label="Delete Answer" (click)="deleteAnswer(element, answer)">
                  <mat-icon>delete</mat-icon>
                </button>

                <button *ngIf="editingExamQuestions && !answer.archived && !element.archived" type="button" mat-icon-button color="warn" matTooltip="Archive Section"
                  matTooltipShowDelay=800 aria-label="Archive Question" (click)="toggleArchiveAnswer(answer)">
                  <mat-icon>archive</mat-icon>
                </button>
                <button *ngIf="editingExamQuestions && answer.archived && !element.archived" type="button" mat-icon-button color="warn" matTooltip="Unarchive Section"
                  matTooltipShowDelay=800 aria-label="Unarchive Section" (click)="toggleArchiveAnswer(answer)">
                  <mat-icon>unarchive</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-radio-group>
        </div>
      </div>
    </div>
    <button *ngIf="!addingCoreElement" type="button" mat-icon-button color="primary" matTooltip="Add Question"
      matTooltipShowDelay=800 aria-label="Add Question" (click)="toggleQuestionAddForm()">
      <mat-icon>add</mat-icon> Add New Question
    </button>

    <div *ngIf="addingCoreElement" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="">
      <span><strong>({{examMap.length + 1}})</strong></span>
      <mat-form-field fxFlex="70">
        <textarea rows="1" matInput type="text" [(ngModel)]="coreText" name="coreText" placeholder="Text"></textarea>
      </mat-form-field>
      <div fxLayout="row" fxFlex="20" fxLayoutGap="8px">
        <div>
          <button *ngIf="coreText != ''" type="button" mat-stroked-button color="primary" matTooltip="Submit Question" matTooltipShowDelay=800
            aria-label="Submit Question" (click)="addCoreElement()">
            save
          </button>
        </div>
        <div>
          <button type="button" mat-stroked-button color="warn" matTooltip="Camcel" matTooltipShowDelay=800
            aria-label="Cancel" (click)="toggleQuestionAddForm()">
            cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column">
    <div>
    <button *ngIf="editingExamQuestions" type="button" mat-stroked-button color="primary" matTooltip="Update Quiz Map"
      matTooltipShowDelay=800 aria-label="Update Quiz Map" (click)="saveQuiz()">
      Save Quiz
    </button>
  </div>
    <span *ngIf="!examValid" class="text-red-400">
      <strong>All Questions Must Have At Least Two Answers And One Correct Answer</strong>
    </span>
  </div>
</div>
