import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IManufacturerLicense } from "app/models/licenses/manufacturer-license";
import { IServiceCenterLicense } from "app/models/licenses/service-center-license";
import { ITestingLabLicense } from "app/models/licenses/testing-lab-license";
import { IWorkerLicense } from "app/models/licenses/worker/worker-license";
import { SharedService } from "app/services/core/shared.service";
import { ManufacturerService } from "app/services/licenses/manufacturer.service";
import { ServiceCenterService } from "app/services/licenses/service-center.service";
import { TestingLabService } from "app/services/licenses/testing-lab.service";
import { DialogAddPermitComponent } from "./dialog-add-permit/dialog-add-permit.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogAddPermitSB303Component } from "./dialog-add-permit-sb303/dialog-add-permit-sb303.component";

@Component({
  selector: 'app-associated-worker-permits',
  templateUrl: './associated-worker-permits.component.html',
  styleUrls: ['./associated-worker-permits.component.scss']
})

export class AssociatedWorkerPermitComponent {

  constructor(public sharedService: SharedService,
        public manufacturerService: ManufacturerService,
        public testingLabService: TestingLabService,
        public serviceCenterService: ServiceCenterService,
        private dialog: MatDialog) { }

  @Output() removePTSPermit: EventEmitter<string> = new EventEmitter<string>();
  @Output() remove303PTSPermit: EventEmitter<string> = new EventEmitter<string>();
  @Output() addPermit: EventEmitter<IWorkerLicense[]> = new EventEmitter<IWorkerLicense[]>();
  @Output() add303Permit: EventEmitter<IWorkerLicense[]> = new EventEmitter<IWorkerLicense[]>();
  @Input() cardColor: string = '';
  @Input() licenseType: string = '';
  @Input() permits: IWorkerLicense[] = [];
  @Input() sb303Permits: IWorkerLicense[] = [];
  @Input() id: number = 0;
  @Input() isAdmin: boolean = false;
  @Input() manufacturerLicenses: IManufacturerLicense[] = [];
  @Input() testingLabLicenses: ITestingLabLicense[] = [];
  @Input() serviceCenterLicenses: IServiceCenterLicense[] = [];
  @Input() sB303ServiceCenters: IServiceCenterLicense[] = [];

  removeWorkerPermit(permitId: string): void {
    this.sharedService.openConfirm("Remove Worker Permit: " + permitId + "?");
    this.sharedService.confirmed().subscribe((confirmed) => {
      if(confirmed){
        if(this.licenseType == this.sharedService.manufacturer){
          this.manufacturerService.removeWorkerPermit(this.id, permitId).subscribe({
            complete: () => {
              this.removePTSPermit.emit(permitId);
            },
            error: e => console.log('error', e)
          });
        }
        if(this.licenseType == this.sharedService.testingLab){
          this.testingLabService.removeWorkerPermit(this.id, permitId).subscribe({
            complete: () => {
              this.removePTSPermit.emit(permitId);
            },
            error: e => console.log('error', e)
          });
        }
        if(this.licenseType == this.sharedService.serviceCenter){
          this.serviceCenterService.removeWorkerPermit(this.id, permitId).subscribe({
            complete: () => {
              this.removePTSPermit.emit(permitId);
            },
            error: e => console.log('error', e)
          });
        }
      }
    });
  }

  remove303WorkerPermit(permitId: string): void {
    this.sharedService.openConfirm("Remove Worker Permit: " + permitId + "?");
    this.sharedService.confirmed().subscribe((confirmed) => {
      if(confirmed){
        this.serviceCenterService.removeSB303WorkerPermit(this.id, permitId).subscribe({
          complete: () => {
            this.remove303PTSPermit.emit(permitId);
          },
          error: e => console.log('error', e)
        });
      }
    });
  }

  addWorkerPermit(): void {
    const dialogRef = this.dialog.open(DialogAddPermitComponent, {
      data: this.permits,
      role: 'dialog',
      ariaLabel: 'Add Worker Permits',
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(!this.sharedService.isCancelled(response)){
        if(this.licenseType == this.sharedService.manufacturer){
          this.manufacturerService.addWorkerPermit(this.id, response).subscribe({
            next: response => this.addPermit.emit(response),
            error: e => console.log('error', e)
          });
        }
        if(this.licenseType == this.sharedService.testingLab){
          this.testingLabService.addWorkerPermit(this.id, response).subscribe({
            next: response => this.addPermit.emit(response),
            error: e => console.log('error', e)
          });
        }
        if(this.licenseType == this.sharedService.serviceCenter){
          this.serviceCenterService.addWorkerPermit(this.id, response).subscribe({
            next: response => this.addPermit.emit(response),
            error: e => console.log('error', e)
          });
        }
      }
    });
  }

  addSB303WorkerPermit(): void {
    const dialogRef = this.dialog.open(DialogAddPermitSB303Component, {
      data: this.sb303Permits,
      role: 'dialog',
      ariaLabel: 'Add 303 Reporting Worker Permits',
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(!this.sharedService.isCancelled(response)){
        this.serviceCenterService.addSB303WorkerPermit(this.id, response).subscribe({
          next: response => this.add303Permit.emit(response),
          error: e => console.log('error', e)
        });
      }
    });
  }

}
