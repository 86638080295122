import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IAuditLog, IChange } from 'app/models/audit-logging/audit-logging';

@Component({
  selector: 'audit-log-table',
  templateUrl: './audit-log-table.component.html',
  styleUrls: ['./audit-log-table.component.scss']
})
export class AuditLogTableComponent implements OnInit {

  @Input() auditLog: IAuditLog = {
    keyValue: '',
    userId: '',
    tableName: '',
    timeStamp: '',
    changes: [],
    staffChange: false,
    licenseHolder: '',
    associatedWorker: ''
  }

  @Input() LicenseId: string = '';
  @Input() LicenseName: string = '';
  public changesColumns: string[] = ['operation', 'propertyName', 'oldValue', 'newValue'];
  public changesSource = new MatTableDataSource<IChange>(this.auditLog.changes);

  constructor() { }

  ngOnInit(): void {
    this.changesSource.data = this.auditLog.changes
  }

}
