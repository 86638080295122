import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IAuditLog } from 'app/models/audit-logging/audit-logging';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';
import { AuditLoggingComplianceSearch } from 'app/models/audit-logging/audit-logging-compliance-search';
import { AuditLoggingClientDataSearch } from 'app/models/audit-logging/audit-logging-client-data-search';
import { AuditLoggingServiceCenterDataSearch } from 'app/models/audit-logging/audit-logging-service-center-data-search';

@Injectable({providedIn: 'root'})
export class AuditLogService {
  private api: string = environment.privateApi + 'AuditLogging/';

  constructor(private http: HttpClient) { }

  initiateSearch(entityId: string, entityType): Observable<AuditLoggingSearch>{
    return this.http.get<AuditLoggingSearch>(this.api + "InitiateSearch/" + entityId + "/" + entityType);
  }

  getAuditLogging(search: AuditLoggingSearch): Observable<IAuditLog[]>{
    return this.http.post<IAuditLog[]>(this.api + "GetAuditLogs", search);
  }

  getTrainingProgramAuditLogs(programId: number): Observable<IAuditLog[]> {
    return this.http.get<IAuditLog[]>(this.api + 'GetAuditLogs/' + programId);
  }

  initiateComplianceSearch(entityId: string): Observable<AuditLoggingComplianceSearch>{
    return this.http.get<AuditLoggingComplianceSearch>(this.api + "InitiateComplianceSearch/" + entityId);
  }

  getComplianceAuditLogging(search: AuditLoggingComplianceSearch): Observable<IAuditLog[]>{
    return this.http.post<IAuditLog[]>(this.api + "GetComplianceAuditLogs", search);
  }

  initiateClientDataSearch(entityId: string): Observable<AuditLoggingClientDataSearch>{
    return this.http.get<AuditLoggingClientDataSearch>(this.api + "InitiateClientDataSearch/" + entityId);
  }

  getClientDataAuditLogging(search: AuditLoggingClientDataSearch): Observable<IAuditLog[]>{
    return this.http.post<IAuditLog[]>(this.api + "GetClientDataAuditLogs", search);
  }

  initiateServiceCenterDataSearch(entityId: string): Observable<AuditLoggingServiceCenterDataSearch>{
    return this.http.get<AuditLoggingServiceCenterDataSearch>(this.api + "InitiateServiceCenterDataSearch/" + entityId);
  }

  getServiceCenterDataAuditLogging(search: AuditLoggingServiceCenterDataSearch): Observable<IAuditLog[]>{
    return this.http.post<IAuditLog[]>(this.api + "GetServiceCenterDataAuditLogs", search);
  }
}
