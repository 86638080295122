import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  templateUrl: './manufacturer-complaints.component.html'
})
export class ManufacturerComplaintsComponent implements OnInit {

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

}
