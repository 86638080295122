<div [hidden]="loading">
  <section>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <span class="f-s-20" fxFlex>Edit Interview</span>
      <button mat-raised-button type="button" color="primary" (click)="saveInterview(false)">Save</button>
      <button mat-raised-button type="button" color="primary" (click)="saveInterview(true)">Save & Close</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Close</button>
    </div>
  </section>

  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
      <form [formGroup]="interviewForm">
        <div fxLayout="row">
          <mat-form-field formControlName="notes" fxFlex="100">
            <mat-label>Summary of interview</mat-label>
            <textarea matInput formControlName="notes"></textarea>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field formControlName="auditInfo" fxFlex="100">
            <mat-label>Interview Date</mat-label>
            <input placeholder="mm/dd/yyyy"
                  matInput
                  type="datetime-local"
                  formControlName="auditInfo" />
            <mat-error *ngIf="this.interviewForm.get('auditInfo').value > today">
              Please choose an earlier date.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutGap="12px">
          <div fxlayout="row" fxFlex="100">
            <span class="f-s-20">Files</span>
          </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <mat-table [dataSource]="interviewDocumentsSource" aria-describedby="Interview Documents" class="mat-elevation-z8" fxFlex="100">

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
                    <mat-cell *matCellDef="let document">
                      <span class="mobile-label">Documents</span>
                      <span>{{ document.name }}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let document">
                      <div fxLayout="row">
                        <button type="button" mat-icon-button class="m-8" (click)="deleteInterviewDocument(document.id, document.name)" *ngIf="!document.azureFile"
                          color="warn" aria-label="Delete">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <button type="button" mat-icon-button class="m-8" (click)="deleteAzureDocument(document.id, document.name)" *ngIf="document.azureFile"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                        <button type="primary" mat-icon-button class="m-8" (click)="downloadInterviewDocument(document.id, document.name)" *ngIf="!document.azureFile"
                          color="primary" aria-label="Download">
                          <mat-icon>download</mat-icon>
                        </button>
                        <button type="primary" mat-icon-button class="m-8" (click)="downloadAzureDocument(document.name)" *ngIf="document.azureFile"
                        color="primary" aria-label="Download">
                        <mat-icon>download</mat-icon>
                      </button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="interviewDocumentsColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: interviewDocumentsColumns"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" #interviewDocumentsPaginator="matPaginator"></mat-paginator>
              </div>
            </div>

            <div fxlayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="50">
                <input type="file" class="file-input"  #fileUpload (change)="uploadInterviewDocument($event, false)"/>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div class="file-upload">
                    <button mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                      Upload Document
                      <mat-icon>attach_file</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="50" fxLayoutAlign="end end">
                <input type="file" class="file-input"  #interviewUpload (change)="uploadToContainer($event)"/>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div class="file-upload">
                    <button mat-raised-button color="primary" class="upload-btn" (click)="interviewUpload.click()">
                      Upload Interview
                      <mat-icon>attach_file</mat-icon>
                    </button>
                  </div>
                </div>
                <progress max="100" [value]="uploadProgress" *ngIf="uploading"></progress>
                <span *ngIf="uploading">{{ uploadProgress }}%</span>
              </div>
            </div>
            <div fxlayout="row" fxFlex="100"  fxLayoutAlign="center center" *ngIf="downloading">
              <div fxlayout="column">
                <div fxlayout="row" fxLayoutAlign="center center">
                  <span>Downloading</span>
                </div>
                <div fxlayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                  <progress max="100" [value]="downloadProgress"></progress>
                  <span>{{ downloadProgress }}%</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>

  </div>
  <div [hidden]="!loading">
    <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
  </div>
