<h2 mat-dialog-title>
  <mat-icon class="icon-20" >edit</mat-icon> Edit User
</h2>
<section *ngIf="processing">
  <h2>Loading...</h2>
  <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading content">
  </mat-progress-bar>
</section>
<div *ngIf="user != null">
  <br />
  <mat-card [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode}">
    <mat-card-title>{{user?.fullName}} ({{user?.userName}})</mat-card-title>
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
        <div fxFlex="100" fxFlex.lt-md="100">
          <span>&nbsp;</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.address != null && user?.address.length > 0">
          <em class="text-muted">Address:  &nbsp;&nbsp;</em>
          <span>{{user?.address}} {{user?.city}} {{user?.state}} {{user?.zip}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.mobile != null && user?.mobile.length > 0">
          <em class="text-muted">Mobile:  &nbsp;&nbsp;</em>
          <span>{{user?.mobile}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100"  *ngIf="user?.phone != null && user?.phone.length > 0">
          <em class="text-muted">Phone:  &nbsp;&nbsp;</em>
          <span>{{user?.phone}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100"  *ngIf="user?.jobTitle != null && user?.jobTitle.length > 0">
          <em class="text-muted">Title:  &nbsp;&nbsp;</em>
          <span>{{user?.jobTitle}}</span>
        </div>

        <div fxFlex="100" fxFlex.lt-md="100" >
          <br/>
          <em class="text-muted">Roles:  &nbsp;&nbsp;</em>
          <br/>
          <span *ngFor="let role of roles">
            <mat-checkbox (change)="updateUserRole(role, $event)" [checked]="userHasRole(role)">{{role}}</mat-checkbox><br />
          </span>
        </div>
      </div>

      <div>
        <br />
        <button mat-raised-button color="primary" (click)="saveUser()" [disabled]="processing || user == null" color="primary">Save User</button>
        <button mat-button mat-dialog-close="Cancel" aria-label="Cancel">Cancel</button>
      </div>
  </mat-card>
</div>
