import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClientData } from 'app/models/303/clientData';
import { SB303Service } from 'app/services/303/303.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-dialog-view-client-data',
  templateUrl: './dialog-view-client-data.component.html'
})
export class DialogViewClientDataComponent implements OnInit {

  public loading: boolean = false;
  public clientData: IClientData = {
    id: 0,
    submitted: false,
    open: false,
    submittedDate: '',
    licenseId: '',
    entityName: '',
    racialIdentity: undefined,
    primaryRacialIdentity: undefined,
    spokenLanguage: undefined,
    writtenLanguage: undefined,
    disabilityStatus: undefined,
    genderIdentity: undefined,
    sexualOrientation: undefined,
    gender: undefined,
    income: undefined,
    county: undefined,
    visitType: undefined,
    under21: null,
    age21_24: null,
    age25_29: null,
    age30_34: null,
    age35_39: null,
    age40_44: null,
    age45_49: null,
    age50_54: null,
    age55_59: null,
    age60_64: null,
    age65_69: null,
    age70_74: null,
    age75_79: null,
    age80_84: null,
    age85Older: null,
    noAgeAnswer: null,
    reportingStartDate: '',
    reportingEndDate: ''
  };

  public racialIdentityForm = new FormGroup({
    centralAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    mexican: new FormControl(null, [Validators.required, Validators.min(0)]),
    southAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherHispanic: new FormControl(null, [Validators.required, Validators.min(0)]),
    chamoru: new FormControl(null, [Validators.required, Validators.min(0)]),
    marshallese: new FormControl(null, [Validators.required, Validators.min(0)]),
    micronesian: new FormControl(null, [Validators.required, Validators.min(0)]),
    nativeHawaiian: new FormControl(null, [Validators.required, Validators.min(0)]),
    samoan: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherPacificIslander: new FormControl(null, [Validators.required, Validators.min(0)]),
    easternEuropean: new FormControl(null, [Validators.required, Validators.min(0)]),
    slavic: new FormControl(null, [Validators.required, Validators.min(0)]),
    westernEuropean: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherWhite: new FormControl(null, [Validators.required, Validators.min(0)]),
    americanIndian: new FormControl(null, [Validators.required, Validators.min(0)]),
    alaskaNative: new FormControl(null, [Validators.required, Validators.min(0)]),
    canadianInnuitMetisFirstNation: new FormControl(null, [Validators.required, Validators.min(0)]),
    indigenousMexicanCentralAmericanSouthAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    africanAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    afroCaribbean: new FormControl(null, [Validators.required, Validators.min(0)]),
    ethiopian: new FormControl(null, [Validators.required, Validators.min(0)]),
    somali: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherAfrican: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherBlack: new FormControl(null, [Validators.required, Validators.min(0)]),
    middleEastern: new FormControl(null, [Validators.required, Validators.min(0)]),
    northAfrican: new FormControl(null, [Validators.required, Validators.min(0)]),
    asianIndian: new FormControl(null, [Validators.required, Validators.min(0)]),
    cambodian: new FormControl(null, [Validators.required, Validators.min(0)]),
    chinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    myanmar: new FormControl(null, [Validators.required, Validators.min(0)]),
    filipino: new FormControl(null, [Validators.required, Validators.min(0)]),
    hmong: new FormControl(null, [Validators.required, Validators.min(0)]),
    japanese: new FormControl(null, [Validators.required, Validators.min(0)]),
    korean: new FormControl(null, [Validators.required, Validators.min(0)]),
    laotian: new FormControl(null, [Validators.required, Validators.min(0)]),
    southAsian: new FormControl(null, [Validators.required, Validators.min(0)]),
    vietnamese: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherAsian: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherTotal: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnowTotal: new FormControl(null, [Validators.required, Validators.min(0)]),
    refusedTotal: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public primaryRacialIdentityForm = new FormGroup({
    centralAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    mexican: new FormControl(null, [Validators.required, Validators.min(0)]),
    southAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherHispanic: new FormControl(null, [Validators.required, Validators.min(0)]),
    chamoru: new FormControl(null, [Validators.required, Validators.min(0)]),
    marshallese: new FormControl(null, [Validators.required, Validators.min(0)]),
    micronesian: new FormControl(null, [Validators.required, Validators.min(0)]),
    nativeHawaiian: new FormControl(null, [Validators.required, Validators.min(0)]),
    samoan: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherPacificIslander: new FormControl(null, [Validators.required, Validators.min(0)]),
    easternEuropean: new FormControl(null, [Validators.required, Validators.min(0)]),
    slavic: new FormControl(null, [Validators.required, Validators.min(0)]),
    westernEuropean: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherWhite: new FormControl(null, [Validators.required, Validators.min(0)]),
    americanIndian: new FormControl(null, [Validators.required, Validators.min(0)]),
    alaskaNative: new FormControl(null, [Validators.required, Validators.min(0)]),
    canadianInnuitMetisFirstNation: new FormControl(null, [Validators.required, Validators.min(0)]),
    indigenousMexicanCentralAmericanSouthAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    africanAmerican: new FormControl(null, [Validators.required, Validators.min(0)]),
    afroCaribbean: new FormControl(null, [Validators.required, Validators.min(0)]),
    ethiopian: new FormControl(null, [Validators.required, Validators.min(0)]),
    somali: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherAfrican: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherBlack: new FormControl(null, [Validators.required, Validators.min(0)]),
    middleEastern: new FormControl(null, [Validators.required, Validators.min(0)]),
    northAfrican: new FormControl(null, [Validators.required, Validators.min(0)]),
    asianIndian: new FormControl(null, [Validators.required, Validators.min(0)]),
    cambodian: new FormControl(null, [Validators.required, Validators.min(0)]),
    chinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    myanmar: new FormControl(null, [Validators.required, Validators.min(0)]),
    filipino: new FormControl(null, [Validators.required, Validators.min(0)]),
    hmong: new FormControl(null, [Validators.required, Validators.min(0)]),
    japanese: new FormControl(null, [Validators.required, Validators.min(0)]),
    korean: new FormControl(null, [Validators.required, Validators.min(0)]),
    laotian: new FormControl(null, [Validators.required, Validators.min(0)]),
    southAsian: new FormControl(null, [Validators.required, Validators.min(0)]),
    vietnamese: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherAsian: new FormControl(null, [Validators.required, Validators.min(0)]),
    multipleIdentities: new FormControl(null, [Validators.required, Validators.min(0)]),
    biracialMultiRacial: new FormControl(null, [Validators.required, Validators.min(0)]),
    na: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnowTotal: new FormControl(null, [Validators.required, Validators.min(0)]),
    refusedTotal: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public spokenLanguageForm = new FormGroup({
    arabic: new FormControl(null, [Validators.required, Validators.min(0)]),
    bosnian: new FormControl(null, [Validators.required, Validators.min(0)]),
    burmese: new FormControl(null, [Validators.required, Validators.min(0)]),
    cambodian: new FormControl(null, [Validators.required, Validators.min(0)]),
    chuukese: new FormControl(null, [Validators.required, Validators.min(0)]),
    english: new FormControl(null, [Validators.required, Validators.min(0)]),
    farsi: new FormControl(null, [Validators.required, Validators.min(0)]),
    french: new FormControl(null, [Validators.required, Validators.min(0)]),
    german: new FormControl(null, [Validators.required, Validators.min(0)]),
    japanese: new FormControl(null, [Validators.required, Validators.min(0)]),
    korean: new FormControl(null, [Validators.required, Validators.min(0)]),
    lao: new FormControl(null, [Validators.required, Validators.min(0)]),
    marshallese: new FormControl(null, [Validators.required, Validators.min(0)]),
    oromo: new FormControl(null, [Validators.required, Validators.min(0)]),
    pohnpeian: new FormControl(null, [Validators.required, Validators.min(0)]),
    romanian: new FormControl(null, [Validators.required, Validators.min(0)]),
    russian: new FormControl(null, [Validators.required, Validators.min(0)]),
    simplifiedChinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    somali: new FormControl(null, [Validators.required, Validators.min(0)]),
    spanish: new FormControl(null, [Validators.required, Validators.min(0)]),
    thai: new FormControl(null, [Validators.required, Validators.min(0)]),
    traditionalChinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    ukrainian: new FormControl(null, [Validators.required, Validators.min(0)]),
    vietnamese: new FormControl(null, [Validators.required, Validators.min(0)]),
    other: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public writtenLanguageForm = new FormGroup({
    arabic: new FormControl(null, [Validators.required, Validators.min(0)]),
    bosnian: new FormControl(null, [Validators.required, Validators.min(0)]),
    burmese: new FormControl(null, [Validators.required, Validators.min(0)]),
    cambodian: new FormControl(null, [Validators.required, Validators.min(0)]),
    chuukese: new FormControl(null, [Validators.required, Validators.min(0)]),
    english: new FormControl(null, [Validators.required, Validators.min(0)]),
    farsi: new FormControl(null, [Validators.required, Validators.min(0)]),
    french: new FormControl(null, [Validators.required, Validators.min(0)]),
    german: new FormControl(null, [Validators.required, Validators.min(0)]),
    japanese: new FormControl(null, [Validators.required, Validators.min(0)]),
    korean: new FormControl(null, [Validators.required, Validators.min(0)]),
    lao: new FormControl(null, [Validators.required, Validators.min(0)]),
    marshallese: new FormControl(null, [Validators.required, Validators.min(0)]),
    oromo: new FormControl(null, [Validators.required, Validators.min(0)]),
    pohnpeian: new FormControl(null, [Validators.required, Validators.min(0)]),
    romanian: new FormControl(null, [Validators.required, Validators.min(0)]),
    russian: new FormControl(null, [Validators.required, Validators.min(0)]),
    simplifiedChinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    somali: new FormControl(null, [Validators.required, Validators.min(0)]),
    spanish: new FormControl(null, [Validators.required, Validators.min(0)]),
    thai: new FormControl(null, [Validators.required, Validators.min(0)]),
    traditionalChinese: new FormControl(null, [Validators.required, Validators.min(0)]),
    ukrainian: new FormControl(null, [Validators.required, Validators.min(0)]),
    vietnamese: new FormControl(null, [Validators.required, Validators.min(0)]),
    other: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public deafForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public blindForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyWalkingForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
      no: new FormControl(null, [Validators.required, Validators.min(0)]),
      dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
      noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
      sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
      under21: new FormControl(null, [Validators.required, Validators.min(0)]),
      age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
      age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
      age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
      age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
      noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyConcentratingForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyDressingForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
      no: new FormControl(null, [Validators.required, Validators.min(0)]),
      dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
      noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
      sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
      under21: new FormControl(null, [Validators.required, Validators.min(0)]),
      age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
      age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
      age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
      age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
      noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyLearningForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyCommunicatingForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstand: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyErrandsForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public difficultyMoodsForm = new FormGroup({
    yes: new FormControl(null, [Validators.required, Validators.min(0)]),
    no: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    sinceBirth: new FormControl(null, [Validators.required, Validators.min(0)]),
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_40: new FormControl(null, [Validators.required, Validators.min(0)]),
    age41_60: new FormControl(null, [Validators.required, Validators.min(0)]),
    age61_80: new FormControl(null, [Validators.required, Validators.min(0)]),
    age81Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstand: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public genderIdentityForm = new FormGroup({
    woman: new FormControl(null, [Validators.required, Validators.min(0)]),
    man: new FormControl(null, [Validators.required, Validators.min(0)]),
    nonBinary: new FormControl(null, [Validators.required, Validators.min(0)]),
    agender: new FormControl(null, [Validators.required, Validators.min(0)]),
    bigender: new FormControl(null, [Validators.required, Validators.min(0)]),
    demiboy: new FormControl(null, [Validators.required, Validators.min(0)]),
    demigirl: new FormControl(null, [Validators.required, Validators.min(0)]),
    genderfluid: new FormControl(null, [Validators.required, Validators.min(0)]),
    genderqueer: new FormControl(null, [Validators.required, Validators.min(0)]),
    questioning: new FormControl(null, [Validators.required, Validators.min(0)]),
    notListed: new FormControl(null, [Validators.required, Validators.min(0)]),
    specificToEthnicity: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstand: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    yesTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
    noTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
    questioningTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnowTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstandTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswerTransgender: new FormControl(null, [Validators.required, Validators.min(0)]),
  });

  public genderForm = new FormGroup({
    female: new FormControl(null, [Validators.required, Validators.min(0)]),
    male: new FormControl(null, [Validators.required, Validators.min(0)]),
    intersex: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstand: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
    notListed: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public sexualOrientationForm = new FormGroup({
    sameGenderLoving: new FormControl(null, [Validators.required, Validators.min(0)]),
    lesbian: new FormControl(null, [Validators.required, Validators.min(0)]),
    gay: new FormControl(null, [Validators.required, Validators.min(0)]),
    bisexual: new FormControl(null, [Validators.required, Validators.min(0)]),
    pansexual: new FormControl(null, [Validators.required, Validators.min(0)]),
    straight: new FormControl(null, [Validators.required, Validators.min(0)]),
    asexual: new FormControl(null, [Validators.required, Validators.min(0)]),
    queer: new FormControl(null, [Validators.required, Validators.min(0)]),
    questioning: new FormControl(null, [Validators.required, Validators.min(0)]),
    notListed: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontUnderstand: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)]),
  });

  public incomeForm = new FormGroup({
    income0_11000: new FormControl(null, [Validators.required, Validators.min(0)]),
    income11001_44725: new FormControl(null, [Validators.required, Validators.min(0)]),
    income44726_95375: new FormControl(null, [Validators.required, Validators.min(0)]),
    income95376_182100: new FormControl(null, [Validators.required, Validators.min(0)]),
    income182101_231250: new FormControl(null, [Validators.required, Validators.min(0)]),
    income231251_578125: new FormControl(null, [Validators.required, Validators.min(0)]),
    income578126More: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public ageForm = new FormGroup({
    under21: new FormControl(null, [Validators.required, Validators.min(0)]),
    age21_24: new FormControl(null, [Validators.required, Validators.min(0)]),
    age25_29: new FormControl(null, [Validators.required, Validators.min(0)]),
    age30_34: new FormControl(null, [Validators.required, Validators.min(0)]),
    age35_39: new FormControl(null, [Validators.required, Validators.min(0)]),
    age40_44: new FormControl(null, [Validators.required, Validators.min(0)]),
    age45_49: new FormControl(null, [Validators.required, Validators.min(0)]),
    age50_54: new FormControl(null, [Validators.required, Validators.min(0)]),
    age55_59: new FormControl(null, [Validators.required, Validators.min(0)]),
    age60_64: new FormControl(null, [Validators.required, Validators.min(0)]),
    age65_69: new FormControl(null, [Validators.required, Validators.min(0)]),
    age70_74: new FormControl(null, [Validators.required, Validators.min(0)]),
    age75_79: new FormControl(null, [Validators.required, Validators.min(0)]),
    age80_84: new FormControl(null, [Validators.required, Validators.min(0)]),
    age85Older: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAgeAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public countyForm = new FormGroup({
    baker: new FormControl(null, [Validators.required, Validators.min(0)]),
    benton: new FormControl(null, [Validators.required, Validators.min(0)]),
    clackamas: new FormControl(null, [Validators.required, Validators.min(0)]),
    clatsop: new FormControl(null, [Validators.required, Validators.min(0)]),
    columbia: new FormControl(null, [Validators.required, Validators.min(0)]),
    coos: new FormControl(null, [Validators.required, Validators.min(0)]),
    crook: new FormControl(null, [Validators.required, Validators.min(0)]),
    curry: new FormControl(null, [Validators.required, Validators.min(0)]),
    deschutes: new FormControl(null, [Validators.required, Validators.min(0)]),
    douglas: new FormControl(null, [Validators.required, Validators.min(0)]),
    gilliam: new FormControl(null, [Validators.required, Validators.min(0)]),
    grant: new FormControl(null, [Validators.required, Validators.min(0)]),
    harney: new FormControl(null, [Validators.required, Validators.min(0)]),
    hoodRiver: new FormControl(null, [Validators.required, Validators.min(0)]),
    jackson: new FormControl(null, [Validators.required, Validators.min(0)]),
    jefferson: new FormControl(null, [Validators.required, Validators.min(0)]),
    josephine: new FormControl(null, [Validators.required, Validators.min(0)]),
    klamath: new FormControl(null, [Validators.required, Validators.min(0)]),
    lake: new FormControl(null, [Validators.required, Validators.min(0)]),
    lane: new FormControl(null, [Validators.required, Validators.min(0)]),
    lincoln: new FormControl(null, [Validators.required, Validators.min(0)]),
    linn: new FormControl(null, [Validators.required, Validators.min(0)]),
    malheur: new FormControl(null, [Validators.required, Validators.min(0)]),
    marion: new FormControl(null, [Validators.required, Validators.min(0)]),
    morrow: new FormControl(null, [Validators.required, Validators.min(0)]),
    multnomah: new FormControl(null, [Validators.required, Validators.min(0)]),
    polk: new FormControl(null, [Validators.required, Validators.min(0)]),
    sherman: new FormControl(null, [Validators.required, Validators.min(0)]),
    tillamook: new FormControl(null, [Validators.required, Validators.min(0)]),
    umatilla: new FormControl(null, [Validators.required, Validators.min(0)]),
    union: new FormControl(null, [Validators.required, Validators.min(0)]),
    wallowa: new FormControl(null, [Validators.required, Validators.min(0)]),
    wasco: new FormControl(null, [Validators.required, Validators.min(0)]),
    washington: new FormControl(null, [Validators.required, Validators.min(0)]),
    wheeler: new FormControl(null, [Validators.required, Validators.min(0)]),
    yamhill: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherInsideUS: new FormControl(null, [Validators.required, Validators.min(0)]),
    outsideUS: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public visitTypeForm = new FormGroup({
    generalHealth: new FormControl(null, [Validators.required, Validators.min(0)]),
    culturallyOrLinguisticallyResponsiveHealth: new FormControl(null, [Validators.required, Validators.min(0)]),
    enhancedCreativity: new FormControl(null, [Validators.required, Validators.min(0)]),
    changeOfPerspective: new FormControl(null, [Validators.required, Validators.min(0)]),
    expandedConsciousness: new FormControl(null, [Validators.required, Validators.min(0)]),
    spiritualityReasons: new FormControl(null, [Validators.required, Validators.min(0)]),
    genderIdentityDevelopment: new FormControl(null, [Validators.required, Validators.min(0)]),
    mentalOrPhysicalExhaustion: new FormControl(null, [Validators.required, Validators.min(0)]),
    chronicPain: new FormControl(null, [Validators.required, Validators.min(0)]),
    brainInjury: new FormControl(null, [Validators.required, Validators.min(0)]),
    endOfLife: new FormControl(null, [Validators.required, Validators.min(0)]),
    substanceUse: new FormControl(null, [Validators.required, Validators.min(0)]),
    anxiety: new FormControl(null, [Validators.required, Validators.min(0)]),
    depression: new FormControl(null, [Validators.required, Validators.min(0)]),
    eatingDisorder: new FormControl(null, [Validators.required, Validators.min(0)]),
    ptsd: new FormControl(null, [Validators.required, Validators.min(0)]),
    mentalHealthDiagnosis: new FormControl(null, [Validators.required, Validators.min(0)]),
    undiagnosedMentalHealthIssue: new FormControl(null, [Validators.required, Validators.min(0)]),
    economicDriver: new FormControl(null, [Validators.required, Validators.min(0)]),
    racialTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    genderTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    domesticViolenceTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    combatTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    colonizationTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherTrauma: new FormControl(null, [Validators.required, Validators.min(0)]),
    otherReasons: new FormControl(null, [Validators.required, Validators.min(0)]),
    dontKnow: new FormControl(null, [Validators.required, Validators.min(0)]),
    noAnswer: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public currentQuestion: number = 0;
  public questions = ['1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', '9.', '10.', '11.', '12.'];

  constructor(public dialogRef: MatDialogRef<DialogViewClientDataComponent>,
              public sb303Service: SB303Service,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit(): void {
    this.loading = true;
    this.sb303Service.getClientData(this.data).subscribe({
      next: response => this.clientData = response,
      error: e => console.log('error', e),
      complete: () =>
      {
        this.updateForm();
        this.loading = false;
      }
    });
  }

  updateForm(){
    this.racialIdentityForm.patchValue({
      centralAmerican: this.clientData.racialIdentity.centralAmerican,
      mexican: this.clientData.racialIdentity.mexican,
      southAmerican: this.clientData.racialIdentity.southAmerican,
      otherHispanic: this.clientData.racialIdentity.otherHispanic,
      chamoru: this.clientData.racialIdentity.chamoru,
      marshallese: this.clientData.racialIdentity.marshallese,
      micronesian: this.clientData.racialIdentity.micronesian,
      nativeHawaiian: this.clientData.racialIdentity.nativeHawaiian,
      samoan: this.clientData.racialIdentity.samoan,
      otherPacificIslander: this.clientData.racialIdentity.otherPacificIslander,
      easternEuropean: this.clientData.racialIdentity.easternEuropean,
      slavic: this.clientData.racialIdentity.slavic,
      westernEuropean: this.clientData.racialIdentity.westernEuropean,
      otherWhite: this.clientData.racialIdentity.otherWhite,
      americanIndian: this.clientData.racialIdentity.americanIndian,
      alaskaNative: this.clientData.racialIdentity.alaskaNative,
      canadianInnuitMetisFirstNation: this.clientData.racialIdentity.canadianInnuitMetisFirstNation,
      indigenousMexicanCentralAmericanSouthAmerican: this.clientData.racialIdentity.indigenousMexicanCentralAmericanSouthAmerican,
      africanAmerican: this.clientData.racialIdentity.africanAmerican,
      afroCaribbean: this.clientData.racialIdentity.afroCaribbean,
      ethiopian: this.clientData.racialIdentity.ethiopian,
      somali: this.clientData.racialIdentity.somali,
      otherAfrican: this.clientData.racialIdentity.otherAfrican,
      otherBlack: this.clientData.racialIdentity.otherBlack,
      middleEastern: this.clientData.racialIdentity.middleEastern,
      northAfrican: this.clientData.racialIdentity.northAfrican,
      asianIndian: this.clientData.racialIdentity.asianIndian,
      cambodian: this.clientData.racialIdentity.cambodian,
      chinese: this.clientData.racialIdentity.chinese,
      myanmar: this.clientData.racialIdentity.myanmar,
      filipino: this.clientData.racialIdentity.filipino,
      hmong: this.clientData.racialIdentity.hmong,
      japanese: this.clientData.racialIdentity.japanese,
      korean: this.clientData.racialIdentity.korean,
      laotian: this.clientData.racialIdentity.laotian,
      southAsian: this.clientData.racialIdentity.southAsian,
      vietnamese: this.clientData.racialIdentity.vietnamese,
      otherAsian: this.clientData.racialIdentity.otherAsian,
      otherTotal: this.clientData.racialIdentity.otherTotal,
      dontKnowTotal: this.clientData.racialIdentity.dontKnowTotal,
      refusedTotal: this.clientData.racialIdentity.refusedTotal,
    });

    this.primaryRacialIdentityForm.patchValue({
      centralAmerican: this.clientData.primaryRacialIdentity.centralAmerican,
      mexican: this.clientData.primaryRacialIdentity.mexican,
      southAmerican: this.clientData.primaryRacialIdentity.southAmerican,
      otherHispanic: this.clientData.primaryRacialIdentity.otherHispanic,
      chamoru: this.clientData.primaryRacialIdentity.chamoru,
      marshallese: this.clientData.primaryRacialIdentity.marshallese,
      micronesian: this.clientData.primaryRacialIdentity.micronesian,
      nativeHawaiian: this.clientData.primaryRacialIdentity.nativeHawaiian,
      samoan: this.clientData.primaryRacialIdentity.samoan,
      otherPacificIslander: this.clientData.primaryRacialIdentity.otherPacificIslander,
      easternEuropean: this.clientData.primaryRacialIdentity.easternEuropean,
      slavic: this.clientData.primaryRacialIdentity.slavic,
      westernEuropean: this.clientData.primaryRacialIdentity.westernEuropean,
      otherWhite: this.clientData.primaryRacialIdentity.otherWhite,
      americanIndian: this.clientData.primaryRacialIdentity.americanIndian,
      alaskaNative: this.clientData.primaryRacialIdentity.alaskaNative,
      canadianInnuitMetisFirstNation: this.clientData.primaryRacialIdentity.canadianInnuitMetisFirstNation,
      indigenousMexicanCentralAmericanSouthAmerican: this.clientData.primaryRacialIdentity.indigenousMexicanCentralAmericanSouthAmerican,
      africanAmerican: this.clientData.primaryRacialIdentity.africanAmerican,
      afroCaribbean: this.clientData.primaryRacialIdentity.afroCaribbean,
      ethiopian: this.clientData.primaryRacialIdentity.ethiopian,
      somali: this.clientData.primaryRacialIdentity.somali,
      otherAfrican: this.clientData.primaryRacialIdentity.otherAfrican,
      otherBlack: this.clientData.primaryRacialIdentity.otherBlack,
      middleEastern: this.clientData.primaryRacialIdentity.middleEastern,
      northAfrican: this.clientData.primaryRacialIdentity.northAfrican,
      asianIndian: this.clientData.primaryRacialIdentity.asianIndian,
      cambodian: this.clientData.primaryRacialIdentity.cambodian,
      chinese: this.clientData.primaryRacialIdentity.chinese,
      myanmar: this.clientData.primaryRacialIdentity.myanmar,
      filipino: this.clientData.primaryRacialIdentity.filipino,
      hmong: this.clientData.primaryRacialIdentity.hmong,
      japanese: this.clientData.primaryRacialIdentity.japanese,
      korean: this.clientData.primaryRacialIdentity.korean,
      laotian: this.clientData.primaryRacialIdentity.laotian,
      southAsian: this.clientData.primaryRacialIdentity.southAsian,
      vietnamese: this.clientData.primaryRacialIdentity.vietnamese,
      otherAsian: this.clientData.primaryRacialIdentity.otherAsian,
      multipleIdentities: this.clientData.primaryRacialIdentity.multipleIdentities,
      biracialMultiRacial: this.clientData.primaryRacialIdentity.biracialMultiRacial,
      na: this.clientData.primaryRacialIdentity.na,
      dontKnowTotal: this.clientData.primaryRacialIdentity.dontKnowTotal,
      refusedTotal: this.clientData.primaryRacialIdentity.refusedTotal
    });

    this.spokenLanguageForm.patchValue({
      arabic: this.clientData.spokenLanguage.arabic,
      bosnian: this.clientData.spokenLanguage.bosnian,
      burmese: this.clientData.spokenLanguage.burmese,
      cambodian: this.clientData.spokenLanguage.cambodian,
      chuukese: this.clientData.spokenLanguage.chuukese,
      english: this.clientData.spokenLanguage.english,
      farsi: this.clientData.spokenLanguage.farsi,
      french: this.clientData.spokenLanguage.french,
      german: this.clientData.spokenLanguage.german,
      japanese: this.clientData.spokenLanguage.japanese,
      korean: this.clientData.spokenLanguage.korean,
      lao: this.clientData.spokenLanguage.lao,
      marshallese: this.clientData.spokenLanguage.marshallese,
      oromo: this.clientData.spokenLanguage.oromo,
      pohnpeian: this.clientData.spokenLanguage.pohnpeian,
      romanian: this.clientData.spokenLanguage.romanian,
      russian: this.clientData.spokenLanguage.russian,
      simplifiedChinese: this.clientData.spokenLanguage.simplifiedChinese,
      somali: this.clientData.spokenLanguage.somali,
      spanish: this.clientData.spokenLanguage.spanish,
      thai: this.clientData.spokenLanguage.thai,
      traditionalChinese: this.clientData.spokenLanguage.traditionalChinese,
      ukrainian: this.clientData.spokenLanguage.ukrainian,
      vietnamese: this.clientData.spokenLanguage.vietnamese,
      other: this.clientData.spokenLanguage.other
    });

    this.writtenLanguageForm.patchValue({
      arabic: this.clientData.writtenLanguage.arabic,
      bosnian: this.clientData.writtenLanguage.bosnian,
      burmese: this.clientData.writtenLanguage.burmese,
      cambodian: this.clientData.writtenLanguage.cambodian,
      chuukese: this.clientData.writtenLanguage.chuukese,
      english: this.clientData.writtenLanguage.english,
      farsi: this.clientData.writtenLanguage.farsi,
      french: this.clientData.writtenLanguage.french,
      german: this.clientData.writtenLanguage.german,
      japanese: this.clientData.writtenLanguage.japanese,
      korean: this.clientData.writtenLanguage.korean,
      lao: this.clientData.writtenLanguage.lao,
      marshallese: this.clientData.writtenLanguage.marshallese,
      oromo: this.clientData.writtenLanguage.oromo,
      pohnpeian: this.clientData.writtenLanguage.pohnpeian,
      romanian: this.clientData.writtenLanguage.romanian,
      russian: this.clientData.writtenLanguage.russian,
      simplifiedChinese: this.clientData.writtenLanguage.simplifiedChinese,
      somali: this.clientData.writtenLanguage.somali,
      spanish: this.clientData.writtenLanguage.spanish,
      thai: this.clientData.writtenLanguage.thai,
      traditionalChinese: this.clientData.writtenLanguage.traditionalChinese,
      ukrainian: this.clientData.writtenLanguage.ukrainian,
      vietnamese: this.clientData.writtenLanguage.vietnamese,
      other: this.clientData.writtenLanguage.other
    });

    this.genderIdentityForm.patchValue({
      woman: this.clientData.genderIdentity.woman,
      man: this.clientData.genderIdentity.man,
      nonBinary: this.clientData.genderIdentity.nonBinary,
      agender: this.clientData.genderIdentity.agender,
      bigender: this.clientData.genderIdentity.bigender,
      demiboy: this.clientData.genderIdentity.demiboy,
      demigirl: this.clientData.genderIdentity.demigirl,
      genderfluid: this.clientData.genderIdentity.genderfluid,
      genderqueer: this.clientData.genderIdentity.genderqueer,
      questioning: this.clientData.genderIdentity.questioning,
      notListed: this.clientData.genderIdentity.notListed,
      specificToEthnicity: this.clientData.genderIdentity.specificToEthnicity,
      dontKnow: this.clientData.genderIdentity.dontKnow,
      dontUnderstand: this.clientData.genderIdentity.dontUnderstand,
      noAnswer: this.clientData.genderIdentity.noAnswer,
      yesTransgender: this.clientData.genderIdentity.yesTransgender,
      noTransgender: this.clientData.genderIdentity.noTransgender,
      questioningTransgender: this.clientData.genderIdentity.questioningTransgender,
      dontKnowTransgender: this.clientData.genderIdentity.dontKnowTransgender,
      dontUnderstandTransgender: this.clientData.genderIdentity.dontUnderstandTransgender,
      noAnswerTransgender: this.clientData.genderIdentity.noAnswerTransgender
    });

    this.genderForm.patchValue({
      female: this.clientData.gender.female,
      male: this.clientData.gender.male,
      intersex: this.clientData.gender.intersex,
      dontKnow: this.clientData.gender.dontKnow,
      dontUnderstand: this.clientData.gender.dontUnderstand,
      noAnswer: this.clientData.gender.noAnswer,
      notListed: this.clientData.gender.notListed
    });

    this.sexualOrientationForm.patchValue({
      sameGenderLoving: this.clientData.sexualOrientation.sameGenderLoving,
      lesbian: this.clientData.sexualOrientation.lesbian,
      gay: this.clientData.sexualOrientation.gay,
      bisexual: this.clientData.sexualOrientation.bisexual,
      pansexual: this.clientData.sexualOrientation.pansexual,
      straight: this.clientData.sexualOrientation.straight,
      asexual: this.clientData.sexualOrientation.asexual,
      queer: this.clientData.sexualOrientation.queer,
      questioning: this.clientData.sexualOrientation.questioning,
      notListed: this.clientData.sexualOrientation.notListed,
      dontKnow: this.clientData.sexualOrientation.dontKnow,
      dontUnderstand: this.clientData.sexualOrientation.dontUnderstand,
      noAnswer: this.clientData.sexualOrientation.noAnswer
    });

    this.incomeForm.patchValue({
      income0_11000: this.clientData.income.income0_11000,
      income11001_44725: this.clientData.income.income11001_44725,
      income44726_95375: this.clientData.income.income44726_95375,
      income95376_182100: this.clientData.income.income95376_182100,
      income182101_231250: this.clientData.income.income182101_231250,
      income231251_578125: this.clientData.income.income231251_578125,
      income578126More: this.clientData.income.income578126More,
      noAnswer: this.clientData.income.noAnswer
    });

    this.ageForm.patchValue({
      under21: this.clientData.under21,
      age21_24: this.clientData.age21_24,
      age25_29: this.clientData.age25_29,
      age30_34: this.clientData.age30_34,
      age35_39: this.clientData.age35_39,
      age40_44: this.clientData.age40_44,
      age45_49: this.clientData.age45_49,
      age50_54: this.clientData.age50_54,
      age55_59: this.clientData.age55_59,
      age60_64: this.clientData.age60_64,
      age65_69: this.clientData.age65_69,
      age70_74: this.clientData.age70_74,
      age75_79: this.clientData.age75_79,
      age80_84: this.clientData.age80_84,
      age85Older: this.clientData.age85Older,
      noAgeAnswer: this.clientData.noAgeAnswer
    });

    this.countyForm.patchValue({
      baker: this.clientData.county.baker,
      benton: this.clientData.county.benton,
      clackamas: this.clientData.county.clackamas,
      clatsop: this.clientData.county.clatsop,
      columbia: this.clientData.county.columbia,
      coos: this.clientData.county.coos,
      crook: this.clientData.county.crook,
      curry: this.clientData.county.curry,
      deschutes: this.clientData.county.deschutes,
      douglas: this.clientData.county.douglas,
      gilliam: this.clientData.county.gilliam,
      grant: this.clientData.county.grant,
      harney: this.clientData.county.harney,
      hoodRiver: this.clientData.county.hoodRiver,
      jackson: this.clientData.county.jackson,
      jefferson: this.clientData.county.jefferson,
      josephine: this.clientData.county.josephine,
      klamath: this.clientData.county.klamath,
      lake: this.clientData.county.lake,
      lane: this.clientData.county.lane,
      lincoln: this.clientData.county.lincoln,
      linn: this.clientData.county.linn,
      malheur: this.clientData.county.malheur,
      marion: this.clientData.county.marion,
      morrow: this.clientData.county.morrow,
      multnomah: this.clientData.county.multnomah,
      polk: this.clientData.county.polk,
      sherman: this.clientData.county.sherman,
      tillamook: this.clientData.county.tillamook,
      umatilla: this.clientData.county.umatilla,
      union: this.clientData.county.union,
      wallowa: this.clientData.county.wallowa,
      wasco: this.clientData.county.wasco,
      washington: this.clientData.county.washington,
      wheeler: this.clientData.county.wheeler,
      yamhill: this.clientData.county.yamhill,
      otherInsideUS: this.clientData.county.otherInsideUS,
      outsideUS: this.clientData.county.outsideUS,
      noAnswer: this.clientData.county.noAnswer
    });

    this.visitTypeForm.patchValue({
      generalHealth: this.clientData.visitType.generalHealth,
      culturallyOrLinguisticallyResponsiveHealth: this.clientData.visitType.culturallyOrLinguisticallyResponsiveHealth,
      enhancedCreativity: this.clientData.visitType.enhancedCreativity,
      changeOfPerspective: this.clientData.visitType.changeOfPerspective,
      expandedConsciousness: this.clientData.visitType.expandedConsciousness,
      spiritualityReasons: this.clientData.visitType.spiritualityReasons,
      genderIdentityDevelopment: this.clientData.visitType.genderIdentityDevelopment,
      mentalOrPhysicalExhaustion: this.clientData.visitType.mentalOrPhysicalExhaustion,
      chronicPain: this.clientData.visitType.chronicPain,
      brainInjury: this.clientData.visitType.brainInjury,
      endOfLife: this.clientData.visitType.endOfLife,
      substanceUse: this.clientData.visitType.substanceUse,
      anxiety: this.clientData.visitType.anxiety,
      depression: this.clientData.visitType.depression,
      eatingDisorder: this.clientData.visitType.eatingDisorder,
      ptsd: this.clientData.visitType.ptsd,
      mentalHealthDiagnosis: this.clientData.visitType.mentalHealthDiagnosis,
      undiagnosedMentalHealthIssue: this.clientData.visitType.undiagnosedMentalHealthIssue,
      economicDriver: this.clientData.visitType.economicDriver,
      racialTrauma: this.clientData.visitType.racialTrauma,
      genderTrauma: this.clientData.visitType.genderTrauma,
      domesticViolenceTrauma: this.clientData.visitType.domesticViolenceTrauma,
      combatTrauma: this.clientData.visitType.combatTrauma,
      colonizationTrauma: this.clientData.visitType.colonizationTrauma,
      otherTrauma: this.clientData.visitType.otherTrauma,
      otherReasons: this.clientData.visitType.otherReasons,
      dontKnow: this.clientData.visitType.dontKnow,
      noAnswer: this.clientData.visitType.noAnswer
    });

    this.difficultyMoodsForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyMoods.yes,
      no: this.clientData.disabilityStatus.difficultyMoods.no,
      dontKnow: this.clientData.disabilityStatus.difficultyMoods.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyMoods.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyMoods.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyMoods.under21,
      age21_40: this.clientData.disabilityStatus.difficultyMoods.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyMoods.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyMoods.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyMoods.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyMoods.noAgeAnswer,
      dontUnderstand: this.clientData.disabilityStatus.difficultyMoods.dontUnderstand
    });

    this.difficultyErrandsForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyErrands.yes,
      no: this.clientData.disabilityStatus.difficultyErrands.no,
      dontKnow: this.clientData.disabilityStatus.difficultyErrands.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyErrands.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyErrands.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyErrands.under21,
      age21_40: this.clientData.disabilityStatus.difficultyErrands.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyErrands.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyErrands.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyErrands.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyErrands.noAgeAnswer
    });

    this.difficultyCommunicatingForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyCommunicating.yes,
      no: this.clientData.disabilityStatus.difficultyCommunicating.no,
      dontKnow: this.clientData.disabilityStatus.difficultyCommunicating.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyCommunicating.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyCommunicating.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyCommunicating.under21,
      age21_40: this.clientData.disabilityStatus.difficultyCommunicating.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyCommunicating.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyCommunicating.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyCommunicating.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyCommunicating.noAgeAnswer,
      dontUnderstand: this.clientData.disabilityStatus.difficultyCommunicating.dontUnderstand
    });

    this.difficultyLearningForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyLearning.yes,
      no: this.clientData.disabilityStatus.difficultyLearning.no,
      dontKnow: this.clientData.disabilityStatus.difficultyLearning.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyLearning.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyLearning.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyLearning.under21,
      age21_40: this.clientData.disabilityStatus.difficultyLearning.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyLearning.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyLearning.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyLearning.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyLearning.noAgeAnswer
    });

    this.difficultyDressingForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyDressing.yes,
      no: this.clientData.disabilityStatus.difficultyDressing.no,
      dontKnow: this.clientData.disabilityStatus.difficultyDressing.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyDressing.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyDressing.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyDressing.under21,
      age21_40: this.clientData.disabilityStatus.difficultyDressing.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyDressing.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyDressing.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyDressing.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyDressing.noAgeAnswer
    });

    this.difficultyConcentratingForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyConcentrating.yes,
      no: this.clientData.disabilityStatus.difficultyConcentrating.no,
      dontKnow: this.clientData.disabilityStatus.difficultyConcentrating.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyConcentrating.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyConcentrating.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyConcentrating.under21,
      age21_40: this.clientData.disabilityStatus.difficultyConcentrating.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyConcentrating.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyConcentrating.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyConcentrating.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyConcentrating.noAgeAnswer
    });

    this.difficultyWalkingForm.patchValue({
      yes: this.clientData.disabilityStatus.difficultyWalking.yes,
      no: this.clientData.disabilityStatus.difficultyWalking.no,
      dontKnow: this.clientData.disabilityStatus.difficultyWalking.dontKnow,
      noAnswer: this.clientData.disabilityStatus.difficultyWalking.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.difficultyWalking.sinceBirth,
      under21: this.clientData.disabilityStatus.difficultyWalking.under21,
      age21_40: this.clientData.disabilityStatus.difficultyWalking.age21_40,
      age41_60: this.clientData.disabilityStatus.difficultyWalking.age41_60,
      age61_80: this.clientData.disabilityStatus.difficultyWalking.age61_80,
      age81Older: this.clientData.disabilityStatus.difficultyWalking.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.difficultyWalking.noAgeAnswer
    });

    this.blindForm.patchValue({
      yes: this.clientData.disabilityStatus.blind.yes,
      no: this.clientData.disabilityStatus.blind.no,
      dontKnow: this.clientData.disabilityStatus.blind.dontKnow,
      noAnswer: this.clientData.disabilityStatus.blind.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.blind.sinceBirth,
      under21: this.clientData.disabilityStatus.blind.under21,
      age21_40: this.clientData.disabilityStatus.blind.age21_40,
      age41_60: this.clientData.disabilityStatus.blind.age41_60,
      age61_80: this.clientData.disabilityStatus.blind.age61_80,
      age81Older: this.clientData.disabilityStatus.blind.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.blind.noAgeAnswer
    });

    this.deafForm.patchValue({
      yes: this.clientData.disabilityStatus.deaf.yes,
      no: this.clientData.disabilityStatus.deaf.no,
      dontKnow: this.clientData.disabilityStatus.deaf.dontKnow,
      noAnswer: this.clientData.disabilityStatus.deaf.noAnswer,
      sinceBirth: this.clientData.disabilityStatus.deaf.sinceBirth,
      under21: this.clientData.disabilityStatus.deaf.under21,
      age21_40: this.clientData.disabilityStatus.deaf.age21_40,
      age41_60: this.clientData.disabilityStatus.deaf.age41_60,
      age61_80: this.clientData.disabilityStatus.deaf.age61_80,
      age81Older: this.clientData.disabilityStatus.deaf.age81Older,
      noAgeAnswer: this.clientData.disabilityStatus.deaf.noAgeAnswer
    });

    this.disableForms();
  }

  disableForms(): void {
    this.racialIdentityForm.disable();
    this.primaryRacialIdentityForm.disable();
    this.spokenLanguageForm.disable();
    this.writtenLanguageForm.disable();
    this.deafForm.disable();
    this.blindForm.disable();
    this.genderForm.disable();
    this.incomeForm.disable();
    this.visitTypeForm.disable();
    this.countyForm.disable();
    this.ageForm.disable();
    this.sexualOrientationForm.disable();
    this.genderIdentityForm.disable();
    this.difficultyConcentratingForm.disable();
    this.difficultyDressingForm.disable();
    this.difficultyLearningForm.disable();
    this.difficultyCommunicatingForm.disable();
    this.difficultyErrandsForm.disable();
    this.difficultyMoodsForm.disable();
    this.difficultyWalkingForm.disable();
  }

  changeQuestion(index: number) {
    this.currentQuestion = index;
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
