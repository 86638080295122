import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAuditLog } from 'app/models/audit-logging/audit-logging';
import { AuditLoggingClientDataSearch } from 'app/models/audit-logging/audit-logging-client-data-search';
import { AuditLogService } from 'app/services/audit-log/audit-log.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dialog-client-data-audit-log',
  templateUrl: './client-data-audit-log.html',
  providers: [AuditLogService],
})
export class ClientDataAuditLogDialogComponent implements OnInit  {


  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingClientDataSearch = null;
  entityId: string = "";
  licenseId: string = "";
  licenseName: string = "";

  constructor(public sharedService: SharedService,
              private auditLogService: AuditLogService,
              private dialogRef: MatDialogRef<ClientDataAuditLogDialogComponent>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.entityId = data.entityId;
                this.licenseId = data.licenseId;
                this.licenseName = data.licenseName;
              }

              ngOnInit(): void {
                this.loading = true;
                this.auditLogService.initiateClientDataSearch(this.entityId).subscribe(
                  response => {
                    this.search = response;
                    this.loading = false;
                  },
                  error => {
                    console.log(error);
                    this.toastr.error(error);
                    this.loading = false;
                  }
                );
              }

              showLogs() {
                if(!this.search.showRacialIdentity &&
                  !this.search.showPrimaryRacialIdentity &&
                  !this.search.showWrittenLanguage &&
                  !this.search.showSpokenLanguage &&
                  !this.search.showBlind &&
                  !this.search.showDeaf &&
                  !this.search.showDifficultyWalking &&
                  !this.search.showDifficultyConcentrating &&
                  !this.search.showDifficultyDressing &&
                  !this.search.showDifficultyLearning &&
                  !this.search.showDifficultyCommunicating &&
                  !this.search.showDifficultyErrands &&
                  !this.search.showDifficultyMoods &&
                  !this.search.showGenderIdentity &&
                  !this.search.showSexualOrientation &&
                  !this.search.showGender &&
                  !this.search.showIncome &&
                  !this.search.showCounty &&
                  !this.search.showVisitType &&
                  !this.search.showAge &&
                  !this.search.showClientData)
                {
                  this.toastr.error("Select at least one type of log to display");
                  return;
                }

                this.loading = true;
                this.auditLogService.getClientDataAuditLogging(this.search).subscribe(
                  response => {
                    this.auditlogs = response;
                    this.loading = false;
                  },
                  error => {
                    console.log(error);
                    this.toastr.error(error);
                    this.loading = false;
                  }
                );
              }

            }
