import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IBackgroundCheck } from "app/models/licenses/background-check";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class BackgroundCheckService {
  private api: string = environment.privateApi + 'BackgroundCheck/';

  constructor(private http: HttpClient) { }

  createBackgroundCheck(backgroundCheck: IBackgroundCheck): Observable<IBackgroundCheck>{
    return this.http.post<IBackgroundCheck>(this.api + "CreateBackgroundCheck", backgroundCheck);
  }
}
