import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplaintDialogComponent } from './complaint.component';
import { SharedModule } from '@shared';
import { EntityComplaintsComponent } from './entity-complaints/entity-complaints.component';
import { ManageEntityComplaintsComponent } from './manage-entity-complaints/manage-entity-complaints.component';

@NgModule({
  declarations: [
    ComplaintDialogComponent,
    EntityComplaintsComponent,
    ManageEntityComplaintsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [EntityComplaintsComponent, ManageEntityComplaintsComponent]
})
export class ComplaintModule { }
