import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISB303 } from 'app/models/303/sb303';
import { ISB303DeIdentifiedData } from 'app/models/303/sb303DeIdentifiedData';
import { Observable } from 'rxjs';
import { IClientData } from 'app/models/303/clientData';
import { IServiceCenterData } from 'app/models/303/serviceCenterData';
import { INonReportingLicense } from 'app/models/303/non-reporting-license';

@Injectable({providedIn: 'root'})

export class SB303Service {
  private api: string = environment.privateApi + 'SB303/';

  constructor(private http: HttpClient) { }

  getSB303Records(from: string, to: string): Observable<ISB303[]> {
    return this.http.get<ISB303[]>(this.api + 'GetSB303Records/' + from + '/' + to);
  }

  getSB303RecordsById(licenseId: string): Observable<ISB303[]> {
    return this.http.get<ISB303[]>(this.api + 'GetSB303RecordsById/' + licenseId);
  }

  getNonReportingLicense(from: string, to: string): Observable<INonReportingLicense[]> {
    return this.http.get<INonReportingLicense[]>(this.api + 'GetNonReportingLicense/' + from + '/' + to);
  }

  closeReportingPeriod(): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'CloseReportingPeriod');
  }

  //Client Data
  getClientData(id: number): Observable<IClientData> {
    return this.http.get<IClientData>(this.api + 'GetClientData/' + id);
  }

  openClientData(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'OpenClientData/' + id);
  }

  closeClientData(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'CloseClientData/' + id);
  }

  //Service Center Data
  getServiceCenterData(id: number): Observable<IServiceCenterData> {
    return this.http.get<IServiceCenterData>(this.api + 'GetServiceCenterData/' + id);
  }

  openServiceCenterData(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'OpenServiceCenterData/' + id);
  }

  closeServiceCenterData(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.api + 'CloseServiceCenterData/' + id);
  }

 //De-Identified Data
  uploadDeIdentifiedData(file: any, start: string, end: string): Observable<ISB303DeIdentifiedData>{
      return this.http.post<ISB303DeIdentifiedData>(this.api + 'UploadDeIdentifiedData/' + start + '/' + end, file)
  }

  getDeIdentifiedData(): Observable<ISB303DeIdentifiedData[]> {
    return this.http.get<ISB303DeIdentifiedData[]>(this.api + 'GetDeIdentifiedData');
  }

  deleteDeIdentifiedData(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteDeIdentifiedData/' + id);
  }

  downloadDeIdentifiedData(id: number): Observable<Blob> {
    return this.http.get(this.api + 'DownloadDeIdentifiedData/' + id, {
      responseType: 'blob'
    });
  }

  downloadReportingWindowData(from: string, to: string): Observable<Blob> {
    return this.http.get(this.api + 'DownloadReportingWindowData/' + from + '/' + to, {
      responseType: 'blob'
    });
  }

  downloadIndividualServiceCenterData(from: string, to: string, licenseId: string): Observable<Blob> {
    return this.http.get(this.api + 'DownloadIndividualServiceCenterData/' + from + '/' + to + '/' + licenseId, {
      responseType: 'blob'
    });
  }

  createReport(licenseId: string, startDate: string, endDate: string): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CreateReport/' + licenseId + '/' + startDate + '/' + endDate);
  }

}
