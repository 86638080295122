import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IManagerNotes } from "app/models/manager-notes/manager-notes";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})

export class ManagerNotesService {
  private api: string = environment.privateApi + 'ManagerNotes/';

  constructor(private http: HttpClient) { }

  saveNotes(managerNotes: IManagerNotes): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'SaveManagerNotes', managerNotes);
  }
}
