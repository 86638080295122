import { Component, OnInit } from "@angular/core";
import { IDocument } from "app/models/documents/document";
import { IAdminSettingsLink, ILicenseAdminSettings } from "app/models/product-tracking/admin-settings";
import { SharedService } from "app/services/core/shared.service";
import { LicenseDashboardService } from "app/services/licenses/license-dashboard/license-dashboard.service";
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'licensing-home-component',
  templateUrl: './licensing-home.component.html',
  styleUrls: ['./licensing-home.component.scss'],
})
export class LicensingHomeComponent implements OnInit {

  public editing: boolean = false;
  public adminSettings: ILicenseAdminSettings = {
    message: "",
    changeRequestMessage: "",
    headerText: "",
    renewalMessage: "",
    links: [],
    changeRequestLinks: [],
    renewalLinks: [],
    documentLinks: [],
    videoLinks: [],
    sb303Links: [],
    sb303Message: ""
  };

  public newLink: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public newChangeRequestLink: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public newRenewalLink: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public newDocumentLink: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public newSb303Link: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public newVideoLink: IAdminSettingsLink = {
    description: '',
    link: '',
    order: 0,
    section: ''
  }

  public loading: boolean = true;
  constructor(public sharedService: SharedService,
              public authService: AuthService,
              public licenseDashboardService: LicenseDashboardService) { }

  ngOnInit(): void {
    this.licenseDashboardService.getAdminSettings().subscribe(
      response => {
        this.adminSettings = response;
        this.orderLinks();
        this.loading = false;
      },
      error => console.log('error', error)
    );
  }

  edit(): void {
    this.editing = !this.editing;
  }
  orderLinks(): void {
    this.adminSettings.links.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.changeRequestLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.renewalLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.documentLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.videoLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.sb303Links.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
  }

  updateAdminSettings(): void {
    this.licenseDashboardService.updateAdminSettings(this.adminSettings).subscribe({
      error: e => console.log('error', e)
    });
    this.orderLinks();
  }


  addLink(type: string): void {
    if(type == 'help')
    {
      let link: IAdminSettingsLink = {
        description: this.newLink.description,
        link: this.newLink.link,
        order: this.newLink.order,
        section: ''
      }
      this.adminSettings.links.push(link);
      this.newLink.description = '';
      this.newLink.link = '';
    }

    if(type == 'document')
    {
      let link: IAdminSettingsLink = {
        description: this.newDocumentLink.description,
        link: this.newDocumentLink.link,
        order: this.newDocumentLink.order,
        section: this.newDocumentLink.section
      }
      this.adminSettings.documentLinks.push(link);
      this.newDocumentLink.description = '';
      this.newDocumentLink.link = '';
      this.newDocumentLink.section = '';
    }

    if(type == 'sb303')
      {
        let link: IAdminSettingsLink = {
          description: this.newSb303Link.description,
          link: this.newSb303Link.link,
          order: this.newSb303Link.order,
        section: ''
        }
        this.adminSettings.sb303Links.push(link);
        this.newSb303Link.description = '';
        this.newSb303Link.link = '';
      }

    if(type == 'video')
      {
        let link: IAdminSettingsLink = {
          description: this.newVideoLink.description,
          link: this.newVideoLink.link,
          order: this.newVideoLink.order,
        section: ''
        }
        this.adminSettings.videoLinks.push(link);
        this.newVideoLink.description = '';
        this.newVideoLink.link = '';
      }

    if(type == 'changeRequest')
    {
      let link: IAdminSettingsLink = {
        description: this.newChangeRequestLink.description,
        link: this.newChangeRequestLink.link,
        order: this.newChangeRequestLink.order,
        section: ''
      }
      this.adminSettings.changeRequestLinks.push(link);
      this.newChangeRequestLink.description = '';
      this.newChangeRequestLink.link = '';
    }

    if(type == 'renewal')
    {
      let link: IAdminSettingsLink = {
        description: this.newRenewalLink.description,
        link: this.newRenewalLink.link,
        order: this.newRenewalLink.order,
        section: ''
      }
      this.adminSettings.renewalLinks.push(link);
      this.newRenewalLink.description = '';
      this.newRenewalLink.link = '';
    }

    this.updateAdminSettings();
  }

  removeLink(description: string, type: string): void {
    this.sharedService.openConfirm("Remove this link?");
    this.sharedService.confirmed().subscribe(
      response => {
        if(response){
          if(type == 'help')
            this.adminSettings.links = this.adminSettings.links.filter(l => l.description !== description);
          if(type == 'changeRequest')
            this.adminSettings.changeRequestLinks = this.adminSettings.changeRequestLinks.filter(l => l.description !== description);
          if(type == 'renewal')
            this.adminSettings.renewalLinks = this.adminSettings.renewalLinks.filter(l => l.description !== description);
          if(type == 'document')
            this.adminSettings.documentLinks = this.adminSettings.documentLinks.filter(l => l.description !== description);
          if(type == 'sb303')
            this.adminSettings.sb303Links = this.adminSettings.sb303Links.filter(l => l.description !== description);
          if(type == 'video')
            this.adminSettings.videoLinks = this.adminSettings.videoLinks.filter(l => l.description !== description);
          this.licenseDashboardService.updateAdminSettings(this.adminSettings).subscribe({
            error: e => console.log('error', e)
          });
        }
      }
    );
  }
}

