<div fxLayout="row">
  <span fxFlex></span>
  <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
</div>
<br/>
<div fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="15">
      <mat-card>
        <ul navAccordion class="matero-sidemenu" style="width:auto" fxFlex>
          <li navAccordionItem class="matero-sidemenu-item" *ngFor="let question of questions; let index = index" [ngClass]="{'active': currentQuestion === index}">
              <a matRipple (click)="changeQuestion(index)">
                <span>{{question}}</span>
              </a>
            </li>
          </ul>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="85">
      <mat-card fxFlex="100" style="border-radius: 0;">
        <div *ngIf="currentQuestion == 0" fxLayout="column">
          <span><b>1.	Please enter the number of clients who indicated racial or ethnic identities listed below. If there were no responses for a particular field, enter 0.</b></span>
          <form [formGroup]="racialIdentityForm">
            <br/>
            <span><strong>Hispanic and Latino/a/x</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="centralAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">Central American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="mexican" type="number">
              </mat-form-field>
              <span fxFlex="80">Mexican</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="southAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">South American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherHispanic" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Hispanic or Latino/a/x</span>
            </div>
            <br>
            <span><strong>Native Hawaiian and Pacific Islander</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chamoru" type="number">
              </mat-form-field>
              <span fxFlex="80">CHamoru (Chamorro)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="marshallese" type="number">
              </mat-form-field>
              <span fxFlex="80">Marshallese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="micronesian" type="number">
              </mat-form-field>
              <span fxFlex="80">Communities of the Micronesian Region</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="nativeHawaiian" type="number">
              </mat-form-field>
              <span fxFlex="80">Native Hawaiian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="samoan" type="number">
              </mat-form-field>
              <span fxFlex="80">Samoan</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherPacificIslander" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Pacific Islander</span>
            </div>
            <br>
            <span><strong>White</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="easternEuropean" type="number">
              </mat-form-field>
              <span fxFlex="80">Eastern European</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="slavic" type="number">
              </mat-form-field>
              <span fxFlex="80">Slavic</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="westernEuropean" type="number">
              </mat-form-field>
              <span fxFlex="80">Western European</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherWhite" type="number">
              </mat-form-field>
              <span fxFlex="80">Other White</span>
            </div>
            <br>
            <span><strong>American Indian and Alaska Native</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="americanIndian" type="number">
              </mat-form-field>
              <span fxFlex="80">American Indian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="alaskaNative" type="number">
              </mat-form-field>
              <span fxFlex="80">Alaska Native</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="canadianInnuitMetisFirstNation" type="number">
              </mat-form-field>
              <span fxFlex="80">Canadian Innuit, Metis, or First Nation</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="indigenousMexicanCentralAmericanSouthAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">Indigenous Mexican, Central American or South American</span>
            </div>
            <br>
            <span><strong>Black and African American</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="africanAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">African American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="afroCaribbean" type="number">
              </mat-form-field>
              <span fxFlex="80">Afro-Caribbean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="ethiopian" type="number">
              </mat-form-field>
              <span fxFlex="80">Ethiopian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="somali" type="number">
              </mat-form-field>
              <span fxFlex="80">Somali</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherAfrican" type="number">
              </mat-form-field>
              <span fxFlex="80">Other African (Black)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherBlack" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Black</span>
            </div>
            <br>
            <span><strong>Middle Eastern / North African</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="middleEastern" type="number">
              </mat-form-field>
              <span fxFlex="80">Middle Eastern</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="northAfrican" type="number">
              </mat-form-field>
              <span fxFlex="80">North African</span>
            </div>
            <br>
            <span><strong>Asian</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="asianIndian" type="number">
              </mat-form-field>
              <span fxFlex="80">Asian Indian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="cambodian" type="number">
              </mat-form-field>
              <span fxFlex="80">Cambodian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="myanmar" type="number">
              </mat-form-field>
              <span fxFlex="80">Communities of Myanmar</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="filipino" type="number">
              </mat-form-field>
              <span fxFlex="80">Filipino/a</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="hmong" type="number">
              </mat-form-field>
              <span fxFlex="80">Hmong</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="japanese" type="number">
              </mat-form-field>
              <span fxFlex="80">Japanese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="korean" type="number">
              </mat-form-field>
              <span fxFlex="80">Korean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="laotian" type="number">
              </mat-form-field>
              <span fxFlex="80">Laotian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="southAsian" type="number">
              </mat-form-field>
              <span fxFlex="80">South Asian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="vietnamese" type="number">
              </mat-form-field>
              <span fxFlex="80">Vietnamese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherAsian" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Asian</span>
            </div>
            <br>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Other (not listed)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="dontKnowTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't Know</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="refusedTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't want to answer</span>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 1">
          <span><b>2.	Please enter the number of clients who indicated either a primary racial or ethnic identity, a biracial or multiracial identity, or other. If there were no responses for a particular field, enter 0.</b></span>
          <form [formGroup]="primaryRacialIdentityForm">
            <br/>
            <span><strong>Hispanic and Latino/a/x</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="centralAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">Central American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="mexican" type="number">
              </mat-form-field>
              <span fxFlex="80">Mexican</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="southAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">South American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherHispanic" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Hispanic or Latino/a/x</span>
            </div>
          <br>
            <span><strong>Native Hawaiian and Pacific Islander</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chamoru" type="number">
              </mat-form-field>
              <span fxFlex="80">CHamoru (Chamorro)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="marshallese" type="number">
              </mat-form-field>
              <span fxFlex="80">Marshallese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="micronesian" type="number">
              </mat-form-field>
              <span fxFlex="80">Communities of the Micronesian Region</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="nativeHawaiian" type="number">
              </mat-form-field>
              <span fxFlex="80">Native Hawaiian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="samoan" type="number">
              </mat-form-field>
              <span fxFlex="80">Samoan</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherPacificIslander" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Pacific Islander</span>
            </div>
            <br>
            <span><strong>White</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="easternEuropean" type="number">
              </mat-form-field>
              <span fxFlex="80">Eastern European</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="slavic" type="number">
              </mat-form-field>
              <span fxFlex="80">Slavic</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="westernEuropean" type="number">
              </mat-form-field>
              <span fxFlex="80">Western European</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherWhite" type="number">
              </mat-form-field>
              <span fxFlex="80">Other White</span>
            </div>
            <br>
            <span><strong>American Indian and Alaska Native</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="americanIndian" type="number">
              </mat-form-field>
              <span fxFlex="80">American Indian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="alaskaNative" type="number">
              </mat-form-field>
              <span fxFlex="80">Alaska Native</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="canadianInnuitMetisFirstNation" type="number">
              </mat-form-field>
              <span fxFlex="80">Canadian Innuit, Metis, or First Nation</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="indigenousMexicanCentralAmericanSouthAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">Indigenous Mexican, Central American or South American</span>
            </div>
            <br>
            <span><strong>Black and African American</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="africanAmerican" type="number">
              </mat-form-field>
              <span fxFlex="80">African American</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="afroCaribbean" type="number">
              </mat-form-field>
              <span fxFlex="80">Afro-Caribbean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="ethiopian" type="number">
              </mat-form-field>
              <span fxFlex="80">Ethiopian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="somali" type="number">
              </mat-form-field>
              <span fxFlex="80">Somali</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherAfrican" type="number">
              </mat-form-field>
              <span fxFlex="80">Other African (Black)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherBlack" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Black</span>
            </div>
            <br>
            <span><strong>Middle Eastern / North African</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="middleEastern" type="number">
              </mat-form-field>
              <span fxFlex="80">Middle Eastern</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="northAfrican" type="number">
              </mat-form-field>
              <span fxFlex="80">North African</span>
            </div>
            <br>
            <span><strong>Asian</strong></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="asianIndian" type="number">
              </mat-form-field>
              <span fxFlex="80">Asian Indian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="cambodian" type="number">
              </mat-form-field>
              <span fxFlex="80">Cambodian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="myanmar" type="number">
              </mat-form-field>
              <span fxFlex="80">Communities of Myanmar</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="filipino" type="number">
              </mat-form-field>
              <span fxFlex="80">Filipino/a</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="hmong" type="number">
              </mat-form-field>
              <span fxFlex="80">Hmong</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="japanese" type="number">
              </mat-form-field>
              <span fxFlex="80">Japanese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="korean" type="number">
              </mat-form-field>
              <span fxFlex="80">Korean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="laotian" type="number">
              </mat-form-field>
              <span fxFlex="80">Laotian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="southAsian" type="number">
              </mat-form-field>
              <span fxFlex="80">South Asian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="vietnamese" type="number">
              </mat-form-field>
              <span fxFlex="80">Vietnamese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="otherAsian" type="number">
              </mat-form-field>
              <span fxFlex="80">Other Asian</span>
            </div>
            <br>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="multipleIdentities" type="number">
              </mat-form-field>
              <span fxFlex="80">Do not have just one primary racial or ethnic identity</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="biracialMultiRacial" type="number">
              </mat-form-field>
              <span fxFlex="80">No, identify as Biracial or Multiracial</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="na" type="number">
              </mat-form-field>
              <span fxFlex="80">N/A. Only checked one category above</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="dontKnowTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't know</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="refusedTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't want to answer</span>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 2">
          <span><b>3.Number of clients who indicated preferred spoken languages. If there were no responses for a particular field, enter 0.</b></span>
          <form [formGroup]="spokenLanguageForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="arabic" type="number">
              </mat-form-field>
              <span fxFlex="80">Arabic</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="bosnian" type="number">
              </mat-form-field>
              <span fxFlex="80">Bosnian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="burmese" type="number">
              </mat-form-field>
              <span fxFlex="80">Burmese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="cambodian" type="number">
              </mat-form-field>
              <span fxFlex="80">Cambodian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="simplifiedChinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese, Simplified</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="traditionalChinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese, Traditional</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chuukese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chuukese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="english" type="number">
              </mat-form-field>
              <span fxFlex="80">English</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="farsi" type="number">
              </mat-form-field>
              <span fxFlex="80">Farsi</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="french" type="number">
              </mat-form-field>
              <span fxFlex="80">French</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="german" type="number">
              </mat-form-field>
              <span fxFlex="80">German</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="japanese" type="number">
              </mat-form-field>
              <span fxFlex="80">Japanese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="korean" type="number">
              </mat-form-field>
              <span fxFlex="80">Korean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="lao" type="number">
              </mat-form-field>
              <span fxFlex="80">Lao</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="marshallese" type="number">
              </mat-form-field>
              <span fxFlex="80">Marshallese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="oromo" type="number">
              </mat-form-field>
              <span fxFlex="80">Oromo (Cushite)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="pohnpeian" type="number">
              </mat-form-field>
              <span fxFlex="80">Pohnpeian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="romanian" type="number">
              </mat-form-field>
              <span fxFlex="80">Romanian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="russian" type="number">
              </mat-form-field>
              <span fxFlex="80">Russian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="somali" type="number">
              </mat-form-field>
              <span fxFlex="80">Somali</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="spanish" type="number">
              </mat-form-field>
              <span fxFlex="80">Spanish</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="thai" type="number">
              </mat-form-field>
              <span fxFlex="80">Thai</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="ukrainian" type="number">
              </mat-form-field>
              <span fxFlex="80">Ukrainian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="vietnamese" type="number">
              </mat-form-field>
              <span fxFlex="80">Vietnamese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="other" type="number">
              </mat-form-field>
              <span fxFlex="80">Other</span>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 3">
          <span><b>4.Number of clients who indicated preferred written languages. If there were no responses for a particular field, enter 0.</b></span>
          <form [formGroup]="writtenLanguageForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="arabic" type="number">
              </mat-form-field>
              <span fxFlex="80">Arabic</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="bosnian" type="number">
              </mat-form-field>
              <span fxFlex="80">Bosnian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="burmese" type="number">
              </mat-form-field>
              <span fxFlex="80">Burmese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="cambodian" type="number">
              </mat-form-field>
              <span fxFlex="80">Cambodian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="simplifiedChinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese, Simplified</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="traditionalChinese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chinese, Traditional</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="chuukese" type="number">
              </mat-form-field>
              <span fxFlex="80">Chuukese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="english" type="number">
              </mat-form-field>
              <span fxFlex="80">English</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="farsi" type="number">
              </mat-form-field>
              <span fxFlex="80">Farsi</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="french" type="number">
              </mat-form-field>
              <span fxFlex="80">French</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="german" type="number">
              </mat-form-field>
              <span fxFlex="80">German</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="japanese" type="number">
              </mat-form-field>
              <span fxFlex="80">Japanese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="korean" type="number">
              </mat-form-field>
              <span fxFlex="80">Korean</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="lao" type="number">
              </mat-form-field>
              <span fxFlex="80">Lao</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="marshallese" type="number">
              </mat-form-field>
              <span fxFlex="80">Marshallese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="oromo" type="number">
              </mat-form-field>
              <span fxFlex="80">Oromo (Cushite)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="pohnpeian" type="number">
              </mat-form-field>
              <span fxFlex="80">Pohnpeian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="romanian" type="number">
              </mat-form-field>
              <span fxFlex="80">Romanian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="russian" type="number">
              </mat-form-field>
              <span fxFlex="80">Russian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="somali" type="number">
              </mat-form-field>
              <span fxFlex="80">Somali</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="spanish" type="number">
              </mat-form-field>
              <span fxFlex="80">Spanish</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="thai" type="number">
              </mat-form-field>
              <span fxFlex="80">Thai</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="ukrainian" type="number">
              </mat-form-field>
              <span fxFlex="80">Ukrainian</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="vietnamese" type="number">
              </mat-form-field>
              <span fxFlex="80">Vietnamese</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="other" type="number">
              </mat-form-field>
              <span fxFlex="80">Other</span>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 4" fxLayout="column" fxLayoutGap="10px">
          <span><b>5.Clients' Disability Status</b></span>
          <div fxLayout.gt-lg="row"  fxLayout.lt-xl="column" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>a. Number of clients who are <b>deaf</b> or who have <b>serious difficulty hearing</b>.</span>
                  <form [formGroup]="deafForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they are deaf or have serious difficulty hearing.</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they are not deaf or do not have serious difficulty hearing</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>b. Number of clients who are <b>blind</b> or have <b>serious difficulty seeing</b>, even when wearing glasses.</span>
                  <form [formGroup]="blindForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they are blind or have serious difficulty seeing.</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they are not blind or do not have serious difficulty seeing</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
          </div>
          <div fxLayout.gt-lg="row"  fxLayout.lt-xl="column" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>c. Number of clients who have <b>serious difficulty walking or climbing stairs</b>.</span>
                  <form [formGroup]="difficultyWalkingForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have serious difficulty walking or climbing stairs.</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have serious difficulty walking or climbing stairs</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>d. Number of clients who have <b>serious difficulty concentrating, remembering or making decisions</b> due to a physical, mental or emotional condition.</span>
                  <form [formGroup]="difficultyConcentratingForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have serious difficulty concentrating, remembering or making decisions</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have serious difficulty concentrating, remembering or making decisions</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
          </div>
          <div fxLayout.gt-lg="row"  fxLayout.lt-xl="column" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>e. Number of clients who have <b>difficulty dressing or bathing</b>?</span>
                  <form [formGroup]="difficultyDressingForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have difficulty dressing or bathing</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have difficulty dressing or bathing</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>f. Number of clients who have <b>serious difficulty learning how to do things most people their age can learn</b>.</span>
                  <form [formGroup]="difficultyLearningForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have serious difficulty learning how to do things most people their age can learn</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have serious difficulty learning how to do things most people their age can learn</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
          </div>
          <div fxLayout.gt-lg="row"  fxLayout.lt-xl="column" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>g. Number of clients who have <b>serious difficulty communicating</b> (for example understanding or being understood by others) in their usual (customary) language.</span>
                  <form [formGroup]="difficultyCommunicatingForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have serious difficulty communicating</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have serious difficulty communicating</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontUnderstand" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't know what this question is asking</span>
                    </div>
                  </form>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex>
                <mat-card>
                  <span>h. Number of clients who have <b>difficulty doing errands alone</b> such as visiting a doctor's office or shopping, due to a physical, mental or emotional condition.</span>
                  <form [formGroup]="difficultyErrandsForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have difficulty doing errands alone</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have difficulty doing errands alone</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                  </form>
                </mat-card>
            </div>
          </div>
          <div fxLayout.gt-lg="row"  fxLayout.lt-xl="column" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex="50">
                <mat-card>
                  <span>i. Number of clients who have <b>serious difficulty</b> with the following: <b>mood, intense feelings, controlling their behavior, or experiencing delusions or hallucinations</b>.</span>
                  <form [formGroup]="difficultyMoodsForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="yes" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Yes, they have serious difficulty with mood, intense feelings, controlling their behavior, or experiencing delusions or hallucinations</span>
                    </div>
                    <span style="margin-left: 3%"><b>•</b> If yes, at what age did this condition begin?</span>
                    <div style="margin-left: 5%">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="sinceBirth" type="number">
                        </mat-form-field>
                        <span fxFlex="70">Since Birth</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="under21" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Under 21 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age21_40" type="number">
                        </mat-form-field>
                        <span fxFlex="80">21-40 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age41_60" type="number">
                        </mat-form-field>
                        <span fxFlex="80">41-60 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age61_80" type="number">
                        </mat-form-field>
                        <span fxFlex="80">61-80 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="age81Older" type="number">
                        </mat-form-field>
                        <span fxFlex="80"><b>≥</b> 81 Years Old</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-form-field fxFlex="20">
                          <input matInput formControlName="noAgeAnswer" type="number">
                        </mat-form-field>
                        <span fxFlex="80">Don't want to answer</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="no" type="number">
                      </mat-form-field>
                      <span fxFlex="80">No, they do not have serious difficulty with mood, intense feelings, controlling their behavior, or experiencing delusions or hallucinations</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontKnow" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't Know</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="noAnswer" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't want to answer</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field fxFlex="20">
                        <input matInput formControlName="dontUnderstand" type="number">
                      </mat-form-field>
                      <span fxFlex="80">Don't know what this question is asking</span>
                    </div>
                  </form>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50">
            </div>
          </div>
        </div>
        <div *ngIf="currentQuestion == 5">
          <span><b>6.Gender Identity</b></span>
          <form [formGroup]="genderIdentityForm">
            <br/>
            <span style="margin-left:3%">Number of clients who indicated a gender identity listed below:</span>
            <div style="margin-left:5%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="woman" type="number">
                </mat-form-field>
                <span fxFlex="80">Girl or Woman</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="man" type="number">
                </mat-form-field>
                <span fxFlex="80">Boy or Man</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="nonBinary" type="number">
                </mat-form-field>
                <span fxFlex="80">NonBinary</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="agender" type="number">
                </mat-form-field>
                <span fxFlex="80">Agender/No gender</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="bigender" type="number">
                </mat-form-field>
                <span fxFlex="80">Bigender</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="demiboy" type="number">
                </mat-form-field>
                <span fxFlex="80">Demiboy</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="demigirl" type="number">
                </mat-form-field>
                <span fxFlex="80">Demigirl</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="genderfluid" type="number">
                </mat-form-field>
                <span fxFlex="80">Genderfluid</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="genderqueer" type="number">
                </mat-form-field>
                <span fxFlex="80">Genderqueer</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="questioning" type="number">
                </mat-form-field>
                <span fxFlex="80">Questioning/Exploring</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="notListed" type="number">
                </mat-form-field>
                <span fxFlex="80">Not Listed</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="specificToEthnicity" type="number">
                </mat-form-field>
                <span fxFlex="80">I have a gender identity not listed here that is specific to my ethnicity</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontKnow" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontUnderstand" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know what this question is asking</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>
            </div>
            <span style="margin-left:3%">Number of clients who indicated that they are transgender:</span>
            <div style="margin-left:5%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="yesTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">Yes</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">No</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="questioningTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">Questioning/Exploring</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontKnowTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontUnderstandTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know what this question is asking</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswerTransgender" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 6">
          <span><b>7.Number of clients who indicated their sex:</b></span>
          <form [formGroup]="genderForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="female" type="number">
              </mat-form-field>
              <span fxFlex="80">Female</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="male" type="number">
              </mat-form-field>
              <span fxFlex="80">Male</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="intersex" type="number">
              </mat-form-field>
              <span fxFlex="80">Intersex</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="dontKnow" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't know</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="dontUnderstand" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't know what this question is asking</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="noAnswer" type="number">
              </mat-form-field>
              <span fxFlex="80">Don't want to answer</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="notListed" type="number">
              </mat-form-field>
              <span fxFlex="80">Not Listed</span>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 7">
          <span><b>8.Sexual Orientation</b></span>
          <form [formGroup]="sexualOrientationForm">
            <br/>
            <span style="margin-left:3%">Number of clients who indicated their sexual orientation:</span>
            <div style="margin-left:5%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="sameGenderLoving" type="number">
                </mat-form-field>
                <span fxFlex="80">Same-gender Loving</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="lesbian" type="number">
                </mat-form-field>
                <span fxFlex="80">Lesbian</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="gay" type="number">
                </mat-form-field>
                <span fxFlex="80">Gay</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="bisexual" type="number">
                </mat-form-field>
                <span fxFlex="80">Bisexual</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="pansexual" type="number">
                </mat-form-field>
                <span fxFlex="80">Pansexual</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="straight" type="number">
                </mat-form-field>
                <span fxFlex="80">Straight or heterosexual</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="asexual" type="number">
                </mat-form-field>
                <span fxFlex="80">Asexual Spectrum</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="queer" type="number">
                </mat-form-field>
                <span fxFlex="80">Queer</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="questioning" type="number">
                </mat-form-field>
                <span fxFlex="80">Questioning/Exploring</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="notListed" type="number">
                </mat-form-field>
                <span fxFlex="80">Not Listed</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontKnow" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontUnderstand" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't know what this question is asking</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 8">
          <span><b>9.Client annual income</b></span>
          <form [formGroup]="incomeForm">
            <div style="margin-left:3%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income0_11000" type="number">
                </mat-form-field>
                <span fxFlex="80">$0 - $11,000</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income11001_44725" type="number">
                </mat-form-field>
                <span fxFlex="80">$11,001 - $44,725</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income44726_95375" type="number">
                </mat-form-field>
                <span fxFlex="80">$44,726 - $95,375</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income95376_182100" type="number">
                </mat-form-field>
                <span fxFlex="80">$95,376 - $182,100</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income182101_231250" type="number">
                </mat-form-field>
                <span fxFlex="80">$182,101 - $231,250</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income231251_578125" type="number">
                </mat-form-field>
                <span fxFlex="80">$231,251 - $578,125</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="income578126More" type="number">
                </mat-form-field>
                <span fxFlex="80">$578,126+</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 9">
          <span><b>10.Number of clients by age group</b></span>
          <form [formGroup]="ageForm">
            <div style="margin-left: 3%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="under21" type="number">
                </mat-form-field>
                <span fxFlex="80">Under 21 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age21_24" type="number">
                </mat-form-field>
                <span fxFlex="80">21-24 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age25_29" type="number">
                </mat-form-field>
                <span fxFlex="80">25-29 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age30_34" type="number">
                </mat-form-field>
                <span fxFlex="80">30-34 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age35_39" type="number">
                </mat-form-field>
                <span fxFlex="80">35-39 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age40_44" type="number">
                </mat-form-field>
                <span fxFlex="80">40-44 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age45_49" type="number">
                </mat-form-field>
                <span fxFlex="80">45-49 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age50_54" type="number">
                </mat-form-field>
                <span fxFlex="80">50-54 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age55_59" type="number">
                </mat-form-field>
                <span fxFlex="80">55-59 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age60_64" type="number">
                </mat-form-field>
                <span fxFlex="80">60-64 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age65_69" type="number">
                </mat-form-field>
                <span fxFlex="80">65-69 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age70_74" type="number">
                </mat-form-field>
                <span fxFlex="80">70-74 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age75_79" type="number">
                </mat-form-field>
                <span fxFlex="80">75-79 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age80_84" type="number">
                </mat-form-field>
                <span fxFlex="80">80-84 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="age85Older" type="number">
                </mat-form-field>
                <span fxFlex="80"><b>≥</b> 85 Years Old</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAgeAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 10">
          <span><b>11.Number of clients by county of residence</b></span>
          <form [formGroup]="countyForm">
            <div>
              <br/>
              <span><strong>Oregon Counties</strong></span>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="baker" type="number">
                </mat-form-field>
                <span fxFlex="80">Baker</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="benton" type="number">
                </mat-form-field>
                <span fxFlex="80">Benton</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="clackamas" type="number">
                </mat-form-field>
                <span fxFlex="80">Clackamas</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="clatsop" type="number">
                </mat-form-field>
                <span fxFlex="80">Clatsop</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="columbia" type="number">
                </mat-form-field>
                <span fxFlex="80">Columbia</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="coos" type="number">
                </mat-form-field>
                <span fxFlex="80">Coos</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="crook" type="number">
                </mat-form-field>
                <span fxFlex="80">Crook</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="curry" type="number">
                </mat-form-field>
                <span fxFlex="80">Curry</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="deschutes" type="number">
                </mat-form-field>
                <span fxFlex="80">Deschutes</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="douglas" type="number">
                </mat-form-field>
                <span fxFlex="80">Douglas</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="gilliam" type="number">
                </mat-form-field>
                <span fxFlex="80">Gilliam</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="grant" type="number">
                </mat-form-field>
                <span fxFlex="80">Grant</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="harney" type="number">
                </mat-form-field>
                <span fxFlex="80">Harney</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="hoodRiver" type="number">
                </mat-form-field>
                <span fxFlex="80">Hood River</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="jackson" type="number">
                </mat-form-field>
                <span fxFlex="80">Jackson</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="jefferson" type="number">
                </mat-form-field>
                <span fxFlex="80">Jefferson</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="josephine" type="number">
                </mat-form-field>
                <span fxFlex="80">Josephine</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="klamath" type="number">
                </mat-form-field>
                <span fxFlex="80">Klamath</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="lake" type="number">
                </mat-form-field>
                <span fxFlex="80">Lake</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="lane" type="number">
                </mat-form-field>
                <span fxFlex="80">Lane</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="lincoln" type="number">
                </mat-form-field>
                <span fxFlex="80">Lincoln</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="linn" type="number">
                </mat-form-field>
                <span fxFlex="80">Linn</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="malheur" type="number">
                </mat-form-field>
                <span fxFlex="80">Malheur</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="marion" type="number">
                </mat-form-field>
                <span fxFlex="80">Marion</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="morrow" type="number">
                </mat-form-field>
                <span fxFlex="80">Morrow</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="multnomah" type="number">
                </mat-form-field>
                <span fxFlex="80">Multnomah</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="polk" type="number">
                </mat-form-field>
                <span fxFlex="80">Polk</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="sherman" type="number">
                </mat-form-field>
                <span fxFlex="80">Sherman</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="tillamook" type="number">
                </mat-form-field>
                <span fxFlex="80">Tillamook</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="umatilla" type="number">
                </mat-form-field>
                <span fxFlex="80">Umatilla</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="union" type="number">
                </mat-form-field>
                <span fxFlex="80">Union</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="wallowa" type="number">
                </mat-form-field>
                <span fxFlex="80">Wallowa</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="wasco" type="number">
                </mat-form-field>
                <span fxFlex="80">Wasco</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="washington" type="number">
                </mat-form-field>
                <span fxFlex="80">Washington</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="wheeler" type="number">
                </mat-form-field>
                <span fxFlex="80">Wheeler</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="yamhill" type="number">
                </mat-form-field>
                <span fxFlex="80">Yamhill</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="otherInsideUS" type="number">
                </mat-form-field>
                <span fxFlex="80">Other location within the United States of America, U.S Territories or the freely associated states Republic of Marshall Islands, Palau, and the Federated States of Micronesia</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="outsideUS" type="number">
                </mat-form-field>
                <span fxFlex="80">Location outside the United States of America, U.S Territories or the freely associated states Republic of Marshall Islands, Palau, and the Federated States of Micronesia</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">Don't want to answer</span>
              </div>

            </div>
          </form>
        </div>
        <div *ngIf="currentQuestion == 11">
          <span><b>12.Clients' reasons for which they requested psilocybin services.</b></span>
          <form [formGroup]="visitTypeForm">
            <div style="margin-left: 3%">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="generalHealth" type="number">
                </mat-form-field>
                <span fxFlex="80">General health and wellness</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="culturallyOrLinguisticallyResponsiveHealth" type="number">
                </mat-form-field>
                <span fxFlex="80">Access to culturally or linguistically responsive health and wellness options</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="enhancedCreativity" type="number">
                </mat-form-field>
                <span fxFlex="80">Enhanced creativity</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="changeOfPerspective" type="number">
                </mat-form-field>
                <span fxFlex="80">Change of perspective or motivation</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="expandedConsciousness" type="number">
                </mat-form-field>
                <span fxFlex="80">Expanded consciousness</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="spiritualityReasons" type="number">
                </mat-form-field>
                <span fxFlex="80">Spirituality or religious reasons</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="genderIdentityDevelopment" type="number">
                </mat-form-field>
                <span fxFlex="80">Gender identity development</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="mentalOrPhysicalExhaustion" type="number">
                </mat-form-field>
                <span fxFlex="80">Mental or physical exhaustion</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="chronicPain" type="number">
                </mat-form-field>
                <span fxFlex="80">Chronic pain</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="brainInjury" type="number">
                </mat-form-field>
                <span fxFlex="80">Brain injury</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="endOfLife" type="number">
                </mat-form-field>
                <span fxFlex="80">End-of-life psychological distress</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="substanceUse" type="number">
                </mat-form-field>
                <span fxFlex="80">Tobacco, alcohol, or substance use</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="anxiety" type="number">
                </mat-form-field>
                <span fxFlex="80">Anxiety</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="depression" type="number">
                </mat-form-field>
                <span fxFlex="80">Depression</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="eatingDisorder" type="number">
                </mat-form-field>
                <span fxFlex="80">Eating disorder</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="ptsd" type="number">
                </mat-form-field>
                <span fxFlex="80">Post Traumatic Stress Disorder (PTSD)</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="mentalHealthDiagnosis" type="number">
                </mat-form-field>
                <span fxFlex="80">Other Mental health diagnosis</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="undiagnosedMentalHealthIssue" type="number">
                </mat-form-field>
                <span fxFlex="80">Undiagnosed mental or emotional health issues</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="economicDriver" type="number">
                </mat-form-field>
                <span fxFlex="80">Economic drivers of health including effects of short- or long-term poverty, food insecurity, or houselessness</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="racialTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Racial or ethnicity-based trauma</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="genderTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Gender or sexuality-based trauma</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="domesticViolenceTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Trauma related to domestic violence or sexual assault</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="combatTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Trauma related to combat or military service</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="colonizationTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Trauma related to colonization, relocation or displacement</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="otherTrauma" type="number">
                </mat-form-field>
                <span fxFlex="80">Other trauma</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="otherReasons" type="number">
                </mat-form-field>
                <span fxFlex="80">Other reasons not listed here</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="dontKnow" type="number">
                </mat-form-field>
                <span fxFlex="80">I don't know</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field fxFlex="10">
                  <input matInput formControlName="noAnswer" type="number">
                </mat-form-field>
                <span fxFlex="80">I don't want to answer</span>
              </div>
            </div>
          </form>
        </div>

      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
