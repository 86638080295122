<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon class="icon-20" >people</mat-icon> User Administration ({{users.length}})
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" *ngIf="!loading">
        <mat-form-field>
          <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
        </mat-form-field>
        <!-- <span>
          <a (click)="openAddUserDialog()"mat-icon-button aria-label="Add new users" title="Add new users">
              <mat-icon>person_add</mat-icon>
          </a>
        </span> -->
      </div>
      <section *ngIf="loading">
        <h2>Loading...</h2>
        <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading content">
        </mat-progress-bar>
      </section>

      <mat-table
             [dataSource]="dataSource"
             matSort
             #TableUsersSort="matSort"
             aria-describedby="Users"
             class="mat-elevation-z8 demo-table">
        <ng-container matColumnDef="userName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Username</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <span class="mobile-label">Username</span>
            <span [ngStyle]="{'max-width': sharedService.isMobile? '100px': '150px'}"
                  style="word-wrap: break-word; white-space: normal">
              {{user.userName}}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Name</span>
              {{user.fullName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Email</span>
              <span [ngStyle]="{'max-width': sharedService.isMobile? '100px': '150px'}"
                    style="word-wrap: break-word; white-space: normal">
                {{user.email}}
              </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="mobile">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Mobile</span>
              {{user.mobile}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Phone</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Phone</span>
              {{user.phone}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Address</span>
              {{user.address}} {{user.city}} {{user.state}} {{user.zip}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="roles">
            <mat-header-cell *matHeaderCellDef>Roles</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <span class="mobile-label">Roles</span>
                <span *ngIf="user.roles != null && user.roles.length > 0">
                    <span *ngFor="let role of user.roles">
                        {{role}}<br />
                      </span>
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-icon class="icon-20" title="Edit User: {{user.fullName}}" (click)="openEditUserDialog(user)" >edit</mat-icon>
                <span>&nbsp;&nbsp;</span>
                <mat-icon class="icon-20" title="Remove User: {{user.fullName}}" (click)="openRemoveUserDialog(user)" class="text-red-500">delete</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>

      </mat-table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]" #TableUsersPaginator="matPaginator" showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="sharedService.loading || loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
