import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAuditLog } from 'app/models/audit-logging/audit-logging';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';
import { AuditLogService } from 'app/services/audit-log/audit-log.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dialog-audit-log',
  templateUrl: './audit-log.html',
  providers: [AuditLogService],
})
export class AuditLogDialogComponent implements OnInit  {


  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingSearch = null;
  entityId: string = "";
  entityType: string = "";

  constructor(public sharedService: SharedService,
              private auditLogService: AuditLogService,
              private dialogRef: MatDialogRef<AuditLogDialogComponent>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.entityId = data.entityId;
                this.entityType = data.entityType;
              }

  ngOnInit(): void {
    this.loading = true;
    this.auditLogService.initiateSearch(this.entityId, this.entityType).subscribe(
      response => {
        this.search = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  showLogs() {
    if(!this.search.displayApplicants &&
        !this.search.displayDocuments &&
        !this.search.displayLicense && 
        !this.search.displayNotes &&
        !this.search.displayPayments) {
          this.toastr.error("Select at least one type of log to display");
          return;
    }
    
    this.loading = true;
    this.auditLogService.getAuditLogging(this.search).subscribe(
      response => {
        this.auditlogs = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }
}
