<div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
  <div fxLayout="row">
    <mat-card-title>
      Task History
    </mat-card-title>
    <span fxFlex></span>
    <button mat-stroked-button color="warn" type="button" (click)="cancel()">Close</button>
  </div>

  <div fxLayout="row" fxFlex="100">
    <div fxLayout="column" fxFlex="100">
      <mat-table [dataSource]="taskSource" matSort aria-describedby="tasks" #taskSort="matSort" class="mat-elevation-z8 m-b-0" fxFlex="100">
        <ng-container matColumnDef="task">
          <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <span class="mobile-label">Task</span>
            <span *ngIf="task.sendNotice && task.complete && !task.archived">{{task.taskText}} Sent</span>
            <span *ngIf="task.archived">{{task.taskText}} Removed - Not Sent</span>
            <span *ngIf="task.acknowledge && task.complete && !task.archived">{{task.taskText}} Acknowledged</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="teamMember">
          <mat-header-cell *matHeaderCellDef>Team Member</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <span class="mobile-label">Team Member</span>
            {{task.lastUpdatedBy}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateLastUpdated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Modified</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <span class="mobile-label">Date Modified</span>
            <span>{{task.dateLastUpdated}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reason">
          <mat-header-cell *matHeaderCellDef>Reason</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <span class="mobile-label">Reason</span>
            <textarea fxFlex="95" rows="2" matInput [value]="task.reason" disabled></textarea>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="archivedReason">
          <mat-header-cell *matHeaderCellDef>Archived Reason</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <span class="mobile-label">Archived Reason</span>
            <textarea fxFlex="95" rows="2" matInput [value]="task.archivedReason" disabled></textarea>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="taskColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: taskColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25]" #taskPaginator="matPaginator">
      </mat-paginator>
    </div>
  </div>
</div>
