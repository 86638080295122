<mat-dialog-content>
  <section>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
        <div fxLayout="column" class="f-s-20" fxFlex="20">
          <span class="f-s-20"><strong>Section</strong></span>
          <span fxFlex="95" *ngIf="rule.general">General</span>
          <span fxFlex="95" *ngIf="rule.trainingPrograms">Training Programs</span>
          <span fxFlex="95" *ngIf="rule.facilitators">Facilitators</span>
          <span fxFlex="95" *ngIf="rule.manufacturers">Manufacturers</span>
          <span fxFlex="95" *ngIf="rule.serviceCenters">Service Centers</span>
          <span fxFlex="95" *ngIf="rule.testingLabs">Testing Labs</span>
          <span fxFlex="95" *ngIf="rule.workerPermits">Worker Permits</span>
        </div>
        <div fxLayout="column" fxFlex="25">
          <span class="f-s-20"><strong>Sub-Section</strong></span>
          <span class="f-s-20">{{rule.subSection}}</span>
        </div>
        <div fxLayout="column" fxFlex="30">
          <span class="f-s-20"><strong>Segment Number</strong></span>
          <span class="f-s-20">{{ rule.segmentNumber }}</span>
        </div>
        <div fxLayout="column" fxFlex="25">
          <span class="f-s-20"><strong>Segment</strong></span>
          <span class="f-s-20">{{ rule.segment }}</span>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Summary</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <div fxLayout="column">
            <span>{{ rule.summary }}</span>
          </div>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Application</strong></span>
        <span>{{ rule.application }}</span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Service Center Question</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.questionServiceCenter }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Manufacturer Question</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.questionManufacturer }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Laboratory Question</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.questionLaboratory }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Answer</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.answer }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Service Center Guidance</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.guidanceServiceCenter }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Manufacturer Guidance</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.guidanceManufacturer }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Laboratory Guidance</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.guidanceLaboratory }}</span>
        </span>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>If Non-Compliant</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.nonCompliant }}</span>
        </span>
      </div>

      <div fxLayout="column">
        <span class="f-s-20"><strong>Statute</strong></span>
        <span>{{rule.statute}}</span>
      </div>

      <span class="f-s-20" fxFlex><strong>Metric </strong>{{rule.monitorMetric? 'Yes' : 'No'}}</span>

      <div fxLayout="column" fxLayoutGap="8px">
        <span class="f-s-20"><strong>Notes</strong></span>
        <span [ngStyle]="{'max-width': sharedService.isMobile? '250px': '600px'}"
          style="word-wrap: break-word; white-space: normal">
          <span>{{ rule.notes }}</span>
        </span>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="">
        <div fxLayout="column" fxFlex="50">
          <span class="f-s-20"><strong>Historical Cases</strong></span>
          <span *ngFor="let compliance of rule.historicNonCompliances">{{compliance}}</span>
        </div>
        <div fxLayout="column" fxFlex="50">
          <span class="f-s-20"><strong>Investigation Items</strong></span>
          <span *ngFor="let item of rule.investigationItems">{{item.value}}</span>
        </div>
      </div>

      <div>
        <button mat-raised-button type="button" color="warn" (click)="cancel()">Close</button>
      </div>

    </div>
  </section>
</mat-dialog-content>
