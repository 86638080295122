import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-timeout",
  template: ``,
})
export class AppTimeoutComponent {
  timedOut = false;
  public environment = environment;
  lastPing?: Date = null;
  timeoutInterval: number = 10; //logout 60 seconds after warning

  constructor(
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private authService: AuthService,
    private sharedService: SharedService,
    public dialog: MatDialog) {
      //Timeout controls
      if (this.environment.useTimeout) {
        idle.setIdle(this.environment.idleInterval);
        // sets a timeout period  seconds. after X seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(this.timeoutInterval);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
          this.reset();
        });

        idle.onTimeout.subscribe(() => {
          if(this.authService.isAuthenticated()){
            this.dialog.closeAll();
            this.timedOut = true;
            this.router.navigate(["/"]);
            this.authService.logout();
          }
        });

        idle.onIdleStart.subscribe(() => {
          if(this.authService.isAuthenticated()){
            this.dialog.open(TimeoutDialog, {
              minWidth: "350px",
              panelClass: this.sharedService.darkMode ? "theme-dark" : "",
            });
          }
        });

        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);

        this.keepalive.onPing.subscribe(() => {
          this.lastPing = new Date();
        });

        this.reset();
      }
    }

  reset() {
    this.idle.watch();
    this.timedOut = false;
    this.dialog.closeAll();
  }
}

@Component({
  selector: "timeout-dialog",
  templateUrl: "./dialog-timeout.html",
})
export class TimeoutDialog { }
