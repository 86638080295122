import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { ComplaintService } from "app/services/complaints/complaint.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })

export class ComplaintIdValidator{
  constructor(private service: ComplaintService){}

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>
    {
      return this.service.checkLicenseExists(control.value).pipe(
        map(exists => (!exists ? { idNotFound: true} : null )),
        catchError(() => of(null))
      )
    }

}
