import { BehaviorSubject } from 'rxjs';
import { IMenu } from './models/core/menu';

export const menu$ = new BehaviorSubject<IMenu[]>([
    {
      route: "licensing-home",
      name: "TLC Home",
      type: "link",
      icon: "home"
    },
    {
      route: "license-dashboard",
      name: "License Dashboard",
      type: "link",
      icon: "list_alt"
    },
    {
      route: "product-tracking",
      name: "Product Tracking",
      type: "link",
      icon: "lan"
    },
    {
      route: "303-reporting",
      name: "303 Data",
      type: "link",
      icon: "toc"
    },
    {
      route: "active-application-tracking",
      name: "Active Application Tracker",
      type: "link",
      icon: "track_changes"
    },
    {
      route: "compliance-tracking",
      name: "Compliance Tracker",
      type: "link",
      icon: "ballot"
    },
    {
      route: "applicant/search",
      name: "Search Applicants",
      type: "link",
      icon: "search"
    },
    {
      route: "facilitator",
      name: "Facilitator",
      type: "sub",
      icon: "group",
      roles: ['Facilitator'],
      children: [
        {
          route: "assigned",
          name: "Assigned",
          type: "link"
        },
        {
          route: "search",
          name: "Search",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "exam-questions",
          name: "Exam",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        }
      ]
    },
    {
      route: "manufacturer",
      name: "Manufacturer",
      type: "sub",
      icon: "scale",
      roles: ['Manufacturer'],
      children: [
        {
          route: "assigned",
          name: "Assigned",
          type: "link"
        },
        {
          route: "search",
          name: "Search",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        }
      ]
    },
    {
      route: "service-center",
      name: "Service Center",
      type: "sub",
      icon: "store_mall_directory",
      roles: ['ServiceCenter'],
      children: [
        {
          route: "assigned",
          name: "Assigned",
          type: "link"
        },
        {
          route: "search",
          name: "Search",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        }
      ]
    },
    {
      route: "testing-lab",
      name: "Laboratories",
      type: "sub",
      icon: "domain",
      roles: ['TestingLab'],
      children: [
        {
          route: "assigned",
          name: "Assigned",
          type: "link"
        },
        {
          route: "search",
          name: "Search",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        }
      ]
    },
    {
      route: "worker-permit",
      name: "Worker Permits",
      type: "sub",
      icon: "badge",
      roles: ['WorkerPermit'],
      children: [
        {
          route: "assigned",
          name: "Assigned",
          type: "link"
        },
        {
          route: "search",
          name: "Search",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        }
      ]
    },
    {
      route: "training",
      name: "Training Programs",
      type: "sub",
      icon: "description",
      roles: ['Training'],
      children: [
        {
          route: "assigned",
          name: "Assigned Programs",
          type: "link"
        },
        {
          route: "programs",
          name: "Search Programs",
          type: "link"
        },
        {
          route: "tasks",
          name: "Tasks",
          type: "link"
        },
        {
          route: "complaints",
          name: "Complaints",
          type: "link"
        },
        {
          route: "curriculum",
          name: "Manage Curriculum",
          type: "link"
        }
      ]
    },
    {
      route: "rules",
      name: "Manage Compliance",
      type: "link",
      icon: "menu_book",
      roles: ['Admin']
    },
    {
      route: "messages",
      name: "Messages",
      type: "link",
      icon: "mail"
    },
    {
      route: "reports",
      name: "Reports",
      type: "link",
      icon: "bar_chart"
    },
    {
      route: "user-management",
      name: "User Management",
      type: "link",
      icon: "manage_accounts",
      roles: ['Admin']
    }
  ]);
