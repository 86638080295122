import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { EmailReasonDialog } from 'app/components/tasks/dialog-email-reason';
import { ISimpleLicense } from 'app/models/licenses/simple-license';
import { ITask } from 'app/models/tasks/task';
import { IStaff } from 'app/models/user-info/staff';
import { SharedService } from 'app/services/core/shared.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './manufacturer-tasks.component.html',
  styleUrls: ['./manufacturer-tasks.component.scss']
})
export class ManufacturerTasksComponent implements OnInit {

  public loading: boolean = false;
  public loadingStaff: boolean = false;
  public tasks: ISimpleLicense[] = [];
  public licenseStaff: IStaff[] = [];
  public renewal: string = "Renewal Submitted";
  public newSubmission: string = "New Submission";
  public resubmission: string = "Incomplete Program Resubmission";
  public incompleteNotice: string = "Send Incomplete Program Notice";
  public insufficientNotice: string = "Send Insufficient Program Notice";
  public approvedNotice: string = "Send Approved Notice";
  public closedNotice: string = "Send Closed Notice"
  public nonPaymentNotice: string = "Send 45 Day Non-Payment Notice";
  public nonPaymentElapsedNotice: string = "Unpaid After 90 Days";
  public firstWarning: string = "Send 30 Day Notice";
  public secondWarning: string = "Send 7 Day Notice";
  public updatedCurriculum: string = "Curriculum Update Request";

  public taskColumns: string[] = ['entityName', 'licenseId', 'tasks', 'status', 'assignedTo', 'assignedToCompliance', 'claim', 'actions'];
  public taskSource = new MatTableDataSource<ISimpleLicense>(this.tasks);
  @ViewChild('taskPaginator', {static: false}) taskPaginator: MatPaginator;
  @ViewChild('taskSort', {static: false}) taskSort: MatSort;

  constructor(public licenseService: ManufacturerService,
              public sharedService: SharedService,
              public router: Router,
              private dialog: MatDialog,
              public authService: AuthService,
              private toastr: ToastrService) {
              }

  ngOnInit(): void {
    this.getTrainingStaff();
    this.getTasks();
  }

  getTasks(): void{
    this.loading = true;
    this.licenseService.getLicenseTasks().subscribe(
      response => {
        this.tasks = response;
        this.updateTaskTable();
        this.loading = false;
      },
      error => {
        console.log('error', error);
        this.loading = false;
      }
    );
  }

  updateTaskTable(): void{
    this.taskSource.data = this.tasks;
    this.taskSource.sort = this.taskSort;
    this.taskSource.paginator = this.taskPaginator;
  }

  getTrainingStaff(): void{
    this.loadingStaff = true;
    this.licenseService.getLicenseStaff().subscribe(
      response => {
        this.licenseStaff = response;
        this.loadingStaff = false;
      },
      error => {
        console.log('error', error);
        this.loadingStaff = false;
      }
    );
  }

  filterTable(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.taskSource.filter = filter;
  }

  acknowledgeTask(taskId: number): void {
    this.sharedService.openConfirm("Acknowledge and Clear Task?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.acknowledgeTask(taskId).subscribe(
            () => {
              let index = this.tasks.findIndex(t => t.tasks.some(task => task.id === taskId));
              this.tasks[index].tasks = this.tasks[index].tasks.filter(task => task.id !== taskId);
              if(this.tasks[index].tasks.length === 0){
                this.tasks = this.tasks.filter(task => task.id !== this.tasks[index].id);
              }
              this.taskSource.data = this.tasks;
              this.toastr.success('Task Acknowledged!');
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  sendTaskNotice(task: ITask): void {
    this.sharedService.openConfirm("Send Task Notice to Applicant?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.sendTaskNotice(task).subscribe(
            () => {
              let index = this.tasks.findIndex(t => t.tasks.some(t => t.id === task.id));
              this.tasks[index].tasks = this.tasks[index].tasks.filter(t => t.id !== task.id);
              if(this.tasks[index].tasks.length === 0){
                this.tasks = this.tasks.filter(task => task.id !== this.tasks[index].id);
              }
              this.taskSource.data = this.tasks;
              this.toastr.success('Notice Sent!');
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  sendTaskWithReason(task: ITask): void {
    let reason: string = 'empty';
    let sendNotice: boolean = true;
    if(task.requiresReason){
      const dialogRef = this.dialog.open(EmailReasonDialog, {
        minWidth: !this.sharedService.mobile? '400px' : '300px',
        maxWidth: !this.sharedService.mobile? '700px' : '300px',
        maxHeight: !this.sharedService.mobile? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (!this.sharedService.isCancelled(response)) {
          reason = response;
        }
        else{
          sendNotice = false;
        }
      });
    }
    if(sendNotice){
      task.reason = reason;
      this.licenseService.sendTaskNotice(task).subscribe(
        () => {
          let index = this.tasks.findIndex(t => t.tasks.some(t => t.id === task.id));
          this.tasks[index].tasks = this.tasks[index].tasks.filter(t => t.id !== task.id);
          if(this.tasks[index].tasks.length === 0){
            this.tasks = this.tasks.filter(task => task.id !== this.tasks[index].id);
          }
          this.taskSource.data = this.tasks;
          this.toastr.success('Notice Sent!');
        },
        error => console.log('error', error)
      );
    }
  }

  assignTask(event: MatSelectChange, id: number): void {
    let user: IStaff = this.licenseStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      this.releaseProgram(id, null);
    }
    else {
      this.licenseService.assignLicense(user, id).subscribe(
      () =>{
        this.tasks.filter(t => t.id === id).forEach(task => {
          task.assignedTo = user.name;
        });
        this.updateTaskTable();
        if(event.value.length == 0) {
          this.toastr.success("Manufacturer unassigned" );
        }
        else {
          this.toastr.success("Manufacturer assigned to: " + event.value);
        }
      },
      error => {
        this.tasks.find(t => t.id == id).assignedTo = '';
        this.updateTaskTable();
        console.log('error', error);
      }
    );
    }
  }

  claimProgram(id: number): void {
    this.licenseService.claimLicense(id).subscribe(
      () => this.router.navigateByUrl("/manufacturer/assigned/" + id),
      error => {
        console.log('error', error);
      }
    );
  }

  releaseProgram(id: number, e:Event): void {
    if(e != null) {
      e.preventDefault();
    }
    this.licenseService.releaseLicense(id).subscribe(
      () => {
        this.tasks.find(t => t.id == id).assignedTo = '';
        this.toastr.success("Manufacturer Released");
        this.updateTaskTable();
      },
      error => {
        console.log('error', error);
      }
    );
  }

  assignComplianceMember(event: MatSelectChange, licenseId: number): void {
    let user: IStaff = this.licenseStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: '',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.licenseService.assignComplianceMember(user, licenseId).subscribe(
      () => {
        this.tasks.filter(t => t.id === licenseId).forEach(task => {
          task.assignedToCompliance = user.name;
        });
        this.updateTaskTable();
        if(event.value.length == 0) {
          this.toastr.success("Manufacturer compliance member unassigned" );
        }
        else {
          this.toastr.success("Manufacturer compliance assigned to: " + event.value);
        }
      },
      error => {
        this.tasks.find(t => t.id == licenseId).assignedToCompliance = '';
        this.updateTaskTable();
        console.log('error', error);
      }
    );
  }
}
