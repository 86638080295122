import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IComplaint } from 'app/models/complaints/complaint';
import { Observable } from 'rxjs';
import { ITrainingProgramSimple } from 'app/models/training/training-program-simple';
import { IStaff } from 'app/models/user-info/staff';

@Injectable({providedIn: 'root'})

export class ComplaintService {
  private api: string = environment.privateApi + 'Complaint/';

  constructor(private http: HttpClient) { }

  getStaff(): Observable<IStaff[]>{
    return this.http.get<IStaff[]>(this.api + "GetAllUsers");
  }

  saveComplaint(complaint: IComplaint): Observable<IComplaint> {
    return this.http.post<IComplaint>(this.api + 'SaveComplaint', complaint);
  }

  getTrainingProgramComplaints(programId: string): Observable<IComplaint[]> {
    return this.http.get<IComplaint[]>(this.api + 'GetTrainingProgramComplaints/' + programId);
  }

  getTrainingPrograms(): Observable<ITrainingProgramSimple[]>{
    return this.http.get<ITrainingProgramSimple[]>(this.api + 'GetTrainingPrograms');
  }

  getAllTrainingProgramComplaints(): Observable<IComplaint[]> {
    return this.http.get<IComplaint[]>(this.api + 'GetAllTrainingProgramComplaints');
  }

  getComplaints(entityId: string): Observable<IComplaint[]>{
    return this.http.get<IComplaint[]>(this.api + 'GetComplaints/' + entityId);
  }

  getComplaintsByType(entityType: string): Observable<IComplaint[]>{
    return this.http.get<IComplaint[]>(this.api + 'GetComplaintsByType/' + entityType);
  }

  checkLicenseExists(licenseId: string): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckLicenseExists/' + licenseId);
  }
}
