import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IPayment } from "../../models/payment";
import { PaymentService } from "../../services/payments.service";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
    selector: 'dialog-mark-paid',
    templateUrl: './mark-paid.html',
    providers: [PaymentService, { provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  })
  export class DialogMarkPaidComponent implements OnInit  {
    public processing: boolean = false;
    public today: Date = new Date(Date.now());
    public paymentForm = new UntypedFormGroup({
      paymentCleared: new UntypedFormControl("", Validators.required)
    });

    constructor(public paymentService: PaymentService,
                private toastr: ToastrService,
                private dialogRef: MatDialogRef<DialogMarkPaidComponent>,
                @Inject(MAT_DIALOG_DATA) public payment: IPayment) {}

    ngOnInit(): void {
      this.setForm();
    }

    setForm(): void {
      this.paymentForm.patchValue({
        paymentCleared:  this.payment.paymentCleared != null ? new Date(this.payment.paymentCleared) : new Date()
      });
    }

    savePayment() {
      if(this.paymentForm.valid) {
        let form = this.paymentForm.value;
        this.processing = true;
        this.payment.paid = true;
        this.payment.status = "Cleared"
        this.payment.paymentCleared = form.paymentCleared;

        this.paymentService.savePayment(this.payment).subscribe(
          response => {
            this.processing = false;
            this.toastr.success("Payment Saved" );
            this.dialogRef.close(response);
          },
          error => {
            console.error('error: ' + error.statusText);
            this.processing = false;
            this.toastr.error("Unable to save payment");
          }
        );
      }
    }

    cancel() {
      this.dialogRef.close('cancel');
    }

  }
