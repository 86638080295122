<div fxLayout="column" *ngIf="applicant.id === 0">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
    <span class="f-s-18"><strong>Entity Applicants</strong></span>
    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter Applicants" />
    </mat-form-field>
    <span fxFlex></span>
    <span *ngIf="editingLicense">Click to Edit</span>
    <button *ngIf="editingLicense"
            mat-icon-button
            type="button"
            color="primary"
            (click)="addApplicant()"
            aria-label="Add Applicant"
            matTooltip="Add Applicant"
            matTooltipPosition="below"
            [matTooltipShowDelay]="500">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
    class="mat-elevation-z8 p-t-0">
    <ng-container matColumnDef="entityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Entity</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Entity</span>
        {{ applicant.entityName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="primaryContact">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Contact</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Contact</span>
        {{ applicant.primaryContactName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
        <button *ngIf="editingLicense && authService.isAdmin" mat-icon-button (click)="deleteApplicant(applicant)" color="warn" aria-label="Delete Applicant">
          <mat-icon>delete</mat-icon>
        </button>
        <button *ngIf="editingLicense && !applicant.archived" mat-icon-button (click)="archiveApplicant(applicant, true)" color="warn" aria-label="Archive Applicant">
          <mat-icon>archive</mat-icon>
        </button>
        <button *ngIf="editingLicense && applicant.archived" mat-icon-button (click)="archiveApplicant(applicant, false)" color="primary" aria-label="Archive Applicant">
          <mat-icon>unarchive</mat-icon>
        </button>
        <span *ngIf="!editingLicense && applicant.archived"><b>Archived</b></span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns" [ngClass]="{'archived': row.archived }"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
  </mat-paginator>
</div>

<mat-card [style.background-color]="cardColor" *ngIf="applicant.id > 0">
  <mat-card-title>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>{{addingApplicant? 'Add An Entity Applicant' : 'Editing ' + applicant.entityName}}</span>
      <span *ngIf="applicant.archived"><b>Archived</b></span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="cancel()">{{addingApplicant? 'Cancel' : 'Close'}}</button>
      <button [disabled]="!applicantForm.valid" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(false)">Save</button>
      <button [disabled]="!applicantForm.valid" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(true)">Save and Close</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <!--Applicant Form-->
    <form [formGroup]="applicantForm">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Contact Information-->
          <mat-card fxFlex="50" fxFlex.lt-md="100">
            <mat-card-title>
              Contact Information
            </mat-card-title>
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">

                <span class="f-s-16"><strong>Legal Entity Name</strong></span>
                <span>{{applicant.entityName}}</span>

                <span class="f-s-16"><strong>Registered Business</strong></span>
                <div fxLayout="row">
                  <mat-checkbox [checked]="applicant.businessInOregon" disabled>
                    <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                  </mat-checkbox>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicant.businessInOregon" [@slideInOut]>
                  <div fxLayout="column">
                    <span>Oregon Secretary of State Registry Number</span>
                    <span>{{applicant.secretaryofStateNumber}}</span>
                  </div>
                </div>

                <div fxLayout="row">
                  <mat-checkbox [checked]="applicant.registeredBusiness" disabled>
                    <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                  </mat-checkbox>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicant.registeredBusiness" [@slideInOut]>
                  <div fxLayout="column">
                    <span>Location Registered</span>
                    <span>{{applicant.registeredIn}}</span>
                  </div>
                  <div fxLayout="column">
                    <span>Registration Number</span>
                    <span>{{applicant.registryNumber}}</span>
                  </div>
                </div>

                <span class="f-s-16">
                  <strong>Registration Attestation</strong>
                </span>
                <mat-checkbox [checked]="applicant.registrationAttestation" disabled>
                  Applicant attests to registration requirements
                </mat-checkbox>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                  <mat-checkbox [checked]="applicant.previousLicense" disabled [ngClass]="{'updated-field': isFieldUpdated('PreviousLicense', 'EntityApplicants', applicant.id.toString())}">
                    <span class="text-wrap">
                      Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?
                    </span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Oregon Psilocybin license ids</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('AffiliatedLicenses', 'EntityApplicants', applicant.id.toString())}">{{applicant.affiliatedLicenses}}</span>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" *ngIf="renewal">
                  <div fxLayout="column" fxLayoutGap="8px">
                    <span *ngIf="applicantForm.get('applicantPageCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'EntityApplicants', applicant.id.toString())}">Applicant attests this information to be current and valid</strong></span>
                    <span *ngIf="!applicantForm.get('applicantPageCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'EntityApplicants', applicant.id.toString())}">Applicant does not attest this information to be current and valid</strong></span>
                    <textarea *ngIf="!applicantForm.get('applicantPageCorrect').value" matInput placeholder="Financial changes" cols="2" [value]="applicant.applicantPageCorrectReason" [readonly]="true" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrectReason', 'EntityApplicants', applicant.id.toString())}"></textarea>
                  </div>
                </div>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column">
                <span class="f-s-16"><strong>Legal Entity Name</strong></span>
                <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                  <input matInput placeholder="Legal Entity Name" formControlName="entityName">
                  <mat-error *ngIf="applicantForm.get('entityName').invalid">
                    Please provide the legal entity name
                  </mat-error>
                </mat-form-field>

                <span class="f-s-16"><strong>Registered Business</strong></span>
                <div fxLayout="row">
                  <mat-checkbox formControlName="businessInOregon">
                    <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                  </mat-checkbox>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicantForm.get('businessInOregon').value" [@slideInOut]>
                  <div fxLayout="column">
                    <span>Oregon Secretary of State Registry Number</span>
                    <mat-form-field>
                      <input matInput placeholder="Secretary of State Number" formControlName="secretaryofStateNumber">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row">
                  <mat-checkbox formControlName="registeredBusiness">
                    <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                  </mat-checkbox>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicantForm.get('registeredBusiness').value" [@slideInOut]>
                  <div fxLayout="column">
                    <span>Location Registered</span>
                    <mat-form-field>
                      <input matInput placeholder="Location Registered" formControlName="registeredIn">
                    </mat-form-field>
                    <span>Registration Number</span>
                    <mat-form-field>
                      <input matInput placeholder="Registration Number" formControlName="registryNumber">
                    </mat-form-field>
                  </div>
                </div>

                <span class="f-s-16">
                  <strong>Registration Attestation</strong>
                </span>
                <mat-checkbox formControlName="registrationAttestation">
                  Applicant attests to registration requirements
                </mat-checkbox>

                <div fxLayout="column" fxLayoutGap="12px">
                  <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                  <mat-checkbox formControlName="previousLicense" [ngClass]="{'updated-field': isFieldUpdated('PreviousLicense', 'EntityApplicants', applicant.id.toString())}">
                    <span class="text-wrap">
                      Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?
                    </span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Please provide the license ids for each Oregon Psilocybin license</span>
                    <mat-form-field>
                      <input matInput placeholder="Affiliated Licenses" formControlName="affiliatedLicenses" [ngClass]="{'updated-field': isFieldUpdated('AffiliatedLicenses', 'EntityApplicants', applicant.id.toString())}"
                        (blur)="validatePreviousLicense()">
                      <mat-error *ngIf="validatePreviousLicense()">
                        Please provide all license numbers
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <mat-checkbox formControlName="applicantPageCorrect" *ngIf="renewal" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'EntityApplicants', applicant.id.toString())}">
                    Applicant attests this information to be current and valid
                  </mat-checkbox>
                  <span *ngIf="!applicantForm.get('applicantPageCorrect').value && renewal">Changes</span>
                  <textarea *ngIf="!applicantForm.get('applicantPageCorrect').value && renewal" matInput placeholder="Applicant Changes" cols="2" formControlName="applicantPageCorrectReason" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrectReason', 'EntityApplicants', applicant.id.toString())}"></textarea>

                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="50" fxFlex.lt-md="100">
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Contact Phone</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PrimaryContactNumber', 'EntityApplicants', applicant.id.toString())}">{{applicant.primaryContactNumber | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Email Address</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PrimaryContactEmail', 'EntityApplicants', applicant.id.toString())}">{{applicant.primaryContactEmail}}</span>

                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Preferred Language</strong></span>
                    <span>{{applicant.language}}</span>
                  </div>
                </div>

              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Contact Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Phone" formControlName="primaryContactNumber" [ngClass]="{'updated-field': isFieldUpdated('PrimaryContactNumber', 'EntityApplicants', applicant.id.toString())}"
                        mask="(000) 000-0000 000000" [validation]="false">
                      <mat-error *ngIf="applicantForm.get('primaryContactNumber').invalid">
                        A name is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Email" formControlName="primaryContactEmail" [ngClass]="{'updated-field': isFieldUpdated('PrimaryContactEmail', 'EntityApplicants', applicant.id.toString())}">
                      <mat-error *ngIf="applicantForm.get('primaryContactEmail').hasError('required')">
                        Please enter an email adress
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('primaryContactEmail').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Confirm Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                      <mat-error *ngIf="validateEmail()">
                        Email does not match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <span class="f-s-16"><strong>Preferred Language</strong></span>
                <div>
                  <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                    <input matInput placeholder="Language" formControlName="language">
                  </mat-form-field>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <!--Addresses-->
          <mat-card [fxFlex]="editingLicense? 60 : 40" fxFlex.lt-md="100">
            <mat-card-title>Addresses</mat-card-title>
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column">
                  <span><strong>Headquarters Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <span [ngClass]="{'updated-field': isFieldUpdated('HeadquartersStreet', 'EntityApplicants', applicant.id.toString())}">{{applicant.headquartersStreet }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('HeadquartersCity', 'EntityApplicants', applicant.id.toString())}">{{applicant.headquartersCity }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('HeadquartersState', 'EntityApplicants', applicant.id.toString())}">{{applicant.headquartersState }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('HeadquartersZip', 'EntityApplicants', applicant.id.toString())}">{{applicant.headquartersZip }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('HeadquartersCounty', 'EntityApplicants', applicant.id.toString())}">{{applicant.headquartersCounty }}</span>
                  </div>
                </div>

                <div fxLayout="column">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                    <span><strong>Physical Address</strong></span>
                    <mat-checkbox [checked]="applicant.physicalIsHeadquarters" disabled [ngClass]="{'updated-field': isFieldUpdated('PhysicalIsHeadquarters', 'EntityApplicants', applicant.id.toString())}">
                      Same as Headquarters
                    </mat-checkbox>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalStreet', 'EntityApplicants', applicant.id.toString())}">{{applicant.physicalStreet }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalCity', 'EntityApplicants', applicant.id.toString())}">{{applicant.physicalCity }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalState', 'EntityApplicants', applicant.id.toString())}">{{applicant.physicalState }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalZip', 'EntityApplicants', applicant.id.toString())}">{{applicant.physicalZip }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalCounty', 'EntityApplicants', applicant.id.toString())}">{{applicant.physicalCounty }}</span>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox [checked]="applicant.mailingIsPhysical" disabled [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'EntityApplicants', applicant.id.toString())}">
                      Same as physical
                    </mat-checkbox>
                    <mat-checkbox [checked]="applicant.mailingIsHeadquarters" disabled [ngClass]="{'updated-field': isFieldUpdated('MailingIsHeadquarters', 'EntityApplicants', applicant.id.toString())}">
                      Same as Headquarters
                    </mat-checkbox>
                  </div>
                  <div fxLayout="row" fxLayoutGap="16px" [@slideInOut] *ngIf="!applicant.mailingIsPhysical">
                    <span [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'EntityApplicants', applicant.id.toString())}">{{applicant.mailingStreet }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'EntityApplicants', applicant.id.toString())}">{{applicant.mailingCity }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'EntityApplicants', applicant.id.toString())}">{{applicant.mailingState }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'EntityApplicants', applicant.id.toString())}">{{applicant.mailingZip }}</span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'EntityApplicants', applicant.id.toString())}">{{applicant.mailingCounty }}</span>
                  </div>
                </div>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column">
                <span class="f-s-18"><strong>Headquarters Address</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput placeholder="Street Apt/Unit/Suite" formControlName="headquartersStreet" [ngClass]="{'updated-field': isFieldUpdated('HeadquartersStreet', 'EntityApplicants', applicant.id.toString())}">
                    <mat-error *ngIf="applicantForm.get('headquartersStreet').invalid">
                      Please provide a street
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput placeholder="City" formControlName="headquartersCity" [ngClass]="{'updated-field': isFieldUpdated('HeadquartersCity', 'EntityApplicants', applicant.id.toString())}">
                    <mat-error *ngIf="applicantForm.get('headquartersCity').invalid">
                      Please provide a city
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <mat-label>State/US territory/freely associated state</mat-label>
                    <mat-select formControlName="headquartersState" [ngClass]="{'updated-field': isFieldUpdated('HeadquartersState', 'EntityApplicants', applicant.id.toString())}">
                      <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="applicantForm.get('headquartersState').invalid">
                      Please select a state
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput placeholder="Zip" formControlName="headquartersZip" [ngClass]="{'updated-field': isFieldUpdated('HeadquartersZip', 'EntityApplicants', applicant.id.toString())}">
                    <mat-error *ngIf="applicantForm.get('headquartersZip').hasError('required')">
                      Please provide a zipcode
                    </mat-error>
                    <mat-error *ngIf="applicantForm.get('headquartersZip').hasError('pattern')">
                      Zipcode should match this format: 99999 or 99999-1234
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                    *ngIf="applicantForm.get('headquartersState').value === 'OR'">
                    <mat-label>County</mat-label>
                    <mat-select formControlName="headquartersCounty" [ngClass]="{'updated-field': isFieldUpdated('HeadquartersCounty', 'EntityApplicants', applicant.id.toString())}">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                  <span class="f-s-18"><strong>Physical Address</strong></span>
                  <mat-checkbox formControlName="physicalIsHeadquarters" (change)="physicalIsSame()" [ngClass]="{'updated-field': isFieldUpdated('PhysicalIsHeadquarters', 'EntityApplicants', applicant.id.toString())}">
                    Same as Headquarters
                  </mat-checkbox>
                </div>
                <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('physicalIsHeadquarters').value">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet" [ngClass]="{'updated-field': isFieldUpdated('PhysicalStreet', 'EntityApplicants', applicant.id.toString())}"
                        (blur)="validatePhysicalStreet()">
                      <mat-error *ngIf="validatePhysicalStreet()">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="physicalCity" (blur)="validatePhysicalCity()" [ngClass]="{'updated-field': isFieldUpdated('PhysicalCity', 'EntityApplicants', applicant.id.toString())}">
                      <mat-error *ngIf="validatePhysicalCity()">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState" (blur)="validatePhysicalState()" [ngClass]="{'updated-field': isFieldUpdated('PhysicalState', 'EntityApplicants', applicant.id.toString())}">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="validatePhysicalState()">
                        Please provide a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="physicalZip" (blur)="validatePhysicalZip()" [ngClass]="{'updated-field': isFieldUpdated('PhysicalZip', 'EntityApplicants', applicant.id.toString())}">
                      <mat-error *ngIf="validatePhysicalZip()">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="physicalCounty" [ngClass]="{'updated-field': isFieldUpdated('PhysicalCounty', 'EntityApplicants', applicant.id.toString())}">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <span class="f-s-18"><strong>Mailing Address</strong></span>
                  <mat-checkbox formControlName="mailingIsHeadquarters" (change)="mailingIsSame()" [ngClass]="{'updated-field': isFieldUpdated('MailingIsHeadquarters', 'EntityApplicants', applicant.id.toString())}"
                    *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    Same as Headquarters
                  </mat-checkbox>
                  <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()" [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'EntityApplicants', applicant.id.toString())}"
                    *ngIf="!applicantForm.get('mailingIsHeadquarters').value">
                    Same as Physical
                  </mat-checkbox>
                </div>

                <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsHeadquarters').value &&
                                                        !applicantForm.get('mailingIsPhysical').value">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet" [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'EntityApplicants', applicant.id.toString())}"
                        (blur)="validateMailingStreet()">
                      <mat-error *ngIf="validateMailingStreet()">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()" [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'EntityApplicants', applicant.id.toString())}">
                      <mat-error *ngIf="validateMailingCity()">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="mailingState" (blur)="validateMailingState()" [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'EntityApplicants', applicant.id.toString())}">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="validateMailingState()">
                        Please provide a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()" [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'EntityApplicants', applicant.id.toString())}">
                      <mat-error *ngIf="validateMailingZip()">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('mailingState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="mailingCounty"  [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'EntityApplicants', applicant.id.toString())}">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>

            </mat-card-content>
          </mat-card>
          <!--Individual Applicants-->
          <mat-card [fxFlex]="editingLicense? 40 : 60" fxFlex.lt-md="100">
            <mat-card-title>
              Individual Applicants
            </mat-card-title>
            <mat-card-content>
              <div [fxLayout]="editingLicense? 'column' : 'row'" fxLayoutGap="8px">

                <div fxLayout="column" [fxFlex]="editingLicense? 100 : 50">
                  <span>Available Applicants</span>
                  <mat-table [dataSource]="applicantSource" matSort #applicantSort="matSort" aria-describedby="applicants"
                    class="mat-elevation-z8 p-t-0">
                    <ng-container matColumnDef="firstName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
                      <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                        <span class="mobile-label">First</span>
                        {{ applicant.legalFirstName }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
                      <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                        <span class="mobile-label">Last</span>
                        {{ applicant.legalLastName }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
                        <button *ngIf="editingLicense" type="button" mat-icon-button
                          (click)="addIndividualApplicant(applicant.id)" color="primary" aria-label="Associate Applicant">
                          <mat-icon>add</mat-icon>
                        </button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="applicantColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: applicantColumns"></mat-row>
                  </mat-table>
                  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #applicantPaginator="matPaginator">
                  </mat-paginator>
                </div>

                <div fxLayout="column" [fxFlex]="editingLicense? 100 : 50">
                  <span>Associated Applicants</span>
                  <mat-table [dataSource]="associatedApplicantSource" matSort #associatedApplicantSort="matSort"
                    aria-describedby="Associated Applicants" class="mat-elevation-z8 p-t-0">
                    <ng-container matColumnDef="firstName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
                      <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                        <span class="mobile-label">First</span>
                        {{ applicant.legalFirstName }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
                      <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                        <span class="mobile-label">Last</span>
                        {{ applicant.legalLastName }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
                        <button *ngIf="editingLicense" type="button" mat-icon-button class="m-8"
                          (click)="deleteIndividualApplicant(applicant.id)" color="warn"
                          aria-label="Remove Applicant Association">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="applicantColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: applicantColumns"></mat-row>
                  </mat-table>
                  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #associatedApplicantPaginator="matPaginator">
                  </mat-paginator>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Upload Documents-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100" *ngIf="editingLicense">
            <mat-card-title>
              <span *ngIf="documentsValid">
                Supporting Documents
              </span>
              <span *ngIf="!documentsValid" class="text-red-400">
                Supporting Documents Incomplete
              </span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap.lt-lg="8px">
                <span>You must provide a completed entity applicant information form</span>
                <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                  <input type="file" class="file-input" (change)="uploadDocument($event)" #entityApplicantFileUpload />
                  <div fxLayout="row" fxLayoutGap="8px">
                    <div class="file-upload">
                      <button type="button" mat-raised-button color="primary" class="upload-btn"
                        (click)="entityApplicantFileUpload.click()">
                        Browse
                        <mat-icon>attach_file</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Current Documents-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100" fxLayoutGap="8px">
            <mat-card>
              <mat-card-title>
                <span *ngIf="documentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                    <span class="f-s-16" fxFlex="10" *ngIf="editingLicense"><strong>Archive</strong></span>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments" style="justify-content: center;">
                    <ng-container *ngIf="!document.deprecated">
                    <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                      (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                      <span fxFlex="20">Applicant Information</span>

                      <span fxFlex="20">{{document.dateCreated | date}}</span>

                      <button fxFlex="10" [hidden]="!editingLicense || (!authService.isAdmin && !admin)" mat-icon-button color="warn"  fxLayoutAlign="start center" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                        <mat-icon>archive</mat-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="row">
              <button type="button" color="primary" mat-flat-button (click)="viewArchivedDocuments()">
                <span *ngIf="!viewArchived">
                  View Archived Documents
                </span>
                <span *ngIf="viewArchived">
                  Hide Archived Documents
                </span>
              </button>
            </div>

            <br />
            <mat-card *ngIf="viewArchived" [style.background]="'#c7cdd1'">
              <mat-card-title>
                <span>Archived Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                    <span class="f-s-16" fxFlex="10" *ngIf="editingLicense"><strong>Unarchive</strong></span>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments" style="justify-content: center;">
                    <ng-container *ngIf="document.deprecated">
                    <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                      (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                      <span fxFlex="20">Applicant Information</span>

                      <span fxFlex="20">{{document.dateCreated | date}}</span>

                      <button fxFlex="10" [hidden]="!editingLicense || !authService.isAdmin" mat-icon-button color="primary"  fxLayoutAlign="start center" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                        <mat-icon>unarchive</mat-icon>
                      </button>

                      <button fxFlex="10" *ngIf="editingLicense && authService.isAdmin" type="button" mat-icon-button class="m-8"
                        (click)="deleteFile(document.id, document.name)" color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-card>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
