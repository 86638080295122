<mat-card [style.background]="cardColor">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">Notes </span>
        <span *ngIf="editingNotes" class="f-s-14">Click Entry to Edit </span>
        <button type="button" mat-icon-button color="primary" matTooltip="Add New Note" matTooltipShowDelay=800
                aria-label="Add New Note" [disabled]="!editingNotes" (click)="addNewNote()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="notesSource" matSort #notesSort="matSort" aria-describedby="notes" class="mat-elevation-z8">
        <ng-container matColumnDef="text">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Note</mat-header-cell>
          <mat-cell (click)="editingNotes? editNote(note) : ''" *matCellDef="let note">
            <span class="mobile-label">Note</span>
            <textarea fxFlex="95" rows="2" matInput [value]="note.text"></textarea>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateLastUpdated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Audit Info</mat-header-cell>
          <mat-cell (click)="canEdit(note)? editNote(note) : ''" *matCellDef="let note">
            <span class="mobile-label">Audit</span>
            <span>Last Updated: {{note.dateLastUpdated}} by {{note.lastUpdatedBy}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let note">
            <button mat-icon-button color="warn" aria-label="Delete Note" *ngIf="canDelete(note)"
              (click)="deleteNote(note.id)" [disabled]="!editingNotes">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="notesColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: notesColumns" [ngClass]="{'archived': row.archived }"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #notesPaginator="matPaginator">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
