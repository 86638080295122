<div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
  <div fxLayout="row">
    <span style="font-size:x-large" fxFlex>Manage Administrative Rules and Statutes</span>
    <div *ngIf="displayCsv">
      <input type="file" class="file-input" (change)="uploadRules($event)" #fileUpload />
      <div fxLayout="row" fxLayoutGap="8px">
        <div class="file-upload">
          <button mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
            Upload Rules CSV
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field fxFlex="20" fxFlex.lt-md="100">
        <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
      </mat-form-field>
      <span fxFlex></span>
      <span>Click Row to Edit</span>
      <button mat-stroked-button type="button" color="primary" (click)="displayArchived()">{{hideArchived? 'Show Archived' : 'Hide Archived'}}</button>
      <button type="button" mat-icon-button color="primary"
        matTooltip="Add Rule" matTooltipShowDelay=800 aria-label="Add Rule" (click)="addRule()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <mat-table [dataSource]="ruleSource" matSort #ruleSort="matSort" aria-describedby="Rule References" class="mat-elevation-z8">
      <ng-container matColumnDef="section">
        <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
        <mat-cell *matCellDef="let rule" (click)="editRule(rule)">
          <span class="mobile-label">Section</span>
          <div fxLayout="column">
            <span fxFlex="95" *ngIf="rule.general">General</span>
            <span fxFlex="95" *ngIf="rule.trainingPrograms">Training Programs</span>
            <span fxFlex="95" *ngIf="rule.facilitators">Facilitators</span>
            <span fxFlex="95" *ngIf="rule.manufacturers">Manufacturers</span>
            <span fxFlex="95" *ngIf="rule.serviceCenters">Service Centers</span>
            <span fxFlex="95" *ngIf="rule.testingLabs">Testing Labs</span>
            <span fxFlex="95" *ngIf="rule.workerPermits">Worker Permits</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subSection">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Section</mat-header-cell>
        <mat-cell *matCellDef="let rule" (click)="editRule(rule)">
          <span class="mobile-label">Sub-Section</span>
          {{ rule.subSection }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="statute">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rule</mat-header-cell>
        <mat-cell *matCellDef="let rule" (click)="editRule(rule)">
          <span class="mobile-label">Rule</span>
          {{ rule.statute }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="summary">
        <mat-header-cell *matHeaderCellDef>Summary</mat-header-cell>
        <mat-cell *matCellDef="let rule" (click)="editRule(rule)">
          <span class="mobile-label">Summary</span>
          <span fxFlex="95">
            {{ rule.summary | slice: 0:100 }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let rule">
          <div fxLayout="row" fxLayoutGap="8px">
            <button mat-stroked-button type="button" color="primary" (click)="viewRuleDetails(rule)">Details</button>
            <button mat-stroked-button *ngIf="rule.archived" type="button" color="primary" (click)="archive(rule, false)">Un-Archive</button>
            <button mat-stroked-button *ngIf="!rule.archived" type="button" color="primary" (click)="archive(rule, true)">Archive</button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="ruleColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ruleColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]" #rulePaginator="matPaginator">
    </mat-paginator>

  </div>
</div>
