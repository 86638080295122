import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ILicenseChecklist } from "app/models/licenses/license-checklist";
import { ILicenseChecklistApplicant } from "app/models/licenses/license-checklist-applicant";
import { ILicenseChecklistCurriculum } from "app/models/licenses/license-checklist-curriculum";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})

export class LicenseChecklistService {
  private api: string = environment.privateApi + 'LicenseChecklist/';
  constructor(private http: HttpClient) {

  }

  createLicenseChecklist(id: number, entityType: string): Observable<ILicenseChecklist>{
    return this.http.post<ILicenseChecklist>(this.api + 'CreateLicenseChecklist/' + id + '/' + entityType, null);
  }

  getLicenseChecklist(id: number, entityType: string): Observable<ILicenseChecklist>{
    return this.http.get<ILicenseChecklist>(this.api + 'GetLicenseChecklist/' + id + '/' + entityType);
  }

  updateLicenseChecklist(id: number, entityType: string, checklist: ILicenseChecklist): Observable<ILicenseChecklist>{
    const headers = new HttpHeaders().set('Psilocybin', 'StaffWeb');

    return this.http.post<ILicenseChecklist>(this.api + 'UpdateLicenseChecklist/' + id + '/' + entityType, checklist, { headers });
  }

  saveApplicants(id: number, applicants: ILicenseChecklistApplicant[]): Observable<ILicenseChecklistApplicant[]>{
    return this.http.post<ILicenseChecklistApplicant[]>(this.api + 'SaveApplicants/' + id, applicants);
  }

  addCurriculum(id: number, curriculum: ILicenseChecklistCurriculum): Observable<ILicenseChecklistCurriculum>{
    return this.http.post<ILicenseChecklistCurriculum>(this.api + 'AddCurriculum/' + id, curriculum);
  }

  editCurriculum(id: number, curriculum: ILicenseChecklistCurriculum): Observable<ILicenseChecklistCurriculum>{
    return this.http.post<ILicenseChecklistCurriculum>(this.api + 'EditCurriculum/' + id, curriculum);
  }

  deleteCurriculum(id: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'deleteCurriculum/' + id);
  }
}
