import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { AssignedServiceCentersComponent } from './assigned-service-centers/assigned-service-centers.component';
import { SearchServiceCentersComponent } from './search-service-centers/search-service-centers.component';
import { ServiceCenterComplaintsComponent } from './service-center-complaints/service-center-complaints.component';
import { ServiceCenterTasksComponent } from './service-center-tasks/service-center-tasks.component';

const routes: Routes = [
  { path: "", redirectTo: "search", pathMatch: 'full' },
  { path: 'search', component: SearchServiceCentersComponent, canActivate: [ AuthGuard ]},
  { path: 'search/:id', component: SearchServiceCentersComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned', component: AssignedServiceCentersComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned/:id', component: AssignedServiceCentersComponent, canActivate: [ AuthGuard ]},
  { path: 'tasks', component: ServiceCenterTasksComponent, canActivate: [ AuthGuard ]},
  { path: 'complaints', component: ServiceCenterComplaintsComponent, canActivate: [ AuthGuard ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceCenterRoutingModule { }
