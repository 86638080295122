import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-dialog-export-site-inspection',
  templateUrl: './dialog-export-site-inspection.component.html',
  styleUrls: ['./dialog-export-site-inspection.component.scss']
})
export class DialogExportSiteInspectionComponent {
   @ViewChild('pdf') pdf: PDFExportComponent;

  constructor(public dialogRef: MatDialogRef<DialogExportSiteInspectionComponent>,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pdf.saveAs('Site Inspection ' + this.data.siteInspection.caseId + '.pdf');
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }, 2000);
  }
}
