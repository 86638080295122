import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { ILicenseSearch } from 'app/models/licenses/license-search';
import { ISimpleLicense } from 'app/models/licenses/simple-license';
import { ITestingLabLicense } from 'app/models/licenses/testing-lab-license';
import { ITask } from 'app/models/tasks/task';
import { IStaff } from 'app/models/user-info/staff';
import { Observable } from 'rxjs';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';
import { IWorkerLicense } from 'app/models/licenses/worker/worker-license';

@Injectable({providedIn: 'root'})
export class TestingLabService {
  private api: string = environment.privateApi + 'TestingLab/';

  constructor(private http: HttpClient) { }

  getLicense(id: number): Observable<ITestingLabLicense> {
    return this.http.get<ITestingLabLicense>(this.api + 'GetLicense/' + id);
  }

  getPDF(search: AuditLoggingSearch): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "OriginalCopy", search);
  }

  withdrawLicense(approved: boolean, id: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "withdrawLicense/" + approved + "/" + id, null);
  }

  surrenderLicense(approved: boolean, id: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "surrenderLicense/" + approved + "/" + id, null);
  }

  createLicense(): Observable<ITestingLabLicense> {
    return this.http.post<ITestingLabLicense>(this.api + 'CreateLicense', null);
  }

  updateLicense(license: ITestingLabLicense): Observable<ISimpleLicense> {
    return this.http.post<ISimpleLicense>(this.api + 'UpdateLicense', license);
  }

  searchLicenses(search: ILicenseSearch): Observable<ISimpleLicense[]> {
    return this.http.post<ISimpleLicense[]>(this.api + 'SearchLicenses', search);
  }

  getLicenseTasks(): Observable<ISimpleLicense[]> {
    return this.http.get<ISimpleLicense[]>(this.api + 'GetLicenseTasks');
  }

  getAssignedLicenses(): Observable<ISimpleLicense[]> {
    return this.http.get<ISimpleLicense[]>(this.api + 'GetAssignedLicenses');
  }

  claimLicense(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ClaimLicense/' + id, null);
  }

  assignLicense(staff: IStaff, id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AssignLicense/' + id, staff);
  }

  assignComplianceMember(staff: IStaff, id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignComplianceMember/' + id, staff);
  }

  releaseLicense(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ReleaseLicense/' + id, null);
  }

  releaseComplianceMember(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ReleaseComplianceMember/' + id, null);
  }

  updateLicenseAccount(id: number, userId: string): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'UpdateLicenseAccount/' + id + '/' + userId, null);
  }

  deleteLicense(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteLicense/' + id);
  }

  sendTaskNotice(task: ITask): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'SendTaskNotice', task);
  }

  acknowledgeTask(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AcknowledgeTask/' + id, null);
  }

  deleteTask(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteTask/' + id);
  }

  uploadDocument(file: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument', file);
  }

  downloadDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  deleteDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id);
  }

  deprecateDocument(id: number): Observable<any>{
    return this.http.post<any>(this.api + "DeprecateDocument/" + id,null);
  }

  getDocumentTypes(): Observable<IDocumentType[]> {
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  getLicenseStaff(): Observable<IStaff[]>{
    return this.http.get<IStaff[]>(this.api + 'GetLicenseStaff');
  }

  removePayment(id: number) {
    this.http.delete<any>(this.api + 'RemovePayment/' + id).subscribe(
      () => {
        return;
      },
      error => console.log('error', error)
    )
  }

  getExpiredLicenses(licenseId: string): Observable<ITestingLabLicense[]>{
    return this.http.get<ITestingLabLicense[]>(this.api + 'GetExpiredLicenses/' + licenseId);
  }

  createRenewalLicense(licenseId: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + 'CreateTestingLabRenewal/' + licenseId, null);
  }

  transferPTSItems(expiredLicenseId: number, renewalLicenseId: number): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'TransferProductTrackingItems/' + expiredLicenseId + '/' + renewalLicenseId);
  }

  removeWorkerPermit(licenseId: number, permitId: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'RemoveWorkerPermit/' + licenseId + '/' + permitId);
  }

  addWorkerPermit(licenseId: number, permitIds: string[]): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'AddWorkerPermit/' + licenseId, permitIds);
  }
}
