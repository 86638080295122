import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  templateUrl: './testing-lab-complaints.component.html',
  styleUrls: ['./testing-lab-complaints.component.scss']
})
export class TestingLabComplaintsComponent implements OnInit {

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

}
