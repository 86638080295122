import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'dialog-task-archive-reason',
  templateUrl: 'dialog-task-archive-reason.html'
})

export class TaskArchiveReasonDialog {
  reason: string = null;
  constructor(public dialogRef: MatDialogRef<TaskArchiveReasonDialog>) { }

  submit(): void {
    this.dialogRef.close(this.reason);
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
