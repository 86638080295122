import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "app/auth/auth.service";
import { ApplicantHistoryDialogComponent } from "app/components/audit-log/applicant-history/applicant-history-dialog.component";
import { IDocument } from "app/models/documents/document";
import { IApplicant } from "app/models/licenses/applicant";
import { IApplicantDetails } from "app/models/licenses/applicant-search/applicant-details";
import { IEntityDetails } from "app/models/licenses/applicant-search/entity-details";
import { SharedService } from "app/services/core/shared.service";
import { ApplicantService } from "app/services/licenses/applicant.service";
import { ToastrService } from "ngx-toastr";


export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'view-parent',
  templateUrl: './view-parent-dialog.component.html',
  styleUrls: ['./view-parent-dialog.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class ViewParentComponent implements OnInit {
  public applicant: IApplicant = {
    id: 0,
    applicantId: '',
    ssn: '',
    noSSN: false,
    ssnAttestation: false,
    legalFirstName: '',
    legalMiddleName: '',
    legalLastName: '',
    preferredFirstName: '',
    preferredMiddleName: '',
    preferredLastName: '',
    alternateNames: '',
    oregonResidency: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    phone: '',
    alternatePhone: '',
    email: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    dob: '',
    backgroundCheckDate: '',
    backgroundCheckPassed: false,
    backgroundCheckNotes: '',
    applicantDocuments: [],
    residentialHistory: [],
    backgroundCheckInitiatedDate: '',
    parentApplicantId: 0,
    duplicate: false,
    possibleDuplicate: false,
    isParent: false,
    heldLicense: false,
    licensesHeld: "",
    deniedLicense: false,
    subjectToDiscipline: false,
    suedForDamages: false,
    settledAllegations: false,
    allegedAbuse: false,
    substantiatedAllegation: false,
    applicantPageCorrect: null,
    applicantPageCorrectReason: '',
    changeInConvictionHistory: false,
    archived: false,
    backgroundChecks: [],
    fingerPrintsDate: "",
    fingerPrintsExpirationDate: "",
    stateOnly: false,
    fullBackGroundCheck: false
  }

  public entityDetails: IEntityDetails = {
    id: 0,
    entityName: '',
    businessRegisteredIn: '',
    oregonResidency: false,
    headquartersState: '',
    headquartersCity: '',
    headquartersStreet: '',
    headquartersZip: '',
    headquartersCounty: '',
    physicalIsHeadquarters: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingIsHeadquarters: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    applicants: [],
    manufacturers: [],
    serviceCenters: [],
    laboratories: [],
    applicantDocuments: []
  }
  public viewingEntity: boolean = false;
  public removedCurrent: boolean = false;
  public loading: boolean = true;
  public relatedApplicants: IApplicantDetails[] = [];
  public editingApplicant: boolean = false;
  public viewArchived: boolean = false;
  public today: Date = new Date(Date.now());
  public documentsValid: boolean = true;
  public documentTypeReference: { [id: number]: string } = {};
  public personalIdentificationType: number = 0;
  public applicantInfoType: number = 0;
  public physicalType: number = 0;
  public otherType: number = 0;
  public personalIdentification: string = 'Personal Identification';
  public applicantInfo: string = 'Applicant Information';
  public physicalApplication: string = 'Physical Application';
  public otherApplication: string = 'Other';

  public applicantForm = new UntypedFormGroup({
    ssn: new UntypedFormControl(""),
    noSsn: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    oregonResidency: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    backgroundCheckDate: new UntypedFormControl(''),
    backgroundCheckInitiatedDate: new UntypedFormControl(''),
    backgroundCheckNotes: new UntypedFormControl(''),
    backgroundCheckPassed: new UntypedFormControl(false),
    dob: new UntypedFormControl("", [Validators.required]),
    identification: new UntypedFormControl(false),
    applicantInfo: new UntypedFormControl(false),
    other: new UntypedFormControl(false),
    physicalApplication: new UntypedFormControl(false)
    }, {
      validators: [
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.emailValidator(),
        this.ssnValidator(),
        this.previousLicenseValidator()
      ]
    }
  );
  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<ViewParentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public sharedService: SharedService,
              public applicantService: ApplicantService,
              public toastr: ToastrService,
              public authService: AuthService,) { }

  ngOnInit() {
    this.getApplicant();
    this.setupDocumentTypeReference();
  }

  edit(): void {
    this.editingApplicant = !this.editingApplicant;
  }

  getChildrenApplicants(): void {
    this.applicantService.getChildrenApplicants(this.data.parentId).subscribe(
      response => {
        this.relatedApplicants = response;
        this.loading = false;
      },
      error => console.log('error', error)
    );
  }

  setupDocumentTypeReference(): void {
    this.personalIdentificationType = this.data.documentTypes.find(dt => dt.type === this.personalIdentification).id;
    this.applicantInfoType = this.data.documentTypes.find(dt => dt.type === this.applicantInfo).id;
    this.physicalType = this.data.documentTypes.find(dt => dt.type === this.physicalApplication).id;
    this.otherType = this.data.documentTypes.find(dt => dt.type === this.otherApplication).id;

    this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
    this.documentTypeReference[this.applicantInfoType] = this.applicantInfo;
    this.documentTypeReference[this.physicalType] = this.physicalApplication;
    this.documentTypeReference[this.otherType] = this.otherApplication;
  }

  updateApplicantForm(): void {
    this.applicantForm.patchValue({
      ssn: this.applicant.ssn,
      noSsn: this.applicant.noSSN,
      ssnAttestation: this.applicant.ssnAttestation,
      legalFirstName: this.applicant.legalFirstName,
      legalMiddleName: this.applicant.legalMiddleName,
      legalLastName: this.applicant.legalLastName,
      preferredFirstName: this.applicant.preferredFirstName,
      preferredMiddleName: this.applicant.preferredMiddleName,
      preferredLastName: this.applicant.preferredLastName,
      alternateNames: this.applicant.alternateNames,
      oregonResidency: this.applicant.oregonResidency,
      physicalStreet: this.applicant.physicalStreet,
      physicalCity: this.applicant.physicalCity,
      physicalState: this.applicant.physicalState,
      physicalZip: this.applicant.physicalZip,
      physicalCounty: this.applicant.physicalCounty,
      mailingIsPhysical: this.applicant.mailingIsPhysical,
      mailingStreet: this.applicant.mailingStreet,
      mailingCity: this.applicant.mailingCity,
      mailingState: this.applicant.mailingState,
      mailingZip: this.applicant.mailingZip,
      mailingCounty: this.applicant.mailingCounty,
      phone: this.applicant.phone,
      alternatePhone: this.applicant.alternatePhone,
      email: this.applicant.email,
      confirmEmail: this.applicant.email,
      language: this.applicant.language,
      backgroundCheckPassed: this.applicant.backgroundCheckPassed,
      backgroundCheckNotes: this.applicant.backgroundCheckNotes,
      previousLicense: this.applicant.previousLicense,
      affiliatedLicenses: this.applicant.affiliatedLicenses,
      identification: false,
      residency: false
    });
    if(this.applicant.dob != null && this.applicant.dob.length > 0)
    {
      this.applicantForm.patchValue({
        dob: new Date(this.applicant.dob)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }
    if(this.applicant.backgroundCheckDate != null && this.applicant.backgroundCheckDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckDate: new Date(this.applicant.backgroundCheckDate)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }
    if(this.applicant.backgroundCheckInitiatedDate != null && this.applicant.backgroundCheckInitiatedDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckInitiatedDate: new Date(this.applicant.backgroundCheckInitiatedDate)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }
  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    this.applicant.ssn = applicantInfo.ssn,
    this.applicant.noSSN = applicantInfo.noSsn,
    this.applicant.ssnAttestation = applicantInfo.ssnAttestation,
    this.applicant.legalFirstName = applicantInfo.legalFirstName,
    this.applicant.legalMiddleName = applicantInfo.legalMiddleName,
    this.applicant.legalLastName = applicantInfo.legalLastName,
    this.applicant.preferredFirstName = applicantInfo.preferredFirstName,
    this.applicant.preferredMiddleName = applicantInfo.preferredMiddleName,
    this.applicant.preferredLastName = applicantInfo.preferredLastName,
    this.applicant.alternateNames = applicantInfo.alternateNames,
    this.applicant.oregonResidency = applicantInfo.oregonResidency,
    this.applicant.physicalState = applicantInfo.physicalState,
    this.applicant.physicalCity = applicantInfo.physicalCity,
    this.applicant.physicalStreet = applicantInfo.physicalStreet,
    this.applicant.physicalZip = applicantInfo.physicalZip,
    this.applicant.physicalCounty = applicantInfo.physicalCounty,
    this.applicant.mailingIsPhysical = applicantInfo.mailingIsPhysical,
    this.applicant.mailingState = applicantInfo.mailingState,
    this.applicant.mailingCity = applicantInfo.mailingCity,
    this.applicant.mailingStreet = applicantInfo.mailingStreet,
    this.applicant.mailingZip = applicantInfo.mailingZip,
    this.applicant.mailingCounty = applicantInfo.mailingCounty,
    this.applicant.phone = applicantInfo.phone,
    this.applicant.alternatePhone = applicantInfo.alternatePhone,
    this.applicant.email = applicantInfo.email,
    this.applicant.language = applicantInfo.language,
    this.applicant.backgroundCheckDate = applicantInfo.backgroundCheckDate,
    this.applicant.backgroundCheckInitiatedDate = applicantInfo.backgroundCheckInitiatedDate,
    this.applicant.backgroundCheckNotes = applicantInfo.backgroundCheckNotes,
    this.applicant.backgroundCheckPassed = applicantInfo.backgroundCheckPassed,
    this.applicant.previousLicense = applicantInfo.previousLicense,
    this.applicant.affiliatedLicenses = applicantInfo.affiliatedLicenses,
    this.applicant.dob = applicantInfo.dob

    if(applicantInfo.mailingIsPhysical){
      this.applicant.mailingState = applicantInfo.physicalState;
      this.applicant.mailingCity = applicantInfo.physicalCity;
      this.applicant.mailingStreet = applicantInfo.physicalStreet;
      this.applicant.mailingZip = applicantInfo.physicalZip;
      this.applicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
  }

  getApplicant(): void {
    this.applicantService.getApplicant(this.data.parentId).subscribe(
      response => {
        this.applicant = response;
        this.updateApplicantForm();
        this.getChildrenApplicants();
      },
      error => console.log('error', error)
    );
  }

  saveApplicant(close: boolean): void {
    this.updateApplicant();
    this.applicantService.updateParentApplicant(this.applicant).subscribe(
      () => {
        this.editingApplicant = false;
        if(close){
          this.cancel();
        }
      },
      error => console.log('error', error)
    );
  }

  cancel(): void {
    this.dialogRef.close(this.removedCurrent);
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.applicantService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteDocument(id).subscribe(
            () => {
              this.applicant.applicantDocuments = this.applicant.applicantDocuments.filter(item => item.id !== id);
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  deprecateDocument(id: number) {
    let archived = false;
    let currentDocumentIndex = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
    archived = this.applicant.applicantDocuments[currentDocumentIndex].deprecated
    this.sharedService.openConfirm(archived ? "Unarchive Document?" : "Archive Document?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deprecateDocument(id).subscribe(
            () => {
                let index = this.applicant.applicantDocuments.findIndex(ad => ad.id == id);
                this.applicant.applicantDocuments[index].deprecated = !this.applicant.applicantDocuments[index].deprecated;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  viewArchivedDocuments(): void {
    this.viewArchived = !this.viewArchived;
  }

  uploadDocument(event: Event): void {
    let form = this.applicantForm.value;
    let types: number[] = [];

    if(form.identification) {
      types.push(this.personalIdentificationType);
    }
    if(form.applicantInfo){
      types.push(this.applicantInfoType);
    }
    if(form.other){
      types.push(this.otherType);
    }
    if(form.physicalApplication){
      types.push(this.physicalType);
    }

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      let upload: IDocument = {
        id: 0,
        name: file.name,
        comments: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.applicant.id,
        types: types,
        pendingUpdate: false,
        extension: "",
        deprecated: false,
        adminOnly: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.applicantService.uploadDocument(formData).subscribe(
        response => this.applicant.applicantDocuments.push(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.applicantForm.patchValue({
            identification: false,
            applicantInfo: false,
            other: false,
            physicalApplication: false
          });
        });
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  removeDuplicate(id: number) {
    this.sharedService.openConfirm("Are you sure you want to remove this applicant as a duplicate?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.removeDuplicate(id).subscribe(
            response => {
              if(response)
              {
                if(id == this.data.currentApplicantId)
                {
                  this.removedCurrent = true;
                }
                this.relatedApplicants = this.relatedApplicants.filter(a => a.id !== id);
                this.toastr.success("Applicant removed as duplicate!");
              }
            },
            error => console.log('error', error)
          );
    }});
  }

  getEntityDetails(id: number): void {
    this.applicantService.getEntityApplicantDetails(id).subscribe(
      response => {
        this.entityDetails = response;
        this.viewingEntity = true;
      },
      error => console.log('error', error)
    );
  }

  closeEntity(): void {
    this.viewingEntity = false;
  }

  openApplicantHistoryChangeLog() {
      this.dialog.open(ApplicantHistoryDialogComponent, {
        data: this.data.parentId,
        minWidth: !this.sharedService.mobile? '700px' : '300px',
        maxWidth: !this.sharedService.mobile? '800px' : '300px',
        maxHeight: !this.sharedService.mobile? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
  }
    //Applicant Custom Validators

    ssnValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const ssn = control.value.ssn;
        const noSsn = control.value.noSsn;
        if(noSsn){
          return null;
        }
        return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
      }
    }

    validateSsn(): boolean {
      if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
        this.applicantForm.get('ssn').setErrors(['ssnRequired']);
        return true;
      }
      this.applicantForm.get('ssn').clearValidators();
      this.applicantForm.get('ssn').updateValueAndValidity();
      return false;
    }

    previousLicenseValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const hasPreviousLicense = control.value.previousLicense;
        const affiliatedLicenses = control.value.affiliatedLicenses;
        if(!hasPreviousLicense){
          return null;
        }
        return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
      }
    }

    validatePreviousLicense(): boolean {
      if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
        this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
        return true;
      }
      this.applicantForm.get('affiliatedLicenses').clearValidators();
      this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
      return false;
    }

    mailingIsSame(): void {
      this.applicantForm.get('mailingStreet').updateValueAndValidity();
      this.applicantForm.get('mailingCity').updateValueAndValidity();
      this.applicantForm.get('mailingState').updateValueAndValidity();
      this.applicantForm.get('mailingZip').updateValueAndValidity();
    }

    emailValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const email = control.value.email;
        const confirmation = control.value.confirmEmail;
        return email === confirmation ? null : { emailConfirmed: true };
      }
    }

    validateEmail(): boolean {
      if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
        this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
        return true;
      }
      this.applicantForm.get('confirmEmail').clearValidators();
      this.applicantForm.get('confirmEmail').updateValueAndValidity();
      return false;
    }

    mailingStreetValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const street = control.value.mailingStreet;
        const same = control.value.mailingIsPhysical;
        if (!same) {
          return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
        }
        return null;
      };
    }

    validateMailingStreet(): boolean {
      if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
        this.applicantForm.get('mailingStreet').setErrors(['required']);
        return true;
      }
      this.applicantForm.get('mailingStreet').clearValidators();
      this.applicantForm.get('mailingStreet').updateValueAndValidity();
      return false;
    }

    mailingCityValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const city = control.value.mailingCity;
        const same = control.value.mailingIsPhysical;
        if (!same) {
          return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
        }
        return null;
      };
    }

    validateMailingCity(): boolean {
      if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
        this.applicantForm.get('mailingCity').setErrors(['required']);
        return true;
      }
      this.applicantForm.get('mailingCity').clearValidators();
      this.applicantForm.get('mailingCity').updateValueAndValidity();
      return false;
    }

    mailingStateValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const state = control.value.mailingState;
        const same = control.value.mailingIsPhysical;
        if (!same) {
          return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
        }
        return null;
      };
    }

    validateMailingState(): boolean {
      if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
        this.applicantForm.get('mailingState').setErrors(['required']);
        return true;
      }
      this.applicantForm.get('mailingState').clearValidators();
      this.applicantForm.get('mailingState').updateValueAndValidity();
      return false;
    }

    mailingZipValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const zip = control.value.mailingZip;
        const same = control.value.mailingIsPhysical;
        if (!same) {
          return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
        }
        return null;
      };
    }

    validateMailingZip(): boolean {
      if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
        this.applicantForm.get('mailingZip').setErrors(['required']);
        return true;
      }
      this.applicantForm.get('mailingZip').clearValidators();
      this.applicantForm.get('mailingZip').updateValueAndValidity();
      return false;
    }
    //End of Validators
}
