
<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.orange">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
      <mat-icon class="medium-icon m-24">domain</mat-icon>
      <span class="f-s-20">{{title}} </span>
      <span fxFlex></span>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
  <div [@slideRight] *ngIf="license.id === 0" fxLayout="column" fxLayoutGap="8px" fxFlex="100">
    <div fxLayout="row" fxLayout.lt-md="column"
      fxLayoutAlign="start center" fxLayoutAlign.lt-md="" fxLayoutGap="8px">
      <form [formGroup]="searchForm" *ngIf="showSearch" fxFlex="80">
        <div fxLayout="row">
          <div fxLayout="row" fxFlex="80" fxLayout.lt-md="column" fxLayoutAlign.lt-md="" fxLayoutGap="16px"
            fxLayoutAlign="start center">
            <mat-form-field fxFlex="20">
              <input matInput placeholder="Entity Name" formControlName="entityName">
            </mat-form-field>
            <mat-form-field fxFlex="20">
              <input matInput placeholder="license Id" formControlName="licenseId">
            </mat-form-field>
            <mat-form-field fxFlex="20" style="margin-top: -1em; margin-bottom: -1.25em">
              <mat-label>
                Assigned To
              </mat-label>
              <mat-select formControlName="assignedTo">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let staff of licenseStaff" value={{staff.id}}>{{staff.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="30">
              <mat-label>
                Statuses
              </mat-label>
              <mat-select formControlName="statuses" multiple #select>
                <mat-checkbox [(ngModel)]="allSelected" style = "margin: 5px 17px;"
                [ngModelOptions]="{standalone: true}"
                (change)="toggleAllSelection()">Select All</mat-checkbox>
                <mat-option *ngFor="let status of sharedService.statusListServiceCenterManufacturerLaboratory" [value]="status" (click)="optionClick()">
                  {{status}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="8px" fxLayoutAlign.lt-lg="center center">
            <mat-checkbox formControlName="onlyPaid">
              Paid Application Fee
            </mat-checkbox>
            <button mat-stroked-button type="submit" color="primary" (click)="searchLicenses()"
              matTooltip="Search Licenses" matTooltipShowDelay=800 aria-label="Search Licenses">
              Search
            </button>
          </div>
        </div>
        <div fxLayout="row">
          <div fxLayout="row" fxFlex="80" fxLayout.lt-md="column" fxLayoutAlign.lt-md="" fxLayoutGap="16px"
            fxLayoutAlign="start center">
            <mat-form-field fxFlex="20">
              <input matInput placeholder="Street" formControlName="street">
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <input matInput placeholder="City" formControlName="city">
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <mat-label>
                County
              </mat-label>
              <mat-select formControlName="county">
                <mat-option value="">None</mat-option>
                <mat-option *ngFor="let county of sharedService.counties" value={{county}}>
                  {{county}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <span fxFlex></span>
      <span (click)="createLicense()" class="f-s-16" style="cursor: pointer;" *ngIf="showAddNew && (authService.isAdmin || authService.isTestingLabAdmin)">Add License</span>
      <button type="button" mat-icon-button color="primary" matTooltip="Add license" matTooltipShowDelay=800
        *ngIf="showAddNew && (authService.isAdmin || authService.isTestingLabAdmin)" aria-label="Add license" (click)="createLicense()">
        <mat-icon>post_add</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column"
      fxLayoutAlign="start center" fxLayoutAlign.lt-md="" fxLayoutGap="8px">
      <span fxFlex></span>
      <span>Results: {{ licenseCount }}</span>
    </div>
    <mat-table [dataSource]="licenseSource" matSort aria-describedby="licenses" #licenseSort="matSort" class="mat-elevation-z8 m-b-0">

      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Operational Name</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="column" fxLayoutGap="4px">
            <div fxLayout="row" fxLayoutGap="4px">
             <span>{{license.entityName}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>
                <span *ngIf="license.street != ''">{{license.street}}, </span>
                <span *ngIf="license.city != ''">{{license.city}} </span>
                <span *ngIf="license.county != ''">{{license.county}} County</span>
              </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="licenseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="row" fxLayoutGap="4px">
            <span>{{license.licenseId}}</span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tasks">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tasks</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="column">
            <span *ngFor="let task of license.tasks">
              <span *ngIf="!task.complete && !task.archived">{{task.taskText}}</span>
            </span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="row" fxLayoutGap="8px">
            <span>{{license.status}}</span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="applicationDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Submitted</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="row" fxLayoutGap="8px">
            <span>{{license.applicationDate | date}}</span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="approvedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Approved Date</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="column">
            <div *ngIf="license.approvedDate !== null && license.approvedDate !== ''" fxLayout="row" fxLayoutGap="8px">
              <span>{{license.approvedDate | date}}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
        <mat-cell *matCellDef="let license" (click)="getLicense(license.id)">
          <div fxLayout="column">
            <div *ngIf="license.expirationDate !== null && license.expirationDate !== ''" fxLayout="row" fxLayoutGap="8px">
              <span>{{license.expirationDate | date}}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignedTo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Team Member</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
            <mat-select [(value)]="license.assignedTo" (selectionChange)="assignLicense($event, license.id)">
              <mat-option>-- None --</mat-option>
              <mat-option *ngFor="let staff of licenseStaff" value={{staff.name}}>{{staff.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignedToCompliance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
            <mat-select [(value)]="license.assignedToCompliance" (selectionChange)="assignComplianceMember($event, license.id)">
             <mat-option>-- None --</mat-option>
             <mat-option *ngFor="let staff of licenseStaff" value={{staff.name}}>{{staff.name}}
             </mat-option>
           </mat-select>
         </mat-form-field>
         <span *ngIf="!authService.isAdmin">{{license.assignedToCompliance}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header="">
        </mat-header-cell>
        <mat-cell *matCellDef="let license">
          <div fxLayout="column" fxLayoutGap="4px">
            <button mat-button class="m-l-16" type="button" color="primary" [ngStyle]="{'line-height': '1.5', 'text-align': 'start'}"
            *ngIf="sharedService.userNameClaim != license.assignedTo" (click)="claimLicense(license)">Claim &amp;
            Edit</button>
            <button mat-button class="text-wrap" type="button" color="error" [ngStyle]="{'line-height': '1.5', 'text-align': 'start'}"
              *ngIf="authService.isAdmin && license.assignedTo != '' && license.assignedTo != null"
              (click)="releaseLicense(license.id, $event)">Release Team Member</button>
              <button mat-button class="text-wrap" type="button" color="error" [ngStyle]="{'line-height': '1.5', 'text-align': 'start'}"
              *ngIf="authService.isAdmin && license.assignedToCompliance != '' && license.assignedToCompliance != null"
              (click)="releaseComplianceMember(license.id, $event)">Release Compliance Member</button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="managerFlag">
        <mat-header-cell *matHeaderCellDef mat-sort-header="">
          Alerts
        </mat-header-cell>
        <mat-cell *matCellDef="let license">
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
            <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
            <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
            <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="licenseColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: licenseColumns" [ngClass]="{'hasRenewal': row.hasRenewal, 'isRenewal': row.isRenewal && !row.hasRenewal && !row.renewed}"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]" #licensePaginator="matPaginator" [pageIndex]="currentPage" [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>

<mat-tab-group animationDuration="200ms" *ngIf="license.id !== 0" [selectedIndex]="selectedIndex" (selectedIndexChange)="changeStep($event)">
  <mat-tab label="Testing Lab Dashboard">
    <mat-card>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100">
          <div fxFlex.lt-md="100" [fxFlex]="showChecklist? 75: 100">
        <div fxLayout="column">
          <!--License Information-->
          <form [formGroup]="licenseForm" *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
            <div fxLayout="column">
                <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor">
                  <mat-card-title>
                    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                      <span *ngIf="addingNewLicense">Add Testing Lab</span>
                      <span *ngIf="editingLicense && !addingNewLicense">Edit Testing Lab</span>
                      <span *ngIf="!addingNewLicense && !editingLicense">Testing Lab</span>
                      <span class="f-s-18">{{license.licenseId}}</span>
                      <button type="button" mat-icon-button (click)="sharedService.copyToClipboard(license.licenseId)" color="primary"
                        matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <span fxFlex></span>
                      <span style="font-size: 18px;">{{licenseForm.get('status').value}} - </span>
                      <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center" style="font-size: 18px;" *ngIf="sharedService.approvedStatuses.includes(licenseForm.get('status').value)">
                        <span *ngIf="!license.isRenewal">Approved on - {{licenseForm.get('approvedDate').value | date}}</span>
                        <span *ngIf="license.isRenewal">Renewed on - {{licenseForm.get('approvedDate').value | date}}</span>
                        <span *ngIf="license.isRenewal">Date Renewal Approved - {{licenseForm.get('renewedDate').value | date}}</span>
                        <span>Expires on - {{license.expirationDate | date}}</span>
                      </div>
                      <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center" style="font-size: 18px;" *ngIf="sharedService.nonApprovedStatuses.includes(licenseForm.get('status').value)">
                        <span>Submitted on - {{license.applicationDate | date}}</span>
                      </div>
                      <span fxFlex></span>
                      <div fxLayout="column">
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign.lt-md="start center">
                        <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                        <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                        <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                        <button mat-button type="button" color="warn" (click)="cancelWorkingLicense()">Cancel</button>
                        <button mat-button type="button" color="primary" *ngIf="(!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" (click)="updateLicense(false)">Save</button>
                        <button mat-button type="button" color="primary" *ngIf="(!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" (click)="updateLicense(true)">Save & Close</button>
                        <button *ngIf="!addingNewLicense && (!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" type="button" mat-icon-button [color]="editingLicense? 'primary':'warn'"
                          [matTooltip]="editingLicense? 'Stop Editing' : 'Edit Testing Lab'" matTooltipShowDelay=800
                          aria-label="Edit Testing Lab" (click)="editLicense()">
                          <mat-icon>{{editingLicense? 'edit' : 'edit_off'}}</mat-icon>
                        </button>
                      </div>
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign.lt-md="start center">
                        <button *ngIf="licenseChecklist.id != 0 && licenseChecklist.id != null" type="button" mat-icon-button [color]="!showChecklist? 'primary':'warn'"
                          matTooltip="Toggle Checklist" matTooltipShowDelay=800 aria-label="Toggle Checklist" (click)="toggleChecklist()">
                          <mat-icon>checklist</mat-icon>
                        </button>
                        <button mat-button type="button" color="primary" *ngIf="licenseChecklist.id == 0 && (!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" (click)="createChecklist()">Create Checklist</button>
                        <button mat-button type="button" color="primary" *ngIf="license.status == 'Approved'" (click)="viewPrintScreen(license)">Print License</button>
                      </div>
                    </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <span class="f-s-18 m-b-8" *ngIf="license.enteredByStaff">Manually Entered</span>
                    </div>
                  </mat-card-title>
                  <mat-card-content>
                    <div fxLayout="row" fxLayoutGap="16px">
                      <mat-card fxFlex="45">
                        <mat-card-title>
                          <span>Testing Lab Information</span>
                        </mat-card-title>
                        <mat-card-content *ngIf="!editingLicense">
                          <div fxLayout="column"  fxLayoutGap="8px">
                            <span class="f-s-16"><strong>Laboratory Operational Name</strong></span>
                            <span>{{license.entityName}}</span>

                            <span class="f-s-16"><strong>Previous Laboratory Operational Names</strong></span>
                            <span *ngIf="license.previousLicenseNames.length < 1">None</span>
                            <span *ngFor="let name of license.previousLicenseNames">{{name}}</span>

                            <span class="f-s-16"><strong>Testing Lab Website</strong></span>
                            <span [ngClass]="{'updated-field': isFieldUpdated('EntityWebsite', 'TestingLabLicenses', license.id.toString())}">{{license.entityWebsite}}</span>

                            <span class="f-s-16"><strong>Registered Business</strong></span>
                            <div fxLayout="row">
                              <mat-checkbox [checked]="license.businessInOregon" disabled>
                                <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" fxLayoutGap="8px" *ngIf="license.businessInOregon" [@slideInOut]>
                              <div fxLayout="column">
                                <span>Oregon Secretary of State Registry Number</span>
                                <span>{{license.secretaryofStateNumber}}</span>
                              </div>
                            </div>

                            <div fxLayout="row">
                              <mat-checkbox [checked]="license.registeredBusiness" disabled>
                                <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" fxLayoutGap="8px" *ngIf="license.registeredBusiness" [@slideInOut]>
                              <div fxLayout="column">
                                <span>Location Registered</span>
                                <span>{{license.registeredIn}}</span>
                              </div>
                              <div fxLayout="column">
                                <span>Registration Number</span>
                                <span>{{license.registryNumber}}</span>
                              </div>
                            </div>

                            <span class="f-s-16"><strong>Other OPS Licenses or Permits</strong></span>
                            <mat-checkbox [checked]="license.previousLicense" disabled [ngClass]="{'updated-field': isFieldUpdated('PreviousLicense', 'TestingLabLicenses', license.id.toString())}">
                              <span class="text-wrap">Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?</span>
                            </mat-checkbox>
                            <div fxLayout="column" *ngIf="this.licenseForm.get('previousLicense').value" [@slideInOut]>
                              <span [ngClass]="{'updated-field': isFieldUpdated('AffiliatedLicenses', 'TestingLabLicenses', license.id.toString())}">{{license.affiliatedLicenses}}</span>
                            </div>
                            <span class="f-s-16"><strong>Inspection Last Completed</strong></span>
                            <span>{{license.inspectionCompletedDate | date}}</span>
                            <span class="f-s-16" *ngIf="!license.isRenewal"><strong>Approved Date</strong></span>
                            <span class="f-s-16" *ngIf="license.isRenewal"><strong>Renewed Date</strong></span>
                            <span>{{license.approvedDate | date}}</span>
                            <span class="f-s-16"><strong>Expiration Date</strong></span>
                            <span>{{license.expirationDate | date}}</span>
                            <div fxLayout="row" fxLayoutGap="8px" *ngIf="license.isRenewal">
                              <div fxLayout="column" fxLayoutGap="8px">
                                <span *ngIf="licenseForm.get('applicantsCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantsCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant attests the applicants listed are their current applicants</strong></span>
                                <span *ngIf="!licenseForm.get('applicantsCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantsCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant does not attest the applicants listed are their current applicants</strong></span>
                                <textarea *ngIf="!licenseForm.get('applicantsCorrect').value" matInput placeholder="Applicant Changes" cols="2" [value]="license.applicantsCorrectReason" [readonly]="true" [ngClass]="{'updated-field': isFieldUpdated('ApplicantsCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                              </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="8px" *ngIf="license.isRenewal">
                              <div fxLayout="column" fxLayoutGap="8px">
                                <span *ngIf="licenseForm.get('financialInterestCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('FinancialInterestCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant attests financial interest statements to be current and correct</strong></span>
                                <span *ngIf="!licenseForm.get('financialInterestCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('FinancialInterestCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant does not attest financial interest statements to be current and correct</strong></span>
                                <textarea *ngIf="!licenseForm.get('financialInterestCorrect').value" matInput placeholder="Financial Changes" cols="2" [value]="license.financialInterestCorrectReason" [readonly]="true" [ngClass]="{'updated-field': isFieldUpdated('FinancialInterestCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                              </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="8px" *ngIf="license.isRenewal">
                              <div fxLayout="column" fxLayoutGap="8px">
                                <span *ngIf="licenseForm.get('testingLabInformationCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('TestingLabInformationCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant attests Testing Lab information to be current and correct</strong></span>
                                <span *ngIf="!licenseForm.get('testingLabInformationCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('TestingLabInformationCorrect', 'TestingLabLicenses', license.id.toString())}">Applicant does not attest Testing Lab information to be current and correct</strong></span>
                                <textarea *ngIf="!licenseForm.get('testingLabInformationCorrect').value" matInput placeholder="Lab Changes" cols="2" [value]="license.testingLabInformationCorrectReason" [readonly]="true" [ngClass]="{'updated-field': isFieldUpdated('TestingLabInformationCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>

                        <mat-card-content *ngIf="editingLicense">
                          <div fxLayout="column"  fxLayoutGap="8px">
                            <span class="f-s-16"><strong>Laboratory Operational Name</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Name" formControlName="entityName">
                              <mat-error *ngIf="licenseForm.get('entityName').invalid">
                                A Testing Lab name is required
                              </mat-error>
                            </mat-form-field>

                            <span class="f-s-16"><strong>Testing Lab Website</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Website" formControlName="entityWebsite" [ngClass]="{'updated-field': isFieldUpdated('EntityWebsite', 'TestingLabLicenses', license.id.toString())}">
                              <mat-error *ngIf="licenseForm.get('entityWebsite').hasError('pattern')">
                                Please enter a valid URL (http:// or https://)
                              </mat-error>
                            </mat-form-field>

                            <span class="f-s-16"><strong>Registered Business</strong></span>
                            <div fxLayout="row">
                              <mat-checkbox formControlName="businessInOregon">
                                <span class="text-wrap">This legal entity is registered as a business with the Secretary of State (SOS) in Oregon</span>
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" fxLayoutGap="8px" *ngIf="licenseForm.get('businessInOregon').value" [@slideInOut]>
                              <div fxLayout="column">
                                <span>Oregon Secretary of State Registry Number</span>
                                <mat-form-field>
                                  <input matInput placeholder="Secretary of State Number" formControlName="secretaryofStateNumber">
                                </mat-form-field>
                              </div>
                            </div>

                            <div fxLayout="row">
                              <mat-checkbox formControlName="registeredBusiness">
                                <span class="text-wrap">This legal entity is registered as a business in another state, US territory or a freely associated state</span>
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" fxLayoutGap="8px" *ngIf="licenseForm.get('registeredBusiness').value" [@slideInOut]>
                              <div fxLayout="column">
                                <span>Location Registered</span>
                                <mat-form-field>
                                  <input matInput placeholder="Location Registered" formControlName="registeredIn">
                                </mat-form-field>
                                <span>Registration Number</span>
                                <mat-form-field>
                                  <input matInput placeholder="Registration Number" formControlName="registryNumber">
                                </mat-form-field>
                              </div>
                            </div>

                            <span class="f-s-16"><strong>Other OPS Licenses or Permits</strong></span>
                            <mat-checkbox formControlName="previousLicense" [ngClass]="{'updated-field': isFieldUpdated('PreviousLicense', 'TestingLabLicenses', license.id.toString())}">
                              <span class="text-wrap">Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?</span>
                            </mat-checkbox>
                            <div fxLayout="column" *ngIf="this.licenseForm.get('previousLicense').value" [@slideInOut]>
                              <span>Please provide the license numbers for each Oregon Psilocybin license</span>
                              <mat-form-field>
                                <input matInput placeholder="Affiliated Licenses" formControlName="affiliatedLicenses" [ngClass]="{'updated-field': isFieldUpdated('AffiliatedLicenses', 'TestingLabLicenses', license.id.toString())}"
                                  (blur)="validatePreviousLicense()">
                                <mat-error *ngIf="validatePreviousLicense()">
                                  Please provide all license numbers
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <span class="f-s-16"><strong>Inspection Last Completed</strong></span>
                            <mat-form-field>
                              <mat-label>Inspection Completed On</mat-label>
                              <input placeholder="mm/dd/yyyy" formControlName="inspectionCompletedDate" matInput [matDatepicker]="inspectionCompletedDate"
                                [max]="today" />
                              <mat-datepicker-toggle matSuffix [for]="inspectionCompletedDate"></mat-datepicker-toggle>
                              <mat-datepicker #inspectionCompletedDate>
                                <mat-datepicker-actions>
                                  <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                  <button mat-button matDatepickerCancel>Cancel</button>
                                </mat-datepicker-actions>
                              </mat-datepicker>
                              <mat-error *ngIf="licenseForm.get('inspectionCompletedDate').value > today">
                                Please choose an earlier date.
                              </mat-error>
                            </mat-form-field>
                            <span class="f-s-16" *ngIf="!license.isRenewal"><strong>Approved Date</strong></span>
                            <span class="f-s-16" *ngIf="license.isRenewal"><strong>Renewed Date</strong></span>
                            <span>{{license.approvedDate | date}}</span>
                            <span class="f-s-16"><strong>Expiration Date</strong></span>
                            <span>{{license.expirationDate | date}}</span>
                            <mat-checkbox formControlName="applicantsCorrect" *ngIf="license.isRenewal" [ngClass]="{'updated-field': isFieldUpdated('ApplicantsCorrect', 'TestingLabLicenses', license.id.toString())}">
                              Applicant attests the applicants listed are their current applicants
                            </mat-checkbox>
                            <span *ngIf="!licenseForm.get('applicantsCorrect').value && license.isRenewal">Changes</span>
                            <textarea *ngIf="!licenseForm.get('applicantsCorrect').value && license.isRenewal" matInput placeholder="Applicant changes" cols="2" formControlName="applicantsCorrectReason" [ngClass]="{'updated-field': isFieldUpdated('ApplicantsCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                            <mat-checkbox formControlName="financialInterestCorrect" *ngIf="license.isRenewal" [ngClass]="{'updated-field': isFieldUpdated('FinancialInterestCorrect', 'TestingLabLicenses', license.id.toString())}">
                              Applicant attests financial interest statements to be current and correct
                            </mat-checkbox>
                            <span *ngIf="!licenseForm.get('financialInterestCorrect').value && license.isRenewal">Changes</span>
                            <textarea *ngIf="!licenseForm.get('financialInterestCorrect').value && license.isRenewal" matInput placeholder="Financial changes" cols="2" formControlName="financialInterestCorrectReason" [ngClass]="{'updated-field': isFieldUpdated('FinancialInterestCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                            <mat-checkbox formControlName="testingLabInformationCorrect" *ngIf="license.isRenewal" [ngClass]="{'updated-field': isFieldUpdated('TestingLabInformationCorrect', 'TestingLabLicenses', license.id.toString())}">
                              Applicant attests Testing Lab information to be current and valid
                            </mat-checkbox>
                            <span *ngIf="!licenseForm.get('testingLabInformationCorrect').value && license.isRenewal">Changes</span>
                            <textarea *ngIf="!licenseForm.get('testingLabInformationCorrect').value && license.isRenewal" matInput placeholder="Lab changes" cols="2" formControlName="testingLabInformationCorrectReason" [ngClass]="{'updated-field': isFieldUpdated('TestingLabInformationCorrectReason', 'TestingLabLicenses', license.id.toString())}"></textarea>
                          </div>
                        </mat-card-content>
                      </mat-card>

                      <div fxLayout="column" fxFlex="35">
                        <mat-card>
                          <mat-card-title>
                            <span>Primary Point of Contact</span>
                          </mat-card-title>
                          <mat-card-content>
                            <div fxLayout="column" fxLayoutGap="16px">

                              <div fxLayout="column">
                                <span class="f-s-16"><strong>Contact Name</strong></span>
                                <span>{{license.primaryContactName}}</span>
                              </div>
                              <div fxLayout="column">
                                <span class="f-s-16"><strong>Contact Phone</strong></span>
                                <span>{{license.primaryContactNumber | mask: '(000) 000-0000 000000'}}</span>
                              </div>
                              <div fxLayout="column">
                                <span class="f-s-16"><strong>Email Address</strong></span>
                                <span fxLayoutAlign="start center" fxLayoutGap="12px">
                                  <span>{{license.primaryContactEmail}}</span>
                                  <span *ngIf="license.accountEmail != null && license.accountEmail.length > 0 && license.primaryContactEmail != ''">
                                    <a *ngIf="license.accountIsB2C" (click)="openAddMessageDialog(license.primaryContactName, license.accountEmail) ">
                                      <mat-icon>email</mat-icon>
                                    </a>
                                    <a *ngIf="!license.accountIsB2C" [href]="'mailto:' + license.accountEmail" >
                                      <mat-icon>email</mat-icon>
                                    </a>
                                  </span>
                                </span>
                              </div>
                              <div fxLayout="column">
                                <span class="f-s-16"><strong>Preferred Language</strong></span>
                                <span>{{license.language}}</span>
                              </div>

                            </div>
                          </mat-card-content>
                        </mat-card>

                        <mat-card *ngIf="license.previousPointsOfContact.length > 0">
                          <mat-card-title>
                            <span>Previous Points of Contact</span>
                          </mat-card-title>
                          <mat-card-content>
                            <div fxLayout="column" fxLayoutGap="16px">
                              <div fxLayout="column">
                                <span class="f-s-16"><strong>Contact Name</strong></span>
                                <span *ngFor="let name of license.previousPointsOfContact">{{name}}</span>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>

                        <mat-card>
                          <mat-card-title>
                            <span>Account Information</span>
                          </mat-card-title>
                          <mat-card-content>
                            <div class="m-t-8" fxLayout="column" fxLayoutGap="8px">
                              <div>
                                <button *ngIf="license.accountName === null || license.accountName.length <= 0"
                                        type="button" color="primary" mat-flat-button (click)="addUserAccount()">Add User</button>
                              </div>

                              <span>{{license.accountName}} </span>

                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <span *ngIf="license.accountEmail != null && license.accountEmail.length > 0">
                                  {{license.accountEmail}}
                                </span>

                                <span *ngIf="license.accountEmail != null && license.accountEmail.length > 0 && (license.primaryContactEmail == null || license.primaryContactEmail == '')">
                                  <a *ngIf="license.accountIsB2C" (click)="openAddMessageDialog(license.accountName, license.accountEmail) ">
                                    <mat-icon>email</mat-icon>
                                  </a>
                                  <a *ngIf="!license.accountIsB2C" [href]="'mailto:' + license.accountEmail" >
                                    <mat-icon>email</mat-icon>
                                  </a>
                                </span>

                                <button *ngIf="!license.accountIsB2C && license.accountName?.length > 0"
                                  type="button" color="primary" mat-button (click)="editUserAccount()">Edit User</button>
                              </div>
                              </div>
                              <br />
                              <mat-card-title>
                                <span>Attorney Information</span>
                              </mat-card-title>
                              <div class="m-t-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="!editingLicense">
                                <div fxLayout="column" fxLayoutGap="8px">
                                  <div fxLayout="row" class="f-s-16 m-b-8">
                                    <span *ngIf="license.attorneyContact"><b>Attorney should be contacted instead of licensee</b></span>
                                    <span *ngIf="!license.attorneyContact">Attorney should <b>not</b> be contacted instead of licensee</span>
                                  </div>
                                  <div fxLayout="row" class="f-s-16 m-b-8">
                                    <span *ngIf="license.attorneyConsent">Attorney consent document received</span>
                                    <span *ngIf="!license.attorneyConsent">Attorney consent document <b>not yet</b> received</span>
                                  </div>
                                  <div fxLayout="row" fxLayoutGap="16px">
                                    <div fxLayout="column">
                                      <span><b>Name</b></span>
                                      <span>{{license.attorneyName}}</span>
                                    </div>
                                    <div fxLayout="column">
                                      <span><b>Phone Number</b></span>
                                      <span>{{license.attorneyPhone | mask: '(000) 000-0000 000000'}}</span>
                                    </div>
                                    <div fxLayout="column">
                                      <span><b>Email</b></span>
                                      <span>{{license.attorneyEmail}}</span>
                                    </div>
                                  </div>
                                  <div fxLayout="column">
                                    <span><b>Attorney's communication preference and procedure</b></span>
                                    <span fxFlex="100" class="text-wrap">{{license.attorneyNotes}}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="m-t-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="editingLicense">
                                <div fxLayout="column" fxFlex="100">
                                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                      <span class="f-2-20"><strong>Attorney Name</strong></span>
                                      <mat-form-field>
                                        <input matInput placeholder="Attorney Name" formControlName="attorneyName">
                                      </mat-form-field>
                                    </div>
                                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                      <span class="f-2-20"><strong>Attorney Phone</strong></span>
                                      <mat-form-field>
                                        <input matInput placeholder="Attorney Phone" formControlName="attorneyPhone" mask="(000) 000-0000 000000" [validation]="false">
                                        <mat-error *ngIf="licenseForm.get('attorneyPhone').hasError('pattern')">
                                          Phone should match this format: 999-999-9999 x1234
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                      <span class="f-2-20"><strong>Attorney Email</strong></span>
                                      <mat-form-field>
                                        <input matInput placeholder="Attorney Email" formControlName="attorneyEmail">
                                        <mat-error *ngIf="licenseForm.get('attorneyEmail').hasError('email')">
                                          This doesn't look like a valid email address
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100" fxLayoutGap="8px">
                                      <mat-checkbox formControlName="attorneyContact">
                                        <span class="text-wrap">Attorney should be contacted instead of licensee</span>
                                      </mat-checkbox>
                                      <mat-checkbox formControlName="attorneyConsent">
                                        <span class="text-wrap">Attorney consent document received</span>
                                      </mat-checkbox>
                                    </div>
                                  </div>

                                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                    <div fxLayout="column" fxFlex="100" fxFlex.lt-lg="100">
                                      <span class="f-2-20"><strong>Attorney's communication preference and procedure</strong></span>
                                      <span><i>(Use this section only to record Attorney's communication preference and procedure. Continue to keep all other notes in "Notes" section of profile.)</i></span>
                                      <mat-form-field>
                                        <textarea matInput placeholder="Attorney's communication preference and procedure" formControlName="attorneyNotes"></textarea>
                                      </mat-form-field>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <mat-card fxFlex="25">
                        <mat-card-title>
                          <span>Attestations</span>
                        </mat-card-title>
                        <mat-card-content *ngIf="!editingLicense">
                          <div fxLayout="column" fxLayoutGap="8px">
                            <span class="f-s-16">
                              <strong>Endorsements requested</strong>
                            </span>


                            <span class="f-s-16">
                              <strong>Financial Interest</strong>
                            </span>
                            <mat-checkbox [checked]="license.financialAttestation" disabled>
                              <span class="text-wrap">Applicant attests to all financial stuff</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>License Attestation</strong>
                            </span>
                            <mat-checkbox [checked]="license.attestation" disabled>
                              <span class="text-wrap">Applicant attests to everything</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>Registration Attestation</strong>
                            </span>
                            <mat-checkbox [checked]="license.registrationAttestation" disabled>
                              <span class="text-wrap">Applicant attests to registration requirements</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>Directory Consent</strong>
                            </span>
                            <mat-checkbox [checked]="license.directoryConsent" disabled>
                              <span class="text-wrap">
                                Applicant consents to having license in the OPS License Directory
                              </span>
                            </mat-checkbox>
                            <mat-checkbox *ngIf="license.directoryConsent" [checked]="license.directoryConsentWebsite" disabled>
                              <span class="text-wrap">
                                Applicant consents to have their website displayed in the OPS License Directory: <strong>{{license.directoryWebsite}}</strong>
                              </span>
                            </mat-checkbox>
                            <mat-checkbox *ngIf="license.directoryConsent" [checked]="license.directoryConsentEmail" disabled>
                              <span class="text-wrap">
                                Applicant consents to have their email displayed in the OPS License Directory: <strong>{{license.directoryEmail}}</strong>
                              </span>
                            </mat-checkbox>
                            <mat-checkbox *ngIf="license.directoryConsent" [checked]="license.directoryConsentPhone" disabled>
                              <span class="text-wrap">
                                Applicant consents to have their phone number displayed in the OPS License Directory: <strong>{{license.directoryPhone | mask: '(000) 000-0000 000000' }}</strong>
                              </span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>Electronic Signature</strong>
                            </span>
                            <span>{{license.electronicSignature}}</span>

                          </div>
                        </mat-card-content>

                        <mat-card-content *ngIf="editingLicense">
                          <div fxLayout="column" fxLayoutGap="8px">
                            <span class="f-s-16">
                              <strong>Endorsements requested</strong>
                            </span>

                            <span class="f-s-16">
                              <strong>Financial Interest</strong>
                            </span>
                            <mat-checkbox formControlName="financialAttestation" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                              <span class="text-wrap">Applicant attests to all financial stuff</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>License Attestation</strong>
                            </span>
                            <mat-checkbox formControlName="attestation" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                              <span class="text-wrap">Applicant attests to everything</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>Registration Attestation</strong>
                            </span>
                            <mat-checkbox formControlName="registrationAttestation" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                              <span class="text-wrap">Applicant attests to registration requirements</span>
                            </mat-checkbox>

                            <span class="f-s-16">
                              <strong>Directory Consent</strong>
                            </span>
                            <mat-checkbox formControlName="directoryConsent" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                              <span class="text-wrap">
                                Applicant consents to having license in the OPS License Directory
                              </span>
                            </mat-checkbox>
                            <div fxlayout="row" fxLayoutGap="10px">
                              <mat-checkbox formControlName="directoryConsentWebsite" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                                <span class="text-wrap">
                                  Applicant consents to have their website displayed in the OPS License Directory:
                                </span>
                              </mat-checkbox>
                              <mat-form-field>
                                <input matInput placeholder="Website" formControlName="directoryWebsite">
                              </mat-form-field>
                            </div>
                            <div fxlayout="row" fxLayoutGap="10px">
                              <mat-checkbox formControlName="directoryConsentEmail" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                                <span class="text-wrap">
                                  Applicant consents to have their email displayed in the OPS License Directory:
                                </span>
                              </mat-checkbox>
                              <mat-form-field>
                                <input matInput placeholder="Email" formControlName="directoryEmail">
                              </mat-form-field>
                            </div>
                            <div fxlayout="row" fxLayoutGap="10px">
                              <mat-checkbox formControlName="directoryConsentPhone" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin}">
                                <span class="text-wrap">
                                  Applicant consents to have their phone number displayed in the OPS License Directory:
                                </span>
                              </mat-checkbox>
                              <mat-form-field>
                                <input matInput placeholder="Phone" formControlName="directoryPhone" mask="(000) 000-0000 000000" >
                              </mat-form-field>
                            </div>
                            <span class="f-s-16">
                              <strong>Electronic Signature</strong>
                            </span>
                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                              <mat-label>
                                Signature
                              </mat-label>
                              <input matInput placeholder="Signature" formControlName="electronicSignature">
                              <mat-error *ngIf="licenseForm.get('electronicSignature').invalid">
                                Signature is required
                              </mat-error>
                            </mat-form-field>

                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </mat-card-content>
                </mat-card>

                <div fxLayout="row" fxLayoutGap="8px">
                  <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" [fxFlex]="editingLicense? '100' : '50'">
                    <mat-card-title>
                      <span>Testing Lab Address</span>
                    </mat-card-title>
                    <mat-card-content>
                      <mat-card>
                        <mat-card-content *ngIf="!editingLicense">
                          <div fxLayout="column" fxLayoutGap="16px">
                            <span class="f-s-18"><strong>Physical Address</strong></span>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                              <span>{{license.physicalStreet}}</span>
                              <span>{{license.physicalCity}}</span>
                              <span>{{license.physicalState}}</span>
                              <span>{{license.physicalZip}}</span>
                              <span>{{license.physicalCounty}}</span>
                            </div>

                            <div fxLayout="column">
                              <span><strong>Description of the premises</strong></span>
                              <div>
                                <textarea matInput placeholder="Describe the Premises" cols="2" [value]="license.premisesDescription"></textarea>
                              </div>
                            </div>
                          </div>

                          <div fxLayout="column" class="m-t-8">
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                              <span class="f-s-18"><strong>Mailing Address</strong></span>
                              <mat-checkbox [checked]="license.mailingIsPhysical" disabled [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'TestingLabLicenses', license.id.toString())}">
                                Same as Physical
                              </mat-checkbox>
                            </div>
                            <div *ngIf="!license.mailingIsPhysical" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                              <span [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'TestingLabLicenses', license.id.toString())}">{{license.mailingStreet}}</span>
                              <span [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'TestingLabLicenses', license.id.toString())}">{{license.mailingCity}}</span>
                              <span [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'TestingLabLicenses', license.id.toString())}">{{license.mailingState}}</span>
                              <span [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'TestingLabLicenses', license.id.toString())}">{{license.mailingZip}}</span>
                              <span [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'TestingLabLicenses', license.id.toString())}">{{license.mailingCounty}}</span>
                            </div>
                          </div>
                        </mat-card-content>

                        <mat-card-content *ngIf="editingLicense">
                          <div fxLayout="column">
                            <span class="f-s-18"><strong>Physical Address</strong></span>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                              <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                                <mat-error *ngIf="licenseForm.get('physicalStreet').invalid">
                                  Please provide a street
                                </mat-error>
                              </mat-form-field>

                              <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                <input matInput placeholder="City" formControlName="physicalCity">
                                <mat-error *ngIf="licenseForm.get('physicalCity').invalid">
                                  Please provide a city
                                </mat-error>
                              </mat-form-field>

                              <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                                <mat-label>State/US territory/freely associated state</mat-label>
                                <mat-select formControlName="physicalState">
                                  <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="licenseForm.get('physicalState').invalid">
                                  Please select a state
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                              <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                <input matInput placeholder="Zip" formControlName="physicalZip">
                                <mat-error *ngIf="licenseForm.get('physicalZip').hasError('required')">
                                  Please provide a zipcode
                                </mat-error>
                                <mat-error *ngIf="licenseForm.get('physicalZip').hasError('pattern')">
                                  Zipcode should match this format: 99999 or 99999-1234
                                </mat-error>
                              </mat-form-field>

                              <mat-form-field fxFlex="20" fxFlex.lt-md="100"
                                *ngIf="licenseForm.get('physicalState').value === 'OR'">
                                <mat-label>County</mat-label>
                                <mat-select formControlName="physicalCounty">
                                  <mat-option>-- None --</mat-option>
                                  <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <span>Please provide a brief description of the premises</span>
                            <div>
                              <mat-form-field fxFlex="75">
                                <textarea matInput placeholder="Describe the Premises" cols="2" formControlName="premisesDescription"></textarea>
                              </mat-form-field>
                            </div>
                          </div>

                          <div fxLayout="column" class="m-t-8">
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                              <span class="f-s-18"><strong>Mailing Address</strong></span>
                              <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()" [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'TestingLabLicenses', license.id.toString())}">
                                Same as Physical
                              </mat-checkbox>
                            </div>
                            <div fxLayout="column" [@slideInOut] *ngIf="!licenseForm.get('mailingIsPhysical').value">
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                  <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet" [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'TestingLabLicenses', license.id.toString())}"
                                    (blur)="validateMailingStreet()">
                                  <mat-error *ngIf="validateMailingStreet()">
                                    Please provide a street
                                  </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                  <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()" [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'TestingLabLicenses', license.id.toString())}">
                                  <mat-error *ngIf="validateMailingCity()">
                                    Please provide a city
                                  </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                                  <mat-label>State/US territory/freely associated state</mat-label>
                                  <mat-select formControlName="mailingState" (blur)="validateMailingState()" [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'TestingLabLicenses', license.id.toString())}">
                                    <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="validateMailingState()">
                                    Please provide a state
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                  <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()" [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'TestingLabLicenses', license.id.toString())}">
                                  <mat-error *ngIf="validateMailingZip()">
                                    Please provide a zipcode
                                  </mat-error>
                                  <mat-error *ngIf="licenseForm.get('mailingZip').hasError('pattern')">
                                    Zipcode should match this format: 99999 or 99999-1234
                                  </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="20" fxFlex.lt-md="100"
                                  *ngIf="licenseForm.get('mailingState').value === 'OR'">
                                  <mat-label>County</mat-label>
                                  <mat-select formControlName="mailingCounty" [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'TestingLabLicenses', license.id.toString())}">
                                    <mat-option>-- None --</mat-option>
                                    <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mat-card-content>
                  </mat-card>

                  <mat-card fxFlex="50" fxFlex.lt-lg="100" [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" *ngIf="!editingLicense">
                    <mat-card-title>
                      <span>Current Documents</span>
                    </mat-card-title>
                    <mat-card-content fxLayout="column" fxLayoutGap="8px">
                      <mat-card>
                        <mat-card-content>
                          <div fxLayout="column">
                            <div fxLayout="row" *ngIf="!sharedService.mobile">
                              <span class="f-s-16" fxFlex="35"><strong>File</strong></span>
                              <span class="f-s-16" fxFlex="25"><strong>Requirement</strong></span>
                              <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                              *ngFor="let document of this.license.licenseDocuments" style="justify-content: center;">
                              <ng-container *ngIf="!document.deprecated">
                                <span fxFlex="35" fxFlex.lt-lg="40" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                                (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                                <div fxFlex="25" fxLayout="column">
                                  <span *ngFor="let type of document.types">
                                    {{documentTypeReference[type]}}
                                  </span>
                                </div>
                                <span fxFlex="20">{{document.dateCreated | date}}</span>
                              </ng-container>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>

                      <div fxLayout="row">
                        <button type="button" color="primary" mat-flat-button (click)="viewArchivedDocuments()">
                          <span *ngIf="!viewArchived">
                            View Archived Documents
                          </span>
                          <span *ngIf="viewArchived">
                            Hide Archived Documents
                          </span>
                        </button>
                      </div>

                      <mat-card-title *ngIf="viewArchived">
                        <span>Archived Documents</span>
                      </mat-card-title>
                      <mat-card *ngIf="viewArchived" [style.background]="'#c7cdd1'">
                        <mat-card-content>
                          <div fxLayout="column">
                            <div fxLayout="row" *ngIf="!sharedService.mobile">
                              <span class="f-s-16" fxFlex="35"><strong>File</strong></span>
                              <span class="f-s-16" fxFlex="25"><strong>Requirement</strong></span>
                              <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                              *ngFor="let document of this.license.licenseDocuments" style="justify-content: center;">
                              <ng-container *ngIf="document.deprecated">
                                <span fxFlex="35" fxFlex.lt-lg="40" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                                (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                                <div fxFlex="25" fxLayout="column">
                                  <span *ngFor="let type of document.types">
                                    {{documentTypeReference[type]}}
                                  </span>
                                </div>

                                <span fxFlex="20">{{document.dateCreated | date}}</span>
                              </ng-container>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mat-card-content>
                  </mat-card>
                </div>
            </div>
          </form>

          <!--Documents-->
          <form [formGroup]="documentsForm" *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxFlex="100" *ngIf="editingLicense">
              <mat-card fxFlex="50" fxFlex.lt-lg="100" [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor">
                <mat-card-title>
                  Upload Supporting Documents
                </mat-card-title>
                <mat-card-content>
                  <mat-card>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <span class="f-2-14">For each document select the requirements it verifies</span>
                        <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">
                          <div fxLayout="column">

                            <mat-checkbox formControlName="orelap">
                              <span class="text-wrap" [ngClass]="!documentsValid && !documentsForm.get('orelap').value ? 'text-red-400' : ''">
                                ORELAP Accreditation</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="premisesSketch">
                              <span class="text-wrap"
                                [ngClass]="!documentsValid && !documentsForm.get('premisesSketch').value ? 'text-red-400' : ''">
                                Premises Plan Including Boundaries, and Camera Plan
                              </span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="premisePicture">
                              <span class="text-wrap" [ngClass]="!documentsValid && !documentsForm.get('premisePicture').value ? 'text-red-400' : ''">
                                Proposed Premises Pictures
                              </span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="updatedPremisePicture">
                              <span class="text-wrap">Updated premise pictures</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="socialEquityPlan">
                              <span class="text-wrap"
                                [ngClass]="!documentsValid && !documentsForm.get('socialEquityPlan').value ? 'text-red-400' : ''">
                                Social Equity Plan
                              </span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="updatedEquityPlan">
                              <span class="text-wrap">Updated Social Equity Plan</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="equityPlanEvaluation">
                              <span class="text-wrap">Social Equity Plan Evaluation</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="physical">
                              <span class="text-wrap">Physical Application</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="gisReview">
                              <span class="text-wrap">Gis Review</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="interviewDocumentation">
                              <span class="text-wrap">Interview Documentation</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="siteInspectionFollowUp">
                              <span class="text-wrap">Site Inspection Follow Up</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="caseDocumentation">
                              <span class="text-wrap">Case Documentation</span>
                            </mat-checkbox>

                            <mat-checkbox formControlName="other">
                              <span class="text-wrap">Other</span>
                            </mat-checkbox>

                          </div>

                          <input type="file" class="file-input" (change)="uploadLicenseDocument($event)" #fileUpload />
                          <div fxLayout="row" fxLayoutGap="8px">
                            <div class="file-upload">
                              <button [disabled]="!documentsForm.get('orelap').value &&
                                                  !documentsForm.get('premisesSketch').value &&
                                                  !documentsForm.get('socialEquityPlan').value &&
                                                  !documentsForm.get('other').value &&
                                                  !documentsForm.get('physical').value &&
                                                  !documentsForm.get('gisReview').value &&
                                                  !documentsForm.get('gisReview').value &&
                                                  !documentsForm.get('interviewDocumentation').value &&
                                                  !documentsForm.get('siteInspectionFollowUp').value &&
                                                  !documentsForm.get('caseDocumentation').value &&
                                                  !documentsForm.get('premisePicture').value &&
                                                  !documentsForm.get('updatedEquityPlan').value &&
                                                  !documentsForm.get('equityPlanEvaluation').value &&
                                                  !documentsForm.get('updatedPremisePicture').value"
                                        mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                                Browse
                                <mat-icon>attach_file</mat-icon>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </mat-card-content>
              </mat-card>

              <mat-card fxFlex="50" fxFlex.lt-lg="100" [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor">
                <mat-card-title>
                  <span>Current Documents</span>
                </mat-card-title>
                <mat-card-content>
                  <mat-card>
                    <mat-card-content>
                      <div fxLayout="column">
                        <div fxLayout="row" *ngIf="!sharedService.mobile">
                          <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                          <span class="f-s-16" fxFlex="10"><strong>Archive</strong></span>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                          *ngFor="let document of this.license.licenseDocuments" style="justify-content: center;">
                          <ng-container *ngIf="!document.deprecated">
                            <span fxFlex="30" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                            (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                            <div fxFlex="20" fxLayout="column">
                              <span *ngFor="let type of document.types">
                                {{documentTypeReference[type]}}
                              </span>
                            </div>

                            <span fxFlex="20">{{document.dateCreated | date}}</span>

                            <button fxFlex="10" [hidden]="!editingLicense || (!authService.isAdmin && !authService.isTestingLabAdmin)" mat-icon-button color="warn" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                              <mat-icon>archive</mat-icon>
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <div fxLayout="row">
                    <button type="button" color="primary" mat-flat-button (click)="viewArchivedDocuments()">
                      <span *ngIf="!viewArchived">
                        View Archived Documents
                      </span>
                      <span *ngIf="viewArchived">
                        Hide Archived Documents
                      </span>
                    </button>
                  </div>

                  <br />
                  <mat-card-title *ngIf="viewArchived">
                    <span>Archived Documents</span>
                  </mat-card-title>
                  <mat-card *ngIf="viewArchived" [style.background]="'#c7cdd1'">
                    <mat-card-content>
                      <div fxLayout="column">
                        <div fxLayout="row" *ngIf="!sharedService.mobile">
                          <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                          <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                          <span class="f-s-16" fxFlex="10"><strong>Unarchive</strong></span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                          *ngFor="let document of this.license.licenseDocuments" style="justify-content: center;">
                          <ng-container *ngIf="document.deprecated">
                            <span fxFlex="30" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                            (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                            <div fxFlex="20" fxLayout="column">
                              <span *ngFor="let type of document.types">
                                {{documentTypeReference[type]}}
                              </span>
                            </div>

                            <span fxFlex="20">{{document.dateCreated | date}}</span>

                            <button fxFlex="10" [hidden]="!editingLicense || !authService.isAdmin" mat-icon-button color="primary" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                              <mat-icon>unarchive</mat-icon>
                            </button>

                            <span fxFlex></span>

                            <button *ngIf="editingLicense && authService.isAdmin" type="button" fxFlex="10" fxLayoutAlign="start end" mat-icon-button class="m-8" (click)="deleteDocument(document.id, document.name)"
                              color="warn" aria-label="Delete">
                            <mat-icon>delete</mat-icon>
                          </button>
                          </ng-container>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </mat-card-content>
              </mat-card>
            </div>
          </form>

          <!--Applicants-->
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor"
                      [fxFlex]="editingApplicant? '100': '50'"
                      [ngStyle]="{'display': !editingEntityApplicant? '' : 'none'}">
              <mat-card-content fxLayoutGap="8px">
                <app-applicant fxFlex.lt-md="100"
                  [applicationDate]="license.applicationDate"
                  [licenseId]="license.id"
                  [licenseType]="sharedService.testingLab"
                  [primaryContactId]="license.primaryContactId"
                  [documentTypes]="documentTypes"
                  tableColor="orange"
                  [renewal]="license.isRenewal"
                  (notifyDeduplicate)="deduplicateApplicant($event)"
                  (notifyParent)="editApplicant($event)"
                  (notifyUpdate)="updateApplicant($event)"
                  (notifyDelete)="deleteApplicant($event)"
                  (setPointOfContact)="setPointOfContact($event)"
                  [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor"
                  [editingLicense]="editingLicense"
                  [renewalChanges]="changedFields"
                  [admin]="authService.isTestingLabAdmin">
                </app-applicant>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" style="cursor: pointer;" *ngIf="!dedeuplicating">
                  <button type="button" color="primary" mat-flat-button (click)="openContactInformationChangeLog()">
                    <span>
                      Contact Information History
                    </span>
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" [fxFlex]="editingEntityApplicant? '100': '50'" *ngIf="!editingApplicant">
              <mat-card-content>
                <app-entity-applicant
                  fxFlex.lt-md="100"
                  [licenseId]="license.id"
                  [licenseType]="sharedService.testingLab"
                  tableColor="orange"
                  [renewal]="license.isRenewal"
                  [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor"
                  [documentTypes]="documentTypes"
                  [editingLicense]="editingLicense"
                  [individualApplicants]="individualApplicants?.applicants"
                  (notifyParent)="editEntityApplicant($event)"
                  [renewalChanges]="changedFields"
                  [admin]="authService.isTestingLabAdmin">
                </app-entity-applicant>
              </mat-card-content>
            </mat-card>
          </div>

          <!--Payments-->
          <app-manage-payments *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]
                               [entityId]="license.id.toString()"
                               [entityType]="paymentService.TestingLabPaymentOption"
                               [allowMultiplePayments]='true'
                               [editingPayment]="editingLicense"
                               (notifyParent)="paymentUpdated($event)"
                               [allowDelete]='authService.isAdmin'
                               [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor"
                               [renewal]="license.isRenewal">
          </app-manage-payments>

          <!--Notes-->
          <app-manage-notes *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]
                            [entityId]="license.id.toString()"
                            [entityType]="sharedService.testingLab"
                            [editingNotes]="editingLicense"
                            [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor">
          </app-manage-notes>
          <app-manager-notes *ngIf="!editingApplicant && !editingEntityApplicant && (authService.isAdmin || (license.managerFlag == 'blue' || license.managerFlag == 'yellow'))" [isAdmin]="authService.isAdmin"
                             [notes]="license.managerNotes" [managerFlag]="license.managerFlag" [reviewType]="license.reviewType" [editingNotes]="editingLicense" [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" (notifyParent)="managerNotesUpdated($event)">
          </app-manager-notes>
          <!--Worker Pemits-->
          <app-associated-worker-permits *ngIf="!editingApplicant && !editingEntityApplicant" [licenseType]="sharedService.testingLab" (removePTSPermit)="removePTSPermit($event)" [isAdmin]="authService.isAdmin || authService.isServiceCenterAdmin"
            (addPermit)="addPermit($event)" [id]="license.id" [permits]="license.workerPermits" [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor">
          </app-associated-worker-permits>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
            <!--Tasks-->
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Tasks
              </mat-card-title>
              <mat-card-content>
                <mat-table [dataSource]="taskSource" aria-describedby="tasks" class="mat-elevation-z8 m-b-0">

                  <ng-container matColumnDef="task">
                    <mat-header-cell *matHeaderCellDef>Task</mat-header-cell>
                    <mat-cell *matCellDef="let task">
                      <span class="mobile-label">Task</span>
                      {{task.taskText}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef="">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let task">
                      <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
                        <div fxLayout="column" fxLayoutGap="8px">
                          <button *ngIf="task.sendNotice" mat-flat-button color="primary" style="line-height: normal" (click)="sendTaskNotice(task)">
                            <span class="text-wrap">{{task.taskText}}</span>
                          </button>
                          <button *ngIf="task.acknowledge" mat-flat-button color="primary" (click)="acknowledgeTask(task.id)">
                            Acknowledge
                          </button>
                        </div>
                        <button *ngIf="task.id !== 0 && (authService.isTestingLabAdmin || authService.isAdmin)" mat-icon-button color="warn" aria-label="Delete Task"
                          (click)="deleteTask(task)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="taskColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: taskColumns"></mat-row>
                </mat-table>
              </mat-card-content>
            </mat-card>

            <!--Complete-->
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Complete Testing Lab
              </mat-card-title>
              <mat-card-content>
                <mat-card>
                  <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.requestWithdrawDate != null && license.requestWithdrawDate != ''">
                        <span><strong>Requested Withdraw On</strong></span>
                        <span>{{license.requestWithdrawDate | date}}</span>
                      </div>
                      <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.approveWithdrawDate != null && license.approveWithdrawDate != ''">
                        <span><strong>Approved Withdraw On</strong></span>
                        <span>{{license.approveWithdrawDate | date}}</span>
                      </div>
                      <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.denyWithdrawDate != null && license.denyWithdrawDate != ''">
                        <span><strong>Denied Withdraw On</strong></span>
                        <span>{{license.denyWithdrawDate | date}}</span>
                      </div>

                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.requestSurrenderDate != null && license.requestSurrenderDate != ''">
                          <span><strong>Requested Surrender On</strong></span>
                          <span>{{license.requestSurrenderDate | date}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.approveSurrenderDate != null && license.approveSurrenderDate != ''">
                          <span><strong>Approved Surrender On</strong></span>
                          <span>{{license.approveSurrenderDate | date}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.denySurrenderDate != null && license.denySurrenderDate != ''">
                          <span><strong>Denied Surrender On</strong></span>
                          <span>{{license.denySurrenderDate | date}}</span>
                        </div>

                      <div fxLayout="row" fxLayoutGap="8px" style="font-size:large">
                        <span><strong>Current Status</strong></span>
                        <div fxLayout="row" fxLayoutGap="8px">
                          <span>{{license.status}}</span>
                          <span *ngIf="license.isRenewal">Renewal</span>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutGap="8px" style="font-size:large">
                        <span><strong>Submitted On</strong></span>
                        <span>{{license.applicationDate | date}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.isRenewal && !editingLicense">
                          <span *ngIf="!license.isRenewal"><strong>Approved Date</strong></span>
                          <span *ngIf="license.isRenewal"><strong>Renewed Date</strong></span>
                          <span>{{license.approvedDate | date}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.isRenewal && editingLicense && authService.isAdmin && licenseForm.get('status').value == 'Approved'">
                          <mat-form-field [formGroup]="licenseForm">
                            <mat-label *ngIf="!license.isRenewal">Approved Date</mat-label>
                            <mat-label *ngIf="license.isRenewal">Renewed Date</mat-label>
                            <input placeholder="mm/dd/yyyy" formControlName="approvedDate" matInput [matDatepicker]="approvedDate"/>
                            <mat-datepicker-toggle matSuffix [for]="approvedDate"></mat-datepicker-toggle>
                            <mat-datepicker #approvedDate>
                              <mat-datepicker-actions>
                                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                <button mat-button matDatepickerCancel>Cancel</button>
                              </mat-datepicker-actions>
                            </mat-datepicker>
                          </mat-form-field>
                          <span><i>*Enter date after current expiration date. This date calculates the new expiration date.</i></span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.isRenewal && !editingLicense">
                          <span><strong>Date Renewal Approved</strong></span>
                          <span>{{license.renewedDate | date}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="8px" style="font-size:large" *ngIf="license.isRenewal && editingLicense && licenseForm.get('status').value == 'Approved'">
                          <mat-form-field [formGroup]="licenseForm">
                            <mat-label>Date Renewal Approved</mat-label>
                            <input placeholder="mm/dd/yyyy" formControlName="renewedDate" matInput [matDatepicker]="renewedDate"/>
                            <mat-datepicker-toggle matSuffix [for]="renewedDate"></mat-datepicker-toggle>
                            <mat-datepicker #renewedDate>
                              <mat-datepicker-actions>
                                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                <button mat-button matDatepickerCancel>Cancel</button>
                              </mat-datepicker-actions>
                            </mat-datepicker>
                          </mat-form-field>
                          <span><i>*Date that staff marked the renewal as approved. This date shows up on public side.</i></span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="" *ngIf="license.status == sharedService.requestSurrender && authService.isTestingLabAdmin">
                          <button mat-flat-button type="button" color="primary" (click)="surrenderLicense(true, license.id)">
                             Approve Surrender Request
                          </button>
                          <button  mat-flat-button type="button" color="warn" (click)="surrenderLicense(false, license.id)">
                            Deny Surrender Request
                          </button>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="" *ngIf="license.status == sharedService.requestWithdraw && authService.isTestingLabAdmin">
                          <button mat-flat-button type="button" color="primary" (click)="withdrawLicense(true, license.id)">
                             Approve Withdraw Request
                        </button>
                        <button  mat-flat-button type="button" color="warn" (click)="withdrawLicense(false, license.id)">
                          Deny Withdraw Request
                      </button>
                        </div>

                      <span *ngIf="license.status != sharedService.requestWithdraw && license.status != sharedService.requestSurrender"><strong>Update Status</strong></span>
                      <div fxLayout="row" fxFlex="100" [hidden]="authService.isTestingLabAdmin || license.status == sharedService.requestWithdraw || license.status == sharedService.requestSurrender || license.status == sharedService.revoked || license.status == sharedService.pendingRevocation || license.status == sharedService.denied
                      || license.status == sharedService.pendingDenial || license.status == sharedService.approved || license.status == sharedService.awaitingPayment || license.status == sharedService.withdrawn || license.status == sharedService.closed">
                        <mat-form-field [formGroup]="licenseForm" fxFlex="100">
                          <mat-label>
                            Status
                          </mat-label>
                          <mat-select formControlName="status" fxFlex="100">
                            <mat-option *ngIf="license.isRenewal"[value]="sharedService.renewalSubmitted">Renewal Submitted/For Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && !license.isRenewal" [value]="sharedService.underReview">Under Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && !license.isRenewal" [value]="sharedService.peerReview">Ready For Peer Review</mat-option>
                            <mat-option *ngIf="license.isRenewal" [value]="sharedService.renewalPeerReview">Renewal Ready for Peer Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && validateBackgroundCheckCompleted() && !license.isRenewal" [value]="sharedService.submittedForApproval">Submitted for Approval</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && validateBackgroundCheckCompleted() && license.isRenewal" [value]="sharedService.renewalSubmittedForApproval">Renewal Submitted for Approval</mat-option>
                            <mat-option *ngIf="hasPaidApplicationFee && !license.isRenewal" [value]="sharedService.awaitingPayment">Approved, Pending Payment and Clearance</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && !license.isRenewal" [value]="sharedService.incomplete">Incomplete</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && !license.isRenewal" [value]="sharedService.insufficient">Insufficient</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && !license.isRenewal" [value]="sharedService.readyForLicensingManagerReview">Ready for Licensing Manager Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && !license.isRenewal" [value]="sharedService.readyForComplianceManagerReview">Ready for Compliance Manager Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && !license.isRenewal" [value]="sharedService.siteInspectionComplete">Site Inspection Complete, Awaiting Follow Up</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && license.isRenewal" [value]="sharedService.renewalReadyForLicensingManagerReview">Renewal Ready for Licensing Manager Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && license.isRenewal" [value]="sharedService.renewalReadyForComplianceManagerReview">Renewal Ready for Compliance Manager Review</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && license.isRenewal" [value]="sharedService.renewalSubmittedForApprovalForLicensingManager">Renewal Submitted for Approval for Licensing Manager</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved && validateChecklist() && license.isRenewal" [value]="sharedService.renewalSiteInspectionComplete">Renewal Site Inspection Complete, Awaiting Follow Up</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved" [value]="sharedService.submitForClosure">Submit For Closure</mat-option>
                            <mat-option *ngIf="license.status != sharedService.approved" [value]="sharedService.pendingDenial">Pending Denial</mat-option>
                            <mat-option *ngIf="license.status == sharedService.approved" [value]="sharedService.pendingRevocation">Pending Revocation</mat-option>
                            <mat-option *ngIf="license.status == sharedService.approved" [value]="sharedService.expired">Expired</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxFlex="100" [hidden]="!authService.isTestingLabAdmin ||license.status == sharedService.requestWithdraw || license.status == sharedService.requestSurrender">
                        <mat-form-field [formGroup]="licenseForm" fxFlex="100">
                          <mat-label>
                            Status
                          </mat-label>
                          <mat-select formControlName="status" fxFlex="100">
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.submitted">Submitted</mat-option>
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.pending">Pending</mat-option>
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.underReview">Under Review</mat-option>
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.peerReview">Ready For Peer Review</mat-option>
                            <mat-option  *ngIf="license.isRenewal" [value]="sharedService.renewalPeerReview">Renewal Ready for Peer Review</mat-option>
                            <mat-option  *ngIf="license.isRenewal && authService.isAdmin" [value]="sharedService.pendingRenewal">Pending Renewal</mat-option>
                            <mat-option  *ngIf="license.isRenewal" [value]="sharedService.renewalSubmitted">Renewal Submitted/For Review</mat-option>
                            <mat-option  *ngIf="license.isRenewal" [value]="sharedService.renewalReviewTimely">Renewal Under Review/Timely</mat-option>
                            <mat-option  *ngIf="license.isRenewal" [value]="sharedService.renewalReviewUntimely">Renewal Under Review/Untimely</mat-option>
                            <mat-option  *ngIf="authService.isAdmin && license.isRenewal" [value]="sharedService.renewalApprovedPendingPaymentandClearance">Renewal Approved, Pending Fee Payment and Clearance</mat-option>
                            <mat-option  *ngIf="authService.isAdmin" [value]="sharedService.renewed">Renewed</mat-option>
                            <mat-option  *ngIf="validateChecklist() && validateBackgroundCheckCompleted() && !license.isRenewal" [value]="sharedService.submittedForApproval">Submitted for Approval</mat-option>
                            <mat-option  *ngIf="validateChecklist() && validateBackgroundCheckCompleted() && license.isRenewal" [value]="sharedService.renewalSubmittedForApproval">Renewal Submitted for Approval</mat-option>
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.incomplete">Incomplete</mat-option>
                            <mat-option  *ngIf="!license.isRenewal" [value]="sharedService.insufficient">Insufficient</mat-option>
                            <mat-option  *ngIf="validateChecklist() && !license.isRenewal" [value]="sharedService.readyForLicensingManagerReview">Ready for Licensing Manager Review</mat-option>
                            <mat-option  *ngIf="validateChecklist() && !license.isRenewal" [value]="sharedService.readyForComplianceAssignmentAndInspection">Ready for Compliance Assignment and Inspection</mat-option>
                            <mat-option  *ngIf="validateChecklist() && !license.isRenewal" [value]="sharedService.readyForComplianceManagerReview">Ready for Compliance Manager Review</mat-option>
                            <mat-option  *ngIf="validateChecklist() && !license.isRenewal" [value]="sharedService.siteInspectionComplete">Site Inspection Complete, Awaiting Follow Up</mat-option>
                            <mat-option  *ngIf="validateChecklist() && license.isRenewal" [value]="sharedService.renewalReadyForLicensingManagerReview">Renewal Ready for Licensing Manager Review</mat-option>
                            <mat-option  *ngIf="validateChecklist() && license.isRenewal" [value]="sharedService.renewalReadyForComplianceAssignmentAndInspection">Renewal Ready for Compliance Assignment and Inspection</mat-option>
                            <mat-option  *ngIf="validateChecklist() && license.isRenewal" [value]="sharedService.renewalReadyForComplianceManagerReview">Renewal Ready for Compliance Manager Review</mat-option>
                            <mat-option  *ngIf="validateChecklist() && license.isRenewal" [value]="sharedService.renewalSubmittedForApprovalForLicensingManager">Renewal Submitted for Approval for Licensing Manager</mat-option>
                            <mat-option  *ngIf="validateChecklist() && license.isRenewal" [value]="sharedService.renewalSiteInspectionComplete">Renewal Site Inspection Complete, Awaiting Follow Up</mat-option>
                            <mat-option  [value]="sharedService.closed">Closed</mat-option>
                            <mat-option  [value]="sharedService.withdrawn">Withdrawn</mat-option>
                            <mat-option  *ngIf="hasPaidApplicationFee && !license.isRenewal" [value]="sharedService.awaitingPayment">Approved, Pending Payment and Clearance</mat-option>
                            <mat-option  *ngIf="licensePaid && validateChecklist() && validateBackgroundCheckCompleted()"  [value]="sharedService.approved">Approved</mat-option>
                            <mat-option  [value]="sharedService.submitForClosure">Submit For Closure</mat-option>
                            <mat-option  [value]="sharedService.pendingDenial">Pending Denial</mat-option>
                            <mat-option  [value]="sharedService.denied">Denied</mat-option>
                            <mat-option  [value]="sharedService.surrendered">Surrendered</mat-option>
                            <mat-option  *ngIf="license.status == sharedService.approved" [value]="sharedService.pendingRevocation">Pending Revocation</mat-option>
                            <mat-option  [value]="sharedService.revoked">Revoked</mat-option>
                            <mat-option  [value]="sharedService.expired">Expired</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" fxFlex="100" *ngIf="!validateBackgroundCheckCompleted()">
                        <span style="color: red"><em>License missing passing background check or background check completed date</em></span>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mat-card-content>
              <button type="button" color="primary" mat-flat-button *ngIf="canTransfer() && !license.isRenewal && (authService.isAdmin || authService.isTestingLabAdmin)" (click)="transferPTS(license.id)">
                <span>Transfer PTS Items</span>
              </button>
            </mat-card>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="!dedeuplicating && !editingApplicant && !editingEntityApplicant">
            <!--Previous Licenses-->
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Historical Licenses
              </mat-card-title>
              <mat-card-content fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutGap="8px">
                <mat-table [dataSource]="expiredLicensesSource" matSort aria-describedby="expiredLicenses" #expiredLicensesSort="matSort" class="mat-elevation-z8 m-b-0">

                  <ng-container matColumnDef="licenseId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>License ID</mat-header-cell>
                    <mat-cell *matCellDef="let license" [routerLink]="['/testing-lab/assigned', license.id]">
                      <span class="mobile-label">License ID</span>
                      {{license.licenseId}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="applicationDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</mat-header-cell>
                    <mat-cell *matCellDef="let license" [routerLink]="['/testing-lab/assigned', license.id]">
                      <span class="mobile-label">Submitted Date</span>
                      {{license.applicationDate | date}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="approvedDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Approved/Renewed Date</mat-header-cell>
                    <mat-cell *matCellDef="let license" [routerLink]="['/testing-lab/assigned', license.id]">
                      <span class="mobile-label">Approved Date</span>
                      {{license.approvedDate | date}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expirationDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
                    <mat-cell *matCellDef="let license" [routerLink]="['/testing-lab/assigned', license.id]">
                      <span class="mobile-label">Expiration Date</span>
                      {{license.expirationDate | date}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let license" [routerLink]="['/testing-lab/assigned', license.id]">
                      <span class="mobile-label">Status</span>
                      {{license.status}}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="expiredLicensesColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: expiredLicensesColumns" ngClass="hasRenewal" [ngClass]="{'hasRenewal': row.hasRenewal, 'isRenewal': row.isRenewal && !row.hasRenewal && !row.renewed}"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25]" #expiredLicensesPaginator="matPaginator">
                </mat-paginator>
              </div>
              </mat-card-content>
            </mat-card>

            <!--Renewals-->
            <mat-card [style.background]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor" fxFlex="50" fxFlex.lt-lg="100" fxLayoutGap="20px" fxLayout="column" *ngIf="license.canRenew || license.isRenewal">
              <div fxLayout="row">
                <mat-card-title>
                  Renewal Currently Under Review
                </mat-card-title>
                <span fxFlex></span>
                <span>
                  <span (click)="createRenewal(license)" class="f-s-16" style="cursor: pointer;" *ngIf="license.canRenew && !hasRenewal && license.status == 'Approved' && (authService.isAdmin || authService.isTestingLabAdmin)">Add Renewal</span>
                  <button type="button" mat-icon-button color="primary" matTooltip="Add Renewal" matTooltipShowDelay=800
                    *ngIf="license.canRenew && !hasRenewal && license.status == 'Approved' && (authService.isAdmin || authService.isTestingLabAdmin)" aria-label="Add license" (click)="createRenewal(license)">
                    <mat-icon>post_add</mat-icon>
                  </button>
                </span>
              </div>

              <mat-card-content fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutGap="16px" *ngIf="authService.isAdmin || authService.isTestingLabAdmin">
                    <div fxLayout="column">
                      <span class="f-s-14 m-b-8" *ngIf="license.isRenewal && !editingLicense && licenseForm.get('hiddenFromTracker').value">License hidden from Up For Renewal Active Application Tracker</span>
                      <form [formGroup]="licenseForm" *ngIf="editingLicense && license.isRenewal">
                        <mat-checkbox formControlName="hiddenFromTracker">
                          Hide From Up For Renewal Active Application Tracker
                        </mat-checkbox>
                      </form>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="16px">
                    <div fxLayout="column" fxFlex="33">
                      <span class="f-s-18 m-b-8" *ngIf="license.isRenewal && !editingLicense && licenseForm.get('timely').value">Timely Renewal</span>
                      <span class="f-s-18 m-b-8" *ngIf="license.isRenewal && !editingLicense && !licenseForm.get('timely').value">Untimely Renewal</span>
                        <form [formGroup]="licenseForm" *ngIf="editingLicense && license.isRenewal">
                          <mat-checkbox formControlName="timely">
                            Timely Renewal
                          </mat-checkbox>
                        </form>
                    </div>

                    <span [fxFlex]="canTransfer()? 0: 33"></span>

                    <div fxLayout="column" fxFlex="33">
                      <button type="button" color="primary" mat-flat-button (click)="openRenewalChangeLog()" *ngIf="license.isRenewal">
                        <span>
                         Updates On Renewal
                        </span>
                      </button>
                    </div>

                    <div fxLayout="column" [fxFlex]="canTransfer()? 33: 0">
                      <button type="button" color="primary" mat-flat-button *ngIf="canTransfer() && (authService.isAdmin || authService.isTestingLabAdmin)" (click)="transferPTS(license.id)">
                        <span>Transfer PTS Items</span>
                      </button>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="16px" fxFlex>

                    <mat-table [dataSource]="[license]" fxFlex>
                      <ng-container matColumnDef="licenseId">
                        <mat-header-cell *matHeaderCellDef>License ID</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">License ID</span>
                          {{license.licenseId}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="applicationDate">
                        <mat-header-cell *matHeaderCellDef>Submitted Date</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">Submitted Date</span>
                          {{license.applicationDate | date}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="approvedDate">
                        <mat-header-cell *matHeaderCellDef>Approved Date</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">Approved Date</span>
                          {{licenseForm.get('approvedDate').value | date}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="renewedDate">
                        <mat-header-cell *matHeaderCellDef>Date Renewal Approved</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">Date Renewal Approved</span>
                          {{licenseForm.get('renewedDate').value | date}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="expirationDate">
                        <mat-header-cell *matHeaderCellDef>Expiration Date</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">Expiration Date</span>
                          {{license.expirationDate | date}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let license">
                          <span class="mobile-label">Status</span>
                          {{license.status}}
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="currentLicenseColumns"></mat-header-row>
                      <mat-row *matRowDef="let license; columns: currentLicenseColumns" [ngClass]="{'hasRenewal': license.hasRenewal, 'isRenewal': license.isRenewal && !license.hasRenewal && !license.renewed}"></mat-row>
                    </mat-table>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign.lt-md="start center"
            *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" style="cursor: pointer;">
              <span (click)="openChangeLog()">
                <mat-icon class="icon-20">history_edu</mat-icon> Change Log
              </span>
              <button  mat-button type="button" color="primary" (click)="openTaskHistory()">Task History</button>
              <button mat-button type="button" color="primary" (click)="exportLicense()">Export Original PDF</button>
            </div>
            <span fxFlex></span>
            <button mat-button type="button" color="warn" (click)="cancelWorkingLicense()">Cancel</button>
            <button mat-button type="button" color="primary" *ngIf="(!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" (click)="updateLicense(false)">Save</button>
            <button mat-button type="button" color="primary" *ngIf="(!license.hasRenewal || (license.hasRenewal && (authService.isAdmin || authService.isTestingLabAdmin)))" (click)="updateLicense(true)">Save & Close</button>
          </div>
        </div>
      </div>
      <div [@slideInOut] fxLayout="row" [hidden]="!showChecklist" [fxFlex]="showChecklist? 25: 0">
        <app-licenseChecklist
        [entityType]="sharedService.testingLab"
        [license]="license"
        [applicants]="individualApplicants != null? individualApplicants.applicants : license.applicants"
        (notifyParent)="updateChecklist($event)"
        [staff]="licenseStaff"
        [renewal]="license.isRenewal"
        >

        </app-licenseChecklist>
        </div>
      </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="Site Inspections">
    <ng-template matTabContent>
      <app-site-inspection [entityId]="license.id"
                           [renewal]="license.isRenewal"
                           [licenseId]="license.licenseId"
                           [entityType]="sharedService.testingLab"
                           [entityName]="license.entityName"
                           [staff]="licenseStaff"
                           [notes]="notes.notes"
                           [cardColor]="license.isRenewal && !license.hasRenewal && !license.renewed? '#f9f093' : license.hasRenewal ? '#c7cdd1' : cardColor"
                           tableColor="orange">
      </app-site-inspection>
    </ng-template>
  </mat-tab>
  <mat-tab label="Testing Lab Compliance">
    <ng-template matTabContent>
      <app-compliance [entityId]="license.id"
                      [renewal]="license.isRenewal"
                      [licenseId]="license.licenseId"
                      [entityReference]="license.licenseId"
                      [compliance]="compliance"
                      [entityType]="sharedService.testingLab"
                      [entityName]="license.entityName"
                      [notes]="notes.notes"
                      [staff]="licenseStaff">
      </app-compliance>
    </ng-template>
  </mat-tab>
  <mat-tab label="Reported Events">
    <ng-template matTabContent>
      <app-reported-event [entityId]="license.id"
                          [entityType]="sharedService.testingLab"
                          [reportedEvent]="reportedEvent">
      </app-reported-event>
    </ng-template>
  </mat-tab>
</mat-tab-group>


<div *ngIf="licenseLoading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
