<h2 mat-dialog-title>
  <mat-icon class="icon-20" >add_box</mat-icon> New message
</h2>
<section *ngIf="loading">
  <h2>Loading...</h2>
  <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
  </mat-progress-bar>
</section>

<section *ngIf="!loading">
  <mat-dialog-content>
    <form id="addNewInternalMessage"  [formGroup]="sendNewMessageForm">

      <!-- To -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <mat-form-field fxFlex>
              <mat-label>To: </mat-label>
              <mat-select  id="recipient" name="recipient" formControlName="recipient" (selectionChange)="selectedToValue($event)">
                <mat-option *ngFor="let r of recipients" value="{{r.recipient}}" style="font-size: 14pt;">{{r.name}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>

      <!-- From -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
        <mat-form-field fxFlex>
            <mat-label>From: </mat-label>
            <mat-select  id="from" name="from" formControlName="from" (selectionChange)="selectedFromValue($event)">
              <mat-option *ngFor="let s of senders" value="{{s.name}}" style="font-size: 14pt;">{{s.name}}</mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <!-- License -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
        <mat-form-field fxFlex>
            <mat-label>Select the license, permit. or training program you are messaging about:  </mat-label>
            <mat-select  id="from" name="from" formControlName="license" (selectionChange)="selectedLicenseValue($event)">
              <mat-option *ngFor="let l of licenses" value="{{l.id}}" style="font-size: 14pt;">{{l.id}} ({{l.name}})</mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <!-- Subject -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
            <mat-form-field  fxFlex   form="addNewInternalMessage">
              <mat-label>Subject:</mat-label>
              <input matInput placeholder="Subject" form="addNewInternalMessage" required  formControlName="subject" style="font-size: 14pt;">
            </mat-form-field>
      </div>

      <!-- Message -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid">
            <mat-form-field  fxFlex>
              <mat-label>Message here:</mat-label>
              <textarea matInput placeholder="Message" rows="10" form="addNewInternalMessage" required formControlName="message" #messageBody style="font-size: 14pt;"></textarea>
            </mat-form-field>
      </div>

      <!-- Priority -->
      <div fxLayout="row wrap" fxLayoutGap="8px grid" *ngIf="env.internalMessaging.canSetPriority">
          <mat-checkbox form="addNewInternalMessage"  formControlName="highPriority" >High Priority</mat-checkbox>
          <br />
      </div>

      <div fxLayout="row wrap" fxLayoutGap="8px grid" >
        <span>&nbsp;</span>
    </div>

      <!-- Files -->
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px grid" *ngIf="env.internalMessaging.canAttach">
        <div fxLayout="column" fxFlex="50">
          <input
            type="file"
            class="file-input"
            multiple
            (change)="onFileSelected($event)"
            #fileUpload
          />
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutGap="8px">
              <div class="file-upload">
                <button
                  mat-raised-button
                  color="primary"
                  class="upload-btn"
                  (click)="fileUpload.click()"
                >
                  Attach Files
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutGap="8px grid"  *ngIf="fileNames.length > 0 && env.internalMessaging.canAttach">
        <span>&nbsp;</span>
    </div>
      <!-- staged files -->
    <div *ngIf="fileNames.length > 0 && env.internalMessaging.canAttach">

    </div>
      <div fxLayout="row"   *ngFor="let name of fileNames">
        <div fxLayout="column"><mat-icon class="icon-20" color="warn" (click)="removeStagedFile(name)" matTooltip="Remove file: {{name}}">delete_outline</mat-icon>&nbsp;&nbsp;</div>
        <div fxLayout="column">{{ name }} </div>
      </div>
    </form>

    <!-- Buttons -->
    <div>
      <br />
      <br />
      <button mat-button [disabled]="loading" color="primary" (click)="sendMessage()" [disabled]="sending">Send Message</button>
      <button mat-button mat-dialog-close="Cancel" aria-label="Cancel">Cancel</button>
    </div>
  </mat-dialog-content>
</section>

