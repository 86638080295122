import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InternalApplicationLicense, InternalApplicationMessage, InternalApplicationNewMessage, InternalApplicationRecipient } from './internal-app-message';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InternalApplicationMessagingService } from './internal-app-messaging.service'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SharedService } from 'app/services/core/shared.service';
import { Router } from '@angular/router';
import { IStaffType } from 'app/models/user-info/staff-type';
import { IStaff } from 'app/models/user-info/staff';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-internal-app-messaging',
  templateUrl: './internal-app-messaging.component.html',
  styleUrls: ['./internal-app-messaging.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class InternalAppMessagingComponent implements OnInit {
  public environment = environment;
  public recipients: string[] = ['Psilocybin Licensing Team', 'Psilocybin Compliance Team', 'Product Tracking Team'];
  public staffMembers: IStaffType[] = [];
  messages: InternalApplicationMessage[] = [];
  includeArchived: boolean = false;
  expandedElement: InternalApplicationMessage | null;
  currentView: string = "";
  currentMailbox: string = "";
  processing: boolean = false;
  displayedColumns: string[] = ['icons', 'subject', 'recipient',  'creator', 'license','createdDate', 'assignedToId', 'flagged'];
  dataSource = new MatTableDataSource<InternalApplicationMessage>(this.messages);
  senders: InternalApplicationRecipient[] = [];

  public activeTableFilters: { [key: string]: string } = {};
  @ViewChild('TableOnePaginator') paginator: MatPaginator;
  @ViewChild('TableOneSort') sort: MatSort;

  constructor(public dialog: MatDialog,
    public sharedService: SharedService,
    private toastr: ToastrService,
    public internalApplicationMessagingService: InternalApplicationMessagingService,
    private router: Router) { }

  ngOnInit() {
    if(!environment.internalMessaging.enabled) {
      this.router.navigate(['sessions/403']);
    }
    this.currentMailbox = this.internalApplicationMessagingService.assignedInbox;
    this.loadAssignedMessages();
    this.loadSenders();
    this.getStaff();
  }

  getStaff(): void {
    this.internalApplicationMessagingService.getStaff().subscribe(
      response => this.staffMembers = response,
      error => console.log('error', error)
    );
  }

  assignMessage(event: MatSelectChange, messageId: number): void {
    let user: IStaff = this.staffMembers.find(staff => staff.id === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: ' ',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.internalApplicationMessagingService.assignMessage(user.id, messageId).subscribe(
      () =>{
        this.messages.find(m => m.id == messageId).assignedToId = user.id;
        if(user === null || user.name === null || user.name.length === 0) {
          this.toastr.success("Message unassigned");
        }
        else {
          this.toastr.success("Message assigned to " + user.name);
        }
      },
      error => console.log('error', error)
    );
  }

  loadLicensingInbox() {
    this.currentMailbox = this.internalApplicationMessagingService.licensingInbox;
    this.loadUnread();
  }

  loadAssignedInbox() {
    this.currentMailbox = this.internalApplicationMessagingService.assignedInbox;
    this.currentView = '';
    this.loadAssignedMessages();
  }

  loadComplianceInbox() {
    this.currentMailbox = this.internalApplicationMessagingService.complianceInbox;
    this.loadUnread();
  }

  loadPTSInbox() {
    this.currentMailbox = this.internalApplicationMessagingService.ptsInbox;
    this.loadUnread();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  canReply(sender: string): boolean {
    //if the environment senders matches the sender of the message, don't allow a reply to the staff.
    let item = this.senders.find(s => s.name == sender);
    if(item != undefined && item != null) {
      return false;
    }
    return true;
  }

  loadSenders() {
    this.environment.internalMessaging.defaultStaffRecipients.forEach(element => {
      this.senders.push({
        recipient: element.recipient,
        name: element.name,
        email: element.email
      })
     });
  }

  filterTable(event: Event, filterKey: string): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue) {
      this.activeTableFilters[filterKey] = filterValue;
    } else {
      delete this.activeTableFilters[filterKey];
    }

    this.applyTableFilters();
  }

  filterByDateRange(startDate: string, endDate: string): void {
    if (startDate && endDate) {
      this.activeTableFilters['dateRange'] = JSON.stringify({ startDate, endDate });
    } else {
      delete this.activeTableFilters['dateRange'];
    }

    this.applyTableFilters();
  }

  applyTableFilters(): void {
    this.dataSource.filterPredicate = (data: InternalApplicationMessage, filter: string) => {
      const filters = JSON.parse(filter);

      return Object.keys(filters).every(key => {
        const filterValue = filters[key];

        switch (key) {
          case 'general':
            return Object.values(data).some(value =>
              value?.toString().toLowerCase().includes(filterValue)
            );
          case 'licenseNumber':
            return data.entityId?.toLowerCase().includes(filterValue);
          case 'dateRange':
            const { startDate, endDate } = JSON.parse(filterValue);
            const itemDate = new Date(data.createdDate);
            return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
          default:
            return true;
        }
      });
    };

    this.dataSource.filter = JSON.stringify(this.activeTableFilters);
  }

  open(message: InternalApplicationMessage) {
    let messageIndex = this.messages.indexOf(message);
    if (messageIndex >= 0) {
      if (!this.messages[messageIndex].opened) {
        if (this.messages[messageIndex].isBroadcast) {
          this.internalApplicationMessagingService.openedBroadcast(this.messages[messageIndex].id);
        }
        else {
          this.internalApplicationMessagingService.openedStaffMessage(this.messages[messageIndex].id);
        }
        this.messages[messageIndex].opened = true;
      }
    }
  }

  archive(messageId: number) {
    let m = this.messages.find(f => f.id == messageId);
    if (m != undefined && m != null) {
      this.processing = true;
      if (m.isBroadcast) {
        this.internalApplicationMessagingService.archiveBroadcast(messageId).subscribe(result => {
          this.processing = false;
          this.refresh();
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
      else {
        this.internalApplicationMessagingService.archiveStaffMessage(messageId).subscribe(result => {
          this.processing = false;
          this.refresh();
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
    }
  }

  unarchive(messageId: number) {
    let m = this.messages.find(f => f.id == messageId);
    if (m != undefined && m != null) {
      this.processing = true;
      if (m.isBroadcast) {
        this.internalApplicationMessagingService.unarchiveBroadcast(messageId).subscribe(result => {
          this.processing = false;
          m.archived = false;
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
      else {
        this.internalApplicationMessagingService.unarchiveStaffMessage(messageId).subscribe(result => {
          this.processing = false;
          m.archived = false;
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
    }
  }

  flagMessage(message: InternalApplicationMessage) {
    this.internalApplicationMessagingService.flagMessage(message.id, !message.flagged).subscribe({
      next: response => {
        if(response) {
          message.flagged = !message.flagged;
          if(message.flagged)
            this.toastr.success("Message flagged");
          else
            this.toastr.success("Message unflagged");
        }
      },
      error: e => {
        this.toastr.error('Could not flag message');
        console.log('error', e)
      }
    });
  }

  downloadAttachment(id: number, name: string) {
    this.internalApplicationMessagingService.download(id).subscribe(
      (response) => { this.internalApplicationMessagingService.saveFile(name, response); },
      (error) => console.log("error", error)
    );
  }

  refresh() {
    if (this.currentView == this.internalApplicationMessagingService.inboxView) {
      this.loadInbox();
    }
    else if (this.currentView == this.internalApplicationMessagingService.unreadView) {
      this.loadUnread();
    }
    else if (this.currentView == this.internalApplicationMessagingService.sentView) {
      this.loadSent();
    }
    else if (this.currentView == this.internalApplicationMessagingService.staffView) {
      this.loadStaff();
    }
    else if (this.currentMailbox == this.internalApplicationMessagingService.assignedInbox)
    {
      this.loadAssignedInbox()
    }
  }

  updateMessageTable(): void {
    this.dataSource.data = this.messages;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadInbox() {
    this.processing = true;
    let name = ''

    if(this.currentMailbox == this.internalApplicationMessagingService.licensingInbox)
      name = 'Psilocybin Licensing Team';
    if(this.currentMailbox == this.internalApplicationMessagingService.complianceInbox)
      name = 'Psilocybin Compliance Team';
    if(this.currentMailbox == this.internalApplicationMessagingService.ptsInbox)
      name = 'Product Tracking Team';

    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.getRecipientInbox(name, this.includeArchived).subscribe(result => {
      this.messages = result;
      this.currentView = this.internalApplicationMessagingService.inboxView
      this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
      () => {
        this.updateMessageTable();
      });
  }

  loadSent() {
    this.processing = true;
    this.internalApplicationMessagingService.getSent(this.includeArchived).subscribe(result => {
      if(this.currentMailbox == this.internalApplicationMessagingService.licensingInbox)
        this.messages = result.filter(m => m.creatorName == 'Psilocybin Licensing Team');
      if(this.currentMailbox == this.internalApplicationMessagingService.complianceInbox)
        this.messages = result.filter(m => m.creatorName == 'Psilocybin Compliance Team');
      if(this.currentMailbox == this.internalApplicationMessagingService.ptsInbox)
        this.messages = result.filter(m => m.creatorName == 'Product Tracking Team');
      this.currentView = this.internalApplicationMessagingService.sentView
      this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
      () => {
        this.updateMessageTable();
      });
  }

  loadStaff() {
    this.processing = true;
    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.getStaffInbox(this.includeArchived).subscribe(result => {
      this.messages = result;
      this.currentView = this.internalApplicationMessagingService.staffView
      this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
      () => {
        this.updateMessageTable();
      });
  }

  loadUnread() {
    this.processing = true;
    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.getUnread().subscribe(result => {
      if(this.currentMailbox == this.internalApplicationMessagingService.licensingInbox)
        this.messages = result.filter(m => m.recipients.includes('Psilocybin Licensing Team'));
      if(this.currentMailbox == this.internalApplicationMessagingService.complianceInbox)
        this.messages = result.filter(m => m.recipients.includes('Psilocybin Compliance Team'));
      if(this.currentMailbox == this.internalApplicationMessagingService.ptsInbox)
        this.messages = result.filter(m => m.recipients.includes('Product Tracking Team'));
      this.processing = false;
      this.currentView = this.internalApplicationMessagingService.unreadView;
    }, error => {
      console.error(error);
      this.processing = false;
    },
      () => {
        this.updateMessageTable();
      });
  }

  loadAssignedMessages(){
    this.processing = true;
    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.assignedMessages().subscribe(result => {
      if(this.includeArchived)
        this.messages = result;
      if(!this.includeArchived)
        this.messages = result.filter(m => !m.archived);
    this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
      () => {
        this.updateMessageTable();
      });
  }

  openAddMessageDialog(messageId: string) {
    const dialogRef = this.dialog.open(DialogAddMessageComponent, {
      width: '1000px',
      data: {
        id: messageId,
      },
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "Cancel") {
        this.refresh();
      }
    });
  }

  openAddannouncementDialog() {
    const dialogRef = this.dialog.open(DialogAddAnnouncementComponent, {
      width: '400px',
      data: {
      },
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "Cancel") {
        this.refresh();
      }
    });
  }

  editAnnouncement(messageId: number) {
    const dialogRef = this.dialog.open(DialogEditAnnouncementComponent, {
      width: '400px',
      data: this.messages.find(m => m.id == messageId),
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "Cancel") {
        this.refresh();
      }
    });
  }

  changeRecipient(event: MatSelectChange, messageId: number): void {
    let recipient: string = this.recipients.find(recipient => recipient === event.value);
    let recipientEmail: string;

    if(recipient == 'Psilocybin Licensing Team')
      recipientEmail = 'Licensing.Psilocybin@odhsoha.oregon.gov';
    if(recipient == 'Psilocybin Compliance Team')
      recipientEmail = 'Compliance.Psilocybin@odhsoha.oregon.gov';
    if(recipient == 'Product Tracking Team')
      recipientEmail = 'Compliance.Psilocybin@odhsoha.oregon.gov';

    this.internalApplicationMessagingService.changeRecipient(recipient, recipientEmail, messageId).subscribe(
      result => {
        if(result.recipients.length == 0){
          this.toastr.error("Could not change recipient");
        }
        else {
          this.messages.find(m => m.id == messageId).recipients = result.recipients;
          this.messages = this.messages.filter(m => m.id != messageId);
          this.updateMessageTable();
          this.toastr.success("Recipient Updated");
        }
      },
      error => console.log('error', error)
    );
  }

attachDocument(attachmentId: number){
  this.internalApplicationMessagingService.attachDocument(attachmentId).subscribe(
    response =>
    {
      if(response)
        this.toastr.success("Document added to application");
      else
        this.toastr.error("Document could not be added to application");
    },
      error => console.log('error', error)
    );
}
}
/*
 * Add message conponent
 */
@Component({
  selector: 'dialog-add-message',
  templateUrl: 'add-message.html',
  styleUrls: ['./internal-app-messaging.component.scss']
})
export class DialogAddMessageComponent implements OnInit {
  @ViewChild("messageBody") messageBody;
  loading: boolean = false;
  sending: boolean = false;
  recipients: InternalApplicationRecipient[] = [];
  senders: InternalApplicationRecipient[] = [];
  licenses: InternalApplicationLicense[] = [];
  currentFiles: string[] = [];
  fileNames: string[] = [];
  files: File[] = [];
  message: InternalApplicationNewMessage = {
    id: 0,
    subject: '',
    message: '',
    canRespond: true,
    creator: '',
    highPriority: false,
    isBroadcast: false,
    recipient: '',
    recipientEmail: '',
    recipientName: '',
    fromEmail: '',
    fromName: '',
    threadId: '',
    attachments: [],
    active: true,
    entityId: '',
    entityType: '',
    entityName: ''
  }
  sendNewMessageForm = new UntypedFormGroup({
    recipient: new UntypedFormControl(''),
    from: new UntypedFormControl(''),
    subject: new UntypedFormControl(''),
    license: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    highPriority: new UntypedFormControl(false)
  });

  public env = environment;
  replyId = 0;
  name: string = '';
  email: string = '';

  constructor(private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogAddMessageComponent>,
    public internalApplicationMessagingService: InternalApplicationMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data != undefined && this.data.id != undefined && this.data.id > 0) {
      this.replyId = data.id;
    }
    if (data.name != undefined && data.name != null) {
      this.name = data.name;
    }
    if (data.email != undefined && data.email != null) {
      this.email = data.email;
    }
  }

  ngOnInit(): void {
    this.loadSenders();
    this.loadRecipients();
  }

  ngAfterViewInit() {
    if (this.replyId > 0) {
      //this.messageBody.nativeElement.focus();
    }
  }

  onFileSelected(event: Event): void {
    let newFiles: File[] = Array.from((event.target as HTMLInputElement).files);
    newFiles.forEach((file) => {
      this.files.push(file);
      this.fileNames.push(file.name);
    });
  }

  removeStagedFile(fileName: string): void {
    this.files = this.files.filter((file) => file.name !== fileName);
    this.fileNames = this.fileNames.filter((name) => name !== fileName);
  }

  loadSenders() {
    this.env.internalMessaging.defaultStaffRecipients.forEach(element => {
      this.senders.push({
        recipient: element.recipient,
        name: element.name,
        email: element.email
      })
     });
  }

  loadUserLicenses() {
    this.internalApplicationMessagingService.getUserLicenses(this.email).subscribe(
      result => {
        if(result != undefined && result != null) {
          result.forEach(r => {
              this.licenses.push({ id: r.licenseId, type: r.type , name: r.name, licenseId: r.licenseId, email: r.email, recipientName: r.recipientName});
          });
          if(result.length == 1) {
            this.message.entityId = result[0].id;
            this.message.entityType = result[0].type;
            this.message.entityName = result[0].name;
            this.sendNewMessageForm.get('license').setValue(result[0].id);
          }

        }
      },
      error => {
        this.toastr.error('Unable get user infomation');
      });
  }

  loadRecipients() {
    this.loading = true;
    if (this.replyId > 0) {
      //Go get the sender info based on the message id
      this.internalApplicationMessagingService.getMessage(this.replyId).subscribe(
        result => {
          this.recipients.push({
            recipient: result.creator,
            name: result.creatorName,
            email: result.creatorEmail
          });
          this.sendNewMessageForm.get('subject').setValue("RE: " + result.subject);
          this.sendNewMessageForm.get('message').setValue('\n\n--------------- <From: ' + result.creatorName + '> ---------------\n' + result.message);
          this.sendNewMessageForm.get('recipient').setValue(result.creator);
          this.message.recipient = result.creator;
          this.message.recipientName = result.creatorName;
          this.message.recipientEmail = result.creatorEmail;
          this.message.entityId = result.entityId;
          this.message.entityType = result.entityType;
          this.message.entityName = result.entityName;
          this.sendNewMessageForm.get('license').setValue(result.entityId);
          this.licenses.push({id: result.entityId, type: result.entityType, name: result.entityName, licenseId: result.entityId, email: '', recipientName: ''});

          if(result.recipients != null && result.recipients != undefined && result.recipients.length > 0) {
            let r = this.senders.find(s => s.name == result.recipients[0]);
            if(r != null && r != undefined) {
              this.message.fromName = r.name;
              this.message.fromEmail = r.email;
              this.sendNewMessageForm.get('from').setValue(r.name);
            }
          }

          if(this.replyId == 0) {
            this.loadUserLicenses();
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.toastr.error('Unable get reply message infomation');
          this.dialogRef.close();
        });
    }
    else {
      if (this.name != null && this.name.length > 0 && this.email != null && this.email.length > 0) {
        this.recipients.push({
          recipient: this.name,
          name: this.name,
          email: this.email,
        });
        this.message.recipient = this.name;
        this.message.recipientName = this.name;
        this.message.recipientEmail = this.email;
        this.sendNewMessageForm.get('recipient').setValue(this.name);
      }
      if(this.replyId == 0) {
        this.loadUserLicenses();
      }
      this.loading = false;
    }
  }

  sendMessage() {
    this.message.subject = this.sendNewMessageForm.value.subject;
    this.message.message = this.sendNewMessageForm.value.message;
    this.message.highPriority = this.sendNewMessageForm.value.highPriority;

    if (this.message.recipient == undefined || this.message.recipient.length == 0) {
      this.toastr.error("Please select a To recipient");
      return;
    }
    if (this.message.subject == undefined || this.message.subject.length == 0) {
      this.toastr.error("Please enter a Subject");
      return;
    }
    if (this.message.message == undefined || this.message.message.length == 0) {
      this.toastr.error("Please enter a Message");
      return;
    }

    if (this.message.fromName == undefined || this.message.fromName.length == 0) {
      this.toastr.error("Please select a From recipient");
      return;
    }

    if (this.message.entityId == undefined || this.message.entityId.length == 0 || this.message.entityType == undefined || this.message.entityType.length == 0) {
      this.toastr.error("Please select a License");
      return;
    }
    const formData = new FormData();
    formData.append("message", JSON.stringify(this.message));

    //attachements
    if (this.files.length > 0 && this.env.internalMessaging.canAttach) {
      this.files.forEach((file, index) => {
        return formData.append("file" + index, file, file.name);
      });
    }
    this.sending = true;
    this.internalApplicationMessagingService.sendMessage(formData).subscribe(result => {
      this.sending = false;
      this.toastr.success('Message Sent');
      this.dialogRef.close();
    }, error => {
      this.sending = false;
      this.toastr.error('Unable to send Message');
      console.log(error);
    });
  }

  selectedFromValue(event: MatSelectChange) {
    let from = this.senders.find(s => s.name == event.source.triggerValue);
    if (from != undefined && from != null) {
      this.message.fromEmail = from.email;
      this.message.fromName = event.source.triggerValue;
    }
  }

  selectedLicenseValue(event: MatSelectChange) {
    let l = this.licenses.find(s => s.id + " (" + s.name + ")" == event.source.triggerValue);
    if (l != undefined && l != null) {
      this.message.entityId = l.id;
      this.message.entityType = l.type;
      this.message.entityName = l.name;
      if(l.email != null && l.email != '')
        this.message.recipientEmail = l.email;
      else
        this.message.recipientEmail = this.email;
      if(l.recipientName != null && l.recipientName != '')
        this.message.recipientName = l.recipientName;
      else
        this.message.recipientName = this.name;
   }
  }

  selectedToValue(event: MatSelectChange) {
    this.message.recipient = event.value;
    this.message.recipientName = event.source.triggerValue;
    let recipient = this.recipients.find(f => f.name == event.source.triggerValue);
    if (recipient != undefined) {
      this.message.recipientEmail = recipient.email;
    }
  }

}

/*
 * Edit Announcement Dialog
 */
@Component({
  selector: 'dialog-edit-announcement',
  templateUrl: 'edit-announcement.html',
  styleUrls: ['./internal-app-messaging.component.scss']
})
export class DialogEditAnnouncementComponent implements OnInit {
  loading: boolean = false;
  sending: boolean = false;
  from: InternalApplicationRecipient[] = [];
  announcement: InternalApplicationMessage = null;

  message: InternalApplicationNewMessage = {
    id: 0,
    subject: '',
    message: '',
    canRespond: true,
    creator: '',
    highPriority: false,
    isBroadcast: false,
    recipient: '',
    recipientEmail: '',
    recipientName: '',
    fromEmail: '',
    fromName: '',
    threadId: '',
    attachments: [],
    active: true,
    entityId: '',
    entityType: '',
    entityName: ''
  }

  sendAnnouncementForm = new UntypedFormGroup({
    from: new UntypedFormControl(''),
    subject: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    active: new UntypedFormControl(true)
  });

  public env = environment;
  replyId = 0;
  constructor(private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogEditAnnouncementComponent>,
    public internalApplicationMessagingService: InternalApplicationMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.announcement = data;
  }

  ngOnInit(): void {
    this.loadFrom();
    this.sendAnnouncementForm.get('message').setValue(this.announcement.message);
    this.sendAnnouncementForm.get('subject').setValue(this.announcement.subject);
    this.sendAnnouncementForm.get('from').setValue(this.announcement.creator);
    this.sendAnnouncementForm.get('active').setValue(this.announcement.active);
    this.message.recipient = this.announcement.creator;
    this.message.recipientEmail = this.announcement.creatorEmail;
    this.message.recipientName = this.announcement.creatorName;
    this.message.active = this.announcement.active;
  }

  loadFrom() {
    this.loading = true;
    //Add in the default options
    this.env.internalMessaging.defaultStaffRecipients.forEach(element => {
      this.from.push({
        recipient: element.recipient,
        name: element.name,
        email: element.email
      })
    });
    this.loading = false;

  }

  sendAnnouncement() {
    this.message.subject = this.sendAnnouncementForm.value.subject;
    this.message.message = this.sendAnnouncementForm.value.message;
    this.message.active = this.sendAnnouncementForm.value.active;
    this.message.id = this.announcement.id;
    if (this.message.recipient == undefined || this.message.recipient.length == 0) {
      this.toastr.error("Please select a From");
      return;
    }
    if (this.message.subject == undefined || this.message.subject.length == 0) {
      this.toastr.error("Please enter a Subject");
      return;
    }
    if (this.message.message == undefined || this.message.message.length == 0) {
      this.toastr.error("Please enter a Message");
      return;
    }
    this.sending = true;
    this.internalApplicationMessagingService.updateBroadcast(this.message).subscribe(result => {
      this.sending = false;
      this.toastr.success('Announcement Updated');
      this.dialogRef.close();
    }, error => {
      this.sending = false;
      this.toastr.error('Unable to update Announcement');
    });
  }

  selectedFromValue(event: MatSelectChange) {
    this.message.recipient = event.value;
    this.message.recipientName = event.source.triggerValue;
    let f = this.from.find(f => f.name == event.source.triggerValue);
    if (f != undefined) {
      this.message.recipientEmail = f.email;
    }
  }

}

/*
 * Add Announcement Dialog
 */
@Component({
  selector: 'dialog-add-announcement',
  templateUrl: 'add-announcement.html',
  styleUrls: ['./internal-app-messaging.component.scss']
})
export class DialogAddAnnouncementComponent implements OnInit {
  loading: boolean = false;
  sending: boolean = false;
  from: InternalApplicationRecipient[] = [];
  message: InternalApplicationNewMessage = {
    id: 0,
    subject: '',
    message: '',
    canRespond: true,
    creator: '',
    highPriority: false,
    isBroadcast: false,
    recipient: '',
    recipientEmail: '',
    recipientName: '',
    fromEmail: '',
    fromName: '',
    threadId: '',
    attachments: [],
    active: true,
    entityId: '',
    entityType: '',
    entityName: ''
  }
  sendNewAnnouncementForm = new UntypedFormGroup({
    from: new UntypedFormControl(''),
    subject: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    active: new UntypedFormControl(true)
  });

  public env = environment;
  replyId = 0;
  constructor(private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogAddAnnouncementComponent>,
    public internalApplicationMessagingService: InternalApplicationMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.loadFrom();
  }

  loadFrom() {
    this.loading = true;
    //Add in the default options
    this.env.internalMessaging.defaultStaffRecipients.forEach(element => {
      this.from.push({
        recipient: element.recipient,
        name: element.name,
        email: element.email
      })
    });
    this.loading = false;

  }

  sendAnnouncement() {
    this.message.subject = this.sendNewAnnouncementForm.value.subject;
    this.message.message = this.sendNewAnnouncementForm.value.message;
    this.message.active = this.sendNewAnnouncementForm.value.active;

    if (this.message.recipient == undefined || this.message.recipient.length == 0) {
      this.toastr.error("Please select a From");
      return;
    }
    if (this.message.subject == undefined || this.message.subject.length == 0) {
      this.toastr.error("Please enter a Subject");
      return;
    }
    if (this.message.message == undefined || this.message.message.length == 0) {
      this.toastr.error("Please enter a Message");
      return;
    }
    this.sending = true;
    this.internalApplicationMessagingService.sendBroadcast(this.message).subscribe(result => {
      this.sending = false;
      this.toastr.success('Announcement Sent');
      this.dialogRef.close();
    }, error => {
      this.sending = false;
      this.toastr.error('Unable to send Announcement');
    });
  }

  selectedToValue(event: MatSelectChange) {
    this.message.recipient = event.value;
    this.message.recipientName = event.source.triggerValue;
    let f = this.from.find(f => f.name == event.source.triggerValue);
    if (f != undefined) {
      this.message.recipientEmail = f.email;
    }
  }

}

