import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { WorkerPermitService } from "app/services/licenses/worker.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })

export class WorkerPermitValidator implements AsyncValidator {
  constructor(private service: WorkerPermitService){}

  validate(
    control: AbstractControl
  ): Observable< ValidationErrors | null > {
    return this.service.checkWorkerPermitExists(control.value).pipe(
      map(exists => (!exists ? { labNotFound: true} : null )),
      catchError(() => of(null))
    )
  }
}
