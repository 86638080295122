<h2 mat-dialog-title *ngIf="!loading">
  <mat-icon class="icon-20" >history_edu</mat-icon> Change Log
  <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
    <section style="font-family: Oswald; font-size: 12pt;" fxLayoutGap="10px">
      <mat-checkbox  [(ngModel)]="search.showClients">Sessions</mat-checkbox>
      <mat-checkbox  [(ngModel)]="search.showDenied">Denied Services</mat-checkbox>
      <mat-checkbox  [(ngModel)]="search.showAdverseReactions">Adverse Reactions</mat-checkbox>
      <mat-checkbox  [(ngModel)]="search.showAverage">Averages</mat-checkbox>
      <mat-checkbox  [(ngModel)]="search.showPostSession">Post-Session Reactions</mat-checkbox>
      <mat-checkbox  [(ngModel)]="search.showServiceCenterData">Service Center Data</mat-checkbox>
    </section>

  <button mat-button (click)="showLogs()" color="primary" aria-label="Show Logs">Show Logs</button>
</h2>
<div *ngIf="!loading">
  <mat-card >
      <div fxLayout="column" fxLayoutGap="8px grid">
          <br/>
          <div *ngFor="let log of auditlogs">
            <audit-log-table [auditLog]=log [LicenseId]="licenseId" [LicenseName]="licenseName"></audit-log-table>
          </div>
        <br />
        <div *ngIf="loading">
          <small>Loading...</small>
          <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
          </mat-progress-bar>
        </div>
        <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
      </div>
  </mat-card>
</div>

<div *ngIf="loading">
  <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
    <span>
      <h1>Loading</h1>
      <mat-spinner [diameter]="30"></mat-spinner>
    </span>
  </div>
</div>
