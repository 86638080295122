import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAuditLog } from 'app/models/audit-logging/audit-logging';
import { AuditLoggingComplianceSearch } from 'app/models/audit-logging/audit-logging-compliance-search';
import { AuditLogService } from 'app/services/audit-log/audit-log.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dialog-compliance-audit-log',
  templateUrl: './compliance-audit-log.html',
  providers: [AuditLogService],
})
export class ComplianceAuditLogDialogComponent implements OnInit  {


  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingComplianceSearch = null;
  entityId: string = "";

  constructor(public sharedService: SharedService,
              private auditLogService: AuditLogService,
              private dialogRef: MatDialogRef<ComplianceAuditLogDialogComponent>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.entityId = data.entityId;
              }

  ngOnInit(): void {
    this.loading = true;
    this.auditLogService.initiateComplianceSearch(this.entityId).subscribe(
      response => {
        this.search = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  showLogs() {
     if(!this.search.displayNotices &&
        !this.search.displayRules &&
        !this.search.displayActionItems &&
        !this.search.displayReferencedLicenses &&
        !this.search.displayCompliances &&
        !this.search.displayInvestigations &&
        !this.search.displayCompliancePlans &&
        !this.search.displayNotes &&
        !this.search.displayComplaints
  ) {
          this.toastr.error("Select at least one type of log to display");
          return;
    }

    this.loading = true;
    this.auditLogService.getComplianceAuditLogging(this.search).subscribe(
      response => {
        this.auditlogs = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }
}
