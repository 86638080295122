import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { IApplicationTrackingLicense } from 'app/models/licenses/application-tracking-license';
import { IStaff } from 'app/models/user-info/staff';
import { IStaffType } from 'app/models/user-info/staff-type';
import { ActiveApplicationTrackingService } from 'app/services/active-application-tracking/active-application-tracking.service';
import { SharedService } from 'app/services/core/shared.service';
import { FacilitatorService } from 'app/services/licenses/facilitator/facilitator.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ServiceCenterService } from 'app/services/licenses/service-center.service';
import { TestingLabService } from 'app/services/licenses/testing-lab.service';
import { WorkerPermitService } from 'app/services/licenses/worker.service';
import { TrainingService } from 'app/services/training/training.service';
import { ToastrService } from 'ngx-toastr';
import { ManagerNotesDialogComponent } from '../manager-notes/dialog/manager-notes-dialog.component';


export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-active-application-tracking',
  templateUrl: './active-application-tracking.component.html',
  styleUrls: ['./active-application-tracking.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class ActiveApplicationTrackingComponent implements OnInit {

  public workerStaff: IStaffType[] = [];
  public facilitatorStaff: IStaffType[] = [];
  public trainingStaff: IStaffType[] = [];
  public manufacturerStaff: IStaffType[] = [];
  public testingLabStaff: IStaffType[] = [];
  public serviceCenterStaff: IStaffType[] = [];

  public loadingWorkerPermit = true;
  public loadingTrainingProgram = true;
  public loadingManufacturer = true;
  public loadingFacilitator = true;
  public loadingServiceCenter = true;
  public loadingTestinglab = true;
  public loadingExpiredLicenses = true;
  public loadingUpForRenewalLicenses = true;

  public selectedIndex: number = 0;
  public selectedRenewalIndex: number = 0;
  public activeStatuses: string[] = [this.sharedService.submitted, this.sharedService.underReview, this.sharedService.insufficient, this.sharedService.incomplete,
    this.sharedService.readyForLicensingManagerReview, this.sharedService.readyForComplianceAssignmentAndInspection, this.sharedService.readyForComplianceManagerReview,
    this.sharedService.PaymentClearedPendingApproval, this.sharedService.pendingDenial, this.sharedService.submitForClosure, this.sharedService.peerReview]
  public workerLicenses: IApplicationTrackingLicense[] = [];
  public trainingLicenses: IApplicationTrackingLicense[] = [];
  public facilitatorLicenses: IApplicationTrackingLicense[] = [];
  public manufacturerLicenses: IApplicationTrackingLicense[] = [];
  public serviceCenterLicenses: IApplicationTrackingLicense[] =[];
  public testingLabLicenses: IApplicationTrackingLicense[] = [];
  public expiredLicenses: IApplicationTrackingLicense[] = [];
  public upForRenewalLicenses: IApplicationTrackingLicense[] = [];
  public activeLicenseTotal: number = 0;
  public renewalLicenseTotal: number = 0;

  public workerPermitColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'assignedDate', 'assignedTo', 'backgroundCheckInitiatedDate', 'backgroundCheckDate', 'backgroundCheckExpiryDate', 'licenseFeeClearedDate', 'status', 'managerFlag'];
  public workerPermitSource = new MatTableDataSource<IApplicationTrackingLicense>(this.workerLicenses);
  @ViewChild('workerPermitPaginator', {static: false}) workerPermitPaginator: MatPaginator;
  @ViewChild('workerPermitSort', {static: false}) workerPermitSort: MatSort;

  public trainingColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'applicationFeeClearedDate', 'assignedDate', 'assignedTo', 'licenseFeeClearedDate', 'status', 'managerFlag'];
  public trainingSource = new MatTableDataSource<IApplicationTrackingLicense>(this.trainingLicenses);
  @ViewChild('trainingPaginator', {static: false}) trainingPaginator: MatPaginator;
  @ViewChild('trainingSort', {static: false}) trainingSort: MatSort;

  public facilitatorColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'applicationFeeClearedDate', 'assignedDate', 'assignedTo', 'backgroundCheckInitiatedDate', 'backgroundCheckDate', 'backgroundCheckExpiryDate', 'licenseFeeClearedDate', 'status', 'managerFlag'];
  public facilitatorSource = new MatTableDataSource<IApplicationTrackingLicense>(this.facilitatorLicenses);
  @ViewChild('facilitatorPaginator', {static: false}) facilitatorPaginator: MatPaginator;
  @ViewChild('facilitatorSort', {static: false}) facilitatorSort: MatSort;

  public facilitatorRenewalSource = new MatTableDataSource<IApplicationTrackingLicense>(this.facilitatorLicenses);
  @ViewChild('facilitatorRenewalPaginator', {static: false}) facilitatorRenewalPaginator: MatPaginator;
  @ViewChild('facilitatorRenewalSort', {static: false}) facilitatorRenewalSort: MatSort;

  public manufacturerColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'applicationFeeClearedDate', 'assignedDate', 'assignedTo', 'backgroundCheckInitiatedDate', 'backgroundCheckDate', 'backgroundCheckExpiryDate', 'complianceAssignedDate', 'complianceAssignedTo', 'inspectionCompletedDate',  'licenseFeeClearedDate', 'status', 'managerFlag'];
  public manufacturerSource = new MatTableDataSource<IApplicationTrackingLicense>(this.manufacturerLicenses);
  @ViewChild('manufacturerPaginator', {static: false}) manufacturerPaginator: MatPaginator;
  @ViewChild('manufacturerSort', {static: false}) manufacturerSort: MatSort;

  public manufacturerRenewalSource = new MatTableDataSource<IApplicationTrackingLicense>(this.manufacturerLicenses);
  @ViewChild('manufacturerRenewalPaginator', {static: false}) manufacturerRenewalPaginator: MatPaginator;
  @ViewChild('manufacturerRenewalSort', {static: false}) manufacturerRenewalSort: MatSort;

  public serviceCenterColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'applicationFeeClearedDate', 'assignedDate', 'assignedTo', 'backgroundCheckInitiatedDate', 'backgroundCheckDate', 'backgroundCheckExpiryDate', 'complianceAssignedDate', 'complianceAssignedTo', 'inspectionCompletedDate', 'licenseFeeClearedDate', 'status', 'managerFlag'];
  public serviceCenterSource = new MatTableDataSource<IApplicationTrackingLicense>(this.serviceCenterLicenses);
  @ViewChild('serviceCenterPaginator', {static: false}) serviceCenterPaginator: MatPaginator;
  @ViewChild('serviceCenterSort', {static: false}) serviceCenterSort: MatSort;

  public serviceCenterRenewalSource = new MatTableDataSource<IApplicationTrackingLicense>(this.serviceCenterLicenses);
  @ViewChild('serviceCenterRenewalPaginator', {static: false}) serviceCenterRenewalPaginator: MatPaginator;
  @ViewChild('serviceCenterRenewalSort', {static: false}) serviceCenterRenewalSort: MatSort;

  public testingLabColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'applicationFeeClearedDate', 'assignedDate', 'assignedTo', 'backgroundCheckInitiatedDate', 'backgroundCheckDate', 'backgroundCheckExpiryDate', 'complianceAssignedDate', 'complianceAssignedTo', 'inspectionCompletedDate', 'licenseFeeClearedDate', 'status', 'managerFlag'];
  public testingLabSource = new MatTableDataSource<IApplicationTrackingLicense>(this.testingLabLicenses);
  @ViewChild('testingLabPaginator', {static: false}) testingLabPaginator: MatPaginator;
  @ViewChild('testingLabSort', {static: false}) testingLabSort: MatSort;

  public testingLabRenewalSource = new MatTableDataSource<IApplicationTrackingLicense>(this.testingLabLicenses);
  @ViewChild('testingLabRenewalPaginator', {static: false}) testingLabRenewalPaginator: MatPaginator;
  @ViewChild('testingLabRenewalSort', {static: false}) testingLabRenewalSort: MatSort;

  public expiredColumns: string[] = ['licenseName', 'licenseId', 'submittedDate', 'enteredByStaff', 'expiredDate', 'status', 'managerFlag'];
  public expiredSource = new MatTableDataSource<IApplicationTrackingLicense>(this.expiredLicenses);
  @ViewChild('expiredPaginator', {static: false}) expiredPaginator: MatPaginator;
  @ViewChild('expiredSort', {static: false}) expiredSort: MatSort;

  public upForRenewalColumns: string[] = ['entityType', 'licenseName', 'licenseId', 'expirationDate', 'canStartRenewalDate', 'timelyDate', 'deadlineForRenewal', 'hide', 'managerFlag'];
  public upForRenewalSource = new MatTableDataSource<IApplicationTrackingLicense>(this.upForRenewalLicenses);
  @ViewChild('upForRenewalPaginator', {static: false}) upForRenewalPaginator: MatPaginator;
  @ViewChild('upForRenewalSort', {static: false}) upForRenewalSort: MatSort;

  constructor(public sharedService: SharedService,
        public authService: AuthService,
        public activeApplicationTrackingService: ActiveApplicationTrackingService,
        public workerPermitService: WorkerPermitService,
        public trainingProgramService: TrainingService,
        public facilitatorLicenseService: FacilitatorService,
        public testingLabLicenseService: TestingLabService,
        public serviceCenterLicenseService: ServiceCenterService,
        public manufacturerLicenseService: ManufacturerService,
        public toastr: ToastrService,
        public dialog: MatDialog
        ) { }

  ngOnInit(): void {
    this.getStaff();
    this.getWorkerPermits();
    this.getTrainingPrograms();
    this.getFacilitatorLicenses();
    this.getManufacturerLicenses();
    this.getServiceCenterLicenses();
    this.getTestinglabLicenses();
    this.getExpiredLicenses();
    this.getUpForRenewalLicenses();
  }

  changeStep(step: number): void {
    this.selectedIndex = step;
  }

  changeRenewalStep(step: number): void {
    this.selectedRenewalIndex = step;
  }

  getStaff(): void {
    this.activeApplicationTrackingService.getStaff().subscribe(
      response =>
      {
        this.workerStaff = response.filter(s => s.type == "WorkerPermit");
        this.facilitatorStaff = response.filter(s => s.type == "Facilitator");
        this.trainingStaff = response.filter(s => s.type == "Training");
        this.manufacturerStaff = response.filter(s => s.type == "Manufacturer");
        this.testingLabStaff = response.filter(s => s.type == "TestingLab");
        this.serviceCenterStaff = response.filter(s => s.type == "ServiceCenter");
      },
      error => console.log('error', error)
    );
  }

//Expired Licenses
getExpiredLicenses():void {
  this.activeApplicationTrackingService.getExpiredLicenses().subscribe(
    response => this.expiredLicenses = response,
    error => console.log('error', error),
    () =>{
      this.updateExpiredLicenseTable();
    });
}

updateExpiredLicenseTable(): void {
  this.expiredSource.data = this.expiredLicenses;
  this.expiredSource.sort = this.expiredSort;
  this.expiredSource.sortingDataAccessor = this.customExpiredDateSortAccessor;
  this.expiredSource.paginator = this.expiredPaginator;
  this.loadingExpiredLicenses = false;
}

customExpiredDateSortAccessor = (item: any, property: string) => {
  const direction = this.expiredSort.direction;

  if (property === 'submittedDate') {
    const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'applicationFeeClearedDate') {
    const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'assignedDate') {
    const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckInitiatedDate') {
    const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckDate') {
    const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckExpiryDate') {
    const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'licenseFeeClearedDate') {
    const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }
  return item[property];
};

filterExpiredTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.expiredSource.filter = value.trim().toLowerCase();
}
//Testing Labs
getTestinglabLicenses(): void {
  this.activeApplicationTrackingService.getLicenses('TestingLab').subscribe(
    response => this.testingLabLicenses = response,
    error => console.log('error', error),
    () => {
      this.updateTestingLabTable();
      this.activeLicenseTotal += this.testingLabLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
        l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      this.renewalLicenseTotal += this.testingLabLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
        l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
    });
}

updateTestingLabTable(): void {
  this.testingLabSource.data = this.testingLabLicenses.filter(tl => tl.status != this.sharedService.renewalSubmitted && tl.status != this.sharedService.renewalReviewTimely && tl.status != this.sharedService.renewalReviewUntimely &&
    tl.status != this.sharedService.renewalApprovedPendingPaymentandClearance && tl.status != this.sharedService.renewalPeerReview && tl.status != this.sharedService.renewalReadyForLicensingManagerReview && tl.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    && tl.status != this.sharedService.renewalSiteInspectionComplete && tl.status != this.sharedService.renewalReadyForComplianceManagerReview && tl.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.testingLabSource.sort = this.testingLabSort;
  this.testingLabSource.paginator = this.testingLabPaginator;
  this.testingLabSource.sortingDataAccessor = this.customTestingLabDateSortAccessor;

  this.testingLabRenewalSource.data = this.testingLabLicenses.filter(tl => tl.status == this.sharedService.renewalSubmitted || tl.status == this.sharedService.renewalReviewTimely || tl.status == this.sharedService.renewalReviewUntimely ||
    tl.status == this.sharedService.renewalApprovedPendingPaymentandClearance || tl.status == this.sharedService.renewalPeerReview || tl.status == this.sharedService.renewalReadyForLicensingManagerReview || tl.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    || tl.status == this.sharedService.renewalSiteInspectionComplete || tl.status == this.sharedService.renewalReadyForComplianceManagerReview || tl.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.testingLabRenewalSource.sort = this.testingLabRenewalSort;
  this.testingLabRenewalSource.paginator = this.testingLabRenewalPaginator;
  this.loadingTestinglab = false;
}

customTestingLabDateSortAccessor = (item: any, property: string) => {
  const direction = this.testingLabSort.direction;

  if (property === 'submittedDate') {
    const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'applicationFeeClearedDate') {
    const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'assignedDate') {
    const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckInitiatedDate') {
    const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckDate') {
    const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckExpiryDate') {
    const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'licenseFeeClearedDate') {
    const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }
  return item[property];
};


filterTestingLabTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.testingLabSource.filter = value.trim().toLowerCase();
}

filterTestingLabRenewalTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.testingLabRenewalSource.filter = value.trim().toLowerCase();
}

assignTestingLabLicense(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.testingLabStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.testingLabLicenseService.assignLicense(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.testingLabLicenses.find(fl => fl.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
      else
        this.testingLabLicenses.find(fl => fl.id == licenseId).assignedDate = '';
    },
    error => console.log('error', error)
  );
}

assignComplianceMemberTestingLab(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.testingLabStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.testingLabLicenseService.assignComplianceMember(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.testingLabLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = new Date(Date.now()).toDateString();
      else {
        this.testingLabLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = '';
      }

    },
    error => console.log('error', error)
  );
}

//Service Center
getServiceCenterLicenses(): void {
  this.activeApplicationTrackingService.getLicenses('ServiceCenter').subscribe(
    response => this.serviceCenterLicenses = response,
    error => console.log('error', error),
    () => {
      this.updateServiceCenterTable();
      this.activeLicenseTotal += this.serviceCenterLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
        l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      this.renewalLicenseTotal += this.serviceCenterLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
        l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
    });
}

updateServiceCenterTable(): void {
  this.serviceCenterSource.data = this.serviceCenterLicenses.filter(tl => tl.status != this.sharedService.renewalSubmitted && tl.status != this.sharedService.renewalReviewTimely && tl.status != this.sharedService.renewalReviewUntimely &&
    tl.status != this.sharedService.renewalApprovedPendingPaymentandClearance && tl.status != this.sharedService.renewalPeerReview && tl.status != this.sharedService.renewalReadyForLicensingManagerReview && tl.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    && tl.status != this.sharedService.renewalSiteInspectionComplete && tl.status != this.sharedService.renewalReadyForComplianceManagerReview && tl.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.serviceCenterSource.sort = this.serviceCenterSort;
  this.serviceCenterSource.paginator = this.serviceCenterPaginator;
  this.serviceCenterSource.sortingDataAccessor = this.customServiceCenterDateSortAccessor;

  this.serviceCenterRenewalSource.data = this.serviceCenterLicenses.filter(tl => tl.status == this.sharedService.renewalSubmitted || tl.status == this.sharedService.renewalReviewTimely || tl.status == this.sharedService.renewalReviewUntimely ||
    tl.status == this.sharedService.renewalApprovedPendingPaymentandClearance || tl.status == this.sharedService.renewalPeerReview || tl.status == this.sharedService.renewalReadyForLicensingManagerReview || tl.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    || tl.status == this.sharedService.renewalSiteInspectionComplete || tl.status == this.sharedService.renewalReadyForComplianceManagerReview || tl.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.serviceCenterRenewalSource.sort = this.serviceCenterRenewalSort;
  this.serviceCenterRenewalSource.paginator = this.serviceCenterRenewalPaginator;
  this.loadingServiceCenter = false;
}

customServiceCenterDateSortAccessor = (item: any, property: string) => {
  const direction = this.serviceCenterSort.direction;

  if (property === 'submittedDate') {
    const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'applicationFeeClearedDate') {
    const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'assignedDate') {
    const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckInitiatedDate') {
    const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckDate') {
    const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckExpiryDate') {
    const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'licenseFeeClearedDate') {
    const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }
  return item[property];
};

filterServiceCenterTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.serviceCenterSource.filter = value.trim().toLowerCase();
}

filterServiceCenterRenewalTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.serviceCenterRenewalSource.filter = value.trim().toLowerCase();
}

assignServiceCenterLicense(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.serviceCenterStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.serviceCenterLicenseService.assignLicense(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.serviceCenterLicenses.find(fl => fl.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
      else
        this.serviceCenterLicenses.find(fl => fl.id == licenseId).assignedDate = '';
    },
    error => console.log('error', error)
  );
}

assignComplianceMemberServiceCenter(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.serviceCenterStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.serviceCenterLicenseService.assignComplianceMember(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.serviceCenterLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = new Date(Date.now()).toDateString();
      else {
        this.serviceCenterLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = '';
      }

    },
    error => console.log('error', error)
  );
}

//Manufacturer
getManufacturerLicenses(): void {
  this.activeApplicationTrackingService.getLicenses('Manufacturer').subscribe(
    response => this.manufacturerLicenses = response,
    error => console.log('error', error),
    () => {
      this.updateManufacturerTable();
      this.activeLicenseTotal += this.manufacturerLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
        l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      this.renewalLicenseTotal += this.manufacturerLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
        l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
    });
}

updateManufacturerTable(): void {
  this.manufacturerSource.data = this.manufacturerLicenses.filter(tl => tl.status != this.sharedService.renewalSubmitted && tl.status != this.sharedService.renewalReviewTimely && tl.status != this.sharedService.renewalReviewUntimely &&
    tl.status != this.sharedService.renewalApprovedPendingPaymentandClearance && tl.status != this.sharedService.renewalPeerReview && tl.status != this.sharedService.renewalReadyForLicensingManagerReview && tl.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    && tl.status != this.sharedService.renewalSiteInspectionComplete && tl.status != this.sharedService.renewalReadyForComplianceManagerReview && tl.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.manufacturerSource.sort = this.manufacturerSort;
  this.manufacturerSource.paginator = this.manufacturerPaginator;
  this.manufacturerSource.sortingDataAccessor = this.customManufacturerDateSortAccessor;

  this.manufacturerRenewalSource.data = this.manufacturerLicenses.filter(tl => tl.status == this.sharedService.renewalSubmitted || tl.status == this.sharedService.renewalReviewTimely || tl.status == this.sharedService.renewalReviewUntimely ||
    tl.status == this.sharedService.renewalApprovedPendingPaymentandClearance || tl.status == this.sharedService.renewalPeerReview || tl.status == this.sharedService.renewalReadyForLicensingManagerReview || tl.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    || tl.status == this.sharedService.renewalSiteInspectionComplete || tl.status == this.sharedService.renewalReadyForComplianceManagerReview || tl.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.manufacturerRenewalSource.sort = this.manufacturerRenewalSort;
  this.manufacturerRenewalSource.paginator = this.manufacturerRenewalPaginator;
  this.loadingManufacturer = false;
}

customManufacturerDateSortAccessor = (item: any, property: string) => {
  const direction = this.manufacturerSort.direction;

  if (property === 'submittedDate') {
    const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'applicationFeeClearedDate') {
    const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'assignedDate') {
    const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckInitiatedDate') {
    const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckDate') {
    const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckExpiryDate') {
    const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'licenseFeeClearedDate') {
    const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }
  return item[property];
};

filterManufacturerTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.manufacturerSource.filter = value.trim().toLowerCase();
}

filterManufacturerRenewalTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.manufacturerRenewalSource.filter = value.trim().toLowerCase();
}

assignManufacturerLicense(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.manufacturerStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.manufacturerLicenseService.assignLicense(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.manufacturerLicenses.find(fl => fl.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
      else {
        this.manufacturerLicenses.find(fl => fl.id == licenseId).assignedDate = '';
      }
    },
    error => console.log('error', error)
  );
}

assignComplianceMemberManufacturer(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.manufacturerStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.manufacturerLicenseService.assignComplianceMember(user, licenseId).subscribe(
    () => {
      if(user.name != '')
        this.manufacturerLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = new Date(Date.now()).toDateString();
      else
        this.manufacturerLicenses.find(fl => fl.id == licenseId).complianceAssignedDate = '';
    },
    error => console.log('error', error)
  );
}

//Facilitators
getFacilitatorLicenses(): void {
  this.activeApplicationTrackingService.getLicenses('Facilitator').subscribe(
    response => this.facilitatorLicenses = response,
    error => console.log('error', error),
    () => {
      this.updateFacilitatorTable();
      this.activeLicenseTotal += this.facilitatorLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
        l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      this.renewalLicenseTotal += this.facilitatorLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
        l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
        || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
    });
}

updateFacilitatorTable(): void {
  this.facilitatorSource.data = this.facilitatorLicenses.filter(tl => tl.status != this.sharedService.renewalSubmitted && tl.status != this.sharedService.renewalReviewTimely && tl.status != this.sharedService.renewalReviewUntimely &&
    tl.status != this.sharedService.renewalApprovedPendingPaymentandClearance && tl.status != this.sharedService.renewalPeerReview && tl.status != this.sharedService.renewalReadyForLicensingManagerReview && tl.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    && tl.status != this.sharedService.renewalSiteInspectionComplete && tl.status != this.sharedService.renewalReadyForComplianceManagerReview && tl.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.facilitatorSource.sort = this.facilitatorSort;
  this.facilitatorSource.sortingDataAccessor = this.customFacilitatorDateSortAccessor;
  this.facilitatorSource.paginator = this.facilitatorPaginator;

  this.facilitatorRenewalSource.data = this.facilitatorLicenses.filter(tl => tl.status == this.sharedService.renewalSubmitted || tl.status == this.sharedService.renewalReviewTimely || tl.status == this.sharedService.renewalReviewUntimely ||
    tl.status == this.sharedService.renewalApprovedPendingPaymentandClearance || tl.status == this.sharedService.renewalPeerReview || tl.status == this.sharedService.renewalReadyForLicensingManagerReview || tl.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
    || tl.status == this.sharedService.renewalSiteInspectionComplete || tl.status == this.sharedService.renewalReadyForComplianceManagerReview || tl.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager);
  this.facilitatorRenewalSource.sort = this.facilitatorRenewalSort;
  this.facilitatorRenewalSource.paginator = this.facilitatorRenewalPaginator;
  this.loadingFacilitator = false;
}

customFacilitatorDateSortAccessor = (item: any, property: string) => {
  const direction = this.facilitatorSort.direction;

  if (property === 'submittedDate') {
    const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'applicationFeeClearedDate') {
    const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'assignedDate') {
    const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckInitiatedDate') {
    const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckDate') {
    const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'backgroundCheckExpiryDate') {
    const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }

  if (property === 'licenseFeeClearedDate') {
    const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
    if (dateValue === null) {
      return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    }
    return dateValue.getTime();
  }
  return item[property];
};

filterFacilitatorTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.facilitatorSource.filter = value.trim().toLowerCase();
}

filterFacilitatorRenewalTable(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  this.facilitatorRenewalSource.filter = value.trim().toLowerCase();
}

assignFacilitatorLicense(event: MatSelectChange, licenseId: number): void {
  let user: IStaff = this.facilitatorStaff.find(staff => staff.name === event.value);
  if(user === undefined){
    let emptyUser: IStaff = {
      id: '',
      name: '',
      email: ''
    }
    user = emptyUser;
  }
  this.facilitatorLicenseService.assignLicense(licenseId, user).subscribe(
    () => {
      if(user.name != '')
        this.facilitatorLicenses.find(fl => fl.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
      else
        this.facilitatorLicenses.find(fl => fl.id == licenseId).assignedDate = '';
    },
    error => console.log('error', error)
  );
}


//Training Programs
  getTrainingPrograms(): void {
    this.activeApplicationTrackingService.getLicenses('TrainingProgram').subscribe(
      response => this.trainingLicenses = response,
      error => console.log('error', error),
      () => {
        this.updateTrainingTable();
        this.activeLicenseTotal +=  this.trainingLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
          l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
          && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
        this.renewalLicenseTotal +=  this.trainingLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
          l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
          || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      });
  }

  updateTrainingTable(): void {
    this.trainingSource.data = this.trainingLicenses;
    this.trainingSource.sort = this.trainingSort;
    this.trainingSource.sortingDataAccessor = this.customTrainingProgramDateSortAccessor;
    this.trainingSource.paginator = this.trainingPaginator;
    this.loadingTrainingProgram = false;
  }

  filterTrainingProgramTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.trainingSource.filter = value.trim().toLowerCase();
  }

  assignTrainingProgram(event: MatSelectChange, licenseId: number): void {
    let user: IStaff = this.trainingStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: '',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.trainingProgramService.assignTrainingProgram(licenseId, user).subscribe(
      () => {
        if(user.name != '')
          this.trainingLicenses.find(tp => tp.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
        else {
          this.trainingLicenses.find(tp => tp.id == licenseId).assignedDate = '';
        }

      },
      error => console.log('error', error)
    );
  }

  customTrainingProgramDateSortAccessor = (item: any, property: string) => {
    const direction = this.trainingSort.direction;

    if (property === 'submittedDate') {
      const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'applicationFeeClearedDate') {
      const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'assignedDate') {
      const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckInitiatedDate') {
      const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckDate') {
      const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckExpiryDate') {
      const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'licenseFeeClearedDate') {
      const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };



//Worker Permits

  filterWorkerPermitTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.workerPermitSource.filter = value.trim().toLowerCase();
  }

  assignWorkerPermit(event: MatSelectChange, licenseId: number): void {
    let user: IStaff = this.workerStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: '',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.workerPermitService.assignWorkerPermit(licenseId, user).subscribe(
      () => {
        if(user.name != '')
          this.workerLicenses.find(wp => wp.id == licenseId).assignedDate = new Date(Date.now()).toDateString();
        else {
          this.workerLicenses.find(wp => wp.id == licenseId).assignedDate = '';
        }

      },
      error => console.log('error', error)
    );
  }

  getWorkerPermits(): void {
    this.activeApplicationTrackingService.getLicenses('WorkerPermit').subscribe(
      response => this.workerLicenses = response,
      error => console.log('error', error),
      () => {
        this.updateWorkerPermitTable();
        this.activeLicenseTotal += this.workerLicenses.filter(l => l.status != this.sharedService.renewalSubmitted && l.status != this.sharedService.renewalReviewTimely && l.status != this.sharedService.renewalReviewUntimely &&
          l.status != this.sharedService.renewalApprovedPendingPaymentandClearance && l.status != this.sharedService.renewalPeerReview && l.status != this.sharedService.renewalReadyForLicensingManagerReview && l.status != this.sharedService.renewalReadyForComplianceAssignmentAndInspection
          && l.status != this.sharedService.renewalSiteInspectionComplete && l.status != this.sharedService.renewalReadyForComplianceManagerReview && l.status != this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
        this.renewalLicenseTotal += this.workerLicenses.filter(l => l.status == this.sharedService.renewalSubmitted || l.status == this.sharedService.renewalReviewTimely || l.status == this.sharedService.renewalReviewUntimely ||
          l.status == this.sharedService.renewalApprovedPendingPaymentandClearance || l.status == this.sharedService.renewalPeerReview || l.status == this.sharedService.renewalReadyForLicensingManagerReview || l.status == this.sharedService.renewalReadyForComplianceAssignmentAndInspection
          || l.status == this.sharedService.renewalSiteInspectionComplete || l.status == this.sharedService.renewalReadyForComplianceManagerReview || l.status == this.sharedService.renewalSubmittedForApprovalForLicensingManager).length;
      });
  }

  updateWorkerPermitTable(): void {
    this.workerPermitSource.data = this.workerLicenses;
    this.workerPermitSource.sort = this.workerPermitSort;
    this.workerPermitSource.sortingDataAccessor = this.customWorkerPermitDateSortAccessor;
    this.workerPermitSource.paginator = this.workerPermitPaginator;
    this.loadingWorkerPermit = false;
  }

  customWorkerPermitDateSortAccessor = (item: any, property: string) => {
    const direction = this.workerPermitSort.direction;

    if (property === 'submittedDate') {
      const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'applicationFeeClearedDate') {
      const dateValue = item.applicationFeeClearedDate ? new Date(item.applicationFeeClearedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'assignedDate') {
      const dateValue = item.assignedDate ? new Date(item.assignedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckInitiatedDate') {
      const dateValue = item.backgroundCheckInitiatedDate ? new Date(item.backgroundCheckInitiatedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckDate') {
      const dateValue = item.backgroundCheckDate ? new Date(item.backgroundCheckDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'backgroundCheckExpiryDate') {
      const dateValue = item.backgroundCheckExpiryDate ? new Date(item.backgroundCheckExpiryDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'licenseFeeClearedDate') {
      const dateValue = item.licenseFeeClearedDate ? new Date(item.licenseFeeClearedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  //Up For Renewal
  getUpForRenewalLicenses(): void {
    this.activeApplicationTrackingService.getUpForRenewalLicenses().subscribe(
      response => this.upForRenewalLicenses = response,
      error => console.log('error', error),
      () => {
        this.updateUpForRenewalTable();
      });

  }

  updateUpForRenewalTable(): void {
    this.upForRenewalSource.data = this.upForRenewalLicenses;
    this.upForRenewalSource.sort = this.upForRenewalSort;
    this.upForRenewalSource.sortingDataAccessor = this.customUpForRenewalDateSortAccessor;
    this.upForRenewalSource.paginator = this.upForRenewalPaginator;
    this.loadingUpForRenewalLicenses = false;
  }

  hideLicense(license: IApplicationTrackingLicense): void {
    this.sharedService.openConfirm("Hide this license from Active Application Tracker?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.activeApplicationTrackingService.hideLicense(license).subscribe({
            next: response => {
              if(response)
              {
                this.upForRenewalLicenses = this.upForRenewalLicenses.filter(l => l.id != license.id);
                this.updateUpForRenewalTable();
                this.toastr.success('License Hidden');
              }
              else {
                this.toastr.error('Could not hide license');
              }
            },
            error: err => console.log('error', err),
          });
        }
      });
  }

  customUpForRenewalDateSortAccessor = (item: any, property: string) => {
    const direction = this.upForRenewalSort.direction;

    if (property === 'expirationDate') {
      const dateValue = item.expirationDate ? new Date(item.expirationDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'canStartRenewalDate') {
      const dateValue = item.canStartRenewalDate ? new Date(item.canStartRenewalDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'timelyDate') {
      const dateValue = item.timelyDate ? new Date(item.timelyDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'deadlineForRenewal') {
      const dateValue = item.deadlineForRenewal ? new Date(item.deadlineForRenewal) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  filterUpForRenewalTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.upForRenewalSource.filter = value.trim().toLowerCase();
  }

  openManagerNotes(license: IApplicationTrackingLicense): void {
    let entityType = "";
    if(license.entityType == "TestingLab")
      entityType = this.sharedService.testingLabEntityType;
    if(license.entityType == "ServiceCenter")
      entityType = this.sharedService.serviceCenterEntityType;
    if(license.entityType == "Manufacturer")
      entityType = this.sharedService.manufacturerEntityType;
      if(license.entityType == "Facilitator")
      entityType = this.sharedService.facilitatorEntityType;
    if(license.entityType == "TrainingProgram")
      entityType = this.sharedService.trainingProgramEntityType;
    if(license.entityType == "WorkerPermit")
      entityType = this.sharedService.workerPermitEntityType;

    const dialogRef = this.dialog.open(ManagerNotesDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        entityId: license.id,
        entityType: entityType,
        managerFlag: license.managerFlag,
        reviewType: license.reviewType,
        managerNotes: license.managerNotes,
        isAdmin: this.authService.isAdmin
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        license.managerFlag = result.flag;
        license.reviewType = result.reviewType;
        license.managerNotes = result.notes;
      }
     });
  }
}
