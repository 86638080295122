import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IComplaint } from 'app/models/complaints/complaint';
import { IComplaintDialogData } from 'app/models/complaints/complaint-dialog-data';
import { ICompliance } from 'app/models/compliance/compliance';
import { ComplaintService } from 'app/services/complaints/complaint.service';
import { ComplianceService } from 'app/services/compliance/compliance.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ComplaintDialogComponent } from '../complaint.component';

@Component({
  selector: 'app-manage-entity-complaints',
  templateUrl: './manage-entity-complaints.component.html',
  styleUrls: ['./manage-entity-complaints.component.scss']
})
export class ManageEntityComplaintsComponent implements OnInit {
  @Input() parentId: number = 0;
  @Input() licenseId: string = '';
  @Input() parentName: string = '';
  @Input() entityType: string = '';
  @Input() status: string = '';
  @Input() editing: boolean = false;
  @Input() cardColor: string = '';
  @Output() notifyParent: EventEmitter<ICompliance> = new EventEmitter<ICompliance>();

  public complaints: IComplaint[] = [];
  public compliance: ICompliance = {
    id: 0,
    caseId: '',
    entityId: 0,
    entityType: '',
    status: 'New',
    category: '',
    tier: '',
    dueDate: '',
    closedDate: '',
    type: '',
    sent: null,
    assignedTo: '',
    manager: '',
    outcome: '',
    agencyGenerated: false,
    locked: false,
    dateOfNonCompliance: '',
    dateOfCompliance: '',
    originalLicenseId: '',
    complianceRules: [],
    complaints: [],
    notes: [],
    complianceActions: [],
    investigations: [],
    compliancePlans: [],
    relatedCases: [],
    licenseId: '',
    notices: [],
    referencedLicenses: [],
    complianceDocuments: [],
    noticeStatus: '',
    denialCriteria: '',
    administrativeAction: '',
  }

  public complaintColumns: string[] = ['dateCreated', 'name', 'complaintText', 'contactEmail', 'contactNumber', 'status', 'actions'];
  public complaintSource = new MatTableDataSource<IComplaint>(this.complaints);
  @ViewChild('complaintPaginator', {static: false}) complaintPaginator: MatPaginator;
  @ViewChild('complaintSort', {static: false}) complaintSort: MatSort;

  constructor(public sharedService: SharedService,
              public complaintService: ComplaintService,
              private complianceService: ComplianceService,
              private dialog: MatDialog,
              public toastr: ToastrService) { }

  ngOnInit(): void {
   this.getComplaints();
  }


  getComplaints(): void {
    this.complaintService.getComplaints(this.licenseId).subscribe(
      response => this.complaints = response,
      error => console.log('error', error),
      () => {
        this.complaints.forEach(complaint => {
          complaint.parentName = this.parentName;
          complaint.parentLicenseName = this.parentName;
        });
        this.updateComplaintTable()
      }
    );
  }

  updateComplaintTable(): void {
    this.complaintSource.data = this.complaints;
    this.complaintSource.sort = this.complaintSort;
    this.complaintSource.paginator = this.complaintPaginator;
  }

  addComplaint(): void{
    let complaint: IComplaint = {
      id: 0,
      entityType: '',
      status: '',
      name: '',
      contactEmail: '',
      contactNumber: '',
      preferredContact: '',
      identityConcern: '',
      parentId: this.licenseId,
      parentName: this.parentName,
      complaintText: '',
      previousReporting: '',
      signature: '',
      dateCreated: '',
      parentLicenseName: this.parentName,
      assignedTo: '',
      noticeStatus: '',
      caseId: ''
    }
    let dialogData: IComplaintDialogData = {
      type: this.entityType,
      complaint: complaint,
      disableParentSelection: true,
      caseId: complaint.caseId,
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.complaints.push(response);
        this.updateComplaintTable();
        this.toastr.success("Added Complaint!");
      }
    });
  }

  editComplaint(complaint: IComplaint): void {
    let dialogData: IComplaintDialogData = {
      type: this.entityType,
      complaint: complaint,
      disableParentSelection: true,
      caseId: complaint.caseId
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        let index = this.complaints.findIndex(c => c.id === response.id);
        this.complaints[index] = response;
        this.updateComplaintTable();
        this.toastr.success("Complaint Updated!");
      }
    });
  }

  openNonCompliance(complaint: IComplaint): void {
    this.sharedService.openConfirm('Open Case?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.complaintService.saveComplaint(complaint).subscribe(
          response => complaint = response,
          error => console.log('error', error),
          () => {
            this.compliance.entityType = this.entityType;
            this.compliance.entityId = this.parentId;
            this.compliance.complaints.push(complaint);
            this.complianceService.createComplianceCase(this.compliance).subscribe(
              response => this.compliance = response,
              error => console.log('error', error),
              () => {
                this.notifyParent.emit(this.compliance);
              }
            );
          }
        )
      }
    });
  }

}
