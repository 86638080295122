import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IApplicantData } from "app/models/303/applicantData/applicantData";
import { IApplicantDataSearch } from "app/models/303/applicantData/applicantDataSearch";
import { IApplicantDataTable } from "app/models/303/applicantData/applicantDataTable";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ApplicantDataService {
  private api: string = environment.privateApi + 'ApplicantData/';
  private cachedData: IApplicantData[] = [];
  private totalRecords = 0;
  private preloadedPages = 0;
  private chunkSize = 5;
  private lastSortBy: string = '';
  private lastSortDirection: string = '';
  private lastName: string = '';
  private lastLicenseId: string = '';
  private lastSubmittedDate: string = '';

  constructor(private http: HttpClient) { }

  getApplicantData(applicantDataSearch: IApplicantDataSearch): Observable<IApplicantDataTable> {
    const totalCachedPages = this.cachedData.length / 10;
    const currentChunkEndPage = Math.ceil(totalCachedPages) - 1;
    const searchParamsChanged = !this.isSearchParamsEqual(applicantDataSearch);
    const sortChanged = this.isSortChanged(applicantDataSearch.sortBy, applicantDataSearch.sortDirection);

    if (!searchParamsChanged && !sortChanged && totalCachedPages > 0 && applicantDataSearch.page <= currentChunkEndPage) {
      const startIndex = applicantDataSearch.page * 10;
      const endIndex = startIndex + 10;
      return of({
        data: this.cachedData.slice(startIndex, endIndex),
        totalRecords: this.totalRecords
      });
    }

    if (sortChanged || searchParamsChanged) {
      this.cachedData = [];
      this.preloadedPages = 0;
    }

    return this.http.post<IApplicantDataTable>(this.api + 'GetApplicantData', applicantDataSearch)
    .pipe(
      map((result) => {
        this.lastSortBy = applicantDataSearch.sortBy;
        this.lastSortDirection = applicantDataSearch.sortDirection;

        this.lastName = applicantDataSearch.name;
        this.lastLicenseId = applicantDataSearch.licenseId;
        this.lastSubmittedDate = applicantDataSearch.submittedDate;

        this.cachedData.push(...result.data);
        this.totalRecords = result.totalRecords;
        this.preloadedPages += this.chunkSize;

        const startIndex = applicantDataSearch.page * 10;
        const endIndex = startIndex + 10;
        return {
          data: this.cachedData.slice(startIndex, endIndex),
          totalRecords: this.totalRecords
        };
      })
    );
  }

  downloadAllApplicantData(): Observable<Blob> {
    return this.http.get(this.api + 'DownloadAllApplicantData', {
      responseType: 'blob'
    });
  }

  private isSearchParamsEqual(search: IApplicantDataSearch): boolean {
    return (
      this.lastName === search.name &&
      this.lastLicenseId === search.licenseId &&
      this.lastSubmittedDate === search.submittedDate
    );
  }

  private isSortChanged(sortBy: string, sortDirection: string): boolean {
    return this.lastSortBy !== sortBy || this.lastSortDirection !== sortDirection;
  }
}
