import { Component, Output, EventEmitter,
         Input, ChangeDetectionStrategy, ViewEncapsulation, OnInit,
} from "@angular/core";
import { environment } from "environments/environment";
import { Subject, Subscription, timer } from "rxjs";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import { EventMessage, EventType } from "@azure/msal-browser";
import { Router } from "@angular/router";
import { InternalApplicationMessagingService } from "app/components/internal-app-messaging/internal-app-messaging.service";
import { switchMap } from 'rxjs/operators';

@Component({
  selector: "app-navbar",
  host: {
    class: "matero-header",
  },
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})

export class NavbarComponent implements OnInit {
  //subscription !: Subscription;
  public environment = environment;
  public authenticated: boolean = false;
  @Input() isMobile = false;
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    public sharedService: SharedService,
    public router: Router,
    private authService: AuthService,
    public internalApplicationMessagingService: InternalApplicationMessagingService) {}

  ngOnInit(): void{
    if (this.isAuthenticated()) {
      this.postLogin();
    }
    else{
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      ).subscribe(
        () => {
          this.postLogin();
          this.toggleSidenav.emit();
        },
        error => console.log('error', error)
      );
    }
  }

  postLogin() {
    this.authService.setActiveAccount();
    this.authService.loadUserInformation();
    //Turning this off for optimization and biz say they do not use it. Leaving it commented out in case they want it back.
    //this.internalApplicationMessagingService.loadUnreadCount()
    ////timer to check for unread messages - in milliseconds
    // this.subscription = timer(0, 300000).pipe(
    //   switchMap(async () => {
    //     this.internalApplicationMessagingService.loadUnreadCount()
    //   })
    // ).subscribe(result => {});
  }

  public get username(): string{
    return this.authService.username
  }

  login() {
    this.authService.login();
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }

  toggleTheme() {
    this.sharedService.darkMode = !this.sharedService.darkMode;
    this.sharedService.userTheme = this.sharedService.darkMode ? "dark" : "light";
    if(this.isAuthenticated()){
      this.sharedService.updateUserTheme();
    }
    this.sharedService.toggleTheme();
  }

  ngOnDestroy(): void {

    //this.subscription.unsubscribe();
    this._destroying$.next();
    this._destroying$.complete();
  }
}
