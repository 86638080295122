import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/auth/auth.service";
import { IApplicant } from "app/models/licenses/applicant";
import { ILicenseChecklistApplicant } from "app/models/licenses/license-checklist-applicant";
import { SharedService } from "app/services/core/shared.service";
import { LicenseChecklistService } from "app/services/license-checklist/license-checklist.service";
import { ToastrService } from 'ngx-toastr';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-licenseChecklistApplicant',
  templateUrl: './license-checklist-applicant.component.html',
  styleUrls: ['./license-checklist-applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})
export class LicenseChecklistApplicantComponent implements OnInit {
  public applicantForm = new UntypedFormGroup({
    applicantName: new UntypedFormControl(""),
    typeOfId: new UntypedFormControl(""),
    nameMatchId: new UntypedFormControl(""),
    legalAgeComplete: new UntypedFormControl(""),
    backgroundCheckDate: new UntypedFormControl(""),
    applicantDataSubmittedDate: new UntypedFormControl("")
  });

  public licenseChecklistApplicants: ILicenseChecklistApplicant[] = [];

  public saving: boolean = false;
  public editing: boolean = false;
  public updatingIndex: number = 0;
  public licenseApplicants: IApplicant[] = [];
  public checklistId: number = 0;
  public today: Date = new Date(Date.now());
  public applicantColumns: string[] = ['applicantName', 'typeOfId', 'nameMatchId', 'legalAgeComplete', 'backgroundCheckDate', 'applicantDataSubmittedDate', 'actions'];
  public applicantSource = new MatTableDataSource<ILicenseChecklistApplicant>(this.licenseChecklistApplicants);
  @ViewChild('applicantPaginator', { static: false }) applicantPaginator: MatPaginator;
  @ViewChild('applicantSort', { static: false }) applicantSort: MatSort;

  constructor(public sharedService: SharedService,
    public authService: AuthService,
    private licenseChecklistService: LicenseChecklistService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<LicenseChecklistApplicantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      this.licenseChecklistApplicants = data.licenseChecklistApplicants;
      this.checklistId = data.checklistId;
      this.licenseApplicants = data.licenseApplicants;
    }

  ngOnInit(): void {
    this.getApplicants();
    this.updateTable();
  }

  getApplicants(): void {
    let newLicenseApplicants: ILicenseChecklistApplicant[] = [];
    this.licenseApplicants.forEach(applicant => {
      if(this.licenseChecklistApplicants.every(checklistApplicant => checklistApplicant.applicantId != applicant.id))
        {
          let newApplicant:ILicenseChecklistApplicant = {
            id: 0,
            applicantId: applicant.id,
            applicantName: applicant.legalFirstName + ' ' + applicant.legalMiddleName + ' ' + applicant.legalLastName,
            typeOfId: "",
            nameMatchId: false,
            legalAgeComplete: false,
            backgroundCheckDate: applicant.backgroundCheckDate,
            applicantDataSubmittedDate: ""
          }
          newLicenseApplicants.push(newApplicant);
        }
    });

    this.licenseChecklistApplicants = this.licenseChecklistApplicants.concat(newLicenseApplicants);
  }

  updateTable(): void{
    this.applicantSource.data = this.licenseChecklistApplicants.filter(checklistApplicant => {
      let applicant = this.licenseApplicants.find(applicant => applicant.id === checklistApplicant.applicantId);
      return !(applicant && applicant.archived);
    });
    this.applicantSource.sort = this.applicantSort;
    this.applicantSource.paginator = this.applicantPaginator;
  }



  Edit(applicant: ILicenseChecklistApplicant): void {
    this.editing = true;
    this.updatingIndex = this.licenseChecklistApplicants.findIndex(a => a.applicantName == applicant.applicantName);

    this.applicantForm.patchValue({
      applicantName: applicant.applicantName,
      typeOfId: applicant.typeOfId,
      nameMatchId: applicant.nameMatchId,
      legalAgeComplete: applicant.legalAgeComplete,
    });

    if(applicant.backgroundCheckDate != null && applicant.backgroundCheckDate.length > 0)
      this.applicantForm.patchValue({
        backgroundCheckDate: new Date(applicant.backgroundCheckDate)
      });
    else {
      this.applicantForm.patchValue({
        backgroundCheckDate: new Date("")
      });
    }

    if(applicant.applicantDataSubmittedDate != null && applicant.applicantDataSubmittedDate.length > 0)
      this.applicantForm.patchValue({
        applicantDataSubmittedDate: new Date(applicant.applicantDataSubmittedDate)
      });
    else {
      this.applicantForm.patchValue({
        applicantDataSubmittedDate: new Date("")
      });
    }
  }

  Cancel(): void {
    this.editing = false;
    this.updatingIndex = 0;
  }

  Update(): void {
    let form = this.applicantForm.value;
    this.licenseChecklistApplicants[this.updatingIndex].applicantName = form.applicantName;
    this.licenseChecklistApplicants[this.updatingIndex].typeOfId = form.typeOfId;
    this.licenseChecklistApplicants[this.updatingIndex].nameMatchId = form.nameMatchId;
    this.licenseChecklistApplicants[this.updatingIndex].legalAgeComplete = form.legalAgeComplete;
    this.licenseChecklistApplicants[this.updatingIndex].backgroundCheckDate = form.backgroundCheckDate;
    this.licenseChecklistApplicants[this.updatingIndex].applicantDataSubmittedDate = form.applicantDataSubmittedDate;
    this.updateTable();
    this.editing = false;
  }

  Save(): void {
    this.saving = true;
    this.licenseChecklistService.saveApplicants(this.checklistId, this.licenseChecklistApplicants).subscribe(
      response => this.licenseChecklistApplicants = response,
      error => console.log('error', error),
      () => {
        this.updateTable();
        this.saving = false;
      },
    );
}

  close(): void{
    this.dialogRef.close(this.licenseChecklistApplicants);
  }
}
