<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">
    <span class="f-s-20" fxFlex>Generating PDF</span>
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</mat-dialog-content>

<div class="print-outer">
  <div class="print-inner">
    <kendo-pdf-export #pdf
    paperSize="letter"
    [scale]="0.70"
    margin="1cm">
      <ng-template kendoPDFTemplate
                    let-pageNum="pageNum"
                    let-totalPages="totalPages">
        <div class="pageNum">Page {{ pageNum }} of {{ totalPages }}</div>
      </ng-template>
      <div fxLayout="column" id="manifestPdf" #manifestPdf>
        <div fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="column" fxFlex>
            <span><strong>PUBLIC HEALTH DIVISION</strong></span>
            <span><strong>CENTER FOR HEALTH PROTECTION</strong></span>
            <span><strong>Oregon Psilocybin Services</strong></span>
            <span><a href="http://oregon.gov/psilocybin">http://oregon.gov/psilocybin</a></span>
          </div>
          <img class="p-4" src="../../../../../assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
        </div>

        <div fxLayout="column">
          <div fxLayout="column" fxLayoutGap="8px">
            <span style="font-size: x-large" fxFlex><strong>Site Inspection for {{data.entityName}}</strong></span>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
              <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                <span><strong>Case ID:</strong></span>
                <span>{{data.siteInspection.caseId}}</span>
              </div>
              <span fxFlex></span>
            </div>
            <div fxLayout="row" fxLayoutGap="8px">
              <div fxLayout="column" fxFlex="40" fxLayoutGap="5px">
                <div fxLayout="row">
                  <span fxFlex="20"><strong>Status</strong></span>
                  <span>{{data.siteInspection.status}}</span>
                </div>
                <div fxLayout="row">
                  <span fxFlex="20"><strong>Type</strong></span>
                  <span>{{data.siteInspection.type}}</span>
                </div>
                <div fxLayout="row">
                  <span fxFlex="20"><strong>Visit Date</strong></span>
                  <span>{{data.siteInspection.visitDate| date}}</span>
                </div>
              </div>
              <div fxLayout="column" fxFlex="45" fxLayoutGap="5px">
                <div fxLayout="row">
                  <span fxFlex="30"><strong>Assigned To</strong></span>
                  <span>{{data.staffReference[data.siteInspection.assignedTo]}}</span>
                </div>
                <div fxLayout="row">
                  <span fxFlex="30"><strong>Manager</strong></span>
                  <span>{{data.staffReference[data.siteInspection.manager]}}</span>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" class="m-t-16">
            <div *ngFor="let rule of data.siteInspection.siteInspectionRules">
              <ng-container [ngTemplateOutlet]="inspectionRuleTpl"
                            [ngTemplateOutletContext]="{inspectionRule: rule, locked: true}">
              </ng-container>
            </div>
          </div>
          <span><strong>Site Visit Summary</strong></span>
          <div fxLayout="row" class="m-t-8">
            <span> {{data.siteInspection.summary}} </span>
          </div>
        </div>
      </div>
    </kendo-pdf-export>
  </div>
</div>

<ng-template #inspectionRuleTpl let-inspectionRule="inspectionRule" let-locked="locked">
  <mat-card>
    <mat-card-content>
      <mat-card>
        <mat-card-title>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <span>{{inspectionRule.rule.segmentNumber}}</span>
            <span fxFlex>{{inspectionRule.rule.segment}}</span>
            <span>OAR Rule {{inspectionRule.rule.statute}}</span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="column">
              <span><strong>Question</strong></span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.manufacturer">{{inspectionRule.rule.questionManufacturer}}</span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.serviceCenter">{{inspectionRule.rule.questionServiceCenter}}</span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.testingLab">{{inspectionRule.rule.questionLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Answer</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.answer}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Guidance</strong></span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.manufacturer">{{inspectionRule.rule.guidanceManufacturer}}</span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.serviceCenter">{{inspectionRule.rule.guidanceServiceCenter}}</span>
              <span class="text-wrap" *ngIf="data.entityType === sharedService.testingLab">{{inspectionRule.rule.guidanceLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span class="m-t-8"><strong>If Insufficient</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.nonCompliant}}</span>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="" class="m-b-16">
              <mat-checkbox [checked]="inspectionRule.compliant == true" [disabled]="locked">
                Sufficient
              </mat-checkbox>
              <mat-checkbox [checked]="inspectionRule.compliant == false" [disabled]="locked">
                Insufficient
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="inspectionRule.educationProvided" [disabled]="locked">
                Education Provided
              </mat-checkbox>
            </div>
            <div fxLayout="column">
              <span><strong>Notes</strong></span>
              <span>{{inspectionRule.notes}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</ng-template>
