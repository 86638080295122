<section class="p-b-16">
  <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
    <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs('Compliance Plan' + compliancePlan.compliancePlanId +'.pdf')">
      Export to PDF
    </button>

    <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
  </div>
</section>
<mat-dialog-content>
  <kendo-pdf-export #pdf
                    paperSize="letter"
                    [scale]="0.75"
                    margin="1cm"
                    keepTogether="span">

                    <div fxLayout="column">
                      <span style="font-size: x-large" fxFlex><strong>Compliance Plan</strong></span>
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
                        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                          <span>Compliance Plan ID:</span>
                          <span>{{compliancePlan.compliancePlanId}}</span>
                        </div>
                        <span fxFlex></span>
                      </div>
                    </div>
    <div *ngFor="let rule of compliancePlan.compliancePlanRules">



      <ng-container [ngTemplateOutlet]="compliancePlanRuleTemplate"
                    [ngTemplateOutletContext]="{compliancePlanRule: rule}">
      </ng-container>
    </div>
    <ng-template #compliancePlanRuleTemplate let-compliancePlanRule="compliancePlanRule">
      <mat-card>
        <mat-card-content>
          <mat-card  fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
              <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                <div fxLayout="row">
                  <span style="font-size: large"><strong>Rule out of compliance: {{compliancePlanRule.rule.statute}}</strong></span>
                </div>
              </div>


              <div fxLayout="column" fxLayoutGap="8px" style="font-size: large" fxLayoutAlign.lt-md="center center" fxFlex="20">
                <div fxLayout="column">
                  <span><strong>Status</strong></span>
                  <span>{{compliancePlanRule.status}}</span>
                </div>
              </div>



              <div fxLayout="column" fxLayoutGap="8px" style="font-size: large" fxLayoutAlign.lt-md="center center" fxFlex="20">
                <div fxLayout="column">
                  <span><strong>Resolved Date</strong></span>
                  <span>{{compliancePlanRule.resolvedDate | date}}</span>
                </div>
              </div>
            </div>

            <mat-card-content fxLayout="column" fxLayoutGap="16px">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                <mat-card fxFlex="50">
                  <div fxLayout="column" fxFlex="100">
                    <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                      <span><strong>Rules Summary</strong></span>
                      <span >{{compliancePlanRule.rulesSummary}}</span>
                    </div>
                  </div>
                </mat-card>

                <mat-card fxFlex="50">
                  <div fxLayout="column" fxFlex="100">
                    <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                      <span><strong>Reason for not meeting Requirement</strong></span>
                      <span >{{compliancePlanRule.notMeetingRequirementReason}}</span>
                    </div>
                  </div>
                </mat-card>
              </div>

              <div fxLayout="row">
                <mat-card fxFlex="100">
                  <div fxLayout="row" fxFlex="100">
                    <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                      <span><strong>Plan and Guidance</strong></span>
                      <span >{{compliancePlanRule.notes}}</span>
                    </div>
                  </div>
                </mat-card>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </ng-template>

  </kendo-pdf-export>
</mat-dialog-content>
