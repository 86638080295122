import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ICompliancePlan } from "app/models/compliance-plan/compliance-plan";
import { SharedService } from "app/services/core/shared.service";

@Component({
  selector: 'app-print-compliance-plan',
  templateUrl: './print-compliance-plan.component.html',
  styleUrls: ['./print-compliance-plan.component.scss']
})

export class PrintCompliancePlanComponent implements OnInit {
  public compliancePlan: ICompliancePlan = {
    id: 0,
    compliancePlanId: "",
    status: "",
    startedDate: "",
    sentTrainingPlan: false,
    assignedTo: "",
    compliancePlanRules: []
  };
  public staffReference: {[id: string]: string} = {};

  constructor(public sharedService: SharedService,
    public dialogRef: MatDialogRef<PrintCompliancePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
      this.compliancePlan = this.data.currentCompliancePlan;
      this.staffReference = this.data.staff;
    }

    cancel(): void {
      this.dialogRef.close('cancel');
    }
  }
