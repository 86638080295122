import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core';
import { IMenu } from '../../models/core/menu';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  @Input() isMobile = false;

  menu$ = this.menu.getValidMenuItems();
  menuResponse: IMenu[] = [];
  buildRoute = this.menu.buildRoute;
  @Output() toggleCollapsed = new EventEmitter<void>();

  constructor(private menu: MenuService, private authService: AuthService) { }

  ngOnInit() {
    //This sets up the namespace required for translating the sidebar options
    this.menu$.subscribe(
      response => {
        if(environment.translateText){
          this.menu.addNamespace(response, 'menu');
        }
      }
    )
  }

}
