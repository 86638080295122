<div fxLayout="Column" fxLayoutGap="8px">
  <span class="f-s-20"> Mark Payment Cleared</span>
  <form [formGroup]="paymentForm" (ngSubmit)="savePayment()" class="p-t-16">
    <div fxLayout="column" fxLayoutGap="16px" fxLayoutGap.lt-md="4px">
      <mat-form-field >
          <mat-label>Payment Cleared</mat-label>
          <input formControlName="paymentCleared"
                 placeholder="mm/dd/yyyy"
                 matInput
                 [matDatepicker]="datepickerPaymentCleared"
                 [max]="today"/>
          <mat-datepicker-toggle matSuffix [for]="datepickerPaymentCleared"></mat-datepicker-toggle>
          <mat-datepicker #datepickerPaymentCleared>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="paymentForm.get('paymentCleared').hasError('required')">
            A cleared date is required
          </mat-error>
          <mat-error *ngIf="paymentForm.get('paymentCleared').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>
      <div fxLayout="row" fxLayoutGap="16px" class="m-t-16">
        <button *ngIf="paymentForm.get('paymentCleared').value <= today && paymentForm.valid"
                mat-raised-button type="button" color="primary" [disabled]="processing"
                (click)="savePayment()">Mark as Cleared
        </button>
        <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>
