
<div class="container-1" fxLayoutGap="12px">
  <div fxLayout="column">
    <p><strong>Changed By:</strong><br/>
      {{ auditLog.userId }}<br/>
      <span *ngIf="auditLog.staffChange"><strong>Staff</strong></span>
      <span *ngIf="auditLog.licenseHolder"><strong>{{auditLog.licenseHolder}}</strong></span>
      <span *ngIf="auditLog.associatedWorker"><strong>{{auditLog.associatedWorker}}</strong></span>
    </p>
  </div>
  <div>
    <p><strong>Item Changed:</strong><br/>
    {{ auditLog.tableName }}</p>
  </div>
  <div>
    <p><strong>Change Date:</strong><br/>
    {{ auditLog.timeStamp }}</p>
  </div>
  <div>
    <p><strong>License ID:</strong><br/>
    {{ LicenseId }}</p>
  </div>
  <div>
    <p><strong>License Name:</strong><br/>
    {{ LicenseName }}</p>
  </div>
</div>

<p>
  <mat-table class="mat-elevation-z8" [dataSource]="changesSource">

    <ng-container matColumnDef="operation">
      <mat-header-cell *matHeaderCellDef>Operation</mat-header-cell>
      <mat-cell *matCellDef="let change">{{ change.operation }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="propertyName">
      <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
      <mat-cell *matCellDef="let change">{{ change.propertyName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <mat-header-cell *matHeaderCellDef>Old Value</mat-header-cell>
      <mat-cell *matCellDef="let change"><span style="white-space: pre-wrap">{{ change.oldValue }}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="newValue">
      <mat-header-cell *matHeaderCellDef>New Value</mat-header-cell>
      <mat-cell *matCellDef="let change"><span style="white-space: pre-wrap">{{ change.newValue }}</span></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="changesColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: changesColumns"></mat-row>

  </mat-table>
</p>
