<section>
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>Add Action</span>
    <button mat-raised-button type="button" color="primary" (click)="addAction()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <form [formGroup]="actionForm" (ngSubmit)="addAction()">
    <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
      <mat-form-field>
        <mat-label>Action</mat-label>
        <input type="text"
               placeholder="Select"
               aria-label="Action"
               matInput
               formControlName="action"
               [matAutocomplete]="action">
        <mat-autocomplete #action="matAutocomplete">
          <mat-option *ngFor="let action of filteredActions | async" [value]="action.value" >
            {{action.value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input placeholder="mm/dd/yyyy"
               matInput
               type="datetime-local"
               formControlName="startDate" />
        <mat-error *ngIf="this.actionForm.get('startDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input placeholder="mm/dd/yyyy"
               matInput
               type="datetime-local"
               formControlName="endDate" />
        <mat-error *ngIf="this.actionForm.get('startDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
