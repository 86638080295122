<mat-icon *ngIf="currentApplicant.possibleDuplicate && !deduplicating && !currentApplicant.duplicate" matTooltip="Possible Duplicate Applicant" matTooltipShowDelay=100 color="warn" (click)="showDuplicates()">error_outline</mat-icon>
<mat-card *ngIf="deduplicating && !selectingApplicants" fxFlex="100"> <!--[hidden]="!deduplicating || selectingApplicants"-->
  <div fxLayout="row">
    <mat-card-content fxFlex="100">
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100">
        <div fxFlex.lt-md="100" fxFlex="100">
          <div fxLayout="column">
            <mat-card fxFlex="100">
              <mat-card-title fxFlex="100">
                <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" fxFlex="100">
                  <span>Deduplicate Applicant</span>
                  <span fxFlex></span>
                  <div fxLayout="column">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign.lt-md="start center">
                      <button mat-button type="button" color="warn" (click)="close()">Cancel</button>
                      <button mat-button type="button" color="primary"(click)="markAsDuplicate()">Mark As Duplicate</button>
                    </div>
                  </div>
                </div>
              </mat-card-title>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-card-content>
  </div>

  <!--Current Applicant-->
  <div fxLayout="row" fxLayoutGap="8px">
    <mat-card fxFlex="100">
      <mat-card-title>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <span>Current Applicant</span>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <!--Personal Information-->
            <mat-card fxFlex="33" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex>Personal Information</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column">
                    <span><strong>Legal Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span>{{currentApplicant.legalFirstName}}</span>
                      <span>{{currentApplicant.legalMiddleName}}</span>
                      <span>{{currentApplicant.legalLastName}}</span>
                    </div>
                  </div>
                  <div fxLayout="column">
                    <span><strong>Preferred Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span>{{currentApplicant.preferredFirstName}}</span>
                      <span>{{currentApplicant.preferredMiddleName}}</span>
                      <span>{{currentApplicant.preferredLastName}}</span>
                    </div>
                  </div>
                  <div fxLayout="column">
                    <span><strong>Other Names or Aliases</strong></span>
                    <span>{{currentApplicant.alternateNames}}</span>
                  </div>

                  <div fxLayout="column">
                    <span><strong>DOB</strong></span>
                    <span>{{currentApplicant.dob | date}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="18px" fxFlex="100">
                    <div fxLayout="column">
                      <span><strong>SSN</strong></span>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="18px" fxLayoutGap.lt-lg=""
                        fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">

                        <span>{{ currentApplicant.ssn }}</span>

                        <mat-checkbox [checked]="currentApplicant.noSSN">
                          Applicant has no SSN
                        </mat-checkbox>
                      </div>
                      <mat-checkbox [checked]="currentApplicant.ssnAttestation">
                        <span class="text-wrap">
                          Applicant consents to use of SSN
                        </span>
                      </mat-checkbox>
                    </div>
                  </div>

                  <mat-checkbox fxFlex="40" [checked]="currentApplicant.backgroundCheckPassed" disabled>
                    Background Check Passed
                  </mat-checkbox>

                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Date</strong></span>
                    <span>{{currentApplicant.backgroundCheckDate | date}}</span>
                  </div>

                  <textarea matInput cols="2" [value]="currentApplicant.backgroundCheckNotes" placeholder="Background Check Notes" disabled></textarea>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Contact Information-->
            <mat-card fxFlex="33" fxFlex.lt-lg="100">
              <mat-card-title>
                Contact Information
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <span>{{currentApplicant.phone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <span>{{currentApplicant.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
                  </div>

                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <span>{{currentApplicant.email}}</span>
                  </div>
                  <div fxLayout="column">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <span>{{currentApplicant.language}}</span>
                  </div>

                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                    <mat-checkbox [checked]="currentApplicant.previousLicense">
                      <span class="text-wrap">Applicant has other licenses</span>
                    </mat-checkbox>
                    <div fxLayout="column" class="m-t-8" *ngIf="currentApplicant.previousLicense">
                      <span><strong>Other Oregon Psilocybin licenses</strong></span>
                      <span>{{currentApplicant.affiliatedLicenses}}</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Addresses-->
            <mat-card fxFlex="33" fxFlex.lt-md="100">
              <mat-card-title>Address</mat-card-title>
              <mat-card-content>
                <div fxLayout="row" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                    <div fxLayout="column">
                      <span><strong>Physical Address</strong></span>
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="18px">
                        <span>{{currentApplicant.physicalStreet }}</span>
                        <span>{{currentApplicant.physicalCity }}</span>
                        <span>{{currentApplicant.physicalState }}</span>
                        <span>{{currentApplicant.physicalZip }}</span>
                        <span>{{currentApplicant.physicalCounty }}</span>
                      </div>
                    </div>

                    <div fxLayout="column" class="m-t-8">
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                        <span><strong>Mailing Address</strong></span>
                        <mat-checkbox [checked]="currentApplicant.mailingIsPhysical" disabled>
                          Same as physical
                        </mat-checkbox>
                      </div>
                      <div fxLayout="column" [@slideInOut] *ngIf="!currentApplicant.mailingIsPhysical">
                        <span>{{currentApplicant.mailingStreet }}</span>
                        <span>{{currentApplicant.mailingCity }}</span>
                        <span>{{currentApplicant.mailingState }}</span>
                        <span>{{currentApplicant.mailingZip }}</span>
                        <span>{{currentApplicant.mailingCounty }}</span>
                      </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="8px">
                      <span><strong>Oregon Residency</strong></span>
                      <mat-checkbox [checked]="currentApplicant.oregonResidency" disabled>
                        <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!--Possible Duplicates-->
  <div fxLayout="row" fxLayoutGap="8px">
    <div fxFlex="100" fxLayout="column" fxLayoutGap="16px">
      <mat-card>
        <mat-card-title>
          <span>Possible Duplicates</span>
        </mat-card-title>
        <mat-card-content>
          <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
        class="mat-elevation-z8 p-t-0">
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">First</span>
            {{ applicant.firstName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Last</span>
            {{ applicant.lastName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="middleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Middle</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Middle</span>
            {{ applicant.middleName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dob">
          <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">DOB</span>
            {{ applicant.dob | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ssn">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">SSN</span>
            {{ applicant.ssn }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="applicantId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant ID</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">ID</span>
            {{ applicant.applicantId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="similarity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Similarity</span>
            {{ applicant.similarity }} %
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant" (click)="$event.stopPropagation()">
            <span class="mobile-label"></span>
            <button *ngIf="applicant.isParent" mat-flat-button type="button" color="primary" (click)="linkToParent(applicant.id)">Link To Parent</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
        <mat-row (click)="getApplicantDetails(row.id)" *matRowDef="let row; columns: dataColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
      </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

<!--View Applicant Details-->
  <div fxLayout="row" fxLayoutGap="8px">
    <mat-card *ngIf="applicantDetails.id > 0" [@slideInOut] fxFlex="100"  [style.background-color]="sharedService.gold + '20'">
      <mat-card-title>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <span>Viewing</span>
          <span>{{applicantDetails.legalFirstName}}</span>
          <span>{{applicantDetails.legalLastName}}</span>
          <span fxFlex></span>
          <button mat-flat-button type="button" color="warn" (click)="closeApplicantDetails()">Close</button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <!--Personal Information-->
            <mat-card fxFlex="35" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex>Personal Information</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column">
                    <span><strong>Legal Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span>{{applicantDetails.legalFirstName}}</span>
                      <span>{{applicantDetails.legalMiddleName}}</span>
                      <span>{{applicantDetails.legalLastName}}</span>
                    </div>
                  </div>
                  <div fxLayout="column">
                    <span><strong>Preferred Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span>{{applicantDetails.preferredFirstName}}</span>
                      <span>{{applicantDetails.preferredMiddleName}}</span>
                      <span>{{applicantDetails.preferredLastName}}</span>
                    </div>
                  </div>
                  <div fxLayout="column">
                    <span><strong>Other Names or Aliases</strong></span>
                    <span>{{applicantDetails.alternateNames}}</span>
                  </div>

                  <div fxLayout="column">
                    <span><strong>DOB</strong></span>
                    <span>{{applicantDetails.dob | date}}</span>
                  </div>

                  <div fxLayout="column" fxLayoutGap="18px" fxFlex="100">
                    <div fxLayout="column">
                      <span><strong>SSN</strong></span>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="18px" fxLayoutGap.lt-lg=""
                        fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">

                        <span>{{ applicantDetails.ssn }} </span>


                        <mat-checkbox [checked]="applicantDetails.noSSN">
                          Applicant has no SSN
                        </mat-checkbox>
                      </div>
                      <mat-checkbox [checked]="applicantDetails.ssnAttestation">
                        <span class="text-wrap">
                          Applicant consents to use of SSN
                        </span>
                      </mat-checkbox>
                    </div>
                  </div>

                  <mat-checkbox fxFlex="40" [checked]="applicantDetails.backgroundCheckPassed" disabled>
                    Background Check Passed
                  </mat-checkbox>

                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Date</strong></span>
                    <span>{{applicantDetails.backgroundCheckDate | date}}</span>
                  </div>

                  <textarea matInput cols="2" [value]="applicantDetails.backgroundCheckNotes" placeholder="Background Check Notes" disabled></textarea>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Contact Information-->
            <mat-card fxFlex="35" fxFlex.lt-lg="100">
              <mat-card-title>
                Contact Information
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <span>{{applicantDetails.phone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <span>{{applicantDetails.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
                  </div>

                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <span>{{applicantDetails.email}}</span>
                  </div>
                  <div fxLayout="column">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <span>{{applicantDetails.language}}</span>
                  </div>

                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                    <mat-checkbox [checked]="applicantDetails.previousLicense">
                      <span class="text-wrap">Applicant has other licenses</span>
                    </mat-checkbox>
                    <div fxLayout="column" class="m-t-8" *ngIf="applicantDetails.previousLicense">
                      <span><strong>Other Oregon Psilocybin licenses</strong></span>
                      <span>{{applicantDetails.affiliatedLicenses}}</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Relationships-->
            <mat-card fxFlex="30">
              <mat-card-title>
                <span>Relationships</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" style="font-size: 18px;">
                  <div *ngIf="applicantDetails.facilitators.length > 0">
                    <span class="f-s-16"><strong>Facilitators</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                      *ngFor="let relationship of applicantDetails.facilitators" [routerLink]="'facilitator/search/' + relationship.id">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>

                  <div *ngIf="applicantDetails.manufacturers.length > 0">
                    <span class="f-s-16"><strong>Manufacturers</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                      *ngFor="let relationship of applicantDetails.manufacturers" [routerLink]="'/manufacturer/search/' + relationship.id">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>

                  <div *ngIf="applicantDetails.serviceCenters.length > 0">
                    <span class="f-s-16"><strong>Service Centers</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                       *ngFor="let relationship of applicantDetails.serviceCenters" [routerLink]="'service-center/search/' + relationship.id">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>

                  <div *ngIf="applicantDetails.laboratories.length > 0">
                    <span class="f-s-16"><strong>Laboratories</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                      *ngFor="let relationship of applicantDetails.laboratories" [routerLink]="'testing-lab/search/' + relationship.id">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>

                  <div *ngIf="applicantDetails.workerPermits.length > 0">
                    <span class="f-s-16"><strong>Worker Permits</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                      *ngFor="let relationship of applicantDetails.workerPermits" [routerLink]="'worker-permit/search/' + relationship.id">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>

                  <div *ngIf="applicantDetails.entityApplicants.length > 0">
                    <span class="f-s-16"><strong>Entity Applicants</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px" class="text-indigo-A700" style="cursor: pointer"
                      *ngFor="let relationship of applicantDetails.entityApplicants">
                      <span>{{relationship.name}}</span>
                      <span>{{relationship.entityId}}</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <!--Addresses-->
            <mat-card fxFlex="100" fxFlex.lt-md="100">
              <mat-card-title>Address</mat-card-title>
              <mat-card-content>
                <div fxLayout="row" fxLayoutGap="8px" style="font-size: 18px;">
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                    <div fxLayout="column">
                      <span><strong>Physical Address</strong></span>
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="18px">
                        <span>{{applicantDetails.physicalStreet }}</span>
                        <span>{{applicantDetails.physicalCity }}</span>
                        <span>{{applicantDetails.physicalState }}</span>
                        <span>{{applicantDetails.physicalZip }}</span>
                        <span>{{applicantDetails.physicalCounty }}</span>
                      </div>
                    </div>

                    <div fxLayout="column" class="m-t-8">
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                        <span><strong>Mailing Address</strong></span>
                        <mat-checkbox [checked]="applicantDetails.mailingIsPhysical" disabled>
                          Same as physical
                        </mat-checkbox>
                      </div>
                      <div fxLayout="column" [@slideInOut] *ngIf="!applicantDetails.mailingIsPhysical">
                        <span>{{applicantDetails.mailingStreet }}</span>
                        <span>{{applicantDetails.mailingCity }}</span>
                        <span>{{applicantDetails.mailingState }}</span>
                        <span>{{applicantDetails.mailingZip }}</span>
                        <span>{{applicantDetails.mailingCounty }}</span>
                      </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="8px">
                      <span><strong>Oregon Residency</strong></span>
                      <mat-checkbox [checked]="applicantDetails.oregonResidency" disabled>
                        <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-card>

<!--Select Duplicates-->
<mat-card [hidden]="!selectingApplicants || creatingApplicant" fxFlex="100">
  <div fxLayout="row">
    <div fxFlex="100" fxLayout="column" fxLayoutGap="16px">
        <mat-card-title >
          <span>Select Duplicates</span>
        </mat-card-title>
        <mat-card-content>
          <mat-table [dataSource]="selectApplicantSource" matSort #selectApplicantsort="matSort" aria-describedby="applicants"
        class="mat-elevation-z8 p-t-0">

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">First</span>
            {{ applicant.firstName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Last</span>
            {{ applicant.lastName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="middleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Middle</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Middle</span>
            {{ applicant.middleName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dob">
          <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">DOB</span>
            {{ applicant.dob | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ssn">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">SSN</span>
            {{ applicant.ssn }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="applicantId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant ID</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">ID</span>
            {{ applicant.applicantId }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="similarity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let applicant">
            <span class="mobile-label">Similarity</span>
            {{ applicant.similarity }} %
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="selectApplicantColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: selectApplicantColumns" (click)="addToDuplicateList('duplicates', row.id)" [ngStyle]="{'background-color': isApplicantSelected(row.id)? '#95cdf5': ''}"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 20, 50]" #selectApplicantpaginator="matPaginator">
      </mat-paginator>
        </mat-card-content>
        <mat-card-title >
          <span>Search For Applicant</span>
        </mat-card-title>
        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-t-8">
            <mat-form-field>
              <input matInput type="text" placeholder="First Name" [(ngModel)]="firstName">
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="Last Name" [(ngModel)]="lastName">
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="SSN" [(ngModel)]="ssn" mask="000-00-0000">
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="Applicant Id" [(ngModel)]="applicantId">
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="Similarity Threshold" [(ngModel)]="similarity">
            </mat-form-field>
            <button mat-flat-button type="button" color="primary" (click)="searchForApplicants()">Search Applicants</button>
          </div>
          <div>
            <mat-form-field fxFlex="20" fxFlex.lt-md="100">
              <input matInput (keyup)="filterTable($event)" placeholder="Filter Results"/>
            </mat-form-field>
          </div>

          <mat-table [dataSource]="searchApplicantSource" matSort #sort="matSort" aria-describedby="applicants"
          class="mat-elevation-z8 p-t-0">
          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">First</span>
              {{ applicant.firstName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">Last</span>
              {{ applicant.lastName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="middleName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Middle</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">Middle</span>
              {{ applicant.middleName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="dob">
            <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">DOB</span>
              {{ applicant.dob | date }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ssn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">SSN</span>
              <span>***-**-</span>{{ applicant.ssn | slice: 5:9}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="applicantId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Applicant ID</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">ID</span>
              {{ applicant.applicantId }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="similarity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Similarity</mat-header-cell>
            <mat-cell tabindex="0" *matCellDef="let applicant">
              <span class="mobile-label">Similarity</span>
              {{ applicant.similarity }} %
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="searchApplicantColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: searchApplicantColumns" (click)="addToDuplicateList('search', row.id)" [ngStyle]="{'background-color': isApplicantSelected(row.id)? '#95cdf5': ''}"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
        </mat-paginator>
        </mat-card-content>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px">
    <button mat-raised-button color="warn" (click)="stopSelectingDuplicates()">Back</button>
    <button mat-raised-button color="primary" (click)="createNewApplicant()" [disabled]="duplicateList.length < 1">Next</button>
  </div>
</mat-card>

<!--Create New Applicant-->
<mat-card [hidden]="!creatingApplicant" fxFlex="100" fxLayout="column" fxLayoutGap="8px">
  <div fxFlex="100" fxLayout="row">
    <span fxFlex></span>
    <button mat-raised-button color="primary" (click)="openAutoFill()">Autofill</button>
  </div>

  <mat-card-content fxLayout="row" fxFlex="100">
    <form [formGroup]="applicantForm" fxLayout="column" fxLayoutGap="8px" fxFlex="100">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Personal Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <div fxLayout="row" fxLayout.lt-lg="column">
                <span fxFlex>Personal Information</span>
              </div>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Legal Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="legalFirstName">
                    <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                      Please enter your first name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="legalLastName">
                    <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                      Please enter your last name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="legalMiddleName">
                  </mat-form-field>
                </div>

                <span><strong>Preferred Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="preferredFirstName">
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="preferredLastName">
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                  </mat-form-field>
                </div>

                <div fxLayout="column">
                  <span><strong>Please provide any other names you have used</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                    <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                      <input matInput placeholder="Name, other name, any other names" formControlName="alternateNames">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Date of Birth</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                        [max]="sharedService.today" />
                      <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                      <mat-datepicker #dob>
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                        Date of birth is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column">
                  <span>Social Security Number Disclosure</span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                    fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                      <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                      <mat-error *ngIf="validateSsn()">
                        SSN is Required
                      </mat-error>
                    </mat-form-field>
                    <mat-checkbox formControlName="noSsn">
                      Applicant has no SSN
                    </mat-checkbox>
                  </div>
                  <mat-checkbox formControlName="ssnAttestation">
                    <span class="text-wrap">
                      Applicant consents to use of SSN
                    </span>
                  </mat-checkbox>

                  <div fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-checkbox fxFlex="35" formControlName="backgroundCheckPassed">
                        Background Check Passed
                      </mat-checkbox>
                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Initiated Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckInitiatedDate" matInput [matDatepicker]="backgroundCheckInitiatedDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckInitiatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckInitiatedDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').hasError('required')">
                          Background Check Initiated Date is required
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Completed Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').hasError('required')">
                          Background Check Completed Date is required
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <textarea matInput cols="2" formControlName="backgroundCheckNotes" placeholder="Background Check Notes"></textarea>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Contact Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Contact Information
            </mat-card-title>

            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000"
                        [validation]="false">
                      <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                        A primary phone number is required
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Alt Phone" formControlName="alternatePhone"
                        mask="(000) 000-0000 000000" [validation]="false">
                      <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Email" formControlName="email">
                      <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                        Please enter an email adress
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                      <mat-error *ngIf="validateEmail()">
                        Email does not match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Language" formControlName="language">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Previous License or Permit</strong></span>
                  <mat-checkbox formControlName="previousLicense">
                    <span class="text-wrap">
                      Check if this individual has applied for or received any other licenses from Oregon Psilocybin
                      Services</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Please provide the license ids for each Oregon Psilocybin license</span>
                    <mat-form-field>
                      <input matInput placeholder="Affiliated Licenses" formControlName="affiliatedLicenses"
                        (blur)="validatePreviousLicense()">
                      <mat-error *ngIf="validatePreviousLicense()">
                        Please provide all license numbers
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <!--Addresses-->
          <mat-card fxFlex="100" fxFlex.lt-md="100">
            <mat-card-title>Address</mat-card-title>

            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="column">
                  <span><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                      <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="physicalCity">
                      <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                        Please select a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="physicalZip">
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="physicalCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                      Same as physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet"
                          (blur)="validateMailingStreet()">
                        <mat-error *ngIf="validateMailingStreet()">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()">
                        <mat-error *ngIf="validateMailingCity()">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="validateMailingState()">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()">
                        <mat-error *ngIf="validateMailingZip()">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                        *ngIf="applicantForm.get('mailingState').value === 'OR'">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="mailingCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-18"><strong>Oregon Residency</strong></span>
                  <mat-checkbox formControlName="oregonResidency">
                    <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                  </mat-checkbox>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
    </form>
  </mat-card-content>

<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px">
  <button mat-raised-button color="warn" (click)="stopCreatingApplicant()">Back</button>
  <button mat-raised-button color="primary" (click)="saveNewApplicant()" [disabled]="!newApplicantValid()">Create Parent</button>
</div>
</mat-card>

<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
