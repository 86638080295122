import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAuditLog } from 'app/models/audit-logging/audit-logging';
import { AuditLoggingServiceCenterDataSearch } from 'app/models/audit-logging/audit-logging-service-center-data-search';
import { AuditLogService } from 'app/services/audit-log/audit-log.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dialog-service-center-data-audit-log',
  templateUrl: './service-center-data-audit-log.html',
  providers: [AuditLogService],
})
export class ServiceCenterDataAuditLogDialogComponent implements OnInit  {


  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingServiceCenterDataSearch = null;
  entityId: string = "";
  licenseId: string = "";
  licenseName: string = "";

  constructor(public sharedService: SharedService,
              private auditLogService: AuditLogService,
              private dialogRef: MatDialogRef<ServiceCenterDataAuditLogDialogComponent>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.entityId = data.entityId;
                this.licenseId = data.licenseId;
                this.licenseName = data.licenseName;
              }

              ngOnInit(): void {
                this.loading = true;
                this.auditLogService.initiateServiceCenterDataSearch(this.entityId).subscribe(
                  response => {
                    this.search = response;
                    this.loading = false;
                  },
                  error => {
                    console.log(error);
                    this.toastr.error(error);
                    this.loading = false;
                  }
                );
              }

              showLogs() {
                if(!this.search.showClients &&
                  !this.search.showDenied &&
                  !this.search.showAdverseReactions &&
                  !this.search.showAverage &&
                  !this.search.showPostSession &&
                  !this.search.showServiceCenterData)
                {
                  this.toastr.error("Select at least one type of log to display");
                  return;
                }

                this.loading = true;
                this.auditLogService.getServiceCenterDataAuditLogging(this.search).subscribe(
                  response => {
                    this.auditlogs = response;
                    this.loading = false;
                  },
                  error => {
                    console.log(error);
                    this.toastr.error(error);
                    this.loading = false;
                  }
                );
              }

            }
