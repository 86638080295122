import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { SB303Service } from "app/services/303/303.service";
import { SharedService } from "app/services/core/shared.service";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-upload-deidentified-data',
  templateUrl: './dialog-upload-deidentified-data.component.html'
})

export class DialogUploadDeIdentifiedDataComponent {

  public loading: boolean = false;

  public dateForm = new FormGroup({
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<DialogUploadDeIdentifiedDataComponent>,
    public sb303Service: SB303Service,
    public sharedService: SharedService) { }

  uploadDeIdentifiedData(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    const formData = new FormData();
    formData.append("file", file, file.name);
    let form = this.dateForm.value;
    let from = new Date(form.from).toDateString();
    let to = new Date(form.to).toDateString();

    this.sb303Service.uploadDeIdentifiedData(formData, from, to).subscribe({
      next: response => {
        this.dialogRef.close(response);
      },
      error: e =>  {
        (event.target as HTMLInputElement).value = '';
         console.log('error', e)
      },
      complete: () => {
        (event.target as HTMLInputElement).value = '';
      }
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
