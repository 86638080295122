import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { ILicenseSearch } from 'app/models/licenses/license-search';
import { IServiceCenterLicense } from 'app/models/licenses/service-center-license';
import { ISimpleLicense } from 'app/models/licenses/simple-license';
import { ITask } from 'app/models/tasks/task';
import { IStaff } from 'app/models/user-info/staff';
import { Observable } from 'rxjs';
import { ITrainingProgram } from 'app/models/training/training-program';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';
import { IWorkerLicense } from 'app/models/licenses/worker/worker-license';

@Injectable({ providedIn: 'root' })
export class ServiceCenterService {
  private api: string = environment.privateApi + 'ServiceCenter/';

  constructor(private http: HttpClient) { }

  getLicense(id: number): Observable<IServiceCenterLicense> {
    return this.http.get<IServiceCenterLicense>(this.api + 'GetLicense/' + id);
  }

  getPDF(search: AuditLoggingSearch): Observable<IServiceCenterLicense>{
    return this.http.post<IServiceCenterLicense>(this.api + "OriginalCopy", search);
  }

  withdrawLicense(approved: boolean, id: number): Observable<IServiceCenterLicense>{
    return this.http.post<IServiceCenterLicense>(this.api + "withdrawLicense/" + approved + "/" + id, null);
  }

  surrenderLicense(approved: boolean, id: number): Observable<IServiceCenterLicense>{
    return this.http.post<IServiceCenterLicense>(this.api + "surrenderLicense/" + approved + "/" + id, null);
  }

  createLicense(): Observable<IServiceCenterLicense> {
    return this.http.post<IServiceCenterLicense>(this.api + 'CreateLicense', null);
  }

  updateLicense(license: IServiceCenterLicense): Observable<ISimpleLicense> {
    return this.http.post<ISimpleLicense>(this.api + 'UpdateLicense', license);
  }

  searchLicenses(search: ILicenseSearch): Observable<ISimpleLicense[]> {
    return this.http.post<ISimpleLicense[]>(this.api + 'SearchLicenses', search);
  }

  getLicenseTasks(): Observable<ISimpleLicense[]> {
    return this.http.get<ISimpleLicense[]>(this.api + 'GetLicenseTasks');
  }

  getAssignedLicenses(): Observable<ISimpleLicense[]> {
    return this.http.get<ISimpleLicense[]>(this.api + 'GetAssignedLicenses');
  }

  claimLicense(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ClaimLicense/' + id, null);
  }


  assignLicense(staff: IStaff, id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AssignLicense/' + id, staff);
  }

  assignComplianceMember(staff: IStaff, id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignComplianceMember/' + id, staff);
  }

  releaseLicense(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ReleaseLicense/' + id, null);
  }

  releaseComplianceMember(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'ReleaseComplianceMember/' + id, null);
  }

  updateLicenseAccount(id: number, userId: string): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'UpdateLicenseAccount/' + id + '/' + userId, null);
  }

  deleteLicense(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteLicense/' + id);
  }

  sendTaskNotice(task: ITask): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'SendTaskNotice', task);
  }

  acknowledgeTask(id: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AcknowledgeTask/' + id, null);
  }

  deleteTask(task: ITask): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'DeleteTask', task);
  }

  uploadDocument(file: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument', file);
  }

  downloadDocument(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  deleteDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id);
  }

  deprecateDocument(id: number): Observable<any>{
    return this.http.post<any>(this.api + "DeprecateDocument/" + id, null);
  }

  getLicenseStaff(): Observable<IStaff[]> {
    return this.http.get<IStaff[]>(this.api + 'GetLicenseStaff');
  }

  getDocumentTypes(): Observable<IDocumentType[]> {
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  removePayment(id: number) {
    this.http.delete<any>(this.api + 'RemovePayment/' + id).subscribe(
      () => {
        return;
      },
      error => console.log('error', error)
    )
  }

  getExpiredLicenses(licenseId: string): Observable<IServiceCenterLicense[]>{
    return this.http.get<IServiceCenterLicense[]>(this.api + 'GetExpiredLicenses/' + licenseId);
  }

  createRenewalLicense(licenseId: number): Observable<IServiceCenterLicense>{
    return this.http.post<IServiceCenterLicense>(this.api + 'CreateServiceCenterRenewal/' + licenseId, null);
  }

  transferPTSAndSB303Items(expiredLicenseId: number, renewalLicenseId: number): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'TransferProductTrackingAndSB303Items/' + expiredLicenseId + '/' + renewalLicenseId);
  }

  //Practicum Sites
  getTrainingPrograms(): Observable<ITrainingProgram[]> {
    return this.http.get<ITrainingProgram[]>(this.api + 'GetTrainingPrograms');
  }

  addTrainingProgram(licenseId: number, trainingProgramId: number): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'AddTrainingProgram/' + licenseId + '/' + trainingProgramId, null);
  }

  deleteTrainingProgram(licenseId: number, trainingProgramId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.api + 'DeleteTrainingProgram/' + licenseId + '/' + trainingProgramId);
  }

  removeWorkerPermit(licenseId: number, permitId: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'RemoveWorkerPermit/' + licenseId + '/' + permitId);
  }

  removeSB303WorkerPermit(licenseId: number, permitId: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'RemoveSB303WorkerPermit/' + licenseId + '/' + permitId);
  }

  addWorkerPermit(licenseId: number, permitIds: string[]): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'AddWorkerPermit/' + licenseId, permitIds);
  }

  addSB303WorkerPermit(licenseId: number, permitIds: string[]): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'AddSB303WorkerPermit/' + licenseId, permitIds);
  }

}
