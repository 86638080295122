import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared";
import { AssociatedWorkerPermitComponent } from "./associated-worker-permits.component";
import { DialogAddPermitComponent } from "./dialog-add-permit/dialog-add-permit.component";
import { DialogAddPermitSB303Component } from "./dialog-add-permit-sb303/dialog-add-permit-sb303.component";


const COMPONENTS = [
  AssociatedWorkerPermitComponent,
  DialogAddPermitComponent,
  DialogAddPermitSB303Component
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [AssociatedWorkerPermitComponent]
})
export class AssociatedWorkerPermitsModule { }
