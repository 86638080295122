import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/services/core/shared.service';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';

@Component({
  selector: 'app-print-testing-lab',
  templateUrl: './print-testing-lab.component.html',
  styleUrls: ['./print-testing-lab.component.scss']
})
export class PrintTestingLabComponent implements OnInit {

  constructor(public sharedService: SharedService,
    public dialogRef: MatDialogRef<PrintTestingLabComponent>,
    @Inject(MAT_DIALOG_DATA) public license: IManufacturerLicense) { }

  ngOnInit(): void {

  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
