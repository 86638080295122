<h2 mat-dialog-title>
  <mat-icon class="icon-20" >announcement</mat-icon> New Announcement 
</h2>
<section *ngIf="loading">
  <h2>Loading...</h2>
  <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
  </mat-progress-bar>
</section>
<mat-card *ngIf="!loading">
      <form id="addNewInternalAnnouncement"  [formGroup]="sendNewAnnouncementForm"> 

        <div fxLayout="row wrap" fxLayoutGap="8px grid">  
            <mat-form-field fxFlex>
                <mat-label>From: </mat-label>
                <mat-select  id="recipient" name="recipient" formControlName="from" (selectionChange)="selectedToValue($event)">
                  <mat-option *ngFor="let f of from" value="{{f.recipient}}">{{f.name}}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>  

        <div fxLayout="row wrap" fxLayoutGap="8px grid">
              <mat-form-field  fxFlex   form="addNewInternalMessage">
                <mat-label>Subject:</mat-label>
                <input matInput placeholder="Subject" form="addNewInternalAnnouncement" required  formControlName="subject">
              </mat-form-field>       
        </div>  

        <div fxLayout="row wrap" fxLayoutGap="8px grid">    
              <mat-form-field  fxFlex>
                <mat-label>Message:</mat-label>
                <textarea matInput placeholder="Message" rows="10" form="addNewInternalAnnouncement" required formControlName="message" #messageBody ></textarea>
              </mat-form-field>
        </div>  
        
        <div fxLayout="row wrap" fxLayoutGap="8px grid"> 
          <mat-checkbox form="addNewInternalAnnouncement"  formControlName="active" >Active</mat-checkbox>
          <br />
        </div>   
         
      </form>

      <div> 
        <br />       
        <button mat-button color="primary" (click)="sendAnnouncement()" [disabled]="sending">Send Announcement</button>
        <button mat-button mat-dialog-close="Cancel" aria-label="Cancel">Cancel</button>
      </div>
</mat-card>
    
    