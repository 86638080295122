import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { INote } from 'app/models/notes/note';

@Injectable({providedIn: 'root'})

export class NoteService {
  private api: string = environment.privateApi + 'Notes/';

  constructor(private http: HttpClient) { }

  getNotesByEntity(entityId: string, entityType: string): Observable<INote[]>{
    return this.http.get<INote[]>(this.api + 'GetNotes/' + entityType + '/' + entityId);
  }

  saveNote(note: INote): Observable<INote>{
    return this.http.post<any>(this.api + "SaveNote", note);
  }

  deleteNote(id: number): Observable<any> {
    return this.http.get<any>(this.api + 'DeleteNote/'+ id);
  }

}
