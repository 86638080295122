import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IApplicant } from "app/models/licenses/applicant";
import { IApplicantDetails } from "app/models/licenses/applicant-search/applicant-details";
import { IApplicantSearch } from "app/models/licenses/applicant-search/applicant-search";
import { IApplicantSearchResult } from "app/models/licenses/applicant-search/applicant-search-result";
import { SharedService } from "app/services/core/shared.service";
import { ApplicantService } from "app/services/licenses/applicant.service";
import { ToastrService } from "ngx-toastr";
import { AutofillDuplicateApplicantComponent } from "./dialog/autofill-duplicate-applicant.component";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-duplicate-applicant',
  templateUrl: './duplicate-applicant.component.html',
  styleUrls: ['./duplicate-applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class DuplicateApplicantComponent implements OnInit{
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyChange: EventEmitter<IApplicant> = new EventEmitter<IApplicant>();
  @Input() currentApplicant: IApplicant = {
    id: 0,
    applicantId: "",
    ssn: "",
    noSSN: false,
    ssnAttestation: false,
    legalFirstName: "",
    legalMiddleName: "",
    legalLastName: "",
    preferredFirstName: "",
    preferredMiddleName: "",
    preferredLastName: "",
    alternateNames: "",
    oregonResidency: false,
    physicalState: "",
    physicalCity: "",
    physicalStreet: "",
    physicalZip: "",
    physicalCounty: "",
    mailingIsPhysical: false,
    mailingState: "",
    mailingCity: "",
    mailingStreet: "",
    mailingZip: "",
    mailingCounty: "",
    phone: "",
    affiliatedLicenses: "",
    alternatePhone: "",
    email: "",
    language: "",
    previousLicense: false,
    dob: "",
    backgroundCheckDate: "",
    backgroundCheckInitiatedDate: "",
    backgroundCheckPassed: false,
    backgroundCheckNotes: "",
    applicantDocuments: [],
    residentialHistory: [],
    parentApplicantId: 0,
    duplicate: false,
    possibleDuplicate: false,
    isParent: false,
    heldLicense: false,
    licensesHeld: "",
    deniedLicense: false,
    subjectToDiscipline: false,
    suedForDamages: false,
    settledAllegations: false,
    allegedAbuse: false,
    substantiatedAllegation: false,
    applicantPageCorrect: null,
    applicantPageCorrectReason: '',
    changeInConvictionHistory: false,
    archived: false,
    backgroundChecks: [],
    fingerPrintsDate: "",
    fingerPrintsExpirationDate: "",
    stateOnly: false,
    fullBackGroundCheck: false
  };

  public applicantForm = new UntypedFormGroup({
    ssn: new UntypedFormControl(""),
    noSsn: new UntypedFormControl(false),
    ssnAttestation: new UntypedFormControl(false),
    legalFirstName: new UntypedFormControl("", [Validators.required]),
    legalMiddleName: new UntypedFormControl(""),
    legalLastName: new UntypedFormControl("", [Validators.required]),
    preferredFirstName: new UntypedFormControl(""),
    preferredMiddleName: new UntypedFormControl(""),
    preferredLastName: new UntypedFormControl(""),
    alternateNames: new UntypedFormControl(""),
    oregonResidency: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
    language: new UntypedFormControl(""),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    backgroundCheckDate: new UntypedFormControl(''),
    backgroundCheckInitiatedDate: new UntypedFormControl(''),
    backgroundCheckNotes: new UntypedFormControl(''),
    backgroundCheckPassed: new UntypedFormControl(false),
    dob: new UntypedFormControl("", [Validators.required]),
    identification: new UntypedFormControl(false),
    applicantInfo: new UntypedFormControl(false),
    other: new UntypedFormControl(false),
    physicalApplication: new UntypedFormControl(false)
     }, {
      validators: [
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.emailValidator(),
        this.ssnValidator(),
        this.previousLicenseValidator()
      ]
    }
  );

 public newApplicant: IApplicant = {
   id: 0,
   applicantId: "",
   ssn: "",
   noSSN: false,
   ssnAttestation: false,
   legalFirstName: "",
   legalMiddleName: "",
   legalLastName: "",
   preferredFirstName: "",
   preferredMiddleName: "",
   preferredLastName: "",
   alternateNames: "",
   oregonResidency: false,
   physicalState: "",
   physicalCity: "",
   physicalStreet: "",
   physicalZip: "",
   physicalCounty: "",
   mailingIsPhysical: false,
   mailingState: "",
   mailingCity: "",
   mailingStreet: "",
   mailingZip: "",
   mailingCounty: "",
   phone: "",
   affiliatedLicenses: "",
   alternatePhone: "",
   email: "",
   language: "",
   previousLicense: false,
   dob: "",
   backgroundCheckDate: "",
   backgroundCheckInitiatedDate: "",
   backgroundCheckPassed: false,
   backgroundCheckNotes: "",
   applicantDocuments: [],
   residentialHistory: [],
   parentApplicantId: 0,
   duplicate: false,
   possibleDuplicate: false,
   isParent: false,
   heldLicense: false,
   licensesHeld: "",
   deniedLicense: false,
   subjectToDiscipline: false,
   suedForDamages: false,
   settledAllegations: false,
   allegedAbuse: false,
   substantiatedAllegation: false,
   applicantPageCorrect: null,
   applicantPageCorrectReason: '',
   changeInConvictionHistory: false,
   archived: false,
   backgroundChecks: [],
   fingerPrintsDate: "",
   fingerPrintsExpirationDate: "",
   stateOnly: false,
   fullBackGroundCheck: false
 };

 public applicantDetails: IApplicantDetails = {
   id: 0,
   applicantId: '',
   ssn: '',
   noSSN: false,
   ssnAttestation: false,
   legalFirstName: '',
   legalMiddleName: '',
   legalLastName: '',
   preferredFirstName: '',
   preferredMiddleName: '',
   preferredLastName: '',
   alternateNames: '',
   oregonResidency: false,
   physicalState: '',
   physicalCity: '',
   physicalStreet: '',
   physicalZip: '',
   physicalCounty: '',
   mailingIsPhysical: false,
   mailingState: '',
   mailingCity: '',
   mailingStreet: '',
   mailingZip: '',
   mailingCounty: '',
   phone: '',
   affiliatedLicenses: '',
   alternatePhone: '',
   email: '',
   language: '',
   previousLicense: false,
   dob: '',
   backgroundCheckDate: '',
   backgroundCheckPassed: false,
   backgroundCheckNotes: '',
   entityApplicants: [],
   facilitators: [],
   manufacturers: [],
   serviceCenters: [],
   laboratories: [],
   workerPermits: [],
   applicantDocuments: [],
   residentialHistory: [],
   heldLicense: false,
   licensesHeld: "",
   deniedLicense: false,
   subjectToDiscipline: false,
   suedForDamages: false,
   settledAllegations: false,
   allegedAbuse: false,
   substantiatedAllegation: false,
   backgroundChecks: []
 }
 public today: Date = new Date(Date.now());
 public duplicateApplicants: IApplicantSearchResult[] = [];
 public duplicateList:IApplicantSearchResult[] = [];
 public deduplicating: boolean = false;
 public selectingApplicants: boolean = false;
 public creatingApplicant: boolean = false;
 public loading: boolean = true;
 public search: IApplicantSearch = {
   applicantId: "",
   firstName: "",
   lastName: "",
   dob: "",
   ssn: "",
   entityName: "",
   city: "",
   similarity: 0,
   duplicate: false,
   archived: false
 }

 public firstName: string = '';
 public lastName: string = '';
 public applicantId: string = '';
 public ssn: string = '';
 public showSsn: boolean = false;
 public similarity: number = 70;
 public searchApplicants: IApplicantSearchResult[] = [];

 public dataColumns: string[] = ['firstName', 'lastName', 'middleName', 'dob', 'ssn', 'applicantId', 'similarity','action'];
 public dataSource = new MatTableDataSource<IApplicantSearchResult>(this.duplicateApplicants);
 @ViewChild('paginator', {static: false}) paginator: MatPaginator;
 @ViewChild('sort', {static: false}) sort: MatSort;

 public selectApplicantColumns: string[] = ['firstName', 'lastName', 'middleName', 'dob', 'ssn', 'applicantId', 'similarity'];
 public selectApplicantSource = new MatTableDataSource<IApplicantSearchResult>(this.duplicateApplicants);
 @ViewChild('paginator', {static: false}) selectApplicantpaginator: MatPaginator;
 @ViewChild('sort', {static: false}) selectApplicantsort: MatSort;

 public searchApplicantColumns: string[] = ['firstName', 'lastName', 'middleName', 'dob', 'ssn', 'applicantId', 'similarity'];
 public searchApplicantSource = new MatTableDataSource<IApplicantSearchResult>(this.searchApplicants);
 @ViewChild('paginator', {static: false}) searchApplicantpaginator: MatPaginator;
 @ViewChild('sort', {static: false}) searchApplicantsort: MatSort;

  constructor(public sharedService: SharedService,
    public applicantService: ApplicantService,
    public toastr: ToastrService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = false;
  }


  close(): void {
    this.loading = true;
    this.deduplicating = false;
    setTimeout(() => {
      this.notifyParent.emit(this.deduplicating);
    }, 20);
    this.loading = false;
  }

  showDuplicates():void {
    this.loading = true;
    this.deduplicating = !this.deduplicating;
    this.notifyParent.emit(this.deduplicating);

    this.search.firstName = this.currentApplicant.legalFirstName;
    this.search.lastName = this.currentApplicant.legalLastName;
    this.search.similarity = 70;

    this.applicantService.searchApplicants(this.search).subscribe(
      response =>
        {
          this.duplicateApplicants = response.filter(a => a.id != this.currentApplicant.id);
          this.updateApplicantTable();
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  updateApplicantTable(): void {
    this.dataSource.data = this.duplicateApplicants;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateSelectApplicantTable(): void {
    this.selectApplicantSource.data = this.duplicateApplicants.filter(a => !a.isParent);
    this.selectApplicantSource.paginator = this.selectApplicantpaginator
    this.selectApplicantSource.sort = this.selectApplicantsort;
  }

  updateSearchApplicantsTable(): void {
    this.searchApplicantSource.data = this.searchApplicants;
    this.searchApplicantSource.paginator = this.searchApplicantpaginator
    this.searchApplicantSource.sort = this.searchApplicantsort;
  }

  getApplicantDetails(id: number): void {
    this.applicantService.getApplicantDetails(id).subscribe(
      response => this.applicantDetails = response,
      error => console.log('error', error)
    );
  }

  closeApplicantDetails(): void {
    let emptyDetails: IApplicantDetails = {
      id: 0,
      applicantId: '',
      ssn: '',
      noSSN: false,
      ssnAttestation: false,
      legalFirstName: '',
      legalMiddleName: '',
      legalLastName: '',
      preferredFirstName: '',
      preferredMiddleName: '',
      preferredLastName: '',
      alternateNames: '',
      oregonResidency: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      phone: '',
      affiliatedLicenses: '',
      alternatePhone: '',
      email: '',
      language: '',
      previousLicense: false,
      dob: '',
      backgroundCheckDate: '',
      backgroundCheckPassed: false,
      backgroundCheckNotes: '',
      entityApplicants: [],
      facilitators: [],
      manufacturers: [],
      serviceCenters: [],
      laboratories: [],
      workerPermits: [],
      applicantDocuments: [],
      residentialHistory: [],
      heldLicense: false,
      licensesHeld: "",
      deniedLicense: false,
      subjectToDiscipline: false,
      suedForDamages: false,
      settledAllegations: false,
      allegedAbuse: false,
      substantiatedAllegation: false,
      backgroundChecks: []
    };
    this.applicantDetails = emptyDetails;
  }

  markAsDuplicate(): void {
    this.updateSelectApplicantTable();
    this.selectingApplicants = true;
  }

  addToDuplicateList(table: string, applicantId: number): void{

    let selected = this.duplicateList.findIndex(a => a.id == applicantId);

    if(selected == -1)
      {
        if(table == 'duplicates')
          this.duplicateList.push(this.duplicateApplicants.find(a => a.id == applicantId));
        if(table == 'search')
          this.duplicateList.push(this.searchApplicants.find(a => a.id == applicantId));
      }
    else
      this.duplicateList = this.duplicateList.filter(a => a.id !== applicantId);
  }

  stopSelectingDuplicates(): void {
    this.selectingApplicants = false;
    this.closeApplicantDetails();
  }

  createNewApplicant(): void {
    this.creatingApplicant = true;
  }

  stopCreatingApplicant(): void {
    this.creatingApplicant = false;
  }

  isApplicantSelected(id: number): boolean {
    return this.duplicateList.some(a => a.id === id);
  }

  openAutoFill(){
    let autoFillList:IApplicantSearchResult[]  = [];
    this.duplicateList.forEach(
      applicant => {
        autoFillList.push(applicant);
      }
    );

    let currentApplicant: IApplicantSearchResult = {
      id: this.currentApplicant.id,
      firstName: this.currentApplicant.legalFirstName,
      lastName: this.currentApplicant.legalLastName,
      middleName: this.currentApplicant.legalMiddleName,
      dob: this.currentApplicant.dob,
      ssn: this.currentApplicant.ssn,
      applicantId: this.currentApplicant.applicantId,
      similarity: 100,
      duplicate: false,
      isParent: false
    };
    autoFillList.push(currentApplicant);

    const dialogRef = this.dialog.open(AutofillDuplicateApplicantComponent, {
      minWidth: !this.sharedService.mobile? '800px' : '',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      data: autoFillList,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result >= 0)
        {
          this.applicantService.getApplicant(result).subscribe(
            response => {
              this.newApplicant = response;
              this.updateApplicantForm();
            },
            error => console.log('error', error)
          )
        }
     });
  }

  updateApplicantForm(): void {
    this.applicantForm.patchValue({
      ssn: this.newApplicant.ssn,
      noSsn: this.newApplicant.noSSN,
      ssnAttestation: this.newApplicant.ssnAttestation,
      legalFirstName: this.newApplicant.legalFirstName,
      legalMiddleName: this.newApplicant.legalMiddleName,
      legalLastName: this.newApplicant.legalLastName,
      preferredFirstName: this.newApplicant.preferredFirstName,
      preferredMiddleName: this.newApplicant.preferredMiddleName,
      preferredLastName: this.newApplicant.preferredLastName,
      alternateNames: this.newApplicant.alternateNames,
      oregonResidency: this.newApplicant.oregonResidency,
      physicalStreet: this.newApplicant.physicalStreet,
      physicalCity: this.newApplicant.physicalCity,
      physicalState: this.newApplicant.physicalState,
      physicalZip: this.newApplicant.physicalZip,
      physicalCounty: this.newApplicant.physicalCounty,
      mailingIsPhysical: this.newApplicant.mailingIsPhysical,
      mailingStreet: this.newApplicant.mailingStreet,
      mailingCity: this.newApplicant.mailingCity,
      mailingState: this.newApplicant.mailingState,
      mailingZip: this.newApplicant.mailingZip,
      mailingCounty: this.newApplicant.mailingCounty,
      phone: this.newApplicant.phone,
      alternatePhone: this.newApplicant.alternatePhone,
      email: this.newApplicant.email,
      confirmEmail: this.newApplicant.email,
      language: this.newApplicant.language,
      backgroundCheckPassed: this.newApplicant.backgroundCheckPassed,
      backgroundCheckNotes: this.newApplicant.backgroundCheckNotes,
      previousLicense: this.newApplicant.previousLicense,
      affiliatedLicenses: this.newApplicant.affiliatedLicenses,
      identification: false,
      residency: false
    });
    if(this.newApplicant.dob != null && this.newApplicant.dob.length > 0)
    {
      this.applicantForm.patchValue({
        dob: new Date(this.newApplicant.dob)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }
    if(this.newApplicant.backgroundCheckDate != null && this.newApplicant.backgroundCheckDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckDate: new Date(this.newApplicant.backgroundCheckDate)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }
    if(this.newApplicant.backgroundCheckInitiatedDate != null && this.newApplicant.backgroundCheckInitiatedDate.length > 0)
    {
      this.applicantForm.patchValue({
        backgroundCheckInitiatedDate: new Date(this.newApplicant.backgroundCheckInitiatedDate)
      });
    }
    else {
      this.applicantForm.get('backgroundCheckDate').reset();
    }


  }

  newApplicantValid(): boolean {
    return this.applicantForm.valid;
  }

  saveNewApplicant(): void {
    this.sharedService.openConfirm("Are you sure you want to create a parent applicant?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.updateApplicant();
          this.applicantService.createParentApplicant(this.newApplicant).subscribe(
            response => {
              let parentId = response.id;
              if(parentId > 0)
              {
                let currentApplicantResult: IApplicantSearchResult = {
                  id: this.currentApplicant.id,
                  firstName: this.currentApplicant.legalFirstName,
                  lastName: this.currentApplicant.legalLastName,
                  middleName: this.currentApplicant.legalMiddleName,
                  dob: this.currentApplicant.dob,
                  ssn: this.currentApplicant.ssn,
                  applicantId: this.currentApplicant.applicantId,
                  similarity: 100,
                  duplicate: this.currentApplicant.duplicate,
                  isParent: false
                };
                this.duplicateList.push(currentApplicantResult);

                this.applicantService.markAsDuplicate(this.duplicateList, parentId).subscribe(
                  response => {
                    if(response) {
                      this.toastr.success('Applicants Deduplicated');
                      this.currentApplicant.possibleDuplicate = false;
                      this.currentApplicant.duplicate = true;
                      this.currentApplicant.parentApplicantId = parentId;
                      this.notifyChange.emit(this.currentApplicant);
                      this.creatingApplicant = false;
                      this.selectingApplicants = false;
                      this.close();
                    }
                    else
                      this.toastr.error('Could not deduplicate Applicants');
                  },
                  error => console.log('error', error)
                );}
              else
                this.toastr.error('Could not deduplicate Applicants');
            },
            error => console.log('error', error)
          );
      }});


  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    this.newApplicant.ssn = applicantInfo.ssn,
    this.newApplicant.noSSN = applicantInfo.noSsn,
    this.newApplicant.ssnAttestation = applicantInfo.ssnAttestation,
    this.newApplicant.legalFirstName = applicantInfo.legalFirstName,
    this.newApplicant.legalMiddleName = applicantInfo.legalMiddleName,
    this.newApplicant.legalLastName = applicantInfo.legalLastName,
    this.newApplicant.preferredFirstName = applicantInfo.preferredFirstName,
    this.newApplicant.preferredMiddleName = applicantInfo.preferredMiddleName,
    this.newApplicant.preferredLastName = applicantInfo.preferredLastName,
    this.newApplicant.alternateNames = applicantInfo.alternateNames,
    this.newApplicant.oregonResidency = applicantInfo.oregonResidency,
    this.newApplicant.physicalState = applicantInfo.physicalState,
    this.newApplicant.physicalCity = applicantInfo.physicalCity,
    this.newApplicant.physicalStreet = applicantInfo.physicalStreet,
    this.newApplicant.physicalZip = applicantInfo.physicalZip,
    this.newApplicant.physicalCounty = applicantInfo.physicalCounty,
    this.newApplicant.mailingIsPhysical = applicantInfo.mailingIsPhysical,
    this.newApplicant.mailingState = applicantInfo.mailingState,
    this.newApplicant.mailingCity = applicantInfo.mailingCity,
    this.newApplicant.mailingStreet = applicantInfo.mailingStreet,
    this.newApplicant.mailingZip = applicantInfo.mailingZip,
    this.newApplicant.mailingCounty = applicantInfo.mailingCounty,
    this.newApplicant.phone = applicantInfo.phone,
    this.newApplicant.alternatePhone = applicantInfo.alternatePhone,
    this.newApplicant.email = applicantInfo.email,
    this.newApplicant.language = applicantInfo.language,
    this.newApplicant.backgroundCheckDate = applicantInfo.backgroundCheckDate,
    this.newApplicant.backgroundCheckInitiatedDate = applicantInfo.backgroundCheckInitiatedDate,
    this.newApplicant.backgroundCheckNotes = applicantInfo.backgroundCheckNotes,
    this.newApplicant.backgroundCheckPassed = applicantInfo.backgroundCheckPassed,
    this.newApplicant.previousLicense = applicantInfo.previousLicense,
    this.newApplicant.affiliatedLicenses = applicantInfo.affiliatedLicenses,
    this.newApplicant.dob = applicantInfo.dob

    if(applicantInfo.mailingIsPhysical){
      this.newApplicant.mailingState = applicantInfo.physicalState;
      this.newApplicant.mailingCity = applicantInfo.physicalCity;
      this.newApplicant.mailingStreet = applicantInfo.physicalStreet;
      this.newApplicant.mailingZip = applicantInfo.physicalZip;
      this.newApplicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
  }

  linkToParent(id: number): void {
    this.sharedService.openConfirm("Do you want to link this applicant to the existing parent?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.linkToParent(this.currentApplicant.id, id).subscribe(
            response => {
              if(response)
              {
                this.toastr.success('Applicant linked to parent');
                this.creatingApplicant = false;
                this.currentApplicant.possibleDuplicate = false;
                this.currentApplicant.duplicate = true;
                this.currentApplicant.parentApplicantId = id;
                this.notifyChange.emit(this.currentApplicant);
                this.selectingApplicants = false;
                this.close();
              }
              else{
                this.toastr.error('Could not link applicant');
              }
            },
            error => console.log('error', error)
          );
    }});

  }

  searchForApplicants(): void {
    this.searchApplicants.forEach(applicant => {
      this.duplicateList = this.duplicateList.filter(a => a.id !== applicant.id);
    })

    let search: IApplicantSearch = {
      applicantId: this.applicantId,
      firstName: this.firstName,
      lastName: this.lastName,
      ssn: this.ssn,
      entityName: '',
      city: '',
      similarity: this.similarity,
      dob: "",
      duplicate: false,
      archived: false
    }

    this.applicantService.searchApplicants(search).subscribe(
      response => this.searchApplicants = response,
      error => console.log('error', error),
      () => this.updateSearchApplicantsTable()
    );
  }

  filterTable(event: Event): void{
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.searchApplicantSource.filter = filter;
  }


//Validators
  ssnValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ssn = control.value.ssn;
      const noSsn = control.value.noSsn;
      if(noSsn){
        return null;
      }
      return (ssn !== null && ssn !== '' && ssn !== undefined) ? null : { ssnRequired: true };
    }
  }

  validateSsn(): boolean {
    if (this.applicantForm.hasError('ssnRequired') && this.applicantForm.get('ssn').touched) {
      this.applicantForm.get('ssn').setErrors(['ssnRequired']);
      return true;
    }
    this.applicantForm.get('ssn').clearValidators();
    this.applicantForm.get('ssn').updateValueAndValidity();
    return false;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.email;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators
}
