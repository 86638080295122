import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IComplaint } from "app/models/complaints/complaint";
import { IComplaintTrackingItem } from "app/models/complaints/complaint-tracking-item";
import { IComplianceTrackingItem } from "app/models/compliance/compliance-tracking-item";
import { ISiteInspectionTrackingItem } from "app/models/site-inspection/site-inspection-tracking-item";
import { IStaffType } from "app/models/user-info/staff-type";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})

export class ComplianceTrackingService {
  private api: string = environment.privateApi + 'ComplianceTracking/';

  constructor(private http: HttpClient) { }

  getCases(): Observable<IComplianceTrackingItem[]>{
      return this.http.get<IComplianceTrackingItem[]>(this.api + "GetCases");
    }

  getStaff(): Observable<IStaffType[]>{
      return this.http.get<IStaffType[]>(this.api + 'GetStaff');
    }

  getAdmins(): Observable<IStaffType[]> {
      return this.http.get<IStaffType[]>(this.api + "GetAdmins");
    }

  assignCase(userId: string, caseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignCase/' + userId + '/' + caseId, null)
  }

  assignCaseManager(userId: string, caseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignCaseManager/' + userId + '/' + caseId, null)
  }

  getSiteInspections(): Observable<ISiteInspectionTrackingItem[]>{
    return this.http.get<ISiteInspectionTrackingItem[]>(this.api + "GetSiteInspections");
  }

  assignSiteInspection(userId: string, caseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignSiteInspection/' + userId + '/' + caseId, null)
  }

  assignSiteInspectionManager(userId: string, caseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignSiteInspectionManager/' + userId + '/' + caseId, null)
  }

  getComplaints(): Observable<IComplaintTrackingItem[]>{
    return this.http.get<IComplaintTrackingItem[]>(this.api + "GetComplaints");
  }
}
