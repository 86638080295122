import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ISiteInspection } from 'app/models/site-inspection/site-inspection';
import { IFollowup } from 'app/models/site-inspection/followup';
import { MatDialog } from '@angular/material/dialog';
import { DialogExportFollowupComponent } from 'app/components/site-inspection/dialog-export-followup/dialog-export-followup.component';
import { DialogExportSiteInspectionComponent } from 'app/components/site-inspection/dialog-export-site-inspection/dialog-export-site-inspection.component';
import { IFollowupRule } from 'app/models/site-inspection/followup-rule';
import { IDocument } from 'app/models/documents/document';

@Injectable({providedIn: 'root'})
export class SiteInspectionService {
  private readonly localStorageKey = 'myCachedRequests';
  public api: string = environment.privateApi + "SiteInspection/"

  constructor(private httpClient: HttpClient,
              public dialog: MatDialog,) {
    window.addEventListener('online', () => this.sendCachedRequests());
   }

   public exportFollowup(followup: IFollowup, staffReference: {[id: string]: string}, entityName: string, siteInspectionType: string): void {
    this.dialog.open(DialogExportFollowupComponent, {
      data: {followup: followup, staffReference: staffReference, entityName: entityName, siteInspectionType: siteInspectionType},
      role: 'dialog',
      ariaLabel: "Export Followup PDF",
      minWidth: '350px',
      maxWidth: '350px',
    });
  }

  public exportSiteInspection(siteInspection: ISiteInspection, staffReference: {[id: string]: string}, entityName: string, entityType: string): void {
    this.dialog.open(DialogExportSiteInspectionComponent, {
      data: {siteInspection: siteInspection, staffReference: staffReference, entityName: entityName, entityType: entityType},
      role: 'dialog',
      ariaLabel: "Export Site Inspection PDF",
      minWidth: '350px',
      maxWidth: '350px',
    });
  }

  getSiteInspections(entityId: number, entityType: string) : Observable<ISiteInspection[]>{
    return this.httpClient.get<ISiteInspection[]>(this.api + 'GetSiteInspections/' + entityId + '/' + entityType);
  }

  getExpiredSiteInspections(entityId: number, licenseId: string, entityType: string) : Observable<ISiteInspection[]>{
    return this.httpClient.get<ISiteInspection[]>(this.api + 'GetExpiredSiteInspections/' + entityId + '/' + licenseId + '/' + entityType);
  }

  createSiteInspection(entityId: number, entityType: string): Observable<ISiteInspection> {
    let extension: string = 'CreateSiteInspection/' + entityId + '/' + entityType;
    if (navigator.onLine) {
      return this.httpClient.post<ISiteInspection>(this.api + 'CreateSiteInspection/' + entityId + '/' + entityType, null);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'type': 'siteInspection'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  createFollowUp(siteInspection: any): Observable<ISiteInspection> {
    return this.httpClient.post<ISiteInspection>(this.api + 'CreateFollowUp', siteInspection);
  }

  uploadDocument(file: FormData): Observable<IDocument>{
    return this.httpClient.post<IDocument>(this.api + 'UploadFollowupDocument', file);
  }

  downloadDocument(fileId: number): Observable<Blob>{
    return this.httpClient.get<Blob>(this.api + "DownloadFollowupDocument/" + fileId, { responseType: 'blob' as 'json' });
  }

  deleteDocument(id: number): Observable<any>{
    return this.httpClient.delete<any>(this.api + "DeleteFollowupDocument/" + id);
  }

  updateSiteInspection(siteInspection: any): Observable<ISiteInspection> {
    let extension: string = 'UpdateSiteInspection';
    if (navigator.onLine) {
      return this.httpClient.post<ISiteInspection>(this.api + 'UpdateSiteInspection', siteInspection);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': siteInspection, 'type': 'siteInspection'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(siteInspection);
        observer.complete();
      });
    }
  }

  updateNonCompliantRules(siteInspection: any): Observable<IFollowupRule[]> {
    return this.httpClient.post<IFollowupRule[]>(this.api + 'UpdateNonCompliantRules', siteInspection);
  }

  updateFollowup(followup: any): Observable<boolean> {
    let extension: string = 'UpdateFollowup';
    if (navigator.onLine) {
      return this.httpClient.post<boolean>(this.api + 'UpdateFollowup', followup);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'data': followup, 'type': 'siteInspection'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(followup);
        observer.complete();
      });
    }
  }

  addRule(followupId: number): Observable<IFollowupRule> {
    return this.httpClient.post<IFollowupRule>(this.api + 'AddFollowupRule/' + followupId, null);
  }

  lockFollowup(followupId: number, locked: boolean): Observable<boolean> {
    let extension: string = 'LockFollowup/' + followupId + '/' + locked;
    if (navigator.onLine) {
      return this.httpClient.post<boolean>(this.api + 'LockFollowup/' + followupId + '/' + locked, null);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'type': 'siteInspection'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  lockSiteInspection(inspectionId: number, locked: boolean): Observable<boolean> {
    let extension: string = 'LockSiteInspection/' + inspectionId + '/' + locked;
    if (navigator.onLine) {
      return this.httpClient.post<boolean>(this.api + 'LockSiteInspection/' + inspectionId + '/' + locked, null);
    } else {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
      cachedRequests[extension] = {'type': 'siteInspection'};
      localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
  }

  sendCachedRequests(): void {
    if (navigator.onLine) {
      const cachedRequests = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
        Object.keys(cachedRequests).forEach(extension => {
          const request = cachedRequests[extension];
          if(request['type'] == 'siteInspection')
          {
                this.httpClient.post(this.api + extension, request['data']).subscribe(() => {
                  delete cachedRequests[extension];
                  localStorage.setItem(this.localStorageKey, JSON.stringify(cachedRequests));
                });
          }
        });
        Object.keys(cachedRequests).forEach(extension => {
          delete cachedRequests[extension];
        });
      }
    }
}
