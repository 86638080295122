import { Component} from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { environment } from "environments/environment";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  public environment = environment;

  constructor(public sharedService: SharedService,
              public authService: AuthService) {}

 

}
