import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/auth/auth.service";
import { ILicenseChecklistCurriculum } from "app/models/licenses/license-checklist-curriculum";
import { SharedService } from "app/services/core/shared.service";
import { LicenseChecklistService } from "app/services/license-checklist/license-checklist.service";
import { ToastrService } from 'ngx-toastr';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-licenseChecklistCurriculum',
  templateUrl: './license-checklist-curriculum.component.html',
  styleUrls: ['./license-checklist-curriculum.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})
export class LicenseChecklistCurriculumComponent implements OnInit {
  public curriculumForm = new UntypedFormGroup({
    curriculumOne: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumOneA: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumOneB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumOneC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwo: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoA: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoD: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoE: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumTwoF: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThree: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCE:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCF:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCG:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeCH:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeE:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumThreeF:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFour: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourA: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourD: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourE: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFourF: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFive:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveA: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveD: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveE: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveF: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveG: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveH: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumFiveI: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSix: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixA: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixD: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixE: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixF: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixG: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixH: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixI: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixJ: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixK: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSixL: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSeven: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenAA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenAB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenAC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenAD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenBA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenBB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenBC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenCA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenCB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenCC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenCD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenCE:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumSevenE:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEight: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightAA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightAB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightAC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumEightD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNine: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineAA:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineAB:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineAC:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineAD:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineAE:new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineB: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineC: new UntypedFormControl(false, [Validators.requiredTrue]),
    curriculumNineD: new UntypedFormControl(false, [Validators.requiredTrue]),
    practicumPlanIncluded: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  public licenseCurriculums: ILicenseChecklistCurriculum[] = [];
  public curriculum: ILicenseChecklistCurriculum = {
    id: 0,
    curriculumOne: false,
    curriculumOneA: false,
    curriculumOneB: false,
    curriculumOneC: false,
    curriculumTwo: false,
    curriculumTwoA: false,
    curriculumTwoB: false,
    curriculumTwoC: false,
    curriculumTwoD: false,
    curriculumTwoE: false,
    curriculumTwoF: false,
    curriculumThreeA: false,
    curriculumThreeB: false,
    curriculumThreeC: false,
    curriculumThreeCA: false,
    curriculumThreeCB: false,
    curriculumThreeCC: false,
    curriculumThreeCD: false,
    curriculumThreeCE: false,
    curriculumThreeCF: false,
    curriculumThreeCG: false,
    curriculumThreeCH: false,
    curriculumThreeD: false,
    curriculumThreeE: false,
    curriculumThreeF: false,
    curriculumFour: false,
    curriculumFourA: false,
    curriculumFourB: false,
    curriculumFourC: false,
    curriculumFourD: false,
    curriculumFourE: false,
    curriculumFourF: false,
    curriculumFive: false,
    curriculumFiveA: false,
    curriculumFiveB: false,
    curriculumFiveC: false,
    curriculumFiveD: false,
    curriculumFiveE: false,
    curriculumFiveF: false,
    curriculumFiveG: false,
    curriculumFiveH: false,
    curriculumFiveI: false,
    curriculumSix: false,
    curriculumSixA: false,
    curriculumSixB: false,
    curriculumSixC: false,
    curriculumSixD: false,
    curriculumSixE: false,
    curriculumSixF: false,
    curriculumSixG: false,
    curriculumSixH: false,
    curriculumSixI: false,
    curriculumSixJ: false,
    curriculumSixK: false,
    curriculumSixL: false,
    curriculumSeven: false,
    curriculumSevenA: false,
    curriculumSevenAA: false,
    curriculumSevenAB: false,
    curriculumSevenAC: false,
    curriculumSevenAD: false,
    curriculumSevenB: false,
    curriculumSevenBA: false,
    curriculumSevenBB: false,
    curriculumSevenBC: false,
    curriculumSevenC: false,
    curriculumSevenCA: false,
    curriculumSevenCB: false,
    curriculumSevenCC: false,
    curriculumSevenCD: false,
    curriculumSevenCE: false,
    curriculumSevenD: false,
    curriculumSevenE: false,
    curriculumEight: false,
    curriculumEightA: false,
    curriculumEightAA: false,
    curriculumEightAB: false,
    curriculumEightAC: false,
    curriculumEightB: false,
    curriculumEightC: false,
    curriculumEightD: false,
    curriculumNine: false,
    curriculumNineA: false,
    curriculumNineAA: false,
    curriculumNineAB: false,
    curriculumNineAC: false,
    curriculumNineAD: false,
    curriculumNineAE: false,
    curriculumNineB: false,
    curriculumNineC: false,
    curriculumNineD: false,
    practicumPlanIncluded: false,
    curriculumThree: false,
    submittedDate: "",
    completed: false
  };
  public checklistId: number = 0;
  public today: Date = new Date(Date.now());
  public addingCurriculum: boolean = false;
  public editing: boolean = false;

  public curriculumColumns: string[] = ['completed', 'submittedDate', 'actions'];
  public curriculumSource = new MatTableDataSource<ILicenseChecklistCurriculum>(this.licenseCurriculums);
  @ViewChild('curriculumPaginator', { static: false }) curriculumPaginator: MatPaginator;
  @ViewChild('curriculumSort', { static: false }) curriculumSort: MatSort;

  constructor(public sharedService: SharedService,
    public authService: AuthService,
    private licenseChecklistService: LicenseChecklistService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<LicenseChecklistCurriculumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
       this.licenseCurriculums = data.licenseCurriculums;
       this.checklistId = data.checklistId;
    }

  ngOnInit(): void {
     this.updateTable();
  }

  updateTable(): void{
    this.curriculumSource.data = this.licenseCurriculums;
    this.curriculumSource.sort = this.curriculumSort;
    this.curriculumSource.paginator = this.curriculumPaginator;
  }

  showCurriculum(): void {
    this.addingCurriculum = true;
  }

  save(): void {
    let form = this.curriculumForm.value;
    this.curriculum.curriculumOne = form.curriculumOne;
    this.curriculum.curriculumOneA = form.curriculumOneA;
    this.curriculum.curriculumOneB = form.curriculumOneB;
    this.curriculum.curriculumOneC = form.curriculumOneC;
    this.curriculum.curriculumTwo = form.curriculumTwo;
    this.curriculum.curriculumTwoA = form.curriculumTwoA;
    this.curriculum.curriculumTwoB = form.curriculumTwoB;
    this.curriculum.curriculumTwoC = form.curriculumTwoC;
    this.curriculum.curriculumTwoD = form.curriculumTwoD;
    this.curriculum.curriculumTwoE = form.curriculumTwoE;
    this.curriculum.curriculumTwoF = form.curriculumTwoF;
    this.curriculum.curriculumThree = form.curriculumThree;
    this.curriculum.curriculumThreeA = form.curriculumThreeA;
    this.curriculum.curriculumThreeB = form.curriculumThreeB;
    this.curriculum.curriculumThreeC = form.curriculumThreeC;
    this.curriculum.curriculumThreeCA = form.curriculumThreeC;
    this.curriculum.curriculumThreeCB = form.curriculumThreeC;
    this.curriculum.curriculumThreeCC = form.curriculumThreeC;
    this.curriculum.curriculumThreeCD = form.curriculumThreeC;
    this.curriculum.curriculumThreeCE = form.curriculumThreeC;
    this.curriculum.curriculumThreeCF = form.curriculumThreeC;
    this.curriculum.curriculumThreeCG = form.curriculumThreeC;
    this.curriculum.curriculumThreeCH = form.curriculumThreeC;
    this.curriculum.curriculumThreeD = form.curriculumThreeD;
    this.curriculum.curriculumThreeE = form.curriculumThreeE;
    this.curriculum.curriculumThreeF = form.curriculumThreeF;
    this.curriculum.curriculumFour = form.curriculumFour;
    this.curriculum.curriculumFourA = form.curriculumFourA;
    this.curriculum.curriculumFourB = form.curriculumFourB;
    this.curriculum.curriculumFourC = form.curriculumFourC;
    this.curriculum.curriculumFourD = form.curriculumFourD;
    this.curriculum.curriculumFourE = form.curriculumFourE;
    this.curriculum.curriculumFourF = form.curriculumFourF;
    this.curriculum.curriculumFive = form.curriculumFive;
    this.curriculum.curriculumFiveA = form.curriculumFiveA;
    this.curriculum.curriculumFiveB = form.curriculumFiveB;
    this.curriculum.curriculumFiveC = form.curriculumFiveC;
    this.curriculum.curriculumFiveD = form.curriculumFiveD;
    this.curriculum.curriculumFiveE = form.curriculumFiveE;
    this.curriculum.curriculumFiveF = form.curriculumFiveF;
    this.curriculum.curriculumFiveG = form.curriculumFiveG;
    this.curriculum.curriculumFiveH = form.curriculumFiveH;
    this.curriculum.curriculumFiveI = form.curriculumFiveI;
    this.curriculum.curriculumSix = form.curriculumSix;
    this.curriculum.curriculumSixA = form.curriculumSixA;
    this.curriculum.curriculumSixB = form.curriculumSixB;
    this.curriculum.curriculumSixC = form.curriculumSixC;
    this.curriculum.curriculumSixD = form.curriculumSixD;
    this.curriculum.curriculumSixE = form.curriculumSixE;
    this.curriculum.curriculumSixF = form.curriculumSixF;
    this.curriculum.curriculumSixG = form.curriculumSixG;
    this.curriculum.curriculumSixH = form.curriculumSixH;
    this.curriculum.curriculumSixI = form.curriculumSixI;
    this.curriculum.curriculumSixJ = form.curriculumSixJ;
    this.curriculum.curriculumSixK = form.curriculumSixK;
    this.curriculum.curriculumSixL = form.curriculumSixL;
    this.curriculum.curriculumSeven = form.curriculumSeven;
    this.curriculum.curriculumSevenA = form.curriculumSevenA;
    this.curriculum.curriculumSevenAA = form.curriculumSevenAA;
    this.curriculum.curriculumSevenAB = form.curriculumSevenAB;
    this.curriculum.curriculumSevenAC = form.curriculumSevenAC;
    this.curriculum.curriculumSevenAD = form.curriculumSevenAD;
    this.curriculum.curriculumSevenB = form.curriculumSevenB;
    this.curriculum.curriculumSevenBA = form.curriculumSevenBA;
    this.curriculum.curriculumSevenBB = form.curriculumSevenBB;
    this.curriculum.curriculumSevenBC = form.curriculumSevenBC;
    this.curriculum.curriculumSevenC = form.curriculumSevenC;
    this.curriculum.curriculumSevenCA = form.curriculumSevenCA;
    this.curriculum.curriculumSevenCB = form.curriculumSevenCB;
    this.curriculum.curriculumSevenCC = form.curriculumSevenCC;
    this.curriculum.curriculumSevenCD = form.curriculumSevenCD;
    this.curriculum.curriculumSevenCE = form.curriculumSevenCE;
    this.curriculum.curriculumSevenD = form.curriculumSevenD;
    this.curriculum.curriculumSevenE = form.curriculumSevenE;
    this.curriculum.curriculumEight = form.curriculumEight;
    this.curriculum.curriculumEightA = form.curriculumEightA;
    this.curriculum.curriculumEightAA = form.curriculumEightAA;
    this.curriculum.curriculumEightAB = form.curriculumEightAB;
    this.curriculum.curriculumEightAC = form.curriculumEightAC;
    this.curriculum.curriculumEightB = form.curriculumEightB;
    this.curriculum.curriculumEightC = form.curriculumEightC;
    this.curriculum.curriculumEightD = form.curriculumEightD;
    this.curriculum.curriculumNine = form.curriculumNine;
    this.curriculum.curriculumNineA = form.curriculumNineA;
    this.curriculum.curriculumNineAA = form.curriculumNineAA;
    this.curriculum.curriculumNineAB = form.curriculumNineAB;
    this.curriculum.curriculumNineAC = form.curriculumNineAC;
    this.curriculum.curriculumNineAD = form.curriculumNineAD;
    this.curriculum.curriculumNineAE = form.curriculumNineAE;
    this.curriculum.curriculumNineB = form.curriculumNineB;
    this.curriculum.curriculumNineC = form.curriculumNineC;
    this.curriculum.curriculumNineD = form.curriculumNineD;
    this.curriculum.practicumPlanIncluded = form.practicumPlanIncluded;

   if(this.curriculumForm.valid)
      this.curriculum.completed = true;
  else
    this.curriculum.completed = false;

    if(!this.editing)
    {
      this.licenseChecklistService.addCurriculum(this.checklistId, this.curriculum).subscribe(
        response => this.licenseCurriculums.push(response),
        error => console.log('error', error),
        () => {
          this.updateTable();
          this.addingCurriculum = false;
          this.getNewCurriculum();
          this.updateCurriculumForm();
        },
      );
    }
    else {
      this.licenseChecklistService.editCurriculum(this.checklistId, this.curriculum).subscribe(
        response =>
        {
          let index = this.licenseCurriculums.findIndex(lc => lc.id == this.curriculum.id);
          this.licenseCurriculums[index] = response;
        },
        error => console.log('error', error),
        () => {
          this.updateTable();
          this.addingCurriculum = false;
          this.getNewCurriculum();
          this.updateCurriculumForm();
        },
      );
    }
  }


  deleteCurriculum(id: number): void {
    this.sharedService.openConfirm("Are you sure you want to delete this curriculum review?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseChecklistService.deleteCurriculum(id).subscribe(
            response => {
              if(response){
                this.licenseCurriculums = this.licenseCurriculums.filter(lc => lc.id != id);
                this.updateTable();
                this.toastr.success('Curriculum review deleted');
              }
              else
                this.toastr.error("Unable to delete curriculum review");
            },
            error => console.log('error', error),
          )
    }});
  }

  getNewCurriculum(): void {
    this.curriculum.id = 0;
    this.curriculum.completed = false;
    this.curriculum.submittedDate = '';
    this.curriculum.curriculumOne = false;
    this.curriculum.curriculumOneA = false;
    this.curriculum.curriculumOneB = false;
    this.curriculum.curriculumOneC = false;
    this.curriculum.curriculumTwo = false;
    this.curriculum.curriculumTwoA = false;
    this.curriculum.curriculumTwoB = false;
    this.curriculum.curriculumTwoC = false;
    this.curriculum.curriculumTwoD = false;
    this.curriculum.curriculumTwoE = false;
    this.curriculum.curriculumTwoF = false;
    this.curriculum.curriculumThree = false;
    this.curriculum.curriculumThreeA = false;
    this.curriculum.curriculumThreeB = false;
    this.curriculum.curriculumThreeC = false;
    this.curriculum.curriculumThreeCA = false;
    this.curriculum.curriculumThreeCB = false;
    this.curriculum.curriculumThreeCC = false;
    this.curriculum.curriculumThreeCD = false;
    this.curriculum.curriculumThreeCE = false;
    this.curriculum.curriculumThreeCF = false;
    this.curriculum.curriculumThreeCG = false;
    this.curriculum.curriculumThreeCH = false;
    this.curriculum.curriculumThreeD = false;
    this.curriculum.curriculumThreeE = false;
    this.curriculum.curriculumThreeF = false;
    this.curriculum.curriculumFour = false;
    this.curriculum.curriculumFourA = false;
    this.curriculum.curriculumFourB = false;
    this.curriculum.curriculumFourC = false;
    this.curriculum.curriculumFourD = false;
    this.curriculum.curriculumFourE = false;
    this.curriculum.curriculumFourF = false;
    this.curriculum.curriculumFive = false;
    this.curriculum.curriculumFiveA = false;
    this.curriculum.curriculumFiveB = false;
    this.curriculum.curriculumFiveC = false;
    this.curriculum.curriculumFiveD = false;
    this.curriculum.curriculumFiveE = false;
    this.curriculum.curriculumFiveF = false;
    this.curriculum.curriculumFiveG = false;
    this.curriculum.curriculumFiveH = false;
    this.curriculum.curriculumFiveI = false;
    this.curriculum.curriculumSix = false;
    this.curriculum.curriculumSixA = false;
    this.curriculum.curriculumSixB = false;
    this.curriculum.curriculumSixC = false;
    this.curriculum.curriculumSixD = false;
    this.curriculum.curriculumSixE = false;
    this.curriculum.curriculumSixF = false;
    this.curriculum.curriculumSixG = false;
    this.curriculum.curriculumSixH = false;
    this.curriculum.curriculumSixI = false;
    this.curriculum.curriculumSixJ = false;
    this.curriculum.curriculumSixK = false;
    this.curriculum.curriculumSixL = false;
    this.curriculum.curriculumSeven = false;
    this.curriculum.curriculumSevenA = false;
    this.curriculum.curriculumSevenAA = false;
    this.curriculum.curriculumSevenAB = false;
    this.curriculum.curriculumSevenAC = false;
    this.curriculum.curriculumSevenAD = false;
    this.curriculum.curriculumSevenB = false;
    this.curriculum.curriculumSevenBA = false;
    this.curriculum.curriculumSevenBB = false;
    this.curriculum.curriculumSevenBC = false;
    this.curriculum.curriculumSevenC = false;
    this.curriculum.curriculumSevenCA = false;
    this.curriculum.curriculumSevenCB = false;
    this.curriculum.curriculumSevenCC = false;
    this.curriculum.curriculumSevenCD = false;
    this.curriculum.curriculumSevenCE = false;
    this.curriculum.curriculumSevenD = false;
    this.curriculum.curriculumSevenE = false;
    this.curriculum.curriculumEight = false;
    this.curriculum.curriculumEightA = false;
    this.curriculum.curriculumEightAA = false;
    this.curriculum.curriculumEightAB = false;
    this.curriculum.curriculumEightAC = false;
    this.curriculum.curriculumEightB = false;
    this.curriculum.curriculumEightC = false;
    this.curriculum.curriculumEightD = false;
    this.curriculum.curriculumNine = false;
    this.curriculum.curriculumNineA = false;
    this.curriculum.curriculumNineAA = false;
    this.curriculum.curriculumNineAB = false;
    this.curriculum.curriculumNineAC = false;
    this.curriculum.curriculumNineAD = false;
    this.curriculum.curriculumNineAE = false;
    this.curriculum.curriculumNineB = false;
    this.curriculum.curriculumNineC = false;
    this.curriculum.curriculumNineD = false;
    this.curriculum.practicumPlanIncluded = false;
  }

  editLicense(id: number): void{
    this.editing = true;
    this.curriculum = this.licenseCurriculums.find(c => c.id == id);
    this.updateCurriculumForm();
    this.addingCurriculum = true;
  }

  updateCurriculumForm(): void {
    this.curriculumForm.patchValue({
      curriculumOne: this.curriculum.curriculumOne,
      curriculumOneA:  this.curriculum.curriculumOneA,
      curriculumOneB:  this.curriculum.curriculumOneB,
      curriculumOneC: this.curriculum.curriculumOneC,
      curriculumTwo:  this.curriculum.curriculumTwo,
      curriculumTwoA:  this.curriculum.curriculumTwoA,
      curriculumTwoB:  this.curriculum.curriculumTwoB,
      curriculumTwoC:  this.curriculum.curriculumTwoC,
      curriculumTwoD:  this.curriculum.curriculumTwoD,
      curriculumTwoE:  this.curriculum.curriculumTwoE,
      curriculumTwoF:  this.curriculum.curriculumTwoF,
      curriculumThree:  this.curriculum.curriculumThree,
      curriculumThreeA:  this.curriculum.curriculumThreeA,
      curriculumThreeB: this.curriculum.curriculumThreeB,
      curriculumThreeC:  this.curriculum.curriculumThreeC,
      curriculumThreeCA:  this.curriculum.curriculumThreeC,
      curriculumThreeCB:  this.curriculum.curriculumThreeC,
      curriculumThreeCC:  this.curriculum.curriculumThreeC,
      curriculumThreeCD:  this.curriculum.curriculumThreeC,
      curriculumThreeCE: this.curriculum.curriculumThreeC,
      curriculumThreeCF:  this.curriculum.curriculumThreeC,
      curriculumThreeCG:  this.curriculum.curriculumThreeC,
      curriculumThreeCH:  this.curriculum.curriculumThreeC,
      curriculumThreeD:  this.curriculum.curriculumThreeD,
      curriculumThreeE:  this.curriculum.curriculumThreeE,
      curriculumThreeF:  this.curriculum.curriculumThreeF,
      curriculumFour:  this.curriculum.curriculumFour,
      curriculumFourA:  this.curriculum.curriculumFourA,
      curriculumFourB:  this.curriculum.curriculumFourB,
      curriculumFourC:  this.curriculum.curriculumFourC,
      curriculumFourD: this.curriculum.curriculumFourD,
      curriculumFourE:  this.curriculum.curriculumFourE,
      curriculumFourF:  this.curriculum.curriculumFourF,
      curriculumFive:  this.curriculum.curriculumFive,
      curriculumFiveA:  this.curriculum.curriculumFiveA,
      curriculumFiveB: this.curriculum.curriculumFiveB,
      curriculumFiveC:  this.curriculum.curriculumFiveC,
      curriculumFiveD:  this.curriculum.curriculumFiveD,
      curriculumFiveE:  this.curriculum.curriculumFiveE,
      curriculumFiveF:  this.curriculum.curriculumFiveF,
      curriculumFiveG: this.curriculum.curriculumFiveG,
      curriculumFiveH:  this.curriculum.curriculumFiveH,
      curriculumFiveI:  this.curriculum.curriculumFiveI,
      curriculumSix:  this.curriculum.curriculumSix,
      curriculumSixA:  this.curriculum.curriculumSixA,
      curriculumSixB: this.curriculum.curriculumSixB,
      curriculumSixC:  this.curriculum.curriculumSixC,
      curriculumSixD:  this.curriculum.curriculumSixD,
      curriculumSixE:  this.curriculum.curriculumSixE,
      curriculumSixF:  this.curriculum.curriculumSixF,
      curriculumSixG:  this.curriculum.curriculumSixG,
      curriculumSixH:  this.curriculum.curriculumSixH,
      curriculumSixI:  this.curriculum.curriculumSixI,
      curriculumSixJ:  this.curriculum.curriculumSixJ,
      curriculumSixK: this.curriculum.curriculumSixK,
      curriculumSixL:  this.curriculum.curriculumSixL,
      curriculumSeven:  this.curriculum.curriculumSeven,
      curriculumSevenA:  this.curriculum.curriculumSevenA,
      curriculumSevenAA:  this.curriculum.curriculumSevenAA,
      curriculumSevenAB:  this.curriculum.curriculumSevenAB,
      curriculumSevenAC:  this.curriculum.curriculumSevenAC,
      curriculumSevenAD:  this.curriculum.curriculumSevenAD,
      curriculumSevenB:  this.curriculum.curriculumSevenB,
      curriculumSevenBA:  this.curriculum.curriculumSevenBA,
      curriculumSevenBB:  this.curriculum.curriculumSevenBB,
      curriculumSevenBC:  this.curriculum.curriculumSevenBC,
      curriculumSevenC:  this.curriculum.curriculumSevenC,
      curriculumSevenCA:  this.curriculum.curriculumSevenCA,
      curriculumSevenCB:  this.curriculum.curriculumSevenCB,
      curriculumSevenCC: this.curriculum.curriculumSevenCC,
      curriculumSevenCD:  this.curriculum.curriculumSevenCD,
      curriculumSevenCE: this.curriculum.curriculumSevenCE,
      curriculumSevenD:  this.curriculum.curriculumSevenD,
      curriculumSevenE:  this.curriculum.curriculumSevenE,
      curriculumEight:  this.curriculum.curriculumEight,
      curriculumEightA:  this.curriculum.curriculumEightA,
      curriculumEightAA:  this.curriculum.curriculumEightAA,
      curriculumEightAB:  this.curriculum.curriculumEightAB,
      curriculumEightAC:  this.curriculum.curriculumEightAC,
      curriculumEightB:  this.curriculum.curriculumEightB,
      curriculumEightC:  this.curriculum.curriculumEightC,
      curriculumEightD:  this.curriculum.curriculumEightD,
      curriculumNine:  this.curriculum.curriculumNine,
      curriculumNineA:  this.curriculum.curriculumNineA,
      curriculumNineAA:  this.curriculum.curriculumNineAA,
      curriculumNineAB:  this.curriculum.curriculumNineAB,
      curriculumNineAC:  this.curriculum.curriculumNineAC,
      curriculumNineAD: this.curriculum.curriculumNineAD,
      curriculumNineAE: this.curriculum.curriculumNineAE,
      curriculumNineB: this.curriculum.curriculumNineB,
      curriculumNineC: this.curriculum.curriculumNineC,
      curriculumNineD: this.curriculum.curriculumNineD,
      practicumPlanIncluded: this.curriculum.practicumPlanIncluded
    })
  }
  close(): void{
    this.dialogRef.close();
  }
}
