import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { IApplicantDetails } from 'app/models/licenses/applicant-search/applicant-details';
import { IApplicantSearch } from 'app/models/licenses/applicant-search/applicant-search';
import { IApplicantSearchResult } from 'app/models/licenses/applicant-search/applicant-search-result';
import { IEntityDetails } from 'app/models/licenses/applicant-search/entity-details';
import { IEntitySearchResult } from 'app/models/licenses/applicant-search/entity-search-result';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { ToastrService } from 'ngx-toastr';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  templateUrl: './applicant-search.component.html',
  styleUrls: ['./applicant-search.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class ApplicantSearchComponent implements OnInit {
  public applicants: IApplicantSearchResult[] = [];
  public entityApplicants: IEntitySearchResult[] = [];
  public applicantDetails: IApplicantDetails = {
    id: 0,
    applicantId: '',
    ssn: '',
    noSSN: false,
    ssnAttestation: false,
    legalFirstName: '',
    legalMiddleName: '',
    legalLastName: '',
    preferredFirstName: '',
    preferredMiddleName: '',
    preferredLastName: '',
    alternateNames: '',
    oregonResidency: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    phone: '',
    affiliatedLicenses: '',
    alternatePhone: '',
    email: '',
    language: '',
    previousLicense: false,
    dob: '',
    backgroundCheckDate: '',
    backgroundCheckPassed: false,
    backgroundCheckNotes: '',
    entityApplicants: [],
    facilitators: [],
    manufacturers: [],
    serviceCenters: [],
    laboratories: [],
    workerPermits: [],
    applicantDocuments: [],
    residentialHistory: [],
    heldLicense: null,
    licensesHeld: '',
    deniedLicense: null,
    subjectToDiscipline: null,
    suedForDamages: null,
    settledAllegations: null,
    allegedAbuse: null,
    substantiatedAllegation: null,
    backgroundChecks: []
  }
  public entityDetails: IEntityDetails = {
    id: 0,
    entityName: '',
    businessRegisteredIn: '',
    oregonResidency: false,
    headquartersState: '',
    headquartersCity: '',
    headquartersStreet: '',
    headquartersZip: '',
    headquartersCounty: '',
    physicalIsHeadquarters: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingIsHeadquarters: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    applicants: [],
    manufacturers: [],
    serviceCenters: [],
    laboratories: [],
    applicantDocuments: []
  }
  public firstName: string = '';
  public lastName: string = '';
  public dob: string ='';
  public applicantId: string = '';
  public ssn: string = '';
  public showSsn: boolean = false;
  public similarity: number = 90;
  public duplicate: boolean = false;
  public archived: boolean = false;
  public archivedEntity: boolean = false;
  public entityId: string = '';
  public city: string = '';
  public entityName: string = '';
  public entitySimilarity: number = 90;
  public documentTypeReference: { [id: number]: string } = {};
  public personalIdentificationType: number = 0;
  public applicantInfoType: number = 0;
  public physicalType: number = 0;
  public otherType: number = 0;
  public personalIdentification: string = 'Personal Identification';
  public applicantInfo: string = 'Applicant Information';
  public physicalApplication: string = 'Physical Application';
  public otherApplication: string = 'Other';
  public deniedLicense: string = 'Previous Denied License Information';
  public subjectToDiscipline: string = 'Previous License Discipline or Revocation Information';
  public suedForDamages: string = 'Previously Sued Information';
  public settledAllegations: string = 'Previous License Allegations or Claims';
  public allegedAbuse: string = 'Previous Alleged Abuse of Child or Adult';
  public deniedLicenseType: number = 0;
  public subjectToDisciplineType: number = 0;
  public suedForDamagesType: number = 0;
  public settledAllegationsType: number = 0;
  public allegedAbuseType: number = 0;


  public dataColumns: string[] = ['firstName', 'lastName', 'middleName', 'dob', 'ssn', 'applicantId', 'similarity'];
  public dataSource = new MatTableDataSource<IApplicantSearchResult>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  public entityColumns: string[] = ['entityName', 'pointOfContact', 'city', 'state', 'entityId', 'similarity'];
  public entitySource = new MatTableDataSource<IEntitySearchResult>(this.entityApplicants);
  @ViewChild('entityPaginator', {static: false}) entityPaginator: MatPaginator;
  @ViewChild('entitySort', {static: false}) entitySort: MatSort;

  constructor(public sharedService: SharedService,
              public applicantService: ApplicantService,
              public router: Router,
              public toastr: ToastrService,
              public authService: AuthService,) { }

  ngOnInit(): void {
    this.getDocumentTypes();
  }

  searchApplicants(): void {
    let search: IApplicantSearch = {
      applicantId: this.applicantId,
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      ssn: this.ssn,
      entityName: '',
      city: '',
      similarity: this.similarity,
      duplicate: this.duplicate,
      archived: this.archived
    }

    this.applicantService.searchApplicants(search).subscribe(
      response => this.applicants = response,
      error => console.log('error', error),
      () => this.updateApplicantTable()
    );
  }

  searchEntityApplicants(): void {
    let search: IApplicantSearch = {
      applicantId: this.entityId,
      firstName: '',
      lastName: '',
      dob: '',
      ssn: '',
      entityName: this.entityName,
      city: this.city,
      similarity: this.entitySimilarity,
      duplicate: false,
      archived: this.archivedEntity,
    }

    this.applicantService.searchEntityApplicants(search).subscribe(
      response => this.entityApplicants = response,
      error => console.log('error', error),
      () => this.updateEntityTable()
    );
  }

  getDocumentTypes(): void {
    this.applicantService.getDocumentTypes().subscribe(
      response => {
        this.personalIdentificationType = response.find(dt => dt.type === this.personalIdentification).id;
        this.physicalType = response.find(dt => dt.type === this.physicalApplication).id;
        this.otherType = response.find(dt => dt.type === this.otherApplication).id;
        this.applicantInfoType = response.find(dt => dt.type === this.applicantInfo).id;
        this.deniedLicenseType = response.find(dt => dt.type === this.deniedLicense).id;
        this.subjectToDisciplineType = response.find(dt => dt.type === this.subjectToDiscipline).id;
        this.suedForDamagesType = response.find(dt => dt.type === this.suedForDamages).id;
        this.settledAllegationsType = response.find(dt => dt.type === this.settledAllegations).id;
        this.allegedAbuseType = response.find(dt => dt.type === this.allegedAbuse).id;

        this.documentTypeReference[this.physicalType] = this.physicalApplication;
        this.documentTypeReference[this.otherType] = this.otherApplication;
        this.documentTypeReference[this.personalIdentificationType] = this.personalIdentification;
        this.documentTypeReference[this.applicantInfoType] = this.applicantInfo;
        this.documentTypeReference[this.deniedLicenseType] = this.deniedLicense;
        this.documentTypeReference[this.subjectToDisciplineType] = this.subjectToDiscipline;
        this.documentTypeReference[this.suedForDamagesType] = this.suedForDamages;
        this.documentTypeReference[this.settledAllegationsType] = this.settledAllegations;
        this.documentTypeReference[this.allegedAbuseType] = this.allegedAbuse;
      },
      error => console.log('error', error)
    );
  }

  toggleSsn(): void {
    this.showSsn = !this.showSsn;
  }

  filterTable(table: string, event: Event): void{
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    if(table === 'individual'){
      this.dataSource.filter = filter;
    }
    else{
      this.entitySource.filter = filter;
    }
  }

  updateApplicantTable(): void {
    this.dataSource.data = this.applicants;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateEntityTable(): void {
    this.entitySource.data = this.entityApplicants;
    this.entitySource.paginator = this.entityPaginator;
    this.entitySource.sort = this.entitySort;
  }

  getApplicantDetails(id: number): void {
    this.applicantService.getApplicantDetails(id).subscribe(
      response => this.applicantDetails = response,
      error => console.log('error', error)
    );
  }

  getEntityDetails(id: number): void {
    this.applicantService.getEntityApplicantDetails(id).subscribe(
      response => this.entityDetails = response,
      error => console.log('error', error)
    );
  }

  viewRelatedEntity(id: number): void {
    this.getEntityDetails(id);
    setTimeout(() => {
      this.closeApplicantDetails();
    }, 50);
  }

  viewRelatedIndividual(id: number): void {
    this.getApplicantDetails(id);
    setTimeout(() => {
      this.closeEntityDetails();
    }, 50);
  }

  closeApplicantDetails(): void {
    let emptyDetails: IApplicantDetails = {
      id: 0,
      applicantId: '',
      ssn: '',
      noSSN: false,
      ssnAttestation: false,
      legalFirstName: '',
      legalMiddleName: '',
      legalLastName: '',
      preferredFirstName: '',
      preferredMiddleName: '',
      preferredLastName: '',
      alternateNames: '',
      oregonResidency: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      phone: '',
      affiliatedLicenses: '',
      alternatePhone: '',
      email: '',
      language: '',
      previousLicense: false,
      dob: '',
      backgroundCheckDate: '',
      backgroundCheckPassed: false,
      backgroundCheckNotes: '',
      entityApplicants: [],
      facilitators: [],
      manufacturers: [],
      serviceCenters: [],
      laboratories: [],
      workerPermits: [],
      applicantDocuments: [],
      residentialHistory: [],
      heldLicense: null,
      licensesHeld: '',
      deniedLicense: null,
      subjectToDiscipline: null,
      suedForDamages: null,
      settledAllegations: null,
      allegedAbuse: null,
      substantiatedAllegation: null,
      backgroundChecks: []
    };
    this.applicantDetails = emptyDetails;
    setTimeout(() => {
      this.updateApplicantTable();
      this.updateEntityTable();
    }, 250);
  }

  closeEntityDetails(): void {
    let emptyDetails: IEntityDetails = {
      id: 0,
      entityName: '',
      businessRegisteredIn: '',
      oregonResidency: false,
      headquartersState: '',
      headquartersCity: '',
      headquartersStreet: '',
      headquartersZip: '',
      headquartersCounty: '',
      physicalIsHeadquarters: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingIsHeadquarters: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      primaryContactName: '',
      primaryContactNumber: '',
      primaryContactEmail: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      applicants: [],
      manufacturers: [],
      serviceCenters: [],
      laboratories: [],
      applicantDocuments: []
    };
    this.entityDetails = emptyDetails;
    setTimeout(() => {
      this.updateApplicantTable();
      this.updateEntityTable();
    }, 250);
  }

  downloadDocument(fileId: number, fileName: string): void {
    if(this.applicantDetails.applicantDocuments.find(d => d.id == fileId).adminOnly)
    {
      if(this.authService.isAdmin)
        this.applicantService.downloadFile(fileId).subscribe(
          (response) => this.saveFile(fileName, response),
          (error) => console.log("error", error)
        );
      else
        this.toastr.error("You do not have permission to download this file");
    }
    else
      this.applicantService.downloadFile(fileId).subscribe(
        (response) => this.saveFile(fileName, response),
        (error) => console.log("error", error)
      );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }
}
