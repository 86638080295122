import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { CommonModule } from '@angular/common';
import { ComplianceComponent,
         AddRuleReferenceDialogComponent,
         ResolveRuleDialogComponent,
         AddComplaintReferenceDialogComponent,
         AddNoteReferenceDialogComponent,
         AddComplianceActionDialogComponent,
         AddRelatedRuleDialogComponent,
         AddAssociatedLicenseDialogComponent} from './compliance.component';
import { AddDocumentRequestDialogComponent, AddInterviewDialogComponent, AddInvestigationPhotoDialogComponent, AddInvestigationWitnessDialogComponent, InvestigationComponent } from '../investigation/investigation.component';
import { AddComplianceRuleDialogComponent, CompliancePlanComponent } from '../compliance-plan/compliance-plan.component';
import { PrintCompliancePlanComponent } from '../compliance-plan/print-compliance-plan/print-compliance-plan.component';
import { DialogExportFollowupComponent } from '../site-inspection/dialog-export-followup/dialog-export-followup.component';
import { DialogExportSiteInspectionComponent } from '../site-inspection/dialog-export-site-inspection/dialog-export-site-inspection.component';



const COMPONENTS = [
  ComplianceComponent,
  AddRuleReferenceDialogComponent,
  ResolveRuleDialogComponent,
  AddComplaintReferenceDialogComponent,
  AddNoteReferenceDialogComponent,
  AddComplianceActionDialogComponent,
  AddRelatedRuleDialogComponent,
  AddAssociatedLicenseDialogComponent,
  InvestigationComponent,
  AddInterviewDialogComponent,
  AddInterviewDialogComponent,
  AddDocumentRequestDialogComponent,
  AddInvestigationPhotoDialogComponent,
  AddInvestigationWitnessDialogComponent,
  CompliancePlanComponent,
  PrintCompliancePlanComponent,
  AddComplianceRuleDialogComponent,
  DialogExportFollowupComponent,
  DialogExportSiteInspectionComponent

]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [...COMPONENTS]
})
export class ComplianceModule { }
