import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/services/core/shared.service';
import { IServiceCenterLicense } from 'app/models/licenses/service-center-license';

@Component({
  selector: 'app-print-service-center',
  templateUrl: './print-service-centers.component.html',
  styleUrls: ['./print-service-centers.component.scss']
})
export class PrintServiceCenterComponent implements OnInit {

  constructor(public sharedService: SharedService,
    public dialogRef: MatDialogRef<PrintServiceCenterComponent>,
    @Inject(MAT_DIALOG_DATA) public license: IServiceCenterLicense) { }

  ngOnInit(): void {

  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
