<mat-card [style.background]="cardColor" fxLayoutGap="12px" *ngIf="licenseType != sharedService.workerPermit">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">Added Worker Permits</span>
        <span fxFlex></span>
        <button mat-button type="button" color="primary" *ngIf="isAdmin" (click)="addWorkerPermit()">Add PTS Permits</button>
        <button mat-button type="button" color="primary" *ngIf="isAdmin && licenseType == sharedService.serviceCenter" (click)="addSB303WorkerPermit()">Add 303 Permits</button>
      </div>
      <mat-card>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="permits.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>TLC-PTS (Product Tracking) Worker Permits</strong></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Permit Holder Name</span>
          </div>
          <div fxLayout="row" *ngFor="let permit of permits" style="font-size: 14px">
            <span fxFlex="15">{{permit.licenseId}}</span>
            <span fxFlex="15">{{permit.applicants[0].legalFirstName}} {{permit.applicants[0].legalLastName}}</span>
            <button fxFlex="15" mat-button type="button" color="warn" *ngIf="isAdmin" (click)="removeWorkerPermit(permit.licenseId)">Remove</button>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="sb303Permits.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>303 Data Reporting Portal Worker Permits</strong></span>
            <span fxFlex></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Permit Holder Name</span>
          </div>
          <div fxLayout="row" *ngFor="let permit of sb303Permits" style="font-size: 14px">
            <span fxFlex="15">{{permit.licenseId}}</span>
            <span fxFlex="15">{{permit.applicants[0].legalFirstName}} {{permit.applicants[0].legalLastName}}</span>
            <button fxFlex="15" mat-button type="button" color="warn" *ngIf="isAdmin" (click)="remove303WorkerPermit(permit.licenseId)">Remove</button>
          </div>
        </div>
        <div fxLayout="row" *ngIf="permits.length <= 0 && sb303Permits.length <= 0" fxLayoutGap="10px">
          <span>No Added Permits</span>
        </div>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

<mat-card [style.background]="cardColor" *ngIf="licenseType == sharedService.workerPermit">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">Access to TLC-PTS (Product Tracking)  or 303 Reporting Portal</span>
      </div>
      <mat-card>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="manufacturerLicenses.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>Manufacturer TLC-PTS (Product Tracking) Licenses</strong></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Entity Name</span>
          </div>
          <div fxLayout="row" *ngFor="let license of manufacturerLicenses" style="font-size: 14px">
            <span fxFlex="15">{{license.licenseId}}</span>
            <span fxFlex="15">{{license.entityName}}</span>
          </div>
        </div>
        <br>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="testingLabLicenses.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>Testing Lab TLC-PTS (Product Tracking) Licenses</strong></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Entity Name</span>
          </div>
          <div fxLayout="row" *ngFor="let license of testingLabLicenses" style="font-size: 14px">
            <span fxFlex="15">{{license.licenseId}}</span>
            <span fxFlex="15">{{license.entityName}}</span>
          </div>
        </div>
        <br>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="serviceCenterLicenses.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>Service Center TLC-PTS (Product Tracking) Licenses</strong></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Entity Name</span>
          </div>
          <div fxLayout="row" *ngFor="let license of serviceCenterLicenses" style="font-size: 14px">
            <span fxFlex="15">{{license.licenseId}}</span>
            <span fxFlex="15">{{license.entityName}}</span>
          </div>
        </div>
        <br>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="sB303ServiceCenters.length > 0">
          <div fxLayout="row" style="font-size: 18px">
            <span><strong>Service Center 303 Data Reporting Portal Licenses</strong></span>
          </div>
          <div fxLayout="row" style="font-size: 18px">
            <span fxFlex="15">License Id</span>
            <span fxFlex="15">Entity Name</span>
          </div>
          <div fxLayout="row" *ngFor="let license of sB303ServiceCenters" style="font-size: 14px">
            <span fxFlex="15">{{license.licenseId}}</span>
            <span fxFlex="15">{{license.entityName}}</span>
          </div>
        </div>
        <span *ngIf="sB303ServiceCenters.length <= 0 && serviceCenterLicenses.length <= 0 && testingLabLicenses.length <= 0 && manufacturerLicenses.length <= 0">No Associated Licenses</span>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
