<mat-dialog-content>
  <div fxLayout="column">
    <span class="f-s-18">
      Provide a reason for removing the notice
    </span>
    <mat-form-field>
      <textarea matInput rows="5" placeholder="Notice Removal Reason" [(ngModel)]="reason" required=""></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="8px">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="reason === null">Archive</button>
    <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
  </div>
</mat-dialog-content>
