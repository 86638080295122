import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ITask } from "app/models/tasks/task";

@Component({
  selector: 'task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss']
})
export class TaskHistoryComponent implements OnInit {
  public taskColumns: string[] = ['task', 'teamMember', 'dateModified','reason'];
  public taskSource = new MatTableDataSource<ITask>(this.tasks);
  @ViewChild('taskPaginator', {static: false}) taskPaginator: MatPaginator;
  @ViewChild('taskSort', {static: false}) taskSort: MatSort;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public tasks: ITask[]) { }

  ngOnInit() {
    setTimeout(() => {
      this.taskSource.data = this.tasks;
      this.taskSource.sort = this.taskSort;
      this.taskSource.paginator = this.taskPaginator;
      this.taskSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'dateLastUpdated': return new Date(item.dateLastUpdated);
          default: return item[property];
      };
    }
    }, 200);

  }
  cancel(): void {
    this.dialogRef.close('cancel');
  }
   customSort(a: any, b: any): number {
    // Convert the date strings to Date objects
    const dateA = new Date(a.dateLastUpdated);
    const dateB = new Date(b.dateLastUpdated);

    // Compare the dates and return the sort order
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  }
}
