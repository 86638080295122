<section>
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>Add Complaint</span>
    <button mat-raised-button type="button" color="primary" (click)="addComplaints()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <div fxLayout="column">
    <mat-table [dataSource]="complaintRefSource" matSort #complaintRefSort="matSort" aria-describedby="Complaints" class="mat-elevation-z8">
      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Complaint Date</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Complaint Date</span>
          {{ complaint.dateCreated | date }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Status</span>
          {{ complaint.status }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="complaintText">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Complaint</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Complaint</span>
          <textarea matInput rows="1" [value]="complaint.complaintText" disabled></textarea>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <button mat-button type="button" color="primary" (click)="addComplaint(complaint)">select</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="complaintRefColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: complaintRefColumns">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" #complaintRefPaginator="matPaginator">
    </mat-paginator>

    <div *ngIf="selectedComplaints.length > 0" fxLayout="column">
      <span class="f-s-16">Selected Complaints</span>
      <mat-list>
        <mat-list-item *ngFor="let complaint of selectedComplaints">
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <textarea matInput rows="1" [value]="complaint.complaintText" disabled></textarea>
            <button type="button" mat-button color="warn" (click)="deleteComplaint(complaint)">Remove</button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</mat-dialog-content>
