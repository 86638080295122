



<div *ngIf="sharedService.loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

<div *ngIf="!sharedService.loading">
  <mat-card>
    <strong> <mat-icon class="icon-20">bar_chart</mat-icon> Reports </strong>
    <mat-list role="list">
      <mat-list-item role="listitem"><a href="{{environment.reports.powerBi}}" target="_blank">PowerBi Reports</a></mat-list-item>
    </mat-list>
  </mat-card>
</div>
