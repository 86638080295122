import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './assigned-testing-labs.component.html',
  styleUrls: ['./assigned-testing-labs.component.scss']
})
export class AssignedTestingLabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
