import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "@env/environment";
import { MsalGuard } from "@azure/msal-angular";
import { DefaultLayoutComponent } from "./layout/default-layout/default-layout.component";
import { HomeComponent } from "./components/home/home.component";
import { UserAdministrationComponent } from "./components/admin/user-management/user-administration.component";
import { AuthGuard } from "./auth/auth.guard";
import { InternalAppMessagingComponent } from "./components/internal-app-messaging/internal-app-messaging.component";
import { ManageComplianceComponent } from "./components/manage-compliance/manage-compliance.component";
import { ExamComponent } from "./components/facilitator/exam/exam.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { LicenseDashboardComponent } from "./components/license-dashboard/license-dashboard.component";
import { ActiveApplicationTrackingComponent } from "./components/active-application-tracking/active-application-tracking.component";
import { ComplianceTrackingComponent } from "./components/compliance-tracking/compliance-tracking.component";
import { LicensingHomeComponent } from "./components/licensing-home/licensing-home.component";
import { SB303Component } from "./components/303/303.component";

const routes: Routes = [
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: environment.authEntireSite ? [MsalGuard] : [],
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full"
      },
      {
        path: "user-management",
        component: UserAdministrationComponent,
        canActivate: [ MsalGuard, AuthGuard ],
        data: { roles: ['Admin']}
      },
      {
        path: "exam-questions",
        component: ExamComponent,
        canActivate: [ MsalGuard, AuthGuard ],
        data: { roles: ['Facilitator Admin']}
      },
      {
        path: "home",
        component: HomeComponent
      },
      { path: 'licensing-home',
      component: LicensingHomeComponent,
      canActivate: [ MsalGuard]
      },
      { path: 'license-dashboard',
      component: LicenseDashboardComponent,
      canActivate: [ MsalGuard]
      },
      {
        path: '303-reporting',
        component: SB303Component,
        canActivate: [ MsalGuard],
        loadChildren: () => import('./components/303/303.module').then(m => m.SB303Module)
        },
      {
      path: 'active-application-tracking',
      component: ActiveApplicationTrackingComponent,
      canActivate: [ MsalGuard]
      },
      { path: 'compliance-tracking',
      component: ComplianceTrackingComponent,
      canActivate: [ MsalGuard]
      },
      {
        path: 'rules',
        canActivate: [ MsalGuard, AuthGuard ],
        component: ManageComplianceComponent,
        data: { roles: ['Admin'] },
        loadChildren: () => import('./components/manage-compliance/manage-compliance.module').then(m => m.ManageComplianceModule)
      },
      {
        path: 'training',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/training/training-program.module').then(m => m.TrainingProgramModule)
      },
      {
        path: 'facilitator',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/facilitator/facilitator.module').then(m => m.FacilitatorModule)
      },
      {
        path: 'manufacturer',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/manufacturer/manufacturer.module').then(m => m.ManufacturerModule)
      },
      {
        path: 'service-center',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/service-center/service-center.module').then(m => m.ServiceCenterModule)
      },
      {
        path: 'testing-lab',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/testing-lab/testing-lab.module').then(m => m.TestingLabModule)
      },
      {
        path: 'worker-permit',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/worker-permit/worker-permit.module').then(m => m.WorkerPermitModule)
      },
      {
        path: 'applicant',
        canActivate: [ MsalGuard ],
        loadChildren: () => import('./components/applicant/applicant.module').then(m => m.ApplicantModule)
      },
      {
        path: 'messages',
        canActivate: [ MsalGuard ],
        component: InternalAppMessagingComponent,
        loadChildren: () => import('./components/internal-app-messaging/internal-app-messaging.module').then(m => m.InternalAppMessagingModule)
      },
      {
        path: 'reports',
        canActivate: [ MsalGuard ],
        component: ReportsComponent,
        loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: "sessions",
        loadChildren: () => import("./components/sessions/sessions.module").then((m) => m.SessionsModule),
      },
      {
        path: 'product-tracking',
        canActivate: [MsalGuard],
        loadChildren: () => import('./components/product-tracking/product-tracking.module').then(m => m.ProductTrackingModule)
      }
    ],
  },
  { path: "**", redirectTo: "home" },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class RoutesRoutingModule {}
