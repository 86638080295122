import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './assigned-service-centers.component.html'
})
export class AssignedServiceCentersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
