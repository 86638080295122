import { trigger, transition, style, animate } from "@angular/animations";
import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Validators, UntypedFormControl, UntypedFormGroup, FormGroup, FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AuthService } from "app/auth/auth.service";
import { ICompliance } from "app/models/compliance/compliance";
import { IDocument } from "app/models/documents/document";
import { IDocumentRequest } from "app/models/investigations/documentRequest";
import { IInterview } from "app/models/investigations/interview";
import { IInvestigation } from "app/models/investigations/investigation";
import { ISiteInspection } from "app/models/site-inspection/site-inspection";
import { IStaff } from "app/models/user-info/staff";
import { SharedService } from "app/services/core/shared.service";
import { InvestigationService } from "app/services/investigation/investigation.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { BlobServiceClient, AnonymousCredential, ContainerClient, BlockBlobClient } from "@azure/storage-blob";
import { IInterviewDocument } from "app/models/documents/interviewDocument";
import { IInvestigationPhoto } from "app/models/investigations/investigation-photo";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { IInvestigationWitness } from "app/models/investigations/investigation-witness";
export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-investigation',
  templateUrl: './investigation.component.html',
  styleUrls: ['./investigation.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class InvestigationComponent implements OnInit {
  @Input() caseId: number = 0;
  @Input() compliance: ICompliance;
  @Input() editing: boolean = true;
  @Input() staff: IStaff[] = [];
  @Input() entityType: string = '';
  @Input() staffReference: {[id: string]: string} = {};
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public siteInspectionForm = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    status: new UntypedFormControl('Compliance'),
    summary: new UntypedFormControl(''),
    visitDate: new UntypedFormControl('')
  });

  public addingSiteInspection: boolean = false;
  public editingInterview: boolean = false;
  public today: Date = new Date(Date.now());
  public routeSubscription: Subscription;
  public investigations: IInvestigation[] = [];
  public investigation: IInvestigation = {
    id: 0,
    investigationId: "",
    status: "",
    startedDate: "",
    completedDate: "",
    incidentDate: "",
    summary: "",
    assignedTo: "",
    interviews: [],
    documentRequests: [],
    siteInspections: [],
    investigationPhotos: [],
    investigationWitnesses: []
  };
  public interview: IInterview = {
    id: 0,
    notes: "",
    auditInfo: "",
    InvestigationId: 0,
    interviewDocuments: []
  };
  public siteInspection: ISiteInspection ={
    id: 0,
    caseId: "",
    type: "",
    status: "",
    assignedTo: "",
    manager: "",
    locked: false,
    summary: "",
    visitDate: "",
    followup: undefined,
    siteInspectionRules: []
  };
  public documentRequest: IDocumentRequest ={
    id: 0,
    notes: "",
    auditInfo: "",
    InvestigationId: 0,
    documentRequestDocuments: [],
    documentName: "",
    deadlineDate: "",
    receivedDate: "",
    sentBy: "",
    recordsWitness: null
  };
  public investigatonPhoto: IInvestigationPhoto = {
    id: 0,
    photographer: "",
    photoTakenDate: "",
    photoLocation: "",
    description: "",
    significance: "",
    InvestigationId: 0,
    investigationPhotoDocuments: []
  };
  public investigationWitness: IInvestigationWitness = {
    id: 0,
    name: "",
    phoneNumber: "",
    email: "",
    testify: null,
    interviewDate: "",
    InvestigationId: 0
  };

  public viewingInvestigation: boolean = false;
  public investigationColumns: string[] = ['investigationId', 'status', 'startedDate', 'completedDate', 'assignedTo', 'actions'];
  public investigationSource = new MatTableDataSource<IInvestigation>(this.investigations);
  @ViewChild('investigationPaginator', {static: false}) investigationPaginator: MatPaginator;
  @ViewChild('investigationSort', {static: false}) investigationSort: MatSort;

  public interviewColumns: string[] = ['notes', 'interviewDocuments', 'auditInfo', 'actions'];
  public interviewSource = new MatTableDataSource<IInterview>(this.investigation.interviews);
  @ViewChild('interviewPaginator', {static: false}) interviewPaginator: MatPaginator;
  @ViewChild('interviewSort', {static: false}) interviewSort: MatSort;

  public documentRequestColumns: string[] = ['documentName', 'auditInfo', 'notes', 'documentRequestDocuments', 'actions'];
  public documentRequestSource = new MatTableDataSource<IDocumentRequest>(this.investigation.documentRequests);
  @ViewChild('documentRequestPaginator', {static: false}) documentRequestPaginator: MatPaginator;
  @ViewChild('documentRequestSort', {static: false}) documentRequestSort: MatSort;

  public photoColumns: string[] = ['description', 'photographer', 'photoTakenDate', 'photoLocation',  'actions'];
  public photoSource = new MatTableDataSource<IInvestigationPhoto>(this.investigation.investigationPhotos);
  @ViewChild('photoPaginator', {static: false}) photoPaginator: MatPaginator;
  @ViewChild('photoSort', {static: false}) photoSort: MatSort;

  public witnessColumns: string[] = ['name', 'interviewDate',  'actions'];
  public witnessSource = new MatTableDataSource<IInvestigationWitness>(this.investigation.investigationWitnesses);
  @ViewChild('witnessPaginator', {static: false}) witnessPaginator: MatPaginator;
  @ViewChild('witnessSort', {static: false}) witnessSort: MatSort;

  public siteInspectionColumns: string[] = ['caseId', 'status', 'type', 'actions'];
  public siteInspectionSource = new MatTableDataSource<ISiteInspection>(this.investigation.siteInspections);
  @ViewChild('siteInspectionPaginator', {static: false}) siteInspectionPaginator: MatPaginator;
  @ViewChild('siteInspectionSort', {static: false}) siteInspectionSort: MatSort;

  public investigationForm = new UntypedFormGroup({
    status: new UntypedFormControl(""),
    assignedTo: new UntypedFormControl(""),
    startedDate: new UntypedFormControl(""),
    completedDate: new UntypedFormControl(""),
    incidentDate: new UntypedFormControl(""),
    summary: new UntypedFormControl("")
  });


  constructor(public sharedService: SharedService,
    private router: Router,
    public toastr: ToastrService,
    public authService: AuthService,
    public investigationService: InvestigationService,
    private dialog: MatDialog) { }

    ngOnInit(): void {
      this.investigationService.getInvestigations(this.compliance.id).subscribe(
        response => this.investigations = response,
        error => console.log('error', error),
          () => {
            this.updateInvestigationTable();
          }
      )
    }

    ngOnChanges(changes: SimpleChanges): void {
      const change = changes['caseId'];
      if (change != null && change.currentValue !== change.previousValue && !change.firstChange) {
        this.investigationService.getInvestigations(this.compliance.id).subscribe(
          response => this.investigations = response,
          error => console.log('error', error),
            () => {
              this.updateInvestigationTable();
            }
        )
      }
    }

    public updateInvestigationTable(): void {
      this.investigationSource.data = this.investigations;
      this.investigationSource.paginator = this.investigationPaginator;
      this.investigationSource.sort = this.investigationSort;
    }
    public updateInterviewTable(): void {
      this.interviewSource.data = this.investigation.interviews;
      this.interviewSource.sort = this.interviewSort;
      setTimeout(() => {
        this.interviewSource.paginator = this.interviewPaginator;
      }, 100);
    }

    public updateSiteInspectionTable(): void {
      this.siteInspectionSource.data = this.investigation.siteInspections;
      this.siteInspectionSource.sort = this.siteInspectionSort;
      setTimeout(() => {
        this.siteInspectionSource.paginator = this.siteInspectionPaginator;
      }, 100);
    }

    public updateDocumentRequestTable(): void {
      this.documentRequestSource.data = this.investigation.documentRequests;
      this.documentRequestSource.sort = this.documentRequestSort;
      setTimeout(() => {
        this.documentRequestSource.paginator = this.documentRequestPaginator;
      }, 100);
    }

    public updateInvestigationPhotoTable(): void {
      this.photoSource.data = this.investigation.investigationPhotos;
      this.photoSource.sort = this.photoSort;
      setTimeout(() => {
        this.photoSource.paginator = this.photoPaginator;
      }, 100);
    }

    public updateInvestigationWitnessTable(): void {
      this.witnessSource.data = this.investigation.investigationWitnesses;
      this.witnessSource.sort = this.witnessSort;
      setTimeout(() => {
        this.witnessSource.paginator = this.witnessPaginator;
      }, 100);
    }

    public filterTable(event: Event, type: string): void {
      let value = (event.target as HTMLInputElement).value;
      let filter = value.trim().toLocaleLowerCase();
     if(type == 'investigations')
      this.investigationSource.filter = filter;
    if(type == 'interviews')
      this.interviewSource.filter = filter;
    if(type == 'DocumentRequests')
      this.documentRequestSource.filter = filter;
    if(type == 'Photos')
      this.photoSource.filter = filter;
    if(type == 'Witnesses')
      this.witnessSource.filter = filter;
    if(type == 'SiteInspections')
      this.siteInspectionSource.filter = filter;
    }

    public editInvestigation(item: IInvestigation): void{
      this.investigation = item;
      this.updateInterviewTable();
      this.updateDocumentRequestTable();
      this.updateInvestigationPhotoTable();
      this.updateInvestigationWitnessTable();
      this.updateSiteInspectionTable();
      this.updateInvestigationForm();
      this.viewingInvestigation = true;
      this.editing = false;
      this.notifyParent.emit(true);
    }

    addInvestigation(): void {
      this.sharedService.openConfirm("Add Investigation?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.investigationService.createInvestigation(this.compliance).subscribe(
                response => this.investigation = response,
                error => console.log('error', error),
                () => {
                  this.investigations.push(this.investigation);
                  this.updateInterviewTable();
                  this.updateInvestigationForm();
                  this.updateInvestigationTable();
                  this.updateSiteInspectionTable();
                  this.updateDocumentRequestTable();
                  this.updateInvestigationPhotoTable();
                  this.updateInvestigationWitnessTable();
                  this.notifyParent.emit(true);
                  this.viewingInvestigation = true;
                  this.editing = true;
                }
              );
          }
        }
      );
    }

    deleteInvestigation(id: number): void{
      this.sharedService.openConfirm("Delete Investigation?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.investigationService.deleteInvestigation(id).subscribe(
              () => {
                this.investigations = this.investigations.filter(item => item.id !== id);
                this.updateInvestigationTable();
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }
    edit(): void {
      this.editing = !this.editing;
    }

    viewComplianceCase(): void {
      let index = this.investigations.indexOf(this.investigation);
      this.investigations[index] = this.investigation
      this.updateInvestigationTable();
      this.viewingInvestigation = false;
      this.editing = !this.viewingInvestigation;
      this.investigation = this.getEmptyInvestigation();
      this.notifyParent.emit(false);
    }

    public updateInvestigation(close: boolean): void {
      let form = this.investigationForm.value;
      this.investigation.status = form.status;
      this.investigation.assignedTo = form.assignedTo;
      this.investigation.startedDate = form.startedDate;
      this.investigation.completedDate = form.completedDate;
      this.investigation.incidentDate = form.incidentDate;
      this.investigation.summary = form.summary;
      this.investigationService.updateInvestigation(this.investigation).subscribe(
        response => this.investigation = response,
        error => console.log('error', error),
        () => {
          let index = this.investigations.indexOf(this.investigation);
          this.investigations[index] = this.investigation
          this.updateInvestigationTable();
          if(close){
            this.compliance.investigations
            this.viewComplianceCase();
          }
        }
      );
    }

    private updateInvestigationForm(): void {
      this.investigationForm.patchValue({
        status: this.investigation.status,
        assignedTo: this.investigation.assignedTo,
        summary: this.investigation.summary
      });

      if (this.investigation.startedDate != null && this.investigation.startedDate.length > 0)
        this.investigationForm.get('startedDate').patchValue(new Date(this.investigation.startedDate));

      if (this.investigation.completedDate != null && this.investigation.completedDate.length > 0)
        this.investigationForm.get('completedDate').patchValue(new Date(this.investigation.completedDate));

      if (this.investigation.incidentDate != null && this.investigation.incidentDate.length > 0)
        this.investigationForm.get('incidentDate').patchValue(new Date(this.investigation.incidentDate));
    }
    private getEmptyInvestigation(): IInvestigation {
      let investigation: IInvestigation = {
        id: 0,
        investigationId: "",
        status: "",
        startedDate: "",
        completedDate: "",
        incidentDate: "",
        summary: "",
        assignedTo: "",
        interviews: [],
        documentRequests: [],
        siteInspections: [],
        investigationPhotos: [],
        investigationWitnesses: []
      }
      return investigation;
    }

    public editDocumentRequest(item: IDocumentRequest): void{
      this.documentRequest = item;
      const dialogRef = this.dialog.open(AddDocumentRequestDialogComponent, {
        data: this.documentRequest,
        minWidth: !this.sharedService.mobile ? '700px' : '300px',
        maxWidth: !this.sharedService.mobile ? '800px' : '300px',
        maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
    }
    deleteDocumentRequest(id: number): void{
      this.sharedService.openConfirm("Delete Document Request?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.investigationService.deleteDocumentRequest(id).subscribe(
              () => {
                this.investigation.documentRequests = this.investigation.documentRequests.filter(item => item.id !== id);
                this.updateDocumentRequestTable();
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }

    createDocumentRequest():void{
      let newDocumentRequest: IDocumentRequest = {
        id: 0,
        notes: "",
        auditInfo: "",
        InvestigationId: this.investigation.id,
        documentRequestDocuments: [],
        documentName: "",
        deadlineDate: "",
        receivedDate: "",
        sentBy: "",
        recordsWitness: null
      }

      this.investigationService.createDocumentRequest(newDocumentRequest).subscribe(
        response => {
          newDocumentRequest = response;
        },
        error => console.log('error', error),
          () => {
            const dialogRef = this.dialog.open(AddDocumentRequestDialogComponent, {
              data: newDocumentRequest,
              minWidth: !this.sharedService.mobile ? '700px' : '300px',
              maxWidth: !this.sharedService.mobile ? '800px' : '300px',
              maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
              panelClass: this.sharedService.darkMode ? "theme-dark" : ""
            });

            dialogRef.afterClosed().subscribe((response) => {
              if (!this.sharedService.isCancelled(response)) {
                this.investigation.documentRequests.push(response);
                this.updateDocumentRequestTable();
              }
            });
          }
          );
    }

    public editInterview(item: IInterview): void{
      this.interview = item;
      const dialogRef = this.dialog.open(AddInterviewDialogComponent, {
        data: this.interview,
        minWidth: !this.sharedService.mobile ? '700px' : '300px',
        maxWidth: !this.sharedService.mobile ? '800px' : '300px',
        maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
    }

    deleteInterview(id: number): void{
      this.sharedService.openConfirm("Delete Interview?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.investigationService.deleteInterview(id).subscribe(
              () => {
                this.investigation.interviews = this.investigation.interviews.filter(item => item.id !== id);
                this.updateInterviewTable();
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }



    createInterview():void{
      let newInterview: IInterview = {
        id: 0,
        notes: "",
        auditInfo: "",
        InvestigationId: this.investigation.id,
        interviewDocuments: []
      }

      this.investigationService.createInterview(newInterview).subscribe(
        response => {
          newInterview = response;
        },
        error => console.log('error', error),
          () => {
            const dialogRef = this.dialog.open(AddInterviewDialogComponent, {
              data: newInterview,
              minWidth: !this.sharedService.mobile ? '700px' : '300px',
              maxWidth: !this.sharedService.mobile ? '800px' : '300px',
              maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
              panelClass: this.sharedService.darkMode ? "theme-dark" : ""
            });

            dialogRef.afterClosed().subscribe((response) => {
              if (!this.sharedService.isCancelled(response)) {
                this.investigation.interviews.push(response);
                this.updateInterviewTable();
              }
            });
          });
    }

  createSiteInspection():void{
      let newSiteInspection: ISiteInspection = {
        id: 0,
        caseId: "",
        type: "",
        status: "",
        assignedTo: "",
        manager: "",
        locked: false,
        summary: "",
        visitDate: "",
        followup: undefined,
        siteInspectionRules: []
      };

      this.investigationService.createSiteInspection(newSiteInspection, this.investigation.id ,this.caseId).subscribe(
        response => {
          this.siteInspection = response;
          this.investigation.siteInspections.push(this.siteInspection);
        },
        error => console.log('error', error),
          () => {
            this.siteInspection.type = 'Compliance';
            this.addingSiteInspection = true;
            this.updateSiteInspectionForm();
            this.updateSiteInspectionTable();
    });
  }

  editSiteInspection(item: ISiteInspection): void{
    this.siteInspection = item;
    this.updateSiteInspectionForm();
  }
  updateSiteInspectionForm(): void {
    this.siteInspectionForm.patchValue({
      type: this.siteInspection.type,
      status:  this.siteInspection.status,
      summary: this.siteInspection.summary,
      visitDate: this.siteInspection.visitDate
    });
    this.addingSiteInspection = true;
  }
  updateSiteInspection(close: boolean): void{
    let form = this.siteInspectionForm.value;
    this.siteInspection.type = form.type;
    this.siteInspection.status = form.status;
    this.siteInspection.summary = form.summary;
    this.siteInspection.visitDate = form.visitDate;

    this.investigationService.updateSiteInspection(this.siteInspection, this.investigation.id).subscribe(
      response => this.siteInspection = response,
      error => console.log('error', error),
      () => {
        let index = this.investigation.siteInspections.indexOf(this.siteInspection);
        this.investigation.siteInspections[index] = this.siteInspection
        this.updateSiteInspectionTable();
        this.compliance.complianceRules.forEach(rule =>
          {
            let currentSiteRule = this.siteInspection.siteInspectionRules.find(e => e.rule.id == rule.rule.id).notes;
            if(currentSiteRule != '')
            {
            if(rule.notes == null || rule.notes == '')
              rule.notes = ' Site Inspection ' + this.siteInspection.caseId + ' notes: ' + currentSiteRule;
            if(!rule.notes.includes(' notes: ' + currentSiteRule))
              rule.notes += ' Site Inspection ' + this.siteInspection.caseId + ' notes: ' + currentSiteRule;
            }
          });
        if(close){
          this.addingSiteInspection = false;
        }
      }
    );
  }
  closeInspection(): void {
    this.addingSiteInspection = false;
  }
  deleteSiteInspection(id: number, name: string): void{
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteSiteInspection(id).subscribe(
            () => {
              this.investigation.siteInspections = this.investigation.siteInspections.filter(item => item.id !== id);
              this.updateSiteInspectionTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  createInvestigationPhoto():void{
    let newInvestigationPhoto: IInvestigationPhoto = {
      id: 0,
      photographer: "",
      photoTakenDate: "",
      photoLocation: "",
      description: "",
      significance: "",
      InvestigationId: this.investigation.id,
      investigationPhotoDocuments: []
    }

    this.investigationService.createInvestigatonPhoto(newInvestigationPhoto).subscribe(
      response => {
        newInvestigationPhoto = response;
      },
      error => console.log('error', error),
        () => {
          const dialogRef = this.dialog.open(AddInvestigationPhotoDialogComponent, {
            data: newInvestigationPhoto,
            minWidth: !this.sharedService.mobile ? '700px' : '300px',
            maxWidth: !this.sharedService.mobile ? '800px' : '300px',
            maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
            panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });

          dialogRef.afterClosed().subscribe((response) => {
            if (!this.sharedService.isCancelled(response)) {
              response.photoTakenDate = new Date(response.photoTakenDate).toLocaleDateString();
              this.investigation.investigationPhotos.push(response);
              this.updateInvestigationPhotoTable();
            }
          });
        }
        );
  }

  deleteInvestigationPhoto(id: number): void{
    this.sharedService.openConfirm("Delete Investigation Photo?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteInvestigationPhoto(id).subscribe(
            () => {
              this.investigation.investigationPhotos = this.investigation.investigationPhotos.filter(item => item.id !== id);
              this.updateInvestigationPhotoTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  public editInvestigationPhoto(item: IInvestigationPhoto): void{
    this.investigatonPhoto = item;
    const dialogRef = this.dialog.open(AddInvestigationPhotoDialogComponent, {
      data: this.investigatonPhoto,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.componentInstance.emitInvestigationPhoto.subscribe((result: IInvestigationPhoto) => {
      this.investigation.investigationPhotos.find(e => e.id === result.id).photographer = result.photographer;
      this.investigation.investigationPhotos.find(e => e.id === result.id).photoTakenDate = result.photoTakenDate;
      this.investigation.investigationPhotos.find(e => e.id === result.id).photoLocation = result.photoLocation;
      this.investigation.investigationPhotos.find(e => e.id === result.id).description = result.description;
      this.investigation.investigationPhotos.find(e => e.id === result.id).significance = result.significance;
    });
  }

  createInvestigationWitness():void{
    let newInvestigationWitness: IInvestigationWitness = {
      id: 0,
      name: "",
      phoneNumber: "",
      email: "",
      testify: false,
      interviewDate: "",
      InvestigationId: this.investigation.id,
    }

    this.investigationService.createInvestigatonWitness(newInvestigationWitness).subscribe(
      response => {
        newInvestigationWitness = response;
      },
      error => console.log('error', error),
        () => {
          const dialogRef = this.dialog.open(AddInvestigationWitnessDialogComponent, {
            data: newInvestigationWitness,
            minWidth: !this.sharedService.mobile ? '700px' : '300px',
            maxWidth: !this.sharedService.mobile ? '800px' : '300px',
            maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
            panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });

          dialogRef.afterClosed().subscribe((response) => {
            if (!this.sharedService.isCancelled(response)) {
              response.interviewDate = new Date(response.interviewDate).toLocaleDateString();
              this.investigation.investigationWitnesses.push(response);
              this.updateInvestigationWitnessTable();
            }
          });
        }
        );
  }

  deleteInvestigationWitness(id: number): void{
    this.sharedService.openConfirm("Delete Investigation Witness?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteInvestigationWitness(id).subscribe(
            () => {
              this.investigation.investigationWitnesses = this.investigation.investigationWitnesses.filter(item => item.id !== id);
              this.updateInvestigationWitnessTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  public editInvestigationWitness(item: IInvestigationWitness): void{
    this.investigationWitness = item;
    const dialogRef = this.dialog.open(AddInvestigationWitnessDialogComponent, {
      data: this.investigationWitness,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.componentInstance.emitInvestigationWitness.subscribe((result: IInvestigationWitness) => {
      this.investigation.investigationWitnesses.find(e => e.id === result.id).name = result.name;
      this.investigation.investigationWitnesses.find(e => e.id === result.id).phoneNumber = result.phoneNumber;
      this.investigation.investigationWitnesses.find(e => e.id === result.id).email = result.email;
      this.investigation.investigationWitnesses.find(e => e.id === result.id).testify = result.testify;
      this.investigation.investigationWitnesses.find(e => e.id === result.id).interviewDate = result.interviewDate;
    });
  }
}



@Component({
  selector: 'add-document-request-dialog',
  styleUrls: ['./investigation.component.scss'],
  templateUrl: 'dialog-add-document-request.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class AddDocumentRequestDialogComponent implements OnInit  {
  public loading: boolean = true;
  public editingDocumentRequest: boolean = false;
  public today: Date = new Date(Date.now());
  public documentRequestForm = new UntypedFormGroup({
    notes: new UntypedFormControl(""),
    auditInfo: new UntypedFormControl(''),
    documentName: new UntypedFormControl(""),
    deadlineDate: new UntypedFormControl(""),
    receivedDate: new UntypedFormControl(""),
    sentBy: new UntypedFormControl(''),
    recordsWitness: new UntypedFormControl(null),
  });

  public documentRequestDocumentsColumns: string[] = ['name', 'actions'];
  public documentRequestDocumentsSource = new MatTableDataSource<IDocument>(this.documentRequest.documentRequestDocuments);
  @ViewChild('documentRequestDocumentsPaginator', {static: false}) documentRequestDocumentsPaginator: MatPaginator;
  @ViewChild('documentRequestDocumentsSort', {static: false}) documentRequestDocumentSort: MatSort;

  constructor(private toastr: ToastrService,
              private dialogRef: MatDialogRef<AddDocumentRequestDialogComponent>,
              public investigationService: InvestigationService,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public documentRequest: IDocumentRequest) {}


  ngOnInit(): void {
    this.updateForm();
    this.updateDocumentRequestDocumentTable();
    setTimeout(() => {
      this.loading = false;
    }, 50);
  }

  updateForm(): void {
    this.documentRequestForm.patchValue({
      notes: this.documentRequest.notes,
      auditInfo:  this.documentRequest.auditInfo,
      documentName: this.documentRequest.documentName,
      sentBy: this.documentRequest.sentBy,
      recordsWitness: this.documentRequest.recordsWitness,
    });
    if (this.documentRequest.deadlineDate != null && this.documentRequest.deadlineDate.length > 0)
      {
        this.documentRequestForm.get('deadlineDate').patchValue(new Date(this.documentRequest.deadlineDate));
      }
    if (this.documentRequest.receivedDate != null && this.documentRequest.receivedDate.length > 0)
      {
        this.documentRequestForm.get('receivedDate').patchValue(new Date(this.documentRequest.receivedDate));
      }
  }

  updateDocumentRequestDocumentTable(): void {
    this.documentRequestDocumentsSource.data = this.documentRequest.documentRequestDocuments;
    this.documentRequestDocumentsSource.sort = this.documentRequestDocumentSort;
    setTimeout(() => {
      this.documentRequestDocumentsSource.paginator = this.documentRequestDocumentsPaginator;
    }, 100);
  }

  saveDocumentRequest(close: boolean): void {
    let form = this.documentRequestForm.value;
    this.documentRequest.notes = form.notes;
    this.documentRequest.auditInfo = form.auditInfo;
    this.documentRequest.documentName = form.documentName;
    this.documentRequest.sentBy = form.sentBy;
    this.documentRequest.recordsWitness = form.recordsWitness;

    if(form.deadlineDate != '')
      this.documentRequest.deadlineDate = new Date(form.deadlineDate).toLocaleDateString()

    if(form.receivedDate != '')
      this.documentRequest.receivedDate = new Date(form.receivedDate).toLocaleDateString()

    this.investigationService.updateDocumentRequest(this.documentRequest).subscribe(
      response => this.documentRequest = response,
      error => console.log('error', error),
    );
    if(close)
      this.dialogRef.close(this.documentRequest);
  }

  deleteDocumentRequestDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteDocumentRequestDocument(id).subscribe(
            () => {
              this.documentRequest.documentRequestDocuments = this.documentRequest.documentRequestDocuments.filter(item => item.id !== id);
              this.updateDocumentRequestDocumentTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }


  downloadDocumentRequestDocument(fileId: number, fileName: string): void {
    this.investigationService.downloadDocumentRequestDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  uploadDocumentRequestDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.documentRequest.id,
      pendingUpdate: false,
      deprecated: false,
      types: [],
      adminOnly: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.investigationService.uploadDocumentRequestDocument(formData).subscribe(
      response => this.documentRequest.documentRequestDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.updateDocumentRequestDocumentTable();
      });
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-interview-dialog',
  styleUrls: ['./investigation.component.scss'],
  templateUrl: 'dialog-add-interview.html'
})

export class AddInterviewDialogComponent implements OnInit  {
  public loading: boolean = true;
  public editingInterview: boolean = false;
  public today: Date = new Date(Date.now());
  public interviewForm = new UntypedFormGroup({
    notes: new UntypedFormControl("", [Validators.required]),
    auditInfo: new UntypedFormControl('', [Validators.required]),
  });

  public interviewDocumentsColumns: string[] = ['name', 'actions'];
  public interviewDocumentsSource = new MatTableDataSource<IInterviewDocument>(this.interview.interviewDocuments);
  @ViewChild('interviewDocumentsPaginator', {static: false}) interviewDocumentsPaginator: MatPaginator;
  @ViewChild('interviewDocumentsSort', {static: false}) interviewDocumentSort: MatSort;

  public downloadProgress: number = 0;
  public uploadProgress: number = 0;
  public uploading: boolean = false;
  public downloading: boolean = false;
  public containerClient:  ContainerClient;
  public sasToken: string = "";
  public blobServiceClient: BlobServiceClient;

  constructor(private toastr: ToastrService,
              private dialogRef: MatDialogRef<AddInterviewDialogComponent>,
              public investigationService: InvestigationService,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public interview: IInterview) {}


  ngOnInit(): void {
    this.investigationService.getSASToken().subscribe({
      next: response => {
        this.sasToken = response
      },
      error: e => {
        console.log('error', e)
      },
      complete: () => {
        this.blobServiceClient = new BlobServiceClient(
          this.sasToken,
          new AnonymousCredential()
        );
        this.containerClient = this.blobServiceClient.getContainerClient('interviews');
      }
    });
    this.updateForm();
    this.updateInterviewDocumentTable();
    setTimeout(() => {
      this.loading = false;
    }, 50);
  }

  public async uploadToContainer(event: Event) {
    this.uploadProgress = 0;
    this.uploading = true;
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let blobName = file.name;
    const blockSize = 2 * 1024 * 1024;
    let offset = 0;
    let counter = 0;
    const blockIds: string[] = [];

    try {
      const blobClient = this.containerClient.getBlockBlobClient(blobName);
      const exists = await blobClient.exists();

      if (exists)
        blobName = this.generateUniqueFileName(blobName);
      const blobClientWithNewName = this.containerClient.getBlockBlobClient(blobName);
      while (offset < file.size) {
        const block = file.slice(offset, offset + blockSize);
        const blockId = btoa(counter.toString().padStart(6, '0'));
        await this.uploadBlock(blobClientWithNewName, blockId, block, progress => {
          this.uploadProgress = Math.round((offset + progress) / file.size * 100);
        });

        blockIds.push(blockId);
        offset += blockSize;
        counter++;
      }

      await blobClientWithNewName.commitBlockList(blockIds);

      let upload: IInterviewDocument = {
        id: 0,
        name: blobName,
        comments: "",
        extension: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.interview.id,
        pendingUpdate: false,
        deprecated: false,
        types: [],
        adminOnly: false,
        azureFile: true
      };

      const response = await this.investigationService.uploadInterviewRecording(upload).toPromise();
      this.interview.interviewDocuments.push(response);
      this.toastr.success('Upload Completed');
      this.updateInterviewDocumentTable();

    } catch (error) {
      this.toastr.error('Upload Failed');
      this.uploading = false;
      return;
    } finally {
      this.uploadProgress = 0;
      this.uploading = false;
      (event.target as HTMLInputElement).value = '';
    }
  }

  private async uploadBlock(blockBlobClient: BlockBlobClient, blockId: string, block: Blob, progressCallback?: (progress: number) => void) {
    const response = await blockBlobClient.stageBlock(blockId, block, block.size, {
      onProgress: ({ loadedBytes }) => {
        if (progressCallback) {
          progressCallback(loadedBytes);
        }
      }
    });
    return response;
  }

  generateUniqueFileName(filename: string): string {
    let name = filename.split('.');
    let extension = name.pop();
    let nameWithoutExtension = name.join('.');
    let timestamp = new Date().getTime();
    return nameWithoutExtension + '_' + timestamp + '.' + extension;
  }

  updateForm(): void {
    this.interviewForm.patchValue({
      notes: this.interview.notes,
      auditInfo:  this.interview.auditInfo
    });
  }

 updateInterviewDocumentTable(): void {
    this.interviewDocumentsSource.data = this.interview.interviewDocuments;
    this.interviewDocumentsSource.sort = this.interviewDocumentSort;
    setTimeout(() => {
      this.interviewDocumentsSource.paginator = this.interviewDocumentsPaginator;
    }, 100);
  }

  saveInterview(close: boolean): void {
    let form = this.interviewForm.value;
      this.interview.notes = form.notes;
      this.interview.auditInfo = form.auditInfo;

    this.investigationService.updateInterview(this.interview).subscribe(
      response => this.interview = response,
      error => console.log('error', error),
    );

    if(close)
      this.dialogRef.close(this.interview);
  }

  deleteInterviewDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteInterviewDocument(id).subscribe(
            () => {
              this.interview.interviewDocuments = this.interview.interviewDocuments.filter(item => item.id !== id);
              this.updateInterviewDocumentTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  async deleteAzureDocument(id: number, documentName: string) {
    this.sharedService.openConfirm("Delete " + documentName + "?");
    this.sharedService.confirmed().subscribe(async confirmed => {
      if (confirmed) {
        try {
          const blobClient = this.containerClient.getBlockBlobClient(documentName);
          await blobClient.delete();

          this.investigationService.deleteInterviewDocument(id).subscribe(
            () => {
              this.interview.interviewDocuments = this.interview.interviewDocuments.filter(item => item.id !== id);
              this.updateInterviewDocumentTable();
              this.toastr.success('Document Deleted');
            },
            error => console.log('error', error)
          );
        } catch (error) {
          this.toastr.error('Could not delete document');
        }
      }
    });
  }

  downloadInterviewDocument(fileId: number, fileName: string): void {
    this.investigationService.downloadInterviewDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  async downloadAzureDocument(documentName: string) {
    this.downloadProgress = 0;
    const blobClient = this.containerClient.getBlockBlobClient(documentName);
    const response = await fetch(blobClient.url);
    const totalBytes = parseInt(response.headers.get('Content-Length') || '0', 10);
    let downloadedBytes = 0;
    const chunks: Uint8Array[] = [];

    const readChunk = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
        const { done, value } = await reader.read();
        if (done) {
            const blob = new Blob(chunks, { type: 'application/octet-stream' });
            this.saveFile(documentName, blob);
            this.downloading = false;
            return;
        }
        if (value) {
            this.downloading = true;
            chunks.push(value);
            downloadedBytes += value.byteLength;
            this.downloadProgress = Math.round((downloadedBytes / totalBytes) * 100);
        }
        await readChunk(reader);
    };

    const reader = response.body?.getReader();
    if (reader) {
        await readChunk(reader);
    }
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  uploadInterviewDocument(event: Event, azureFile: boolean): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IInterviewDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.interview.id,
      pendingUpdate: false,
      deprecated: false,
      types: [],
      adminOnly: false,
      azureFile: azureFile
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.investigationService.uploadInterviewDocument(formData).subscribe(
      response => this.interview.interviewDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.updateInterviewDocumentTable();
      });
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-investigation-photo-dialog',
  styleUrls: ['./investigation.component.scss'],
  templateUrl: 'dialog-add-investigation-photo.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class AddInvestigationPhotoDialogComponent implements OnInit  {
  public loading: boolean = true;
  public today: Date = new Date(Date.now());
  public investigationPhotoForm = new FormGroup({
    photographer: new FormControl(''),
    photoTakenDate: new UntypedFormControl(""),
    photoLocation: new FormControl(''),
    description: new FormControl(''),
    significance: new FormControl(''),
  });

  public investigationPhotoDocumentsColumns: string[] = ['name', 'actions'];
  public investigationPhotoDocumentsSource = new MatTableDataSource<IDocument>(this.investigationPhoto.investigationPhotoDocuments);
  @ViewChild('investigationPhotoDocumentsPaginator', {static: false}) investigationPhotoDocumentsPaginator: MatPaginator;
  @ViewChild('investigationPhotoDocumentsSort', {static: false}) investigationPhotoDocumentsSort: MatSort;
  @Output() emitInvestigationPhoto = new EventEmitter<IInvestigationPhoto>();

  constructor(private dialogRef: MatDialogRef<AddInvestigationPhotoDialogComponent>,
              public investigationService: InvestigationService,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public investigationPhoto: IInvestigationPhoto) {}


  ngOnInit(): void {
    this.updateForm();
    this.updateInvestigationPhotoDocumentTable();
    setTimeout(() => {
      this.loading = false;
    }, 50);
  }

  updateForm(): void {
    this.investigationPhotoForm.patchValue({
      photographer: this.investigationPhoto.photographer,
      photoLocation:  this.investigationPhoto.photoLocation,
      description: this.investigationPhoto.description,
      significance: this.investigationPhoto.significance,
    });
    if (this.investigationPhoto.photoTakenDate != null && this.investigationPhoto.photoTakenDate.length > 0)
      this.investigationPhotoForm.get('photoTakenDate').patchValue(new Date(this.investigationPhoto.photoTakenDate));
  }

  updateInvestigationPhotoDocumentTable(): void {
    this.investigationPhotoDocumentsSource.data = this.investigationPhoto.investigationPhotoDocuments;
    this.investigationPhotoDocumentsSource.sort = this.investigationPhotoDocumentsSort;
    setTimeout(() => {
      this.investigationPhotoDocumentsSource.paginator = this.investigationPhotoDocumentsPaginator;
    }, 100);
  }

  saveInvestigationPhoto(close: boolean): void {
    let form = this.investigationPhotoForm.value;
      this.investigationPhoto.photographer = form.photographer;
      this.investigationPhoto.photoTakenDate = form.photoTakenDate;
      this.investigationPhoto.photoLocation = form.photoLocation;
      this.investigationPhoto.description = form.description;
      this.investigationPhoto.significance = form.significance;

    this.investigationService.updateInvestigationPhoto(this.investigationPhoto).subscribe(
      response =>{
          this.investigationPhoto = response
          this.emitInvestigationPhoto.emit(this.investigationPhoto);
      },
      error => console.log('error', error),
    );
    if(close)
      this.dialogRef.close(this.investigationPhoto);
  }

  deleteInvestigationPhotoDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.investigationService.deleteInvestigationPhotoDocument(id).subscribe(
            () => {
              this.investigationPhoto.investigationPhotoDocuments = this.investigationPhoto.investigationPhotoDocuments.filter(item => item.id !== id);
              this.updateInvestigationPhotoDocumentTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }


  downloadInvestigationPhotoDocument(fileId: number, fileName: string): void {
    this.investigationService.downloadInvestigationPhotoDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  uploadInvestigationPhotoDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.investigationPhoto.id,
      pendingUpdate: false,
      deprecated: false,
      types: [],
      adminOnly: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.investigationService.uploadInvestigationPhotoDocument(formData).subscribe(
      response => this.investigationPhoto.investigationPhotoDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.updateInvestigationPhotoDocumentTable();
      });
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-investigation-witness-dialog',
  styleUrls: ['./investigation.component.scss'],
  templateUrl: 'dialog-add-investigation-witness.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class AddInvestigationWitnessDialogComponent implements OnInit  {
  public loading: boolean = true;
  public today: Date = new Date(Date.now());
  public investigationWitnessForm = new FormGroup({
    name: new FormControl(''),
    phoneNumber: new FormControl('', [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    email: new FormControl('', [Validators.email]),
    testify: new FormControl(null),
    interviewDate: new UntypedFormControl(""),
  });

  @Output() emitInvestigationWitness = new EventEmitter<IInvestigationWitness>();

  constructor(private dialogRef: MatDialogRef<AddInvestigationWitnessDialogComponent>,
              public investigationService: InvestigationService,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public investigationWitness: IInvestigationWitness) {}


  ngOnInit(): void {
    this.updateForm();
    setTimeout(() => {
      this.loading = false;
    }, 50);
  }

  updateForm(): void {
    this.investigationWitnessForm.patchValue({
      name: this.investigationWitness.name,
      phoneNumber:  this.investigationWitness.phoneNumber,
      email: this.investigationWitness.email,
      testify: this.investigationWitness.testify,
    });
    if (this.investigationWitness.interviewDate != null && this.investigationWitness.interviewDate.length > 0)
      this.investigationWitnessForm.get('interviewDate').patchValue(new Date(this.investigationWitness.interviewDate));
  }

  saveInvestigationWitness(close: boolean): void {
    let form = this.investigationWitnessForm.value;
      this.investigationWitness.name = form.name;
      this.investigationWitness.phoneNumber = form.phoneNumber;
      this.investigationWitness.email = form.email;
      this.investigationWitness.testify = form.testify;
      this.investigationWitness.interviewDate = form.interviewDate;

    this.investigationService.updateInvestigationWitness(this.investigationWitness).subscribe(
      response =>{
          this.investigationWitness = response
          this.emitInvestigationWitness.emit(this.investigationWitness);
      },
      error => console.log('error', error),
    );
    if(close)
      this.dialogRef.close(this.investigationWitness);
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}
