import { Injectable } from "@angular/core";
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router, private authSvc: AuthService) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if(this.authSvc.isAuthorized(route.data.roles)) {
      return true;
    }

    this.router.navigate(['/sessions/403']);
    return false;
  }

  canActivateChild(): boolean {
    return this.authSvc.isAuthorized();
  }
}
