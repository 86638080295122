<div fxLayout="column" fxLayoutGap="12px">
  <section>
    <div fxLayout="row" fxLayoutGap="16px">
      <span class="f-s-20" fxFlex>Associate License</span>
      <button mat-raised-button type="button" color="primary" *ngIf="associateForm.get('licenseId').valid && associateForm.get('licenseId').value" (click)="associateLicense()">Associate</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Close</button>
    </div>
  </section>
  <section>
    <form [formGroup]="associateForm">
      <mat-form-field>
        <input matInput placeholder="License Id" formControlName="licenseId">
        <mat-error *ngIf="associateForm.get('licenseId').invalid">
          The license provided could not be found
        </mat-error>
      </mat-form-field>
    </form>
  </section>
</div>
