import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceCenterRoutingModule } from './service-center-routing.module';
import { SharedModule } from '@shared';
import { AssignedServiceCentersComponent } from './assigned-service-centers/assigned-service-centers.component';
import { SearchServiceCentersComponent, ServiceCenterDetailsDialog } from './search-service-centers/search-service-centers.component';
import { ServiceCenterComplaintsComponent } from './service-center-complaints/service-center-complaints.component';
import { ServiceCenterTasksComponent } from './service-center-tasks/service-center-tasks.component';
import { ApplicantModule } from '../applicant/applicant.module';
import { ComplaintModule } from '../complaint/complaint.module';
import { ComplianceModule } from '../compliance/compliance.module';
import { NotesModule } from '../notes/notes.module';
import { PaymentsModule } from '../payments/paymentsmodule';
import { SiteInspectionModule } from '../site-inspection/site-inspection.module';
import { LicenseChecklistModule } from '../license-checklist/license-checklist.module';
import { PrintServiceCenterComponent } from './print-service-centers/print-service-centers.component';
import { ManagerNotesModule } from '../manager-notes/manager-notes.module';
import { ReportedEventModule } from '../reported-event/reported-event.module';
import { AssociatedWorkerPermitsModule } from '../associated-worker-permits/associated-worker-permits.module';

@NgModule({
  declarations: [
    AssignedServiceCentersComponent,
    SearchServiceCentersComponent,
    ServiceCenterComplaintsComponent,
    ServiceCenterTasksComponent,
    PrintServiceCenterComponent,
    ServiceCenterDetailsDialog
  ],
  imports: [
    CommonModule,
    SharedModule,
    ServiceCenterRoutingModule,
    PaymentsModule,
    NotesModule,
    ApplicantModule,
    ComplianceModule,
    ComplaintModule,
    SiteInspectionModule,
    LicenseChecklistModule,
    ManagerNotesModule,
    ReportedEventModule,
    AssociatedWorkerPermitsModule
  ]
})
export class ServiceCenterModule { }
