import { CommonModule } from "@angular/common";
import { ReportedEventComponent } from "./reported-event.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared";

const COMPONENTS = [
  ReportedEventComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [...COMPONENTS]
})
export class ReportedEventModule { }
