<div fxLayout="column" fxLayoutGap="16px">
  <span style="font-size:x-large">Manage Compliance</span>
  <mat-tab-group dynamicHeight>
    <mat-tab label="Rules">
      <app-manage-rules></app-manage-rules>
    </mat-tab>
    <mat-tab label="Categories">
      <ng-template matTabContent>
        <app-manage-lookups [type]="sharedService.category"></app-manage-lookups>
      </ng-template>
    </mat-tab>
    <mat-tab label="Sub-Sections">
      <ng-template matTabContent>
        <app-manage-lookups [type]="sharedService.subSection"></app-manage-lookups>
      </ng-template>
    </mat-tab>
    <mat-tab label="Investigation Items">
      <ng-template matTabContent>
        <app-manage-lookups [type]="sharedService.investigationItem"></app-manage-lookups>
      </ng-template>
    </mat-tab>
    <mat-tab label="Action Items">
      <ng-template matTabContent>
        <app-manage-lookups [type]="sharedService.actionItem"></app-manage-lookups>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
