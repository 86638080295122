import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuditLogService } from "app/services/audit-log/audit-log.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";
import { IAuditLog } from "app/models/audit-logging/audit-logging";
import { AuditLoggingSearch } from "app/models/audit-logging/audit-logging-search";

@Component({
  selector: 'contact-information-audit-log',
  templateUrl: './contact-information-table.component.html',
  providers: [AuditLogService],
})

export class ContactInformationDialogComponent implements OnInit  {

  public auditlogs: IAuditLog[] = [];
  loading: boolean = false;
  search: AuditLoggingSearch = null;
  entityId: string = "";
  entityType: string = "";

  constructor(public sharedService: SharedService,
    private auditLogService: AuditLogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.entityId = data.entityId;
      this.entityType = data.entityType;
    }

  ngOnInit(): void {
    this.loading = true;
    this.auditLogService.initiateSearch(this.entityId, this.entityType).subscribe(
      response => {
        this.search = response;
        this.search.displayContactInformation = true;
        this.getLogs();
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  getLogs() {
    this.auditLogService.getAuditLogging(this.search).subscribe(
      response => {
        this.auditlogs = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }
}
