<div [hidden]="loading">
  <section>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <span class="f-s-20" fxFlex>Edit Investigation Witness</span>
      <button mat-raised-button type="button" color="primary" (click)="saveInvestigationWitness(false)" [disabled]="investigationWitnessForm.invalid">Save</button>
      <button mat-raised-button type="button" color="primary" (click)="saveInvestigationWitness(true)">Save & Close</button>
      <button mat-raised-button type="button" color="warn" (click)="cancel()">Close</button>
    </div>
  </section>

  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
      <form [formGroup]="investigationWitnessForm" fxLayout="column">

        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <mat-label>Interview Date</mat-label>
            <input placeholder="mm/dd/yyyy" formControlName="interviewDate" matInput [matDatepicker]="interviewDate"/>
            <mat-datepicker-toggle matSuffix [for]="interviewDate"></mat-datepicker-toggle>
            <mat-datepicker #interviewDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Phone Number" formControlName="phoneNumber"  mask="(000) 000-0000 000000" [validation]="false">
            <mat-error *ngIf="investigationWitnessForm.get('phoneNumber').hasError('pattern')">
              Phone should match this format: 999-999-9999 x1234
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input matInput placeholder="Email" formControlName="email">
            <mat-error *ngIf="investigationWitnessForm.get('email').hasError('email')">
              This doesn't look like a valid email address
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <div fxLayout="column" fxFlex="50">
            <mat-label>Is witness willing to Testify</mat-label>
            <mat-radio-group formControlName="testify" fxLayout="row" fxLayoutGap="8px">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        </form>
      </div>
    </mat-dialog-content>
  </div>

  <div [hidden]="!loading">
    <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
  </div>
