import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { AssignedManufacturersComponent } from './assigned-manufacturers/assigned-manufacturers.component';
import { ManufacturerComplaintsComponent } from './manufacturer-complaints/manufacturer-complaints.component';
import { ManufacturerTasksComponent } from './manufacturer-tasks/manufacturer-tasks.component';
import { SearchManufacturersComponent } from './search-manufacturers/search-manufacturers.component';

const routes: Routes = [
  { path: "", redirectTo: "search", pathMatch: 'full' },
  { path: 'search', component: SearchManufacturersComponent, canActivate: [ AuthGuard ]},
  { path: 'search/:id', component: SearchManufacturersComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned', component: AssignedManufacturersComponent, canActivate: [ AuthGuard ]},
  { path: 'assigned/:id', component: AssignedManufacturersComponent, canActivate: [ AuthGuard ]},
  { path: 'tasks', component: ManufacturerTasksComponent, canActivate: [ AuthGuard ]},
  { path: 'complaints', component: ManufacturerComplaintsComponent, canActivate: [ AuthGuard ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManufacturerRoutingModule { }
