import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IApplicantSearchResult } from "app/models/licenses/applicant-search/applicant-search-result";


@Component({
  selector: 'autofill-select',
  templateUrl: './autofill-duplicate-applicant.component.html',
  styleUrls: ['./autofill-duplicate-applicant.component.scss']
})
export class AutofillDuplicateApplicantComponent implements OnInit {
  public dataColumns: string[] = ['firstName', 'lastName', 'middleName', 'dob', 'ssn', 'applicantId', 'similarity'];
  public dataSource = new MatTableDataSource<IApplicantSearchResult>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<AutofillDuplicateApplicantComponent>,
    @Inject(MAT_DIALOG_DATA) public applicants: IApplicantSearchResult[]) { }

  ngOnInit() {
    setTimeout(() => {
      this.dataSource.data = this.applicants;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 200);
  }

  selectApplicant(id: number) {
    this.dialogRef.close(id);
  }

  cancel(): void {
    this.dialogRef.close(-1);
  }
}
