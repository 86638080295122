<div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
  <span style="font-size:x-large">Manage {{headerType}}</span>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field fxFlex="20" fxFlex.lt-md="100">
        <input matInput (keyup)="filterTable($event)" placeholder="Filter" />
      </mat-form-field>
      <span fxFlex></span>
      <span>Click Row to Edit</span>
      <button mat-stroked-button type="button" color="primary" (click)="displayArchived()">{{hideArchived? 'Show Archived' : 'Hide Archived'}}</button>
      <button type="button" mat-icon-button color="primary"
        [matTooltip]="'Add ' + type" matTooltipShowDelay=800 aria-label="Add Look Up" (click)="addLookUp()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <mat-table [dataSource]="lookUpSource" matSort #lookUpSort="matSort" aria-describedby="Look Ups" class="mat-elevation-z8">
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{type}}</mat-header-cell>
        <mat-cell *matCellDef="let lookUp" (click)="editLookUp(lookUp)">
          <span class="mobile-label">{{type}}</span>
          {{ lookUp.value }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let lookUp">
          <button mat-stroked-button *ngIf="lookUp.archived" type="button" color="primary" (click)="archive(lookUp, false)">Un-Archive</button>
          <button mat-stroked-button *ngIf="!lookUp.archived" type="button" color="primary" (click)="archive(lookUp, true)">Archive</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="lookUpColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: lookUpColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" #lookUpPaginator="matPaginator">
    </mat-paginator>

  </div>
</div>
