<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.blue">
  <mat-card-content>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="" style="color: #303030">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-icon class="medium-icon m-24">home</mat-icon>
        <span class="f-s-20">TLC Home</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column">
  <mat-card [style.background-color]="sharedService.blue + '30'">
    <mat-card-title>
      <div fxLayout="row">
        <span fxFlex>Admin Configuration</span>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="column">

        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center" fxFlex="100">
          <div fxLayout="column" fxFlex="20">
            <span class="f-s-16" fxLayoutAlign="center">
              Header Message
            </span>
            <mat-form-field appearance="fill">
              <mat-label>Header Message</mat-label>
              <textarea rows="2" matInput [(ngModel)]="adminSettings.headerText" (blur)="updateAdminSettings()"></textarea>
            </mat-form-field>
          </div>
        </div>
        <mat-card>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center" fxFlex="100">
            <div fxLayout="column" fxFlex="40">
              <span class="f-s-16">
                Message to users
              </span>
              <mat-form-field appearance="fill">
                <mat-label>Message to users</mat-label>
                <textarea rows="3" matInput [(ngModel)]="adminSettings.message" (blur)="updateAdminSettings()"></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <div fxLayout="row" fxLayoutGap="8px">
          <mat-card  fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <span class="f-s-16">
                  Helpful Links
                </span>
                <span><strong>Add a Link</strong></span>
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" [(ngModel)]="newLink.description">
                  </mat-form-field>
                  <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                    <mat-label>Link</mat-label>
                    <input matInput placeholder="Link URL" [(ngModel)]="newLink.link">
                  </mat-form-field>
                  <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                    <mat-label>Order</mat-label>
                    <input type="number" min="0" matInput placeholder="#" [(ngModel)]="newLink.order" (blur)="updateAdminSettings()">
                  </mat-form-field>
                  <button type="button" mat-icon-button color="primary" (click)="addLink('help')"> <mat-icon>add</mat-icon></button>
                </div>
                <span><strong>Current Links</strong></span>
                <div fxLayout="column" *ngFor="let link of adminSettings.links">
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Description</mat-label>
                      <input matInput placeholder="Description" [(ngModel)]="link.description" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Link</mat-label>
                      <input matInput placeholder="Link URL" [(ngModel)]="link.link" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                      <mat-label>Order</mat-label>
                      <input type="number" min="0" matInput placeholder="#" [(ngModel)]="link.order" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <button type="button" mat-icon-button color="warn" (click)="removeLink(link.description,'help')"> <mat-icon>delete</mat-icon></button>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card  fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">

                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-16">
                    Document Links
                  </span>
                  <span><strong>Add a Link</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Description</mat-label>
                      <input matInput placeholder="Description" [(ngModel)]="newDocumentLink.description">
                    </mat-form-field>
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Link</mat-label>
                      <input matInput placeholder="Link URL" [(ngModel)]="newDocumentLink.link">
                    </mat-form-field>
                    <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                      <mat-label>Order</mat-label>
                      <input type="number" min="0" matInput placeholder="#" [(ngModel)]="newDocumentLink.order" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <button type="button" mat-icon-button color="primary" (click)="addLink('document')"> <mat-icon>add</mat-icon></button>
                  </div>
                  <span><strong>Current Links</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.documentLinks">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description" [(ngModel)]="link.description" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Link</mat-label>
                        <input matInput placeholder="Link URL" [(ngModel)]="link.link" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                        <mat-label>Order</mat-label>
                        <input type="number" min="0" matInput placeholder="#" [(ngModel)]="link.order" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <button type="button" mat-icon-button color="warn" (click)="removeLink(link.description, 'document')"> <mat-icon>delete</mat-icon></button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>


        <div fxLayout="row" fxLayoutGap="8px">
          <mat-card  fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <span class="f-s-16">
                  Change Request Message to Users
                </span>
                <mat-form-field appearance="fill">
                  <mat-label>Change Request Message to Users</mat-label>
                  <textarea rows="3" matInput [(ngModel)]="adminSettings.changeRequestMessage" (blur)="updateAdminSettings()"></textarea>
                </mat-form-field>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-16">
                    Change Request Links
                  </span>
                  <span><strong>Add a Link</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Description</mat-label>
                      <input matInput placeholder="Description" [(ngModel)]="newChangeRequestLink.description">
                    </mat-form-field>
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Link</mat-label>
                      <input matInput placeholder="Link URL" [(ngModel)]="newChangeRequestLink.link">
                    </mat-form-field>
                    <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                      <mat-label>Order</mat-label>
                      <input type="number" min="0" matInput placeholder="#" [(ngModel)]="newChangeRequestLink.order" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <button type="button" mat-icon-button color="primary" (click)="addLink('changeRequest')"> <mat-icon>add</mat-icon></button>
                  </div>
                  <span><strong>Current Links</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.changeRequestLinks">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description" [(ngModel)]="link.description" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Link</mat-label>
                        <input matInput placeholder="Link URL" [(ngModel)]="link.link" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                        <mat-label>Order</mat-label>
                        <input type="number" min="0" matInput placeholder="#" [(ngModel)]="link.order" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <button type="button" mat-icon-button color="warn" (click)="removeLink(link.description, 'changeRequest')"> <mat-icon>delete</mat-icon></button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card  fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <span class="f-s-16">
                  Renewal Message to Users
                </span>
                <mat-form-field appearance="fill">
                  <mat-label>Renewal Message to Users</mat-label>
                  <textarea rows="3" matInput [(ngModel)]="adminSettings.renewalMessage" (blur)="updateAdminSettings()"></textarea>
                </mat-form-field>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-16">
                    Renewal Links
                  </span>
                  <span><strong>Add a Link</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Description</mat-label>
                      <input matInput placeholder="Description" [(ngModel)]="newRenewalLink.description">
                    </mat-form-field>
                    <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                      <mat-label>Link</mat-label>
                      <input matInput placeholder="Link URL" [(ngModel)]="newRenewalLink.link">
                    </mat-form-field>
                    <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                      <mat-label>Order</mat-label>
                      <input type="number" min="0" matInput placeholder="#" [(ngModel)]="newRenewalLink.order" (blur)="updateAdminSettings()">
                    </mat-form-field>
                    <button type="button" mat-icon-button color="primary" (click)="addLink('renewal')"> <mat-icon>add</mat-icon></button>
                  </div>
                  <span><strong>Current Links</strong></span>
                  <div fxLayout="column" *ngFor="let link of adminSettings.renewalLinks">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description" [(ngModel)]="link.description" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="45" fxFlex.lt-lg="100">
                        <mat-label>Link</mat-label>
                        <input matInput placeholder="Link URL" [(ngModel)]="link.link" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <mat-form-field fxFlex="10" fxFlex.lt-lg="100">
                        <mat-label>Order</mat-label>
                        <input type="number" min="0" matInput placeholder="#" [(ngModel)]="link.order" (blur)="updateAdminSettings()">
                      </mat-form-field>
                      <button type="button" mat-icon-button color="warn" (click)="removeLink(link.description, 'renewal')"> <mat-icon>delete</mat-icon></button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
