<mat-dialog-content id="editNote">
  <div fxLayout="Column" fxLayoutGap="8px">
    <span class="f-s-20">{{note.id > 0? 'Edit' : 'Add'}} Note</span>
    <form [formGroup]="noteForm" (ngSubmit)="saveNote()" class="p-t-16">
      <div fxLayout="column" fxLayoutGap="16px" fxLayoutGap.lt-md="4px">
        <mat-form-field>
          <textarea formControlName="text" matInput rows="5" placeholder="Note" type="text">
          </textarea>
          <mat-error *ngIf="noteForm.get('text').invalid">
            Please enter note
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="16px" class="m-t-16">
          <button mat-raised-button type="button" color="primary" [disabled]="processing"
            (click)="saveNote()">Save</button>
          <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
