import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { IComplianceRule } from 'app/models/compliance/compliance-rule';
import { IActionItem } from 'app/models/compliance/look-ups/action-item';
import { ICategory } from 'app/models/compliance/look-ups/category';
import { IRule } from 'app/models/compliance/rule';
import { INote } from 'app/models/notes/note';
import { IFollowup } from 'app/models/site-inspection/followup';
import { IFollowupRule } from 'app/models/site-inspection/followup-rule';
import { ISiteInspection } from 'app/models/site-inspection/site-inspection';
import { IStaff } from 'app/models/user-info/staff';
import { ComplianceService } from 'app/services/compliance/compliance.service';
import { SharedService } from 'app/services/core/shared.service';
import { SiteInspectionService } from 'app/services/site-inspection/site-inspection.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AddNoteReferenceDialogComponent, ResolveRuleDialogComponent } from '../compliance/compliance.component';
import { ViewRuleDialogComponent } from '../manage-compliance/manage-rules/manage-rules.component';
import { DialogEditNoteComponent } from '../notes/edit/edit-note-dialog';
import { IDocument } from 'app/models/documents/document';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-site-inspection',
  templateUrl: './site-inspection.component.html',
  styleUrls: ['./site-inspection.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class SiteInspectionComponent implements OnInit {
  @Input() entityId: number = 0;
  @Input() entityType: string = '';
  @Input() entityName: string = '';
  @Input() staff: IStaff[] = [];
  @Input() notes: INote[] = [];
  @Input() cardColor: string = '';
  @Input() tableColor: string = '';
  @Input() renewal: boolean = false;
  @Input() licenseId: string = '';

  public filterText: string = '';
  public filterTextFollowup: string = '';
  public expired: boolean = false;
  public siteInspections: ISiteInspection[] = [];
  public expiredSiteInspections: ISiteInspection[] = [];
  public followup: IFollowup = {
    id: 0,
    caseId: '',
    status: '',
    category: '',
    tier: '',
    assignedTo: '',
    manager: '',
    outcome: '',
    locked: false,
    dateOfInspection: '',
    dateRequirementsMet: '',
    followupRules: [],
    notes: [],
    followupDocuments: [],
    dateCreated: '',
    message: ''
  }
  public siteInspection: ISiteInspection = {
    id: 0,
    caseId: '',
    type: '',
    status: '',
    assignedTo: '',
    manager: '',
    locked: false,
    summary: '',
    visitDate: '',
    followup: {
      id: 0,
      caseId: '',
      status: '',
      category: '',
      tier: '',
      assignedTo: '',
      manager: '',
      outcome: '',
      locked: false,
      dateOfInspection: '',
      dateRequirementsMet: '',
      followupRules: [],
      notes: [],
      followupDocuments: [],
      dateCreated: '',
      message: ''
    },
    siteInspectionRules: []
  }
  public siteInspectionForm = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    assignedTo: new UntypedFormControl(''),
    manager: new UntypedFormControl(''),
    locked: new UntypedFormControl(false),
    summary: new UntypedFormControl(''),
    visitDate: new UntypedFormControl('')
  });
  public followupForm = new UntypedFormGroup({
    status: new UntypedFormControl(''),
    category: new UntypedFormControl(''),
    tier: new UntypedFormControl(''),
    assignedTo: new UntypedFormControl(''),
    manager: new UntypedFormControl(''),
    outcome: new UntypedFormControl(''),
    locked: new UntypedFormControl(false),
    dateOfInspection: new UntypedFormControl(''),
    dateRequirementsMet: new UntypedFormControl(''),
    message: new UntypedFormControl('')
  });
  public loading: boolean = true;
  public editing: boolean = false;
  public today: Date = new Date(Date.now());
  public managers: IStaff[] = [];
  public staffReference: {[id: string]: string} = {};
  public categories: ICategory[] = [];
  public filteredCategories: Observable<ICategory[]>;
  public isAdmin: boolean = false;
  public rules: IFollowupRule[] = [];

  public inspectionColumns: string[] = ['caseId', 'status', 'type', 'visitDate', 'assignedTo', 'manager', 'followupAge'];
  public inspectionSource = new MatTableDataSource<ISiteInspection>(this.siteInspections);
  @ViewChild('inspectionPaginator', {static: false}) inspectionPaginator: MatPaginator;
  @ViewChild('inspectionSort', {static: false}) inspectionSort: MatSort;


  public expiredInspectionColumns: string[] = ['caseId', 'status', 'type', 'visitDate', 'assignedTo', 'manager'];
  public expiredInspectionSource = new MatTableDataSource<ISiteInspection>(this.expiredSiteInspections);
  @ViewChild('expiredInspectionPaginator', {static: false}) expiredInspectionPaginator: MatPaginator;
  @ViewChild('expiredInspectionSort', {static: false}) expiredInspectionSort: MatSort;

  public notesColumns: string[] = ['text'];
  public notesSource = new MatTableDataSource<INote>(this.followup.notes);
  public siteInspectionRules$ = new BehaviorSubject(this.siteInspection.siteInspectionRules);
  formLoaded: boolean;

  constructor(public sharedService: SharedService,
              public siteInspectionService: SiteInspectionService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              public authService: AuthService,
              public complianceService: ComplianceService
              ) { }

  ngOnInit(): void {
    this.getSiteInspections();
    this.getManagers();
    this.setupStaffReference();
    this.getCategories();
    this.checkAdmin();
    if(this.renewal)
    this.siteInspectionService.getExpiredSiteInspections(this.entityId, this.licenseId, this.entityType).subscribe(
      response => this.expiredSiteInspections = response,
      error => console.log('error', error),
      () => {
        this.updateExpiredSiteInspectionsTable();
      }
    );

    this.siteInspectionRules$.pipe(
      debounceTime(1000), // Wait 1 second before saving
      distinctUntilChanged() // Prevent redundant saves
    ).subscribe(() => {
      if(this.siteInspection && this.siteInspection.siteInspectionRules.length > 0){
      this.autoSave();
      }
    });

    // Auto-save for Site Inspection form
    this.siteInspectionForm.valueChanges.pipe(
      debounceTime(1000),  // Wait 1 second after last input
      distinctUntilChanged() // Prevent unnecessary saves if value doesn't change
    ).subscribe(() => {
       this.autoSave();
    });
      // Auto-save for Followup form
    this.followupForm.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(() => {
      this.autoSaveFollowup();
    });
  }

  // Function to automatically save site inspection form data
  autoSave() {
    if (this.formLoaded && this.siteInspectionForm.valid) {
      console.log('Auto-saving site inspection...');
      this.updateSiteInspection(false); // Call the existing save function
    }
  }

  // Function to automatically save followup form data
  autoSaveFollowup() {
    if (this.followupForm.valid) {
      console.log('Auto-saving Followup...');
      this.updateFollowup(false); // Calls existing save function for followups
    }
  }

  updateRule() {
    this.siteInspectionRules$.next([...this.siteInspection.siteInspectionRules]); 
  }

  getFilteredRules() {
    if (!this.filterText) return this.siteInspection.siteInspectionRules;
    return this.siteInspection.siteInspectionRules.filter(rule =>
      rule.rule.statute.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  getFilteredFollowupRules() {
    if (!this.filterTextFollowup) return this.followup.followupRules;
    return this.followup.followupRules.filter(rule =>
      rule.rule.statute.toLowerCase().includes(this.filterTextFollowup.toLowerCase())
    );
  }

  checkAdmin(): void {
    if(this.authService.isAdmin)
      this.isAdmin = true;
    if(this.entityType == this.sharedService.serviceCenter)
      if(this.authService.isServiceCenterAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.manufacturer)
      if(this.authService.isManufacturerAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.testingLab)
      if(this.authService.isTestingLabAdmin)
        this.isAdmin = true;
    }

  private getSiteInspections(): void {
    this.siteInspectionService.getSiteInspections(this.entityId, this.entityType).subscribe(
      {
        next: response => this.siteInspections = response,
        error: err => console.log('error', err),
        complete: () => {
          this.loading = false;
          setTimeout(() => {
            this.updateSiteInspectionTable();            
          }, 200);
        }
      }
    );
  }

  private updateSiteInspectionTable(): void {
    this.inspectionSource.data = this.siteInspections;
    this.inspectionSource.paginator = this.inspectionPaginator;
    this.inspectionSource.sort = this.inspectionSort;
  }

  private updateExpiredSiteInspectionsTable(): void {
    this.expiredInspectionSource.data = this.expiredSiteInspections;
    this.expiredInspectionSource.paginator = this.expiredInspectionPaginator;
    this.expiredInspectionSource.sort = this.expiredInspectionSort;
  }

  private getManagers(): void {
    this.complianceService.getAdmins().subscribe(
      response => this.managers = response,
      error => console.log('error', error)
    )
  }

  private setupStaffReference(): void {
    this.staff.forEach(staff => {
      this.staffReference[staff.id] = staff.name;
    });
  }

  private getCategories(): void {
    this.complianceService.getLookUps(false, this.sharedService.category).subscribe(
      response => this.categories = response,
      error => console.log('error', error),
      () => {
        this.filteredCategories = this.followupForm.get("category").valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || ''))
        );
      }
    );
  }

  private _filter(value: string): IActionItem[] {
    const filterValue = value.toLowerCase();
    return this.categories.filter(item => item.value.toLowerCase().includes(filterValue));
  }

  public filterTable(event: Event, table: string): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    switch(table){
      case 'inspection':
        this.inspectionSource.filter = filter;
        break;
      case 'expiredInspection':
        this.expiredInspectionSource.filter = filter;
        break;
      case 'notes':
        this.notesSource.filter = filter;
    }
  }

  public createSiteInspetion(): void {
    this.siteInspectionService.createSiteInspection(this.entityId, this.entityType).subscribe(
      {
        next: response => this.siteInspection = response,
        error: err => console.log('error', err),
        complete: () => {
          this.siteInspections.push(this.siteInspection);
          this.viewSiteInspection(this.siteInspection, false);
        }
      }
    )
  }

  public createFollowUp(): void {
    this.siteInspectionService.createFollowUp(this.siteInspection).subscribe(
      {
        next: response => this.siteInspection = response,
        error: err => console.log('error', err),
      }
    )
  }

  public checkCompliant(): boolean {
    let compliant: boolean = true;
    this.siteInspection.siteInspectionRules.forEach(rule => {
      if(rule.compliant == null){
        compliant = false;
      }
    });
    return compliant;
  }

  public viewSiteInspection(inspection: ISiteInspection, expired: boolean): void {
    this.expired = expired;
    this.siteInspection = inspection;
    this.siteInspectionForm.patchValue({
      type: inspection.type,
      status: inspection.status,
      assignedTo: inspection.assignedTo,
      manager: inspection.manager,
      locked: inspection.locked,
      summary: inspection.summary
    });

    if(inspection.visitDate != null && inspection.visitDate.length > 0){
      this.siteInspectionForm.get('visitDate').patchValue(new Date(inspection.visitDate));
    }

    if(inspection.locked){
      this.siteInspectionForm.disable();
    }
    this.formLoaded = true;
  }

  public closeInspection(): void {
    this.siteInspection = this.getEmptySiteInspection();
    this.getSiteInspections();
    setTimeout(() => {
      this.updateSiteInspectionTable();
    }, 200);
  }

  private getEmptySiteInspection(): ISiteInspection {
    let emptyInspection: ISiteInspection = {
      id: 0,
      caseId: '',
      type: '',
      status: '',
      assignedTo: '',
      manager: '',
      locked: false,
      summary: '',
      visitDate: '',
      followup: undefined,
      siteInspectionRules: []
    }
    return emptyInspection;
  }

  public updateFollowup(close: boolean): void {
    let form = this.followupForm.value;
    this.followup.status = form.status;
    this.followup.category = form.category;
    this.followup.tier = form.tier;
    this.followup.message = form.message;
    this.followup.assignedTo = form.assignedTo;
    this.followup.manager = form.manager;
    this.followup.outcome = form.outcome;
    this.followup.dateOfInspection = form.dateOfInspection;
    this.followup.dateRequirementsMet = form.dateRequirementsMet;

    this.followup.followupRules.forEach(rule => {
      if(rule.resolvedDateFormatted != null)
        rule.resolvedDate = rule.resolvedDateFormatted.toISOString();
      else
        rule.resolvedDate = '';
    });

    this.siteInspectionService.updateFollowup(this.followup).subscribe({
      error: err => console.log('error', err),
      complete: () => {
        if(close){
          this.closeFollowup();
        }
      }
    });
  }

  public updateSiteInspection(close: boolean): void {
    let form = this.siteInspectionForm.value;
    this.siteInspection.type = form.type;
    this.siteInspection.status = form.status;
    this.siteInspection.assignedTo = form.assignedTo;
    this.siteInspection.manager = form.manager;
    this.siteInspection.summary = form.summary;
    this.siteInspection.visitDate = form.visitDate;

    this.siteInspectionService.updateSiteInspection(this.siteInspection).subscribe(
      {
        next: response => this.siteInspection = response,
        error: err => console.log('error', err),
        complete: () => {
          if(close){
            this.closeInspection();
          }
        }
      }
    );
  }

  public lockSiteInspection(locked: boolean) : void {
    let confirmation: string = 'Lock this site inspection? If you are not a manager you will lose access to this case.';
    if(!locked){
      confirmation = 'Unlock this site inspection?';
    }
    this.sharedService.openConfirm(confirmation);
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.siteInspectionService.lockSiteInspection(this.siteInspection.id, locked).subscribe({
            error: err => console.log('error', err),
            complete: () => {
              this.siteInspection.locked = locked;
              if(locked){
                this.siteInspectionForm.disable();
              }
              else{
                this.siteInspectionForm.enable();
              }
            }
          });
        }
      }
    )
  }

  public unlockSiteInspection() : void {
    if(this.authService.isAdmin){
      this.lockSiteInspection(false);
    }
    else{
      this.toastr.error("Only a manager can unlock a site inspection");
    }
  }

  public viewFollowup(followup: IFollowup): void {
    this.followup = followup;
    this.editing = !followup.locked;
    this.followupForm.patchValue({
      status: followup.status,
      category: followup.category,
      tier: followup.tier,
      assignedTo: followup.assignedTo,
      manager: followup.manager,
      outcome: followup.outcome,
      locked: followup.locked,
      message: followup.message,
      dateOfInspection: new Date(followup.dateOfInspection)
    });

    this.followup.followupRules.forEach(rule => {
      if(rule.resolvedDate != null && rule.resolvedDate.length > 0)
      rule.resolvedDateFormatted = new Date(rule.resolvedDate);
    });

    if(followup.dateRequirementsMet != null && followup.dateRequirementsMet.length > 0){
      this.followupForm.get('dateRequirementsMet').patchValue(new Date(followup.dateRequirementsMet));
    }
    setTimeout(() => {
      this.notesSource.data = followup.notes;
    }, 500)
  }

  exportFollowup(followup: IFollowup): void {
    this.siteInspectionService.exportFollowup(followup, this.staffReference, this.entityName, this.siteInspection.type);
  }

  exportSiteInspection(siteInspection: ISiteInspection): void {
    this.siteInspectionService.exportSiteInspection(siteInspection, this.staffReference, this.entityName, this.entityType);
  }

  public closeFollowup(): void {
    this.followup = this.getEmptyFollowup();
  }

  private getEmptyFollowup(): IFollowup {
    let emptyFollowup: IFollowup = {
      id: 0,
      caseId: '',
      status: '',
      category: '',
      tier: '',
      assignedTo: '',
      manager: '',
      outcome: '',
      locked: false,
      dateOfInspection: '',
      dateRequirementsMet: '',
      followupRules: [],
      notes: [],
      followupDocuments: [],
      dateCreated: '',
      message: ''
    }
    return emptyFollowup;
  }

  public updateNonCompliantRules(): void {
    this.siteInspectionService.updateNonCompliantRules(this.siteInspection).subscribe(
      response => {
          this.siteInspection.followup.followupRules = response;
          this.toastr.success('Follow Up Rules Updated');
      },
      error => {
        this.toastr.error('Error Updating Followup');
        console.log('error', error)
      },
      () => {
        this.updateExpiredSiteInspectionsTable();
      }
    );
  }

  uploadFollowupDocument(event: Event): void {
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      let upload: IDocument = {
        id: 0,
        name: file.name,
        comments: "",
        extension: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.followup.id,
        types: [],
        deprecated: false,
        pendingUpdate: false,
        adminOnly: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));

      this.siteInspectionService.uploadDocument(formData).subscribe(
        response => this.followup.followupDocuments.unshift(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.toastr.success('Document Uploaded');
        }
      );
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.siteInspectionService.downloadDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.siteInspectionService.deleteDocument(id).subscribe(
            () => {
              this.followup.followupDocuments = this.followup.followupDocuments.filter(item => item.id !== id);
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addRule(){
    this.sharedService.openConfirm("Add a manually entered rule?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.siteInspectionService.addRule(this.followup.id).subscribe(
            response => {
              this.siteInspection.followup.followupRules.push(response);
            },
            error => console.log('error', error)
          );
        }
      }
    );

  }

  public (close: boolean): void {
    this.updateFollowup(close);
  }

  public editFollowup(): void {
    this.editing = !this.editing;
  }

  public lockFollowup(locked: boolean) : void {
    let confirmation: string = 'Lock this followup? If you are not a manager you will lose access to this case.';
    if(!locked){
      confirmation = 'Unlock this followup?';
    }
    this.sharedService.openConfirm(confirmation);
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.siteInspectionService.lockFollowup(this.followup.id, locked).subscribe({
            error: err => console.log('error', err),
            complete: () => {
              this.followup.locked = locked;
              if(locked){
                this.editing = false;
              }
            }
          });
        }
      }
    )
  }

  public unlockFollowup() : void {
    if(this.authService.isAdmin){
      this.lockFollowup(false);
    }
    else{
      this.toastr.error("Only a manager can unlock a followup");
    }
  }

  public viewRuleDetails(rule: IRule): void {
    this.dialog.open(ViewRuleDialogComponent, {
      data: rule,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  public addNoteReference(): void {
    let currentNoteIds: number[] = this.followup.notes.map(note => {return note.id});
    let newNotes: INote[] = this.notes.filter(note => !currentNoteIds.includes(note.id));

    const dialogRef = this.dialog.open(AddNoteReferenceDialogComponent, {
      data: newNotes,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        response.forEach((note: INote) => {
          this.followup.notes.push(note);
        });
        this.siteInspectionService.updateFollowup(this.followup).subscribe({
          error: err => console.log('error', err),
          complete: () => this.notesSource.data = this.followup.notes
        });
      }
    });
  }

  public removeNoteReference(noteId: number): void {
    this.sharedService.openConfirm("Remove the reference to this note?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.followup.notes = this.followup.notes.filter(note => note.id !== noteId);
          this.siteInspectionService.updateFollowup(this.followup).subscribe({
            error: err => console.log('error', err),
            complete: () => this.notesSource.data = this.followup.notes
          });
        }
      }
    )
  }

  public addNewNote(): void {
    let newNote: INote = {
      id: 0,
      archived: false,
      createdBy: '',
      dateCreated: '',
      dateLastUpdated: '',
      entityId: this.entityId.toString(),
      entityType: this.entityType,
      generic: false,
      lastUpdatedBy: '',
      text: ''
    }

    const dialogRef = this.dialog.open(DialogEditNoteComponent, {
      data: newNote,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.followup.notes.push(response);
        this.siteInspectionService.updateFollowup(this.followup).subscribe({
          error: err => console.log('error', err),
          complete: () => this.notesSource.data = this.followup.notes
        });
      }
    });
  }

  public editNote(note: INote): void{
    const dialogRef = this.dialog.open(DialogEditNoteComponent, {
      data: note,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        note = response;
      }
    });
  }

  calculateDays(dateCreated: string): number {
    const today = new Date();
    const dateValue = new Date(dateCreated);
    return Math.floor((today.getTime() - dateValue.getTime()) / (1000 * 60 * 60 * 24));
  }

}
