<section>
  <div fxLayout="row" fxLayoutGap="16px">
    <span class="f-s-20" fxFlex>{{rule.id > 0? 'Edit' : 'Add'}} Rule and/or Statute</span>
    <button mat-raised-button type="button" color="primary" [disabled]="!ruleForm.valid"
      (click)="updateRule()">Save</button>
    <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <form [formGroup]="ruleForm" (ngSubmit)="updateRule()" class="m-t-24">
    <div fxLayout="column">

      <span class="m-b-8">Section</span>
      <mat-checkbox formControlName="general">General</mat-checkbox>
      <mat-checkbox formControlName="trainingPrograms">Training Programs</mat-checkbox>
      <mat-checkbox formControlName="facilitators">Facilitators</mat-checkbox>
      <mat-checkbox formControlName="manufacturers">Manufacturers</mat-checkbox>
      <mat-checkbox formControlName="serviceCenters">Service Centers</mat-checkbox>
      <mat-checkbox formControlName="testingLabs">Testing Labs</mat-checkbox>
      <mat-checkbox formControlName="workerPermits">Worker Permits</mat-checkbox>

      <mat-form-field class="m-t-8">
        <mat-label>Sub-Section</mat-label>
        <mat-select formControlName="subSection">
          <mat-option *ngFor="let subSection of subSections" [value]="subSection.value">{{subSection.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <mat-label>Segment Number</mat-label>
        <input matInput type="number" placeholder="Segment Number" formControlName="segmentNumber">
        <mat-error *ngIf="ruleForm.get('segmentNumber').invalid">
          Please provide the segment number
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Segment</mat-label>
        <input matInput placeholder="Segment" formControlName="segment">
        <mat-error *ngIf="ruleForm.get('segment').invalid">
          Please provide the segment
        </mat-error>
      </mat-form-field>

      <mat-form-field class="m-b-8">
        <mat-label>Summary</mat-label>
        <textarea formControlName="summary" matInput rows="3" placeholder="Summary" type="text"></textarea>
        <mat-error *ngIf="ruleForm.get('summary').invalid">
          Please provide a summary
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Application</mat-label>
        <input matInput placeholder="Application" formControlName="application">
        <mat-error *ngIf="ruleForm.get('application').invalid">
          Please provide the application
        </mat-error>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="questionServiceCenter" matInput rows="3" placeholder="Question Service Center" type="text"></textarea>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="questionManufacturer" matInput rows="3" placeholder="Question Manufacturer" type="text"></textarea>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="questionLaboratory" matInput rows="3" placeholder="Question Laboratory" type="text"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Answer</mat-label>
        <input matInput placeholder="Answer" formControlName="answer">
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="guidanceServiceCenter" matInput rows="3" placeholder="Guidance Service Center" type="text"></textarea>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="guidanceManufacturer" matInput rows="3" placeholder="Guidance Manufacturer" type="text"></textarea>
      </mat-form-field>

      <mat-form-field class="m-t-16">
        <textarea formControlName="guidanceLaboratory" matInput rows="3" placeholder="Guidance Laboratory" type="text"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>If Non-Compliant</mat-label>
        <input matInput placeholder="If Non-Compliant" formControlName="nonCompliant">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Statute</mat-label>
        <input matInput placeholder="Statute" formControlName="statute">
        <mat-error *ngIf="ruleForm.get('statute').invalid">
          Please provide the rule
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="monitorMetric">Metric</mat-checkbox>

      <mat-form-field class="m-t-16">
        <textarea formControlName="notes" matInput rows="3" placeholder="Notes" type="text"></textarea>
      </mat-form-field>

    </div>
  </form>
  <div class="m-t-8">
    <mat-label>Investigation Items</mat-label>
    <mat-select [(ngModel)]="currentItems" multiple>
      <mat-option *ngFor="let item of investigationItems" [value]="item.id">{{item.value}}</mat-option>
    </mat-select>
  </div>
</mat-dialog-content>
