import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ITask } from "app/models/tasks/task";

@Component({
  selector: 'task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss']
})
export class TaskHistoryComponent implements OnInit {
  public taskColumns: string[] = ['task', 'teamMember', 'dateLastUpdated','reason', 'archivedReason'];
  public taskSource = new MatTableDataSource<ITask>(this.tasks);
  @ViewChild('taskPaginator', {static: false}) taskPaginator: MatPaginator;
  @ViewChild('taskSort', {static: false}) taskSort: MatSort;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public tasks: ITask[]) { }

  ngOnInit() {
    setTimeout(() => {
      this.taskSource.data = this.tasks;
      this.taskSource.sort = this.taskSort;
      this.taskSource.paginator = this.taskPaginator;
      this.taskSource.sortingDataAccessor = this.customSortAccessor;
    }, 200);

  }
  cancel(): void {
    this.dialogRef.close('cancel');
  }

  customSortAccessor = (item: any, property: string) => {
    const direction = this.taskSort.direction;

    if (property === 'dateLastUpdated') {
      const dateValue = item.dateLastUpdated ? new Date(item.dateLastUpdated) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };
}
