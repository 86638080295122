import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/services/core/shared.service';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';

@Component({
  selector: 'app-print-manufacturers',
  templateUrl: './print-manufacturers.component.html',
  styleUrls: ['./print-manufacturers.component.scss']
})
export class PrintManufacturerComponent implements OnInit {

  constructor(public sharedService: SharedService,
    public dialogRef: MatDialogRef<PrintManufacturerComponent>,
    @Inject(MAT_DIALOG_DATA) public license: IManufacturerLicense) { }

  ngOnInit(): void {

  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
