<div fxLayout="row">
  <span fxFlex></span>
  <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
</div>
<br/>
<mat-card>
  <mat-card-title>Enter a date range for the De-Identified data being uploaded</mat-card-title>
  <mat-card-content>
    <form [formGroup]="dateForm">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-form-field fxFlex.lt-md="100">
          <mat-label>Begin</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="from" matInput [matDatepicker]="from" />
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="dateForm.get('from').hasError('required')">
            Begin date is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex.lt-md="100">
          <mat-label>End</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="to" matInput [matDatepicker]="to" />
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="dateForm.get('to').hasError('required')">
            End date is required
          </mat-error>
        </mat-form-field>

        <input type="file" class="file-input" #fileUpload (change)="uploadDeIdentifiedData($event)"/>
        <div fxLayout="row" fxLayoutGap="8px" *ngIf="dateForm.valid">
          <div class="file-upload">
            <button mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
              Attach File
              <mat-icon>attach_file</mat-icon>
            </button>
          </div>
        </div>

      </div>
    </form>
  </mat-card-content>
</mat-card>
