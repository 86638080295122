<h2 mat-dialog-title *ngIf="!loading">
  <mat-icon class="icon-20" >history_edu</mat-icon> Change Log
  <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
  <section style="font-family: Oswald; font-size: 12pt;">
    <mat-checkbox  [(ngModel)]="search.displayCompliances">Case</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayNotices">Notices</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayActionItems">Action Items</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayReferencedLicenses">Associated Licenses</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayInvestigations">Investigations</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayCompliancePlans">Compliance Plans</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayNotes">Notes</mat-checkbox>&nbsp;
    <mat-checkbox  [(ngModel)]="search.displayComplaints">Complaints</mat-checkbox>&nbsp;
    <button mat-button (click)="showLogs()" color="primary" aria-label="Show Logs">Show Logs</button>
  </section>
</h2>
<div *ngIf="!loading">
  <mat-card >
      <div fxLayout="column" fxLayoutGap="8px grid">
          <br/>
          <div *ngFor="let log of auditlogs">
            <audit-log-table [auditLog]=log></audit-log-table>
          </div>
        <br />
        <div *ngIf="loading">
          <small>Loading...</small>
          <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
          </mat-progress-bar>
        </div>
        <button mat-button mat-dialog-close="Cancel" aria-label="Close Log">Close</button>
      </div>
  </mat-card>
</div>

<div *ngIf="loading">
  <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
    <span>
      <h1>Loading</h1>
      <mat-spinner [diameter]="30"></mat-spinner>
    </span>
  </div>
</div>
