<div fxLayout="column" *ngIf="applicant.id === 0">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
    <span class="f-s-18"><strong>Individual Applicants/Licensees</strong></span>
    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter Applicants" />
    </mat-form-field>
    <span fxFlex></span>
    <span *ngIf="editingLicense">Click to Edit</span>
    <button mat-icon-button
            *ngIf="editingLicense"
            type="button"
            color="primary"
            (click)="addApplicant()"
            aria-label="Add Applicant"
            matTooltip="Add Applicant"
            matTooltipPosition="below"
            [matTooltipShowDelay]="500">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
    class="mat-elevation-z8 p-t-0">
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">First</span>
        <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{ applicant.legalFirstName }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Last</span>
        {{ applicant.legalLastName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Phone Number</span>
        {{applicant.phone | mask:'(000)-000-0000 000000'}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Email</span>
        {{ applicant.email }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
        <div fxLayout="row" fxLayoutGap="8px">
          <button *ngIf="editingLicense && applicant.applicantId !== primaryContactId && !applicant.archived"
                  mat-flat-button (click)="selectPointOfContact(applicant)" color="primary" aria-label="Select as Point of Contact">
            Make Primary Contact
          </button>
          <span *ngIf="!editingLicense && applicant.archived"><b>Archived</b></span>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
        <div fxLayout="row" fxLayoutGap="8px">
          <button *ngIf="editingLicense && authService.isAdmin" mat-icon-button (click)="deleteApplicant(applicant)" color="warn" aria-label="Delete Applicant">
            <mat-icon>delete</mat-icon>
          </button>
          <button *ngIf="editingLicense && !applicant.archived" mat-icon-button (click)="archiveApplicant(applicant, true)" color="warn" aria-label="Archive Applicant">
            <mat-icon>archive</mat-icon>
          </button>
          <button *ngIf="editingLicense && applicant.archived" mat-icon-button (click)="archiveApplicant(applicant, false)" color="primary" aria-label="Archive Applicant">
            <mat-icon>unarchive</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="duplicate">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label"></span>
        <mat-icon *ngIf="applicant.possibleDuplicate" matTooltip="Possible Duplicate Applicant" matTooltipShowDelay=100 color="warn">error_outline</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="checkDuplicate">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <button mat-button type="button" color="primary" *ngIf="!loadingDuplicates" (click)="checkDuplicates(applicant.id)">Check Duplicates</button>
        <mat-spinner *ngIf="loadingDuplicates" [diameter]="30"></mat-spinner>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns" [ngClass]="{'archived': row.archived }"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
  </mat-paginator>
</div>

<mat-card [style.background-color]="cardColor" *ngIf="applicant.id > 0">
  <mat-card-title>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <app-duplicate-applicant [fxFlex]="dedeuplicating? 100 : 2"
      [currentApplicant]="applicant"
      (notifyParent)="deduplicateApplicant($event)">
      </app-duplicate-applicant>
      <span  *ngIf="!dedeuplicating">{{addingApplicant? 'Add New Applicant' : 'Editing ' + applicant.legalFirstName + ' ' + applicant.legalLastName}}</span>
      <span *ngIf="!dedeuplicating && applicant.archived"><b>Archived</b></span>
      <span fxFlex></span>

      <div *ngIf="!dedeuplicating" fxLayoutGap="8px">
      <button mat-flat-button type="button" color="warn" (click)="cancel()">{{addingApplicant? 'Cancel' : 'Close'}}</button>
      <button mat-flat-button type="button" color="primary"
        (click)="saveApplicant(false)">Save</button>
      <button mat-flat-button type="button" color="primary"
        (click)="saveApplicant(true)">Save and Close</button>
      </div>
    </div>
  </mat-card-title>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
    <div fxLayout="column">
      <span *ngIf="applicant.duplicate" style="color:red"><em>Duplicate Applicant</em></span>
      <span><button fxFlex="10" mat-button type="button" color="primary" *ngIf="applicant.duplicate" (click)="ViewParent()">View Parent Applicant</button></span>
    </div>
  </div>
  <mat-card-content *ngIf="!dedeuplicating">
    <!--Applicant Form-->
    <form [formGroup]="applicantForm">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Personal Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <div fxLayout="row" fxLayout.lt-lg="column">
                <span fxFlex>Personal Information</span>
              </div>
            </mat-card-title>
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="35">
                    <span><strong>Legal Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span style="overflow: hidden;">
                        <span>{{applicant.legalFirstName}} </span>
                        <span>{{applicant.legalMiddleName}} </span>
                        <span>{{applicant.legalLastName}}</span>
                      </span>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="35">
                    <span><strong>Preferred Name</strong></span>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <span style="overflow: hidden;">
                        <span [ngClass]="{'updated-field': isFieldUpdated('PreferredFirstName', 'Applicants', applicant.id.toString())}">{{applicant.preferredFirstName}} </span>
                        <span [ngClass]="{'updated-field': isFieldUpdated('PreferredMiddleName', 'Applicants', applicant.id.toString())}">{{applicant.preferredMiddleName}} </span>
                        <span [ngClass]="{'updated-field': isFieldUpdated('PreferredLastName', 'Applicants', applicant.id.toString())}">{{applicant.preferredLastName}}</span>
                      </span>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Other Names or Aliases</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('AlternateNames', 'Applicants', applicant.id.toString())}">{{applicant.alternateNames}}</span>
                  </div>
                </div>

                <div fxLayout="column">
                  <span><strong>DOB</strong></span>
                  <span>{{applicant.dob | date}}</span>
                </div>

                <div fxLayout="column">
                  <span><strong>Age</strong></span>
                  <span>{{sharedService.ageCalculator(applicant.dob)}}</span>
                </div>

                <div fxLayout="column" fxLayoutGap="16px" fxFlex="100">
                  <div fxLayout="column">
                    <span><strong>SSN</strong></span>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                      fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <span>{{applicant.ssn | mask:"000-00-0000"}}</span>
                      <mat-checkbox [checked]="applicant.noSSN" disabled>
                        Applicant has no SSN
                      </mat-checkbox>
                    </div>
                    <mat-checkbox [checked]="applicant.ssnAttestation" disabled>
                      <span class="text-wrap">
                        Applicant consents to use of SSN
                      </span>
                    </mat-checkbox>
                    <mat-checkbox [checked]="applicant.changeInConvictionHistory" *ngIf="renewal" disabled>
                      <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('ChangeInConvictionHistory', 'Applicants', applicant.id.toString())}">
                        Applicant has had a change in conviction history
                      </span>
                    </mat-checkbox>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <mat-checkbox fxFlex="40" [checked]="applicant.backgroundCheckPassed" disabled>
                    Background Check Passed
                  </mat-checkbox>

                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Initiated Date</strong></span>
                    <span>{{applicant.backgroundCheckInitiatedDate | date}}</span>
                  </div>

                  <div fxLayout="column" fxFlex="30">
                    <span><strong>Background Check Completed Date</strong></span>
                    <span>{{applicant.backgroundCheckDate | date}}</span>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <div fxLayout="column" fxFlex="20">
                    <span><strong>Finger Prints Taken On</strong></span>
                    <span>{{applicant.fingerPrintsDate | date}}</span>
                  </div>

                  <div fxLayout="column" fxFlex="20">
                    <span><strong>Finger Prints Expire On</strong></span>
                    <span>{{applicant.fingerPrintsExpirationDate | date}}</span>
                  </div>

                  <mat-checkbox fxFlex="30" [checked]="applicant.stateOnly" disabled>
                    State Only Background Check
                  </mat-checkbox>

                  <mat-checkbox fxFlex="30" [checked]="applicant.fullBackGroundCheck" disabled>
                    Full Background Check
                  </mat-checkbox>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
                    <span><strong>Background Check Notes</strong></span>
                    <textarea matInput cols="2" [value]="applicant.backgroundCheckNotes" placeholder="Background Check Notes" disabled></textarea>
                  </div>
                </div>


                <div fxLayout="row" fxLayoutGap="8px" *ngIf="renewal">
                   <div fxLayout="column" fxLayoutGap="8px">
                    <span *ngIf="applicantForm.get('applicantPageCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'Applicants', applicant.id.toString())}">Applicant attests this information to be current and valid</strong></span>
                    <span *ngIf="!applicantForm.get('applicantPageCorrect').value"><strong [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'Applicants', applicant.id.toString())}">Applicant does not attest this information to be current and valid</strong></span>
                    <textarea *ngIf="!applicantForm.get('applicantPageCorrect').value" matInput placeholder="Applicant Changes" cols="2" [value]="applicant.applicantPageCorrectReason" [readonly]="true" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrectReason', 'Applicants', applicant.id.toString())}"></textarea>
                   </div>
                </div>
                <mat-card  fxLayoutGap="12px">
                  <mat-card-title>
                    Additional Applicant Questions
                  </mat-card-title>
                  <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="!applicant.heldLicense && !applicant.deniedLicense && !applicant.subjectToDiscipline && !applicant.suedForDamages && !applicant.settledAllegations && !applicant.allegedAbuse">
                      <span><strong>Applicant answered no to all additional questions.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.heldLicense">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('HeldLicense', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has held a license in Oregon or in another state or country.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.deniedLicense">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('DeniedLicense', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been denied a license.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.subjectToDiscipline">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('SubjectToDiscipline', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been subject to discipline by the entity that issued their license or had their license suspended or revoked.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.suedForDamages">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('SuedForDamages', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been sued for money damages related to work they provided under a license.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.settledAllegations">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('SettledAllegations', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has settled allegations or claims against them related to a license.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.allegedAbuse">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('AllegedAbuse', 'Applicants', applicant.id.toString())}">Applicant has had someone allege that they abused a child or an adult.</strong></span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" *ngIf="applicant.substantiatedAllegation">
                      <span><strong [ngClass]="{'updated-field': isFieldUpdated('SubstantiatedAllegation', 'Applicants', applicant.id.toString())}">Allegations were substantiated</strong></span>
                    </div>
                  </div>
                </mat-card>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Legal Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="legalFirstName">
                    <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                      Please enter your first name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="legalLastName">
                    <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                      Please enter your last name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="legalMiddleName">
                  </mat-form-field>
                </div>

                <span><strong>Preferred Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="preferredFirstName" [ngClass]="{'updated-field': isFieldUpdated('PreferredFirstName', 'Applicants', applicant.id.toString())}">
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="preferredLastName" [ngClass]="{'updated-field': isFieldUpdated('PreferredLastName', 'Applicants', applicant.id.toString())}">
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="preferredMiddleName" [ngClass]="{'updated-field': isFieldUpdated('PreferredMiddleName', 'Applicants', applicant.id.toString())}">
                  </mat-form-field>
                </div>

                <div fxLayout="column">
                  <span><strong>Please provide any other names you have used</strong></span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                    <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                      <input matInput placeholder="Name, other name, any other names" formControlName="alternateNames" [ngClass]="{'updated-field': isFieldUpdated('AlternateNames', 'Applicants', applicant.id.toString())}">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Date of Birth</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                        [max]="sharedService.today" />
                      <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                      <mat-datepicker #dob>
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                        Date of birth is required
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>

                <div fxLayout="column">

                  <span>Social Security Number Disclosure</span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                    fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                      <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                      <mat-error *ngIf="validateSsn()">
                        SSN is Required
                      </mat-error>
                    </mat-form-field>
                    <mat-checkbox formControlName="noSsn">
                      Applicant has no SSN
                    </mat-checkbox>
                  </div>
                  <mat-checkbox formControlName="ssnAttestation" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                    <span class="text-wrap">
                      Applicant consents to use of SSN
                    </span>
                  </mat-checkbox>
                  <mat-checkbox formControlName="changeInConvictionHistory" *ngIf="renewal">
                    <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('ChangeInConvictionHistory', 'Applicants', applicant.id.toString())}">
                      Has there been a change in criminal conviction history in the last year?
                    </span>
                  </mat-checkbox>

                  <div fxLayout="column" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-checkbox fxFlex="35" formControlName="backgroundCheckPassed">
                        Background Check Passed
                      </mat-checkbox>
                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Initiated Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckInitiatedDate" matInput [matDatepicker]="backgroundCheckInitiatedDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckInitiatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckInitiatedDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckInitiatedDate').hasError('required')">
                          Background Check Initiated Date is required
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="25">
                        <mat-label>Background Check Completed Date</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="backgroundCheckDate"></mat-datepicker-toggle>
                        <mat-datepicker #backgroundCheckDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('backgroundCheckDate').hasError('required')">
                          Background Check Completed Date is required
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="30">
                        <mat-label>Finger Prints Taken On</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="fingerPrintsDate" matInput [matDatepicker]="fingerPrintsDate"
                          [max]="today" />
                        <mat-datepicker-toggle matSuffix [for]="fingerPrintsDate"></mat-datepicker-toggle>
                        <mat-datepicker #fingerPrintsDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('fingerPrintsDate').value > today">
                          Please choose an earlier date.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30">
                        <mat-label>Finger Prints Expire On</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="fingerPrintsExpirationDate" matInput [matDatepicker]="fingerPrintsExpirationDate"/>
                        <mat-datepicker-toggle matSuffix [for]="fingerPrintsExpirationDate"></mat-datepicker-toggle>
                        <mat-datepicker #fingerPrintsExpirationDate>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('fingerPrintsExpirationDate').value < today">
                          Please choose an earlier date.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <mat-checkbox fxFlex="35" formControlName="stateOnly">
                        State Only Background Check
                      </mat-checkbox>

                      <mat-checkbox fxFlex="35" formControlName="fullBackGroundCheck">
                        Full Background Check
                      </mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                      <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
                        <span><strong>Background Check Notes</strong></span>
                        <textarea matInput cols="2" formControlName="backgroundCheckNotes" placeholder="Background Check Notes"></textarea>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <button mat-raised-button color="primary" (click)="deprecateBackgroundCheck()">Archive Background Check</button>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <div fxLayout="column" fxFlex="100">
                        <mat-table [dataSource]="backgroundCheckSource" matSort aria-describedby="Background Checks" #backgroundCheckSort="matSort" class="mat-elevation-z8 m-b-0">
                          <ng-container matColumnDef="backgroundCheckInitiatedDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>BCU Initiated Date</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span>{{background.backgroundCheckInitiatedDate | date}}</span>
                              </div>
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="backgroundCheckDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>BCU Date</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span>{{background.backgroundCheckDate | date}}</span>
                              </div>
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="fingerPrintsDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Finger Prints Date</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span>{{background.fingerPrintsDate | date}}</span>
                              </div>
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="fingerPrintsExpirationDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Finger Prints Expire On</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span>{{background.fingerPrintsExpirationDate | date}}</span>
                              </div>
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="backgroundCheckPassed">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>BCU Status</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span *ngIf="background.backgroundCheckPassed" style="color:green">Passed</span>
                                <span *ngIf="!background.backgroundCheckPassed" style="color:red"> Not Passed</span>
                              </div>
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="backgroundType">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>BCU Type</mat-header-cell>
                            <mat-cell *matCellDef="let background">
                              <div fxLayout="column" fxLayoutGap="8px">
                                <span *ngIf="background.stateOnly">State Only</span>
                                <span *ngIf="background.fullBackGroundCheck">Full BCU</span>
                              </div>
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="backgroundCheckColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: backgroundCheckColumns"></mat-row>
                        </mat-table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #backgroundCheckPaginator="matPaginator">
                        </mat-paginator>
                      </div>
                    </div>

                    <mat-checkbox formControlName="applicantPageCorrect" *ngIf="renewal" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrect', 'Applicants', applicant.id.toString())}">
                      Applicant attests this information to be current and valid
                    </mat-checkbox>
                     <span *ngIf="!applicantForm.get('applicantPageCorrect').value && renewal">Changes</span>
                     <textarea *ngIf="!applicantForm.get('applicantPageCorrect').value && renewal" matInput placeholder="Applicant Changes" cols="2" formControlName="applicantPageCorrectReason" [ngClass]="{'updated-field': isFieldUpdated('ApplicantPageCorrectReason', 'Applicants', applicant.id.toString())}"></textarea>

                    <mat-card>
                      <mat-card-title>
                        Additional Applicant Questions
                      </mat-card-title>
                      <div fxLayout="column">
                        <mat-checkbox  formControlName="heldLicense" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('HeldLicense', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has held a license in Oregon or in another state or country.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="deniedLicense" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('DeniedLicense', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been denied a license.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="subjectToDiscipline" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('SubjectToDiscipline', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been subject to discipline by the entity that issued their license or had their license suspended or revoked.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="suedForDamages" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('SuedForDamages', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has been sued for money damages related to work they provided under a license.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="settledAllegations" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('SettledAllegations', 'Applicants', applicant.id.toString())}">In the last 10 years, Applicant has settled allegations or claims against them related to a license.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="allegedAbuse" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('AllegedAbuse', 'Applicants', applicant.id.toString())}">Applicant has had someone allege that they abused a child or an adult.</span>
                        </mat-checkbox>
                        <mat-checkbox  formControlName="substantiatedAllegation" class="m-b-8" [ngClass]="{'disable-pointer-events': !authService.isAdmin && !authService.isTestingLabAdmin && !authService.isManufacturerAdmin && !authService.isServiceCenterAdmin}">
                          <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('SubstantiatedAllegation', 'Applicants', applicant.id.toString())}">Allegations were substantiated.</span>
                        </mat-checkbox>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Contact Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Contact Information
            </mat-card-title>
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <span  [ngClass]="{'updated-field': isFieldUpdated('Phone', 'Applicants', applicant.id.toString())}">{{applicant.phone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('AlternatePhone', 'Applicants', applicant.id.toString())}">{{applicant.alternatePhone | mask:'(000)-000-0000 000000'}}</span>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('Email', 'Applicants', applicant.id.toString())}">{{applicant.email}}</span>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <span>{{applicant.language}}</span>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                  <mat-checkbox [checked]="applicant.previousLicense" disabled>
                    <span class="text-wrap" [ngClass]="{'updated-field': isFieldUpdated('PreviousLicense', 'Applicants', applicant.id.toString())}">Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="applicant.previousLicense">
                    <span><strong>Other Oregon Psilocybin licenses</strong></span>
                    <span [ngClass]="{'updated-field': isFieldUpdated('AffiliatedLicenses', 'Applicants', applicant.id.toString())}">{{applicant.affiliatedLicenses}}</span>
                  </div>
                </div>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000" [ngClass]="{'updated-field': isFieldUpdated('Phone', 'Applicants', applicant.id.toString())}"
                        [validation]="false">
                      <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                        A primary phone number is required
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Alt Phone" formControlName="alternatePhone" [ngClass]="{'updated-field': isFieldUpdated('AlternatePhone', 'Applicants', applicant.id.toString())}"
                        mask="(000) 000-0000 000000" [validation]="false">
                      <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Email" formControlName="email" [ngClass]="{'updated-field': isFieldUpdated('Email', 'Applicants', applicant.id.toString())}">
                      <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                        Please enter an email adress
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Confirm Email" formControlName="confirmEmail" [ngClass]="{'updated-field': isFieldUpdated('ConfirmEmail', 'Applicants', applicant.id.toString())}">
                      <mat-error *ngIf="validateEmail()">
                        Email does not match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Language" formControlName="language">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                  <mat-checkbox formControlName="previousLicense">
                    <span class="text-wrap">
                      Has the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services?</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Please provide the license ids for each Oregon Psilocybin license</span>
                    <mat-form-field>
                      <input matInput placeholder="Affiliated Licenses" formControlName="affiliatedLicenses"
                        (blur)="validatePreviousLicense()">
                      <mat-error *ngIf="validatePreviousLicense()">
                        Please provide all license numbers
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <!--Addresses-->
          <mat-card fxFlex="55" fxFlex.lt-md="100">
            <mat-card-title>Address</mat-card-title>
            <mat-card-content *ngIf="!editingLicense">
              <div fxLayout="row" fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">
                  <div fxLayout="column">
                    <span><strong>Physical Address</strong></span>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalStreet', 'Applicants', applicant.id.toString())}">{{applicant.physicalStreet }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalCity', 'Applicants', applicant.id.toString())}">{{applicant.physicalCity }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalState', 'Applicants', applicant.id.toString())}">{{applicant.physicalState }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalZip', 'Applicants', applicant.id.toString())}">{{applicant.physicalZip }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('PhysicalCounty', 'Applicants', applicant.id.toString())}">{{applicant.physicalCounty }}</span>
                    </div>
                  </div>

                  <div fxLayout="column" class="m-t-8">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
                      <span><strong>Mailing Address</strong></span>
                      <mat-checkbox [checked]="applicant.mailingIsPhysical" disabled [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'Applicants', applicant.id.toString())}">
                        Same as physical
                      </mat-checkbox>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutGap="16px" [@slideInOut] *ngIf="!applicant.mailingIsPhysical">
                      <span [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'Applicants', applicant.id.toString())}">{{applicant.mailingStreet }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'Applicants', applicant.id.toString())}">{{applicant.mailingCity }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'Applicants', applicant.id.toString())}">{{applicant.mailingState }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'Applicants', applicant.id.toString())}">{{applicant.mailingZip }}</span>
                      <span [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'Applicants', applicant.id.toString())}">{{applicant.mailingCounty }}</span>
                    </div>
                  </div>

                  <div fxLayout="column" fxLayoutGap="8px" *ngIf="showOregonResidency(applicationDate)">
                    <span><strong>Oregon Residency</strong></span>
                    <mat-checkbox [checked]="applicant.oregonResidency" disabled>
                      <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                    </mat-checkbox>
                  </div>
                </div>

                <app-residential-history [applicantId]="applicant.id"
                                         [residentialHistory]="applicant.residentialHistory"
                                         [editingLicense]="editingLicense"
                                         fxFlex="50">
                </app-residential-history>
              </div>
            </mat-card-content>

            <mat-card-content *ngIf="editingLicense">
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="column">
                  <span><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet" [ngClass]="{'updated-field': isFieldUpdated('PhysicalStreet', 'Applicants', applicant.id.toString())}">
                      <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="physicalCity" [ngClass]="{'updated-field': isFieldUpdated('PhysicalCity', 'Applicants', applicant.id.toString())}">
                      <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState" [ngClass]="{'updated-field': isFieldUpdated('PhysicalState', 'Applicants', applicant.id.toString())}">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                        Please select a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="physicalZip" [ngClass]="{'updated-field': isFieldUpdated('PhysicalZip', 'Applicants', applicant.id.toString())}">
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="physicalCounty" [ngClass]="{'updated-field': isFieldUpdated('PhysicalCounty', 'Applicants', applicant.id.toString())}">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()" [ngClass]="{'updated-field': isFieldUpdated('MailingIsPhysical', 'Applicants', applicant.id.toString())}">
                      Same as physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet" [ngClass]="{'updated-field': isFieldUpdated('MailingStreet', 'Applicants', applicant.id.toString())}"
                          (blur)="validateMailingStreet()">
                        <mat-error *ngIf="validateMailingStreet()">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()" [ngClass]="{'updated-field': isFieldUpdated('MailingCity', 'Applicants', applicant.id.toString())}">
                        <mat-error *ngIf="validateMailingCity()">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="mailingState" (blur)="validateMailingState()" [ngClass]="{'updated-field': isFieldUpdated('MailingState', 'Applicants', applicant.id.toString())}">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="validateMailingState()">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()" [ngClass]="{'updated-field': isFieldUpdated('MailingZip', 'Applicants', applicant.id.toString())}">
                        <mat-error *ngIf="validateMailingZip()">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                        *ngIf="applicantForm.get('mailingState').value === 'OR'">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="mailingCounty" [ngClass]="{'updated-field': isFieldUpdated('MailingCounty', 'Applicants', applicant.id.toString())}">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" *ngIf="showOregonResidency(applicationDate)">
                  <span class="f-s-18"><strong>Oregon Residency</strong></span>
                  <mat-checkbox formControlName="oregonResidency">
                    <span class="text-wrap">Applicant meets the Oregon residency requirements</span>
                  </mat-checkbox>
                </div>

                <app-residential-history [applicantId]="applicant.id"
                                         [editingLicense]="editingLicense"
                                         [residentialHistory]="applicant.residentialHistory">
                </app-residential-history>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Documents-->
          <div fxLayout="column" fxFlex="45" fxFlex.lt-md="100" fxLayoutGap="8px">
            <!--Upload-->
            <mat-card *ngIf="editingLicense">
              <mat-card-title>
                <span *ngIf="documentsValid">
                  Supporting Documents
                </span>
                <span *ngIf="!documentsValid" class="text-red-400">
                  Supporting Documents Incomplete
                </span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap.lt-lg="8px">
                  <span>You must provide proof of your identity and that you are at least 21</span>
                  <span>You can upload a separate document for each requirement or combine multiple documents into a
                    single file for upload</span>

                  <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                    <div fxLayout="column">
                      <span class="f-2-14">For each document select the requirements it verifies</span>
                      <mat-checkbox formControlName="identification">Identification</mat-checkbox>
                      <mat-checkbox formControlName="applicantInfo">Applicant Information Form</mat-checkbox>
                      <mat-checkbox formControlName="other">Other</mat-checkbox>
                      <mat-checkbox formControlName="physicalApplication">Physical Application</mat-checkbox>

                      <mat-checkbox formControlName="deniedLicenseDocument">Previous Denied License Information</mat-checkbox>
                      <mat-checkbox formControlName="subjectToDisciplineDocument">Previous License Discipline or Revocation Information</mat-checkbox>
                      <mat-checkbox formControlName="suedForDamagesDocument">Previously Sued Information</mat-checkbox>
                      <mat-checkbox formControlName="settledAllegationsDocument">Previous License Allegations or Claims</mat-checkbox>
                      <mat-checkbox formControlName="allegedAbuseDocument">Previous Alleged Abuse of Child or Adult</mat-checkbox>
                    </div>

                    <input type="file" class="file-input" (change)="uploadDocument($event)" #applicantFileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!applicantForm.get('identification').value &&
                                            !applicantForm.get('applicantInfo').value &&
                                            !applicantForm.get('other').value &&
                                            !applicantForm.get('physicalApplication').value &&
                                            !applicantForm.get('deniedLicenseDocument').value &&
                                            !applicantForm.get('subjectToDisciplineDocument').value &&
                                            !applicantForm.get('suedForDamagesDocument').value &&
                                            !applicantForm.get('settledAllegationsDocument').value &&
                                            !applicantForm.get('allegedAbuseDocument').value"
                          type="button" mat-raised-button color="primary" class="upload-btn"
                          (click)="applicantFileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Current-->
            <mat-card>
              <mat-card-title>
                <span *ngIf="documentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Requirement</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                    <span class="f-s-16" fxFlex="10" *ngIf="editingLicense"><strong>Archive</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments" style="justify-content: center;">
                    <ng-container *ngIf="!document.deprecated">
                      <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" [matTooltip]="document.name" matTooltipShowDelay=500
                        (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                       <div fxFlex="20" fxFlex.lt-md="" fxLayout="column">
                         <span *ngFor="let type of document.types">
                           {{documentTypeReference[type]}}
                         </span>
                       </div>
                       <span fxFlex="20">{{document.dateCreated | date}}</span>

                      <button fxFlex="10" [hidden]="!editingLicense || (!authService.isAdmin && !admin)" mat-icon-button color="warn"  fxLayoutAlign="start center" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                        <mat-icon>archive</mat-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="row">
              <button type="button" color="primary" mat-flat-button (click)="viewArchivedDocuments()">
                <span *ngIf="!viewArchived">
                  View Archived Documents
                </span>
                <span *ngIf="viewArchived">
                  Hide Archived Documents
                </span>
              </button>
            </div>

            <!--Archived-->
            <mat-card *ngIf="viewArchived" [style.background]="'#c7cdd1'">
              <mat-card-title>
                <span *ngIf="documentsValid">Archived Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16" fxFlex="25"><strong>Requirement</strong></span>
                    <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                    <span class="f-s-16" fxFlex="10" *ngIf="editingLicense"><strong>Unarchive</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments" style="justify-content: center;">
                    <ng-container *ngIf="document.deprecated">
                      <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" [matTooltip]="document.name" matTooltipShowDelay=500
                        (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                       <div fxFlex="25" fxFlex.lt-md="" fxLayout="column">
                         <span *ngFor="let type of document.types">
                           {{documentTypeReference[type]}}
                         </span>
                       </div>
                       <span fxFlex="20">{{document.dateCreated | date}}</span>

                      <button fxFlex="10" [hidden]="!editingLicense || !authService.isAdmin" mat-icon-button color="primary"  fxLayoutAlign="start center" aria-label="Archive Document" (click)="deprecateDocument(document.id)">
                        <mat-icon>unarchive</mat-icon>
                      </button>

                      <span *ngIf="editingLicense" fxFlex></span>

                      <span fxLayoutAlign="start end" *ngIf="editingLicense && authService.isAdmin">
                        <button  type="button" mat-icon-button class="m-8"
                          (click)="deleteFile(document.id, document.name)" color="warn" aria-label="Delete">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

      </div>
    </form>
  </mat-card-content>
</mat-card>
