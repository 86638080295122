<mat-card>
    <mat-card-title>
      Active Application Tracking
    </mat-card-title>
    <div fxLayout="column" *ngIf="!loadingWorkerPermit && !loadingTrainingProgram && !loadingManufacturer && !loadingFacilitator && !loadingServiceCenter && !loadingTestinglab ">
      <span>Active Licenses: {{activeLicenseTotal}}</span>
      <span>Renewal Licenses: {{renewalLicenseTotal}}</span>
    </div>
    <mat-tab-group animationDuration="150ms" [selectedIndex]="selectedIndex" (selectedIndexChange)="changeStep($event)">

      <mat-tab label="Worker Permits" [hidden]="loadingWorkerPermit">
        <mat-form-field>
          <input matInput (keyup)="filterWorkerPermitTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="workerPermitSource" matSort aria-describedby="licenses" #workerPermitSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Permit</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
              <span class="mobile-label">Permit</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Permit Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
              <span class="mobile-label">Permit Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignWorkerPermit($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of workerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckInitiatedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Initiated Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Completed On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Completed On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckExpiryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Expires On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckExpiryDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Permit Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Permit Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="workerPermitColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: workerPermitColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #workerPermitPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingWorkerPermit">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Training Programs" [hidden]="loadingTrainingProgram">
        <mat-form-field>
          <input matInput (keyup)="filterTrainingProgramTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="trainingSource" matSort aria-describedby="licenses" #trainingSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Program</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'training' + '/assigned', license.id]">
              <span class="mobile-label">Program</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Program Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'training' + '/assigned', license.id]">
              <span class="mobile-label">Program Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="applicationFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Application Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.applicationFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignTrainingProgram($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of trainingStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Program Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Program Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="trainingColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: trainingColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #trainingPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingTrainingProgram">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Facilitators" [hidden]="loadingFacilitator">
        <mat-form-field>
          <input matInput (keyup)="filterFacilitatorTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="facilitatorSource" matSort aria-describedby="licenses" #facilitatorSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Facilitator License</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
              <span class="mobile-label">Facilitator License</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="applicationFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Application Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.applicationFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignFacilitatorLicense($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of trainingStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckInitiatedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Initiated Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Completed On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Completed On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckExpiryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Expires On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckExpiryDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="facilitatorColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: facilitatorColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #facilitatorPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingFacilitator">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Manufacturers" [hidden]="loadingManufacturer">
        <mat-form-field>
          <input matInput (keyup)="filterManufacturerTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="manufacturerSource" matSort aria-describedby="licenses" #manufacturerSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer License</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
              <span class="mobile-label">Manufacturer License</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="applicationFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Application Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.applicationFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignManufacturerLicense($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckInitiatedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Initiated Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Completed Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckExpiryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Expires On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckExpiryDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="complianceAssignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Compliance Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.complianceAssignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="complianceAssignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Compliance Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberManufacturer($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="inspectionCompletedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Inspection Last Completed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.inspectionCompletedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="manufacturerColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: manufacturerColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #manufacturerPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingManufacturer">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Service Centers" [hidden]="loadingServiceCenter">

        <mat-form-field>
          <input matInput (keyup)="filterServiceCenterTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="serviceCenterSource" matSort aria-describedby="licenses" #serviceCenterSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Service Center License</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
              <span class="mobile-label">Service Center License</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="applicationFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Application Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.applicationFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignServiceCenterLicense($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckInitiatedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Initiated Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Completed Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckExpiryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Expires On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckExpiryDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="complianceAssignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Compliance Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.complianceAssignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="complianceAssignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Compliance Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberServiceCenter($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="inspectionCompletedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Inspection Last Completed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.inspectionCompletedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="serviceCenterColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: serviceCenterColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #serviceCenterPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingServiceCenter">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Laboratories" [hidden]="loadingTestinglab">

        <mat-form-field>
          <input matInput (keyup)="filterTestingLabTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="testingLabSource" matSort aria-describedby="licenses" #testingLabSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Laboratory License</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
              <span class="mobile-label">Laboratory License</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="applicationFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Application Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.applicationFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Team Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.assignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.assignedTo" (selectionChange)="assignTestingLabLicense($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckInitiatedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Initiated Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Checks Completed Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backgroundCheckExpiryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Background Check Expires On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.backgroundCheckExpiryDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="complianceAssignedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Compliance Member Assigned On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.complianceAssignedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="complianceAssignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
            <mat-cell *matCellDef="let license" style="justify-content: normal">
              <span class="mobile-label">Assigned To Compliance Member</span>
              <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                 <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberTestingLab($event, license.id)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="inspectionCompletedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Inspection Last Completed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.inspectionCompletedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseFeeClearedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Fee Cleared On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.licenseFeeClearedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="testingLabColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: testingLabColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #testingLabPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingTestinglab">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Expired" [hidden]="loadingExpiredLicenses">
        <mat-form-field>
          <input matInput (keyup)="filterExpiredTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="expiredSource" matSort aria-describedby="licenses" #expiredSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License</span>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'WorkerPermit'" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'TrainingProgram'" [routerLink]="['/' + 'training' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'Facilitator'" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'Manufacturer'" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'ServiceCenter'" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'TestingLab'" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                <span>{{license.licenseName}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'WorkerPermit'" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'TrainingProgram'" [routerLink]="['/' + 'training' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'Facilitator'" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'Manufacturer'" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'ServiceCenter'" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="license.entityType == 'TestingLab'" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="submittedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Submitted On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.submittedDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enteredByStaff">
            <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">TLC or Mailed</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span *ngIf="license.enteredByStaff">Mailed</span>
                <span *ngIf="!license.enteredByStaff">TLC</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expiredDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Expired On</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Expired On</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.expirationDate | date }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Status</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{ license.status }}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="expiredColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: expiredColumns" fxLayoutGap="12px"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #expiredPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingExpiredLicenses">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Active Renewals" [hidden]="loadingTestinglab || loadingServiceCenter || loadingManufacturer || loadingFacilitator">

        <mat-tab-group animationDuration="150ms" [selectedIndex]="selectedRenewalIndex" (selectedIndexChange)="changeRenewalStep($event)" fxLayoutGap="32px">

          <mat-tab label="Facilitators" [hidden]="loadingFacilitator">
            <mat-form-field>
              <input matInput (keyup)="filterFacilitatorRenewalTable($event)" placeholder="Filter" />
            </mat-form-field>
            <mat-table [dataSource]="facilitatorRenewalSource" matSort aria-describedby="licenses" #facilitatorRenewalSort="matSort" class="mat-elevation-z8 m-b-0">

              <ng-container matColumnDef="licenseName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Facilitator License</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                  <span class="mobile-label">Facilitator License</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseName}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseId">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                  <span class="mobile-label">License Id</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseId}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="submittedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Submitted On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.submittedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="enteredByStaff">
                <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">TLC or Mailed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span *ngIf="license.enteredByStaff">Mailed</span>
                    <span *ngIf="!license.enteredByStaff">TLC</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="applicationFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Application Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.applicationFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="assignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Team Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.assignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.assignedTo" (selectionChange)="assignFacilitatorLicense($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of trainingStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckInitiatedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Initiated Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Completed On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Completed On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckExpiryDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Expires On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckExpiryDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">License Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.licenseFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Status</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.status }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="managerFlag">
                <mat-header-cell *matHeaderCellDef mat-sort-header="">
                  Alerts
                </mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <div fxLayout="row" fxLayoutGap="12px">
                    <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                    <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                    <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                    <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="facilitatorColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: facilitatorColumns" fxLayoutGap="12px"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" #facilitatorRenewalPaginator="matPaginator">
            </mat-paginator>

            <div *ngIf="loadingFacilitator">
              <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
                <h1>Loading</h1>
              </div>
            </div>

          </mat-tab>

          <mat-tab label="Manufacturers" [hidden]="loadingManufacturer">
            <mat-form-field>
              <input matInput (keyup)="filterManufacturerRenewalTable($event)" placeholder="Filter" />
            </mat-form-field>
            <mat-table [dataSource]="manufacturerRenewalSource" matSort aria-describedby="licenses" #manufacturerRenewalSort="matSort" class="mat-elevation-z8 m-b-0">

              <ng-container matColumnDef="licenseName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer License</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                  <span class="mobile-label">Manufacturer License</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseName}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseId">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                  <span class="mobile-label">License Id</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseId}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="submittedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Submitted On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.submittedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="enteredByStaff">
                <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">TLC or Mailed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span *ngIf="license.enteredByStaff">Mailed</span>
                    <span *ngIf="!license.enteredByStaff">TLC</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="applicationFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Application Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.applicationFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="assignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Team Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.assignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.assignedTo" (selectionChange)="assignManufacturerLicense($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckInitiatedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Initiated Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Completed Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckExpiryDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Expires On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckExpiryDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="complianceAssignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Compliance Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.complianceAssignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="complianceAssignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Compliance Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberManufacturer($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="inspectionCompletedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Inspection Last Completed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.inspectionCompletedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">License Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.licenseFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Status</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.status }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="managerFlag">
                <mat-header-cell *matHeaderCellDef mat-sort-header="">
                  Alerts
                </mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <div fxLayout="row" fxLayoutGap="12px">
                    <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                    <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                    <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                    <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="manufacturerColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: manufacturerColumns" fxLayoutGap="12px"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" #manufacturerRenewalPaginator="matPaginator">
            </mat-paginator>

            <div *ngIf="loadingManufacturer">
              <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
                <h1>Loading</h1>
              </div>
            </div>

          </mat-tab>

          <mat-tab label="Service Centers" [hidden]="loadingServiceCenter">

            <mat-form-field>
              <input matInput (keyup)="filterServiceCenterRenewalTable($event)" placeholder="Filter" />
            </mat-form-field>
            <mat-table [dataSource]="serviceCenterRenewalSource" matSort aria-describedby="licenses" #serviceCenterRenewalSort="matSort" class="mat-elevation-z8 m-b-0">

              <ng-container matColumnDef="licenseName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Service Center License</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                  <span class="mobile-label">Service Center License</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseName}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseId">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                  <span class="mobile-label">License Id</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseId}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="submittedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Submitted On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.submittedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="enteredByStaff">
                <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">TLC or Mailed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span *ngIf="license.enteredByStaff">Mailed</span>
                    <span *ngIf="!license.enteredByStaff">TLC</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="applicationFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Application Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.applicationFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="assignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Team Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.assignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.assignedTo" (selectionChange)="assignServiceCenterLicense($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckInitiatedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Initiated Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Completed Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckExpiryDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Expires On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckExpiryDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="complianceAssignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Compliance Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.complianceAssignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="complianceAssignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Compliance Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberServiceCenter($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="inspectionCompletedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Inspection Last Completed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.inspectionCompletedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">License Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.licenseFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Status</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.status }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="managerFlag">
                <mat-header-cell *matHeaderCellDef mat-sort-header="">
                  Alerts
                </mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <div fxLayout="row" fxLayoutGap="12px">
                    <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                    <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                    <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                    <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="serviceCenterColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: serviceCenterColumns" fxLayoutGap="12px"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" #serviceCenterRenewalPaginator="matPaginator">
            </mat-paginator>

            <div *ngIf="loadingServiceCenter">
              <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
                <h1>Loading</h1>
              </div>
            </div>

          </mat-tab>

          <mat-tab label="Laboratories" [hidden]="loadingTestinglab">

            <mat-form-field>
              <input matInput (keyup)="filterTestingLabRenewalTable($event)" placeholder="Filter" />
            </mat-form-field>
            <mat-table [dataSource]="testingLabRenewalSource" matSort aria-describedby="licenses" #testingLabRenewalSort="matSort" class="mat-elevation-z8 m-b-0">

              <ng-container matColumnDef="licenseName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Laboratory License</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                  <span class="mobile-label">Laboratory License</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseName}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseId">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
                <mat-cell *matCellDef="let license" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                  <span class="mobile-label">License Id</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{license.licenseId}}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="submittedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Submitted On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.submittedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="enteredByStaff">
                <mat-header-cell *matHeaderCellDef mat-sort-header>TLC or Mailed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">TLC or Mailed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span *ngIf="license.enteredByStaff">Mailed</span>
                    <span *ngIf="!license.enteredByStaff">TLC</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="applicationFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Application Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Application Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.applicationFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="assignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Team Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.assignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.assignedTo" (selectionChange)="assignTestingLabLicense($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.assignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckInitiatedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Initiated Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Initiated Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckInitiatedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Checks Completed Date</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Checks Completed Date</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundCheckExpiryDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Background Check Expires On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Background Check Expires On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.backgroundCheckExpiryDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="complianceAssignedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Member Assigned On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Compliance Member Assigned On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.complianceAssignedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="complianceAssignedTo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To Compliance Member</mat-header-cell>
                <mat-cell *matCellDef="let license" style="justify-content: normal">
                  <span class="mobile-label">Assigned To Compliance Member</span>
                  <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
                     <mat-select [(value)]="license.complianceAssignedTo" (selectionChange)="assignComplianceMemberTestingLab($event, license.id)">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let staff of manufacturerStaff" value={{staff.name}}>{{staff.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span *ngIf="!authService.isAdmin">{{license.complianceAssignedTo}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="inspectionCompletedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Last Completed</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Inspection Last Completed</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.inspectionCompletedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="licenseFeeClearedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>License Fee Cleared On</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">License Fee Cleared On</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.licenseFeeClearedDate | date }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <span class="mobile-label">Status</span>
                  <div fxLayout="row" fxLayoutGap="4px">
                    <span>{{ license.status }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="managerFlag">
                <mat-header-cell *matHeaderCellDef mat-sort-header="">
                  Alerts
                </mat-header-cell>
                <mat-cell *matCellDef="let license">
                  <div fxLayout="row" fxLayoutGap="12px">
                    <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                    <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                    <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                    <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="testingLabColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: testingLabColumns" fxLayoutGap="12px"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" #testingLabRenewalPaginator="matPaginator">
            </mat-paginator>

            <div *ngIf="loadingTestinglab">
              <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
                <h1>Loading</h1>
              </div>
            </div>

          </mat-tab>
        </mat-tab-group>
      </mat-tab>

      <mat-tab label="Up for Renewal" [hidden]="loadingUpForRenewalLicenses">
        <mat-form-field>
          <input matInput (keyup)="filterUpForRenewalTable($event)" placeholder="Filter" />
        </mat-form-field>
        <mat-table [dataSource]="upForRenewalSource" matSort aria-describedby="licenses" #upForRenewalSort="matSort" class="mat-elevation-z8 m-b-0">

          <ng-container matColumnDef="entityType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Type</span>
              <div fxLayout="row" *ngIf="license.entityType == 'WorkerPermit'" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
                <span>Worker Permit</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'TrainingProgram'" [routerLink]="['/' + 'training' + '/assigned', license.id]">
                <span>Training Program</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'Facilitator'" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                <span>Facilitator</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'Manufacturer'" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                <span>Manufacturer</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'ServiceCenter'" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                <span>Service Center</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'TestingLab'" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                <span>Laboratory</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Name</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Name</span>
                <div fxLayout="row" *ngIf="license.entityType == 'WorkerPermit'" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
                <div fxLayout="row" *ngIf="license.entityType == 'TrainingProgram'" [routerLink]="['/' + 'training' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
                <div fxLayout="row" *ngIf="license.entityType == 'Facilitator'" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
                <div fxLayout="row" *ngIf="license.entityType == 'Manufacturer'" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
                <div fxLayout="row" *ngIf="license.entityType == 'ServiceCenter'" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
                <div fxLayout="row" *ngIf="license.entityType == 'TestingLab'" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                  <span>{{license.licenseName}}</span>
                </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">License Id</span>
              <div fxLayout="row" *ngIf="license.entityType == 'WorkerPermit'" [routerLink]="['/' + 'worker-permit' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'TrainingProgram'" [routerLink]="['/' + 'training' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'Facilitator'" [routerLink]="['/' + 'facilitator' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'Manufacturer'" [routerLink]="['/' + 'manufacturer' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'ServiceCenter'" [routerLink]="['/' + 'service-center' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.entityType == 'TestingLab'" [routerLink]="['/' + 'testing-lab' + '/assigned', license.id]">
                <span>{{license.licenseId}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Expiration Date</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.expirationDate | date}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="canStartRenewalDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Can Start Renewal</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Date Can Start Renewal</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.canStartRenewalDate | date}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timelyDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Deadline for Timely Submission</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Deadline for Timely Submission</span>
              <div fxLayout="row" fxLayoutGap="4px">
                <span>{{license.timelyDate | date}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="deadlineForRenewal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Deadline for Untimely Submisson</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label">Deadline for Untimely Submisson</span>
              <div fxLayout="row">
                <span>{{license.deadlineForRenewal | date}}</span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hide">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Hide License</mat-header-cell>
            <mat-cell *matCellDef="let license">
              <span class="mobile-label"></span>
              <div fxLayout="row" fxLayoutGap="4px" *ngIf="authService.isAdmin || ((license.entityType == 'Facilitator' && authService.isFacilitatorAdmin) || (license.entityType == 'Manufacturer' && authService.isManufacturerAdmin) || (license.entityType == 'ServiceCenter' && authService.isServiceCenterAdmin) || (license.entityType == 'TestingLab' && authService.isTestingLabAdmin))">
                <button type="button" mat-icon-button color="primary"
                matTooltip="Hide from AAT" matTooltipShowDelay=800 aria-label="Hide from AAT"
                (click)="hideLicense(license)">
                <mat-icon>visibility_off</mat-icon>
              </button>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="managerFlag">
            <mat-header-cell *matHeaderCellDef mat-sort-header="">
              Alerts
            </mat-header-cell>
            <mat-cell *matCellDef="let license">
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-icon style="color:red" *ngIf="license.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
                <mat-icon style="color:blue" *ngIf="license.managerFlag == 'blue'">flag</mat-icon>
                <mat-icon style="color:rgb(229, 238, 65)" *ngIf="license.managerFlag == 'yellow'">flag</mat-icon>
                <mat-icon *ngIf="license.managerFlag == 'blue' || license.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotes(license)" >more_horiz</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="upForRenewalColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: upForRenewalColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #upForRenewalPaginator="matPaginator">
        </mat-paginator>

        <div *ngIf="loadingUpForRenewalLicenses">
          <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
