<div *ngIf="!loading" class="m-t-16">
  <div [@slideLeft] *ngIf="siteInspection.id === 0" fxLayout="column" class="m-t-16">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span>Site Inspections</span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'inspection')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <button type="button" mat-icon-button color="primary" matTooltip="Create Site Inspection"
                matTooltipShowDelay=800 aria-label="Create Site Inspection" (click)="createSiteInspetion()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="inspectionSource" matSort #inspectionSort="matSort" aria-describedby="Site Inspections" class="mat-elevation-z8">
        <ng-container matColumnDef="caseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Case Id</span>
            {{ inspection.caseId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Status</span>
            {{ inspection.status }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Type</span>
            {{ inspection.type }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="visitDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Visit Date</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Visit Date</span>
            {{ inspection.visitDate | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignedTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Assigned To</span>
            {{staffReference[inspection.assignedTo]}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="manager">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span class="mobile-label">Manager</span>
            {{staffReference[inspection.manager]}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="followupAge">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Days Since Followup</mat-header-cell>
          <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
            <span>{{ calculateDays(inspection.followup.dateCreated) }} days</span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="inspectionColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: inspectionColumns" (click)="viewSiteInspection(row, false)"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #inspectionPaginator="matPaginator">
      </mat-paginator>

      <div [hidden]="!renewal">
        <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
          <span>Previous Site Inspections</span>
          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
            <input matInput (keyup)="filterTable($event, 'expiredInspection')" placeholder="Filter" />
          </mat-form-field>
          <span fxFlex></span>
        </div>

        <mat-table [dataSource]="expiredInspectionSource" matSort #expiredInspectionSort="matSort" aria-describedby="Site Inspections" class="mat-elevation-z8">
          <ng-container matColumnDef="caseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Case Id</span>
              {{ inspection.caseId }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Status</span>
              {{ inspection.status }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Type</span>
              {{ inspection.type }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="visitDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Visit Date</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Visit Date</span>
              {{ inspection.visitDate | date }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Assigned To</span>
              {{staffReference[inspection.assignedTo]}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="manager">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let inspection">
              <span class="mobile-label">Manager</span>
              {{staffReference[inspection.manager]}}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="expiredInspectionColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: expiredInspectionColumns" (click)="viewSiteInspection(row, true)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #expiredInspectionPaginator="matPaginator">
        </mat-paginator>
      </div>
    </div>
  </div>

  <div [@slideLeft] *ngIf="siteInspection.id > 0 && followup.id === 0" fxLayout="column">
    <form [formGroup]="siteInspectionForm">
      <div fxLayout="column">
        <span style="font-size: x-large" fxFlex><strong>Site Inspection for {{entityName}}</strong></span>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>Case ID:</span>
            <span>{{siteInspection.caseId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(siteInspection.caseId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <div *ngIf="siteInspection.followup?.id > 0" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>Followup:</span>
            <span>{{siteInspection.followup.caseId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(siteInspection.followup.caseId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
            <div fxLayout="column" fxLayoutGap="8px">
              <button mat-button type="button" color="primary" (click)="viewFollowup(siteInspection.followup)">
                View Followup
              </button>
              <button mat-button type="button" color="primary" (click)="updateNonCompliantRules()">
                Update Followup
              </button>
            </div>
          </div>
          <div *ngIf="siteInspection.followup?.id <= 0 && checkCompliant()" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <div fxLayout="column" fxLayoutGap="8px">
              <button mat-button type="button" color="primary" (click)="createFollowUp()">
                Create Followup
              </button>
            </div>
          </div>
          <span fxFlex></span>
          <div fxLayout="row">
            <button mat-button type="button" color="primary" (click)="exportSiteInspection(siteInspection)">Export to PDF</button>
            <button *ngIf="!siteInspection.locked && !expired" type="button" mat-button color="primary" (click)="updateSiteInspection(false)">Save</button>
            <button *ngIf="!siteInspection.locked && !expired" type="button" mat-button color="primary" (click)="updateSiteInspection(true)">Save and Close</button>
            <button type="button" mat-button color="warn" (click)="closeInspection()">Close</button>
            <button mat-button type="button" color="warn" *ngIf="authService.isAdmin && !expired" (click)="siteInspection.locked? unlockSiteInspection() : lockSiteInspection(true)">
              {{siteInspection.locked? 'Unlock' : 'Lock'}}
              <mat-icon>
                {{siteInspection.locked? 'lock_open' : 'lock'}}
              </mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-t-8">
          <mat-form-field fxFlex="15" fxFlex.lt-md="100">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option value="New">New</mat-option>
              <mat-option value="Working">Working</mat-option>
              <mat-option *ngIf="authService.isAdmin" value="Complete">Complete</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15" fxFlex.lt-md="100">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
              <mat-option value="Application">Application</mat-option>
              <mat-option value="Renewal">Renewal</mat-option>
              <mat-option value="ChangeRequest">Change Request</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
            <mat-label>Assigned To</mat-label>
            <mat-select formControlName="assignedTo">
              <mat-option [value]="">-- None --</mat-option>
              <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
            <mat-label>Manager</mat-label>
            <mat-select formControlName="manager">
              <mat-option [value]="">-- None --</mat-option>
              <mat-option *ngFor="let user of managers" [value]="user.id">{{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="15" fxFlex.lt-md="100">
            <mat-label>Visit Date</mat-label>
            <input placeholder="mm/dd/yyyy"
                   formControlName="visitDate"
                   matInput
                   [matDatepicker]="datepickerVisit"
                   [max]="today" />
            <mat-datepicker-toggle matSuffix [for]="datepickerVisit"></mat-datepicker-toggle>
            <mat-datepicker #datepickerVisit>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <mat-error *ngIf="siteInspectionForm.get('visitDate').value > today">
              Please choose an earlier date.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div fxLayout="row">
      <div fxLayout="column">
        <mat-form-field>
          <input matInput [(ngModel)]="filterText" placeholder="Filter Rules" />
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="8px" class="m-t-16" style="overflow-y: auto; height: 400px">
      <div *ngFor="let rule of getFilteredRules()">
        <ng-container [ngTemplateOutlet]="inspectionRuleTpl"
                      [ngTemplateOutletContext]="{inspectionRule: rule, locked: siteInspection.locked}">
        </ng-container>
      </div>
    </div>

    <form [formGroup]="siteInspectionForm" class="m-t-8">
      <span><strong>Site Visit Summary</strong></span>
      <div fxLayout="row" class="m-t-8">
        <mat-form-field fxFlex="100">
          <textarea formControlName="summary" matInput rows="5" placeholder="Summary" type="text"></textarea>
        </mat-form-field>
      </div>
    </form>

    <div fxLayout="row">
      <button *ngIf="!siteInspection.locked && !expired" type="button" mat-button color="primary" (click)="updateSiteInspection(false)">Save</button>
      <button *ngIf="!siteInspection.locked && !expired" type="button" mat-button color="primary" (click)="updateSiteInspection(true)">Save and Close</button>
      <button type="button" mat-button color="warn" (click)="closeInspection()">Close</button>
    </div>
  </div>

  <div [@slideLeft] *ngIf="followup.id > 0">
    <div fxLayout="column">
      <span style="font-size: x-large" fxFlex><strong>Site Inspection Followup for {{entityName}}</strong></span>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <span class="f-s-18">Case ID:</span>
          <span>{{followup.caseId}}</span>
          <button mat-icon-button (click)="sharedService.copyToClipboard(followup.caseId)" color="primary" matTooltip="Copy to Clipboard"
            matTooltipShowDelay=800 aria-label="Copy to Clipboard">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="siteInspection.followup" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <span>Days since followup: {{ calculateDays(siteInspection.followup.dateCreated) }} days</span>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" *ngIf="!editing">
        <div fxLayout="column" fxFlex="25" fxLayoutGap="8px">
          <div fxLayout="row">
            <span fxFlex="35"><strong>Status</strong></span>
            <span>{{followup.status}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Category</strong></span>
            <span>{{followup.category}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Tier</strong></span>
            <span>{{followup.tier}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Outcome</strong></span>
            <span>{{followup.outcome}}</span>
          </div>
        </div>

        <div fxLayout="column" fxFlex="65" fxLayoutGap="8px">
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutGap="12px">
                <span><strong>Date of Inspection</strong></span>
                <span>{{followup.dateOfInspection | date}}</span>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutGap="12px">
                <span><strong>Assigned To</strong></span>
                <span>{{staffReference[followup.assignedTo]}}</span>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutGap="12px">
                <span><strong>Date Requirements Met</strong></span>
                <span>{{followup.dateRequirementsMet | date}}</span>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutGap="12px">
                <span><strong>Manager</strong></span>
                <span>{{staffReference[followup.manager]}}</span>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column" fxFlex="100">
              <div fxLayout="row" fxLayoutGap="12px">
                <span><strong>Message to Licensee</strong></span>
                <textarea matInput rows="5" [value]="followup.message" placeholder="Message to Licensee" disabled></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form [formGroup]="followupForm" (ngSubmit)="updateFollowup(false)" *ngIf="editing">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
          <div fxLayout="column" fxFlex="30" fxFlex.lt-md="100">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option value="New">New</mat-option>
                <mat-option value="Working">Working</mat-option>
                <mat-option value="Closed">Closed</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Category</mat-label>
              <input type="text"
                      placeholder="Select"
                      aria-label="Category"
                      matInput
                      formControlName="category"
                      [matAutocomplete]="category">
              <mat-autocomplete #category="matAutocomplete">
                <mat-option *ngFor="let category of filteredCategories | async" [value]="category.value" >
                  {{category.value}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tier</mat-label>
              <mat-select formControlName="tier">
                <mat-option value="Tier1">Tier 1</mat-option>
                <mat-option value="Tier2">Tier 2</mat-option>
                <mat-option value="Tier3">Tier 3</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Outcome</mat-label>
              <mat-select formControlName="outcome">
                <mat-option value="Closed">Closed</mat-option>
                <mat-option value="Education">Education</mat-option>
                <mat-option value="Administrative Action">Administrative Action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="60" fxFlex.lt-md="100">
            <div fxLayout="row" fxLayoutGap="8px">
              <div fxLayout="column" fxFlex="50">
                <mat-form-field>
                  <mat-label>Date of Inspection</mat-label>
                  <input placeholder="mm/dd/yyyy"
                          formControlName="dateOfInspection"
                          matInput
                          [matDatepicker]="datepickerInspection"
                          [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="datepickerInspection"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerInspection>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="followupForm.get('dateOfInspection').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <mat-form-field>
                  <mat-label>Assigned To</mat-label>
                  <mat-select formControlName="assignedTo">
                    <mat-option [value]="">-- None --</mat-option>
                    <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="8px">
              <div fxLayout="column" fxFlex="50">
                <mat-form-field>
                  <mat-label>Date Requirements Met</mat-label>
                  <input placeholder="mm/dd/yyyy"
                          formControlName="dateRequirementsMet"
                          matInput
                          [matDatepicker]="datepickerMet"
                          [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="datepickerMet"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerMet>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="followupForm.get('dateRequirementsMet').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <mat-form-field>
                  <mat-label>Manager</mat-label>
                  <mat-select formControlName="manager">
                    <mat-option [value]="">-- None --</mat-option>
                    <mat-option *ngFor="let user of managers" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="8px">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field>
                  <mat-label>Message to Licensee</mat-label>
                  <textarea formControlName="message" matInput rows="5" placeholder="Message to Licensee" type="text"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div fxLayout="column" class="m-t-16">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
          <span class="f-s-18">Non-Compliant Rules and Statutes</span>
          <mat-form-field>
            <input matInput [(ngModel)]="filterTextFollowup" placeholder="Filter Rules" />
          </mat-form-field>
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
              <button *ngIf="!followup.locked && !expired && editing" type="button" mat-icon-button color="primary"
                matTooltip="Add Rule" matTooltipShowDelay=800 aria-label="Add Rule" (click)="addRule()">
                <mat-icon>add_box</mat-icon>
              </button>
              <span>Add Manually Entered Rule</span>
            </div>
          </div>
          <span fxFlex></span>
          <div fxLayout="row">
            <button *ngIf="!followup.locked && !expired" type="button" mat-icon-button [color]="editing? 'primary':'warn'"
              [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800 aria-label="Edit Compliance Case"
              (click)="editFollowup()">
              <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
            </button>
            <button *ngIf="editing && !expired" mat-button type="button" color="primary" (click)="updateFollowup(false)">Save</button>
            <button *ngIf="editing && !expired" mat-button type="button" color="primary" (click)="updateFollowup(true)">Save & Close</button>
            <button mat-button type="button" color="primary" (click)="exportFollowup(followup)">Export to PDF</button>
            <button mat-button type="button" color="warn" (click)="closeFollowup()">Close</button>
            <button *ngIf="!expired" mat-button type="button" color="warn" (click)="followup.locked? unlockFollowup() : lockFollowup(true)">
              {{followup.locked? 'Unlock' : 'Lock'}}
              <mat-icon>
                {{followup.locked? 'lock_open' : 'lock'}}
              </mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" class="m-t-16" style="overflow-y: auto; height: 500px">
          <div *ngFor="let rule of getFilteredFollowupRules()">
            <ng-container [ngTemplateOutlet]="followUpTemplate"
                          [ngTemplateOutletContext]="{followUpRule: rule, locked: siteInspection.locked}">
            </ng-container>
          </div>
        </div>
      </div>

      <div fxLayout="column" class="m-t-8">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
          <span class="f-s-18">Notes</span>
          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
            <input matInput (keyup)="filterTable($event, 'notes')" placeholder="Filter" />
          </mat-form-field>
          <span fxFlex></span>

          <div fxLayout="row">
            <button *ngIf="editing && !expired" type="button" mat-button color="primary" matTooltip="Reference Note" matTooltipShowDelay=800
              aria-label="Reference Note" (click)="addNoteReference()">
              Reference A Note
            </button>

            <button *ngIf="editing && !expired" type="button" mat-button color="primary" matTooltip="Add Note" matTooltipShowDelay=800
              aria-label="Add Note" (click)="addNewNote()">
              Add Note
            </button>
          </div>
        </div>

        <mat-table [dataSource]="notesSource" aria-describedby="notes" class="mat-elevation-z8">
          <ng-container matColumnDef="text">
            <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
            <mat-cell [ngClass]="tableColor" *matCellDef="let note">
              <span class="mobile-label">Note</span>
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px" fxFlex="100">
                <textarea fxFlex="80" fxFlex.lt-md="100" (click)="editing? editNote(note) : ''" rows="4" matInput [value]="note.text"></textarea>
                <div fxLayout="column">
                  <span>{{note.generic? "Reference" : "Followup Specific"}}</span>
                  <span>Updated By {{note.lastUpdatedBy}}</span>
                  <span>Last Updated {{note.dateLastUpdated | date}}</span>
                  <button *ngIf="note.generic && editing" mat-button color="warn" aria-label="Delete Note"(click)="removeNoteReference(note.id)">
                    Remove Reference
                  </button>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="notesColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: notesColumns"></mat-row>
        </mat-table>
      </div>

      <div fxLayout="column" class="m-t-8">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutGap.lt-md="" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
          <span class="f-s-18">Documents</span>
          <div fxLayout="row">
            <input type="file" class="file-input"  (change)="uploadFollowupDocument($event)" #fileUpload/>
            <div fxLayout="row" fxLayoutGap="8px">
              <div class="file-upload">
                <button [disabled]="!editing || followup.locked" mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                  Browse
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <mat-card-content>
          <mat-card>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row">
                  <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                  <span class="f-s-16" fxFlex="20"><strong>Date Uploaded</strong></span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                  *ngFor="let document of followup.followupDocuments" style="justify-content: center;">
                    <span fxFlex="30" class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
                    (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>

                    <span fxFlex="20">{{document.dateCreated | date}}</span>

                    <button fxFlex="10" [hidden]="!editing || !authService.isAdmin" mat-icon-button color="warn" aria-label="Delete Document" (click)="deleteDocument(document.id, document.name)">
                      <mat-icon>delete</mat-icon>
                    </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </div>
    </div>
  </div>
</div>

<ng-template #inspectionRuleTpl let-inspectionRule="inspectionRule" let-locked="locked">
  <mat-card [style.background-color]="cardColor">
    <mat-card-content>
      <mat-card>
        <mat-card-title>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <span [ngStyle]="{'color': inspectionRule.compliant === null ? 'red' : 'black'}">{{inspectionRule.rule.segmentNumber}}</span>
            <span [ngStyle]="{'color': inspectionRule.compliant === null ? 'red' : 'black'}" fxFlex>{{inspectionRule.rule.segment}}</span>
            <span [ngStyle]="{'color': inspectionRule.compliant === null ? 'red' : 'black'}">OAR Rule {{inspectionRule.rule.statute}}</span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="column">
              <span><strong>Question</strong></span>
              <span class="text-wrap" *ngIf="entityType === sharedService.manufacturer">{{inspectionRule.rule.questionManufacturer}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.serviceCenter">{{inspectionRule.rule.questionServiceCenter}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.testingLab">{{inspectionRule.rule.questionLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Answer</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.answer}}</span>
            </div>
            <div fxLayout="column">
              <span><strong>Guidance</strong></span>
              <span class="text-wrap" *ngIf="entityType === sharedService.manufacturer">{{inspectionRule.rule.guidanceManufacturer}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.serviceCenter">{{inspectionRule.rule.guidanceServiceCenter}}</span>
              <span class="text-wrap" *ngIf="entityType === sharedService.testingLab">{{inspectionRule.rule.guidanceLaboratory}}</span>
            </div>
            <div fxLayout="column">
              <span class="m-t-8"><strong>If Insufficient</strong></span>
              <span class="text-wrap">{{inspectionRule.rule.nonCompliant}}</span>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="" class="m-b-16">
              <mat-radio-group [(ngModel)]="inspectionRule.compliant" [disabled]="locked" fxLayoutGap="8px">
                <mat-radio-button [value]="true">Sufficient</mat-radio-button>
                <mat-radio-button [value]="false">Insufficient</mat-radio-button>
              </mat-radio-group>
              <mat-checkbox [(ngModel)]="inspectionRule.educationProvided" [disabled]="locked">
                Education Provided
              </mat-checkbox>
            </div>
            <div fxLayout="column">
              <span><strong>Notes</strong></span>
              <textarea matInput cols="2" placeholder="Notes" [(ngModel)]="inspectionRule.notes" [disabled]="locked"></textarea>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #followUpTemplate let-followUpRule="followUpRule" let-locked="locked">
  <mat-card>
    <mat-card-content>
      <mat-card  fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
          <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row" *ngIf="!editing || !followUpRule.rule.userCreated">
              <span style="font-size: large"><strong>Rule out of compliance: {{followUpRule.rule.statute}}</strong></span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="editing && followUpRule.rule.userCreated" fxLayoutGap="8px">
              <span style="font-size: large"><strong>Rule out of compliance:</strong></span>
              <mat-form-field>
                <input matInput [(ngModel)]="followUpRule.rule.statute" placeholder="Rule" />
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="editing" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row" fxLayoutGap="12px">
              <div fxLayout="column">
                <mat-form-field appearance="fill">
                  <mat-label>Resolved Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="resolvedDate"
                    [(ngModel)]="followUpRule.resolvedDateFormatted"
                    [readonly]="locked"/>
                  <mat-datepicker-toggle matSuffix [for]="resolvedDate"></mat-datepicker-toggle>
                  <mat-datepicker #resolvedDate>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>

              <div fxLayout="column">
                <button *ngIf="!followUpRule.rule.userCreated" mat-raised-button color="primary" (click)="viewRuleDetails(followUpRule.rule)">View Rule Details</button>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="!editing" style="font-size: large" fxLayoutAlign.lt-md="center center">
            <div fxLayout="column">
              <span><strong>Resolved Date</strong></span>
              <span>{{followUpRule.resolvedDate | date}}</span>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="!editing" style="font-size: large" fxLayoutAlign.lt-md="center center">
            <div fxLayout="column">
              <button *ngIf="!followUpRule.rule.userCreated" mat-raised-button color="primary" (click)="viewRuleDetails(followUpRule.rule)">View Rule Details</button>
            </div>
          </div>
        </div>

        <mat-card-content fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
            <mat-card fxFlex="50">
              <div fxLayout="column" fxFlex="100">
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing || !followUpRule.rule.userCreated">
                  <span><strong>Rules Summary</strong></span>
                  <span >{{followUpRule.rule.summary}}</span>
                </div>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="editing && followUpRule.rule.userCreated">
                  <mat-form-field fxFlex="100">
                    <textarea [(ngModel)]="followUpRule.rule.summary" matInput rows="5" placeholder="Rule Summary" type="text"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>

            <mat-card fxFlex="50">
              <div fxLayout="column" fxFlex="100">
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing">
                  <span><strong>Reason for not meeting Requirement</strong></span>
                  <span >{{followUpRule.notMeetingRequirementReason}}</span>
                </div>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                  <mat-form-field fxFlex="100" *ngIf="editing">
                    <textarea [(ngModel)]="followUpRule.notMeetingRequirementReason" matInput rows="5" placeholder="Reason for not meeting Requirement" type="text" [readonly]="locked"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
            <mat-card fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field fxFlex="100" *ngIf="editing">
                  <textarea [(ngModel)]="followUpRule.notes" matInput rows="5" placeholder="Plan and Guidance" type="text" [readonly]="locked"></textarea>
                </mat-form-field>
                <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100" *ngIf="!editing">
                  <span><strong>Plan and Guidance</strong></span>
                  <span >{{followUpRule.notes}}</span>
                </div>
              </div>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<div *ngIf="loading">
  <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
