import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent, IConfirmDialog } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ITrainingProgramSearch } from 'app/models/training/training-program-search';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IWorkerPermitSearch } from 'app/models/licenses/worker/worker-search';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class SharedService {
  public mobile: boolean = false;
  public tablet: boolean = false;
  private api: string = environment.privateApi;
  private changeTheme: Subject<string> = new Subject<string>();
  public darkMode: boolean = false;
  public purple: string = '#9355dc';
  public blue: string = '#628ef4';
  public gold: string = '#ffbe2e';
  public green: string = '#70e17b';
  public pink: string = '#e9695f';
  public orange: string = '#EC891D';
  public red: string = '#fc4132'
  public userTheme: string = environment.theme;
  public userPreferencesLoaded: boolean = false;
  public userNameClaim: string = "";
  public loading: boolean = false;
  public today: Date = new Date(Date.now());

  /*
  * Statuses
  */
  public pending: string = "Pending";
  public submitted: string = "Submitted";
  public resubmitted: string = "Resubmitted";
  public underReview: string = "Under Review";
  public submittedForApproval: string = 'Submitted for Approval';
  public renewalSubmittedForApproval: string = 'Renewal Submitted for Approval';
  public peerReview: string = 'Ready For Peer Review';
  public siteInspectionComplete: string = 'Site Inspection Complete, Awaiting Follow Up';
  public approved: string = "Approved";
  public incomplete: string = "Incomplete";
  public insufficient: string = "Insufficient";
  public withdrawn: string = "Withdrawn";
  public closed: string = "Closed";
  public pendingDenial: string = "Pending Denial";
  public denied: string = "Denied";
  public revoked: string = "Revoked";
  public expired: string = "Expired";
  public requestWithdraw: string = "Request Withdraw";
  public requestSurrender: string = "Request Surrender";
  public surrendered: string = "Surrendered"
  public pendingRevocation: string = "Pending Revocation"
  public awaitingPayment: string = "Approved, Pending Payment and Clearance";
  public readyForLicensingManagerReview: string = "Ready for Licensing Manager Review";
  public submitForClosure: string = "Submit For Closure";
  public readyForComplianceAssignmentAndInspection: string = "Ready for Compliance Assignment and Inspection";
  public readyForComplianceManagerReview: string = "Ready for Compliance Manager Review";
  public renewalSubmitted: string = "Renewal Submitted/For Review";
  public pendingRenewal: string = "Pending Renewal";
  public renewalReviewTimely: string = "Renewal Under Review/Timely";
  public renewalReviewUntimely: string = "Renewal Under Review/Untimely";
  public renewalApprovedPendingPaymentandClearance: string = "Renewal Approved, Pending Fee Payment and Clearance";
  public renewed: string = "Renewed";
  public renewalPeerReview = "Renewal Ready For Peer Review";
  public renewalReadyForLicensingManagerReview = "Renewal Ready for Licensing Manager Review";
  public renewalReadyForComplianceAssignmentAndInspection = "Renewal Ready for Compliance Assignment and Inspection";
  public renewalSiteInspectionComplete = "Renewal Site Inspection Complete, Awaiting Follow Up";
  public renewalReadyForComplianceManagerReview = "Renewal Ready for Compliance Manager Review";
  public renewalSubmittedForApprovalForLicensingManager = "Renewal Submitted for Approval for Licensing Manager";

  public statusList: string[] = [
    this.submitted,
    this.resubmitted,
    this.incomplete,
    this.insufficient,
    this.underReview,
    this.peerReview,
    this.renewalPeerReview,
    this.pending,
    this.approved,
    this.closed,
    this.denied,
    this.expired,
    this.renewalSubmitted,
    this.pendingRenewal,
    this.renewalReviewTimely,
    this.renewalReviewUntimely,
    this.renewalApprovedPendingPaymentandClearance,
    this.renewed,
    this.revoked,
    this.awaitingPayment,
    this.pendingDenial,
    this.submittedForApproval,
    this.renewalSubmittedForApproval,
    this.readyForLicensingManagerReview,
    this.readyForComplianceManagerReview,
    this.renewalReadyForLicensingManagerReview,
    this.renewalReadyForComplianceAssignmentAndInspection,
    this.renewalSiteInspectionComplete,
    this.renewalReadyForComplianceManagerReview,
    this.renewalSubmittedForApprovalForLicensingManager,
    this.submitForClosure,
    this.pendingRevocation,
    this.requestWithdraw,
    this.requestSurrender,
    this.withdrawn,
    this.surrendered
  ];
  public statusListServiceCenterManufacturerLaboratory: string[] = [
    this.submitted,
    this.resubmitted,
    this.incomplete,
    this.insufficient,
    this.underReview,
    this.peerReview,
    this.renewalPeerReview,
    this.pending,
    this.approved,
    this.closed,
    this.denied,
    this.expired,
    this.renewalSubmitted,
    this.pendingRenewal,
    this.renewalReviewTimely,
    this.renewalReviewUntimely,
    this.renewalApprovedPendingPaymentandClearance,
    this.renewed,
    this.revoked,
    this.awaitingPayment,
    this.pendingDenial,
    this.submittedForApproval,
    this.renewalSubmittedForApproval,
    this.readyForLicensingManagerReview,
    this.readyForComplianceAssignmentAndInspection,
    this.readyForComplianceManagerReview,
    this.renewalReadyForLicensingManagerReview,
    this.renewalReadyForComplianceAssignmentAndInspection,
    this.renewalSiteInspectionComplete,
    this.renewalReadyForComplianceManagerReview,
    this.renewalSubmittedForApprovalForLicensingManager,
    this.siteInspectionComplete,
    this.submitForClosure,
    this.pendingRevocation,
    this.requestWithdraw,
    this.requestSurrender,
    this.withdrawn,
    this.surrendered
  ];

  public nonApprovedStatuses: string[] = [
    this.submitted,
    this.resubmitted,
    this.incomplete,
    this.insufficient,
    this.underReview,
    this.peerReview,
    this.renewalPeerReview,
    this.closed,
    this.denied,
    this.renewalSubmitted,
    this.renewalReviewTimely,
    this.renewalReviewUntimely,
    this.renewalApprovedPendingPaymentandClearance,
    this.awaitingPayment,
    this.pendingDenial,
    this.submittedForApproval,
    this.renewalSubmittedForApproval,
    this.readyForLicensingManagerReview,
    this.readyForComplianceAssignmentAndInspection,
    this.readyForComplianceManagerReview,
    this.siteInspectionComplete,
    this.renewalReadyForLicensingManagerReview,
    this.renewalReadyForComplianceAssignmentAndInspection,
    this.renewalSiteInspectionComplete,
    this.renewalReadyForComplianceManagerReview,
    this.renewalSubmittedForApprovalForLicensingManager,
    this.submitForClosure,
    this.requestWithdraw,
    this.withdrawn,
  ];

  public approvedStatuses: string[] = [
    this.approved,
    this.expired,
    this.renewed,
    this.revoked,
    this.pendingRevocation,
    this.requestSurrender,
    this.surrendered
  ];

  public defaultSearchStatuses: string[] = [
    this.underReview, this.submitted, this.peerReview, this.renewalPeerReview, this.resubmitted, this.awaitingPayment, this.renewalSubmitted, this.approved, this.renewalReviewTimely, this.renewalReviewUntimely, this.renewalApprovedPendingPaymentandClearance
  ]

  //Look Up Types
  public category: string = "Category";
  public subSection: string = "SubSection";
  public investigationItem: string = "Investigation Item";
  public actionItem: string = "Action Item";

  //Entity Types
  public trainingProgram: string = 'TrainingProgram';
  public facilitator: string = 'Facilitator';
  public manufacturer: string = 'Manufacturer';
  public serviceCenter: string = 'ServiceCenter';
  public testingLab: string = 'TestingLab';
  public workerPermit: string = 'WorkerPermit';
  public trainingProgramEntityType = 'TrainingProgram';
  public workerPermitEntityType: string = 'WorkerPermitLicense';
  public manufacturerEntityType: string = 'ManufacturerLicense';
  public facilitatorEntityType: string = 'FacilitatorLicense';
  public serviceCenterEntityType: string = 'ServiceCenterLicense';
  public testingLabEntityType: string = 'TestingLabLicense';

  //Tasks
  public approvedPendingPayment: string = 'Send Approved, Pending Payment and Clearance Notice';
  public applicationPaymentDue: string = 'Application Payment Due';
  public licensePaymentDue: string = 'License Payment Due';
  public PaymentClearedPendingApproval = 'Payment Cleared Awaiting Approval';

  public searchForm = new UntypedFormGroup({
    programName: new UntypedFormControl(""),
    programId: new UntypedFormControl(''),
    assignedTo: new UntypedFormControl(''),
    statuses: new UntypedFormControl([this.submitted, this.approved, this.underReview, this.approved, this.peerReview]),
    renewals: new UntypedFormControl(false),
    onlyPaid: new UntypedFormControl(true)
  });

  public WorkerSearchForm = new UntypedFormGroup({
    permitId: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    assignedTo: new UntypedFormControl(''),
    statuses: new UntypedFormControl([this.submitted, this.underReview, this.awaitingPayment, this.approved, this.peerReview]),
    renewals: new UntypedFormControl(false),
    onlyPaid: new UntypedFormControl(false)
  });

  public search: ITrainingProgramSearch = {
    programName: '',
    programId: '',
    statuses: [],
    assignedTo: '',
    renewals: false,
    onlyPaid: false
  }
  public workerSearch: IWorkerPermitSearch = {
    permitId: '',
    name: '',
    statuses: [],
    assignedTo: '',
    renewals: false,
    onlyPaid: false
  }
  archivedIndicator: string = "-";

  stateList: string[] = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL",
    "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
    "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI",
    "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "American Samoa" ,
    "Commonwealth of the Northern Mariana Islands", "Guam", "Puerto Rico", "US Virgin Islands",
    "Federated States of Micronesia", "Republic of the Marshall Islands", "Republic of Palau"
  ];

  countyList: string[] = [
    "Baker", "Benton", "Clackamas", "Clatsop", "Columbia", "Coos", "Crook",
    "Curry", "Deschutes", "Douglas", "Gilliam", "Grant", "Harney", "Hood River",
    "Jackson", "Jefferson", "Josephine", "Klamath", "Lake", "Lane", "Lincoln",
    "Linn", "Malheur", "Marion", "Morrow", "Multnomah", "Polk", "Sherman",
    "Tillamook", "Umatilla", "Union", "Wallowa", "Wasco", "Washington", "Wheeler",
    "Yamhill"
  ];

  loremIpsum: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
  in culpa qui officia deserunt mollit anim id est laborum.`

  public confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private httpClient: HttpClient,
              public dialog: MatDialog,
              public toastr: ToastrService) { }

  public openNotice(message: string): void {
    let data: IConfirmDialog = {
      message: message,
      confirmation: false
    }
    this.openDialog(data);
  }

  public openConfirm(message: string): void {
    let data: IConfirmDialog = {
      message: message,
      confirmation: true
    }
    this.openDialog(data);
  }

  openDialog(data: IConfirmDialog): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      role: 'dialog',
      ariaLabel: "Confirmation Dialog",
      minWidth: '300px',
      maxWidth: !this.mobile? '700px' : '300px',
      maxHeight: this.mobile? '600px' : '700px',
      panelClass: this.darkMode ? "theme-dark" : "",
    });
  }

  public confirmed(): Observable<any>{
    return this.confirmDialogRef.afterClosed().pipe(take(1), map(response => {
      return response;
    }));
  }

  get isMobile(): boolean {
    return this.mobile;
  }

  get counties(): string[] {
    return this.countyList;
  }

  get states(): string[] {
    return this.stateList;
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    this.toastr.success("Copied!");
  }

  themeChanged$ = this.changeTheme.asObservable();

  toggleTheme() {
    this.darkMode = this.userTheme === 'dark';
    this.changeTheme.next(this.userTheme);
  }

  updateUser(): void{
    if(!this.userPreferencesLoaded){
      this.loading = true;
      this.getUserTheme().subscribe(
        response => this.userTheme = response,
        error => console.log('error', error),
        () => {
          this.toggleTheme();
          this.userPreferencesLoaded = true;
          this.loading = false;
        }
      );
    }
  }

  updateUserTheme(): void {
    this.httpClient.post<any>(this.api + "UserProfile/UpdateUserTheme/" + this.userTheme, null).subscribe(
      () => { },
      error => console.log('error', error)
    );
  }

  getUserTheme(): Observable<string> {
    return this.httpClient.get<string>(this.api + "UserProfile/GetUserTheme", { responseType: 'text' as 'json' });
  }

  setUserNameClaim(name: string) {
    this.userNameClaim = name;
  }

  isCancelled(response: any): boolean {
    return response === 'cancel' || response === null || response === '' || response === undefined;
  }

  public ageCalculator(dob: string): number {
    if(dob){
      const convertAge = new Date(dob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      return Math.floor((timeDiff / (1000 * 3600 * 24))/365);
    }
  }
}
