<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
        <mat-icon class="icon-20">email</mat-icon>
        <span>Messages</span>
      </div>
    </mat-card-title>

    <mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <button *ngIf="currentMailbox == 'Assigned' && currentView != 'Staff'" mat-stroked-button
        color="primary">Assigned Messages</button>
        <button *ngIf="currentMailbox != 'Assigned' || currentView == 'Staff'" mat-button
        (click)='loadAssignedInbox()'>Assigned Messages</button>

        <button *ngIf="currentMailbox == 'Licensing' && currentView != 'Staff'" mat-stroked-button
          color="primary">Licensing Inbox</button>
        <button *ngIf="currentMailbox != 'Licensing' || currentView == 'Staff'" mat-button
          (click)='loadLicensingInbox()'>Licensing Inbox</button>

        <button *ngIf="currentMailbox == 'Compliance' && currentView != 'Staff'" mat-stroked-button
          color="primary">Compliance Inbox</button>
        <button *ngIf="currentMailbox != 'Compliance' || currentView == 'Staff'" mat-button
          (click)='loadComplianceInbox()'>Compliance Inbox</button>

        <button *ngIf="currentMailbox =='PTS' && currentView != 'Staff'" mat-stroked-button
          color="primary">Product Tracking Team Inbox</button>
        <button *ngIf="currentMailbox != 'PTS' || currentView == 'Staff'" mat-button
          (click)='loadPTSInbox()'>Product Tracking Team Inbox</button>

        <button *ngIf="currentView =='Staff' && environment.internalMessaging.canViewStaffMessages" mat-stroked-button
          color="primary">All Messages</button>
        <button *ngIf="currentView != 'Staff' && environment.internalMessaging.canViewStaffMessages" mat-button
          (click)='loadStaff()'>All Messages</button>
      </div>
    </mat-card-subtitle>


    <mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <button *ngIf="currentView=='Unread' && currentMailbox != 'Assigned'" mat-stroked-button color="primary">Unread</button>
            <button *ngIf="currentView!='Unread' && currentMailbox != 'Assigned'" mat-button (click)='loadUnread()'>Unread</button>

            <button *ngIf="currentView=='Inbox' && currentMailbox != 'Assigned'" mat-stroked-button color="primary">Inbox</button>
            <button *ngIf="currentView!='Inbox' && currentMailbox != 'Assigned'" mat-button (click)='loadInbox()'>Inbox</button>

            <button *ngIf="currentView=='Sent' && environment.internalMessaging.canRespond && currentMailbox != 'Assigned'" mat-stroked-button
              color="primary">Sent</button>
            <button *ngIf="currentView!='Sent' && environment.internalMessaging.canRespond && currentMailbox != 'Assigned'" mat-button
              (click)='loadSent()'>Sent</button>
            <button mat-button (click)='refresh()'>
              <mat-icon class="icon-20" aria-placeholder='refresh'>refresh</mat-icon>
            </button>
            <mat-checkbox form="addNewInternalMessage" *ngIf="environment.internalMessaging.canViewArchived"
              (change)="refresh()" [(ngModel)]="includeArchived">Include Archived
            </mat-checkbox>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <span>All messages will be automatically archived when they are 180 days (about 6 months) old.</span>
          <span>Click the "Include Archived" checkbox and enter search criteria to find archived messages.</span>
        </div>
      </div>

    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <span *ngIf="environment.internalMessaging.canCreate">
      <a mat-icon-button aria-label="Send New Message" title="Send New Message" (click)="openAddMessageDialog('')">
        <mat-icon>add_box</mat-icon>
      </a>
      <a mat-icon-button aria-label="Create New Announcement" title="Create New Announcement"
        *ngIf="environment.internalMessaging.canAnnounce" (click)="openAddannouncementDialog()">
        <mat-icon>announcement</mat-icon>
      </a>
    </span>
    <div style="overflow-x: auto;">
      <section *ngIf="processing">
        <h2>Loading...</h2>
      </section>
      <div fxLayoutGap="12px">
        <mat-form-field [hidden]="processing">
          <input matInput (keyup)="filterTable($event, 'general')" placeholder="General Search" />
        </mat-form-field>
        <mat-form-field [hidden]="processing">
          <input matInput (keyup)="filterTable($event, 'licenseNumber')" placeholder="License Number" />
        </mat-form-field>

        <mat-form-field [hidden]="processing">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startDatePicker" (dateChange)="filterByDateRange(startDateInput.value, endDateInput.value)" #startDateInput>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field [hidden]="processing">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endDatePicker" (dateChange)="filterByDateRange(startDateInput.value, endDateInput.value)" #endDateInput>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort aria-describedby="Messages"
        #TableOneSort="matSort" class="mat-elevation-z8 demo-table" [hidden]="processing">
        <ng-container matColumnDef="icons">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
          <td mat-cell *matCellDef="let message" [ngClass]="{'is-unopened': !message.opened}">
            <mat-icon class="icon-20" *ngIf="message.isBroadcast" matTooltip="Announcement message">announcement
            </mat-icon> &nbsp;
            <mat-icon class="icon-20" *ngIf="message.highPriority" matTooltip="High priority message">priority_high
            </mat-icon> &nbsp;
            <mat-icon class="icon-20" *ngIf="message.archived" aria-placeholder="archived"
              matTooltip="Message archived">archive</mat-icon> {{message.Archived}}
            <mat-icon class="icon-20" *ngIf="message.attachments != undefined && message.attachments.length > 0"
              aria-placeholder="archived" matTooltip="Message has attachments">attach_file</mat-icon>
            {{message.Archived}}
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
          <td mat-cell *matCellDef="let message" [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}">
            {{ message.subject }}
          </td>
        </ng-container>
        <ng-container matColumnDef="recipient">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}" (click)="$event.stopPropagation()">
            <span *ngIf="message.recipients[0] != 'Psilocybin Licensing Team' && message.recipients[0] != 'Psilocybin Compliance Team' && message.recipients[0] != 'Product Tracking Team'">{{message.recipients[0]}}</span>
            <mat-form-field fxFlex.xl="75" fxFlex.lg="75" fxFlex.lt-lg="100" *ngIf="message.recipients[0] == 'Psilocybin Licensing Team' || message.recipients[0] == 'Psilocybin Compliance Team' || message.recipients[0] == 'Product Tracking Team'">
              <mat-select [(value)]="message.recipients[0]" (selectionChange)="changeRecipient($event, message.id)">
                <mat-option class="text-wrap" *ngFor="let recipient of recipients" value={{recipient}}>{{recipient}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="creator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}">{{
            message.creatorName }}</td>
        </ng-container>
        <ng-container matColumnDef="license">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>License/Permit/Program</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}">{{
            message.entityId }} ({{message.entityName}})</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}">{{
            message.createdDate }}</td>
        </ng-container>
        <ng-container matColumnDef="assignedToId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}" (click)="$event.stopPropagation()">
            <mat-form-field>
              <mat-select [(value)]="message.assignedToId" (selectionChange)="assignMessage($event, message.id)">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let staff of staffMembers" value={{staff.id}}>{{staff.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="flagged">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened && currentView != internalApplicationMessagingService.inboxView}" (click)="flagMessage(message); $event.stopPropagation()">
            <mat-icon style="color:red" *ngIf="message.flagged">flag</mat-icon>
            <mat-icon style="color:black" *ngIf="!message.flagged">outlined_flag</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let message" [attr.colspan]="displayedColumns.length">
            <div class="expansion-element-detail" [@detailExpand]="
              message == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expansion-element-diagram">
                <mat-card>
                  <mat-card-subtitle>To: {{message.recipients}}</mat-card-subtitle>
                  <mat-card-subtitle>From: {{message.creatorName}}</mat-card-subtitle>
                  <mat-card-subtitle *ngIf="environment.internalMessaging.canAnnounce && message.isBroadcast">Active:
                    {{message.active}}</mat-card-subtitle>
                  <h3>{{message.subject}}</h3>
                  <mat-card-content>
                    <br />
                    <p style="white-space: pre-wrap">{{ message.message }}</p>
                  </mat-card-content>
                  <div *ngIf="message.attachments != undefined && message.attachments.length > 0">
                    <mat-divider inset></mat-divider>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start top">
                      <div *ngFor="let attachment of message.attachments">
                        <span>
                          <button
                            type="button" color="primary" (click)="attachDocument(attachment.id)" mat-button >Add To Application</button>
                        </span>
                        <span (click)="downloadAttachment(attachment.id, attachment.name)">
                          <mat-icon class="icon-20" color="success" matTooltip="Downlaod file: {{attachment.name}}">
                            cloud_download</mat-icon>
                          &nbsp;&nbsp;
                          {{ attachment.name }}
                          &nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                  <mat-divider inset></mat-divider>
                  <mat-card-actions>
                    <button mat-button *ngIf="environment.internalMessaging.canRespond && message.canRespond && canReply(message.creatorName)"
                      (click)="openAddMessageDialog(message.id)">Reply</button>
                    <button mat-button (click)="archive(message.id)" *ngIf="!message.archived">Archive</button>
                    <button mat-button (click)="unarchive(message.id)" *ngIf="message.archived">UnArchive</button>
                    <button mat-button *ngIf="environment.internalMessaging.canAnnounce && message.isBroadcast"
                      (click)="editAnnouncement(message.id)">Edit Announcement</button>
                  </mat-card-actions>
                  <mat-card-footer>
                  </mat-card-footer>
                </mat-card>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="expansion-element-row"
          [class.expansion-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row" (click)="open(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expansion-detail-row"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" #TableOnePaginator="matPaginator" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
