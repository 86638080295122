import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { Observable } from 'rxjs';
import { IFacilitatorLicenseSimple } from 'app/models/licenses/facilitator/facilitator-license-simple';
import { IFacilitatorSearch } from 'app/models/licenses/facilitator/facilitator-search';
import { IFacilitator } from 'app/models/licenses/facilitator/facilitator-license';
import { ITaskSendNotice } from 'app/models/tasks/task';
import { IApplicant } from 'app/models/licenses/applicant';
import { IStaff } from 'app/models/user-info/staff';
import { AuditLoggingSearch } from 'app/models/audit-logging/audit-logging-search';
import { ITask } from 'app/models/tasks/task';

@Injectable({providedIn: 'root'})

export class FacilitatorService {
  private api: string = environment.privateApi + 'Facilitator/';

  constructor(private http: HttpClient) { }

  getLicense(id: number): Observable<IFacilitator>{
    return this.http.get<IFacilitator>(this.api + 'GetLicense/' + id);
  }

  checkDuplicates(applicantId: number): Observable<boolean>{
    return this.http.get<boolean>(this.api + 'CheckForDuplicate/' + applicantId);
  }

  getPDF(search: AuditLoggingSearch): Observable<IFacilitator>{
    return this.http.post<IFacilitator>(this.api + "OriginalCopy", search);
  }

  withdrawLicense(approved: boolean, id: number): Observable<IFacilitator>{
    return this.http.post<IFacilitator>(this.api + "withdrawLicense/" + approved + "/" + id, null);
  }

  surrenderLicense(approved: boolean, id: number): Observable<IFacilitator>{
    return this.http.post<IFacilitator>(this.api + "surrenderLicense/" + approved + "/" + id, null);
  }

  createLicense(): Observable<IFacilitator>{
    return this.http.post<IFacilitator>(this.api + 'CreateLicense', null);
  }

  updateLicense(facilitator: IFacilitator): Observable<IFacilitatorLicenseSimple>{
    return this.http.post<IFacilitatorLicenseSimple>(this.api + 'UpdateLicense', facilitator);
  }

  searchLicenses(search: IFacilitatorSearch): Observable<IFacilitatorLicenseSimple[]>{
    return this.http.post<IFacilitatorLicenseSimple[]>(this.api + 'SearchLicenses', search);
  }

  getLicenseTasks(): Observable<IFacilitatorLicenseSimple[]>{
    return this.http.get<IFacilitatorLicenseSimple[]>(this.api + 'GetLicenseTasks');
  }

  getAssignedLicenses(): Observable<IFacilitatorLicenseSimple[]>{
    return this.http.get<IFacilitatorLicenseSimple[]>(this.api + 'GetAssignedLicenses');
  }

  claimLicense(id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'ClaimLicense/' + id, null)
  }

  assignLicense(id: number, staff: IStaff): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AssignLicense/' + id, staff);
  }

  releaseLicense(id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'ReleaseLicense/' + id, null);
  }

  updateLicenseAccount(licenseId: number, userId: string): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'UpdateLicenseAccount/' + licenseId + '/' + userId, null);
  }

  deleteLicense(licenseId: number, applicantId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteLicense/' + licenseId + '/' + applicantId);
  }

  updateLicensePayment(facilitator: IFacilitator):Observable<any>{
    return this.http.post<any>(this.api + 'UpdateLicensePaymentId', facilitator);
  }

  editLicenseApplicant(applicant: IApplicant): Observable<IApplicant>{
    return this.http.post<IApplicant>(this.api + 'EditLicenseApplicant', applicant);
  }

  sendTaskNotice(id: number, reason: string): Observable<boolean> {
    let taskSendNotice: ITaskSendNotice = {
      id: id,
      reason: reason
    };
    return this.http.post<boolean>(this.api + 'SendTaskNotice/', taskSendNotice);
  }

  acknowledgeTask(id: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AcknowledgeTask/' + id, null);
  }

  deleteTask(task: ITask): Observable<boolean> {
    return this.http.post<boolean>(this.api + 'DeleteTask', task);
  }

  uploadDocument(file: FormData, applicant: boolean): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument/' + applicant, file);
  }

  downloadFile(fileId: number, applicant: boolean): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId + '/' + applicant, { responseType: 'blob' as 'json' });
  }

  deleteDocument(id: number, applicant: boolean): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id + '/' + applicant);
  }

  deprecateDocument(id: number, applicant: boolean): Observable<boolean>{
    return this.http.post<boolean>(this.api + "DeprecateDocument/" + id + '/' + applicant,null);
  }

  getLicenseStaff(): Observable<IStaff[]>{
    return this.http.get<IStaff[]>(this.api + 'GetLicenseStaff');
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  getExpiredLicenses(licenseId: string): Observable<IFacilitator[]>{
    return this.http.get<IFacilitator[]>(this.api + 'GetExpiredLicenses/' + licenseId);
  }

  createRenewalLicense(licenseId: number): Observable<IFacilitator>{
    return this.http.post<IFacilitator>(this.api + 'CreateFacilitatorRenewal/' + licenseId, null);
  }
}
